import { ReactComponent as AltitudeIcon } from 'assets/icons/altitudeIcon.svg'
import {ReactComponent as SpeedIcon} from 'assets/icons/speedIcon.svg'
import Alert from 'components/Alert/Alert';
import { isScouting } from 'models/Mission';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { IAction, IWaypoint, setCurrentMission, setCurrentPoint } from 'state/slices/volareSlice';

import { FlightInfo } from '../Components/FlightInfo/FlightInfo';
import ListInputItem from '../Components/InputList/ListInputItem';
import { MissionPointForm } from './MissionPointForm';

export default function EditForm () {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const globalState = useAppSelector((state) => state.volare);

    // const handleEditAction = (action: IAction) => { 
    //     const mission = JSON.parse(JSON.stringify(globalState.currentMission));
    //     mission.waypoints[globalState.currentPoint.index].actions[action.index] = action;
    //     dispatch(setCurrentMission(mission));
    // }

    // const handleAddAction = (action: IAction) => {
    //     const mission = JSON.parse(JSON.stringify(globalState.currentMission));
    //     action.action = action.action.toUpperCase();
    //     action.index = mission.waypoints[globalState.currentPoint.index].actions.length;
    
    //     mission.waypoints[globalState.currentPoint.index].actions.push(action);
    //     dispatch(setCurrentMission(mission));
    // };

    // const handleRemoveAction = (index: number) => {
    //     const mission = JSON.parse(JSON.stringify(globalState.currentMission));
    //     mission.waypoints[globalState.currentPoint.index].actions.splice(index, 1);
    //     mission.waypoints[globalState.currentPoint.index].actions = mission.waypoints[
    //       globalState.currentPoint.index
    //     ].actions.map((action: IAction, idx: number) => {
    //       action.index = idx;
    //       return action;
    //     });
    //     dispatch(setCurrentMission(mission));
    // };

    // const removeCurrentPoint = () => {
    //     dispatch(setCurrentPoint({ index: -1, position: [0, 0] }));
    // };

    // const handleChangeWaypointParams = (params: IWaypoint) => {
    //     const mission = JSON.parse(JSON.stringify(globalState.currentMission));
    //     mission.waypoints[globalState.currentPoint.index].altitude = params.altitude;
    //     mission.waypoints[globalState.currentPoint.index].speed = params.speed;
    //     dispatch(setCurrentMission(mission));
    // };

    // const handleChangeAllWaypointParams = (params: IWaypoint) => {
    //     const mission = JSON.parse(JSON.stringify(globalState.currentMission));
    //     mission.waypoints = mission.waypoints.map((waypoint: IWaypoint) => {
    //       return {
    //         ...waypoint,
    //         altitude: params.altitude,
    //         speed: params.speed,
    //         actions: params.actions,
    //       };
    //     });
    //     dispatch(setCurrentMission(mission));
    // };

    return ( 
        <>
            <FlightInfo scouting={true} />

            { globalState.currentPoint?.index >= 0 && globalState.currentMission && isScouting(globalState.currentMission)  ?
                <MissionPointForm
                    index={globalState.currentPoint.index}
                    mission={globalState.currentMission}
                    // handleEditAction={handleEditAction}
                    // handleAddAction={handleAddAction}
                    // handleRemoveAction={handleRemoveAction}
                    // removeCurrentPoint={removeCurrentPoint}
                    // handleChangeWaypointParams={handleChangeWaypointParams}
                    // handleChangeAllWaypointParams={handleChangeAllWaypointParams}
                />
                :(
                    <Alert type='info' text={t('edit-mode-tip')}/>
                )
            }   
        </>
    )
}