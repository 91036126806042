import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as LayersIcon } from 'assets/icons/ico-layers.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashIcon.svg';
import { useEffect, useState } from 'react';
import { IAllTasks } from 'state/slices/taskSlice';

import style from '../PqContextual/Contextual.module.scss';
import EditOption from './Options/EditOption';
import LayersOption from './Options/LayersOption';
import TrashOption from './Options/TrashOption';


export default function MissionContextual({
    projectId,
    lotId,
    task,
}: {
    projectId: string;
    lotId: string;
    task: IAllTasks
}) {
    const [option, setOption] = useState<number>(0);
    const {data:lot, isLoading} = useGetLotQuery(
        {projectId:+projectId,lotId:+lotId}
    )

    const options = [
        <LayersOption key={0} taskId={task.taskId} lot={lot} projectId={+projectId} lotId={+{lotId}}/>,
        <EditOption key={1} task={task}/>,
        <TrashOption key={2} taskId={task.taskId}/>
    ];

    return (
        <div className={style.contextualMenu}>
            {
                !isLoading &&
                options[option]
            }
            <div className={style.tabBar} >
                <div className={style.tabIcons}>
                    <div
                        className={option == 0 ? style.bubble : style.bubbleOff}
                        onClick={() => setOption(0) }
                    >
                        <LayersIcon className={style.tabicon} stroke={style.azul} />
                    </div>

                    <div
                        className={option == 2 ? style.bubble : style.bubbleOff}
                        onClick={() => setOption(1) }
                    >
                        <EditIcon className={style.tabicon} fill={style.azul} />
                    </div>

                    <div
                        className={option == 3 ? style.bubble : style.bubbleOff}
                        onClick={() => setOption(2) }
                    >
                        <TrashIcon className={style.tabicon} fill={style.azul}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
