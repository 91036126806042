import {
  useGetPrescriptionQuery,
  useGetTaskQuery,
  useGetTaskUploadedMetadataQuery,
} from 'api/Vistaguay/TaskAPI';
import { ReactComponent as Camera } from 'assets/icons/ico-camara.svg';
import Button from 'components/Button/Button';
import { PrescriptionPolygons } from 'components/Domain/Prescription/PrescriptionPolygons';
import MCircleText from 'components/Map/Components/MCircleText';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import MapContext from 'components/Map/Context/MapContext';
import { SecureImg } from 'components/Secure/SecureImg/SecureImg';
import { PhotoMetadata } from 'models/PhotoMetadata';
import { Polygon as OlPolygon } from 'ol/geom';
import { customRound } from 'pages/TaskList/TaksListMap/CustomRound';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from 'services/configurations';
import { useAppSelector } from 'state/hooks';
import { ColorCodes, cvRadius } from 'styles/pointStyle';

import { CountMapLegend } from './CountMapLegend';
import style from './TaskViewMap.module.scss';
// const BACKEND_URL = 'localhost:3000';

export const getImageUrl = (taskId: string, lotId: string, fileName: string) => {
  return `http://${API_URL}/task/${taskId}/photos/name/${fileName}?lotId=${lotId}`;
};

export const getRadius =  (covPercent: number) => {
  if (covPercent > 60) { return cvRadius.big;}
  else if (50 < covPercent && covPercent <= 60) {return cvRadius.medium;}
  else if (covPercent <= 50) {return cvRadius.small;}
};

export const getColor = (gapPercent: number) => {
  if (gapPercent <= 25) return ColorCodes.GREEN;
  if (gapPercent <= 35) return ColorCodes.LIGHT_GREEN;
  if (gapPercent <= 40) return ColorCodes.YELLOW;
  if (gapPercent <= 50) return ColorCodes.ORANGE;
  else return  ColorCodes.RED;
};

export function TaskViewMap() {
  const map = useContext(MapContext);
  const { projectId, lotId, taskId } = useParams<{
    projectId: string;
    lotId: string;
    taskId: string;
  }>();
  const navigate = useNavigate();

  if (!taskId || !lotId || !projectId) {
    return null;
  }

  const { data: task, isLoading: isLoadingTask } = useGetTaskQuery({ taskId });
  const { data: photosMetadata } = useGetTaskUploadedMetadataQuery({ taskId, lotId });
  const { data: prescriptionData } = useGetPrescriptionQuery({ taskId });

  const token = useAppSelector((state) => state.data.accessToken);

  useEffect(() => {
    // zoom to polygon
    const feat = PhotoMetadata.generarPoligono(photosMetadata);

    if (feat) {
      const padding = 200;
      map?.getView().fit((feat as OlPolygon).getExtent(), {
        padding: [padding, padding, padding, padding], // [0, 0, 0, 0].map(() => padding)
        duration: 600,
      });
    }
  }, [photosMetadata]);

  const showPrescriptions = prescriptionData != undefined;
  const prescriptionToShow = prescriptionData;

  const [photoModalUrl, setPhotoModalUrl] = useState<string | null>(null);


  return (
    <>
      {photoModalUrl && (
        <Modal
          show={!!photoModalUrl}
          onHide={() => {
            setPhotoModalUrl(null);
          }}
          centered
          size='xl'
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <SecureImg
              style={{
                width: '100%',
              }}
              linkUrl={photoModalUrl}
            />
          </Modal.Body>
        </Modal>
      )}
      {photosMetadata && (
        <MVectorLayer zIndex={30}>
          {photosMetadata.map((photoMetadata: any, i: number) => {
            const popup = {
              title: overlayTitle({ photoMetadata, lotId, taskId }),
              content: <OverlayBody photoMetadata={photoMetadata} />,
            };
            if(photoMetadata.densidad == null || photoMetadata.densidad == undefined)
            {
              return
            }
            return (
              <MCircleText
                key={photoMetadata.fileName}
                lat={+photoMetadata.GPSLatitude}
                lng={+photoMetadata.GPSLongitude}
                text={`${customRound(photoMetadata.densidad / 1000)}K`}
                radius={getRadius(photoMetadata.cov_cv)}
                color={getColor(photoMetadata.gap_cv)}
                hovered={i % 2 === 0}
                overlayContent={{
                  title: (
                    <PhotoPopupTitle
                      photoMetadata={photoMetadata}
                      token={token}
                      taskId={taskId}
                      openImageModal={(url: string) => {
                        setPhotoModalUrl(url);
                      }}
                    />
                  ),
                  content: (
                    <PhotoPopup photoMetadata={photoMetadata} taskId={taskId} token={token} />
                  ),
                }}
              />
            );
          })}
          <CountMapLegend showShareButton={true} task={task} />
        </MVectorLayer>
      )}
      {prescriptionToShow && (
        <MVectorLayer isVisible={showPrescriptions} zIndex={20}>
          {showPrescriptions && <PrescriptionPolygons prescription={prescriptionToShow} />}
        </MVectorLayer>
      )}
    </>
  );
}

export const overlayTitle = ({
  photoMetadata,
  lotId,
  taskId,
}: {
  photoMetadata: any;
  lotId: string;
  taskId: string;
}) => {
  return `<div class=${style.popupTitle}>
      <a href='${getImageUrl(taskId, lotId, photoMetadata.fileName)}' target="_blank"><h1>${
    photoMetadata.fileName
  }</h1></a>
    </div>`;
};

export const OverlayBody = ({ photoMetadata }: { photoMetadata: any }) => {
  return (
    <div className={style.popupBody}>
      <pre>{JSON.stringify(photoMetadata, null, 2)}</pre>
    </div>
  );
};

export const PhotoPopupTitle = ({ photoMetadata, taskId, openImageModal }: any) => {
  return (
    <div className={style.popHeader}>
        <div className={style.photo}>
          <div  className={style.data}>
            <div
              onClick={() =>
                openImageModal(`task/${taskId}/result/${photoMetadata.img_name.replace('JPG', 'PNG')}`)
              }
            >
              <Camera style={{ fill: 'blue', width: '28px', height: '28px'}} />
              <div>{photoMetadata.img_name.replace('.JPG', '')}</div>
            </div>
            {/* </DownloadLinkToken> */}
          </div>
        </div>
      <div className={style.density}>
        <h2>Densidad</h2>
        <div className={style.densityValue}>
          <span
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            {photoMetadata.densidad}
          </span>
          <span
            style={{
              fontSize: '1rem',
            }}
          >
            pl/ha
          </span>
        </div>
      </div>
    </div>
  );
};

export const PhotoPopup = ({ photoMetadata }: any) => {
  return (
    <div className={style.popMap}>
      <div className={style.popBody}>
        <div className={style.popBodyRow}>
          <h2>Espaciamiento</h2>
          <div>
            {photoMetadata.gap_mean.toFixed(2)}
            <span>X̄ (cm)</span>
          </div>
          <div>
            {photoMetadata.gap_std.toFixed(2)}
            <span>DS (cm)</span>
          </div>
          <div>
            {photoMetadata.gap_cv.toFixed(2)}
            <span>CV (%)</span>
          </div>
        </div>
        <div className={style.popBodyRow}>
        <h2 style={{ marginRight: '25px' }}>Cobertura</h2>
          <div>
            {photoMetadata.cov_mean.toFixed(2)}
            <span>X̄ (cm²/pl)</span>
          </div>
          <div>
            {photoMetadata.cov_std.toFixed(2)}
            <span>DS (cm²/pl)</span>
          </div>
          <div>
            {photoMetadata.cov_cv.toFixed(2)}
            <span>CV (%)</span>
          </div>
        </div>
      </div>
    </div>
  );
};
