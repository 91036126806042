import MosaicTask from './MosaicTask';

export enum COLOR_RAMP {
    RdYIGn = 'RdYlGn',
    Blues = 'Blues',
    Spectral = 'Spectral',
    Grays = 'Grays'
}
export interface HistogramStyle {
    min:number,
    max:number,
    colorRamp: COLOR_RAMP,
    steps: number,
    stepValues: number[],
}

export enum ITypeAlgo {
    MOSAICO = 1,
    DEM = 2,
    ENDVI = 3,
    VARI = 4,
    MALEZA = 5,
    NDWIG = 6,
}

export interface Algorithm {
    algo_id: number,
    estado_id: number,
    fecha_update?: string,
    lat_nort?: number | null,
    lon_oeste?: number | null,
    lat_sur?: number | null,
    lon_este?: number | null,
    superficie?: number | null,
    tipo_algo_id: number,
    notified: number,
    tif_values: object,
    Mosaico: MosaicTask,
    histogramStyle?: HistogramStyle,
}