import { typeDescription } from '../PhotoDescriptionSection';

export interface photoMetadata {
    densidad: number;
    fileName: string;
    img_name: string;
  }

export function getPhoto(photosMetadata: photoMetadata[], selectedAmbiente: number, type: typeDescription) {
  let filtredPhotosMetadata = photosMetadata;

  filtredPhotosMetadata = filtredPhotosMetadata.filter(
    (photoMetadata: photoMetadata) => photoMetadata.densidad != undefined,
  );

  if (selectedAmbiente != -1) {
    filtredPhotosMetadata = filtredPhotosMetadata.filter((photo: photoMetadata) => {
        return photo.fileName.split('/')[0] == selectedAmbiente.toString();
    });
}


  const selectedPhoto = filtredPhotosMetadata.reduce((prev: photoMetadata, current: photoMetadata) => {
    if (type === typeDescription.Min) {
        return prev.densidad < current.densidad ? prev : current;
    } else {
      return prev.densidad > current.densidad ? prev : current;
    }
  }, filtredPhotosMetadata[0]);
  return selectedPhoto;
}