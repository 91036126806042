import { useMeQuery } from 'api/Vistaguay/VistaguayAPI';
import { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'state/hooks';

import style from './ControlPanel.module.scss';
import { AdminCredits } from './Credits/AdminCredits';
import { Prices } from './Prices/Prices';
import { TaskSumaryRow } from './Reports/TaskSummary';

interface ITaskSumary {
  count: number;
  name: string;
  status: number;
  color?: string;
}

export const ControlPanel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [section, setSection] = useState<'reports' | 'credits' | 'prices' | 'groups'>('reports');

  const {data} = useMeQuery()

  useEffect(() => {
    if (data && data?.rol !== 1) {
      navigate('/not-authorized')
    }
  }, [data, navigate])

  if (!data) return null

  return (
    <div className={style.modal}>
      <div className={style.modalContent}>
        {/* <button
          className={style.close}
          onClick={() => {
            navigate('/');
          }}
        >
          &times;
        </button> */}
        <h1>Panel de control</h1>

        <Nav
          variant='tabs'
          style={{
            width: '100%',

          }}
        >
          <Nav.Item>
            <Nav.Link
              active={section === 'reports'}
              onClick={() => {
                setSection('reports');
              }}
            >
              Reports
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              active={section === 'credits'}
              onClick={() => {
                setSection('credits');
              }}
            >
              Credits
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              active={section === 'prices'}
              onClick={() => {
                setSection('prices');
              }}
            >
              Prices
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link
              active={section === 'groups'}
              onClick={() => {
                setSection('groups');
              }}
            >
              Admin groups:
            </Nav.Link>
          </Nav.Item> */}
        </Nav>

        {section === 'reports' && <TaskSumaryRow />}
        {section === 'credits' && <AdminCredits />}
        {section === 'prices' && <Prices />}
        {/* {section === 'groups' && <GroupAdmin />} */}
      </div>
    </div>
  );
};
