import { useGetCreditQuery, useGetTransactionsMutation } from 'api/Vistaguay/CreditAPI';
import { useMeQuery } from 'api/Vistaguay/VistaguayAPI';
import { ReactComponent as CloseIcon } from 'assets/icons/ico-close.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ico-search.svg';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { DatePicker } from 'components/Inputs/Input';
import { PaginatorFooter } from 'components/PaginatorFooter/PaginatorFooter';
import Select from 'components/Select/Select';
import { Table } from 'components/Table/Table';
import { TRANSACTION_TYPE } from 'models/Transaction';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAppDispatch } from 'state/hooks';
import { setModalGlobalState } from 'state/slices/uiSlice';

import style from './PurchaseHistoryModal.module.scss';

const COLUMNS = [
  {
    property: 'id',
    label: 'ID',
  },
  {
    property: 'paymentMethod',
    label: 'Metodo de pago'
  },
  {
    property: 'reason',
    label: 'Motivo',
  },
  {
    property: 'createdAt',
    label: 'Fecha',
  },
  {
    property: 'type',
    label: 'Tipo',
  },
  {
    property: 'amount',
    label: 'Monto',
  },
  
];

const PAGE_SIZE = 10;

export default function PurchaseHistoryModal () {
  const dispatch = useAppDispatch();

  const { data: credit, isLoading: creditsLoading } = useGetCreditQuery();
  const [getTransactions, { data: transactions, isLoading: transactionsLoading }] = useGetTransactionsMutation();
  const { data: me, isLoading: meLoading } = useMeQuery();
  const isLoading = creditsLoading || transactionsLoading || meLoading

  const [page, setPage] = useState(1);
  const [type, setType] = useState<TRANSACTION_TYPE | ''>('');
  const [dateFrom, setDateFrom] = useState<Date | undefined>(undefined);
  const [dateTo, setDateTo] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (me) {
      getTransactions({
        userId: me.usuarioId,
        pageNumber: page,
        pageSize: 10,
        type: type === '' ? undefined : type,
        startDate: dateFrom,
        endDate: dateTo,
      });
    }
  }, [dateFrom, dateTo, getTransactions, me, page, type]);

  const handleSearch = () => {
    if (me) {
      getTransactions({
        userId: me.usuarioId,
        pageNumber: page,
        pageSize: 10,
        type: type === '' ? undefined : type,
        startDate: dateFrom,
        endDate: dateTo,
      });
    }
  };

  useEffect(() => {
    console.log(transactions)
  },[transactions])

  return (
    <div className={style.modalWrapper}>

      <div className={style.closeButtonWrapper}>
        <div 
          className={style.closeButton} 
          onClick={() => dispatch(setModalGlobalState({isOpen: false, type: null}))}
        >
          <CloseIcon width={10} fill={style.dark}/>
        </div>
      </div>

      <div className={style.modalContent}>
        <div>
          <h1>Historial de transacciones</h1>
        </div>
        <div className={style.search}>
          <DatePicker
            label='Fecha desde'
            onChange={(date) => {
              setDateFrom(date);
            }}
            value={dateFrom}
          />
          <DatePicker
            label='Fecha hasta'
            onChange={(date) => {
              setDateTo(date);
            }}
            value={dateTo}
          />
          <select
            value={type}
            onChange={(e) => {
              setType(e.target.value as TRANSACTION_TYPE);
            }}
          >
            <option value='' disabled>
              Tipo
            </option>
            <option value={TRANSACTION_TYPE.CREDIT}>CREDIT</option>
            <option value={TRANSACTION_TYPE.DEBIT}>DEBIT</option>
          </select>
          <div className={style.buttons}>
            <SearchIcon className={style.searchIcon} onClick={handleSearch} />
            <Button
              onClick={() => {
                setDateFrom(undefined);
                setDateTo(undefined);
                setType('');
                handleSearch();
              }}
              text='Limpiar Filtros'
              color='white'
              style={{
                visibility: dateFrom || dateTo || type ? 'visible' : 'hidden',
                width: 'unset',
                fontSize: '14px',
                padding: '5px',
              }}
            />
          </div>
        </div>
        {isLoading &&
          <div >
            <Spinner />
          </div>
        }
        {transactions && transactions.data.length > 0 && (
          <div className={style.container}>
            <Table
              data={transactions.data.map((transaction) => ({
                ...transaction,
                createdAt: new Date(transaction.createdAt).toLocaleDateString(),
                color: transaction.type === 'CREDIT' ? '#009144' : '#cf4941',
              }))}
              columns={COLUMNS}
              colored
            />
            {Math.ceil(transactions.total / PAGE_SIZE) > 1 && (
              <PaginatorFooter
                page={page}
                handleNextPage={() => {
                  setPage(page + 1);
                }}
                handlePrevPage={() => {
                  setPage(page - 1);
                }}
                totalPages={Math.ceil(transactions.total / PAGE_SIZE)}
              />
            )}
          </div>
        )}
        {transactions && transactions.data.length === 0 && (
          <div className={style.container}>
            <p>No hay transacciones</p>
          </div>
        )}
      </div>
    </div>
  );
};
