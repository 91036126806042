import { url } from 'inspector';
import { method } from 'lodash';
import { ConteosDePlantasReporte } from 'models/ConteosDePlantasReporte';
import MosaicTask from 'models/MosaicTask';
import { PlantCountTask } from 'models/PlantCountTask';
import { Prescription } from 'models/Prescription';
import { Task } from 'models/Task';
import { MosaicFormProps } from 'pages/Tasks/Mosaics/AddMosaic';

import { VistaguayApi } from './VistaguayAPI';

export const addToFormData = (formData: FormData, key: string, value?: any) => {
  if (value) {
    formData.append(key, value);
  }
};

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Task'],
});


export interface IPlantCountFilterQuery {
  userId?: string;
  username?: string;
  name?: string;
  plantCountTaskId?: string;
  status?: string;
  page?: string;
  limit?: string;
  createdAfter?: string;
  createdBefore?: string;

}

interface Iparams {
  [key: string]: string | undefined;
}

const buildParamsQueryUrl = (params: Iparams) => {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      queryParams.append(key, value);
    }
  });
  return queryParams.toString();
};

const taskApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    dummyTasks: builder.query({
      query: () => 'task',
      providesTags: ['Task'],
    }),
    getTask: builder.query({
      query: ({ taskId }: { taskId: string }) => `task/${taskId}`,
      providesTags: ['Task'],
    }),
    startCount: builder.mutation({
      query: ({ taskId }: { taskId: string }) => {
        return { url: `task/count/${taskId}/start`, method: 'POST', body: {} };
      },
    }),
    getCountMetadata: builder.query({
      query: ({ taskId }: { taskId: string }) => `task/${taskId}/result/metadata`,
      providesTags: ['Task'],
    }),
    getTaskUploadedMetadata: builder.query({
      query: ({ taskId, lotId }: { taskId: string; lotId: string }) =>
        `task/${taskId}/photo/metadata`,
      providesTags: ['Task'],
    }),
    getTasks: builder.query<[PlantCountTask], { campaignId: string; lotId: string }>({
      query: ({ campaignId, lotId }: { campaignId: string; lotId: string }) =>
        `task/generic?${buildParamsQueryUrl({ campaignId, lotId })}`,
      providesTags: ['Task'],
    }),
    getFilteredCountTasks: builder.query<PlantCountTask[] | [], IPlantCountFilterQuery>({
      query: (filters: IPlantCountFilterQuery) => {
        const params: Iparams = {
          userId: filters.userId,
          name: filters.name,
          status: filters.status,
          page: filters.page,
          plantCountTaskId: filters.plantCountTaskId,
          limit: filters.limit,
          createdAfter: filters.createdAfter,
          createdBefore: filters.createdBefore,
        };
        return {
          url: `task/admin/all?${buildParamsQueryUrl(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['Task'],
    }),
    postUpdateDescription: builder.mutation({
      query: ({ taskId, description }: { taskId: string; description: string }) => {
        return {
          url: 'task/update',
          method: 'POST',
          body: {
            description: description,
            taskId: taskId,
          },
        };
      },
      invalidatesTags: ['Task'],
    }),
    patchUpdateTask: builder.mutation({
      query: ({ taskId, formData }: { taskId: string, formData: any}) => {
        return {
          url: `task/update/${taskId}`,
          method: 'PATCH',
          body: formData
        }
      },
      invalidatesTags: ['Task'],
    }),
    deleteTask: builder.mutation({
      query: ({ taskId }: { taskId: string }) => {
        return {
          url: `task/delete/${taskId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Task'],
    }),
    addTask: builder.mutation({
      query: ({
        task,
        projectId,
        lotId,
      }: {
        task: Omit<Task, 'id'>;
        projectId: string;
        lotId: string;
      }) => ({
        url: `proyectos/${projectId}/lotes/${lotId}/tareas`,
        method: 'POST',
        body: task,
      }),
      invalidatesTags: ['Task'],
    }),
    addCountTask: builder.mutation({
      query: ({
        countTask,
        projectId,
        lotId,
        campaignId,
        prescription
      }: {
        countTask: Partial<PlantCountTask>;
        projectId?: string;
        lotId?: string;
        campaignId?: number;
        prescription: Prescription | null
      }) => {
        const formData = buildAddCountTaskFormData(countTask, campaignId, lotId, projectId, prescription);

        return {
          url: 'task/count',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Task'],
    }),
    updateStatusCountTask: builder.mutation({
      query: ({ taskId, status }: { taskId: string; status: string }) => {
        return {
          url: `task/${taskId}/status`,
          method: 'PATCH',
          body: {
            status: status,
          },
        };
      },
      invalidatesTags: ['Task'],
    }),
    getPrescription: builder.query({
      query: ({ taskId }: { taskId: string }) => `task/${taskId}/prescription`,
    }),
    deleteFiles: builder.mutation({
      query: ({taskId} : { taskId: string}) => {
        return {
          method:'DELETE',
          url: `task/${taskId}/deleteFiles`
        }
      }
    }),
    getReportData: builder.query<ConteosDePlantasReporte, {taskId: string}>({
      query: ({taskId} : {taskId : string}) => {
        return {
          method:'GET',
          url:`reporte/${taskId}`
        }
      }
    })
  }),
});

// !Fix: countMetadata api return {result:[countmetadata]}
export interface ICountMetaData {
  logo: null;
  establecimiento: string;
  fechaDelVuelo: string;
  cultivoEstadio: string;
  hibrido: string;
  tipoDeSiembra: string;
  den_graf: string;
  densidadCv: string;
  esp_graf: string;
  gapCv: string;
  cob_graf: string;
  coberturaCv: string;
  ejemploPoblación: string;
  ejemploGapCv: string;
  ejemploCoberturaCv: string;
  img_ejemplo: string;
  distanciaSurcos: string;
  densidadObjetivo: string;
  densidadMedia: string;
  densidadMediana: string;
  densidadDesvio: string;
  gapObjetivo: string;
  gapMedia: string;
  gapMediana: string;
  gapDesvio: string;
  coberturaMedia: string;
  coberturaMediana: string;
  coberturaDesvio: string;
  ejemploSuperficie: string;
  ejemploDensidad: string;
  ejemploGapMedia: string;
  ejemploGapDesvio: string;
  ejemploCoberturaMedia: string;
  ejemploCoberturaDesvio: string;
} 

export const {
  useGetTaskQuery,
  useGetTasksQuery,
  useAddTaskMutation,
  useAddCountTaskMutation,
  useGetTaskUploadedMetadataQuery,
  useStartCountMutation,
  useGetCountMetadataQuery,
  usePostUpdateDescriptionMutation,
  useDeleteTaskMutation,
  useGetPrescriptionQuery,
  useGetFilteredCountTasksQuery,
  useUpdateStatusCountTaskMutation,
  useDeleteFilesMutation,
  useGetReportDataQuery,
  usePatchUpdateTaskMutation
} = taskApi;


export function buildMosaicTaskFormData(
  mosaicTask: MosaicFormProps ,
  campaignId: number | undefined,
  lotId: string | undefined,
  projectId: string | undefined,
) {
  const nImagenes = mosaicTask.files.length;
  const formData = {
    nombre: mosaicTask.nombre ?? '',
    fechaVuelo: mosaicTask.flightDate?.toISOString() ?? '',
    fechaSiembra: mosaicTask.sowDate?.toISOString() || '',
    cuitCliente: mosaicTask.clientCuit?.toString() ?? '',
    cultivoId: String(mosaicTask.cultivoId),
    hibridoId: String(mosaicTask.hibridoId),
    estadioId: String(mosaicTask.estadioId),
    tipoCamara: mosaicTask.cameraType,
    camaraId: mosaicTask.cameraId,
    nImagenes: String(nImagenes),
    campaignId: String(campaignId),
    lotId: String(lotId),
    projectId: String(projectId),
  };

  return formData;
}

type DensityObject = { density: number };
function convertArrayToJsonString(input: DensityObject[]): string {
  const result: { [key: string]: string } = {};

  input.forEach((item, index) => {
    result[index] = (item.density * 10).toString();
  });

  return JSON.stringify(result);
}

export function buildAddCountTaskFormData(
  countTask: Partial<PlantCountTask>,
  campaignId: number | undefined,
  lotId: string | undefined,
  projectId: string | undefined,
  prescription: Prescription | null,
) {
  const formData = new FormData();

  formData.append('nombre', countTask.nombre?.toString() ?? '');
  formData.append('fechaVuelo', countTask.fechaVuelo || '');
  formData.append('fechaSiembra', countTask.fechaSiembra || '');
  formData.append('cuitCliente', countTask.CUIT?.toString() ?? '');
  // formData.append('distanceId', String(countTask.distancing));
  formData.append('campaignId', String(campaignId));
  formData.append('lotId', String(lotId));
  formData.append('projectId', String(projectId));
  addToFormData(formData, 'dSurco', countTask.dSurco)
  addToFormData(formData, 'sembradoraId', countTask.seederId);
  addToFormData(formData, 'hibridoId', countTask.genotypeId);
  addToFormData(formData, 'estadioId', countTask.phenologicalStateId);
  addToFormData(formData, 'stageId', countTask.phenologicalStateId);
  addToFormData(formData, 'cultivoId', countTask.cropId);
  addToFormData(formData, 'seederMeterId', countTask.seederMeterId);
  addToFormData(formData, 'seederBrandId', countTask.seederBrandId);
  addToFormData(formData, 'seederModelId', countTask.seederModelId);
  addToFormData(formData, 'siembraId', countTask.sowTypeId);
  
  // Tipo de siembra (variable)
  if (countTask.sowTypeId === 2 && prescription?.id) {
    const density = convertArrayToJsonString(countTask.densidadesJson)
    addToFormData(formData, 'densidadesJson__str', density);
    addToFormData(formData, 'prescriptionId', prescription.id);
    addToFormData(formData, 'tipoProceso', 2);
  }
  // Tipo de siembra (fija)
  if (countTask.sowTypeId === 1) {
    addToFormData(formData, 'densidadesJson__str', JSON.stringify({ '0': countTask.targetDensity }));
    addToFormData(formData, 'tipoProceso', 1);
  }

  // add files
  if (countTask.files) {
    for (let i = 0; i < countTask.files.length; i++) {
      if (countTask.files[i] !== undefined) {
        console.log('adding: ' + countTask.files[i].name);
        formData.append('files', countTask.files[i]);
      }
    }
  }

  return formData;
}
