import MapContext from 'components/Map/Context/MapContext';
import VectorLayerContext from 'components/Map/Context/VectorLayerContext';
import { LineString, Point, Polygon } from 'ol/geom.js';
import Geometry, { Type as GeoType } from 'ol/geom/Geometry';
import { Draw, Modify } from 'ol/interaction.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import Style, { GeometryFunction } from 'ol/style/Style';
import { useContext, useEffect, useRef, useState } from 'react';

import {
  formatArea,
  formatLength,
  labelStyle,
  modifyStyle,
  segmentStyle,
  style,
  tipStyle,
} from './MeasureLib';
import CssStyles from './MMeasure.module.scss'

type ITypeGeometry = {
  Type: 'Polygon' | 'GeometryCollection' | 'Point' | 'LineString' | 'MultiPoint' | 'MultiLineString' | 'MultiPolygon' | 'LinearRing' | 'Circle'
}

import {ReactComponent as PolygonIcon } from 'assets/icons/area.svg'
import {ReactComponent as MeasureIcon } from 'assets/icons/measureIcon.svg'
import { Fill, RegularShape, Stroke, Text } from 'ol/style';

export default function MMeasureTool() {
  const map = useContext(MapContext);
  const [geometry,setGeometry] = useState('');

  const vectorLayer = useContext(VectorLayerContext);
  const typeSelect = useRef<HTMLButtonElement>(null);
  const showSegments = useRef<HTMLButtonElement>(null);


  const buttonRef = useRef<HTMLButtonElement>(null);
  const [measure,setMeasure] = useState(false);
  const [bottonOn, setBotton] = useState(false);


  const handleButtonClick = (selectedGeometry: string) => {
    setMeasure(false);
  
    if (bottonOn === true) {
      setBotton(false);
      setMeasure(false);
      setGeometry('');
    } else if (bottonOn === false) {
      setBotton(true);
      setMeasure(true);
      setGeometry(selectedGeometry);
    }
  };
  
  useEffect(() => {
    const source = vectorLayer?.getSource();
    if (source && measure !== false && geometry && bottonOn === true) {
      const modify = new Modify({ source: source, style: modifyStyle });
      const segmentStyles = [segmentStyle];
      let tipPoint: string | Geometry | GeometryFunction;
  
      const getLabelStyle = (geometry: any) => {
        const styles = [];
        const type = geometry.getType();
        let point, label, lineStyle;
      
        if (type === 'Polygon') {
          point = geometry.getInteriorPoint();
          label = formatArea(geometry);
      
          const outlineStyle = new Style({
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.4)',
              width: 3,
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
          }),
          });
          styles.push(outlineStyle);
        } else if (type === 'LineString') {
          point = new Point(geometry.getLastCoordinate());
          label = formatLength(geometry);
      
          lineStyle = new Style({
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.4)',
              width: 2,
            }),
          });
          styles.push(lineStyle);
      
          // Etiquetas para cada segmento
          geometry.forEachSegment((a: number[], b: number[]) => {
            const segment = new LineString([a, b]);
            const segmentLabel = formatLength(segment);
            const segmentPoint = new Point(segment.getCoordinateAt(0.5));
      
            const segmentLabelStyle = segmentStyle.clone();
            segmentLabelStyle.setGeometry(segmentPoint);
            segmentLabelStyle.getText()?.setText(segmentLabel);
            styles.push(segmentLabelStyle);
          });
        }
      
        if (label) {
          const labelStyle = new Style({
            geometry: point,
            text: new Text({
              text: label,
              font: '12px Roboto,sans-serif',
              fill: new Fill({ 
                color: 'rgba(255, 255, 255, 1)'
               }),
               backgroundFill: new Fill({
                color: 'rgba(0, 0, 0, 0.7)',
            }),  
            padding: [3, 3, 3, 3],
            textBaseline: 'bottom',
            offsetY: -15,
            }),
            
            image: new RegularShape({
              radius: 6,
              points: 3,
              angle: Math.PI,
              displacement: [0, 8.8],
              fill: new Fill({
                  color: 'rgba(0, 0, 0, 0.7)',
              }),
          }),
          });
          styles.push(labelStyle);
        }
      
        return styles;
      };
  
      const styleFunction = (feature?: any, segments?: boolean, drawType?: any, tip?: any) => {
        const styles = [];
        const geometry = feature.getGeometry();
        const type = geometry.getType();
        let point, label, line;
        if (!drawType || drawType === type || type === 'Point') {
          styles.push(style);
          if (type === 'Polygon') {
            point = geometry.getInteriorPoint();
            label = formatArea(geometry);
            line = new LineString(geometry.getCoordinates()[0]);
          } else if (type === 'LineString') {
            point = new Point(geometry.getLastCoordinate());
            label = formatLength(geometry);
            line = geometry;
          }
        }
        if (segments && line) {
          let count = 0;
          line.forEachSegment(function (a: number, b: number) {
            const segment = new LineString([a, b]);
            const label = formatLength(segment);
            if (segmentStyles.length - 1 < count) {
              segmentStyles.push(segmentStyle.clone());
            }
            const segmentPoint = new Point(segment.getCoordinateAt(0.5));
            segmentStyles[count].setGeometry(segmentPoint);
            segmentStyles[count].getText()?.setText(label);
            styles.push(segmentStyles[count]);
            count++;
          });
        }
        
        if (label) {
          labelStyle.setGeometry(point);
          labelStyle.getText()?.setText(label);
          styles.push(labelStyle);
        }
        if (tip && type === 'Point' && !modify.getOverlay().getSource().getFeatures().length) {
          tipPoint = geometry;
          tipStyle.getText()?.setText(tip);
          styles.push(tipStyle);
        }
        return styles;
      };
  
      const vector = new VectorLayer({
        source: source,
        style: (feature) => {
          return styleFunction(feature, true);
        },
      });
  
    
      let draw: Draw;
      const addInteraction = () => {
      const drawType = geometry;
      const idleTip = 'Haga click para comenzar la medición';
      let tip = idleTip;
      console.log('bottonssqq', bottonOn);
  
      // Eliminar todas las interacciones anteriores de dibujo
      map?.getInteractions().getArray().forEach((interaction) => {
        if (interaction instanceof Draw) {
          map?.removeInteraction(interaction);
        }
      });
      
      if (drawType === 'Polygon' || drawType === 'LineString') {
        console.log('drawType', drawType);
        if(bottonOn === true){
        draw = new Draw({
          source: source,
          type: drawType,
          style: function (feature) {
            return styleFunction(feature, true, drawType, tip);
          },
        });
      }
      }
      draw.on('drawstart', function () {
        map?.removeLayer(vector);
        modify.setActive(false);
        tip =  'Haga clic para continuar dibujando ';
        setMeasure(!measure);
      });
      draw.on('drawend', function (event) {
        const feature = event.feature;
        const geometry = feature.getGeometry();
      
        // Obtener y aplicar estilos de etiquetas
        const labelStyles = getLabelStyle(geometry);
        feature.setStyle([...styleFunction(feature, true, geometry), ...labelStyles]);
      
        // Establecer interacciones de modificación y movimiento del cursor
        modifyStyle.setGeometry(tipPoint);
        modify.setActive(true);
        map?.once('pointermove', function () {
          modifyStyle.setGeometry('');
        });
      
        // Actualizar el estado de la medición
        tip = idleTip;
        setMeasure(!measure);
      });
      
      
      
      if(bottonOn === true){
      modify.setActive(true);
      map?.addInteraction(draw);
      }
    };
  
    if (buttonRef.current) {
      buttonRef.current.onclick = (e) => {
        e.preventDefault();
        map?.getInteractions().getArray().forEach((interaction) => {
          if (interaction instanceof Draw || interaction instanceof Modify) {
            map?.removeInteraction(interaction);
          }
        });
        source.clear(true);
        map?.removeLayer(vector);
        modify.setActive(false);
      };
    }
  
    addInteraction();
      
    
  
    if (showSegments.current) {
      showSegments.current.onclick = () => {
        map?.getInteractions().getArray().forEach((interaction) => {
          if (interaction instanceof Draw || interaction instanceof Modify) {
            map?.removeInteraction(interaction);
          }
        });
        source.clear(true);
        map?.removeLayer(vector);
        modify.setActive(false);
        addInteraction();
        setMeasure(measure);
      };
    }
  
    return () => {
      source?.clear(true);
    };
  }
  
  }, [geometry, measure, bottonOn]);
  
  // Cleanup function to remove interactions when buttonOn is false
  useEffect(() => {
    if (bottonOn === false) {
      map?.getInteractions().getArray().forEach((interaction) => {
        if (interaction instanceof Draw || interaction instanceof Modify) {
          map?.removeInteraction(interaction);
          const source = vectorLayer?.getSource();
          source?.clear(true);

          
        }
      });
    }
  }, [bottonOn]);
  

  return (
    <>
      <button 
        ref={typeSelect} 
        className={`${CssStyles.buttons} ${CssStyles[geometry !== 'LineString' ?'buttonclose':'buttonopen']}`}
        onClick={() => handleButtonClick('LineString')}
      >
        <div>
        <MeasureIcon style={{ fill: geometry !== 'LineString' ? 'white' : 'white', width: 20, height: 20 }} />
        </div>
      </button>
      
      <button 
        ref={typeSelect} 
        className={`${CssStyles.buttons} ${CssStyles[geometry !== 'Polygon' ?'buttonclose':'buttonopen']}`}
        style={{borderBottomLeftRadius: 3, borderBottomRightRadius: 3}}
        onClick={() => handleButtonClick('Polygon')}
      >
        <div>
        <PolygonIcon style={{ fill: geometry !== 'Polygon' ? 'white' : 'white', width: 16, height: 16}} />
        </div>
      </button>
      </>

  );
}
