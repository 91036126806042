import { useMeQuery } from 'api/Vistaguay/VistaguayAPI';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'state/hooks';

import { GroupAdmin } from './GroupAdmin';
import style from './GroupAdmin.module.scss';


export const GroupAdminPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {data} = useMeQuery()

  useEffect(() => {
    if (data && data?.rol !== 2) {
      navigate('/not-authorized')
    }
  }, [data, navigate])

  return (
    <div className={style.modal}>
      <div className={style.modalContent}>
        <h1>Panel de control de grupos</h1>
        <GroupAdmin />
      </div>
    </div>
  );
};
