import { Popover } from 'bootstrap';
import { Polygon } from 'models/Polygon';
import { Feature, MapBrowserEvent } from 'ol';
import Collection from 'ol/Collection';
import OlGeoJSON from 'ol/format/GeoJSON';
import { Geometry, MultiPoint, Polygon as OlPolygon } from 'ol/geom';
import Modify from 'ol/interaction/Modify';
import Overlay from 'ol/Overlay';
import { transform } from 'ol/proj';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { useContext, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { dataProjection, featureProjection } from 'services/configurations';
import {
  POLYGON_ALPHA,
  POLYGON_INNER_VERTEX_COLOR,
  POLYGON_OUTER_VERTEX_COLOR,
} from 'styles/variables';

import MapContext from '../../Context/MapContext';
import VectorLayerContext from '../../Context/VectorLayerContext';
import { MPolygonDeleteVertex } from './MPolygonDeleteVertex';

interface PolygonProps {
  coordinates?: number[][][];
  polygon?: Polygon | null;
  isEditable?: boolean;
  onEdit?: (coordinates: number[][][]) => void;
  onLoadCenter?: boolean;
  color?: string;
  filled?: boolean;
  zIndex?: number;
  stroke: string;
  height: number

}

function MPolygon({
  coordinates,
  polygon,
  isEditable,
  onEdit,
  onLoadCenter,
  color,
  filled,
  zIndex,
  stroke,
  height,

}: PolygonProps) {
  const map = useContext(MapContext);
  const vectorLayer = useContext(VectorLayerContext);

  const glowStyle = [];
  for (let i = 5; i > 0; i--) {
    const opacity = i / 30;
    const width = i * 1.2;
    glowStyle.push(new Style({
      stroke: new Stroke({
        color: `rgba(255, 255, 255, ${opacity})`,
        width: width
      })
    }));
  }


  const style = new Style({
    zIndex: 3,
    stroke: new Stroke({
      color: isEditable ? 'white' : stroke,
      width: height,
    }),
    fill: new Fill({
      color: filled ? color : `rgba(247, 247, 247, ${POLYGON_ALPHA})`,
    })
  });

  const combinedStyle = [
    style, 
    ...glowStyle
  ];

  const editStyle = new Style({
    image: new CircleStyle({
      radius: 8,
      fill: new Fill({
        color: POLYGON_INNER_VERTEX_COLOR,
      }),
      stroke: new Stroke({
        color: POLYGON_OUTER_VERTEX_COLOR,
        width: 3, 
      }),
    }),
    geometry: function (feature) {
      // return the coordinates of the first ring of the polygon
      const geometry = feature.getGeometry();
      if (geometry instanceof OlPolygon) {
        const coordinates = geometry.getCoordinates()[0];
        if (coordinates) {
          return new MultiPoint(coordinates);
        }
      }
      return;
    },
    zIndex: 1000,
  });

  const [feature, setFeature] = useState<Feature>(new Feature());

  useEffect(() => {
    const geojson = new OlGeoJSON();
    feature.setGeometryName('coordinates');
    if (coordinates) {
      // transform coordinates to ol format using projections
      const transformedCoordinates = coordinates.map((coord) => {
        return coord.map((c) => transform(c, dataProjection, featureProjection));
      });
      const Polygon = new OlPolygon(transformedCoordinates);
      feature.setGeometry(Polygon);
    } else if (polygon) {
      const polyFeat = geojson.readFeature(polygon, {
        dataProjection: dataProjection,
        featureProjection: featureProjection,
      });
      let geometry;
      if (Array.isArray(polyFeat)) {
        // If feature is an array, get the geometry of the first feature in the array
        geometry = polyFeat[0].getGeometry();
      } else {
        // If feature is not an array, just call getGeometry on it
        geometry = polyFeat.getGeometry();
      }
      feature.setGeometry(geometry as Geometry);
    } else {
      feature.setGeometry(new OlPolygon([]));
    }

    const source = vectorLayer?.getSource();
    if(filled === undefined){
    feature.setStyle(combinedStyle);
    } else {
      feature.setStyle(style);
    }
    if (source) {
      source.addFeature(feature);
    }

    if (onLoadCenter && !isEditable) {
      // zoom to polygon
      const feat = feature.getGeometry();
      if (feat && polygon) {
        const zoomPadding = 150;
        map?.getView().fit((feat as OlPolygon).getExtent(), {
          padding: [zoomPadding, zoomPadding, zoomPadding, zoomPadding],
          duration: 600,
        });
      }
      // map?.getView().setZoom(18);
    }

    return () => {
      vectorLayer?.getSource()?.removeFeature(feature);
    };
  }, [coordinates, feature, polygon, vectorLayer]);

  useEffect(() => {
    if (isEditable) {
      feature.setStyle([style, editStyle]);

      const modify = new Modify({
        features: new Collection([feature]),
      });
      modify.on('modifyend', (e) => {
        const coordinates = (e.features.getArray()[0].getGeometry() as OlPolygon).getCoordinates();
        onEdit?.(
          coordinates.map((coord) =>
            coord.map((c) => transform(c, featureProjection, dataProjection)),
          ),
        );
      });
      map?.addInteraction(modify);

      return () => {
        map?.removeInteraction(modify);
      };
    }
  }, [isEditable, feature, onEdit, map]);

  const randomString = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 10; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  const popup = useRef<Overlay>();

  // show popover on click of a polygon vertex
  useEffect(() => {
    if (isEditable && map) {
      const elementx = document.getElementById('popup');
      const idToRemove = randomString();
      const element = document.createElement('div');
      element.id = idToRemove;
      elementx?.appendChild(element);
      const handleClickOutside = (e: MouseEvent) => {
        const popoverInstance = Popover.getInstance(element);
        if (popoverInstance && !element.contains(e.target as Node)) {
          popoverInstance.dispose();
        }
      };
      const onClickFn = (e: MapBrowserEvent<any>) => {
        const features = map.getFeaturesAtPixel(e.pixel, {
          hitTolerance: 8,
        });
        if (features && features.length > 0) {
          const currentFeat = features.filter((feat) => feat === feature);
          if (currentFeat.length > 0) {
            Popover.getInstance(element)?.dispose();
            // ** First of all lets check if the polygon have more than 3 vertices, else return **/
            const coordinates = (feature.getGeometry() as OlPolygon).getCoordinates();
            if (coordinates[0].length <= 4) {
              return;
            }
            // ** check if the click is on a vertex with a tolerance of 8px, else return **/
            const p0 = e.pixel;
            const closestPoint = (currentFeat[0].getGeometry() as Geometry).getClosestPoint(
              e.coordinate,
            );
            const p1 = map.getPixelFromCoordinate(closestPoint);
            const dx = p0[0] - p1[0];
            const dy = p0[1] - p1[1];
            const dist = Math.sqrt(dx * dx + dy * dy);
            if (dist > 8) {
              return;
            }
            // ***************************************************************************/
            // ** Delete existing popup if any and create a new one **/
            if (popup.current) {
              map.removeOverlay(popup.current);
              popup.current.dispose();
              popup.current = undefined;
            }
            const newPopup = new Overlay({
              element,
            });
            popup.current = newPopup;
            map.addOverlay(newPopup);
            const div = document.createElement('div');
            const root = createRoot(div);
            root.render(
              <MPolygonDeleteVertex
                deletePoint={() => {
                  const coordinates = (feature.getGeometry() as OlPolygon).getCoordinates();
                  const newCoordinates = coordinates.map((coord) =>
                    coord.map((c) => transform(c, featureProjection, dataProjection)),
                  );
                  const transformedClosestPoint = transform(
                    closestPoint,
                    featureProjection,
                    dataProjection,
                  );
                  const threshold = 10;
                  let closestPointIndex = -1;
                  const vertices = coordinates[0];
                  let minDist = threshold;
                  for (let i = 0; i < vertices.length; i++) {
                    const vertex = map.getPixelFromCoordinate(vertices[i]);
                    const dx = p0[0] - vertex[0];
                    const dy = p0[1] - vertex[1];
                    const dist = Math.sqrt(dx * dx + dy * dy);
                    if (dist <= threshold && dist <= minDist) {
                      minDist = dist;
                      closestPointIndex = i;
                    }
                  }
                  if (closestPointIndex !== -1) {
                    // Now if the index is 1 or the last index, we need to remove it both from the start and the end and set the new first point and push it to the end
                    if (closestPointIndex === 0 || closestPointIndex === vertices.length - 1) {
                      newCoordinates[0].pop();
                      newCoordinates[0].shift();
                      newCoordinates[0].push(newCoordinates[0][0]);
                    } else {
                      // else just remove the point
                      newCoordinates[0].splice(closestPointIndex, 1);
                    }
                    if (popup.current) {
                      map.removeOverlay(popup.current);
                      popup.current.dispose();
                      popup.current = undefined;
                    }
                    onEdit?.(newCoordinates);
                  }
                }}
              />,
            );
            const newPopover = new Popover(element, {
              animation: true,
              container: element,
              content: div,
              html: true,
              placement: 'top',
              title: '',
              sanitize: false,
            });
            newPopover.show();
            newPopup.setOffset([0, -10]);
            newPopup.setPosition(
              (currentFeat[0].getGeometry() as Geometry).getClosestPoint(e.coordinate),
            );
            newPopup.setPositioning('top-center');
          }
        }
      };
      const click = map.on('click', onClickFn);
      document.addEventListener('mousedown', handleClickOutside); // Add the click listener
      return () => {
        if (popup.current) {
          map.removeOverlay(popup.current);
          popup.current.dispose();
          popup.current = undefined;
        }
        Popover.getInstance(element)?.dispose();
        document.removeEventListener('mousedown', handleClickOutside); // Remove the click listener
        document.getElementById(idToRemove)?.remove();

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        map.un('click', onClickFn);
      };
    }
  }, [feature, map, isEditable]);

  return <></>;
}

function hexToRgbA(hex: string, alpha: number): string {
  let r: number, g: number, b: number;

  // Remove the # if it exists
  hex = hex.replace('#', '');

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default MPolygon;
