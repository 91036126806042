import { CSSProperties } from 'react';

import styles from './Sidebar.module.scss';

interface SidebarBodyProps {
  children: React.ReactNode;
  sx?: CSSProperties 
}

function SidebarBody({ children, sx = {} }: SidebarBodyProps) {
  return <div style={sx} className={styles.sidebarBody}>{children}</div>;
}

export default SidebarBody;
