import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCampaignProjectsQuery } from 'api/Vistaguay/CampaignAPI';
import { usePatchProjectMutation } from 'api/Vistaguay/ProjectAPI';
import MenuItemSingleEdit from 'components/Vistaguay/MenuItemSingleEdit/MenuItemSingleEdit'
import { Campaign } from 'models/Campaign';
// #region Models
import Project from 'models/Project';
// #endregion
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'state/hooks';
import { setCollapsePrescriptionTable } from 'state/slices/uiSlice';

import styles from './../ProjectContextual.module.scss'

export default function InfoOption ({project}: {project: Project}) {
    const { t } = useTranslation();

    const selectedCampaign: Campaign | null = useAppSelector(
        (state) => state.campaign.selectedCampaign,
    );

    const [projectName, setProjectName] = useState<string | undefined>(project.nombre);
    const [projectDescription, setProjectDescription] = useState<string | undefined>(project.descripcion);
    const [projectColor, setProjectColor] = useState(project.color);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const {
        refetch: refetchProjects
    } = useGetCampaignProjectsQuery(selectedCampaign?.id ?? skipToken);

    const [
        patchProject, 
        {
            isLoading,
            isSuccess,
            isError
        }
    ] = usePatchProjectMutation();

    
    const handleSaveName = (value: string) => { 
        patchProject({
            projectId: project.proyectoId,
            body: {nombre: value}
        })
    }

    const handleSaveDescription = (value: string) => { 
        patchProject({
            projectId: project.proyectoId,
            body: {descripcion: value}
        })
    }

    const handleSaveColor = (value: string) => { 
        patchProject({
            projectId: project.proyectoId,
            body: {color: value}
        }).then((response) => {
            refetchProjects();
        })
    }

    useEffect(() => {
        let timer: any;
        if (isSuccess) {
            setSuccess(true);
            timer = setTimeout(() => {
                setSuccess(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isSuccess]);

    useEffect(() => {
        let timer: any;
        if (isError) {
            setError(true);
            timer = setTimeout(() => {
                setError(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isError]);

    return (
        <div>
            <MenuItemSingleEdit 
                label={t('name')}
                inputValue={projectName || '-'}
                handleChange={(value: string) => setProjectName(value)}
                handleSaveChange={handleSaveName}
                editable
            />

            <MenuItemSingleEdit 
                label={t('description')}
                inputValue={projectDescription || '-'}
                handleChange={(value: any) => setProjectDescription(value)}
                handleSaveChange={handleSaveDescription}
                editable
            />

            <MenuItemSingleEdit 
                label={'Color'}
                inputValue={projectColor}
                inputType='colorpicker'
                handleChange={(value: any) => setProjectColor(value)}
                handleSaveChange={(value: any) => handleSaveColor(value)}
                laoding={isLoading}
                editable
            />

            <MenuItemSingleEdit 
                label={t('total-area')}
                inputValue={'756.1 ha'}
                handleChange={(value: any) => null}
            />

            <div style={{height: '20px'}}>
                { isLoading && (
                    <p className={styles.bounce}>{t('saving')}...</p>
                )}

                { success && (
                    <p style={{color: styles.verdeVistaguay}}>{t('saved')}</p>
                )}

                { error && (
                    <p style={{color:  styles.rojo}}>{t('error')}</p>
                )}
            </div>
        </div>
    )
}