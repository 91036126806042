import {  initMercadoPago,Wallet } from '@mercadopago/sdk-react'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { MP_PUBLIC_KEY } from 'services/configurations'
export default function ButtonMp ({preferenceId} : {preferenceId: string}) { 
    const [isLoading, setIsloading] = useState<boolean>(true)
    initMercadoPago(MP_PUBLIC_KEY)
    return (
        <div id="wallet_container">
            {
                isLoading && <Spinner/>
            }
            <Wallet onReady={() => setIsloading(false)}  initialization={{ preferenceId: preferenceId, redirectMode:'modal' }}  onError={(data) => {
                console.log(data)
            }}/>
        </div>
    )
}