import { IGdalInfo } from 'api/Vistaguay/MosaicAPI';
import * as d3 from 'd3';
import { getColorInterpolators } from 'pages/TaskList/TaskListContextual/MosaicContextual/Options/HistogramOption';
import { useEffect, useRef } from 'react';

const zIndex = 50;
const width = 430;
const height = 250;

interface HistogramProps {
  gdalInfo?: IGdalInfo;
  selectedMin?: number;
  selectedMax?: number;
  colorRamp?: string;
  steps?: number[];
  grouped?: boolean;
  withYAxis?: boolean;
  selectedBand?: number;
}


export const interpolateValue = (xPosition: number, selectedMin: number, selectedMax: number) => {
  const ret = Math.abs((1 / (selectedMin - selectedMax)) * (xPosition - selectedMin));
  if (xPosition < selectedMin) {
    return 0;
  }
  if (xPosition > selectedMax) {
    return 1;
  }
  return ret;
};

export const Histogram = ({
  gdalInfo,
  selectedMin = 0,
  selectedMax = 1,
  colorRamp,
  steps,
  grouped,
  withYAxis = false,
  selectedBand = 0,
}: HistogramProps) => {
  const svgRef = useRef<any>();

  const margin = { top: 20, right: 30, bottom: 40, left: withYAxis ? 70 : 30 };
  const pixelSize = Math.abs(gdalInfo ? gdalInfo.geoTransform[1] * gdalInfo.geoTransform[5] : 1);
  const band = gdalInfo?.bands[selectedBand];

  // eslint-disable-next-line prefer-const
  let data = band?.histogram.buckets || [];

  const groupedBuckets = [];
  const newSize = 1;
  for (let i = 0; i < data.length; i += newSize) {
    groupedBuckets.push(data.slice(i, i + newSize).reduce((a, b) => a + b, 0));
  }
  data = groupedBuckets;

  const min = band?.histogram.min || 0;
  const max = band?.histogram.max || 1;
  let bins = 0;
  let delta = 0;
  bins = data.length;
  delta = (max - min) / bins;

  // if (grouped && steps) {
  //   const groupedBuckets = steps.map((step: number) => {
  //     return 0;
  //   });
  //   groupedBuckets.push(0);
  //   groupedBuckets.push(0);
  //   let stepIterator = 0;
  //   for (let i = 0; i < data.length; i += 1) {
  //     const value = min + i * delta;
  //     if (value <= steps[stepIterator]) {
  //       groupedBuckets[stepIterator] += data[i];
  //     } else {
  //       stepIterator += 1;
  //       groupedBuckets[stepIterator] += data[i];
  //     }
  //   }
  //   bins = data.length;
  //   delta = (max - min) / bins;
  //   data = groupedBuckets;

  // }

  const minY = d3.min(data);
  const maxY = d3.max(data);

  // const min = hardoded.histogram.min || 0;
  // const max = hardoded.histogram.max || 1;

  const getXposFromBinIndex = (i: number) => {
    return min + i * delta + delta / 2;
  };

  const interpolatedColorValue = (xPosition: number) => {
    const ret = Math.abs((1 / (selectedMin - selectedMax)) * (xPosition - selectedMin));
    if (xPosition < selectedMin) {
      return 0;
    }
    if (xPosition > selectedMax) {
      return 1;
    }
    return ret;
  };

  useEffect(() => {
    if (!svgRef.current) {
      return;
    }
    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .style('z-index', zIndex)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const x = d3.scaleLinear().domain([min, max]).range([0, width]);
    svg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x));

    const y = d3
      .scaleLinear()
      .domain([0, maxY || 0])
      .range([height, 0]);

    if (withYAxis) {
      svg.append('g').call(d3.axisLeft(y));
    }

    const colorIntarpoleated = getColorInterpolators(colorRamp || 'viridis');

    const Tooltip = d3
      .select(svgRef.current)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltipX')
      .style('background-color', 'white')
      .style('color', 'black')
      .style('border', 'solid')
      .style('border-radius', '5px')
      .style('z-index', zIndex + 2)
      .style('position', 'absolute')
      .style('padding', '5px');

    const mouseover = function (event: MouseEvent, d: any) {
      const i = data.indexOf(d);
      const xStart = min + i * delta;
      const xEnd = xStart + delta;
      Tooltip.style('opacity', 1).html(
        `Entre [${xStart.toFixed(1)}, ${xEnd.toFixed(1)}]: ${(d * pixelSize).toFixed(
          1,
        )}m<sup>2</sup>`,
      );
    };
    const mousemove = function (event: MouseEvent, d: any) {
      const i = data.indexOf(d);
      const xStart = min + (i - 1) * delta;
      const xEnd = xStart + delta - 3;
      // funciona en barra lateral
      // const posX = event.x;
      // const posY = event.y;
      // funciona en tooltip
      const posX = d3.pointer(event)[0] + 40;
      const posY = d3.pointer(event)[1] - 50;
      Tooltip.style('left', posX + 20 + 'px').style('top', posY + 'px');
    };
    const mouseleave = function (d: any) {
      Tooltip.style('opacity', 0);
    };

    const mouseoverGrouped = function (event: MouseEvent, d: any) {
      if (!steps) return;
      const i = steps.indexOf(d);
      const xStart = steps[i - 1];
      const xEnd = steps[i];

      let pixelCount = 0;
      for (
        let dataIter = Math.floor((xStart - min) / delta);
        dataIter <= (xEnd - min) / delta;
        dataIter++
      ) {
        pixelCount += data[dataIter];
      }

      Tooltip.style('opacity', 1).html(
        `Zona ${i}: ${(pixelCount * pixelSize).toFixed(1)}m<sup>2</sup> `,
      );
    };
    const mousemoveGrouped = function (event: MouseEvent, d: any) {
      if (!steps) return;
      const i = steps.indexOf(d);
      const xStart = min + (i - 1) * delta;
      const xEnd = xStart + delta - 3;
      // funciona en barra lateral
      // const posX = event.x;
      // const posY = event.y;
      // funciona en tooltip
      const posX = d3.pointer(event)[0] + 40;
      const posY = d3.pointer(event)[1];
      Tooltip.style('left', posX + 20 + 'px').style('top', posY + 'px');
    };
    const mouseleaveGrouped = function (d: any) {
      Tooltip.style('opacity', 0);
    };

    let zoneIter = 0;
    zoneIter = +1;

    svg
      .selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', function (d, i) {
        return x(min + i * delta);
      })
      .attr('y', function (d, i) {
        return y(d);
      })
      .attr('width', x(delta) - x(0))

      .attr('height', function (d) {
        return y(0) - y(d);
      })
      .style('fill', function (d, i) {
        const xPosition = getXposFromBinIndex(i);
        if (grouped && steps) {
          while (steps[zoneIter] <= xPosition) {
            zoneIter++;
          }
          if (!steps[zoneIter]) {
            return colorIntarpoleated(
              interpolatedColorValue((steps[zoneIter - 1] + steps[zoneIter - 2]) / 2),
            );
          } else {
            return colorIntarpoleated(
              interpolatedColorValue((steps[zoneIter] + steps[zoneIter - 1]) / 2),
            );
          }
        } else {
          return colorIntarpoleated(interpolatedColorValue(getXposFromBinIndex(i)));
        }
      })
      .attr('stroke', 'None')
      .attr('stroke-width', 'None')
      .style('z-index', zIndex + 5);
    // .on('mouseover', mouseover)
    // .on('mousemove', mousemove)
    // .on('mouseleave', mouseleave);

    if (steps) {
      if (maxY) {
        svg
          .selectAll('grouped')
          .data(steps)
          .enter()
          .append('rect')
          .attr('x', function (d, i) {
            if (i != 0) {
              return x(steps[i - 1]);
            } else {
              return 0;
            }
          })
          .attr('y', function (d, i) {
            // return y(maxY / 2); //hasta la mitad
            return y(maxY);
          })
          .attr('width', function (d, i) {
            if (i != 0) {
              return x(steps[i]) - x(steps[i - 1]);
            } else {
              return 0;
            }
          })

          .attr('height', function (d) {
            // return y(0) - y(maxY / 2); // hasta la mitad
            return y(0) - y(maxY);
          })
          .attr('stroke', '#ffffff30')
          .style('fill', function (d, i) {
            return '#ffffff09';
          })
          .attr('stroke-width', '3')
          .style('z-index', zIndex + 8);
        // .on('mouseover', mouseoverGrouped)
        // .on('mousemove', mousemoveGrouped)
        // .on('mouseleave', mouseleaveGrouped);
      }
    }

    return () => {
      d3.select(svgRef.current).select('svg').remove();
      Tooltip.remove();
    };
  }, [data]);

  return <div ref={svgRef}></div>;
};
