import { useGetCreditQuery, useGetPricesQuery } from 'api/Vistaguay/CreditAPI'
import { ReactComponent as CreditsIcon } from 'assets/icons/ico-credits-outline.svg';
import Alert from 'components/Alert/Alert'
import Button from 'components/Button/Button'
import BuyCredits from 'components/Credits/BuyCredits'
import Skeleton2 from 'components/Skeleton2/Skeleton2'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorList, MosaicFormProps,MosaicStepProps } from '../AddMosaic'
import styles from './../../PlantCount/Map/AddCountMap.module.scss';


// interface MosaicStepCreditProps extends MosaicStepProps {
//     setHasError: Dispatch<React.SetStateAction<{ isValid: boolean; message: string; propName: string }>>
// }

export default function StepCredit({ 
    form, 
    setForm, 
    handleError,
    step,
    currentStep,
    nextStepTouched
}: MosaicStepProps) {
    const { t } = useTranslation();

    const { data: credit, isLoading: creditsLoading, isSuccess: creditSuccess } = useGetCreditQuery()
    const { data: prices, isLoading: pricesLoading, isSuccess: pricesSuccess } = useGetPricesQuery()
    const [needBuy, setNeedBuy] = useState<boolean>(false)

    const [errorsList, setErrorList] = useState<ErrorList[]>([]);

    useEffect(() => {
        if(currentStep == step) {
            const errors: ErrorList[] = [];
            let error = false; 

            if(!creditSuccess && !creditSuccess) {
                errors.push({ message: t('form-credits-error'), propName: 'credit'});
            } else if(credit && credit.amount && prices?.mosaic && (form.files.length * prices.mosaic >= credit.amount)) {
                errors.push({ message: t('form-credits-not-enougth'), propName: 'credit'});
            }

            if(errors.length > 0) { 
                error = true;
            } 
    
            setErrorList(errors);
            handleError(error);
        }
    }, [creditSuccess, pricesSuccess])

    return (
        <div style={{ marginTop: '20px' }}>
            { creditsLoading || pricesLoading ? (
                <Skeleton2 />
            ) : (
                <>
                    {needBuy && prices?.mosaic && credit && (
                        <BuyCredits
                            vpack={false}
                            stepper={false}
                            amount={Math.abs(form.files?.length * prices.mosaic)}
                        />
                    )}

                    {!needBuy && prices && (
                        <ul className={styles.creditList}>
                            <li className={styles.creditItem}>
                                <div className={styles.row}>
                                    <div className={styles.icon} />
                                    <p>{t('available-credits')}</p>
                                </div>

                                <p>{credit?.amount}</p>
                            </li>
                            {/* <li className={styles.creditItem}>
                                <div className={styles.row}>
                                    <ImgIcon className={styles.icon} />
                                    <p>Imagenes / Muestras</p>
                                </div>
                                <p>{form.files?.length}</p>
                            </li> */}
                            <li className={styles.creditItem}>
                                <div className={styles.row}>
                                    <div className={styles.icon}><CreditsIcon /></div>
                                    <p>{t('necesary-credits')}</p>
                                </div>
                                {form.files && prices && prices?.mosaic && (
                                    <p>
                                        {form.files.length * prices.mosaic}
                                    </p>)
                                }
                            </li>
                            <li className={styles.creditItem}>
                                <div className={styles.row}>
                                    <div className={styles.icon}/>
                                    <p>{t('remaining-credits')}</p>
                                </div>
                                {form.files && credit && prices?.mosaic && (
                                    <p
                                        style={{
                                            color:
                                                form.files.length * prices.mosaic >= credit.amount
                                                    ? styles.rojo
                                                    : styles.dark,
                                        }}
                                    >
                                        {credit.amount - form.files?.length * prices.mosaic}
                                    </p>
                                )}
                            </li>
                        </ul>
                    )}

                    {/* ERROR */}
                    { nextStepTouched && errorsList.map((error, index) => {
                        if (error.propName === 'credit') {
                            return <Alert key={index} type='error' text={error.message} />;
                        } else {
                            return null;
                        }
                    })}

                    {!needBuy && prices?.mosaic && credit && form.files.length * prices.mosaic >= credit.amount && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <Button text='Comprar' onClick={() => setNeedBuy(true)} variant='SUCCESS' />

                        </div>
                    )}
                </>
            )}
        </div>
    )
}