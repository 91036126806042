import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { exportGeoJson, exportPdf, exportPng, exportShp } from 'api/Vistaguay/ExportAPI';
import { useGetAlgorithmStatusQuery } from 'api/Vistaguay/MosaicAPI';
import { ReactComponent as GeoPDF } from 'assets/icons/ico-pdf.svg';
import { ReactComponent as PngIcon } from 'assets/icons/ico-png.svg';
import { ReactComponent as GeoTiff } from 'assets/icons/ico-tiff.svg';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import Tooltip from 'components/Tooltip/Tooltip';
import { error } from 'console';
import { Algorithm, ITypeAlgo } from 'models/Algorithm';
import MosaicTask from 'models/MosaicTask';
import { API_URL } from 'services/configurations';
import { useAppSelector } from 'state/hooks';

import style from '../Contextual.module.scss';

interface PropsAlgoExports {
  algorithm: Algorithm,
  taskId: string
}

function AlgoExports ({algorithm, taskId}: PropsAlgoExports) {
  const token = useAppSelector((state) => state.data.accessToken);
  const handleDownload = async (fileName:string) => {
    const response = await fetch(API_URL + `mosaic/${taskId}/export/${fileName}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if(!response.ok) {
      ErrorToaster('El archivo no existe')
    } else {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', fileName);
      tempLink.click();
      tempLink.remove();
    }
  }

  return (
    <>
      <div className={style.contextualButton} style={{marginBottom:'5px',paddingBottom:'5px'}}>
        <p style={{fontWeight:'bold'}}>{ITypeAlgo[algorithm.tipo_algo_id]}</p>
        <div className={style.actions}>
          {
            algorithm.tipo_algo_id != ITypeAlgo.MOSAICO &&
            <Tooltip text={`${ITypeAlgo[algorithm.tipo_algo_id].toLowerCase()}.pdf`}>
              <GeoPDF
                width={25}
                onClick={() => handleDownload(`${ITypeAlgo[algorithm.tipo_algo_id].toLowerCase()}.pdf`)}
                style={{ fill: style.light, cursor: 'pointer' }}
              />
            </Tooltip>
          }
          <Tooltip text={`${ITypeAlgo[algorithm.tipo_algo_id].toLowerCase()}.tif`}>
            <GeoTiff
              width={25}
              onClick={() => handleDownload(`${ITypeAlgo[algorithm.tipo_algo_id].toLowerCase()}.tif`)}
              style={{ fill: style.light, cursor: 'pointer'}}
            />
          </Tooltip>
          <Tooltip text={`${ITypeAlgo[algorithm.tipo_algo_id].toLowerCase()}.png`}>
            <PngIcon
              width={25}
              onClick={() => handleDownload(`${ITypeAlgo[algorithm.tipo_algo_id].toLowerCase()}.png`)}
              style={{ fill: style.light, cursor: 'pointer'}}
            />
          </Tooltip>
        </div>
      </div>
      <hr style={{margin: '5px 0 5px 0'}}/>
    </>

  )
}

export default function ExportOption({ task, taskId }: { task: MosaicTask; taskId: string }) {
  const { 
    data: algorithmData, 
    isLoading: algorithmLoading, 
    isSuccess: algorithmSuccess, 
    isError: algorithmError 
  } = useGetAlgorithmStatusQuery({ taskId: String(task.mosaicoId) });
  
  if (!task) {
    return (
      <div className={style.body}>
        <div className={style.contextualButton}>
          <p>La tarea no se encuentra</p>
        </div>
      </div>
    );
  }

  return (
    <div className={style.body}>
      {
        algorithmSuccess && algorithmData.map((alg) => {
          return <AlgoExports key={alg.algo_id} algorithm={alg} taskId={taskId}/>
        })
      }
      {
        algorithmLoading &&
        <Spinner size={SpinnerSize.LARGE}/>
      }
    </div>
  );
}
