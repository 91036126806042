import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { transform } from 'ol/proj';
import React, { useEffect } from 'react';
import { dataProjection, featureProjection } from 'services/configurations';

import MapContext from '../Context/MapContext';
import VectorLayerContext from '../Context/VectorLayerContext';

interface MClickHandlerProps {
  onClick: (coordinates: number[]) => void;
}

/**
 * @description This component will not execute if there is a feature in the clicked pixel in the vector layer
 */
export const MClickHandler = ({ onClick }: MClickHandlerProps) => {
  const map = React.useContext(MapContext);
  const vectorLayer = React.useContext(VectorLayerContext);

  // execute on click
  useEffect(() => {
    if (map) {
      const handleMapClick = (e: any) => {
        const featuresAtPixel = map.getFeaturesAtPixel(e.pixel, {
          hitTolerance: 8,
        });
        if (featuresAtPixel && featuresAtPixel.length > 0) {
          const features = featuresAtPixel as Feature<Geometry>[];
          const source = vectorLayer?.getSource();
            if (source) {
              const vectorLayerFeatures = source.getFeatures();
                const isAnyFeatureInVectorLayer = vectorLayerFeatures.some((feat) =>
                    features.includes(feat),
                );
                
            if (features && isAnyFeatureInVectorLayer) {
              return;
            }
          }
        }

        const clickedCoord = map.getCoordinateFromPixel(e.pixel);
        const transformedCoord = transform(clickedCoord, featureProjection, dataProjection);
        onClick(transformedCoord);
      };
      map.on('click', handleMapClick);
      return () => {
        map.un('click', handleMapClick);
      };
    }
  }, [map, onClick, vectorLayer]);

  return null;
};
