import { useGetPrescriptionsQuery } from 'api/Vistaguay/LotAPI';
import {ReactComponent as RxIcon} from 'assets/icons/prescription.svg';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setPresciptionToShow, setShowPrescription, toggleShowLot } from 'state/slices/mapSlice';
import { setSelectedPrescriptionId } from 'state/slices/uiSlice';

import style from '../../LotContextual.module.scss';


export default function TrashOption({ 
    lotId,
    projectId 
}: { 
    lotId: number,
    projectId: number 
}) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const showLot = useAppSelector((state) => state.map.showLot);
    const showPrescription = useAppSelector((state) => state.map.showPrescription);
    const {selectedPrescriptionId, selectedPrescription} = useAppSelector((state) => state.ui);
    const prescriptionToShow = useAppSelector((state) => state.map.prescriptionToShow);

    const [showRxTable, setShowRxTable] = useState(false);
    // const [toggleSwitch, setToggleSwitch] = useState(false);

    const { 
        data: prescriptionsData 
    } = useGetPrescriptionsQuery({lotId: lotId, projectId: projectId});


    return(
        <div >
            <div className={style.contextualButton}>
                <p>{t('lote')}</p>
                <div className={style.actions}>
                    <SwitchButton
                        value={showLot} 
                        handleOnChange={(status) => dispatch(toggleShowLot())} 
                    />
                </div>
            </div>

            <hr style={{margin: '5px 0 5px 0'}}/>
            
            { prescriptionsData && prescriptionsData.map((p, index) => { return (
                <>
                    <div className={style.contextualButton} key={p.id}>
                        <p>{p.name}</p>
                        <div className={style.actions}>
                            <RxIcon 
                                fill={(p.id == selectedPrescriptionId && showPrescription) ? style.verdeVistaguay : style.gris}
                            />

                            <SwitchButton
                                overlapDisabled
                                value={p.id == selectedPrescriptionId && showPrescription} 
                                handleOnChange={(status) => {
                                    dispatch(setShowPrescription())
                                    dispatch(setPresciptionToShow(index))
                                    dispatch(setSelectedPrescriptionId(p.id))
                                }} 
                            />
                        </div>
                    </div>

                    <hr style={{margin: '5px 0 5px 0'}}/>
                </>
                
            )})}
        </div>
    )
}