import { MappingMission, Mission, ScoutingMission } from 'models/Mission';

import { VistaguayApi, VistaguayApiResponse } from './VistaguayAPI';

interface DifferentialResponseData {
  results: { location: { lat: number; lng: number }; elevation: number }[];
  status?: number;
}
export interface DifferentialRequestData {
  locations: { lat: number; lng: number }[];
}


const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['missions'],
})

const volareApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getDifferentialAltitude: builder.mutation<DifferentialResponseData, DifferentialRequestData>({
      query: (body) => ({
        url: '/volare/differentialAltitude',
        method: 'POST',
        body: body,
      }),
    }),
    getMissions: builder.query<MappingMission[]|ScoutingMission[], {lotId: string , campaignId:string}>({
      query: ({ lotId, campaignId }) => ({
        url: `/volare/mission?lotId=${lotId}&campaignId=${campaignId}`,
        method: 'GET',
      }),
      providesTags: ['missions'],
    }),
    deleteMission: builder.mutation<VistaguayApiResponse<any>, {lotId:string, campaignId: string, id: string}>({
      query: ({lotId,campaignId,id}) => ({
        url: `/volare/${id}?lotId=${lotId}&campaignId=${campaignId}`,
        method: 'DELETE',
        invalidatesTags: ['missions']
      }),
    }),
    getMission: builder.query<MappingMission|ScoutingMission, any>({
      query: (id) => `/volare/mission/${id}`,
      providesTags: ['missions'],
    }),
    editMappingMission: builder.mutation<VistaguayApiResponse<any>, MappingMission>({
      query: (body) => ({
        url: `/volare/mission/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['missions'],
    }),
    addMappingMission: builder.mutation<VistaguayApiResponse<any>, Mission>({
      query: (body) => ({
        url: '/volare/mission',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['missions'],
    }),
    addScoutingMission: builder.mutation<VistaguayApiResponse<any>, Mission>({
      query: (body) => ({
        url: '/volare/mission',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['missions'],
    }),
    editScoutingMission: builder.mutation<VistaguayApiResponse<any>, ScoutingMission>({
      query: (body) => ({
        url: `/volare/mission/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['missions'],
    }),
  }),
});

export const {
  useGetDifferentialAltitudeMutation,
  useGetMissionsQuery,
  useGetMissionQuery,
  useEditMappingMissionMutation,
  useAddMappingMissionMutation,
  useAddScoutingMissionMutation,
  useEditScoutingMissionMutation,
  useDeleteMissionMutation,
} = volareApi;
