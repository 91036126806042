import { useGetCreditQuery, useGetTransactionsMutation } from 'api/Vistaguay/CreditAPI';
import { useGetPricesQuery } from 'api/Vistaguay/PricesAPI';
import { useMeQuery } from 'api/Vistaguay/VistaguayAPI';
import { ReactComponent as CreditCalculatorIcon } from 'assets/icons/credit-calculator.svg'
import { ReactComponent as VipIcon } from 'assets/icons/ico-vip.svg';
import Button from 'components/Button/Button'
import Input from 'components/Input/Input';
import { TRANSACTION_TYPE } from 'models/Transaction';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'state/hooks';
import { setModalGlobalState } from 'state/slices/uiSlice';

import styles from './Credits.module.scss'
import { GoToProps } from './CreditTab';

interface Props {
    goTo: ({page, quantity, vpack}: GoToProps) => void,
}

export default function CreditResume({ goTo }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [amount, setAmount] = useState(5)
    const [getTransactions, { data: transactions, isLoading: transactionsLoading }] = useGetTransactionsMutation();
    const { data: me, isLoading: meLoading } = useMeQuery();
    const [type, setType] = useState<TRANSACTION_TYPE | ''>('');
    const { data: credit, isLoading: creditsLoading } = useGetCreditQuery();
    const isLoading = creditsLoading || transactionsLoading || meLoading
    const { data: pricesData, isLoading: pricesLoading } = useGetPricesQuery();

    useEffect(() => {
        if (me) {
            getTransactions({
                userId: me.usuarioId,
                pageNumber: 1,
                pageSize: 3,
                type: type === '' ? undefined : type,
            });
        }
    }, [getTransactions, me]);

    return (
        <div >
            {isLoading ? (
                <div 
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
                    <Spinner></Spinner>
                </div>
            ) : (
                <div 
                    style={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'space-between'
                    }}
                >
                    {/* TRANSACTION HISTORY */}
                    <div 
                        style={{ 
                            borderBottom: '1px solid gray',
                            height: '100px',
                            overflowY: 'scroll',
                        }}
                    >
                        {   transactions && 
                            transactions.data.length > 0 &&
                            transactions.data.map((transaction) => 
                            {
                                const transactionType = transaction.type === 'DEBIT' ? t('debit') : t('credit')
                                return (
                                    <div 
                                        key={transaction.id} 
                                        style={{
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            justifyContent: 'space-between',
                                            paddingRight: '10px',
                                        }}
                                    >
                                        <p>{transactionType}</p>
                                        
                                        <h5 style={{
                                            margin: '10px 0px',
                                            fontWeight: transaction.type == 'DEBIT' ? 400 : 600
                                        }}>
                                            {transaction.type == 'DEBIT' ? '-' : '+'}
                                            {transaction.amount}
                                        </h5>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/* CURRENT CREDITS */}
                    <div 
                        style={{
                            borderBottom: '1px solid gray',
                            padding: '20px 0px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <div>
                            <p style={{fontWeight: 600}}>Saldo actual</p>
                            <p style={{fontSize: '12px'}}>{t('in-credits')}</p>
                        </div>
                        
                        <h5>{credit?.amount} </h5>
                    </div>

                    {/* BUY CREDITS */}
                    <div 
                        style={{
                            paddingBottom: '20px',
                            borderBottom: '1px solid gray',
                        }}
                    >
                        <div 
                            style={{
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center',
                                padding: '10px 0px',
                            }}
                        >
                            <p style={{fontWeight: 600}}>Cargar creditos</p>
                            <p style={{fontSize: '14px'}}>1 Credito = 1 u$s</p>
                            
                        </div>

                        <input
                        type='number'
                        min={5}
                        max={3000}
                        value={amount}
                        onChange={(e) => {setAmount(+e.target.value)}}
                        style={{
                            outline: 'none',
                            border: '1px solid',
                            borderColor: styles.verdeVistaguay,
                            width: '100%',
                            borderRadius: '4px',
                            padding: '4px 6px'
                        }}
                        />
                    </div>

                    {/* FOOTER BUTTONS */}
                    <div 
                        style={{ 
                            width: '100%',
                            padding: '20px 0px 0px 0px', 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                        }}
                    >

                        <CreditCalculatorIcon 
                            height={'40px'}
                            onClick={() => { goTo({page: 'calculator'}) } }
                        />

                        <VipIcon onClick={() => { goTo({page: 'vpack' })}}/>

                        <div >
                            <p 
                                style={{ 
                                    fontSize: '12px',
                                    fontWeight: '600', 
                                    color: styles.c4, 
                                    textDecoration: 'line-through' 
                                }}
                            >u$s 
                                {pricesData && pricesData?.vpackPrice}
                            </p>

                            <p 
                                style={{ 
                                    fontSize: '12px',
                                    fontWeight: '600' 
                                }}
                            > 
                                u$s {pricesData && pricesData?.vpackPrice - (pricesData?.vpackDiscount * pricesData?.vpackPrice / 100)}
                            </p>
                        </div>
                        
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button 
                                height='30px'
                                width='100%'
                                disabled={amount <= 0} 
                                variant='SUCCESS' 
                                text='Comprar'
                                onClick={() => {goTo({page: 'buy', quantity: amount, vpack: false})}}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}