import clsx from 'classnames'

import styles from './Stepper.module.scss'

interface StepProp {
    isActive?: boolean,
    color?: string
}

export function Step({ isActive = false, color }: StepProp) {
    return (
        <div
            style={{
                background: color
            }}
            className={
                clsx(
                    isActive ? styles.stepactive : styles.stepdisabled
                )
            }>
            <span></span>
        </div>
    )
}

interface StepperProp {
    title: string,
    StepAmount: number,
    StepCurrent: number,
    color?: string
    noHeader?: boolean,
    blur?: boolean
}

export function Stepper({ 
    StepAmount, 
    StepCurrent, 
    title, 
    color, 
    noHeader = false,
    blur
}: StepperProp) {

    if (noHeader) {
        return (
            <div className={(styles.stepper)}>
                <div className={styles.steps} >
                    {
                        [...Array(StepAmount)].map((e, i) => <Step key={i} color={color} isActive={i - 1 < StepCurrent - 1} />)
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={styles.stepper}>
            <div className={styles.stepperheader}>
                <h4 style={{ fontWeight: '600' }}>{title}</h4>
                <p>{`${StepCurrent} de ${StepAmount}`}</p>
            </div>
            <div className={styles.steps} >
                {
                    [...Array(StepAmount)].map((e, i) => <Step key={i} color={color} isActive={i - 1 < StepCurrent - 1} />)
                }
            </div>
        </div>
    )
}