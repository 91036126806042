import {
  useAddProjectMutation,
  useAddProjectToCampaignMutation,
  useEditProjectMutation,
  useGetProjectQuery,
} from 'api/Vistaguay/ProjectAPI';
import { ReactComponent as FolderIcon } from 'assets/icons/ico-folder.svg';
import Button from 'components/Button/Button';
import VistaguayColorPicker from 'components/ColorPicker/VistaguayColorPicker';
import Input from 'components/Input/Input';
import Spinner from 'components/Spinner/Spinner';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import { SubHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import Project from 'models/Project';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';

import { ReactComponent as CalendarIcon } from '../../assets/icons/ico-calendar.svg';
import headerStyles from './../ProjectListPage/ProjectList.module.scss';
import styles from './AddProject.module.scss';


function AddProject() {

  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );

  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectColor, setProjectColor] = useState('#ffffff');
  const [addProjectToCampaign] = useAddProjectToCampaignMutation()
  const [showError,setShowError] = useState(false)
  const [addProject, { data, isLoading, error, isSuccess }] = useAddProjectMutation();
  const [
    editProject,
    { data: dataEdit, isLoading: isLoadingEdit, error: errorEdit, isSuccess: isSuccessEdit },
  ] = useEditProjectMutation();

  const { t } = useTranslation();

  const { data: dataProject, isLoading: isLoadingProject } = useGetProjectQuery(
    projectId ? +projectId : 0,
    {
      skip: !projectId,
    },
  );

  useEffect(() => {
    if (projectId) {
      setProjectName(dataProject?.nombre || '');
      setProjectDescription(dataProject?.descripcion || '');
      setProjectColor(dataProject?.color || '#000');
      
    }



  }, [dataProject, projectId]);


  useEffect(() => {
    if(data) {
      addProjectToCampaign({
        projectId: data.proyectoId,
        campaignId: Number(selectedCampaign?.id)
      })
    }
  },[data,isLoading,addProjectToCampaign])


  function handleAddProject() {
    if (!projectName) {
      setShowError(true)
      return;
    }
    if (!projectId) {
      const project: Partial<Project> = {
        nombre: projectName,
        descripcion: projectDescription,
        color: projectColor,
      };
      addProject(project);

    } else {
      const project: Partial<Project> = {
        proyectoId: +projectId,
        nombre: projectName,
        descripcion: projectDescription,
        color: projectColor,
      };
      editProject(project);
    }
  }

  function handleCancel() {
    navigate(-1);
  }

  useEffect(() => {
    if (isSuccess || isSuccessEdit) {
      navigate('/projects');
    }
  }, [isSuccess, navigate, isSuccessEdit]);

  return (
    <>
 <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
        {(isLoading || isLoadingProject || isLoadingEdit) && <Spinner />}
        
        <SubHeader sx={{ padding: '0px' }} noBack={true}>
          <div className={headerStyles.header}>
            <div className={headerStyles.headerContent}>
              <CalendarIcon height={25}/>

              <div className={headerStyles.headerText}>
                <div className={headerStyles.titleBox}>
                  <h2 className={headerStyles.title}>{selectedCampaign?.name?.split('-')[0] || ''}</h2>
                  <h2 className={headerStyles.title} style={{marginLeft: '5px',fontWeight: '400'}}>-{selectedCampaign?.name?.split('-')[1] || ''}</h2>
                </div>
              </div>
            </div>
          </div>
        </SubHeader>

        <SidebarBody>
          <div className={styles.wrapper}>
            <div>
              <FolderIcon style={{marginBottom: '20px', fill: projectColor, width:'72', height:'55'}}/>
              <h2 className={styles.title}>{t('new-project')}</h2>
            </div>
            
            <Input 
              label={t('name') || ''}
              className={styles.input}
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />

            <div className={styles.formGroup}>
              <p className={styles.label}>
                <span style={{fontWeight: 'bolder'}}>{t('description')}{' '}</span>
                <span className={styles.label}>({t('opcional')})</span>
              </p>

              <textarea
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
                style={{
                  resize: 'none',
                }}
              />
            </div>
           
            <div className={styles.formGroup}>
              <p className={styles.label} style={{fontWeight: 'bolder'}}>
                {t('select-color')}
              </p>

              <VistaguayColorPicker
                color={projectColor}
                onChange={(color) => {
                  setProjectColor(color);
                }}
              />
            </div>
          </div>
        </SidebarBody>

        <SidebarFooter>
          <div className={styles.gridContainer}>
            <Button
              onClick={handleCancel}
              text={t('cancel')}
              variant={'NEUTRAL-GRAY'}
            />
            <Button
              onClick={handleAddProject}
              text={projectId ? t('edit') : t('save')}
              variant={'SUCCESS'}
            />
          </div>
         
        </SidebarFooter>
      </Sidebar>
    </>
  );
}

export default AddProject;
