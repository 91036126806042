import esri from 'assets/icons/esri_logo.svg';
import googlesvg from 'assets/icons/google.svg'
import Label from 'assets/icons/label.svg'
import mapIcon from 'assets/icons/mapIcon.svg'
import openStreetmap from 'assets/icons/openStreetmap.svg';
import { Map, View } from 'ol';
import { Control, defaults as defaultControls, ScaleLine } from 'ol/control';
import { MultiPoint, Polygon as OlPolygon } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import OlVectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import { XYZ } from 'ol/source';
import SourceOSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { useEffect, useRef, useState } from 'react';
import { dataProjection, featureProjection } from 'services/configurations';

import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setIsMapLoaded } from '../../state/slices/mapSlice';
import { ButtonsMap } from './buttonsmap';
import MMeasuress from './Components/Measure/Measures';
import MMeasureTool from './Components/Measure/MMeasureTool';
import MGeolocateTool from './Components/MGeolocateTool';
import MMousePosition from './Components/MMousePosition';
import MVectorLayer from './Components/MVectorLayer';
import MapContext from './Context/MapContext';
import styles from './Map.module.scss';
import { CustomScaleControl } from './scaleControl';
import SwitcherButton from './SwitcherButton';
const attributions =
  '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
  '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';

interface MapWrapperProps {
  children?: JSX.Element[] | JSX.Element;
  visible?: boolean;
}

function MapWrapper({ children, visible = true }: MapWrapperProps) {
  const dispatch = useAppDispatch();
  const mapElement = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);

  if (!visible) {
    return (
      <>
        {children}
      </>
    )
  }

  useEffect(() => {

    if (mapElement.current) {

      const baseMapSat1 = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
          maxZoom: 18
        })
      })

      const baseMapSat2 = new TileLayer({
        source: new XYZ({
          attributions: attributions,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          maxZoom: 18,
        }),

      });

      const labellayer = new TileLayer({
        source: new XYZ({
          attributions: attributions,
          url: 'https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}',
          maxZoom: 18,
        }),
         visible: false,
      });
    
    
      const baseMapStd = new TileLayer({
        source: new SourceOSM(),
      });

      const styles = [
        new Style({
          fill: new Fill({
            color: 'rgba(0, 255, 0, 0.5)',
          }),
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2,
          }),
        }),
        new Style({
          image: new Circle({
            radius: 5,
            fill: new Fill({
              color: '#ffcc33',
            }),
          }),
          geometry: function (feature) {
            // return the coordinates of all rings of the polygon
            const geometry: OlPolygon | undefined = feature.getGeometry() as OlPolygon;
            if (geometry) {
              const coordinates = geometry.getCoordinates()[0];
              return new MultiPoint(coordinates);
            }
          },
        }),
      ];
      const initialLayer = new OlVectorLayer({
        source: new VectorSource(),
        style: styles,
      });
      // Create the map
      const mapOl = new Map({
        target: mapElement.current,
        layers: [baseMapSat1, baseMapStd, baseMapSat2, initialLayer, labellayer],
        view: new View({
          projection: featureProjection,
          center: transform([-64.22121104148516, -31.4297386063948], dataProjection, featureProjection),
          zoom: 6,
        }),
      });
      setMap(mapOl);
      // Custom layer switcher control
      const switcherElement = document.createElement('div');
      switcherElement.className = 'ol-control layer-switcher';
      switcherElement.innerHTML = `
        <button id='openmodal'><img src="${mapIcon}" alt="Switch layer"/></button>
        <div id='modal'>
          <button id='baseMapSat1' class='selected'><img src="${googlesvg}" alt="Switch layer"/>Google Satélite  <input type="radio" name="size" id="small" checked></button>
          <button id='baseMapSat2'><img src="${esri}" alt="Switch layer"/>ESRI Satélite <input type= "radio" name="size" id="small"></button>
          <button id='baseMapStd'><img src="${openStreetmap}" alt="Switch layer"/>OSM Estándar <input type= radio name="size" id="small"></button>
          <button id='labellayer'><img src="${Label}" alt="Switch layer"/>Etiquetas</button>
          </div>
      `;
      
      const modal = switcherElement.querySelector('#modal');
      const openModalButton = switcherElement.querySelector('#openmodal');
      
      // Inicialmente el modal está cerrado
      if (modal) {
        modal.classList.add('layer-switcher-modal-close');
      }
      
      baseMapSat2.setVisible(false);
      baseMapStd.setVisible(false);
      
      const baseMapSat1Btn = switcherElement.querySelector('#baseMapSat1');
      const baseMapStdBtn = switcherElement.querySelector('#baseMapStd');
      const baseMapSat2Btn = switcherElement.querySelector('#baseMapSat2');
      const labellayerBtn = switcherElement.querySelector('#labellayer');

      baseMapSat1Btn?.addEventListener('click', () => {
        baseMapStdBtn?.classList.remove('selected');
        baseMapSat2Btn?.classList.remove('selected');
        baseMapSat1Btn.classList.add('selected');
        baseMapSat2.setVisible(false);
        baseMapStd.setVisible(false);
        baseMapSat1.setVisible(true);
        
        
        const radio = baseMapSat1Btn.querySelector('input[type="radio"]') as HTMLInputElement;
        if (radio) {
          radio.checked = true;
        }
        const mousePositionElement = document.querySelector('.custom-mouse-position');
        if (mousePositionElement) {
          mousePositionElement.classList.remove('black');
        }

        const customScaleLineElement = document.querySelector('.ol-scale-line');
        if (customScaleLineElement) {
          customScaleLineElement.classList.remove('black');
        }
      });

      

      labellayerBtn?.addEventListener('click', () => {
        // Verificar si el botón ya tiene la clase 'selected'
        const isSelected = labellayerBtn?.classList.contains('selected');
        if (isSelected) {
          labellayerBtn?.classList.remove('selected');
          labellayer.setVisible(false);
        } else {
          labellayerBtn?.classList.add('selected');
          labellayer.setVisible(true);
        }
      });
      
      
      baseMapStdBtn?.addEventListener('click', () => {
        baseMapSat1Btn?.classList.remove('selected');
        baseMapSat2Btn?.classList.remove('selected');
        baseMapStdBtn.classList.add('selected');
        baseMapSat2.setVisible(false);
        baseMapStd.setVisible(true);
        baseMapSat1.setVisible(false);

        const radio = baseMapStdBtn.querySelector('input[type="radio"]') as HTMLInputElement;
        if (radio) {
          radio.checked = true;
        }
        const mousePositionElement = document.querySelector('.custom-mouse-position');
        if (mousePositionElement) {
          mousePositionElement.classList.add('black');
        }

        const customScaleLineElement = document.querySelector('.ol-scale-line');
        if (customScaleLineElement) {
          customScaleLineElement.classList.add('black');
        }
      });
      
      baseMapSat2Btn?.addEventListener('click', () => {
        baseMapStdBtn?.classList.remove('selected');
        baseMapSat1Btn?.classList.remove('selected');
        baseMapSat2Btn.classList.add('selected');
        baseMapSat2.setVisible(true);
        baseMapStd.setVisible(false);
        baseMapSat1.setVisible(false);

        const radio = baseMapSat2Btn.querySelector('input[type="radio"]') as HTMLInputElement;
        if (radio) {
          radio.checked = true;
        }

        const mousePositionElement = document.querySelector('.custom-mouse-position');
        if (mousePositionElement) {
          mousePositionElement.classList.remove('black');
        }

        const customScaleLineElement = document.querySelector('.ol-scale-line');
        if (customScaleLineElement) {
          customScaleLineElement.classList.remove('black');
        }
      });
      
      openModalButton?.addEventListener('click', () => {
        if (modal) {
          // Alterna entre abierto y cerrado usando toggle
          modal.classList.toggle('layer-switcher-modal-open');
          modal.classList.toggle('layer-switcher-modal-close');
          openModalButton.classList.toggle('active');

        }
      });
      
      const switcherControl = new Control({ element: switcherElement });
      mapOl.addControl(switcherControl);
      
      dispatch(setIsMapLoaded(true));
      
      return () => {
        switcherElement && switcherElement.remove();
        mapOl.setTarget(undefined);
        setMap(null);
        dispatch(setIsMapLoaded(false));
      };
      
    }
  }, [dispatch]);



  return (
    <>
      <div id='popup' />
      <div className={styles.mapContainer} ref={mapElement}>
        <MapContext.Provider value={map}>
        {children}
        <MVectorLayer zIndex={1}>
            <ButtonsMap />
          </MVectorLayer>
          <MVectorLayer zIndex={1}>
          <div>
            <div>
              <MMousePosition projection={dataProjection} />  
            </div>
            <div>
              <CustomScaleControl map={map} />  
            </div>
          </div> 
          </MVectorLayer>
        </MapContext.Provider>
      </div>
    </>
  );
}

export default MapWrapper;
