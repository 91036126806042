// EmailAutosuggest.tsx
import './EmailInput.scss';

import React, { FormEvent,useState } from 'react';
import Autosuggest, {
  BlurEvent,
  ChangeEvent as AutosuggestChangeEvent,
  InputProps as AutosuggestInputProps,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';

interface EmailSuggestion {
  value: string;
}

interface EmailAutosuggestProps {
    handleChange: (newValue: string) => void;
}

// Regular expression for validating email
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Retrieve stored emails from local storage
const getStoredEmails = (): string[] => {
  return JSON.parse(localStorage.getItem('emailHistory') || '[]');
};

// Save email to local storage
const saveEmailToStorage = (email: string): void => {
  if (emailRegex.test(email)) {
    const storedEmails = getStoredEmails();
    if (!storedEmails.includes(email)) {
      storedEmails.push(email);
      localStorage.setItem('emailHistory', JSON.stringify(storedEmails));
    }
  }
};

// Teach Autosuggest how to calculate suggestions
const getSuggestions = (value: string): EmailSuggestion[] => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : getStoredEmails().filter(
        (email) => email.toLowerCase().slice(0, inputLength) === inputValue
      ).map((email) => ({ value: email }));
};

// When suggestion is selected, Autosuggest needs to populate the input field
const getSuggestionValue = (suggestion: EmailSuggestion): string => suggestion.value;

// Render each suggestion
const renderSuggestion = (suggestion: EmailSuggestion): JSX.Element => (
  <div>{suggestion.value}</div>
);

const EmailAutosuggest: React.FC<EmailAutosuggestProps> = ({
    handleChange
}) => {
  const [value, setValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<EmailSuggestion[]>([]);
  const [isSuggestionOpen, setIsSuggestionOpen] = useState<boolean>(false);

  const onChange = (event: FormEvent<HTMLElement>, { newValue }: AutosuggestChangeEvent) => {
    setValue(newValue);
    handleChange(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams) => {
    setSuggestions(getSuggestions(value));
    setIsSuggestionOpen(true); // Show suggestions when fetching
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    setIsSuggestionOpen(false); // Hide suggestions when clearing
  };

  const onSuggestionSelected = (
    event: FormEvent<any>,
    { suggestionValue }: SuggestionSelectedEventData<EmailSuggestion>
  ) => {
    saveEmailToStorage(suggestionValue);
  };

  // Updated onBlur to match Autosuggest's expected type
  const onBlur = (event: React.FocusEvent<HTMLElement>, params?: BlurEvent<EmailSuggestion>) => {
    if (value.trim()) {
      saveEmailToStorage(value.trim());
    }
    setIsSuggestionOpen(false); // Hide suggestions when losing focus
  };

  const inputProps: AutosuggestInputProps<EmailSuggestion> = {
    // placeholder: 'Enter your email',
    value,
    onChange,
    onBlur,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={onSuggestionSelected}
    //   theme={{
    //     suggestionsContainerOpen: isSuggestionOpen ? 'react-autosuggest__suggestions-container--open' : 'react-autosuggest__suggestions-container',
    //   }}
    />
  );
};

export default EmailAutosuggest;
