import { method } from 'lodash';
import {Algorithm, HistogramStyle} from 'models/Algorithm';
import { Cameras } from 'models/Cameras';
import MosaicTask from 'models/MosaicTask';

import { VistaguayApi } from './VistaguayAPI';

export const addToFormData = (formData: FormData, key: string, value?: any) => {
  if (value) {
    formData.append(key, value);
  }
};

export interface PhotoMetadata {
  fileName: string;
  lat: number;
  lng: number;
  alt: number;
}
export interface AlgoritmMetadata {
  name: string;
  extened: number[];
}

export interface SaveAlgoStyleRequest extends HistogramStyle {
  mosaicId: number,
  layer: string,
}

export interface IMosaicFilterQuery {
  userId?: string;
  username?: string,
  name?:string;
  mosaicTaskId?: string;
  status?:string;
  page?: string;
  limit?: string;
  createdAfter?: string;
  createdBefore?: string;
}

export interface WmsData {
  url: string;
  layer: string;
  algorithm: string;
  gdalInfo: IGdalInfo;
}

export interface WmsObject {
  [key: string]: WmsData;
}

export interface MosaicMetadata {
  uploadMetadata: PhotoMetadata[];
  uploadedFiles: string[];
  vari: AlgoritmMetadata;
  mosaico: AlgoritmMetadata;
  ndwig: AlgoritmMetadata;
  dem: AlgoritmMetadata;

}

export interface IGdalInfo {
  description: string;
  driverShortName: string;
  driverLongName: string;
  files: string[];
  size: [number, number];
  coordinateSystem: {
    xOrigin: number;
    yOrigin: number;
    pixelWidth: number;
    pixelHeight: number;
  };
  geoTransform: [number, number, number, number, number, number];
  metadata: any;
  cornerCoordinates: {
    upperLeft: [number, number];
    lowerLeft: [number, number];
    lowerRight: [number, number];
    upperRight: [number, number];
    center: [number, number];
  };
  wgs84Extent: {
    type: string;
    coordinates: number[][];
  };
  bands: {
    band: number;
    block: [number, number];
    type: string;
    colorInterpretation: string;
    noDataValue: number;
    nodataValue: number;
    description: string;
    min: number;
    max: number;
    mean: number;
    stdDev: number;
    histogram: {
      count: number;
      min: number;
      max: number;
      buckets: number[];
    };
    unit: string;
    units: string;
    scale: number;
    offset: number;
    origin: number;
    nodata: number;
  }[];
}

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Mosaic', 'MosaicWms','Algorithm'],
});

interface Iparams {
  [key: string]: string;
}

const buildParamsQueryUrl = (params: Iparams) => {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    queryParams.append(key, value);
  });
  return queryParams.toString();
};


const buildParamsQueryUrlFilters = (params: IMosaicFilterQuery) => {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    queryParams.append(key, value);
  });
  return queryParams.toString();
};


const mosaicApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getMosaic: builder.query<MosaicTask, any>({
      query: ({ taskId }: { taskId: string }) => `mosaic/${taskId}`,
      providesTags: ['Mosaic'],
    }),
    // startCount: builder.mutation({
    //   query: ({ taskId }: { taskId: string }) => {
    //     return { url: `task/count/${taskId}/start`, method: 'POST', body: {} };
    //   },
    // }),
    getMosaicAlgorithmStatus: builder.query({
      query: ({ taskId }: { taskId: string }) => `mosaic/${taskId}/algorithms`,
    }),
    getMosaicMetadata: builder.query<MosaicMetadata, {taskId: string}>({
      query: ({ taskId }: { taskId: string }) => `mosaic/${taskId}/photo/metadata`,
      providesTags: ['Mosaic'],
    }),
    getMosaicResultMetadata: builder.query<string[], {taskId:string}>({
      query: ({ taskId }: { taskId: string }) => `mosaic/${taskId}/result/metadata`,
      providesTags: ['Mosaic'],
    }),
    getMosaics: builder.query<MosaicTask[],  { campaignId: string; lotId: string }>({
      query: ({ campaignId, lotId }: { campaignId: string; lotId: string }) =>
        `mosaic/generic?${buildParamsQueryUrl({ campaignId, lotId })}`,
      providesTags: ['Mosaic'],
    }),
    getTiff: builder.query({
      query: ({ taskId }: { taskId: string }) => `task/${taskId}/mosaic/${'al'}`,
      transformResponse: async (response: Response) => await response.blob(),
    }),
    getFile: builder.query({
      query: ({taskId,filename}: { taskId: string, filename: string }) => `mosaic/${taskId}/result/${filename}`,
      transformResponse: async (response: Response) => await response.blob(),
    }),
    getPng: builder.query({
      query: ({ taskId }: { taskId: string }) => 'task/png',
      transformResponse: async (response: Response) => await response.blob(),
    }),

    getWms: builder.query<WmsObject, any>({
      query: ({ taskId }: { taskId: string }) => `mosaic/${taskId}/wms`,
      providesTags: ['MosaicWms'],
    }),
    generateWms: builder.mutation({
      query: ({ taskId }: { taskId: string }) => {
        return { url: `mosaic/${taskId}/wms`, method: 'POST' };
      },
      invalidatesTags: ['MosaicWms'],
    }),
    addMosaic: builder.mutation({
      query: ({
        mosaic,
        projectId,
        lotId,
        campaignId,
      }: {
        mosaic: Partial<MosaicTask>;
        projectId?: string;
        lotId?: string;
        campaignId?: number;
      }) => {
        const formData = buildFormForMosaic(mosaic, campaignId, lotId, projectId);
        return {
          url: 'mosaic/count',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Mosaic'],
    }),
    getMosaicSummary: builder.query<any,any>({ 
      query: () => {
        return {
          url: 'mosaic/admin/summary',
          method: 'GET',
        };
      },
      providesTags: ['Mosaic'],
    }),
    getFilteredMosaics: builder.query<MosaicTask[] | [], IMosaicFilterQuery>({
      query: (filters: IMosaicFilterQuery) => {
        const params: Iparams = {
          name: filters.name || '',
          username: filters.username || '',
          userId: filters.userId || '',
          mosaicTaskId: filters.mosaicTaskId || '',
          status: filters.status?.toString() || '',
          page: filters.page || '',
          limit: filters.limit || '',
          createdAfter: filters.createdAfter || '',
          createdBefore: filters.createdBefore || '',
        };


        return {
          url: `mosaic/admin/all?${buildParamsQueryUrl(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['Mosaic'],
    }),
    updateStatusMosaic: builder.mutation({
      query: ({ taskId, status }: { taskId: string; status: number }) => {
        return {
          url: `mosaic/${taskId}/status`,
          method: 'PATCH',
          body:{
            status:status
          },
        };
      },
      invalidatesTags: ['Mosaic'],
    }),
    reprocess: builder.mutation({
      query: ({ taskId }: { taskId: string }) => {
        return {
          url: `mosaic/${taskId}/public/generatewms`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Mosaic'],
    }),
    getAlgorithmStatus: builder.query<Algorithm[],{taskId: string}>({
      query: ({ taskId }: { taskId: string }) => `algorithm/mosaico/${taskId}`,
      providesTags:['Algorithm']
    }),
    deleteMosaicFiles: builder.mutation({
      query: ({taskId} : { taskId: string}) => {
        return {
          method: 'DELETE',
          url: `mosaic/${taskId}/deleteFiles`
        }
      }
    }),
    restartAlgorithm: builder.mutation({
      query: ({taskId,algoId} : {taskId:string,algoId:string}) => {
        return {
          method: 'PATCH',
          url: `mosaic/${taskId}/${algoId}/restart`
        }
      },
      invalidatesTags: ['Algorithm']
    }),
    checkCameraExist: builder.mutation<Cameras, {cameraBrand:string,cameraModel:string}>({
      query: (body: {cameraBrand:string,cameraModel:string}) => {
        return {
          method:'POST',
          url: 'mosaic/cameras',
          body: body,
        }
      }
    }),
    addNewCamera: builder.mutation<Cameras,Partial<Cameras>>({
      query: (body: Partial<Cameras>) => {
        return {
          method:'POST',
          url: 'mosaic/cameras/add',
          body: body
        }
      }
    }),
    saveAlgorithmStyle: builder.mutation({
      query: ({mosaicId,algoName,body}: {mosaicId: number,algoName: string ,body: HistogramStyle}) => {
        return {
          method:'POST',
          url: `algorithm/${mosaicId}/${algoName}`,
          body: body
        }
      },
      invalidatesTags: ['Algorithm']
    }),
    getAlgorithmStyle: builder.query<HistogramStyle, {mosaicId: number,algoName: string}>({
      query: (body) => {
        return {
          method: 'GET',
          url: `algorithm/${body.mosaicId}/${body.algoName}`
        }
      }
    }),
    updateMosaicUser: builder.mutation({
      query: ({mosaicId,mosaic}: {mosaicId:number,mosaic:MosaicTask}) => {
        return {
          method:'POST',
          url: `mosaic/${mosaicId}/update`,
          body: mosaic
        }
        
      },
      invalidatesTags:['Mosaic']
    }),
    mosaicDelete: builder.mutation({
      query: ({mosaicId}: {mosaicId:number}) => {
        return {
          method:'DELETE',
          url: `mosaic/${mosaicId}`,
        }        
      },
      invalidatesTags:['Mosaic']
    })
  }),
});

export const {
  useUpdateMosaicUserMutation,
  useGetMosaicQuery,
  useGetMosaicsQuery,
  useGetMosaicMetadataQuery,
  useGetMosaicResultMetadataQuery,
  useGetTiffQuery,
  useGetPngQuery,
  useGetWmsQuery,
  useGenerateWmsMutation,
  useGetMosaicSummaryQuery,
  useGetFilteredMosaicsQuery,
  useGetMosaicAlgorithmStatusQuery,
  useGetFileQuery,
  useUpdateStatusMosaicMutation,
  useReprocessMutation,
  useGetAlgorithmStatusQuery,
  useDeleteMosaicFilesMutation,
  useRestartAlgorithmMutation,
  useCheckCameraExistMutation,
  useAddNewCameraMutation,
  useSaveAlgorithmStyleMutation,
  useGetAlgorithmStyleQuery,
  useMosaicDeleteMutation,
} = mosaicApi;


function buildFormForMosaic(
  mosaic: Partial<MosaicTask>,
  campaignId: number | undefined,
  lotId: string | undefined,
  projectId: string | undefined,
) {
  const formData = new FormData();
  formData.append('campaignId', String(campaignId));
  formData.append('lotId', String(lotId));
  formData.append('projectId', String(projectId));

  formData.append('name', mosaic.nombre?.toString() ?? '');
  formData.append('nombre', mosaic.nombre?.toString() ?? '');

  formData.append('fechaVuelo', mosaic?.fechaVuelo || '');
  formData.append('fechaSiembra', mosaic.sowDate?.toISOString() || '');

  formData.append('cuitCliente', mosaic.clientCUIT?.toString() ?? '');

  formData.append('drone', mosaic.drone?.toString() ?? '');
  formData.append('pilot', mosaic.pilot?.toString() ?? '');

  // formData.append('sowDate', countTask.sowDate?.toISOString() || '');

  // pilot: string;
  // drone: string;

  // mosaicoId?: number;

  // estadio?: string;
  // resolucion?: number;
  // fechaSiembra?: string;
  // alturaVuelo?: number;
  // dSurco?: number;
  // cultivo?: string;
  // superficie?: number;
  // estadoId?: number;
  // fechaUpdate?: Date;
  // tipoCamara?: string;
  // deletedAt?: Date;
  // hibrido?: string;
  // cultivoId?: number;
  // estadioId?: number;
  // hibridoId?: number;
  // tipoMosaicoId?: number;
  // nImagenes?: number;
  // creator: User;
}
