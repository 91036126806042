import React, { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
  disabled?: boolean
}

function ColorPicker({
  color,
  onChange,
}: ColorPickerProps) {
  const [selectedColor, setSelectedColor] = useState(color);

  const handleChange = (color: ColorResult, e: React.ChangeEvent<HTMLInputElement>) => {
    
    const newColor = color.hex;
    setSelectedColor(newColor);
    onChange(newColor);
  };

  return (<SketchPicker disableAlpha onChange={handleChange} color={selectedColor} />)
}

export default ColorPicker;
