import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';
import 'ol/ol.css';
import 'i18n';
import 'typeface-roboto';

import React, { useContext } from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import store from './state/store';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <Provider store={store}>
    <App/>
  </Provider>,
);


