import { GeoJSON } from 'models/GeoJSON';
import Feature, {FeatureLike} from 'ol/Feature';
import GeoJSONopenLayer from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import RenderFeature from 'ol/render/Feature';
import React, { useContext, useEffect } from 'react';
import { dataProjection, featureProjection } from 'services/configurations';

import VectorLayerContext from '../Context/VectorLayerContext';

interface GeojsonLayerProps {
  geojson: GeoJSON;
}

export default function MGeoJson({ geojson }: GeojsonLayerProps) {
  const vectorLayer = useContext(VectorLayerContext);
  useEffect(() => {
    const geoJSONFormat = new GeoJSONopenLayer({
      dataProjection: dataProjection,
      featureProjection: featureProjection,
    });
    const source = vectorLayer?.getSource();
    if (geojson?.type === 'FeatureCollection') {
      const feats : FeatureLike[] | RenderFeature[] = geoJSONFormat.readFeatures(geojson);
      if (source) {

        source.addFeatures(feats as unknown as Feature<Geometry>[]);
      }
      return () => {
        if (source) {
          feats.forEach((feat) => source.removeFeature(feat as Feature<Geometry> ));
        }
      };
    } else if (geojson?.type === 'Feature') {
      const feat = geoJSONFormat.readFeature(geojson);

      if (source) {
        source.addFeature(feat as Feature<Geometry>);
      }

      return () => {
        if (source) {
          source.removeFeature(feat as Feature<Geometry>);
        }
      };
    }
  }, [geojson, vectorLayer]);

  return <></>;
}
