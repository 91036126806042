export const LOT_COLOR = '#0000FF';

export const POLYGON_ALPHA = 0.0;

export const NEW_POLYGON_COLOR = '#0000FF';

export const POLYGON_OUTER_VERTEX_COLOR = 'white';
export const POLYGON_INNER_VERTEX_COLOR = '#19A3FF';

export const MISSION_POLYGON_COLOR = '#FF00FF';

export const BLACK_LINE = 'rgba(25, 25, 25, 0.9)';

export const LINE = 'rgba(0, 0, 0)';