import clsx from 'classnames'
import Tooltip from 'components/Tooltip/Tooltip'

import styles from './Stepper.module.scss'

interface StepProp {
    color?: string,
    index: number,
    StepAmount: number,
    StepCurrent: number
    icon: any,
    stepName: string
}

export function Step({ color, index, StepAmount, StepCurrent, icon, stepName }: StepProp) {

    return (
        <div className={styles.stepWrapper}>
            <Tooltip key={index} text={stepName}>
                <div className={styles.stepDot}>
                    <div
                        style={{
                            background: color
                        }}
                        className={
                            clsx(
                                styles.stepDot,
                                styles.dot,
                                (index <= StepCurrent && index < StepAmount) ? styles.stepactive : styles.stepdisabled
                            )
                        }>
                        {/* {icon} */}
                    </div>
                </div>
            </Tooltip>
            
            {
                (index !== StepAmount) && (
                    <div className={
                        clsx(
                            styles.stepperLine,
                            (index < StepCurrent) ? styles.stepactive : styles.stepdisabled
                        )}
                    />
                )
            }
        </div>
    )
}

interface Step {
    index: number,
    step: any,
    icon: any,
    stepName: string
}

interface StepperProp {
    StepAmount: number,
    StepCurrent: number,
    steps: Array<any>,
    color?: string
    noHeader?: boolean,
    blur?: boolean
}

export function Stepper2({ 
    StepAmount, 
    StepCurrent, 
    color, 
    blur,
    steps
}: StepperProp) {

    return (
        <div className={styles.stepper}>
            <div className={styles.steps}>
                {
                    steps.map((step: Step) => <Step key={step.index} index={step.index} color={color} StepAmount={StepAmount} StepCurrent={StepCurrent} icon={step.icon} stepName={step.stepName}/>)
                }
            </div>
        </div>
    )
}
