import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData'
import { useAddNewCameraMutation } from 'api/Vistaguay/MosaicAPI'
import Alert from 'components/Alert/Alert'
import { Input, InputSelect } from 'components/Inputs/Input'
import { Cameras } from 'models/Cameras'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { cuitPatternMatch } from 'services/helpers'

import style from '../../AddTask.module.scss';
import { ErrorList, MosaicFormProps, MosaicStepProps, MosaicStepValidate } from '../AddMosaic'


export default function StepSowing({ 
    form, 
    setForm, 
    handleError,
    step,
    currentStep,
    nextStepTouched
}: MosaicStepProps) {
    const extraData = useGetExtraDataQuery().data
    const navigate = useNavigate()
    const { t } = useTranslation();

    const [errorsList, setErrorList] = useState<ErrorList[]>([]);

    useEffect(() => {
        setForm({...form, taxIdType: 'CUIT'});
    }, [])
      
    useEffect(() => {
        if(currentStep == step) {
          const errors: ErrorList[] = [];
          let error = true; 
            
          if (!form.cultivoId) {
            errors.push({ message: t('form-crops-required'), propName: 'crops' });
          } else if (!form.hibridoId) {
            errors.push({ message: t('form-phenological-stage-required'), propName: 'phenological-stage' });
          } else if (!form.sowDate) {
            errors.push({ message: t('form-seeding-date-required'), propName: 'seeding-date' });
          } else if (new Date(form.sowDate) > new Date()) {
            errors.push({ message: t('form-seeding-date-error'), propName: 'seeding-date' });
          }
          
          error = errors.length > 0 ?? true;
        
          setErrorList(errors);
          handleError(error);
        }
    }, [form.cultivoId, form.sowDate])

    return (
        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {/* CULTIVO */}
            <div>
                <label>{t('crops')}</label>
                <InputSelect
                    placeholder={t('crops')}
                    value={form.cultivoId ? form.cultivoId : ''}
                    onChange={(e) => setForm({ ...form, cultivoId: +e.target.value, hibridoId: undefined, estadioId: undefined })}
                >
                    { extraData?.crops.map(crop => <option key={crop.id} value={crop.id}>{crop.name}</option>)}
                    <option value={''}></option>
                </InputSelect>

                { nextStepTouched && errorsList.map((error, index) => {
                    if (error.propName === 'crops') {
                        return <Alert key={index} type='error' text={error.message} />;
                    } else {
                        return null;
                    }
                })}
            </div>

            {/* GENOTIPO */}
            <div>
                <label>
                    {t('genotype')}
                    <span className={style.smallLegend}>({t('opcional')})</span>
                </label>
                <InputSelect
                    placeholder={t('genotype')}
                    value={form.hibridoId ? form.hibridoId : ''}
                    onChange={(e) => setForm({ ...form, hibridoId: +e.target.value })}
                >
                    {extraData?.crops.find(crop => crop.id === form.cultivoId)?.hybrids.map(hybrid => <option key={hybrid.id} value={hybrid.id}>{hybrid.name}</option>)}
                    <option value={''}> </option>
                </InputSelect>
            </div>

            {/* ESTADIO FENOLOGICO */}
            <div>
                <label>{t('phenological-state')}</label>
                <InputSelect
                    placeholder={t('phenological-state')}
                    value={form.estadioId ? form.estadioId : ''}
                    onChange={(e) => setForm({ ...form, estadioId: +e.target.value })}
                >
                    {extraData?.crops.find(crop => crop.id === form.cultivoId)?.stages.map(stage => <option key={stage.id} value={stage.id}>{stage.name}</option>)}
                    <option value={''}> </option>
                </InputSelect>
                { nextStepTouched && errorsList.map((error, index) => {
                    if (error.propName === 'phenological-stage') {
                        return <Alert key={index} type='error' text={error.message} />;
                    } else {
                        return null;
                    }
                })}
            </div>

            {/* FECHA SIEMBRA */}
            <div>
                <label>{t('form-seeding-date')}</label>
                <Input
                    placeholder={t('form-seeding-date')}
                    type='date'
                    value={form.sowDate == undefined ? undefined : form.sowDate.toISOString().split('T')[0]}
                    onChange={(e) => setForm({ ...form, sowDate: new Date(e.target.value) })}
                />
                { nextStepTouched && errorsList.map((error, index) => {
                    if (error.propName === 'seeding-date') {
                        return <Alert key={index} type='error' text={error.message} />;
                    } else {
                        return null;
                    }
                })}
            </div>

            {/* === INFO TRIBUTARIA ===*/}
            <div style={{marginBottom: '10px'}}>
                <label htmlFor=''>
                {t('tax-id')} 
                <span className={style.smallLegend}>({t('opcional')})</span>
                </label>
                <div className={style.taxIdWrapper}>
                <Input
                    id={t('tax-id').toString()}
                    placeholder= {t('tax-id')}
                    maxLenght={13}
                    type='text'
                    value={form.clientCuit}
                    onChange={(e) => {
                        let taxId = e.target.value;
                        if(form.taxIdType === 'CUIT') {
                          taxId = cuitPatternMatch(e.target.value, 'xx-xxxxxxxx-x');
                        }
                        setForm({ ...form, clientCuit: taxId})}}
                    onKeyPress={(event) => {
                        if (event.key == 'Backspace') {
                          return;
                        } else if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                    }}
                />
                <InputSelect
                    id={'tax-id-type'}
                    placeholder=''
                    options={[{value:'CUIT', name: 'CUIT'}]}
                    value={form?.taxIdType || ''}
                    className={style.taxIdTypeSelect}
                    onChange={(e) => {setForm({ ...form, taxIdType: e.target.value})}}
                >
                    <option value={''} disabled></option>
                </InputSelect>
                </div>
                
            </div>
        </div>
    )
}