export class Campaign {
  id: number;
  name: string;
  begin: Date;
  end: Date;
  createdAt: Date;
  deletedAt: Date;
  

  constructor(id: number, name: string, begin: Date, end: Date, createdAt: Date, deletedAt: Date) {
    this.id = id;
    this.name = name;
    this.begin = begin;
    this.end = end;
    this.createdAt = createdAt;
    this.deletedAt = deletedAt;
  }

  static getSelectedCampaignFromLocalStorage(): Campaign | null {
    const camapignStr = localStorage.getItem('selectedCampaign');
    if(camapignStr == null) {
      return null
    }
    return JSON.parse(camapignStr);
  }
  static setSelectedCampaignFromLocalStorage(campaign: Campaign) {
    localStorage.setItem('selectedCampaign', JSON.stringify(campaign));
  }
}
