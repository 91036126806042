import clsx from 'classnames';

import styles from './CheckBox.module.scss'

interface CheckBoxProps {
    id?: string | undefined;
    sx?: React.CSSProperties;
    name?:string;
    value?:string | number ;
    label?:string;
    checked?:boolean;
    disabled?: boolean;
    onChange?: ( e : React.ChangeEvent<HTMLInputElement> ) => void | undefined,
    onClick?: ( e : React.MouseEvent<HTMLInputElement, MouseEvent> ) => void | undefined,
}

export function CheckBox ({
    id,
    sx,
    checked,
    disabled,
    name = '',
    value = '',
    label = '',
    onChange,
    onClick
} : CheckBoxProps) {
    return (
        <div style={sx} className={styles.checkboxwrapper}>
            
            <span style={{display:'flex',gap:'5px'}}>
                {label}
                <div className={clsx(
                    styles.outerCheckbox,
                    !checked ? styles.unchecked : styles.checked
                )}>
                    <input 
                        id={id} 
                        onClick={onClick} 
                        onChange={onChange} 
                        checked={checked} 
                        name={name} value={value} 
                        className={styles.checkbox} 
                        type="radio"
                        disabled={disabled}
                    />
                </div>
            </span>
        </div>
    )
}