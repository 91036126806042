import { useGetMosaicResultMetadataQuery } from 'api/Vistaguay/MosaicAPI';
import MarkerIcon from 'assets/icons/ico-camara.svg';
import MPoint from 'components/Map/Components/MPoint';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import { useParams } from 'react-router-dom';

export default function PhotoPointsMosaic({taskId} : {taskId:string}) {
    const { projectId, lotId } = useParams<{ projectId: string; lotId: string }>();

    if (!projectId || !lotId) {
      return null;
    }

    const {data: photosMetadata} = useGetMosaicResultMetadataQuery({taskId:taskId})

    return (
      <>
       {photosMetadata &&
          <MVectorLayer>
            {
              photosMetadata.map((photoMetadata: any, i: number) => {
                return (
                  <MPoint 
                    marker={MarkerIcon}
                    circle={true}
                    color={'#807e7e'} 
                    zIndex={70}  
                    onLoadCenter={true} 
                    key={i} 
                    lat={+photoMetadata.GPSLatitude}
                    lng={+photoMetadata.GPSLongitude}
                  />
                )
              })
            }
          </MVectorLayer>
        }
      </>
    )
}