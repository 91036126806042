import { ChangeEvent, useState } from 'react';

interface IFileUploadProp {
  callbackFileChange: (file: File) => void;
  hidden?: boolean;
}

export const FileUpload = ({ callbackFileChange, hidden = true }: IFileUploadProp) => {
  const [file, setFile] = useState<File>();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      callbackFileChange(e.target.files[0]);
    }
  };

  return <input type='file' onChange={handleFileChange} hidden={hidden} />;
};
