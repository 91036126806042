// #region Imports
// #region React & Hooks
// #endregion
// #region Icons
import { ReactComponent as AltitudeIcon } from 'assets/icons/altitudeIcon.svg'
import { ReactComponent as GradesIcon } from 'assets/icons/gradesIcon.svg'
import { ReactComponent as AirplaneIcon} from 'assets/icons/ico-airplane.svg'
import { ReactComponent as RefreshIcon} from 'assets/icons/ico-refresh.svg'
import { ReactComponent as PhotoIcon } from 'assets/icons/imgIcon.svg'
import { ReactComponent as SpeedIcon } from 'assets/icons/speedIcon.svg'
// #endregion
// #region Components
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
// #endregion
// #region Models
import { ScoutingMission } from 'models/Mission'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'state/hooks';
// #endregion
// #region Redux States
import { setCurrentMission, setDiffentialAltitude, setScoutingPoints } from 'state/slices/volareSlice';

import { FlightInfo } from '../../Components/FlightInfo/FlightInfo';
import List from '../../Components/InputList/List';
import ListInputItem from '../../Components/InputList/ListInputItem';
// #endregion
// #region Styles
import styles from './../MissionPointForm.module.scss';
// #endregion
// #endregion

export interface StepProps {
    // scoutingMissionParams: ScoutingMissionParams;
    // setScoutingMissionParams: Dispatch<SetStateAction<ScoutingMissionParams>>;
    handleError: (error: boolean) => void;
    step: number;
}

export default function StepFligthInfo ({
    step,
    handleError
}: StepProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const photoElement = () => <PhotoIcon style={{ width: '20px', height: '20px' }} />
    const speedElement = () => <SpeedIcon style={{ width: '20px', height: '20px' }} />
    const altitudeElement = () => <AltitudeIcon style={{ width: '20px', height: '20px' }} />
    const gradesElement = () => <GradesIcon style={{ width: '20px', height: '20px' }} />

    const currentMission = useAppSelector((state) => state.volare.currentMission) as ScoutingMission;
    const scoutinPoints = useAppSelector((state) => state.volare.scoutingPoints);
    const differentialAltitude = useAppSelector((state) => state.volare.differentialAltitude);

    const [altitude, setAltitude] = useState(currentMission?.altitude);
    const [speed, setSpeed] = useState(currentMission?.speed);

    const reverseScoutingOrder = () => {
        if (scoutinPoints) {
            const newOrder = [...scoutinPoints].reverse(); 
            dispatch(setScoutingPoints(newOrder));
        } 
    }

    return (
        <>
            <FlightInfo scouting={true} />

            {/* Diff Altitude */}
            <div 
                style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '10px', 
                    margin: '20px 0px',
                    fontWeight: '500',
                    color: styles.lightdisabled
                }}
            >
                <AirplaneIcon height={25} fill={styles.lightDisabled}/>
                <p>{t('diferential-altitude')}</p>
                <SwitchButton 
                    switchWidth={34}
                    switchHeight={18}
                    value={differentialAltitude} 
                    handleOnChange={(status) => dispatch(setDiffentialAltitude(!differentialAltitude))}
                />
            </div>

            {/* Revert Starting Point */}
            <div 
                style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '10px', 
                    margin: '20px 0px',
                    fontWeight: '500',
                    color: styles.lightdisabled
                }}
            >
                <RefreshIcon height={25} fill={styles.lightDisabled}/>
                <p>{t('revert-starting-point')}</p>
                <SwitchButton 
                    switchWidth={34}
                    switchHeight={18}
                    value={false} 
                    handleOnChange={reverseScoutingOrder}
                />
            </div>

            { currentMission &&
                <List >
                    {/* Altitude */}
                    <ListInputItem 
                        text={`${t('height')}`} 
                        min={1} 
                        max={200} 
                        unity='m' 
                        amount={altitude} 
                        name='altitude' 
                        Icon={altitudeElement} 
                        handleAction={(e) => {
                            const value = +e.target.value; 
                            setAltitude(value);
                            dispatch(setCurrentMission({...currentMission, altitude: value}))
                        }} 
                    />

                    {/* Speed */}
                    <ListInputItem 
                        text={`${t('speed')}`}
                        unity='m/s' 
                        min={1} 
                        max={15} 
                        amount={speed} 
                        name='speed' 
                        Icon={speedElement} 
                        handleAction={(e) => {
                            const value = +e.target.value;
                            setSpeed(value)
                            dispatch(setCurrentMission({...currentMission, speed: value}))
                        }} 
                    />
                </List>
            }
        </>
    )
}