import { useDeletePrescriptionMutation, useGetPrescriptionsQuery } from 'api/Vistaguay/LotAPI';
import { useDeleteTaskMutation } from 'api/Vistaguay/TaskAPI';
import {ReactComponent as DeleteIcon} from 'assets/icons/ico-delete.svg'
import Button from 'components/Button/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../../LotContextual.module.scss';

export default function TrashOption({ projectId, lotId }: { projectId: number, lotId: number }) {
  const { t } = useTranslation();
  
  const [
    deletePrescription,
    {
      isError,
      isSuccess,
      isLoading
    }
  ] = useDeletePrescriptionMutation();

  const { 
    data: prescriptionsData 
  } = useGetPrescriptionsQuery({lotId: lotId, projectId: projectId});

  const handleDeletePrescription = (prescriptionId: number) => {
    deletePrescription({
      lotId,
      projectId,
      prescriptionId,
    })
  }

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let timer: any;
    if (isSuccess) {
        setSuccess(true);
        timer = setTimeout(() => {
            setSuccess(false);
        }, 2000);
    }

    return () => clearTimeout(timer);
  }, [isSuccess]);

  useEffect(() => {
      let timer: any;
      if (isError) {
          setError(true);
          timer = setTimeout(() => {
              setError(false);
          }, 2000);
      }

      return () => clearTimeout(timer);
  }, [isError]);

  return (
    <>
      { prescriptionsData?.map((p) => (
        <div  key={p.id} >
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
            <p>{p.name}</p>
            <DeleteIcon 
              width={15} 
              fill={style.rojo} 
              style={{cursor: 'pointer'}}
              onClick={() => handleDeletePrescription(p.id)}
            />
          </div>
          <hr style={{margin: '5px 0 5px 0'}}/>
        </div>
      ))}

      <div style={{height: '20px'}}>
        { isLoading && (
          <p className={style.bounce}>{t('deleting')}...</p>
        )}

        { success && (
          <p style={{color: style.verdeVistaguay}}>{t('deleted')}</p>
        )}

        { error && (
          <p style={{color:  style.rojo}}>{t('error')}</p>
        )}
    </div>
    </>
  )
}
