import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import ComparationOperators from '../../../../assets/icons/ComparisonOperators.svg';
import { ReactComponent as Close } from '../../../../assets/icons/ico-close.svg';
import { typeDescription } from '../PhotoDescriptionSection';
import style from '../PhotoDescriptionSection.module.scss';
import { ZoomBox } from '../zoombox/zoombox';
import { AmbienteSelector } from './AmbienteSelector';
import { HeaderModal } from './HeaderModal';
import { getPhotoUrl } from './PhotoUrl';
import { TableModal } from './Table';

export const PhotoDescription = ({
    task,
    photoMetadata,
    taskId,
    closeFunction,
    setSelectedAmbiente,
    selectedAmbiente,
    type,
    prescription,
    densidades,
    densidadesidx,
}: any) => {
    const [imageUrl, setImageUrl] = useState('');
    const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
    const [showZoomBox, setShowZoomBox] = useState(false);
    const [isMouseOverImage, setIsMouseOverImage] = useState(false);
    const [isLoading, setIsloading] = useState<boolean>(true);
    const zoomBoxSize = 12; // Tamaño del zoom box en vw

    const calculateZoomPosition = (x: number, y: number, width: number, height: number) => {
        const zoomX = (x / width) * 100;
        const zoomY = (y / height) * 100;
        return { x: zoomX, y: zoomY };
    };

    const handleMouseEnterImage = () => {
        setIsMouseOverImage(true);
    };

    const handleMouseLeaveImage = () => {
        setIsMouseOverImage(false);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLImageElement>) => {
        const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - left;
        const y = e.clientY - top;

        const squareX = Math.min(Math.max(x, zoomBoxSize / 2 * width / 100), width - zoomBoxSize / 2 * width / 100);
        const squareY = Math.min(Math.max(y, zoomBoxSize / 2 * height / 100), height - zoomBoxSize / 2 * height / 100);

        setZoomPosition(calculateZoomPosition(squareX, squareY, width, height));
        setShowZoomBox(true);
    };

    const handleMouseLeave = () => {
        if (!isMouseOverImage) {
            setShowZoomBox(false);
        }
    };

    useEffect(() => {
        const loadPhoto = async () => {
            setIsloading(true);
            const url = getPhotoUrl(photoMetadata, taskId);
            setImageUrl(url);
            setTimeout(() => {
                setIsloading(false);
            }, 500);
        };
        loadPhoto();
    }, [photoMetadata, taskId]);

    const parts = imageUrl.split('/');
    const lastPart = parts.pop();
    let photoName = null;
    if (lastPart !== undefined) {
        photoName = lastPart.slice(0, -4);
        console.log(photoName); // Output: "dji-0017"
    } else {
        console.log('No file name found.');
    }

    return (
        <div className={style.detalle}>
            <div className={style.cuadro}>
                <HeaderModal
                    type={type}
                    typeDescription={typeDescription}
                    ComparationOperators={ComparationOperators}
                />

                <div className={style.photoName}>
                    <h2 className={`${style.h2imagen} ${style.h2imagenBold}`}>Imagen:</h2>
                    <h2 className={style.h2imagen}>{photoName}</h2>
                </div>

                <div className={style.datos}>
                    <TableModal
                        photosMetadata={photoMetadata}
                    />
                </div>
                <div>
                    <div>
                        <AmbienteSelector
                            task={task}
                            selectedAmbiente={selectedAmbiente}
                            setSelectedAmbiente={setSelectedAmbiente}
                            prescription={prescription}
                            densidades={densidades}
                            photosMetadata={photoMetadata}
                            densidadesidx={densidadesidx}
                            type={type}
                        />
                    </div>
                </div>
            </div>
            <div className={style.fotoHD}
                onMouseEnter={handleMouseEnterImage}
                onMouseLeave={handleMouseLeaveImage}
            >
                <div style={{ position: 'relative' }}>
                    {isMouseOverImage && (
                        <div
                            className={style.square}
                            style={{
                                position: 'absolute',
                                left: `calc(${zoomPosition.x}% - ${zoomBoxSize / 2}vw)`,
                                top: `calc(${zoomPosition.y}% - ${zoomBoxSize / 2}vw)`,
                                width: `${zoomBoxSize}vw`,
                                height: `${zoomBoxSize}vw`,
                                pointerEvents: 'none',
                            }}
                        >
                            <ZoomBox src={imageUrl} zoomPosition={zoomPosition} />
                        </div>
                    )}
                    {isLoading ? (
                        <Spinner className={style.spinner} />
                    ) : (
                        <img
                            src={imageUrl}
                            alt="Zoomable"
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                            style={{ display: 'block', width: '100%' }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
