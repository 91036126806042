import { useGetTaskUploadedMetadataQuery } from 'api/Vistaguay/TaskAPI';
import MCircleText from 'components/Map/Components/MCircleText';
import { SecureImg } from 'components/Secure/SecureImg/SecureImg';
import { 
    getColor,
    getRadius,
    PhotoPopup, 
    PhotoPopupTitle} from 'pages/TaskView/TaskViewMap/TaskViewMap';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';

import { customRound } from '../CustomRound';


export interface PhotoPointsProps {
    taskId: string;
    zIndex?: number;
    activeDensity?: boolean;
    activeTemporalVariability?: boolean;
    activeSpatialVariability?: boolean;
}

export default function PhotoPoints({
    taskId,
    activeDensity,
    activeTemporalVariability,
    activeSpatialVariability,
  }: PhotoPointsProps) {
    const { projectId, lotId } = useParams<{ projectId: string; lotId: string }>();
    if (!projectId || !lotId) {
      return null;
    }
    const { 
      data: photosMetadata
    } = useGetTaskUploadedMetadataQuery({ taskId, lotId });
  
    const token = useAppSelector((state) => state.data.accessToken);
    const [photoModalUrl, setPhotoModalUrl] = useState<string | null>(null);
    const [photoTitle, setPhotoTitle] = useState('');
  
    useEffect(() => {
      if(photoModalUrl) {
        const photoStringArray = photoModalUrl?.split('/')
        const photoStringArrayLength: number = photoStringArray?.length
        const shortPhotoName = photoStringArray && photoStringArrayLength ? photoStringArray[photoStringArrayLength - 1] : ''
        setPhotoTitle(shortPhotoName);
      }
    }, [photoModalUrl])
  
  
    return (
      <>
        {photoModalUrl && (
          <Modal
            show={!!photoModalUrl}
            onHide={() => {
              setPhotoModalUrl(null);
            }}
            centered
            size='xl'
          >
            <Modal.Header closeButton>
              <p style={{fontWeight: 500}}>{photoTitle}</p>
            </Modal.Header>
            <Modal.Body>
              <SecureImg
                style={{
                  width: '100%',
                  height: '100%'
                }}
                linkUrl={photoModalUrl}
              />
            </Modal.Body>
          </Modal>
        )}
  
        {photosMetadata &&
          photosMetadata.map((photoMetadata: any, i: number) => {
            if(photoMetadata.densidad == null || photoMetadata.densidad == undefined)
            {
              return
            }
            return (
              <MCircleText
                key={photoMetadata.fileName + i}
                lat={+photoMetadata.GPSLatitude}
                lng={+photoMetadata.GPSLongitude}
                text={activeDensity ? `${customRound(photoMetadata.densidad / 1000)}K` : ''}
                radius={activeSpatialVariability ? getRadius(photoMetadata.cov_cv) : 0.001}
                color={activeTemporalVariability ? getColor(photoMetadata.gap_cv) : 'transparent'}
                hovered={i % 2 === 0}
                overlayContent={{
                  title: (
                    <PhotoPopupTitle
                      photoMetadata={photoMetadata}
                      token={token}
                      taskId={taskId}
                      openImageModal={(url: string) => {
                        setPhotoModalUrl(url);
                      }}
                    />
                  ),
                  content: <PhotoPopup photoMetadata={photoMetadata} taskId={taskId} token={token} />,
                }}
              />
            );
          })}
      </>
    );
}