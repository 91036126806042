/* eslint-disable camelcase */

import { Campaign } from './Campaign';
import Lot from './Lot';

export class Mission {
  id?: number;
  apk_id?: number;
  type: string;
  name: string;
  altitude: number;
  speed: number;
  flight_direction: number;
  polygon_vertices: MissionPolygonVertex[];
  lot: Pick<Lot, 'id'> | string;
  campaign: Pick<Campaign, 'id'> | number;
  take_off_lat?: number;
  take_off_lng?: number;
  creationDateTime: Date;
  lastModifiedDatetime: Date;

  constructor() {
    this.id = 0;
    this.apk_id = 0;
    this.type = '';
    this.name = '';
    this.altitude = 0;
    this.speed = 0;
    this.flight_direction = 0;
    this.polygon_vertices = [];
    this.lot = { id: 0 };
    this.campaign = { id: 0 };
    this.creationDateTime = new Date();
    this.lastModifiedDatetime = new Date();
  }
}

export class MissionPolygonVertex {
  id?: number;
  index: number;

  lat: number;

  lng: number;
  constructor() {
    this.id = 0;
    this.index = 0;
    this.lat = 0;
    this.lng = 0;
  }
}

export class MissionPoint {
  id?: number;

  index: number;

  lat: number;

  lng: number;

  altitude: number;

  speed: number;

  constructor() {
    this.id = 0;
    this.index = 0;
    this.lat = 0;
    this.lng = 0;
    this.altitude = 0;
    this.speed = 0;
  }
}

export class MappingMission extends Mission {
  waypoints: MissionPoint[];
  frontal_overlap: number;
  lateral_overlap: number;
  camera_name: string;
  creationDateTime: Date; // Nueva propiedad

  camera_horizontal_field_of_view: number;
  camera_vertical_field_of_view: number;
  
  constructor() {
    super();
    this.waypoints = [];
    this.camera_name = '';
    this.camera_horizontal_field_of_view = 0;
    this.camera_vertical_field_of_view = 0;
    this.frontal_overlap = 0;
    this.lateral_overlap = 0;
    this.creationDateTime = new Date(); // Inicialización opcional
  }
}

export class ScoutingMission extends Mission {
  waypoints: ScoutingMissionPoint[];
  prescriptionId?: number;
  photoCount: number;
  creationDateTime: Date;
  gridSize?: string;

  constructor() {
    super();
    this.prescriptionId = 0;
    this.waypoints = [];
    this.creationDateTime = new Date();
    this.photoCount = 0;
  }
}

export class ScoutingMissionPoint extends MissionPoint {
  actions: ScoutingMissionPointAction[];
  constructor() {
    super();
    this.actions = [];
  }
}

export class ScoutingMissionPointAction {
  action: string;

  id?: number;
  index: number;
  point_id?: number;
  value: number | null;

  constructor() {
    this.action = 'WAIT';
    this.id = 0;
    this.index = 0;
    this.point_id = 0;
    this.value = 0;
  }
}


export function isScouting(mission: ScoutingMission | MappingMission): mission is ScoutingMission {
  return (mission as ScoutingMission).type === 'SCOUTING';
}

export function isMapping(mission: ScoutingMission | MappingMission): mission is MappingMission {
  return (mission as MappingMission).type === 'MAPPING';
}
