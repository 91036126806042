import { Popover2 } from '@blueprintjs/popover2';
import { ReactComponent as ArgFlagIcon } from 'assets/flags/ico-flag-arg.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico-question.svg';
import cx from 'classnames'
import Button from 'components/Button/Button';
import { Countries, Option } from 'components/DataLists/DataLists';
import { Input, InputSelect } from 'components/Inputs/Input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { IProfileForm } from '../ProfileModal';
import styles from '../ProfileModal.module.scss'


const customStyles = {
    input: (base: any) => ({
        ...base,
        padding: '7px', // Padding for the input
        '& input': {
          font: 'inherit', // Inherit font styles from parent
        },
    }),
    menu: (base: any) => ({
      ...base,
      maxHeight: '150px', // Altura máxima del menú
      overflowY: 'auto', // Habilitar scroll vertical
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: '150px', 
    }),
};

const filterOption = (option: any, inputValue: string) => {
    return option.label.props.children[1].toLowerCase().includes(inputValue.toLowerCase());
};
  
const prefixOptions = Countries.map((c)=> {
    return {
        value: c.prefix,
        label: (
            <div>
                <img src={c.flag} height={20} style={{ marginRight: '8px' }} />
                {`${c.prefix} - ${c.name}`}
            </div>
        )
    }
})

const countryOptions = Countries.map((c)=> {
    return {
        value: c.name,
        label: (
            <div>
                <img src={c.flag} height={20} style={{ marginRight: '8px' }} />
                {c.name}
            </div>
        )
    }
})


export default function UserData ({
    form,
    setForm
}:{
    form: Partial<IProfileForm>,
    setForm: (form: Partial<IProfileForm>) => void
}) {
    const { t } = useTranslation();

    return (
        <div>
            <div className={styles.section}>
                {/* EMAIL */}
                <div className={styles.inputGroup}>
                    <div className={styles.label}>
                        <span>{t('email')}</span>
                    </div>
                    
                    <Input 
                        placeholder=''
                        value={form?.email}
                        disabled
                    />
                </div>

                {/* NAME */}
                <div className={styles.inputGroup}>
                    <div className={styles.label}>
                        <span>{t('first-name')}</span>
                    </div>
                    
                    <Input 
                        placeholder=''
                        value={form.firstName}
                        onChange={(e) => setForm({...form, firstName: e.target.value})}
                    />
                </div>

                {/* LASTNAME */}
                <div className={styles.inputGroup}>
                    <div className={styles.label}>
                        <span>{t('last-name')}</span>
                    </div>
                    
                    <Input 
                        placeholder=''
                        value={form.lastName}
                        onChange={(e) => setForm({ ...form, lastName: e.target.value})}
                    />
                </div>

                {/* COUNTRY */}
                <div className={styles.inputGroup}>
                    <div className={styles.label}>
                        <span>{t('country')}</span>
                    </div>
                    
                    <Select 
                        value={countryOptions.filter((opt: any) => opt.value === form.country)}
                        styles={customStyles}
                        filterOption={filterOption}
                        options={countryOptions}
                        onChange={(selectedOption) => { 
                            if(selectedOption?.value){
                                setForm({...form, country: selectedOption?.value})
                            }
                        }}
                    />
                </div>

                {/* PHONE */}
                <div style={{marginBottom: '10px'}}>
                    <div className={styles.label}>
                        <span>{t('cellphone')}</span>
                    </div>
                    <div style={{display: 'grid', gridTemplateColumns: '50% 50%', gap: '10px'}}>
                        <Select 
                            value={prefixOptions.filter((opt: any) => opt.value === form.prefix)}
                            styles={customStyles}
                            filterOption={filterOption}
                            options={prefixOptions}
                            onChange={(selectedOption) => { 
                                if(selectedOption?.value){
                                    setForm({...form, prefix: selectedOption?.value})
                                }
                            }}
                        />

                        <Input
                            id='celphone'
                            placeholder=''
                            maxLenght={13}
                            type='text'
                            value={form.phoneNumber}
                            onChange={(e) => setForm({...form, phoneNumber: e.target.value})}
                            onKeyPress={(event) => {
                                if (event.key == 'Backspace') {
                                    return;
                                } else if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}