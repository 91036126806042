import { ReactComponent as MinusIcon } from 'assets/icons/minusCircleIcon.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plusCircleIcon.svg'
import { InputRange, InputRangeVariant } from 'components/Inputs/Input'
import { ChangeEvent, ChangeEventHandler, useState } from 'react'

import styles from './List.module.scss'

interface ListInputItemProps {
    Icon: React.ElementType
    amount: string | number,
    unity: string,
    name: string,
    text?: string,
    min?: number,
    max?: number,
    handleAction: (e : ChangeEvent<HTMLInputElement>) => void
    onlyRange?: boolean
    disabled?: boolean
}

export default function ListInputItem({ min, max, text, Icon, amount, unity, name, handleAction, onlyRange = false ,disabled = false}: ListInputItemProps) {

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> ) => {
        if (min && Number(e.target.value) < min) {
            e.target.value = `${min}`;
        } else if (max && Number(e.target.value) > max) {
            e.target.value = `${max}`;
        }

        handleAction(e)
    };

    return (
        <div className={styles.listItem}>
            <div className={styles.range}>
                <div className={styles.info}>
                    <Icon className={styles.typeIcon} />
                    <p>{`${text}(${unity})`}</p>
                </div>
                <InputRange variant={InputRangeVariant.VOLARE} type="range" name={name} min={min} max={max} value={amount}
                    disabled={disabled}
                    onChange={(e) => handleOnChange(e)}
                />
            </div>
            
            <input
                type="number"
                value={amount}
                className={styles.inputValue}
                name={name}
                min={min}
                max={max}
                disabled={disabled}
                onChange={(e) => handleOnChange(e)}
            />
            
        </div>
    )

}