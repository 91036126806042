import { NumberRange, RangeSlider } from '@blueprintjs/core';
import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetMosaicQuery, useGetMosaicResultMetadataQuery } from 'api/Vistaguay/MosaicAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import { ReactComponent as BackIcon } from 'assets/icons/ico-back.svg';
import { ReactComponent as Dots } from 'assets/icons/ico-dots.svg';
import Invisible from 'assets/icons/notvisible.png';
import Visible from 'assets/icons/visible.png';
import { DownloadLink } from 'components/DownloadLink/DownloadLink';
import { Histogram } from 'components/Histogram/Histogram';
import Spinner from 'components/Spinner/Spinner';
import { motion } from 'framer-motion';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarHeader from 'layout/Sidebar/SidebarHeader';
import { Campaign } from 'models/Campaign';
import Lot from 'models/Lot';
import { PlantCountTask } from 'models/PlantCountTask';
import Project from 'models/Project';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setToggleShowLayer, updateMaxMin } from 'state/slices/mosaicSlice';

import style from './MosiacView.module.scss';

const getFileNameWithoutExtension = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  return fileName.substring(0, lastDotIndex);
};

export function MosiacView() {
  const { projectId, lotId, taskId } = useParams<{
    projectId: string;
    lotId: string;
    taskId: string;
  }>();
  const navigate = useNavigate();

  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );

  if (!projectId || !lotId || !taskId) {
    return null; // FIXME handle error
  }
  const {
    data: project,
    isLoading: isLoadingProject,
    error: errorProject,
  } = useGetProjectQuery(projectId as unknown as number);

  const {
    data: lot,
    isLoading: isLoadingLots,
    error: errorLots,
    isSuccess: isSuccessLot,
  } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });

  const {
    data: task,
    isLoading: isLoadingTask,
    error: errorTask,
    isSuccess: isSuccessTask,
  } = useGetMosaicQuery({ taskId });

  const { data: resultsFilename } = useGetMosaicResultMetadataQuery({ taskId });

  const SelectLayers = () => {
    const mosaicUi = useAppSelector((state) => {
      return state.mosaic;
    });
    const dispatch = useAppDispatch();

    return (
      <>
        {mosaicUi.layers.map((layer, index) => (
          <div className={style.layer} key={index}>
            <p>{layer}</p>
            <img
              src={
                mosaicUi.layersToShow.length >= index && mosaicUi.layersToShow[index]
                  ? Visible
                  : Invisible
              }
              onClick={() => {
                dispatch(setToggleShowLayer({ index }));
              }}
            />
          </div>
        ))}
      </>
    );
  };

  const VariSlider = () => {
    const [min, setMin] = useState(-1);
    const [max, setMax] = useState(1);
    const mosaicUi = useAppSelector((state) => {
      return state.mosaic;
    });

    useEffect(() => {
      setMin(mosaicUi.variMin);
      setMax(mosaicUi.variMax);
    }, []);

    useEffect(() => {
      dispatch(
        updateMaxMin({
          variMin: min,
          variMax: max,
        }),
      );
    }, [max, min]);

    const dispatch = useAppDispatch();

    const handleMinChange = (e: any) => {
      setMin(e.target.value);
    };

    const handleMaxChange = (e: any) => {
      setMax(e.target.value);
    };

    const handleValueChange = (range: NumberRange) => {
      const [min, max] = range;
      setMin(min);
      setMax(max);
    };

    const globalMin = -2000;
    const globalMax = 2000;

    return (
      <>
        <h3>Vari config</h3>
        <div className={style.variRange}>
          <span>{min}</span>
          <span>{max}</span>
        </div>

        <RangeSlider
          min={-2000}
          max={2000}
          // stepSize={2}
          labelStepSize={(globalMax - globalMin) / 10}
          onChange={handleValueChange}
          value={[min, max]}
          // vertical={vertical}
          handleHtmlProps={{
            start: { 'aria-label': 'example start' },
            end: { 'aria-label': 'example end' },
          }}
        />
        <div style={{ padding: '1rem' }}>
          <Histogram />
        </div>
      </>
    );
  };

  const IS_LOADING = isLoadingProject || isLoadingLots || isLoadingTask;

  if (!task) {
    return <></>;
  }

  return (
    <Sidebar>
      <SidebarHeader />
      {isLoadingProject && <Spinner />}
      {
        <>
          <TaskHeader project={project} lot={lot} task={task} />
          <SidebarBody>
            {IS_LOADING && <Spinner />}
            {!IS_LOADING && (
              <>
                <h2 style={{ color: 'black' }}>{task.nombre}</h2>
                <SelectLayers />
                <VariSlider />

                {/* {resultsFilename && (
                  <ul
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                      listStyle: 'none',
                    }}
                  >
                    {resultsFilename.map((filename: string) => (
                      <li key={filename}>
                        <DownloadLink
                          fileName={filename}
                          linkUrl={`mosaic/${taskId}/result/${filename}`}
                        >
                          {filename}
                        </DownloadLink>
                      </li>
                    ))}
                  </ul>
                )} */}
              </>
            )}
          </SidebarBody>
        </>
      }
    </Sidebar>
  );
}

interface LotHeaderProps {
  project: Project | undefined;
  lot: Lot | undefined;
  task: any;
}

const TaskHeader = ({ project, lot, task }: LotHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className={style.lotHeader}>
      <motion.div whileHover={'hoverBack'} className={style.left}>
        <div
          onClick={() => {
            navigate(`/projects/${project?.proyectoId}/lots/${lot?.id}`);
          }}
          className={style.backContainer}
        >
          <AnimatedBackIcon />
          <p>Tareas</p>
        </div>
        <div>
          {task && (
            <>
              <h5>{project?.nombre}</h5>
              <h4>{lot?.name}</h4>
              <h2>*{task.nombre}*</h2>
            </>
          )}
        </div>
      </motion.div>
      <div className={style.projectContextualContainer}>
        <Dots className={style.icon} />
      </div>
    </div>
  );
};

export const AnimatedBackIcon = () => {
  return (
    <motion.svg
      className={style.icon}
      variants={{
        hoverBack: {
          x: -10,
          transition: {
            duration: 0.3,

            repeat: Infinity,
            repeatType: 'reverse',
          },
        },
      }}
    >
      <BackIcon />
    </motion.svg>
  );
};
