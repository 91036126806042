import Button from 'components/Button/Button'
import { useNavigate } from 'react-router-dom'

import style from './Auxiliar.module.scss'

export default function NotAuthorized () {
    const navigate = useNavigate()
    return (
        <div className={style.notfound}>
            <h1 style={{color:style.rojo}}>401</h1>
            <h4>Unauthorized</h4>
            <Button text='GO BACK TO OUR HOMEPAGE' onClick={() => navigate('/')} variant={'ERROR'} style={{width:'300px'}}/>
        </div>
    )
}