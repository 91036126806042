import style from '../PhotoDescriptionSection.module.scss';



interface IPhotoDescriptionSection {
  photosMetadata: any;
}

export const TableModal = ({ photosMetadata }: IPhotoDescriptionSection) => {
    return (
        <table className={style.tabla}>
            <thead>
                <tr>
                <th>
                    <h2>Densidad</h2>
                </th>
                <th>
                    <h2>Espaciamiento</h2>
                </th>
                <th>
                    <h2>Cobertura</h2>
                </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>
                    <h3>{photosMetadata.cant}</h3> <h4>(pl/imagen)</h4>
                </td>
                <td>
                    <h3>{photosMetadata.gap_mean.toFixed(2)}</h3>
                    <h4>Promedio (cm)</h4>
                </td>
                <td>
                    <h3>{photosMetadata.cov_mean.toFixed(2)}</h3> <h4>Promedio (cm<sup>2</sup>/pl)</h4>
                </td>
                </tr>
                <tr>
                <td>
                    <h3>{Math.round(photosMetadata.area)}</h3> <h4>(m²)</h4>
                </td>
                <td>
                    <h3>{photosMetadata.gap_std.toFixed(2)}</h3> <h4>Desvío std (cm)</h4>
                </td>
                <td>
                    <h3>{photosMetadata.cov_std.toFixed(2)}</h3> <h4>Desvío std (cm<sup>2</sup>/pl)</h4>
                </td>
                </tr>
                <tr>
                <td>
                    <h3>{photosMetadata.densidad}</h3> <h4>(pl/ha)</h4>
                </td>
                <td>
                    <h3>{photosMetadata.gap_cv.toFixed(2)}</h3> <h4>CV (%)</h4>
                </td>
                <td>
                    <h3>{photosMetadata.cov_cv.toFixed(2)}</h3> <h4>CV (%)</h4>
                </td>
                </tr>
            </tbody>
        </table>
    );
};
