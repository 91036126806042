import { useAddProjectToCampaignMutation } from 'api/Vistaguay/ProjectAPI';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import { Campaign } from 'models/Campaign';
import Project from 'models/Project';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'state/hooks';

import style from './ProjectContextual/ProjectContextual.module.scss';

interface Props {
  project: Project;
}

function ArchivedContextual({ project }: Props) {
  const { t } = useTranslation();
  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );
  const [addProjectToCampaign, addProjectToCampaignResult] = useAddProjectToCampaignMutation();

  return (
    <div className={style.projectContextualMenu}>
      <div
        onClick={() => {
          ErrorToaster('TODO:Borrar');
        }}
        className={style.contextualButton}
      >
        <p>{t('borrar')}</p>
      </div>
      <div
        onClick={() => {
          if (selectedCampaign) {
            addProjectToCampaign({
              projectId: project.proyectoId,
              campaignId: selectedCampaign?.id,
            });
          }
        }}
        className={style.contextualButton}
      >
        <p>{t('agregar-a-campana-actual')}</p>
      </div>
    </div>
  );
}

export default ArchivedContextual;
