import { createAnalyticsBody, CreateLayerInterface, useCreateAnalyticsMutation, useCreateLayerMutation } from 'api/Vistaguay/AnalyticsApi';
import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import { ReactComponent as EnsayoIcon } from 'assets/icons/ensayoIcon.svg';
import Button from 'components/Button/Button';
import { Input, InputSelect, TextArea } from 'components/Inputs/Input';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import { SlimHeader, SubHeader, TaskHeader } from 'layout/Sidebar/SubHeader';
import { LayerType } from 'models/AnalyticsLayer';
import { GeoJSONGeometry } from 'ol/format/GeoJSON';
import { ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';

import styles from './Analitycs.module.scss';

interface IFormAnalytics {
  name: string;
  description: string;
  cultivoId: number | undefined;
  hibridoId: number | undefined;
}

const formValidation = (form: IFormAnalytics) => {
  if (!form.name) {
    return { isValid: false, message: 'Debe ingresar un nombre', propName: 'name' };
  }
  if (!form.cultivoId) {
    return { isValid: false, message: 'Debe ingresar un cultivo', propName: 'cultivoId' };
  }
  if (!form.hibridoId) {
    return { isValid: false, message: 'Debe ingresar un genotipo', propName: 'hibridoId' };
  }
  return { isValid: true, message: '', propName: '' };
}

const initValues: IFormAnalytics = {
  name: '',
  description: '',
  cultivoId: undefined,
  hibridoId: undefined,
};

const AddAnalitycs = () => {
  const { projectId, lotId } = useParams<{ projectId: string; lotId: string; }>();
  const [form, setForm] = useState<IFormAnalytics>(initValues);
  const [hasError,setHasError] = useState({isValid:false,message:'', propName: ''});
  const [createAnalytics ] = useCreateAnalyticsMutation();
  if (!projectId || !lotId) {
    return null; // FIXME handle error
  };

  const { data: extraData, isLoading: extraDataLoading } = useGetExtraDataQuery();

  const {
    data: project,
    isLoading: isLoadingProject,
    error: errorProject,
  } = useGetProjectQuery(projectId as unknown as number);

  const {
    data: lot,
    isLoading: isLoadingLots,
    error: errorLots,
    isSuccess: isSuccessLot,
  } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });

  const { selectedCampaign } = useAppSelector((state) => {
    return {
      selectedCampaign: state.campaign.selectedCampaign,
    };
  });

  const [createLayer] = useCreateLayerMutation();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {

    const validation = formValidation(form)
    if(!validation.isValid){
      setHasError(validation)
      return
    };
    setHasError({isValid:true,message:'', propName: ''})
    const body : createAnalyticsBody = {
      nombre: form.name,
      descripcion: form.description,
      campaignId: selectedCampaign?.id + '',
      lotId: lotId,
    };
    createAnalytics({
      ...body
    }); 
  };

  const handleCreateLayer = () => {
    const layer : GeoJSONGeometry = {
      type: 'Polygon',
      coordinates: [[[-61.5, -31.5], [-61.5, -30.5], [-60.5, -30.5], [-60.5, -31.5], [-61.5, -31.5]]]
    }
    const body : CreateLayerInterface = {
      analyticsId: 1,
      name: 'Capa de prueba',
      type: LayerType.polygon,
      geography: layer
    }
    createLayer(body)
  }


  return (
<Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
      <SubHeader sx={{ height: '55px', display: 'flex', width: '100%' }} noBack={true}>
        <SlimHeader
          campaignName={selectedCampaign?.name}
          projectName={project?.nombre}
          projectColor={project?.color}
          lotName={lot?.name}
          backTo={`/projects/${projectId}/lots/${lotId}/tasks/new`}
          backText='Cancelar ensayo'
        />
      </SubHeader>
      <SubHeader noBack={true} sx={{ backgroundColor: styles.dark }}>
        <TaskHeader
          title={'Ensayo'}
          icon={<EnsayoIcon style={{ width: '50px', marginRight: '15px' }} />}
        />
      </SubHeader>
      <SidebarBody>
        <form className={styles.form} style={{ marginTop: '0px' }}>
        {
            !hasError.isValid && (
              <p style={{ color: 'red' }}>{`${hasError.message}`}</p>
            )
          }
          <label htmlFor=''>Nombre</label>
          <Input
            value={form.name}
            placeholder='Nombre'
            id='name'
            name='name'
            onChange={handleChange}
          />

          <label htmlFor=''>Descripcion</label>
          <TextArea
            value={form.description}
            id='description'
            name='description'
            placeholder='descripcion'
            sx={{ display: 'flex', width: '100%', height: '130px' }}
            onChange={handleChange}
          />
          <label>Cultivo</label>
          <InputSelect
              placeholder='Cultivo'
              value={form.cultivoId ? form.cultivoId : ''}
              onChange={(e) => setForm({ ...form, cultivoId: +e.target.value, hibridoId: undefined})}

          >
              {
                  extraData?.crops.map(crop => <option key={crop.id} value={crop.id}>{crop.name}</option>)
              }
              <option value={''}> </option>
          </InputSelect>
          <label>Genotipo</label>
          <InputSelect
              placeholder='Genotipo'
              value={form.hibridoId ? form.hibridoId : ''}
              onChange={(e) => setForm({ ...form, hibridoId: +e.target.value })}
          >
              {
                  extraData?.crops.find(crop => crop.id === form.cultivoId)?.hybrids.map(hybrid => <option key={hybrid.id} value={hybrid.id}>{hybrid.name}</option>)
              }
              <option value={''}> </option>
          </InputSelect>
        </form>
      </SidebarBody>
      <SidebarFooter sx={{ display: 'flex', justifyContent: 'center'}}>
        {/* <Button text='Cancelar' variant='ERROR' /> */}
        <Button text='Finalizar' variant='SUCCESS' onClick={handleSubmit} />
      </SidebarFooter>
    </Sidebar>
  );
}

export default AddAnalitycs;