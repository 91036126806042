import { GoogleOAuthProvider } from '@react-oauth/google';
import ModalGlobalComponent from 'components/Modals/ModalGlobalComponent';
import ProtectedRoute from 'layout/ProtectedRoute';
import SidebarAndMap from 'layout/SidebarAndMap';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GOOGLE_AUTH_CLIENT_ID } from 'services/configurations';
import { useAppSelector } from 'state/hooks';

import Topbar from './layout/Topbar/Topbar';
import { routes } from './routes';
export default function App() {
  const { modalGlobalState } = useAppSelector((state) => state.ui);

  // on render see if jwt is set from localstorage
  return (
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
      <BrowserRouter>
        <div className='AppMainContainer'>
          <Topbar />
          <div className='MainArea'>
            <Routes>
              {routes.map((route) => {
                return (
                  <Route
                    key={`${route.path}-centrarl`}
                    path={route.path}
                    element={
                      !route.layout ? (
                        <ProtectedRoute isProtected={route.protected}>
                          <route.centralArea />

                          {
                            modalGlobalState.isOpen && (
                              <ModalGlobalComponent />
                          )}
                        </ProtectedRoute>
                      ) : (
                        <ProtectedRoute isProtected={route.protected} >
                          <SidebarAndMap
                            haveMap={route.map}
                            Sidebar={() => route.sidebar()}
                            Map={() => route.centralArea()}
                          />
                        </ProtectedRoute>
                      )
                    }
                  />
                );
              })}
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}
