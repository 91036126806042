import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Polygon } from '@turf/turf';
import { GeoJSON, GeoJSONFeature } from 'models/GeoJSON';
interface Point {
  name?: string;
  lat: any[];
  lng: any[];
}
export interface MapState {
  isMapLoaded: boolean;
  currentPolygon: GeoJSONFeature<Polygon> | null;
  addPolygon: boolean;
  editPolygon: boolean;
  layers: any[];
  imgPoints: Array<Point>;
  hoverImg: number | null;
  showLot: boolean;
  showPrescription: boolean;
  prescriptionToShow: number;
  isPrescriptionEditable: boolean;
}

const initialState: MapState = {
  isMapLoaded: false,
  currentPolygon: null,
  addPolygon: false,
  editPolygon: false,
  layers: [],
  imgPoints: [],
  hoverImg: null,
  showLot: true,
  showPrescription: false,
  prescriptionToShow: -1,
  isPrescriptionEditable: false,
} as MapState;

const reducer = {
  setIsMapLoaded: (state: MapState, action: PayloadAction<boolean>) => {
    state.isMapLoaded = action.payload;
  },
  setPolygon: (state: MapState, action: PayloadAction<GeoJSONFeature<Polygon>>) => {
    state.currentPolygon = action.payload;
  },
  toggleAddPolygon: (state: MapState, action: PayloadAction<boolean>) => {
    state.addPolygon = action.payload;
  },
  toggleEditPolygon: (state: MapState, action: PayloadAction<boolean>) => {
    state.editPolygon = action.payload;
  },
  addLayer: (state: MapState, action: PayloadAction<any>) => {
    state.layers.push(action.payload);
  },
  removeLayer: (state: MapState, action: PayloadAction<any>) => {
    const index = state.layers.findIndex((l) => l.id === action.payload);
    if (index !== -1) {
      state.layers.splice(index, 1);
    }
  },
  setHoverImg: (state: MapState, action: PayloadAction<number | null>) => {
    state.hoverImg = action.payload;
  },
  toggleLayerEditability: (state: MapState, action: PayloadAction<number>) => {
    const index = state.layers.findIndex((l) => l.id === action.payload);
    if (index !== -1) {
      state.layers[index].isEditable = !state.layers[index].isEditable;
    }
  },
  addImgPoints: (state: MapState, action: PayloadAction<Point>) => {
    state.imgPoints.push(action.payload);
  },
  removeImgPoints: (state: MapState, action: PayloadAction<number>) => {
    const imgpoint = state.imgPoints;
    state.imgPoints = imgpoint.slice(action.payload, action.payload);
  },
  clearImgPoints: (state: MapState) => {
    state.imgPoints = [];
  },
  toggleShowLot: (state: MapState) => {
    state.showLot = !state.showLot;
  },
  setShowPrescription: (state: MapState) => {
    state.showPrescription = !state.showPrescription;
  },
  setPresciptionToShow: (state: MapState, action: PayloadAction<number>) => {
    state.prescriptionToShow = action.payload;
  },
  setPrescriptionEditable: (state: MapState, action: PayloadAction<boolean>) => {
    state.isPrescriptionEditable = action.payload;
  }
};

export const mapSlice = createSlice({
  name: 'Map',
  initialState,
  reducers: reducer,
});

export const {
  removeImgPoints,
  setShowPrescription,
  setPresciptionToShow,
  toggleShowLot,
  toggleEditPolygon,
  setPolygon,
  toggleAddPolygon,
  setHoverImg,
  setIsMapLoaded,
  addLayer,
  removeLayer,
  clearImgPoints,
  toggleLayerEditability,
  addImgPoints,
  setPrescriptionEditable
} = mapSlice.actions;

export default mapSlice.reducer;
