import { CheckBox } from 'components/CheckBox/CheckBox'
import { Input } from 'components/Inputs/Input'

import styles from './Steps.module.scss'

export default function StepTwo() {

    return (
        <div className={styles.containerTwo}>
            <div style={{ marginTop: '5px' }}>
                <h5 style={{ fontSize: '15px', margin: '0' }}>Start numering pattern</h5>
                <Input placeholder={''} />
            </div>
            <div style={{ marginTop: '5px' }}>
                <h5 style={{ fontSize: '15px', margin: '0' }}>Choose numering pattern</h5>
                <div className={styles.rows}>
                    <div style={{ width: 'auto'}}>
                        <CheckBox />
                        <CheckBox />
                        <CheckBox />
                        <CheckBox />
                    </div>
                    <div className={styles.row} style={{ width: '90%'}}>
                        <h5 style={{ fontSize: '15px', margin: '0' }}>Row / Range</h5>
                        <h5 style={{ fontSize: '15px', margin: '0' }}>Range / Row</h5>
                        <h5 style={{ fontSize: '15px', margin: '0' }}>Sequential with snaking pathing</h5>
                        <h5 style={{ fontSize: '15px', margin: '0' }}>Sequential with zig-zag pathing</h5>
                    </div>
                </div>
            </div>
            <div className={styles.columns} style={{ marginTop: '5px'}}>
                <label htmlFor="">Separator (Row-Range)</label>
                <Input placeholder={''} />
                <label htmlFor="">Minimum number of row digit</label>
                <Input placeholder={''} />
                <label htmlFor="">Minimum number of column digit</label>
                <Input placeholder={''} />
            </div>
        </div>
    )
}