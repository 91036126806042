import { useGetReportDataQuery } from 'api/Vistaguay/ReportApi';
import { ReactComponent as Camera } from 'assets/icons/ico-camara.svg';
import { PrescriptionPolygons } from 'components/Domain/Prescription/PrescriptionPolygons';
import MCircleText from 'components/Map/Components/MCircleText';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import MapContext from 'components/Map/Context/MapContext';
import { SecureImg } from 'components/Secure/SecureImg/SecureImg';
import { PhotoMetadata } from 'models/PhotoMetadata';
import { Polygon as OlPolygon } from 'ol/geom';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import style from '../../TaskView/TaskViewMap/TaskViewMap.module.scss';
import { resultImageBasePath } from '../CountReport/CountTaskReport';
import { CountMapLegend } from '../TaskViewMap/CountMapLegend';
import { getColor, getRadius, PhotoPopup } from '../TaskViewMap/TaskViewMap';

export function PublicTaskViewMap() {
  const [photoModalUrl, setPhotoModalUrl] = useState<string | null>(null);
  const [changeCheck, setChangeCheck] = useState(false);
  const map = useContext(MapContext);

  const { token: taskToken } = useParams<{
    token: string;
  }>();

  const { data: reportData, isLoading: isLoadingReportData } = useGetReportDataQuery(
    {
      token: taskToken ? taskToken : '',
    },
    { skip: !taskToken },
  );

  useEffect(() => {
    // zoom to polygon
    console.log('Enter en useEffect');
    if (map && !changeCheck && reportData && reportData.photosMetadata) {
      const feat = PhotoMetadata.generarPoligono(reportData.photosMetadata);

      if (feat) {
        console.log('sale sum');
        const padding = 200;
        map?.getView().fit((feat as OlPolygon).getExtent(), {
          padding: [padding, padding, padding, padding], // [0, 0, 0, 0].map(() => padding)
          duration: 600,
        });
        setChangeCheck(true);
      }
    }
  }, [reportData, changeCheck]);

  const IS_LOADING = isLoadingReportData;
  const SHOW_CONTENT = !IS_LOADING && reportData;
  // if (!SHOW_CONTENT) {
  //   console.log('TaskViewMap: !IS_LOADING && reportData is null');
  //   return null;
  // }
  if (reportData == undefined) {
    // console.log('TaskViewMap: !IS_LOADING && reportData is null');
    return null;
  }
  if (!taskToken) {
    // console.log('TaskViewMap: taskToken is null');
    return null; // FIXME handle error
  }
  const showPrescriptions = reportData.prescription != undefined;
  const prescriptionToShow = reportData.prescription;
  const photosMetadata = reportData.photosMetadata;
  const taskId = reportData.conteosPlantasId;
  // console.log('TaskViewMap:reportData', reportData);

  return (
    <>
      {photoModalUrl && (
        <Modal
          show={!!photoModalUrl}
          onHide={() => {
            setPhotoModalUrl(null);
          }}
          centered
          size='xl'
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img
              style={{
                width: '100%',
              }}
              src={photoModalUrl}
            />
          </Modal.Body>
        </Modal>
      )}
      {photosMetadata && (
        <MVectorLayer zIndex={30}>
          {photosMetadata.map((photoMetadata: any, i: number) => {
            return (
              <MCircleText
                key={photoMetadata.fileName}
                lat={+photoMetadata.GPSLatitude}
                lng={+photoMetadata.GPSLongitude}
                text={photoMetadata.densidad}
                radius={getRadius(photoMetadata.cov_cv)}
                color={getColor(photoMetadata.gap_cv)}
                hovered={i % 2 === 0}
                overlayContent={{
                  title: (
                    <PublicPhotoPopupTitle
                      photoMetadata={photoMetadata}
                      taskId={taskId}
                      openImageModal={(url: string) => {
                        setPhotoModalUrl(url);
                      }}
                    />
                  ),
                  content: <PhotoPopup photoMetadata={photoMetadata} taskId={taskId} />,
                }}
              />
            );
          })}
          <CountMapLegend showShareButton={true} task={reportData} />
        </MVectorLayer>
      )}
      {prescriptionToShow && (
        <MVectorLayer isVisible={showPrescriptions} zIndex={20}>
          {showPrescriptions && <PrescriptionPolygons prescription={prescriptionToShow} />}
        </MVectorLayer>
      )}
    </>
  );
}

export const PublicPhotoPopupTitle = ({ photoMetadata, taskId, openImageModal }: any) => {
  return (
    <div className={style.popHeader}>
      <div className={style.photo}>
        <div
          onClick={() =>
            openImageModal(
              `${resultImageBasePath(taskId)}/${photoMetadata.img_name.replace('JPG', 'PNG')}`,
            )
          }
        >
          <Camera style={{ fill: 'blue', width: '28px', height: '28px' }} />
          <div>{photoMetadata.img_name}</div>
        </div>
        {/* </DownloadLinkToken> */}
      </div>
      <div className={style.density}>
        <h2>Densidad</h2>
        <div className={style.densityValue}>
          <span
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            {photoMetadata.densidad}
          </span>
          <span
            style={{
              fontSize: '1rem',
            }}
          >
            pl/ha
          </span>
        </div>
      </div>
    </div>
  );
};
