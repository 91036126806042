import styles from './Skeleton.module.scss'
interface SkeletonProps {
    sx?: React.CSSProperties

}
export function Skeleton ({sx} : SkeletonProps) {
    return (
        <div style={sx} className={styles.skeleton} >

        </div>
    )
}