import { Popover2 } from '@blueprintjs/popover2';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'state/hooks';
import { setSelectCampaign } from 'state/slices/campaignSlice';

import { ReactComponent as CalendarIcon } from '../../assets/icons/ico-calendar.svg';
import { ReactComponent as Dots} from '../../assets/icons/ico-dots.svg'
import contextualStyle from '../../pages/TaskList/TaskListContextual/PqContextual/Contextual.module.scss';
import CampaignContextual from './Contextual/CampaignContextual';
import style from './SelectCampaign.module.scss'
interface Props {
    selected: boolean;
    campaign: any;
    campaignRef: any;
}

export default function CampaignItem ({selected = false, campaign, campaignRef}: Props) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleClick = () => {
        dispatch(setSelectCampaign(campaign));
        navigate('/projects')
    }

    return (
        <div 
            className={style.campaignItem} 
            ref={campaignRef}
            tabIndex={-1}
        >
            <div className={style.info} onClick={() => handleClick()} >
                <CalendarIcon className={style.calendarIcon}/>
                <div>
                   <p style={{fontWeight: selected ? '900' : '500'}}>{campaign.name}</p>
                   <p className={style.text}>{`${campaign.numOfProjects} ${t('projects')}`}</p>
                </div>
            </div>

            <Popover2
                placement='right'
                interactionKind='click'
                usePortal={true}
                content={<CampaignContextual campaign={campaign}/>}
                className={style.popover}
            >
                <div className={contextualStyle.dotwrap}>
                    <Dots className={style.dotsIcon} />
                </div>
            </Popover2>
        </div>
    )
}