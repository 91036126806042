import { useGetMosaicSummaryQuery } from 'api/Vistaguay/MosaicAPI';
import { useCountInstanceStatusQuery,useCountServerStatusQuery, useGetTasksSumaryQuery } from 'api/Vistaguay/TaskAdminAPI';
import { ReactComponent as PlantcountIcon } from 'assets/icons/Cultivo-comodin.svg'
import { ReactComponent as MosaicIcon } from 'assets/icons/Mosaico-comodin.svg'
import { MOSAIC_STAGE } from 'models/MosaicTask';
import { TASK_TYPE } from 'models/Task';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import style from '../ControlPanel.module.scss';

interface ITaskSumary {
  count: number;
  name: string;
  status: number[];
  color?: string;
  taskType?: TASK_TYPE;
}

const TaskSumaryCell = ({ count, name, color, status, taskType = TASK_TYPE.conteo }: Partial<ITaskSumary>) => {
  const navigate = useNavigate();

  if (!name) return null;

  const GoToFilter = (status: number[]) => {
    if (taskType === TASK_TYPE.mosaico)
      navigate('/controlVistaguay/showFiltroMosaico?status=' + status.join(','));
    else {
      navigate('/controlVistaguay/showFiltroConteo?status=' + status.join(','));
    }

  };
  if (!status) return null;
  return (
    <div className={style.celdaTarea}>
      <h4 className={style.header}>{name.replace('DB_MOSAICO_STATUS_', '')}</h4>
      <div
        onClick={() => {
          GoToFilter(status);
        }}
        style={{ backgroundColor: color ? color : '#ffffff' }}
        className={style.celdaTareaCount}
      >
        {count}
      </div>
    </div>
  );
};

const notUndefined = (value: number | undefined) => (value ? +value : 0);

export const TaskSumaryRow = () => {
  const { data: tasksSumaryData, isLoading: tasksSumaryLoading } = useGetTasksSumaryQuery(null);
  const {
    data: serverStatus,
    isSuccess: serverStatusSucces,
    isFetching: isFetchingServerStatus,
    refetch: refetchStatus,
  } = useCountServerStatusQuery(null);

  const { data: infoInstances, 
    isSuccess: instanceStatusSucess, 
    isFetching: isFetchingInstanceStatus,
    refetch: refetchInfo,
  } = useCountInstanceStatusQuery(null);

  const { data: mosaicSummaryData, isLoading: mosaicSummaryLoading } = useGetMosaicSummaryQuery(null);
  const handleReCheck = () => {
    refetchStatus();
    refetchInfo();
  };

  if (!tasksSumaryData || tasksSumaryLoading || !mosaicSummaryData || mosaicSummaryLoading) return null;

  const newCount = notUndefined(tasksSumaryData.find((item: any) => item.status === 0)?.count);
  const process = notUndefined(tasksSumaryData.find((item: any) => item.status === 1)?.count);
  const published = notUndefined(tasksSumaryData.find((item: any) => item.status === 2)?.count);
  const errorreport = notUndefined(tasksSumaryData.find((item: any) => item.status === 7)?.count);
  const review = notUndefined(tasksSumaryData.find((item: any) => item.status === 3)?.count);
  const broken = notUndefined(tasksSumaryData.find((item: any) => item.status === 4)?.count);
  const discard = notUndefined(tasksSumaryData.find((item: any) => item.status === 9)?.count);
  const finish = notUndefined(tasksSumaryData.find((item: any) => item.status === 10)?.count);


  const mosaicNuevos = notUndefined(mosaicSummaryData.find((item: { estadoId: number, count: string }) => item.estadoId === MOSAIC_STAGE.NEW)?.count);
  const mosaicProcesando = notUndefined(mosaicSummaryData.find((item: { estadoId: number, count: string }) => item.estadoId === MOSAIC_STAGE.PROCESSING)?.count);
  const mosaicReprocesando = notUndefined(mosaicSummaryData.find((item: { estadoId: number, count: string }) => item.estadoId === MOSAIC_STAGE.REPROCESS)?.count);
  const mosaicPublicado = notUndefined(mosaicSummaryData.find((item: { estadoId: number, count: string }) => item.estadoId === MOSAIC_STAGE.PUBLISHED)?.count);
  const mosaicRotos = notUndefined(mosaicSummaryData.find((item: { estadoId: number, count: string }) => item.estadoId === MOSAIC_STAGE.BROKEN)?.count);
  const mosaicDescartados = notUndefined(mosaicSummaryData.find((item: { estadoId: number, count: string }) => item.estadoId === MOSAIC_STAGE.DISCARD)?.count);

  return (
    <div className={style.contentWrapper} style={{margin:'0px'}}>

      
      <div className={style.filaTareas}> 

      <button onClick={handleReCheck}> Actualizar estados </button>

        { infoInstances && infoInstances.instances ? infoInstances.instances.map((info:any, index:number)=>{

          const estado = infoInstances.instances[index].estado;
          const tiempo = infoInstances.instances[index].tiempo ? infoInstances.instances[index].tiempo : null;
          const nombre = infoInstances.instances[index].nombre;

          return(

          <div key={ index } className={style.celdaTarea}>
          <div className={`${style.celdaTareaCount} ${estado === 'running' ? style.running : style.stopped}`}>
                  {isFetchingInstanceStatus ? <Spinner />: instanceStatusSucess ? ( 
                  <><h5> {estado === 'running' ? 'ON' : 'OFF'} 
                  </h5> <span> {tiempo} </span> </> 
                ) : 'Error'}
          </div>
          </div>
          )

        }): null }


          <div className={style.celdaTarea}>
          <h4>Python Engine:{' '}</h4>
          <div className={style.celdaTareaCount} style={{ backgroundColor : '#FFFFFF' }}>{isFetchingServerStatus ? <Spinner /> : serverStatusSucces ? 'ON' : 'Error'}</div>
          
          </div>
          
      </div>
      <div className={style.filaTareas}>

        <PlantcountIcon className={style.iconCell} />

        <TaskSumaryCell
          color={'#ffffff'}
          count={newCount + process + review}
          name='Nuevos/Procesando/Revisar'
          status={[0, 1, 3]}
        />
        <TaskSumaryCell color={'#b14444'} count={broken + errorreport} name='Rotos' status={[4, 7]} />
        <TaskSumaryCell color={'#0c3b3d'} count={discard} name='Descartados' status={[9]} />
        <TaskSumaryCell color={'#236067'} count={finish} name='Terminados' status={[10]} />
        <TaskSumaryCell color={'#57ef74'} count={published} name='Publicados' status={[2]} />


      </div>
      <div className={style.filaTareas}>
        <MosaicIcon className={style.iconCell} />

        <TaskSumaryCell
          taskType={TASK_TYPE.mosaico}
          color={'#ffffff'}
          count={mosaicNuevos + mosaicProcesando}
          name='Nuevos/Procesando'
          status={[0, 1, 3]}
        />
        <TaskSumaryCell taskType={TASK_TYPE.mosaico} color={'#b14444'} count={mosaicRotos} name='Rotos' status={[MOSAIC_STAGE.BROKEN]} />
        <TaskSumaryCell taskType={TASK_TYPE.mosaico} color={'#0c3b3d'} count={mosaicDescartados} name='Descartados' status={[MOSAIC_STAGE.DISCARD]} />
        <TaskSumaryCell taskType={TASK_TYPE.mosaico} color={'#236067'} count={mosaicReprocesando} name='Reprocesando' status={[MOSAIC_STAGE.REPROCESS]} />
        <TaskSumaryCell taskType={TASK_TYPE.mosaico} color={'#57ef74'} count={mosaicPublicado} name='Publicados' status={[MOSAIC_STAGE.PUBLISHED]} />
      </div>
    </div>
  );
};
