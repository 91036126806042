import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import { useAddPrescriptionsMutation, useGetPrescriptionsQuery } from 'api/Vistaguay/LotAPI';
import { ReactComponent as PlusIcon } from 'assets/icons/ico-plus.svg';
import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';
import { CheckBox } from 'components/CheckBox/CheckBox';
import DateInput from 'components/Inputs/DateInput/DateInput';
import { Input, InputSelect } from 'components/Inputs/Input';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { validateGeoJSONasPolygonOrMultipolygon } from 'services/geojsonValidation';
import { readFileAsAJson } from 'services/helpers';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setDistanceId, setName, setPrescription, setShowPrescription, setSowDate, setSowTypeId, setSurco, setTargetDensity } from 'state/slices/pqFormSlice';

import globalInputStyles from '../../../../components/Inputs/GlobalInputStyles.module.scss'
import style from '../../AddTask.module.scss';
import { ErrorList } from '../AddPlantCount';


interface StepProps {
  nextStepTouched: boolean;
  hasError: boolean;
  currentStep: string | number;
  handleError: (error: boolean) => void;
}

const StepSowing = ({ 
  hasError, 
  handleError, 
  currentStep, 
  nextStepTouched 
}: StepProps) => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { lotId, projectId } = useParams();

  const [
    addPrescription, 
    { 
      isSuccess: isAddPrescriptionSuccess, 
      data: addPrescriptionData 
    }
  ] = useAddPrescriptionsMutation();

  const [invalidFormat, setInvalidFormat] = useState('');
  const [selectedSurco, setSelectedSurco] = useState('');
  const [errorsList, setErrors] = useState<ErrorList[]>([]);
  const [densityInputTouched ,setDensityInputTouched] = useState(false);

  const token = useAppSelector((state) => state.data.accessToken);
  const {
    pqForm,
    prescription,
  } = useAppSelector((state) => state.pqFormState);

  const { data: extraData } = useGetExtraDataQuery();

  if (!projectId || !lotId) {
    return <>Error</>;
  }

  const { data: prescriptions } = useGetPrescriptionsQuery({
    lotId: +lotId,
    projectId: +projectId,
  });

  useEffect(() => {
    if (isAddPrescriptionSuccess) {
      const pres = prescriptions?.find((p) => p.id === addPrescriptionData.id);
      if (pres) {
        pres.id !== prescription?.id && dispatch(setPrescription(pres));
      }
    }
  }, [isAddPrescriptionSuccess, addPrescriptionData, prescriptions, dispatch, prescription]);

  useEffect(() => {
    if (currentStep == 2 ) {
      const errors: ErrorList[] = [];
      let error = false; 
    
      if (!pqForm?.fechaSiembra) {
        errors.push({ message: t('form-seeding-date-required'), propName: 'fechaSiembra' });
      } else if (new Date(pqForm.fechaSiembra) > new Date() ) {
        errors.push({ message: t('form-seeding-date-error'), propName: 'fechaSiembra' });
      } else if (!pqForm?.dSurco) {
        errors.push({ message: t('form-dsurco-required'), propName: 'dSurco' });
      } else if (!pqForm?.sowTypeId) {
        errors.push({ message: t('form-sow-type-required'), propName: 'sowType' });
      } else if (pqForm.sowTypeId == 1) {
        if (!pqForm?.targetDensity || pqForm?.targetDensity == 0) {
          errors.push({ message: t('form-target-density-required'), propName: 'targetDensity' });
        }
      } 
      
      if (pqForm.sowTypeId == 2) {
        if (prescription == null || !pqForm?.densidadesJson) {
          errors.push({ message: t('form-prescription-warning-msg'), propName: 'densidadesJson' });
        } else {
          const densidadesJsonValidation = pqForm.densidadesJson.map((d: {density: number}) => d.density == 0);
          if (densidadesJsonValidation.some((isTrue: boolean) => isTrue)) {
            errors.push({ message: t('form-prescription-warning-msg'), propName: 'densidadesJson' });
          }
        }
      }
    
      if(errors.length > 0) { 
        error = true;
      } 
        
      setErrors(errors);
      handleError(error);
    }
  }, [
    pqForm.fechaSiembra,
    pqForm.dSurco,
    pqForm.sowTypeId,
    pqForm.densidadesJson,
    pqForm.targetDensity,
    prescription,
    dispatch
  ])

  const onChangeFile = async (event: any) => {
    try {
      if (event.target.files) {
        const parsedData = await readFileAsAJson({
          file: event.target.files[0],
          token,
        });

        if (event.target.files[0].name.length > 45) {
          return ErrorToaster('El nombre de archivo no debe tener mas de 45 caracteres');
        }

        if (validateGeoJSONasPolygonOrMultipolygon(parsedData)) {
          if(parsedData?.features && parsedData.features.length > 15){
            const errs = errorsList;
            errs.push({propName: 'max-num-records-allowed', message: t('max-num-records-allowed')})
            setErrors(errs)
            handleError(true);
            return
          }

          const prescriptionNew = {
            prescription: parsedData,
            createdAt: String(Date.now()),
            name: event.target.files[0].name,
          };
          
          addPrescription({
            lotId: Number(lotId),
            projectId: Number(projectId),
            prescription: prescriptionNew,
          });
        } else {
          ErrorToaster('Error al cargar prescripción.');
        }
      }
    } catch (e) {
      if (e === 'Archivo inválido') {
        setInvalidFormat('Archivo inválido.')
      }
      
      console.error(`Error al cargar prescipción: ${e}`);
      return false;
    }

    return false;
  };

  return (
    <div className={style.bodyContent}>
      {/* Nombre Tarea */}
      <div className={style.bodyContentRow}>
        <span>{t('taskName')}</span>
        <span className={style.smallLegend}> ({t('opcional')})</span>
        <Input
          id={'pq-name'}
          name={'pq-name'}
          placeholder=''
          value={pqForm.nombre || ''}
          onChange={(e) => {
            if (e.target.value !== '') {
              dispatch(setName(e.target.value));
            }
          }}
        />
      </div>

      {/* Fecha de Siembra */}
      <div className={style.bodyContentRow}>
        <p>{t('form-seeding-date')}</p>
        <DateInput
          dateFormat={'dd/MM/yyyy'}
          wrapperClassName={globalInputStyles.inputWrapper}
          inputclassName={globalInputStyles.input1}
          value={ pqForm?.fechaSiembra ? new Date(pqForm.fechaSiembra) : undefined}
          handleChange={(e) => {
            if(e) {
              const newDateString = e.toISOString();
              dispatch(setSowDate(newDateString));
            }
          }}
        />
        {
          nextStepTouched && errorsList.map((error, index) => {
            if (error.propName === 'fechaSiembra') {
              return <Alert key={index} type='error' text={error.message} />;
            } else {
              return null; 
            }
          })
        }
      </div>

      {/* DISTANCIA ENTRE SURCOS */}
      <div className={style.bodyContentRow}>
        <label>{t('distancing')}</label>
        <InputSelect
          placeholder={t('distancing')}
          options={
            extraData?.distances?.map((distance) => ({
              value: distance.id,
              name: distance.distance + ' cm',
            })).sort((a, b) => a.name.localeCompare(b.name)) || []
          }
          value={selectedSurco || ''}
          onChange={(e) => { 
            setSelectedSurco(e.target.value);
            const dSurco = extraData?.distances?.filter((d) => d.id === +e?.target?.value)[0];
            if(dSurco) { 
              dispatch(setSurco(dSurco.distance))
              dispatch(setDistanceId(dSurco.id)) 
            }
          }}
          // onBlur={() => { !pqForm.dSurco ? setDistancingError(true) : setDistancingError(false) }}
        >
          <option value={''} disabled>{t('select-placeholder')}</option>
        </InputSelect>

        {
          nextStepTouched && errorsList.map((error, index) => {
            if (error.propName === 'dSurco') {
              return <Alert key={index} type='error' text={error.message} />;
            } else {
              return null; // Return null if the condition is not met
            }
          })
        }
      </div>

      {/* TIPO DE SIEMBRA */}
      <div className={style.bodyContentRow}>
        <label style={{ marginBottom: '10px' }}>{t('sowing-type')}</label>
        <div style={{ display: 'flex', gap: '20px' }}>
          {extraData && extraData.seedings.map((seeding) => {
            return (
              <CheckBox
                key={seeding.id}
                label={seeding.name}
                checked={pqForm.sowTypeId == seeding.id}
                name={seeding.name}
                id={seeding.name + seeding.id}
                value={seeding.id}
                onChange={(e) => {
                  e.preventDefault();
                  dispatch(setSowTypeId(+e.target.value))
                }}
              />
            );
          })}
        </div>

        { nextStepTouched && errorsList.map((error, index) => {
            if (error.propName === 'sowType') {
              return <Alert key={index} type='error' text={error.message} />;
            } else {
              return null; 
            }
        })}
      </div>

      {/* PRESCRIPCION */}
      {pqForm.sowTypeId === 2 && prescriptions && (
        <div style={{ marginBottom: '10px' }}>
          <span>{t('form-prescription')} (Rx)</span>
          <div className={style.selectPrescriptionWrapper} style={{ marginBottom: '10px' }}>
            <InputSelect
              id={'prescripctionRx'}
              name='prescriptionrxs'
              placeholder=''
              options={
                prescriptions?.map((prescription) => ({
                  value: prescription.id,
                  name: `${prescription?.name ? prescription.name.split('.')[0] : prescription.createdAt.slice(0, 10)}`,
                })) || []
              }
              value={prescription?.id || ''}
              onChange={(e) => {
                console.log('on change pres:', e)
                if (prescriptions !== null) {
                  const pres = prescriptions?.find((p) => p.id === +e.target.value);
                  if (pres) {
                    dispatch(setPrescription(pres));
                    dispatch(setShowPrescription(true));
                  }
                }
              }}
            >
              <option value='' disabled>{t('select-placeholder')}</option>
            </InputSelect>

            <Button
              width='45px'
              style={{padding: '0px'}}
              text=''
              variant='INFO'
              icon={<PlusIcon style={{ stroke: 'white' }} />}
              onClick={() => inputFile.current?.click()}
            />
          </div>

          {
            invalidFormat !== '' && (
              <Alert type='error' text={invalidFormat} />
            )
          }

          {
            errorsList.map((error, index) => {
              if (error.propName === 'densidadesJson') {
                return <Alert key={index} type='warning' text={error.message} />;
              } else {
                return null; 
              }
            })
          }
        </div>
      )}

      {/* FILE SELECTOR */}
      <input
        type='file'
        id='file'
        name='files'
        ref={inputFile}
        style={{ display: 'none' }}
        accept='.geojson,application/zip,application/x-zip-compressed,.shp,.shx,.dbf'
        onChange={(e) => onChangeFile(e)}
      />

      {/* DENSIDAD OBJETIVO */}
      {pqForm.sowTypeId === 1 && (
        <div style={{ marginBottom: '10px' }}>
          <span>Densidad objetivo (pl/ha) </span>
          <Input
            id={'target-density'}
            name={'targe-density'}
            value={pqForm.targetDensity || ''}
            onKeyPress={(event) => {
              if (event.key == 'Backspace') {
                return;
              } else if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
              if (String(event.currentTarget.value).length >= 6) {
                event.preventDefault();
              }
            }}
            placeholder={t('target-density')}
            onChange={(e) => { dispatch(setTargetDensity(+e.target.value)) }}
            onFocus={() => setDensityInputTouched(true)}
          />
          {
            densityInputTouched && nextStepTouched && errorsList.map((error, index) => {
              if (error.propName === 'targetDensity') {
                return <Alert key={index} type='error' text={error.message} />;
              } else {
                return null; 
              }
            })
          }
        </div>
      )}
    </div>
  );
}

export default StepSowing;