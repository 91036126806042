import style from '../PhotoDescriptionSection.module.scss';

interface IZoomBoxProps {
    src: string;
    zoomPosition: { x: number; y: number };
  }
  
  export const ZoomBox = ({ src, zoomPosition }: IZoomBoxProps) => {
    return (
      <div className={style.zoombox}>
        <img
          src={src}
          alt="Zoomed"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            transformOrigin: '0 0',
            transform: `translate(-${zoomPosition.x * 4}%, -${zoomPosition.y * 4}%) scale(5)`,
          }}
        />
      </div>
    );
  };
  