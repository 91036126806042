import { useRef } from 'react';
import Draggable from 'react-draggable';
import { MosaicTaskView } from 'state/slices/mosaicSlice';

import style from './Histogram.module.scss';
import HistogramOptionStepOne from './HistogramOption';

interface HistogramModalProps {
  mosaicView: MosaicTaskView;
  handdleClose: () => void;
}

export const HistogramModal = ({ mosaicView, handdleClose }: HistogramModalProps) => {
  const nodeRef = useRef(null);

  if (!mosaicView) return null;
  return (
    <>
      <Draggable nodeRef={nodeRef} defaultPosition={{ x: 300, y: -200 }} cancel='.no-drag' disabled={true} >
        <div ref={nodeRef} className={style.modal}>
          <span
            onClick={() => handdleClose()}
            style={{ cursor: 'pointer', position: 'absolute', right: 0, top: 0, padding: '5px' }}
          >
            X
          </span>
          <HistogramOptionStepOne mosaicView={mosaicView} />
        </div>
      </Draggable>
    </>
  );
};
