import { Spinner, SpinnerSize } from '@blueprintjs/core'
import { Tooltip } from '@mui/material'
import { IMosaicFilterQuery,useDeleteMosaicFilesMutation, useGetAlgorithmStatusQuery, useGetFilteredMosaicsQuery, useRestartAlgorithmMutation, useUpdateStatusMosaicMutation } from 'api/Vistaguay/MosaicAPI'
import { IUserSearch, useGetUserByUsernameQuery } from 'api/Vistaguay/TaskAdminAPI'
import Button from 'components/Button/Button'
import { DownloadLink } from 'components/DownloadLink/DownloadLink'
import { DatePicker, Input, InputSelect } from 'components/Inputs/Input'
import { useDebounce } from 'hooks/useDebounce'
import { Algorithm,ITypeAlgo } from 'models/Algorithm'
import MosaicTask, { MOSAIC_STAGE } from 'models/MosaicTask'
import { ChangeEvent, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from './ControlPanel.module.scss'


export const getTaskStatusColor = (status: number) : string => {
    switch(status) {
        case MOSAIC_STAGE.NEW:
            return styles.darkdisabled
        case MOSAIC_STAGE.PROCESSING:
            return styles.azul
        case MOSAIC_STAGE.PUBLISHED:
            return styles.verdeVistaguay
        case MOSAIC_STAGE.BROKEN:
            return styles.rojo
        case MOSAIC_STAGE.REPROCESS:
            return styles.c2
        case MOSAIC_STAGE.DISCARD:
          return styles.gris
        default: 
            return '#f2f2f2'
            break
    }
}


const initValues: IMosaicFilterQuery = {
    username: '',
    userId: '',
    name: '',
    status: '',
    mosaicTaskId: '',
    page: '',
    limit: '',
    createdAfter: '',
    createdBefore: ''

}

export default function MosaicTable() {

    const statusQuery = new URLSearchParams(useLocation().search).get('status')

    const [initFilters, setInitFilters] = useState<IMosaicFilterQuery>({
        ...initValues,
        status: statusQuery ? statusQuery : ''
    })
    const debunceFilters = useDebounce<IMosaicFilterQuery>(initFilters, 1000)
    const [searchUser, setSearchUser] = useState<string>('')
    const [searchUserId, setSearchUserId] = useState<string>('')
    const debounceSearchUserId = useDebounce<string>(searchUserId || '', 1000)
    const debounceSearch = useDebounce<string>(searchUser || ' ', 1000)
    const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    

    const {
        data: mosaicData,
        isLoading: mosaicLoading,
        isFetching: mosaicFetching,
        refetch: refetchMosaic,
    } = useGetFilteredMosaicsQuery(debunceFilters)

    const {
        data: usersData = [] as IUserSearch[],
        isLoading: usersLoading,
        isFetching: usersFetching,
        refetch: refetchUsers,
    } = useGetUserByUsernameQuery({ username: debounceSearch || '' })

    useEffect(() => {
        refetchMosaic()
        
    }, [debunceFilters, refetchMosaic])

    useEffect(() => {
        setInitFilters({
            ...initFilters,
            userId: debounceSearchUserId
        })
    }, [debounceSearchUserId])

    useEffect(() => {
        refetchUsers()
    }, [debounceSearch, refetchUsers])


    return (
        <section className={styles.contentWrapper}>
            <Button style={{height:'30px'}} variant='SUCCESS' text='<< Volver' onClick={
                () => {
                    navigate('/controlVistaguay')
                }
            }/>
            <h1 style={{color:styles.light}}>Mosaicos</h1>
            <header>
                <div style={{display:'flex',gap:'20px'}} >
                    <div style={{width:'200px',display:'flex'}}>
                        <label>Desde:</label>
                        <Input 
                            type='date' 
                            placeholder=''  
                            onChange={(e) => setInitFilters({ ...initFilters, createdAfter: encodeURIComponent(new Date(e.target.value).toISOString()) })}
                        />
                    </div>
                    <div style={{width:'200px',display:'flex'}}>
                        <label>Hasta:</label>
                        <Input 
                        
                            type='date' 
                            placeholder='' 
                            onChange={(e) => {
        
                                setInitFilters((state) => ({...state ,createdBefore:  encodeURIComponent(new Date(e.target.value).toISOString())}))
                                console.log(initFilters)
                            }}
                        />
                    </div>
                    <p>Mosaicos encontrados: {mosaicData?.length}</p>
                </div>
                <div style={{display:'flex',gap:'2px'}}>

                    <p>Filtros: </p>
                    <div >
                        <Input placeholder='Nombre de usuario' value={searchUser}
                            onFocus={() => {
                                setSearchIsOpen(true)
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    setSearchIsOpen(false)
                                }, 200);
                            }}
                            onChange={(e) => {
                                setSearchUser(e.target.value)
                                setInitFilters({ ...initFilters, username: e.target.value })
                            }}
                        />

                        <ul className={styles.searchBox} style={{ display: searchIsOpen ? 'grid' : 'none'}}>
                            {
                                !usersLoading && usersData.map((user: IUserSearch) => (
                                    <li key={user.usuarioId} onClick={() => {
                                        setSearchUser(user.nombre)
                                    }}>{user.nombre}</li>
                                ))
                            }
                            {
                                usersFetching &&
                                <Spinner size={SpinnerSize.SMALL} />
                            }
                        </ul>
                    </div>
                    <Input placeholder='Id de usuario' value={initFilters.userId} onChange={(e) => setInitFilters({ ...initFilters, userId: e.target.value })} />
                    <Input placeholder='Nombre de mosaico' value={initFilters.name} onChange={(e) => setInitFilters({ ...initFilters, name: e.target.value })} />
                    <Input placeholder='Id de mosaico' value={initFilters.mosaicTaskId} onChange={(e) => setInitFilters({ ...initFilters, mosaicTaskId: e.target.value })} />
                    <InputSelect placeholder='Estado' value={initFilters.status} onChange={(e) => setInitFilters({ ...initFilters, status: e.target.value })}>
                        {
                            Object.keys(MOSAIC_STAGE).map((key: string, index: number) => (
                                // dont render NaN value
                                isNaN(+key) ? null :
                                    <option key={key} value={+key}>{MOSAIC_STAGE[+key]}</option>
                            ))
                        }
                        <option key={'allStatus'} value={'0,1,2,3,6,7'}>TODOS</option>
                    </InputSelect>
                </div>
            </header>
            {mosaicFetching ?

                <div className={styles.loadingList}>
                    <Spinner size={SpinnerSize.STANDARD} />
                </div>
                :
                <section className={styles.taskTable}>
                    <header>
                        <p>ID</p>
                        <p>Usuario</p>
                        <p>Estado</p>
                        <p>Nombre</p>
                        <p>Campania</p>
                        <p>Fecha de creacion</p>
                        <p>Algoritmos</p>
                    </header>
                    <ul className={styles.taskList}>
                        {
                            mosaicData &&
                            mosaicData.map((mosaic: MosaicTask) => (
                                <MosaicTableItem key={mosaic.mosaicoId} mosaic={mosaic} />
                            ))
                        }
                        {
                            mosaicData?.length == 0 &&
                            <li style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <h3 style={{ color: styles.rojo }}>No se encontraron mosaicos</h3>
                            </li>
                        }
                    </ul>
                </section>
            }
        </section>
    )
}


const MosaicTableItem = ({ mosaic }: { mosaic: MosaicTask }) => {
    const { data: algorithmData, isLoading: algorithmLoading, isSuccess: algorithmSuccess, isError: algorithmError } = useGetAlgorithmStatusQuery({ taskId: String(mosaic.mosaicoId) })
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <li key={mosaic.mosaicoId} className={styles.tableItem} onClick={() => setIsOpen(!isOpen)}>
                <p style={{ color: styles.rojo }}>{mosaic.mosaicoId}</p>
                <p>{mosaic.creator?.nombre}</p>
                <p>{MOSAIC_STAGE[mosaic.estadoId ? +mosaic.estadoId : 0]}</p>
                <p>{mosaic.nombre}</p>
                <p>{mosaic.campaign?.name}</p>
                <p>{mosaic.createdAt != null ? mosaic.createdAt.split('T')[0] : '' }</p>
                <p>
                    {
                        algorithmData ? algorithmData.map((alg: Algorithm) => (
                            <Tooltip key={alg.algo_id} title={MOSAIC_STAGE[alg.estado_id]}>
                                <span className={styles.success} style={{background: getTaskStatusColor(alg.estado_id)}}>{ITypeAlgo[alg.tipo_algo_id]}</span>
                            </Tooltip>
                        ))
                            :
                            <span className={styles.neutral} >No hay algoritmos</span>
                    }
                </p>
            </li>
            {
                isOpen &&
                <MosaicInfo mosaic={mosaic} algorithmData={algorithmData} />
            }

        </>
    )

}

const MosaicInfo = ({ mosaic, algorithmData }: { mosaic: MosaicTask, algorithmData?: Algorithm[] }) => {
    const [updateStatus] = useUpdateStatusMosaicMutation()
    const [deleteFiles,{data}] = useDeleteMosaicFilesMutation()
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
    const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setModalIsOpen({ isOpen: true, newStatus: +e.target.value })
    }
    const [reprocessAlgo, {isLoading: algoLoading, isSuccess: algoSuccess, isError:algoError}] = useRestartAlgorithmMutation()
    const [algo, setAlgo] = useState<number>()
    const [copyDisabled,setCopyDisabled] = useState(false)
    const handleCopyPath = () => {
        setCopyDisabled(true)
        navigator.clipboard.writeText(`/home/ubuntu/backend/uploads/0/${mosaic.creator?.usuarioId}/${mosaic.lot?.id}/mosaico/${mosaic.mosaicoId}`)
        setTimeout(() => {
            setCopyDisabled(false)
        }, 1000);
    }
    const [modalIsOpen, setModalIsOpen] = useState({ isOpen: false , newStatus: 0})

    const handleReprocess = (tipAlgoId: number, algoId: number) => {
        setAlgo(algoId)
        reprocessAlgo({taskId:String(mosaic.mosaicoId) ,algoId: String(tipAlgoId)})
    }   

    useEffect(() => {
        if(algoSuccess || algoError) {
            setTimeout(() => {
                setAlgo(undefined)
            },2000)
        }
    },[algoLoading,algoSuccess,algoError])

    return (
        <li className={styles.info} key={`mosaicInfo:${mosaic.mosaicoId}`}>
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 0, }}>
                    <p>Total de archivos subidos: <span>{mosaic.nImagenes}</span></p>
                    <p>Fecha de vuelo: <span>{mosaic.fechaVuelo}</span></p>
                    <div style={{display:'flex',flexDirection:'row', margin:0,padding:0}}>
                        <p className={styles.phover}>Ruta: <span>{`/home/ubuntu/backend/uploads/0/${mosaic.creator?.usuarioId}/${mosaic.lot?.id}/mosaico/${mosaic.mosaicoId}`}</span></p>
                        <Button disabled={copyDisabled} variant={'SUCCESS'} style={{height:'30px',fontSize:'13px'}} onClick={handleCopyPath} text={!copyDisabled ? 'Copiar' : 'Copiado..'} />
                    </div>
                    <p>Email: <span>{mosaic.creator?.email}</span></p>
                    <p>Camara: <span>{mosaic.tipoCamara}</span></p>
                    <p>Altura de vuelo: <span>{mosaic.alturaVuelo}m</span></p>
                    <p>Eliminar archivos :</p>
                    <Button text={'Eliminar archivos'} variant={'ERROR'}  style={{fontSize:'12px',height:'25px',width:'200px'}} onClick={() => setDeleteModalIsOpen(true)}/>
                    {
                        data && data === true && <p style={{color: styles.rojo, fontWeight:'normal'}}>Eliminados con exito...</p>
                    }
                    <div style={{display:'flex',margin:0,padding:0,maxWidth:'200px'}}>
                        <label id='estado' style={{fontWeight:'bold'}} >
                            Estado:
                        </label>
                        <InputSelect placeholder='Estado' value={mosaic.estadoId} onChange={(e) => handleStatusChange(e)} sx={{ margin:0 }}>
                            {
                                Object.keys(MOSAIC_STAGE).map((key: string, index: number) => (
                                    // dont render NaN value
                                    isNaN(+key) ? null :
                                        <option key={key} value={+key}>{MOSAIC_STAGE[+key]}</option>
                                ))

                            }
                        </InputSelect>
                    </div>
                </div>
                <Modal 
                    isOpen={deleteModalIsOpen} 
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: styles.verdeOscuro,
                            borderRadius:'12px',
                            border: 'none',
                            width: '300px', // or any custom width
                            height: 'auto', // or any custom height
                        },
                        overlay: {
                            backgroundColor: 'none',
                        }
                    }} >
                    <div className={styles.modal}  >
                        <p >Estas seguro de eliminar la carpeta <span style={{fontSize:'15px', color:styles.verdeVistaguay, fontWeight:'bold'}}>{`/home/ubuntu/backend/uploads/0/${mosaic.creator?.usuarioId}/${mosaic.lot?.id}/mosaico/${mosaic.mosaicoId}`}</span>?</p>
                        <div style={{display:'flex',justifyContent:'space-between',width:'100%',marginTop:'5px'}} >
                            <Button variant={'ERROR'} style={{height:'30px',fontSize:'13px'}} onClick={() => setDeleteModalIsOpen(false)} text='Cancelar' />
                            <Button variant={'SUCCESS'} style={{height:'30px',fontSize:'13px'}} onClick={() => {
                                deleteFiles({taskId: String(mosaic.mosaicoId)})
                                setDeleteModalIsOpen(false)
                            }} text='Aceptar' />
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={modalIsOpen.isOpen} style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: styles.verdeOscuro,
                        borderRadius:'12px',
                        border: 'none',
                        width: '300px', // or any custom width
                        height: 'auto', // or any custom height
                    },
                    overlay: {
                        backgroundColor: 'none',
                    }
                }} >
                    <div className={styles.modal} style={{color:styles.light}}>
                        <p >Estas seguro de cambiar el estado a <span style={{fontSize:'15px', color:styles.verdeVistaguay, fontWeight:'bold'}}>{MOSAIC_STAGE[modalIsOpen.newStatus]}</span>?</p>
                        <div >
                            <Button variant={'SUCCESS'} style={{height:'30px',fontSize:'13px'}} onClick={() => {
                                updateStatus({ taskId: String(mosaic.mosaicoId), status: modalIsOpen.newStatus })
                                setModalIsOpen({isOpen:false, newStatus:0})
                            }} text='Aceptar' />
                            <Button variant={'ERROR'} style={{height:'30px',fontSize:'13px'}} onClick={() => setModalIsOpen({isOpen:false, newStatus:0})} text='Cancelar' />
                        </div>
                    </div>
                </Modal>
                <DownloadLink linkUrl={`mosaic/${mosaic.mosaicoId}/result/agisoft-report.pdf`} fileName='agisoft-report.pdf' >
                    agisoft-report.pdf
                </DownloadLink>
            </div>
            {
                algorithmData ?
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Tipo</th>
                                <th>Estado</th>
                                <th>Fecha actualizacion</th>
                                <th>Links</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                algorithmData.map((alg: Algorithm, i: number) => (
                                    <tr key={alg.algo_id}>
                                        <td>{alg.algo_id}</td>
                                        <td>{ITypeAlgo[alg.tipo_algo_id]}</td>
                                        <td >
                                            <div style={{display:'flex',alignItems:'center',flexDirection:'row', justifyContent:'center', margin:'0px', padding:'0px'}}>
                                                <p style={{fontSize:'14px',fontWeight:'bold', color:getTaskStatusColor(alg.estado_id)}}>
                                                    {MOSAIC_STAGE[alg.estado_id]}
                                                </p>
                                                {
                                                    algo != alg.algo_id &&
                                                    <Button text='Reprocesar' onClick={() => handleReprocess(alg.tipo_algo_id,alg.algo_id)} variant={'NEUTRAL'}  style={{color:styles.light,fontSize:'12px',padding:'2px  5px' ,margin:'0px 5px'}} />
                                                }
                                                {
                                                    algo == alg.algo_id && algoLoading &&
                                                    <Button text='Procesando' disabled={true} onClick={() => handleReprocess(alg.tipo_algo_id,alg.algo_id)} variant={'NEUTRAL'}  style={{color:styles.light,fontSize:'12px',padding:'2px 5px' ,margin:'0px 5px',width:'auto'}} icon={<Spinner size={14} style={{margin:'0px 5px'}} />} />
                                                }
                                                {
                                                     algo == alg.algo_id && algoError &&
                                                     <Button text='Error Reintentar' onClick={() => handleReprocess(alg.tipo_algo_id,alg.algo_id)} variant={'ERROR'}  style={{color:styles.light,fontSize:'12px',padding:'2px 5px' ,margin:'0px 5px',width:'auto'}} />
                                                }
                                                {
                                                    algo == alg.algo_id && algoSuccess && 
                                                    <Button text='Reprocesado!' onClick={() => handleReprocess(alg.tipo_algo_id,alg.algo_id)} variant={'SUCCESS'}  style={{color:styles.light,fontSize:'12px',padding:'2px 5px' ,margin:'0px 5px',width:'auto'}} />
                                                }
                                            </div> 
                                        </td>
                                        <td>
                                            {
                                                alg.tipo_algo_id == ITypeAlgo.MOSAICO ?
                                                mosaic.fechaUpdate &&
                                                new Date(mosaic.fechaUpdate).toLocaleDateString() + ' ' + new Date(mosaic.fechaUpdate).toLocaleTimeString()
                                                :
                                                alg.fecha_update != undefined &&
                                                new Date(alg.fecha_update).toLocaleDateString() + ' ' + new Date(alg.fecha_update).toLocaleTimeString()
                                            }
                                        </td>
                                        <td style={{ display: 'flex', gap: '10px' ,alignItems:'center',justifyContent:'flex-end'}}>
                                            {
                                                alg.tipo_algo_id == ITypeAlgo.MOSAICO ?
                                                <>
                                                    <DownloadLink linkUrl={`mosaic/${mosaic.mosaicoId}/result/${ITypeAlgo[alg.tipo_algo_id].toLowerCase()}.png`} fileName={`${ITypeAlgo[alg.tipo_algo_id]}.png`} >
                                                        {`${ITypeAlgo[alg.tipo_algo_id]}.png`}
                                                    </DownloadLink>
                                                    <DownloadLink linkUrl={`mosaic/${mosaic.mosaicoId}/result/crea_mosaico.log`} fileName={`${ITypeAlgo[alg.tipo_algo_id]}.log`} >
                                                        {`${ITypeAlgo[alg.tipo_algo_id]}.log`}
                                                    </DownloadLink>
                                                </>
                                                :
                                                <>
                                                    <DownloadLink linkUrl={`mosaic/${mosaic.mosaicoId}/result/reporte${ITypeAlgo[alg.tipo_algo_id].charAt(0).toUpperCase() + ITypeAlgo[alg.tipo_algo_id].slice(1).toLowerCase()}.pdf`} fileName={`reporte${ITypeAlgo[alg.tipo_algo_id]}.pdf`} >
                                                        {`reporte${ITypeAlgo[alg.tipo_algo_id]}.pdf`}
                                                    </DownloadLink>
                                                    <DownloadLink linkUrl={`mosaic/${mosaic.mosaicoId}/result/${ITypeAlgo[alg.tipo_algo_id].toLowerCase()}.tif`} fileName={`${ITypeAlgo[alg.tipo_algo_id]}.png`} >
                                                        {`${ITypeAlgo[alg.tipo_algo_id]}.tif`}
                                                    </DownloadLink>
                                                    <DownloadLink linkUrl={`mosaic/${mosaic.mosaicoId}/result/${ITypeAlgo[alg.tipo_algo_id].toLowerCase()}.log`} fileName={`${ITypeAlgo[alg.tipo_algo_id]}.log`} >
                                                        {`${ITypeAlgo[alg.tipo_algo_id]}.log`}
                                                    </DownloadLink>
                                                </>

                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: styles.light, alignItems: 'center' }}>
                        <h3 style={{ color: styles.rojo }}>No se encontraron algoritmos</h3>
                    </div>
            }
        </li>
    )
}