import styles from './Tooltip.module.scss'

type Props = {
    children: string | JSX.Element | JSX.Element[] | false
    text: string
    customClass?: string
    customTextStyle?: React.CSSProperties
}

export default function Tooltip ({ children, text, customClass = '', customTextStyle = {} }: Props) {
    return (
        <div className={`${styles.tooltip} ${customClass}`}>
            {children}
            <span className={styles.tooltiptext} style={customTextStyle}>{text}</span>
        </div>
    )
}
