import Button from 'components/Button/Button';

import styles from '../Menu.module.scss';

export default function DefaultOptions({ isActivedefaultOptions }: { isActivedefaultOptions: boolean }) {

    return (
      <div>
        <h5 style={{ fontSize: '12px', margin: '0', marginBottom: '10px' }}>¿Quierés exportar una capa?</h5>
        <Button text='GeoJSON' className={styles.buttons} />
        <hr style={{ margin: '0', color: '#F2F2F2' }} />
        <Button text='SHP(zip)' className={styles.buttons} />
        <hr style={{ margin: '0', color: '#F2F2F2' }} />
        <Button text='CSV' className={styles.buttons} />
        <hr style={{ margin: '0', color: '#F2F2F2' }} />
      </div>
    );
  };