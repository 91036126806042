/* eslint-disable camelcase */
import { polygon } from '@turf/turf';
import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import {
  useAddMappingMissionMutation,
  useEditMappingMissionMutation,
  useGetDifferentialAltitudeMutation,
  useGetMissionQuery,
  useGetMissionsQuery,
} from 'api/Vistaguay/VolareAPI';
import { ReactComponent as MappingIcon } from 'assets/icons/ico-mapeo.svg';
import { ReactComponent as VolareIcon } from 'assets/icons/volareIcon.svg'
import Alert from 'components/Alert/Alert';
import Spinner from 'components/Spinner/Spinner';
import { Stepper2 } from 'components/Steppers/Stepper2/Stepper2';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import CancelPopup from 'components/Vistaguay/CancelPopup/CancelPopup';
import StepsFormFooter from 'components/Vistaguay/StepsFormFooter/StepsFormFooter';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import { LotProjectItem, SlimHeader, SubHeader, TaskHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import { isMapping, MappingMission } from 'models/Mission';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { cameras } from 'services/mappingMissionGenerator';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setCurrentLot, setCurrentMission, setDiffentialAltitude, setEditMissionPolygon, setMissionBuilderParams, setMissionPolygon } from 'state/slices/volareSlice';

import { validateMission } from '../utils/MissionValidator';
import style from './MappingSidebar.module.scss';
import StepParams from './Steps/StepParams';
import StepFlightInfo from './Steps/StepsFlightInfo';

export const cameraKeys: string[] = [];
for (const key in cameras) {
  // eslint-disable-next-line no-prototype-builtins
  if (cameras.hasOwnProperty(key)) {
    // const camera = cameras[key];
    cameraKeys.push(key);
  }
}

export interface IMappingMissionParams {
  name: string;
  map_mis_altitude: number;
  map_mis_speed: number;
  direction: number;
  map_mis_camera: string;
  map_mis_ov_frontal: number;
  map_mis_ov_lateral: number;
  map_mis_vv?: number;
  map_mis_hv?: number;
}

const generateMissionObject = ({
  currentMission,
  point_list,
  poligon_vertex,
  differentialAltitude,
} : {
  currentMission: MappingMission
  point_list: any[],
  poligon_vertex: any[],
  differentialAltitude: any,
}) => {
  return {
    type: 'MAPPING',
    name: currentMission?.name,
    altitude: currentMission?.altitude,
    speed: currentMission?.speed,
    flight_direction: currentMission?.flight_direction,
    camera_name: currentMission?.camera_name,
    camera_vertical_field_of_view: currentMission?.camera_vertical_field_of_view,
    camera_horizontal_field_of_view: currentMission.camera_horizontal_field_of_view,
    frontal_overlap: currentMission.frontal_overlap,
    lateral_overlap: currentMission.lateral_overlap,
    polygon_vertices: poligon_vertex.map((point_list: any[], idx: any) => ({
      index: idx,
      lat: point_list[1],
      lng: point_list[0],
    })),
    waypoints: point_list.map((point_list: any[], idx: any) => ({
      index: idx,
      lat: point_list[1],
      lng: point_list[0],
      altitude: currentMission.altitude,
      speed: currentMission.speed,
    })),
    lastModifiedDatetime: new Date(),
    take_off_lat: differentialAltitude ? point_list[0][1] : null,
    take_off_lng: differentialAltitude ? point_list[0][0] : null,
  };
};


export const MappingSidebar = () => {
  // #region Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // #endregion

  // #region Redux States
  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );
  const globalState = useAppSelector((state) => state.volare);
  const campaign = useAppSelector((state) => state.campaign.selectedCampaign);
  const currentMission = useAppSelector((state) => state.volare.currentMission) as MappingMission;
  // #endregion

  // #region States
  const [errors, setErrors] = useState<string[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ nextStepTouched, setNextStepTouched ] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [showCancelModal, setShowCancelModal] = useState(false); 
  // #endregion
  
  // #region Params
  const { projectId, lotId, missionId } = useParams<{
    projectId: string;
    lotId: string;
    missionId?: string;
  }>();

  const campaignId = campaign?.id;

  if (!projectId || !lotId || !campaignId) {return null}
  // #endregion

  // const initialState: MappingMission = {
  //   waypoints: [],
  //   type: 'MAPPING',
  //   name: currentMission?.name,
  //   altitude: currentMission?.altitude || 100,
  //   speed: currentMission?.speed || 10,
  //   flight_direction: currentMission?.flight_direction || 0,
  //   frontal_overlap: currentMission?.frontal_overlap || 75,
  //   lateral_overlap: currentMission?.lateral_overlap || 75,
  //   camera_name: currentMission.camera_name || 'MAVIC_PRO',
  //   creationDateTime: new Date(),
    
  // }

  // #region Queries & Mutations
  const [
    getElevations,
    {
      data: elevations,
      isLoading: isElevationsLoading,
      isError: isElevationsError,
      error: elevationsError,
    },
  ] = useGetDifferentialAltitudeMutation();

  const [
    editMission, 
    { 
      isLoading: isEditMissionLoading 
    }
  ] = useEditMappingMissionMutation();

  const [
    addMission, 
    { 
      isLoading: isAddMissionLoading 
    }
  ] = useAddMappingMissionMutation();

  const {
    data: mission,
    isLoading: isCurrentMissionLoading,
    error: isCurrentMissionError,
  } = useGetMissionQuery(+missionId!, {
    skip: !missionId,
  });

  const { data: project, isLoading: isProjectLoading, isError: isProjectError } = useGetProjectQuery(+projectId)

  const {
    data: lot,
    isLoading: isLotLoading,
    error: isLotError,
  } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });
  // #endregion

  // #region Effects
  useEffect(() => {
    if (lot) {
      dispatch(setCurrentLot(lot));
    }
  }, [lot, dispatch]);

  useEffect(() => {
    if (isLotLoading || isCurrentMissionLoading || isElevationsLoading || isEditMissionLoading || isAddMissionLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    isLotLoading,
    isCurrentMissionLoading,
    isElevationsLoading,
    isEditMissionLoading,
    isAddMissionLoading,
  ]);

  useEffect(() => {
    // get lot polygon and store it in redux
    if (lot && lot.polygon && !missionId) {
      dispatch(setMissionPolygon(lot.polygon));
    } else if (missionId && currentMission) {
      const currentMissionPolygon = currentMission.polygon_vertices.map((point: any) => [
        point.lng,
        point.lat,
      ]);
      const polygonFromMission = polygon([currentMissionPolygon]);
      dispatch(setMissionPolygon(polygonFromMission));
    }
  }, [lot, dispatch, currentMission, missionId]);

  useEffect(() => {
    if (isElevationsError) {
      setErrors(['La cantidad de puntos para altura diferencial debe de ser menor a 350']);
      setIsError(true);
      console.error('There has been an error with Elevations Api');
    }
  }, [isElevationsError]);

  useEffect(() => {
    if(missionId && mission) {
      dispatch(setCurrentMission(mission))
    }
  }, [mission])

  // useEffect(() => {
  //   if (mappingMissionParams) {
  //     dispatch(setMissionBuilderParams(mappingMissionParams));
  //   }
  // }, [mappingMissionParams]);
  // #endregion

  // #region Handlers
  async function handleGenereteMissionObject ({
    lotId, 
    campaignId
  }: {
    lotId: number, 
    campaignId: number
  }) {
    const mission = generateMissionObject({
      currentMission: currentMission,
      point_list: globalState.mappingPoints,
      poligon_vertex: globalState.missionPolygon?.geometry.coordinates[0] ?? [],
      differentialAltitude: globalState.differentialAltitude,
    });

    const validationErrors = validateMission(mission);

    if (validationErrors.length == 0) {
      // correct altitud using elevations api
      if (globalState.differentialAltitude) {
        const pointsForElevation: {
          locations: { lat: number; lng: number }[];
        } = {
          locations: [],
        };

        globalState.mappingPoints.forEach((element) => {
          pointsForElevation['locations'].push({ lat: element[1], lng: element[0] });
        });

        // Get elevation from backend API
        const pointsWithElevation = await getElevations(pointsForElevation).unwrap();
        if (pointsWithElevation.status === 500) {
          ErrorToaster('There has been an error with Elevations Api');
          return;
        }

        const referencePoint = globalState.mappingPoints[0];
        let referenceElevation = 0;

        // toFixed(10) is used because elevation api responds sometimes with a small difference
        for (let i = 0; i < pointsWithElevation['results'].length; i++) {
          if (
            pointsWithElevation['results'][i]['location']['lat'].toFixed(10) ==
            referencePoint[1].toFixed(10) &&
            pointsWithElevation['results'][i]['location']['lng'].toFixed(10) ==
            referencePoint[0].toFixed(10)
          ) {
            referenceElevation = pointsWithElevation['results'][i]['elevation'];
            break;
          }
        }

        referenceElevation = Math.round(referenceElevation);
        const originalAltitude = mission['altitude'];
        // Update altitude in mission acording to elevation
        for (let i = 0; i < mission.waypoints.length; i++) {
          console.log(i);
          pointsWithElevation['results'].forEach((element: { [x: string]: any }) => {
            if (
              element['location']['lat'].toFixed(10) == mission.waypoints[i]['lat'].toFixed(10) &&
              element['location']['lng'].toFixed(10) == mission.waypoints[i]['lng'].toFixed(10)
            ) {
              const pointElevation = element['elevation'];
              const diff = pointElevation - referenceElevation;
              const correctedAltitude = Math.round(originalAltitude + diff);
              mission.waypoints[i]['altitude'] = correctedAltitude;
            }
          });
        }
      }

        const missionToAdd = {
          ...mission,
          creationDateTime: new Date(),
          lot: {id: +lotId},
          campaign: {id: campaignId},
        };

        dispatch(setCurrentMission(missionToAdd as MappingMission))
    } else {
      setErrors(validationErrors);
      setIsError(true);
    }
  }

  const handleSend = async () => {
    if(missionId) {
      if(currentMission) {
        editMission({...currentMission, id: +missionId}).then((res) => {
          resetParameters();
          navigate(`/projects/${projectId}/lots/${lotId}`);
        });
      }
    } else { 
      addMission(currentMission).then((res) => {
        resetParameters();
        navigate(`/projects/${projectId}/lots/${lotId}`);
      });
    }
  };

  const resetParameters = () => {
    dispatch(setEditMissionPolygon(false));
    dispatch(setCurrentMission(null));
  }

  const handleExit = () => {
    resetParameters();
    if(missionId) {
      navigate(`/projects/${projectId}/lots/${lotId}`);
    } else {
      navigate(`/projects/${projectId}/lots/${lotId}/tasks/new`);
    }
  }

  const handleError = useCallback((hasError: boolean) => {
    setHasError(hasError);
  },[]);

  const handleNextStep = () => {
    setNextStepTouched(true);
    if(!hasError){
      if (currentStep === (steps.length - 1)) {
        dispatch(setEditMissionPolygon(true));
        handleGenereteMissionObject({lotId: +lotId, campaignId})
      }

      if(currentStep == steps.length) {
        handleSend()
      }else {
        setCurrentStep(currentStep + 1);
      }
      setNextStepTouched(false);
    }
  }

  const handlePreviousStep = () => {
    setCurrentStep((prevState) => prevState - 1)
  }
  // #endregion

  const steps = [
    {
      index: 1,
      step: <StepParams 
        step={1}
        nextStepTouched={nextStepTouched}
        handleError={handleError}
      />,
      stepName: t('requirements'),
      icon: null
    },
    {
      index: 2,
      step: <StepFlightInfo
        step={2}
        handleError={handleError}
      />,
      stepName: t('flight'),
      icon: null
    }
  ]

  return (
    <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
      {isLoading && <Spinner />}

      <SubHeader >
        <LotProjectItem
          campaignName={selectedCampaign?.name}
          projectName={project?.nombre}
          projectColor={project?.color}
          lotName={lot?.name}
        />
      </SubHeader>

      <SubHeader noBack={true} sx={{ backgroundColor: style.dark }}>
        <TaskHeader 
          title={t('mission')}
          subtitle='Mapping'
          icon={<MappingIcon style={{ width: '40px', height: '40px', marginTop: '10px', marginRight: '10px' }} />}
          handleCancel={() => {setShowCancelModal(true)}}
        />
      </SubHeader>

      <SidebarBody>
        <Stepper2 StepCurrent={currentStep} StepAmount={steps.length} steps={steps}/>
        { steps[currentStep - 1].step }

        { showCancelModal && (
          <CancelPopup 
            text={t('cancel-modal-text')}
            handleCancelModalclose={() => setShowCancelModal(false)} 
            handleExit={() => {handleExit()}}
            showCancelBtn
          />
        )}

        { errors.length > 0 && errors.map((error, index) => {
          return <Alert key={index} type='error' text={error} />;
        })}
      </SidebarBody>

      <StepsFormFooter 
        currentStep={currentStep}
        stepsAmount={steps.length}
        disableBackBtn={isCurrentMissionLoading}
        disableNextBtn={hasError || isCurrentMissionLoading}
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
      />
    </Sidebar>
  );
};
