import { ErrorToaster } from 'components/Toaster/AppToaster';
import { useEffect, useState } from 'react';
import { API_URL } from 'services/configurations';
import { useAppSelector } from 'state/hooks';

import styles from '../Button/Button.module.scss'
// import { Toast } from "@blueprintjs/core";

interface IProps {
  fileName: string;
  linkUrl: string;
  children: React.ReactNode;
}

interface IPropsToken extends IProps {
  token: string;
}

export const DownloadLink = ({ fileName, linkUrl, children }: IProps) => {
  const accessToken = useAppSelector((state) => state.data.accessToken);

  if (!accessToken) {
    return <a>{children}</a>;
  }

  return (
    <>
      <DownloadLinkToken token={accessToken} fileName={fileName} linkUrl={linkUrl}>
        {children}
      </DownloadLinkToken>
    </>
  );
};

export const DownloadLinkToken = ({ token, fileName, linkUrl, children }: IPropsToken) => {
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    if (error) {
      ErrorToaster(`El archivo ${fileName} no está disponible`);
      setError(false);
    }
  }, [error]);

  const accessToken = token;

  const downloadFile = async (event: any) => {
    event.preventDefault();
    setFetching(true);

    try {
      const response = await fetch(API_URL + linkUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        setError(true);
        // throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      setFetching(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {fetching && <>Descargando...</>}
      {!fetching && (
        <a href='/' style={{textTransform:'lowercase'}} onClick={downloadFile}>
          {children}
        </a>
      )}
    </>
  );
};
