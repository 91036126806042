import { Popover2 } from '@blueprintjs/popover2';
import { useMeQuery } from 'api/Vistaguay/VistaguayAPI';
// #region Icons
import { ReactComponent as NotificationIcon } from 'assets/icons/ico-bell.svg'
import { ReactComponent as CardsIcon } from 'assets/icons/ico-cards.svg';
import { ReactComponent as CartIcon } from 'assets/icons/ico-cart.svg';
import { ReactComponent as EmailSVG } from 'assets/icons/ico-email.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/ico-logout.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/ico-question.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/ico-report.svg';
import { ReactComponent as UserCardIcon } from 'assets/icons/ico-user-card.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/ico-whatsapp.svg'
import { ReactComponent as TranslateIcon } from 'assets/icons/translate.svg';
import Avatar from 'components/Avatar/Avatar';
import CreditTab from 'components/Credits/CreditTab';
import { CreditInfo } from 'components/Domain/Credit/CreditInfo';
import { Notification, NOTIFICATION_STATUS, NOTIFICATION_TYPE } from 'models/Notification';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'services/configurations';
import {io, Socket} from 'socket.io-client'
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { resetCampaignStatus } from 'state/slices/campaignSlice';
import { logout } from 'state/slices/dataSlice';
import { setModalGlobalState } from 'state/slices/uiSlice';

import BellIcon from '../../assets/icons/ico-bell.svg';
import EmailIcon from '../../assets/icons/ico-email.svg';
import WppIcon from '../../assets/icons/ico-whatsapp.svg';
import VistaguayLogo from '../../assets/icons/vistaguay.svg';
import NotificationPopover from './NotificationPopover';
// #endregion
import styles from './Topbar.module.scss';

export default function Topbar() {
  const jwt = useAppSelector((state) => state.data.accessToken);
  const isLogged = jwt !== null;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.topbar}>
      <img className={styles.vistaguayLogo} onClick={() => navigate('/')} src={VistaguayLogo} />

      <div className={styles.alignedRight}>
    	  <WhatsAppIcon
          onClick={() => {
            window.open('https://api.whatsapp.com/send?phone=5493516887507', '_blank');
          }}
          style={{ height: '20px', width: '20px', fill: styles.verdeVistaguay,
            cursor: 'pointer',
          }} 
        />

        <EmailSVG 
          style={{ height: '20px', width: '20px', fill: styles.verdeVistaguay,
          cursor: 'pointer',  
          }}
          onClick={() => {
              window.open('mailto: soporte@vistaguay.com', '_blank');
          }}
        />

        {!isLogged && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TranslateIcon style={{ height: '20px', width: '25px', fill: styles.verdeVistaguay }} />
            <select
              style={{ marginRight: '25px' }}
              className={styles.language}
              name='language'
              id='language'
              value={i18n.language}
              onChange={(e) => {
                i18n.changeLanguage(e.target.value);
              }}
            >
              <option value='es'>{t('espanol')}</option>
              <option value='en'>{t('english')}</option>
            </select>
          </div>
        )}

        {isLogged && (
          <div>
            <img src={WppIcon}></img>
            <img src={EmailIcon}></img>
            <img src={BellIcon} />
          </div>
        )}
        {isLogged && <TopbarUserInfo />}
      </div>
    </div>
  );
}

const NotificationButton = ({notifications} : {notifications: Notification[]}) => {
  return (
    <div className={styles.notificationButton}>
      <Popover2
        placement='bottom'
        interactionKind='click'
        usePortal={true}
        content={<NotificationPopover notifications={notifications}/>}
      >
        <NotificationIcon className={styles.notificationIcon}/>
      </Popover2>
      {
        notifications.length != 0 &&
        <div className={styles.dot}>
          <p>{notifications.length}</p>
        </div>
      }
    </div>
  )
}

const TopbarUserInfo = () => {
  const { data: meData, isLoading: meLoading } = useMeQuery();
  const jwt = useAppSelector((state) => state.data.accessToken)
  const [notifications ,setNotifications] = useState<Notification[]>([])
  
  useEffect(() => {
    const sslExist = API_URL.split('://')[0] == 'https'
    const socketProtocol = sslExist ? 'wss://' : 'ws://'
    if(meData) {
      const socket: Socket = io(`${socketProtocol}${API_URL.split('://')[1]}`, {
        auth: {
          token: jwt
        }
      })
      
      socket.on('message', (msg : string) => {
        console.info(msg)
      })
      socket.on('new:notification', (notification: string) => {
        setNotifications((prevState) => [...prevState, JSON.parse(notification)])
      })
      socket.on('notifications', (notificationsNew: string) => {
        const not = JSON.parse(notificationsNew)
        setNotifications(not)
      })
      socket.emit('init', {token: jwt})
      
    }
  },[meData])

  if (meLoading) {
    return (
      <div className={styles.userInfo}>
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            dispatch(setModalGlobalState({
              isOpen: true,
              type: 'profile'
            }))
          }}
        >
          <Popover2
            interactionKind='click'
            usePortal={true}
            content={<UserMenu />}
          >
            <Avatar loading={meLoading} />
          </Popover2>
        </div> */}
        <Popover2
          interactionKind='click'
          position='bottom'
          usePortal={true}
          content={<UserMenu />}
        >
          <Avatar loading={meLoading} />
        </Popover2>
      </div>
    );
  }

  return (
    <div className={styles.userInfo}>
      <div className={styles.userInfo} style={{ gap: '20px' }}>
        {/* NOTIFICATIONS */}
        <NotificationButton notifications={notifications}/>

        {/* CREDITS */}
        <Popover2
          placement='bottom'
          interactionKind='click'
          usePortal={true}
          content={<CreditTab />}
        >
          <CreditInfo />
        </Popover2>

        {/* USER */}
        <Popover2
          interactionKind='click'
          position='bottom'
          usePortal={true}
          content={<UserMenu />}
        >
          <Avatar loading={meLoading} />
        </Popover2>
      </div>
    </div>
  );
};

const UserMenu = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div className={styles.menu}>
      {/* TITLE */}
      <div className={styles.menuTitle}>
        <h2>{t('account')}</h2>
      </div>

      <div >
        {/* PROFILE */}
        <div 
          className={styles.menuItem}
          onClick={() => {
            dispatch(setModalGlobalState({
              isOpen: true,
              type: 'profile'
            }))
          }}
        >
          <UserCardIcon />
          <p>{t('my-data')}</p>
        </div>
        
        {/* PLANS */}
        <div 
          className={styles.menuItem}
          onClick={() => {navigate('/pricing')}}
        >
          <CardsIcon />
          <p>{t('display-plans')}</p>
        </div>

        {/* PURCHASE HISTORY */}
        <div 
          className={styles.menuItem}
          onClick={() => {
            dispatch(setModalGlobalState({
              isOpen: true,
              type: 'purchase-history'
            }))
          }}
        >
          <CartIcon />
          <p>{t('purchase-history')}</p>
        </div>

        {/* HELP */}
        <div className={styles.menuItem}>
          <HelpIcon height={20} fill={styles.gris}/>
          <p style={{color: styles.gris}}>{t('help')}</p>
        </div>

        {/* TERMS & CONDITIONS */}
        <div className={styles.menuItem}>
          <ReportIcon fill={styles.gris}/>
          <p style={{color: styles.gris}}>{t('terms-conditions')}</p>
        </div>

        {/* LOGOUT */}
        <div 
          className={styles.menuItem}
          onClick={() => {
            dispatch(logout());
            dispatch(resetCampaignStatus())
            navigate('/');
          }}
        >
          <LogoutIcon />
          <p>{t('logout')}</p>
        </div>
      </div>
    </div>
  )
}