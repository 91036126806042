
import { useUpdateMeMutation, useUploadImgMutation } from 'api/Vistaguay/VistaguayAPI';
import { ReactComponent as CloseIcon } from 'assets/icons/ico-close.svg';
import cx from 'classnames';
import Button from 'components/Button/Button';
import VerticalStepper from 'components/Steppers/VerticalStepper/VerticalStepper';
import User from 'models/User';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setModalGlobalState } from 'state/slices/uiSlice';

import { ReactComponent as BillingIcon } from '../../../assets/icons/ico-billing.svg';
import { ReactComponent as CompanyIcon } from '../../../assets/icons/ico-company.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/ico-settings.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-icon.svg';
import style from './ProfileModal.module.scss';
import CompanyData from './Steps/CompanyData';
import ProfileSettings from './Steps/ProfileSettings';
import UserData from './Steps/UserData';

export interface IProfileForm {
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  prefix: string;
  phoneNumber: string;
  razonSocial: string;
  industry: string | number;
  language: string;
  unitSystem: string;
}

export function ProfileModal ()  {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
  
    const queries = useAppSelector((state) => state.VistaguayApi.queries);

    const user = queries['me(undefined)']?.data as User;
    const formInitialValues: Partial<IProfileForm> = {
      email: user?.email,
      firstName: user?.nombre || '',
      lastName: user?.apellido || '',
      country: user?.country || 'Argentina', 
      prefix: user?.prefix || '+54',
      phoneNumber: user?.phoneNumber || '',
      razonSocial: user?.razonSocial || '',
      industry: user?.industry_id || 1,
      language: user?.language || 'es',
      unitSystem: user?.unitSystem || 'metric',
    }
  
    const [form, setForm] = useState<Partial<IProfileForm>>(formInitialValues);
    const [step, setStep] = useState(1);
    const [firstTime, setFirstStep] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [profileImg, setProfileImg] = useState(user.profileImg)
  
    const steps = [
      {
        index: 1,
        step: <UserData 
          form={form}
          setForm={(form: Partial<IProfileForm>) => setForm(form)}
        />,
        icon: <UserIcon width={25} fill={step === 1 ? 'white' : style.dark}/>,
        label: t('personal-data')
      },
      {
        index: 2,
        step: <CompanyData 
          profileImg={profileImg}
          form={form}
          setForm={(form: Partial<IProfileForm>) => setForm(form)}
        />,
        icon: <CompanyIcon width={25} fill={step === 2 ? 'white' : style.dark}/>,
        label: t('company-data')
      },
      // {
      //   index: 3,
      //   step: <BillingSettings />,
      //   icon: <BillingIcon width={25} fill={step === 3 ? 'white' : style.dark}/>,
      //   label: t('billing-settings')
      // },
      {
        index: 3,
        step: <ProfileSettings />,
        icon: <SettingsIcon width={25} fill={step === 3 ? 'white' : style.dark}/>,
        label: t('settings')
      },
    ]

    const [
      updateUser,
      {
        data: updateUserData,
        isLoading: updateUserLoading,
        isSuccess: updateUserSuccess,
        isError: updateUserError,
        error: updateUserErrorData,
      },
    ] = useUpdateMeMutation();
  
    const handleCurrentSection = (step: number) => {
      if(!firstTime) {
        setStep(step)
      }
    }

    const handleSave = () => {
      if(!disabled) {
        console.log('update')
        updateUser(form)
      }
    }

    useEffect(() => {
      if(
        form.firstName !== user.nombre ||
        form.lastName !== user.apellido ||
        form.country !== user.country ||
        form.phoneNumber !== user.phoneNumber ||
        form.prefix !== user.prefix ||
        form.industry !== user.industry_id ||
        form.unitSystem !== user.unitSystem ||
        form.language !== user.language 
      ){
        setDisabled(false)
      }
    }, [])
  
    return(
      <div className={style.modalWrapper}>
        <div className={style.closeButtonWrapper}>
          <div 
            className={style.closeButton} 
            onClick={() => dispatch(setModalGlobalState({isOpen: false, type: null}))}
          >
            <CloseIcon width={10} fill={style.dark}/>
          </div>
        </div>

        <div className={style.modalContent}>
          <div className={style.mainContent}>
            <div className={style.modalSidebar}>
              { firstTime && (
                <VerticalStepper />
              )}

              <div className={style.modalSidebarButtons}>
                { steps.map((s) => (
                  <div 
                    key={s.index}
                    className={cx(
                      style.modalSidebarButton,
                      step === s.index ? style.activeButton : style.inactiveButton
                    )}
                    onClick={() => handleCurrentSection(s.index)}
                  >
                    {s.icon}
                    <p>{s.label}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className={style.modalSection}>
              {steps[step - 1].step}
            </div>
          </div>

          <div className={style.modalSectionFooter}>
            <Button 
              width='100px'
              height='45px'
              variant='SUCCESS' 
              text={t('save')}
              onClick={() => handleSave()}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    )
}