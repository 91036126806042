// #region Reack & Hooks
// #endregion
// #region API services
import { useDeleteTaskMutation, useGetTasksQuery } from 'api/Vistaguay/TaskAPI';
// #endregion
// #region Components
import Button from 'components/Button/Button';
// #endregion
// #region Models
import { Campaign } from 'models/Campaign';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';
// #endregion

export default  function TrashOption ({
    taskId
}:{
    taskId: string, 
}) {
    // #region HOOKS
    const { t } = useTranslation();
    // #endregion

    // #region Params
    const { projectId, lotId } = useParams<{ projectId: string; lotId: string }>();
    // #endregion

    // #region Redux States
    const selectedCampaign: Campaign | null = useAppSelector((state) => state.campaign.selectedCampaign);
    // #endregion

    if(selectedCampaign == null) return <></>

    // #region API mutations
    const [deleteTask,{isLoading}] = useDeleteTaskMutation();

    const {refetch: refetchTask} = useGetTasksQuery({
        campaignId: String(selectedCampaign?.id),
        lotId: String(lotId),
    },{skip: selectedCampaign == undefined});
    // #endregion

    // #region Handlers
    const handleDelete = () => {
        deleteTask({taskId}).then((response) => {
            refetchTask();
        });
    }
    // #endregion

    return (
        <div 
            style={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                margin: '15px 0px'
          }}
        >
            <h4 style={{ fontSize: '14px', fontWeight: '600' }}>
                {t('delete-task-title')}
            </h4>

            <p style={{ fontSize: '12px', marginBottom: '10px' }}>
                {t('delete-task-text')}
            </p>

            <Button 
                onClick={() => handleDelete()} 
                text={t('errase')} 
                variant={'ERROR'} 
                style={{ fontSize: '12px' }}
                height='30px'
                width='100px'
                isLoading={isLoading}
            />
        </div>
    )
} 