import { Popover2 } from '@blueprintjs/popover2';
import { ReactComponent as Dots } from 'assets/icons/ico-dots.svg';
import { ReactComponent as IconFolder } from 'assets/icons/ico-folder.svg';
import Project from 'models/Project';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'state/hooks';
import { setCurrentProjectId } from 'state/slices/uiSlice';

import ProjectContextual from './ProjectContextual/ProjectContextual';
import styles from './ProjectList.module.scss'

interface Props {
    project: Project,
    lotsAmount?: number
    campaignId: number | string
}

export default function ProjectListItem({
    campaignId,
    project, 
    lotsAmount
} : Props ) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    
    return (
        <li className={styles.body}>
            <div className={styles.projectItem}>
                <div
                    
                    className={styles.left}
                    onClick={() => { 
                        dispatch(setCurrentProjectId(project.proyectoId))
                        navigate(`/projects/${project.proyectoId}`)
                    }}
                >
                    <IconFolder style={{ fill: project.color, marginRight: '10px' }}  className={styles.icon}/>
                    <div className={styles.headerText}>
                        <h4 style={{fontSize: '14px', fontWeight: '600', margin: '0'}}>{project?.nombre}</h4>

                        { lotsAmount &&
                            <p style={{margin: '0px', fontSize: '14px'}}>{`${lotsAmount} ${t('lots')}`}</p>
                        }
                    </div>
                </div>

                <Popover2
                    interactionKind='click'
                    usePortal={true}
                    position='right'
                    content={<ProjectContextual 
                        campaignId={+campaignId} 
                        projectId={+project.proyectoId} 
                        project={project}
                    />}
                >
                    <div className={styles.projectOptionsContainer}>
                        <Dots className={styles.optionsIcon} />
                    </div>
                </Popover2>
            </div>
        </li>
    );
}
