import { Campaign } from './Campaign';
import Lot from './Lot';
import { Task, TASK_TYPE } from './Task';
import User from './User';


export enum PLANTCOUNT_STAGE {
  NEW = 0,
  PROCESSING = 1,
  PUBLISHED = 2,
  REVIEW = 3,
  BROKEN = 4,
  REPROCESREPORT = 5,
  REPORTERROR = 7,
  DISCARD = 9,
  FINISHED = 10,
}


export class PlantCountTask extends Task {
  conteosPlantasId: number;
  name: string;
  nombre: string; 
  prescription: string;
  flightDate: string;
  CUIT: string;
  fechaSiembra: string;
  fechaVuelo: string;
  sowTypeId: number;
  sowerTypeId: number;
  distancing: number;
  cropId: number;
  hybridId: number;
  genotypeId: number;
  targetDensity: number;
  densidadesJson: any | object;
  dSurco: number;
  phenologicalStateId: number;
  tipoProceso: number;
  stageId?: number;
  seederId?: number;
  seederMeterId?: number;
  seederBrandId?: number;
  seederModelId?: number;
  prescriptionId?: number;
  lot?: Lot;
  creator?: User;
  files: File[];
  createdAt?: Date;
  campaign?: Campaign;
  estadoId: number;
  taxIdType?: number | string;

  constructor(
    id: number,
    conteosPlantasId: number,
    name: string,
    nombre: string,
    prescription: string,
    flightDate: string,
    fechaSiembra: string,
    fechaVuelo: string,
    CUIT: string,
    sowTypeId: number,
    sowerTypeId: number,
    distancing: number,
    cropId: number,
    hybridId: number,
    genotypeId: number,
    targetDensity: number,
    files: File[],
    prescriptionId: number,
    densidadesJson: object,
    dSurco: number,
    phenologicalStateId: number,
    estadoId: number,
    tipoProceso: number,
    seederId?: number,
    taxIdType?: number | string
  ) {
    super(conteosPlantasId, name, TASK_TYPE.conteo);
    this.conteosPlantasId = conteosPlantasId;
    this.name = name;
    this.nombre = nombre;
    this.prescription = prescription;
    this.flightDate = flightDate;
    this.CUIT = CUIT;
    this.fechaVuelo = fechaVuelo;
    this.fechaSiembra = fechaSiembra;
    this.sowTypeId = sowTypeId;
    this.sowerTypeId = sowerTypeId;
    this.distancing = distancing;
    this.cropId = cropId;
    this.hybridId = hybridId;
    this.genotypeId = genotypeId;
    this.targetDensity = targetDensity;
    this.files = files;
    this.phenologicalStateId = phenologicalStateId;
    this.prescriptionId = prescriptionId;
    this.densidadesJson = densidadesJson;
    this.dSurco = dSurco;
    this.estadoId = estadoId;
    this.tipoProceso = tipoProceso;
    this.seederId = seederId;
    this.taxIdType = taxIdType
  }
}