import { useGetIndustryQuery } from 'api/Vistaguay/ExtraData';
import { useCreateUserGroupMutation } from 'api/Vistaguay/UserGroupAPI';
import { useUploadImgMutation } from 'api/Vistaguay/VistaguayAPI';
import Button from 'components/Button/Button';
import { CheckBox } from 'components/CheckBox/CheckBox';
import { Input, InputSelect } from 'components/Inputs/Input';
import { Console } from 'console';
import styles from 'pages/Profile/UserConfig.module.scss';
import { PhotoDescriptionSection } from 'pages/TaskView/CountReport/PhotoDescriptionSection';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { API_URL } from 'services/configurations';

interface IProp {
  handdleSend: () => any;
}
export const AddUser = ({ handdleSend }: IProp) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<any>({});
  const [countryData, setCountryData] = useState<Array<any>>([]);
  const [countryLoading, setCountryLoading] = useState<boolean>(true);
  const [notEdit, setNotEdit] = useState<boolean>(false);

  const {
    data: industryData,
    isLoading: industryLoading,
    isSuccess: industrySuccess,
  } = useGetIndustryQuery();

  const [createUserGroup, { isLoading: createUserGroupLoading }] = useCreateUserGroupMutation();

  const [hasError, setHasError] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    setCountryLoading(true);
    async function fetchCountrys() {
      return await fetch('https://restcountries.com/v3.1/all')
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setCountryData(res);
          setCountryLoading(false);
        });
    }
    fetchCountrys();
  }, []);

  const handleSubmit = () => {
    const errors = [];
    if (formData.password !== formData.confirm) {
      console.log(formData.password, formData.confirm);
      errors.push('Las contraseñas no coinciden');
    }
    if (formData.password.length < 6) {
      errors.push('La contraseña debe tener al menos 6 caracteres');
    }
    if (!formData.nombre || formData.nombre == '') {
      errors.push('El nombre es obligatorio');
    }
    if (!formData.apellido || formData.apellido == '') {
      errors.push('El apellido es obligatorio');
    }
    if (!formData.razonSocial || formData.razonSocial == '') {
      errors.push('La razón social es obligatoria');
    }
    if (!formData.phoneNumber || formData.phoneNumber == '') {
      errors.push('El celular es obligatorio');
    }
    if (!formData.language || formData.language == '') {
      errors.push('El idioma es obligatorio');
    }

    const isValid = errors.length === 0;

    // alert(JSON.stringify(formData, null, 2));
    if (isValid) {
      //   console.log(formData);
      createUserGroup({ user: formData });
      handdleSend();
    } else {
      setHasError(true);
      setErrors(errors);
    }
  };

  const LOADING = createUserGroupLoading;

  return (
    <section className={styles.container} style={{ overflow: 'hidden', marginBottom: '50px' }}>
      {LOADING && <Spinner />}
      <div style={{ height: '30px' }}>
        {hasError && (
          <p style={{ color: styles.rojo, textAlign: 'center', paddingBottom: '10px' }}>
            {errors.join('. ')}
          </p>
        )}
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <section className={styles.config}>
          <Input
            placeholder={'Email'}
            type='text'
            value={formData.email}
            disabled={notEdit}
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
          <Input
            placeholder={'Nombre'}
            type='text'
            value={formData.nombre}
            disabled={notEdit}
            onChange={(e) => {
              setFormData({ ...formData, nombre: e.target.value });
            }}
          />
          <Input
            placeholder={'Apellido'}
            type='text'
            value={formData.apellido}
            disabled={notEdit}
            onChange={(e) => {
              setFormData({ ...formData, apellido: e.target.value });
            }}
          />
          <Input
            placeholder={'Empresa / Razon social'}
            type='text'
            value={formData.razonSocial == null ? '' : formData.razonSocial}
            disabled={notEdit}
            onChange={(e) => {
              setFormData({ ...formData, razonSocial: e.target.value });
            }}
          />

          <Input
            placeholder={'Password'}
            type='password'
            value={formData.password == null ? '' : formData.password}
            disabled={notEdit}
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
          <Input
            placeholder={'Confirmar Password'}
            type='password'
            value={formData.confirm == null ? '' : formData.confirm}
            disabled={formData.password == null}
            onChange={(e) => {
              setFormData({ ...formData, confirm: e.target.value });
            }}
          />
        </section>
        <section className={styles.config}>
          <Input
            placeholder={'CUIT'}
            type='text'
            value={formData.cuit == null ? '' : formData.cuit}
            disabled={notEdit}
            onChange={(e) => {
              setFormData({ ...formData, cuit: e.target.value });
            }}
          />
          <Input
            value={formData.phoneNumber == null ? '' : formData.phoneNumber}
            type='text'
            disabled={notEdit}
            onChange={(e) => {
              setFormData({ ...formData, phoneNumber: e.target.value });
            }}
            placeholder={'Celular'}
          />
          <InputSelect
            value={formData.country}
            disabled={notEdit}
            placeholder={'Pais'}
            sx={{ height: '48px' }}
            onChange={(e) => {
              setFormData({ ...formData, country: e.target.value });
            }}
          >
            <option disabled={true} value={'placeholder'}>
              Pais
            </option>
            {!countryLoading &&
              countryData.map((country: any) => {
                return (
                  <option value={country.name.common} key={country.name.common}>
                    {country.name.common}
                  </option>
                );
              })}
          </InputSelect>
          <InputSelect
            placeholder={'Idioma'}
            value={formData.language}
            sx={{ height: '48px' }}
            disabled={notEdit}
            onChange={(e) => {
              setFormData({ ...formData, language: e.target.value });
            }}
          >
            <option disabled={true} value={'placeholder'}>
              Idioma
            </option>
            <option value={'es'} id={'es'}>
              Español
            </option>
            <option value={'en'} id={'en'}>
              English
            </option>
          </InputSelect>
          <InputSelect
            placeholder={'Industria'}
            disabled={notEdit}
            value={String(formData['industry_id'])}
            sx={{ height: '48px' }}
            onChange={(e) => {
              // eslint-disable-next-line camelcase
              setFormData({ ...formData, industry_id: +e.target.value });
            }}
          >
            <option disabled={true} value={'placeholder'}>
              Industria
            </option>
            {industrySuccess &&
              industryData.map((industry) => {
                return (
                  <option value={industry.id} key={industry.id} id={String(industry.id)}>
                    {industry.value}
                  </option>
                );
              })}
          </InputSelect>
        </section>
      </form>
      <footer>
        <Button
          text={t('save')}
          //   disabled={updateUserLoading || meData === formData}
          variant={'SUCCESS'}
          onClick={() => handleSubmit()}
        >
          {LOADING && <Spinner size={'sm'} />}
        </Button>
      </footer>
    </section>
  );
};
