/* eslint-disable camelcase */
import { useEditPricesMutation, useGetPricesQuery } from 'api/Vistaguay/PricesAPI';
import Button from 'components/Button/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from './Prices.module.scss';

export const Prices = () => {
  const { t, i18n } = useTranslation();

  const { data: pricesData, isLoading: pricesLoading } = useGetPricesQuery();
  const [editPrices, { isLoading: editPricesLoading }] = useEditPricesMutation();

  const [weeds, setWeeds] = useState('0');
  const [plantingQuality, setPlantingQuality] = useState('0');
  const [mosaic, setMosaic] = useState('0');
  const [auditoryPrice, setAuditoryPrice] = useState('0');
const [isEditing, setIsEditing] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [vpackPrice, setVpackPrice] = useState('0');
  const [vpackDiscount, setVpackDiscount] = useState('0')
  useEffect(() => {
    if (pricesData) {
      setWeeds('' + pricesData.weeds);
      setPlantingQuality('' + pricesData.planting_quality);
      setMosaic('' + pricesData.mosaic);
      setAuditoryPrice('' + pricesData.auditory);
      setVpackPrice(String(pricesData.vpackPrice));
      setVpackDiscount(String(pricesData.vpackDiscount));
    }
  }, [pricesData,pricesLoading]);

  const handleEdit = () => {
    if (isEditing && hasChanges) {
      editPrices({
        weeds: +weeds,
        planting_quality: +plantingQuality,
        mosaic: +mosaic,
        auditory: +auditoryPrice,
        vpackPrice: +vpackPrice,
        vpackDiscount: +vpackDiscount,
      });
      setIsEditing(false);
    }
  };

  return (
    <div className={style.container}>
      {pricesData && !pricesLoading &&
        (
          <>
          <div className={style.infoContainer}>
            <div className={style.lastPriceInfo}>
              <span className={style.label}> Ultima actualizacion: </span>
              <span>{new Date(pricesData.createdAt).toLocaleString()}</span>
              <span>{pricesData.user.email}</span>
            </div>
          </div>
          <table className={style.table}>
            <thead>
              <tr>
                <th>Tarea</th>
                <th>Unidades</th>
                <th>Precio (Creditos)</th>
                <th>Detalle</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Conteo de plantas (scouting)</td>
                <td>imagen</td>
                <td>
                  <input
                    className={style.value}
                    disabled={!isEditing}
                    value={plantingQuality}
                    onChange={(e) => {
                      if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                        setPlantingQuality(e.target.value);
                        setHasChanges(true);
                      }
                    }}
                  />
                </td>
                <td> - </td>
              </tr>
              <tr>
                <td>Maleza</td>
                <td>hectarea</td>
                <td>
                  <input
                    className={style.value}
                    disabled={!isEditing}
                    value={weeds}
                    onChange={(e) => {
                      if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                        setWeeds(e.target.value);
                        setHasChanges(true);
                      }
                    }}
                  />
                </td>
                <td> minimo 25 hectareas </td>
              </tr>
              <tr>
                <td>Mosaicos</td>
                <td>mapa</td>
                <td>
                  <input
                    className={style.value}
                    disabled={!isEditing}
                    value={mosaic}
                    onChange={(e) => {
                      if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                        setMosaic(e.target.value);
                        setHasChanges(true);
                      }
                    }}
                  />
                </td>
                <td> maximo 1500 imagenes por mapa </td>
              </tr>
              <tr>
                <td>Auditoria 360</td>
                <td>imagen</td>
                <td>
                  <input
                    className={style.value}
                    disabled={!isEditing}
                    value={auditoryPrice}
                    onChange={(e) => {
                      if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                        setAuditoryPrice(e.target.value);
                        setHasChanges(true);
                      }
                    }}
                  />
                </td>
                <td> - </td>
              </tr>
              <tr>
                <td>V-Pack</td>
                <td>credito / unidad</td>
                <td>
                  <input
                    className={style.value}
                    disabled={!isEditing}
                    value={vpackPrice}
                    onChange={(e) => {
                      if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                        setVpackPrice(e.target.value);
                        setHasChanges(true);
                      }
                    }}
                  />
                </td>
                <td> - </td>
              </tr>
              <tr>
                <td>V-Pack descuento</td>
                <td> % </td>
                <td>
                  <input
                    className={style.value}
                    disabled={!isEditing}
                    value={vpackDiscount}
                    onChange={(e) => {
                      if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                        setVpackDiscount(e.target.value);
                        setHasChanges(true);
                      }
                    }}
                  />
                </td>
                <td> - </td>
              </tr>
            </tbody>
          </table>
          </>
        )
      }
      <Button
        style={{marginTop:'10px'}}
        text={isEditing && hasChanges ? t('save') : isEditing ? 'Cancelar' : 'Editar'}
        onClick={() => {
          if(!isEditing) return setIsEditing(true)
          handleEdit()
        }}
        color='white'
      />
    </div>
  );
};
