import { useMediaQuery } from '@mui/material';
import { useGetReportDataQuery } from 'api/Vistaguay/TaskAPI';
import { PlantCountTask } from 'models/PlantCountTask';
import { Prescription } from 'models/Prescription';
import { useTranslation } from 'react-i18next';

import { resultImageBasePath } from './CountTaskReport';
import style from './CountTaskReport.module.scss';
import { Plots } from './Plots';
import { getSempahoreColor } from './Sempahore';

interface IPlotsSectionProps {
  task: PlantCountTask;
  countMetadata: any;
  prescription: Prescription;
  photosMetadata: any;
}

export const PlotsSection = ({ task, countMetadata, prescription, photosMetadata }: IPlotsSectionProps) => {
  const { t } = useTranslation();
  const taskId = String(task.conteosPlantasId);
  const {data: reportData, isSuccess: reportSuccess} = useGetReportDataQuery({taskId: taskId})

  if (!countMetadata || countMetadata.result.length === 0) {
    return null;
  }

  const denMeanCvSum = countMetadata.result
    .map((metadataAmbiente: any, idx: number) => {
      return +metadataAmbiente.densidadCv;
    })
    .reduce((a: number, b: number) => a + b, 0);

  const denMeanCv = denMeanCvSum / countMetadata.result.length;

  const gapMeanCv =
    countMetadata.result
      .map((metadataAmbiente: any) => +metadataAmbiente.gapCv)
      .reduce((a: number, b: number) => a + b, 0) / countMetadata.result.length;

  const covMeanCv =
    countMetadata.result
      .map((metadataAmbiente: any) => +metadataAmbiente.coberturaCv)
      .reduce((a: number, b: number) => a + b, 0) / countMetadata.result.length;

  // const baseImageUrl = `task/${taskId}/result`;
  return (
    <div className={style.plotsConteiner}>
      {
        reportSuccess &&
        <>
          <Plots
            task={task}
            report={reportData}
            photosMetadata={photosMetadata}
            linkUrl={`${resultImageBasePath(taskId)}/densidad_ambientes.svg`}
            title='Densidad'
            semaphoreType={getSempahoreColor('densidad', denMeanCv)}
            unit='PL/HA'
            countMetadata={countMetadata}
            prescription={prescription}
          />
          <Plots
            task={task}
            report={reportData}
            photosMetadata={photosMetadata}
            linkUrl={`${resultImageBasePath(taskId)}/gaps_ambientes.svg`}
            title='Espaciamiento'
            semaphoreType={getSempahoreColor('esparcimiento', gapMeanCv)}
            unit='cm'
            countMetadata={countMetadata}
            prescription={prescription}
          />
          <Plots
            report={reportData}
            photosMetadata={photosMetadata}
            task={task}
            linkUrl={`${resultImageBasePath(taskId)}/size_ambientes.svg`}
            title='Cobertura'
            semaphoreType={getSempahoreColor('cobertura', covMeanCv)}
            unit='cm²/pl'
            countMetadata={countMetadata}
            prescription={prescription}
          />
        </>
      }
    </div>
  );
};
