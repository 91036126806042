import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetMosaicMetadataQuery, useGetWmsQuery } from 'api/Vistaguay/MosaicAPI';
import Invisible from 'assets/icons/notvisible.png';
import Visible from 'assets/icons/visible.png';
import MPoint from 'components/Map/Components/MPoint';
import MWms from 'components/Map/Components/MSimpleWms';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import MPolygon from 'components/Map/Components/Polygon/MPolygon';
import MapContext from 'components/Map/Context/MapContext';
import OlGeoJSON from 'ol/format/GeoJSON';
import { OverlayBody, overlayTitle } from 'pages/TaskView/TaskViewMap/TaskViewMap';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { dataProjection, featureProjection } from 'services/configurations';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setLayers } from 'state/slices/mosaicSlice';
import { BLACK_LINE, LOT_COLOR } from 'styles/variables';

import style from './MosaicsViewMap.module.scss';
import { WmsAlgorithm } from './WmsAlgoritms';

const BACKEND_URL = 'localhost:3000';

const getImageUrl = (taskId: string, lotId: string, fileName: string) => {
  return `http://${BACKEND_URL}/task/${taskId}/photos/name/${fileName}?lotId=${lotId}`;
};

export function MosaicsViewMap() {
  const map = useContext(MapContext);
  const { t } = useTranslation();

  const [showLot, setShowLot] = useState(true);
  const [showPrescriptions, setShowPrescriptions] = useState(true);
  const [prescriptionToShow, setPrescriptionToShow] = useState(-1);

  const mosaicUi = useAppSelector((state) => {
    return state.mosaic;
  });

  const {
    taskId: mosaicId,
    lotId,
    projectId,
  } = useParams<{
    taskId: string;
    lotId: string;
    projectId: string;
  }>();

  if (!mosaicId || !lotId || !projectId) {
    return null;
  }
  const {
    data: lotData,
    isLoading,
    error,
  } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });

  const { data: mosaicMetadata } = useGetMosaicMetadataQuery({ taskId: mosaicId });
  const { data: wmsData } = useGetWmsQuery({ taskId: mosaicId });
  const photosMetadata = mosaicMetadata?.uploadMetadata;
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   if (mosaicMetadata) {
  //     console.dir(mosaicMetadata);
  //   }
  // }, [mosaicMetadata]);

  useEffect(() => {
    if (mosaicUi.layers.length === 0) {
      const layers: string[] = wmsData ? Object.keys(wmsData) : [];
      dispatch(setLayers({ layers }));
    }
  }, [wmsData]);

  const mosaics = useAppSelector((state) => state.mosaic.mosaics);
  const mosaicView = mosaics.find((m) => m.id == Number(mosaicId));

  const mapFit = (extend?: number[]) => {
    if (!extend) return;
    map?.getView().fit(extend);
  };
  
  return (
    <>
      <MVectorLayer isVisible={showLot}>
        <MPolygon onLoadCenter={true} polygon={lotData?.polygon?.geometry} color={LOT_COLOR} stroke={BLACK_LINE} height={1}/>
      </MVectorLayer>

      {wmsData &&
        Object.entries(wmsData).map(([key, value], index) => {
          return (
            <WmsAlgorithm
              key={`${key}-${value.layer}`}
              wmsData={value}
              visible={mosaicUi.layersToShow[index]}
              opacity={1.0}
            />
          );
        })}

      {wmsData &&
        mosaicView?.layers.map((layer, index) => {
          return (
            <WmsAlgorithm
              key={layer.layerName}
              wmsData={wmsData[layer.layerName]}
              visible={mosaicView.layers[index].visible}
              opacity={mosaicView.layers[index].opacity}
            />
          );
        })}

      {photosMetadata && (
        <MVectorLayer layerName='photos'>
          {photosMetadata.map((photoMetadata) => {
            return (
              <MPoint
                key={photoMetadata.fileName}
                color={'red'}
                lat={+photoMetadata.lat}
                lng={+photoMetadata.lng}
                overlayContent={{
                  title: overlayTitle({ photoMetadata, lotId, taskId: mosaicId }),
                  content: OverlayBody({ photoMetadata }),
                }}
              />
            );
          })}
        </MVectorLayer>
      )}
    </>
  );
}
