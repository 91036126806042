import { ChangeEvent } from 'react';

interface ITextInputProp {
  callbackTextChange: (text: string) => void;
}

export const TextInput = (props: ITextInputProp) => {
  const textChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.callbackTextChange(e.target.value);
  };

  return <input type='text' onChange={textChange} />;
};
