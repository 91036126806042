import { useGetPricesQuery } from 'api/Vistaguay/PricesAPI';
import { ReactComponent as AuditoryIcon } from 'assets/icons/360auditory.svg';
import { ReactComponent as PlantCountIcon } from 'assets/icons/Cultivo-comodin.svg';
import { ReactComponent as DropdownIcon} from 'assets/icons/ico-select.svg'
import { ReactComponent as MalezasIcon } from 'assets/icons/malezaIcon.svg';
import {ReactComponent as MercadoPagoIcon} from 'assets/icons/MercadoPago.svg'
import { ReactComponent as OrtomosaicoIcon } from 'assets/icons/Mosaico-comodin.svg';
import {ReactComponent as PayPalIcon} from 'assets/icons/PayPal.svg'
import { useState } from 'react';

import style from './Pricing.module.scss';

const useCollapse = () => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed)
    }
    return {
        isCollapsed,
        toggleCollapse
    }
}

export function AsksPricing() {
    const { data, isLoading } = useGetPricesQuery()
    const {isCollapsed: itemOne, toggleCollapse:itemOneToggle} = useCollapse()
    const {isCollapsed: itemTwo, toggleCollapse:itemTwoToggle} = useCollapse()
    const {isCollapsed: itemThree, toggleCollapse:itemThreeToggle} = useCollapse()
    const {isCollapsed: itemFour, toggleCollapse:itemFourToggle} = useCollapse()
    const {isCollapsed: itemFive, toggleCollapse:itemFiveToggle} = useCollapse()

    return (
        <>
            <section className={style.header}>
                <h1>Preguntas frecuentes</h1>
                <p>
                    Nuestra plataforma funciona con creditos precargados validos por un anio. Podes cargar a
                    medida que necesites o comprar un <strong>V-Pack con un 40% de descuento.</strong>
                </p>
            </section>
            <ul className={style.list} style={{paddingBottom:'40px'}}  >
                <li className={itemOne ? style.open : ''} >
                    <header>
                        <h1>Qué son los créditos y cómo se utilizan</h1>
                        <DropdownIcon onClick={itemOneToggle} style={{rotate: itemOne ? '270deg' : '90deg'}}/>
                    </header>
                    <p>
                        Cada crédito equivale a un (1) dólar estadounidense. La plataforma siempre te va a
                        informar del valor de cada tarea antes de procesarla. Ninguna operación se realiza sin
                        tu aprobación!
                    </p>
                </li>
                <li className={itemTwo ? style.open : ''} >
                    <header>
                        <h1>¿Cuál es la vigencia y validez de los créditos precargados?</h1>
                        <DropdownIcon onClick={itemTwoToggle} style={{rotate: itemTwo ? '270deg' : '90deg'}}/>
                    </header>
                    <p>
                        Un año calendario (365 días) desde el momento en que se acredita el pago. Los créditos
                        vencen y pierden validez cuando pasan 365 días sin que realices ninguna recarga en tu
                        cuenta. Esto quiere decir que cada vez que realices una recarga de cré- ditos, todos tus
                        créditos recuperan su vigencia anual.
                    </p>
                </li>
                <li className={itemThree ? style.open : ''}>
                    <header>
                        <h1>¿Qué sucede cuando me quedo sin créditos?</h1>
                        <DropdownIcon onClick={itemThreeToggle} style={{rotate: itemThree ? '270deg' : '90deg'}}/>
                    </header>
                    <p>
                        Podés seguir utilizando la plataforma y viendo todas tus tareas cargadas. Pero no podés
                        cargar nuevas tareas que requieran consumo de créditos.
                    </p>
                </li>
                <li className={itemFour ? style.open : ''}>
                    <header>
                        <h1>Cómo se calculan los créditos que requiere cada tarea</h1>
                        <DropdownIcon onClick={itemFourToggle} style={{rotate: itemFour ? '270deg' : '90deg'}}/>
                    </header>
                    <div>
                        <p>A continuación te mostramos una referencia</p>
                        <table className={style.tableLight}>
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '5px solid', borderColor: style.light,textAlign:'start' }}>
                                        Tarea
                                    </th>
                                    <th>Descripcion</th>
                                    <th>Creditos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ justifyContent: 'center', textAlign: 'center' }}>
                                    <td style={{ textAlign: 'start', gap: '10px', display: 'flex', alignItems: 'center' }}>
                                        <OrtomosaicoIcon style={{ height: '30px', width: '30px', fill: style.dark }} />
                                        <p style={{ fontSize: '16px', fontWeight: 600, color: style.dark }}>OrtoMosaicos</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: '12px', color: style.darkdisabled, fontWeight: 500 }}>Mapa hasta 1500 imagenes</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: '12px', color: style.darkdisabled, fontWeight: 500 }}>{data && data.mosaic }</p>
                                    </td>
                                </tr>
                                <tr style={{ justifyContent: 'center', textAlign: 'center' }}>
                                    <td style={{ textAlign: 'start', gap: '10px', display: 'flex', alignItems: 'center' }}>
                                        <MalezasIcon style={{ height: '30px', width: '30px', fill: style.dark }} />
                                        <p style={{ fontSize: '16px', fontWeight: 600, color: style.dark }}>Control de malezas</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: '12px', color: style.darkdisabled, fontWeight: 500 }}>Valor por héctarea, mínimo 25 ha</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: '12px', color: style.darkdisabled, fontWeight: 500 }}>{data && data.weeds }</p>
                                    </td>
                                </tr>
                                <tr style={{ justifyContent: 'center', textAlign: 'center' }}>
                                    <td style={{ textAlign: 'start', gap: '10px', display: 'flex', alignItems: 'center' }}>
                                        <PlantCountIcon style={{ height: '30px', width: '30px', fill: style.dark }} />
                                        <p style={{ fontSize: '16px', fontWeight: 600, color: style.dark }}>Conteo de plantas</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: '12px', color: style.darkdisabled, fontWeight: 500 }}>Paquete de 20 muestras</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: '12px', color: style.darkdisabled, fontWeight: 500 }}>{data && data.planting_quality}</p>
                                    </td>
                                </tr>
                                <tr style={{ justifyContent: 'center', textAlign: 'center', marginBottom:'40px' }}>
                                    <td style={{ textAlign: 'start', gap: '10px', display: 'flex', alignItems: 'center' }}>
                                        <AuditoryIcon style={{ height: '30px', width: '30px', fill: style.dark }} />
                                        <p style={{ fontSize: '16px', fontWeight: 600, color: style.dark }}>Auditoria 360</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: '12px', color: style.darkdisabled, fontWeight: 500 }}>Paquete de 20 muestras</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: '12px', color: style.darkdisabled, fontWeight: 500 }}>{data && data.auditory}</p>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                </li>
                <li className={itemFive ? style.open : ''}>
                    <header>
                        <h1>Como pago mis creditos</h1>
                        <DropdownIcon onClick={itemFiveToggle} style={{rotate: itemThree ? '270deg' : '90deg'}}/>
               
                        
                    </header>
                    <div style={{display:'flex', marginTop:'20px', justifyContent:'space-between'}}>
                        <p style={{fontSize:'14px',width:'60%'}}>Puedes pagar sus creditos a traves de la plataformas de pago Mercado pago y PayPal. Si desea utilizar otro sistema de pago porfavor contactese con <span style={{color:style.rojo}}>soporte@vistaguay.com</span></p>
                        <div style={{display:'flex', gap:'50px'}}>
                            <MercadoPagoIcon style={{width:'120px',height:'auto'}}/>
                            <PayPalIcon style={{width:'120px',height:'auto'}}/>
                        </div>
                    </div>
                </li>
            </ul>
        </>
    );
}
