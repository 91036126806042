import { Icon } from '@blueprintjs/core';
import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import { ReactComponent as CropIcon } from 'assets/icons/Cultivo-comodin.svg';
import { ReactComponent as EnsayoIcon } from 'assets/icons/ensayoIcon.svg';
import { ReactComponent as SatelitalIcon } from 'assets/icons/ico-auditoria.svg';
import { ReactComponent as MalezaIcon } from 'assets/icons/malezaIcon.svg';
import { ReactComponent as MosaicIcon } from 'assets/icons/Mosaico-comodin.svg';
import { ReactComponent as VolareIcon } from 'assets/icons/volareIcon.svg';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Spinner from 'components/Spinner/Spinner';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import SidebarHeader from 'layout/Sidebar/SidebarHeader';
import { LotProjectItem, SubHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector } from 'state/hooks';

import analyticsImg from '../../assets/img/taskItem/analytics.png'
import auditImg from '../../assets/img/taskItem/auditoria.png'
import malezaImg from '../../assets/img/taskItem/malezas.png'
import missionImg from '../../assets/img/taskItem/mission.png'
import mosaicImg from '../../assets/img/taskItem/mosaic.png'
import pqImg from '../../assets/img/taskItem/planting-quality.png'
import style from './AddTask.module.scss';

interface Props {
  text: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  Icon: React.ReactNode;
}

interface TaskItem {
  imgpath: string;
  label: string;
  icon: React.ReactNode,
  navigateToPath: string,
  disabled?: boolean
}

export function AddTask() {
  const { projectId, lotId } = useParams<{ projectId: string; lotId: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );

  if (!projectId || !lotId) {
    return <></>;
  }

  useEffect(() => {
    if (!projectId || !lotId) {
      navigate('/campaign/search');
      return;
    }
  }, [projectId, lotId, navigate]);

  const {
    data: project,
    isLoading: isLoadingProject,
    error: errorProject,
  } = useGetProjectQuery(projectId as unknown as number);

  const {
    data: lot,
    isLoading: isLoadingLots,
    error: errorLots,
    isSuccess: isSuccessLot,
  } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    if (isLoadingLots || isLoadingProject) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingLots, isLoadingProject]);

  useEffect(() => {
    if (errorLots || errorProject || !projectId || !lotId) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [errorLots, errorProject, projectId, lotId]);

  const taskItems: TaskItem[] = [
    {
      label: t('mission'),
      imgpath: missionImg,
      navigateToPath: `/projects/${projectId}/lots/${lotId}/addTask/volare`,
      icon: <VolareIcon className={style.taskIcon} />,
    },
    {
      label: t('mosaic'),
      imgpath: mosaicImg,
      navigateToPath: `/projects/${projectId}/lots/${lotId}/addTask/mosaic`,
      icon: <MosaicIcon className={style.taskIcon} />,
    },
    {
      label: t('planting-quality'),
      imgpath: pqImg,
      navigateToPath: `/projects/${projectId}/lots/${lotId}/addTask/plantCount`,
      icon: <CropIcon className={style.taskIcon} />,
    },
    {
      label: t('plague'),
      imgpath: malezaImg,
      navigateToPath: '',
      icon: <MalezaIcon className={style.taskIcon} />,
      disabled: true
    },
    {
      label: t('audit'),
      imgpath: auditImg,
      navigateToPath: '',
      icon: <SatelitalIcon className={style.taskIcon} />,
      disabled: true
    },
    {
      label: t('analitics'),
      imgpath: analyticsImg,
      navigateToPath: '',
      icon: <EnsayoIcon className={style.taskIcon} fill={'white'} />,
      disabled: true
    },
  ];

  return (
    <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
      <SidebarHeader />

      <SubHeader
        backTo={`/projects/${projectId}/lots/${lotId}`}
        backText={t('mis-proyectos') ?? ''}
      >
        <LotProjectItem
          campaignName={selectedCampaign?.name}
          projectName={project?.nombre}
          projectColor={project?.color}
          lotName={lot?.name}
        />
      </SubHeader>

      <SidebarBody>
        <Modal
          isOpen={modalOpened}
          closeFn={() => setModalOpened(false)}
          closeComponent={<Icon icon='cross' color='white' />}
          header={t('nueva-mision')}
        >
          <div className={style.newMissionModal}>
            <Button
              text={t('mapping')}
              variant='NEUTRAL'
              color='white'
              onClick={() => navigate(`/projects/${projectId}/lots/${lotId}/volare/mapping`)}
            />
            <Button
              text={t('scouting')}
              variant='NEUTRAL'
              color='white'
              onClick={() => navigate(`/projects/${projectId}/lots/${lotId}/volare/scouting`)}
            />
          </div>
        </Modal>

        <div className={style.addTaskWrapper}>
          <div style={{ marginBottom: '20px' }}>
            <h2 className={style.title}>{t('add-task')}</h2>
          </div>

          <div className={style.taskItemGrid}>
            {taskItems.map((taskItem, index) => (
              <div 
                className={style.taskItemWrapper} 
                key={index} 
                onClick={() => navigate(taskItem.navigateToPath)}
                style={{
                  opacity: taskItem.disabled ? '50%' : '100%',
                  cursor: taskItem.disabled ? 'default' : ''
                }}
              >
                <div className={style.taskItemImgWrapper}>
                  <img
                    src={taskItem.imgpath}
                    className={style.taskItemImg}
                    loading="lazy"
                    alt={taskItem.label}
                  />
                </div>
                <div className={style.taskItemData}>
                  <p>{taskItem.label}</p>
                  {taskItem.icon}
                </div>
              </div>
            ))}
          </div>
        </div>
      </SidebarBody>

      <SidebarFooter sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          width='40%'
          onClick={() => navigate(`/projects/${projectId}/lots/${lotId}`)}
          text={t('cancel')}
          variant={'NEUTRAL-GRAY'}
        />
      </SidebarFooter>
    </Sidebar>
  );
}