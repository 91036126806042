import { ReactComponent as CopyLinkIcon } from 'assets/icons/ico-copy.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/ico-linkedin.svg';
// import { ReactComponent as TwitterIcon } from 'assets/icons/ico-twitter.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/ico-whatsapp.svg';
import clsx from 'classnames';
import { useState } from 'react';
import { IAllTasks } from 'state/slices/taskSlice';

import style from '../Contextual.module.scss';

export default function ShareOption({
  task,
  showHorizontal,
  showName,
}: {
  task: IAllTasks;
  showHorizontal?: boolean;
  showName?: boolean;
}) {
  const [hasCopy, setHasCopy] = useState(false);
  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
    setHasCopy(true);
  }
  const taskData = task.task as any
  if (!task) {
    return (
      <div className={style.body}>
        <div className={style.contextualButton}>
          <p>La tarea no se encuentra</p>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(style.body, showHorizontal && style.contextualMenuHorizontal)}>
      <div>
        <div className={style.contextualButton}>
          {showName === true? 
          <p>{hasCopy ? 'Enlace copiado' : 'Copiar enlace'}</p>
          : null
          }
          <div
            className={style.actions}
            onClick={() =>
              copyTextToClipboard(`${window.location.origin}/public/task/${taskData.encriptedData}`)
            }
          >
            <CopyLinkIcon
              className={style.icon}
              style={{ fill: style.light, width: '20px', height: '20px' }}
            />
          </div>
        </div>
        <hr style={{margin: '5px 0 5px 0'}}/>
      </div>
      
      <div>
        <div className={style.contextualButton}>
          {showName === true ? <p>LinkedIn</p> : null}
          <div className={style.actions}>
            <LinkedInIcon
              className={style.icon}
              style={{ fill: style.light, width: '20px', height: '20px' }}
            />
          </div>
        </div>
        <hr style={{margin: '5px 0 5px 0'}}/>
      </div>
      
      <div>
        <div className={style.contextualButton}>
          {showName === true? <p>Whatsapp</p> : null}
          <div className={style.actions}>
            <WhatsAppIcon
              className={style.icon}
              style={{ fill: style.light, width: '20px', height: '20px' }}
            />
          </div>
        </div>
        <hr style={{margin: '5px 0 5px 0'}}/>
      </div>
      
      {/* <div className={style.contextualButton}>
      {showName === true? 
        <p>Twitter</p> : null}
        <div className={style.actions}>
          <TwitterIcon
            className={style.icon}
            style={{ fill: style.light, width: '20px', height: '20px' }}
          />
        </div>
      </div> */}
    </div>
  );
}
