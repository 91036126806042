import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Prescription } from 'models/Prescription';


export interface PrescriptionState {
  showPrescription: boolean;
  showPrescriptionTable: boolean; 
  collapsePrescriptionTable: boolean;
  selectedPrescription: Prescription | null;
  prescriptionTheme: Array<string>;
}

const initialState: PrescriptionState = {
    showPrescription: false,
    showPrescriptionTable: false,
    collapsePrescriptionTable: false,
    selectedPrescription: null,
    prescriptionTheme: [
      'rgba(193, 39, 45, 0.6)',
      'rgba(241, 90, 36, 0.6)',
      'rgba(247, 147, 30, 0.6)',
      'rgba(110, 0, 52, 0.6)',
      'rgba(34, 181, 115, 0.6)',
      'rgba(0, 96, 104, 0.6)',
      'rgba(131, 139, 197, 0.6)',
      'rgba(41, 171, 226, 0.6)',
      'rgba(25, 93, 230, 0.6)',
      'rgba(46, 49, 146, 0.6)',
    ]
};

const reducer = {
  setSelectedPrescription: (state: PrescriptionState, action: PayloadAction<Prescription | null>) => {
    state.selectedPrescription = action.payload;
  },
  setShowPrescription: (state: PrescriptionState, action: PayloadAction<boolean>) => {
    state.showPrescription = action.payload;
  },
  setShowPrescriptionTable: (state: PrescriptionState, action: PayloadAction<boolean>) => {
    state.showPrescriptionTable = action.payload;
  },
  setCollapsePrescriptionTable: (state: PrescriptionState, action: PayloadAction<boolean>) => {
    state.collapsePrescriptionTable = action.payload;
  },
  setPrescriptionTheme: (state: PrescriptionState, action: PayloadAction<Array<string>>) => {
    state.prescriptionTheme = action.payload;
  }
};

export const prescriptionSlice = createSlice({
  name: 'prescription',
  initialState,
  reducers: reducer,
});
export const {
  setSelectedPrescription,
  setShowPrescription,
  setShowPrescriptionTable,
  setCollapsePrescriptionTable,
  setPrescriptionTheme
} = prescriptionSlice.actions;

export default prescriptionSlice.reducer;
