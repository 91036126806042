import { ReactComponent as ExportIcon } from 'assets/icons/exportIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico-info.svg'
import { ReactComponent as LayersIcon } from 'assets/icons/ico-layers.svg';
// import { ReactComponent as ShareIcon } from 'assets/icons/shareIcon.svg';
import { ReactComponent as StatsIcon } from 'assets/icons/statsIcon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashIcon.svg';
import MosaicTask from 'models/MosaicTask';
import { useEffect, useState } from 'react';
import { MosaicTaskView } from 'state/slices/mosaicSlice';

import style from './Contextual.module.scss';
import EditOption from './Options/EditOption';
import ExportOption from './Options/ExportOption';
import Histogram from './Options/Histogram';
import LayerSelector from './Options/LayerSelector';
// import ShareOption from './Options/ShareOption';
import TrashOption from './Options/TrashOption';

export function MosaicContextual({
  projectId,
  lotId,
  taskId,
  mosaicTask,
  handdleModal,
  mosaicView
}: {
  projectId: string;
  lotId: string;
  taskId: string;
  mosaicTask: MosaicTask;
  mosaicView: MosaicTaskView
  handdleModal: () => void;
}) {
  const [option, setOption] = useState(0);
  const [task,setTask] = useState<MosaicTask>()

  if (!mosaicView ) {
    return (
      <div className={style.body}>
        <div className={style.contextualButton}>
          <p>La tarea no se encuentra</p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    setTask(mosaicTask)
  },[mosaicTask])

  if(task == undefined) return <></>

  const options = [
    <LayerSelector mosaicView={mosaicView} key={0} />,
    <Histogram key={1} mosaicView={mosaicView}/>,
    <ExportOption key={2} task={mosaicTask} taskId={taskId} />,
    // <ShareOption task={mosaicTask} key={2} />,
    <EditOption task={task} taskId={taskId} key={3} />,
    <TrashOption taskId={taskId} key={4} />,
    
  ];

  return (
    <>
      <div className={style.contextualMenu}>
        {options[option]}
        <div className={style.tabBar}>
          <div className={style.tabBarIcons}>
            {/* LAYERS */}
            <div
              className={option == 0 ? style.bubble : style.bubbleOff}
              onClick={() => {setOption(0);}}
            >
              <LayersIcon
                style={{stroke: style.azul}}
                className={style.tabicon}
              />
            </div>

            {/* HISTOGRAM */}
            <div
              className={option == 1 ? style.bubble : style.bubbleOff}
              onClick={() => { 
                setOption(1); 
                // handdleModal();
              }}
            >
              <StatsIcon
                style={{stroke: style.azul}}
                className={style.tabicon}
              />
            </div>

            {/* EXPORT */}
            <div
              className={option == 2 ? style.bubble : style.bubbleOff}
              onClick={() => {setOption(2)}}
            >
              <ExportIcon
                style={{stroke: style.azul}}
                className={style.tabicon}
              />
            </div>
            
            {/* INFO */}
            <div
              className={option == 2 ? style.bubble : style.bubbleOff}
              onClick={() => setOption(2)}
            >
              <InfoIcon 
                className={style.tabicon} 
                style={{fill: option == 2 ? style.lightdisabled : style.azul}}
              />
            </div>

            {/* DELETE */}
            <div
              className={option == 4 ? style.bubble : style.bubbleOff}
              // onClick={() => setOption(4)}
            >
              <TrashIcon className={style.tabicon} fill={style.lightdisabled}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
