import { useAddCampaignMutation } from 'api/Vistaguay/CampaignAPI';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Spinner from 'components/Spinner/Spinner';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import { SubHeader } from 'layout/Sidebar/SubHeader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'state/hooks';
import { setSelectCampaign } from 'state/slices/campaignSlice';

import { ReactComponent as CalendarIcon } from '../../assets/icons/ico-calendar-blue.svg'
import styles from './AddCampaign.module.scss';

export default function AddCampaign() {
  const { newUser } = useParams()
  const [dateStart, setDateStart] = useState<string>('')
  const [dateEnd, setDateEnd] = useState<string>('')
  const [tag, setTag] = useState<string>('')
  const [AddCampaign, { isLoading, isSuccess, data }] = useAddCampaignMutation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation();

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const startDate = new Date(dateStart)
    const endDate = new Date(dateEnd)
    const name = `${dateStart?.slice(2, 4)}/${dateEnd?.slice(2, 4)} - ${tag}`
    const create = new Date().toISOString()
    console.log('create', create);

    AddCampaign({
      name: name,
      begin: startDate.toISOString(),
      end: endDate.toISOString(),
      createdAt: create,
    })
  }

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setSelectCampaign(data))
      if (newUser == 'true') {
        console.log(newUser)
        navigate('/pricing')
      } else {
        navigate('/projects')
      }
    }

  }, [isSuccess, navigate])

  return (
    <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
      {isLoading && <Spinner />}

      <SidebarBody >
        <div className={styles.addcampaignWrapper}>
          <CalendarIcon style={{marginBottom: '20px'}}/>

          <h2 className={styles.title}>{t('add-campaign')}</h2>
          
          <form onSubmit={(e) => e.preventDefault()}>
            <div className={styles.gridContainer}>
              <Input
              className={styles.input}
                onChange={(e) => setDateStart(e.target.value)}
                style={{width: '100%'}}
                type={'date'}
                label={`${t('desde')}*` ?? ''}
              />
              <Input
               className={styles.input}
                onChange={(e) => setDateEnd(e.target.value)}
                style={{width: '100%'}}
                type={'date'}
                label={`${t('hasta')}*` ?? ''}
              />
            </div>

            <div className={styles.gridContainer} >
              <div className={styles.flexContainer}>
                <div className={styles.boxItem}>
                  <h2 className={styles.box}>{`${dateStart?.slice(2, 4).split('')[0] || ''}`}</h2>
                  <h2 className={styles.box}>{`${dateStart?.slice(2, 4).split('')[1] || ''}`}</h2>
                </div>
                <h2 >/</h2>
                <div className={styles.boxItem}>
                  <h2 className={styles.box}>{`${dateEnd?.slice(2, 4).split('')[0] || ''}`}</h2>
                  <h2 className={styles.box}>{`${dateEnd?.slice(2, 4).split('')[1] || ''}`}</h2>
                </div>
              </div>

              <div style={{display: 'flex', alignItems:'center'}}>
                {/* <h2 style={{marginTop: '20px', marginRight: '10px'}}>-</h2> */}
    
                <Input
                  label={`${t('name')}`}
                  onChange={(e) => setTag(e.target.value)}
                  style={{ width: '100%' }}
                  type={'text'}
                  disabled={dateStart == '' || dateEnd == ''}
                />
              </div>
            </div>
          </form>
        </div>
      </SidebarBody>
      
      <SidebarFooter>
        <div className={styles.footerGridContainer}>
          <Button 
            variant='NEUTRAL-GRAY' 
            text={t('cancelar')}  
            onClick={(e) => navigate('/campaign/search')} 
          />

          <Button
            variant='SUCCESS' 
            text={t('save')}
            disabled={dateStart == '' || dateEnd == ''}
            onClick={handleSubmit}
          />
        </div>
        
      </SidebarFooter>
    </Sidebar>
  );
}
