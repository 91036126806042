import {
  area,
  bbox,
  booleanContains,
  center,
  Feature,
  featureCollection,
  featureEach,
  flip,
  Geometry,
  length,
  LineString,
  lineString,
  polygon,
  Position,
  Properties,
  squareGrid,
  transformRotate,
  transformTranslate,
} from '@turf/turf';
import { ScoutingMission } from 'models/Mission';
import { IFlightPlanInfo } from 'state/slices/volareSlice';

export interface PlanOptions {
  photoCount?: number;
  speed?: number;
  direction?: string;
}

export interface Mission {
  waypoints: Waypoint[];
}

export interface Waypoint {
  actions: Action[];
  lat: number;
  lng: number;
}

export interface Action {
  action: string;
  value: number;
}

const PHOTO_TIME = 2;
const GIMBAL_TIME = 1;

const formatTwoDigitsInteger = (number: number) => ('0' + number).slice(-2);

const secondsToMinuteSecondsString = (seconds: number) => {
  const s = Math.ceil(seconds);
  return `${formatTwoDigitsInteger(Math.floor(s / 60))}:${formatTwoDigitsInteger(s % 60)}`;
};

export interface IScoutingPlan {
  horizontalLine: Feature<LineString, Properties>;
  photoPoints: Feature[];
  planInfo: IFlightPlanInfo;
}

/**
 * @param {Array} points
 * @param {Object} planOptions
 */
export function getScoutingPlan(points: number[][], planOptions: PlanOptions): IScoutingPlan {
  const photoCount = planOptions.photoCount || 10;
  const speed = planOptions.speed || 10;
  const angle = parseInt(planOptions.direction!, 10) * -1 || 0;

  points.push(points[0]);
  const poligonPoints = [points];
  let polFeature = flip(polygon(poligonPoints));

  const pivot = [...polFeature.geometry.coordinates[0][0]];
  const rotateOptions = { pivot: pivot };
  const rotatedPolFeature = transformRotate(polFeature, angle, rotateOptions);
  polFeature = rotatedPolFeature;

  const bBox = bbox(polFeature);
  const [minX, minY, maxX, maxY] = bBox;

  const horizontalLine = lineString([
    [minX, minY],
    [maxX, minY],
  ]);
  const horizontalLenght = length(horizontalLine, { units: 'meters' });

  const getCellSide = (iteradorLargoCuadrado: number) =>
    Math.floor(horizontalLenght / iteradorLargoCuadrado);

  // Determine cell side
  // init vars
  let iteradorLargoCuadrado = 1;
  let cellSide = getCellSide(iteradorLargoCuadrado); // Math.round(horizontalLenght / (iteradorLargoCuadrado * 1.1))
  let squareGridVar = squareGrid(bbox(polFeature), cellSide, { units: 'meters' });

  let features = squareGridVar.features.filter((feature) => {
    const centerVar = center(feature);
    if (booleanContains(polFeature, centerVar)) {
      return feature;
    }
  });
  let squareGridNuevo = { ...squareGridVar, features: features };

  while (squareGridNuevo.features.length < photoCount) {
    iteradorLargoCuadrado = iteradorLargoCuadrado + 1;
    cellSide = getCellSide(iteradorLargoCuadrado);
    squareGridVar = squareGrid(bbox(polFeature), cellSide, { units: 'meters' });
    features = squareGridVar.features.filter((feature) => {
      const centerVar = center(feature);
      if (booleanContains(polFeature, centerVar)) {
        return feature;
      }
    });
    squareGridNuevo = { ...squareGridVar, features: features };
  }
  squareGridNuevo = { ...squareGridNuevo, features: features };

  // correct the order of the flight
  // we use a rectangle as a grid column
  const rectanglePoints = [
    [
      [squareGridNuevo.features[0].geometry.coordinates[0][0][0], bBox[1]],
      [squareGridNuevo.features[0].geometry.coordinates[0][2][0], bBox[1]],
      [squareGridNuevo.features[0].geometry.coordinates[0][2][0], bBox[3]],
      [squareGridNuevo.features[0].geometry.coordinates[0][0][0], bBox[3]],
      [squareGridNuevo.features[0].geometry.coordinates[0][0][0], bBox[1]],
    ],
  ];
  let rectangle = polygon(rectanglePoints);
  let goingToNorth = true;
  let aux: Array<any> = [];
  let photoPoints: Array<Feature> = [];
  featureEach(squareGridNuevo, function (currentFeature, featureIndex) {
    const centerVar = center(currentFeature);

    if (booleanContains(rectangle, centerVar)) {
      // eslint-disable-next-line no-constant-condition
      if ((Math.random() > 0, 8)) aux.push(centerVar);
    } else {
      if (!goingToNorth) {
        aux.reverse();
      }
      photoPoints = photoPoints.concat(aux);
      aux = [centerVar];

      goingToNorth = !goingToNorth;

      // move the rectangle to next column
      /* eslint no-unused-expressions: "off"*/
      rectangle = transformTranslate(rectangle, cellSide, 90, { units: 'meters' });
    }
  });
  if (!goingToNorth) {
    aux.reverse();
  }
  photoPoints = photoPoints.concat(aux);

  const gridCenterCount = photoPoints.length;
  const centersToDelete = gridCenterCount - photoCount;
  const deleteFromStart = Math.ceil(centersToDelete / 2);
  const deleteFromEnd = Math.floor(centersToDelete / 2);
  photoPoints = photoPoints.slice(deleteFromStart, photoPoints.length - deleteFromEnd);

  let collection = featureCollection(photoPoints);

  collection = transformRotate(collection, angle * -1, rotateOptions);

  photoPoints = collection.features;

  let flightPath;
  try {
    flightPath = lineString(
      photoPoints.map((feature) => (feature.geometry as Geometry).coordinates as Position),
      //   photoPoints.map((feature) => (feature.geometry as Geometry).coordinates as Position[]),
    );
  } catch (error) {
    flightPath = lineString([
      [-34.912232267427825, -56.16759181022644],
      [-34.91395665031975, -56.16755962371827],
      [-34.91359594056796, -56.162130832672126],
    ]);
  }

  const areaVar = (area(polFeature) / 10000).toFixed(2);
  const numberOfPhotos = photoPoints.length;

  const waitTime = 3;

  const lengthVar = length(flightPath, { units: 'meters' });
  const retraso = numberOfPhotos * PHOTO_TIME * waitTime; // list_centers.lenght * 2 // 0 // linePathFeature.coordinates.lenght
  const time = lengthVar / speed + retraso; // seconds

  const bateries = Math.ceil(time / 900);

  const planInfo = {
    minutes: secondsToMinuteSecondsString(time),
    area: areaVar,
    photos: numberOfPhotos,
    bateries: bateries,
  };
  // Flip photoPoints and horizontalLine
  const photoPointsFliped = photoPoints.map((feature) => flip(feature));
  const horizontalLineFliped = flip(horizontalLine);

  return {
    horizontalLine: horizontalLineFliped,
    photoPoints: photoPointsFliped,
    planInfo,
  };
}

export function getScoutingPlanInfo(
  planOptions: PlanOptions,
  currentMission: ScoutingMission,
): IFlightPlanInfo {
  if (!currentMission) { 
    return {
      minutes: '-',
      area: '-',
      photos: -1,
      bateries: -1,
    };
  }
  const points = currentMission.waypoints.map((waypoint) => {
    return [waypoint.lng, waypoint.lat];
  });
  const photoCount = currentMission.waypoints.reduce((photoCount, waypoint) => {
    return (
      photoCount +
      waypoint.actions.reduce((photoCountWaypoint, action) => {
        let photoCount = 0;
        if (action.action === 'PHOTO') {
          photoCount = 1;
        }
        return photoCountWaypoint + photoCount;
      }, 0)
    );
  }, 0);
  if (points.length == 0) {
    return {
      minutes: '-',
      area: '-',
      photos: -1,
      bateries: -1,
    };
  }
  try {
    let retraso = 0;
    if (currentMission && currentMission.waypoints) {
      retraso = currentMission.waypoints.reduce((retrasoTotal, waypoint) => {
        return (
          retrasoTotal +
          waypoint.actions.reduce((retrasoWaypoint, action) => {
            let retraso = 0;
            switch (action.action) {
              case 'PHOTO':
                retraso = PHOTO_TIME;
                break;
              case 'WAIT':
                if (action.value) {
                  retraso = action.value;
                }
                break;
              case 'GIMBAL':
                retraso = GIMBAL_TIME;
                break;

              default:
                retraso = 0;
                break;
            }
            return retrasoWaypoint + retraso;
          }, 0)
        );
      }, 0);
    }

    // console.log(`Retraso: ${retraso}`)

    const speed = planOptions.speed || 10;
    const linePoints = [...points];

    // console.log(`%%${JSON.stringify(linePoints)}`)
    const flightPath = lineString(linePoints);

    const lengthVar = length(flightPath, { units: 'meters' });
    // let retraso = linePoints.length * photoTime * waitTime // list_centers.lenght * 2 // 0 // linePathFeature.coordinates.lenght
    const time = lengthVar / speed + retraso; // seconds
    const bateries = Math.ceil(time / 900);

    return {
      minutes: secondsToMinuteSecondsString(time),
      area: '-',
      photos: photoCount,
      bateries: bateries,
    };
  } catch (error) {
    console.error(error);
    return {
      minutes: '-',
      area: '-',
      photos: -1,
      bateries: -1,
    };
  }
}
