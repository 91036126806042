import { difference, Feature, MultiPolygon } from '@turf/turf';
import { Polygon } from 'models/Polygon';
import GeoJSON from 'ol/format/GeoJSON';
import { Draw, Modify } from 'ol/interaction';
import Snap from 'ol/interaction/Snap';
import { Circle } from 'ol/style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import React, { useContext, useEffect } from 'react';
import { dataProjection, featureProjection } from 'services/configurations';

import MapContext from '../../Context/MapContext';
import VectorLayerContext from '../../Context/VectorLayerContext';

interface DrawProps {
  onChange: (a: Polygon) => void;
}

function MPolygonEdit({ onChange }: DrawProps) {
  const vectorLayer = useContext(VectorLayerContext);
  const map = useContext(MapContext);
  const stroke = new Stroke({
    color: 'rgba(255, 50, 50, 0.5)',
    width: 2,
  });
  const fill = new Fill({
    color: 'rgba(255, 100, 100, 0.3)',
  });
  useEffect(() => {
    const source = vectorLayer?.getSource();
    if (source) {
      const draw = new Draw({
        type: 'Polygon',
        style: new Style({
          fill,
          stroke,

          // adds the dots
          image: new Circle({
            fill: fill,
            stroke: stroke,
            radius: 5,
          }),
        }),
      });
      draw.on('drawend', (e) => {
        // using this polygon "cut" the existing polygon
        const geojson = new GeoJSON({
          dataProjection: dataProjection,
          featureProjection: featureProjection,
        });
        const substractPoly = geojson.writeFeatureObject(e.feature).geometry as Polygon;
        const polygon1 = geojson.writeFeatureObject(source.getFeatures()[0]).geometry as Polygon;
        if (!polygon1 || !substractPoly) {
          console.error('Error on MPolygonEdit: polygon1 or substractPoly is undefined');
          return;
        }
        const diff = difference(polygon1, substractPoly);
        // User cannot create multipolygons right now
        if (diff?.geometry?.type === 'MultiPolygon') {
          return;
        } else if (diff?.geometry?.type === 'Polygon') {
          // If the difference is a polygon, we can use it
          onChange(diff.geometry as Polygon);
        }
      });
      map?.addInteraction(draw);

      // EDIT

      const modify = new Modify({
        source,
      });
      modify.on('modifyend', (e) => {
        const geojson = new GeoJSON({
          dataProjection: dataProjection,
          featureProjection: featureProjection,
        });
        const polygon1 = geojson.writeFeatureObject(source.getFeatures()[0]).geometry as Polygon;
        if (!polygon1) {
          console.error('Error on MPolygonEdit: polygon1 is undefined');
          return;
        }
        onChange(polygon1);
      });
      map?.addInteraction(modify);

      // SNAP
      const snap = new Snap({ source });
      map?.addInteraction(snap);

      return () => {
        map?.removeInteraction(draw);
        map?.removeInteraction(modify);
        map?.removeInteraction(snap);
      };
    }
  }, [map, onChange, vectorLayer]);

  return <></>;
}

export default MPolygonEdit;
