import { VistaguayApi } from './VistaguayAPI';

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Users', 'Credit'],
});
interface ITransfer {
  userFrom: number;
  userTo: number;
  amount: number;
}
const userGroupApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getUsersGroup: builder.query<any, void>({
      query: () => 'users/groups',
      providesTags: ['Users'],
    }),
    getGroupInfo: builder.query<any, void>({
      query: () => 'users/groups/info',
      providesTags: ['Users'],
    }),
    addToGroup: builder.mutation({
      query: ({ userId, group }: any) => ({
        url: 'users/groups/add',
        method: 'POST',
        body: { userId, group },
      }),
      invalidatesTags: ['Users'],
    }),

    removeToGroup: builder.mutation({
      query: ({ userId, group }: any) => ({
        url: 'users/groups/remove',
        method: 'DELETE',
        body: { userId, group },
      }),
      invalidatesTags: ['Users'],
    }),
    transfer: builder.mutation<void, ITransfer>({
      query: ({ userFrom, userTo, amount }: any) => ({
        url: 'users/groups/transfer',
        method: 'POST',
        body: {
          userFrom: '' + userFrom,
          userTo: '' + userTo,
          amount: amount,
        },
      }),
      invalidatesTags: ['Users', 'Credit'],
    }),
    createUserGroup: builder.mutation<void, any>({
      query: ({ user }: any) => ({
        url: 'users/groups/userGroup',
        method: 'POST',
        body: {
          ...user,
        },
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  useGetUsersGroupQuery,
  useGetGroupInfoQuery,
  useAddToGroupMutation,
  useRemoveToGroupMutation,
  useTransferMutation,
  useCreateUserGroupMutation,
} = userGroupApi;
