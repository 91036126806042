import { exportGeoJson, exportPdf, exportPng, exportShp } from 'api/Vistaguay/ExportAPI';
import { ReactComponent as JsonIcon } from 'assets/icons/ico-json.svg';
import { ReactComponent as GeoPDF } from 'assets/icons/ico-pdf.svg';
import { ReactComponent as GeoTiff } from 'assets/icons/ico-png.svg';
import { ReactComponent as KMZIcon } from 'assets/icons/ico-zip.svg';
import clsx from 'classnames'
import { ErrorToaster } from 'components/Toaster/AppToaster';
import { PlantCountTask } from 'models/PlantCountTask';
import { useAppSelector } from 'state/hooks';
import { IAllTasks } from 'state/slices/taskSlice';

import style from '../Contextual.module.scss';
export default function ExportOption({ task, taskId }: { task: IAllTasks; taskId: string }) {
  const token = useAppSelector((state) => state.data.accessToken);
  const PqTask = task?.task as PlantCountTask;
  const taskStatus = PqTask?.estadoId; 
  const isDisabled = taskStatus == 5; 

  // #region HANDLERS
  const handleGeoJsonDownload = async () => {
    const resultError = await exportGeoJson({ taskId, token });
    resultError && ErrorToaster('No se encuentra en los resultados de el conteo');
  };
  const handlePdfDownload = async () => {
    if(!isDisabled) {
      const resultError = await exportPdf({ taskId, token });
      resultError && ErrorToaster('No se encuentra en los resultados de el conteo');
    }
  };
  const handlePngDownload = async () => {
    if(!isDisabled) {
      const resultError = await exportPng({ taskId, token });
      resultError && ErrorToaster('No se encuentra en los resultados de el conteo');
    }
  };
  const handleShpDownload = async () => {
    const resultError = await exportShp({ taskId, token });
    resultError && ErrorToaster('No se encuentra en los resultados de el conteo');
  };
  // #endregion

  if (!task) {
    return (
      <div className={style.body}>
        <div className={style.contextualButton}>
          <p>La tarea no se encuentra</p>
        </div>
      </div>
    );
  }

  return (
    <div className={style.body}>
      <div className={clsx(
          style.contextualButton,
          isDisabled ? style.contextualButtonDisabled : style.contextualButtonEnabled
        )}>
        <p className={isDisabled ? style.textDisabled : ''}><b>Reporte</b> GeoPDF</p>
        <div className={clsx(
          style.actions, 
          isDisabled ? style.actionDisabled : ''
        )}>
          <GeoPDF
            onClick={handlePdfDownload}
            className={clsx(
              style.icon,
              isDisabled ? style.iconDisabled : style.iconEnabled
            )}
            style={{width: '28px', height: '28px' }}

          />
        </div>
      </div>

      <hr style={{margin: '5px 0 5px 0'}}/>

      <div className={clsx(
          style.contextualButton,
          isDisabled ? style.contextualButtonDisabled : style.contextualButtonEnabled
        )}>
        <p className={isDisabled ? style.textDisabled : ''}><b>Reporte</b> PNG</p>
        <div className={clsx(
          style.actions, 
          isDisabled ? style.actionDisabled : ''
        )}>
          <GeoTiff
            onClick={handlePngDownload}
            className={clsx(
              style.icon,
              isDisabled ? style.iconDisabled : style.iconEnabled,
            )}
            style={{ width: '28px', height: '28px' }}

          />
        </div>
      </div>

      <hr style={{margin: '5px 0 5px 0'}}/>

      <div className={clsx(
          style.contextualButton, 
          style.contextualButtonEnabled
        )}>
        <p><b>Resultados</b> GeoJSON</p>
        <div className={style.actions}>
          <JsonIcon
            onClick={handleGeoJsonDownload}
            className={style.icon}
            style={{ fill: style.light, width: '25px', height: '25px' }}
          />
        </div>
      </div>

      <hr style={{margin: '5px 0 5px 0'}}/>
      
      <div className={clsx(
          style.contextualButton, 
          style.contextualButtonEnabled
        )}>
        <p><b>Resultados</b> SHP</p>
        <div className={style.actions}>
          <KMZIcon
          
            onClick={handleShpDownload}
            className={style.icon}
            style={{ fill: style.light, width: '28px', height: '28px' }}
          />
        </div>
      </div>
    </div>
  );
}
