/* eslint-disable camelcase */
import { Feature, Geometry, Polygon, polygon,Properties } from '@turf/turf'
import MarkerIcon from 'assets/icons/ico-flag.svg';
import { isScouting,MappingMission, ScoutingMission } from 'models/Mission'
import { useEffect, useState } from 'react'
import { getFlightPlan } from 'services/mappingMissionGenerator'
import { getScoutingPlan,IScoutingPlan, PlanOptions } from 'services/scoutingMissionGenerator'
import { BLACK_LINE, MISSION_POLYGON_COLOR } from 'styles/variables'

import MPoint from './MPoint'
import MPolyLine from './MPolyline'
import MVectorLayer from './MVectorLayer'
import MPolygon from './Polygon/MPolygon'

interface MissionItemProps {
    mission: MappingMission | ScoutingMission;
    zIndex?: number;
    isVisible: boolean;
}

const editingPolygon = (mission: any) => {
    // console.log(`Editando scouting ${JSON.stringify(mission)}`)
    if (mission.polygon_vertices) {
      // console.log(`Imprimiendo polygon vertices`)
      return [mission.polygon_vertices.map((bbddPoint: any) => [bbddPoint.lng, bbddPoint.lat])];
    } else {
      return [];
    }
};


  
export const MMission = ({ mission, zIndex, isVisible }: MissionItemProps) => {
    const [missionPoints,setMissionPoints] = useState<number[][] | null>(null)

    useEffect(() => {
        if(isScouting(mission)) return
        const planOptions = {
            flyHeight: Number(mission.altitude) / 1000 || 0.07,
            speed: mission.speed || 10,
            direction: mission.flight_direction|| 0,
            camera: mission.camera_name || 'MAVIC_PRO',
            ov_frontal: mission.frontal_overlap,
            ov_lateral: mission.lateral_overlap,
        };
        
        const currentMissionPolygon = mission.polygon_vertices.map((point: any) => [
            point.lng,
            point.lat,
        ]);
        const polygonFromMission = polygon([currentMissionPolygon]);
        const mappingFlightPlan  = getFlightPlan(polygonFromMission.geometry,planOptions)

        setMissionPoints(mappingFlightPlan?.line.geometry.coordinates.map((p) => [p[0], p[1]]) || [])
    },[mission])

    if(isScouting(mission)) { 
        return (
            <>
            <MPolyLine 
              zIndex={zIndex}
              coordinates={mission.waypoints.map((waypoint: any) => [
                waypoint.lng,
                waypoint.lat,
              ])}
            />

            { mission.waypoints.map((waypoint: any, idx) => {
                return (
                    <MPoint
                        zIndex={zIndex}
                        key={waypoint.id}
                        lat={waypoint.lat}
                        lng={waypoint.lng}
                        marker={MarkerIcon}
                        pointOrder={`${idx + 1}`}
                    />
                )
            })}


            <MPolygon zIndex={zIndex} color={MISSION_POLYGON_COLOR} coordinates={editingPolygon(mission)} stroke= {BLACK_LINE} height={1}/>
        </>
        )
    }

    return (
        <>
            <MPolygon zIndex={zIndex} color={MISSION_POLYGON_COLOR} coordinates={editingPolygon(mission)} stroke={BLACK_LINE} height={1}/>
            { missionPoints && isVisible &&
                <>
                    <MPolyLine zIndex={zIndex} coordinates={missionPoints} />

                    <MVectorLayer zIndex={zIndex ? zIndex + 100000 : undefined}>
                        <MPoint
                            marker={MarkerIcon}
                            onEdit={(newCoords) => null}
                            key={`${missionPoints[0][0]}-${missionPoints[0][1]}`}
                            lat={missionPoints[0][1] as number}
                            lng={missionPoints[0][0] as number}
                            pointOrder="1"
                        />
                        <MPoint
                            marker={MarkerIcon}
                            onEdit={(newCoords) => null}
                            key={`${missionPoints[missionPoints.length - 1][0]}-${missionPoints[missionPoints.length - 1][1]}`}
                            lat={missionPoints[missionPoints.length - 1][1] as number}
                            lng={missionPoints[missionPoints.length - 1][0] as number}
                            pointOrder="2"
                        />
                    </MVectorLayer>
                </>
            }
        </>
     
    )
}