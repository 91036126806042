import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { IUserSearch, useGetUserByUsernameQuery, useUpdateDensityMutation, useUpdateDistanceMutation } from 'api/Vistaguay/TaskAdminAPI';
import { IPlantCountFilterQuery, useDeleteFilesMutation, useGetFilteredCountTasksQuery, useUpdateStatusCountTaskMutation } from 'api/Vistaguay/TaskAPI';
import Button from 'components/Button/Button';
import { Input, InputSelect } from 'components/Inputs/Input';
import { useDebounce } from 'hooks/useDebounce';
import { PLANTCOUNT_STAGE, PlantCountTask } from 'models/PlantCountTask';
import { useEffect, useState } from 'react';
import Modal from 'react-modal'
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './ControlPanel.module.scss';

const initValues: IPlantCountFilterQuery = {
  userId: '',
  username: '',
  name: '',
  status: '',
  plantCountTaskId: '',
  page: '',
  limit: '',
  createdAfter: '',
  createdBefore: '',
};
export interface DensityJson {
  [key: string] : number | string
}

export default function PlantCountTable() {
  const statusQuery = new URLSearchParams(useLocation().search).get('status');
  const [initFilters, setInitFilters] = useState<IPlantCountFilterQuery>({
    ...initValues,
    status: statusQuery ? statusQuery : '',
  });
  const debunceFilters = useDebounce<IPlantCountFilterQuery>(initFilters, 1000);
  const [searchUserId, setSearchUserId] = useState<string>('');
  const debounceSearchUserId = useDebounce<string>(searchUserId || ' ', 1000);
  const [searchUser, setSearchUser] = useState<string>('');
  const debounceSearch = useDebounce<string>(searchUser || ' ', 1000);
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    data: plantCountData = [],
    isLoading: plantCountLoading,
    isFetching: plantCountFetching,
    refetch: refetchPlantCount,
  } = useGetFilteredCountTasksQuery(debunceFilters);

  const {
    data: usersData = [] as IUserSearch[],
    isLoading: usersLoading,
    isFetching: usersFetching,
    refetch: refetchUsers,
  } = useGetUserByUsernameQuery({ username: debounceSearch || '' });

  useEffect(() => {
    console.log(debunceFilters);
    refetchPlantCount();
  }, [debunceFilters, refetchPlantCount]);

  useEffect(() => {
    setInitFilters({
      ...initFilters,
      userId: debounceSearchUserId,
    });
  }, [debounceSearchUserId]);

  useEffect(() => {
    refetchUsers();
  }, [debounceSearch, refetchUsers]);

  useEffect(() => {
    if (statusQuery) {
      setInitFilters({
        ...initFilters,
        status: statusQuery,
      });
    }
  }, []);

  return (
    <section className={styles.contentWrapper}>
      <Button
        style={{ height: '30px' }}
        variant='SUCCESS'
        text='<< Volver'
        onClick={() => navigate('/controlVistaguay')}
      />
      <h1 style={{ color: styles.light }}>Planting quality</h1>
      <header>
        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={{ width: '200px', display: 'flex' }}>
            <label>Desde:</label>
            <Input
              type='date'
              placeholder=''
              onChange={(e) =>
                setInitFilters({
                  ...initFilters,
                  createdAfter: encodeURIComponent(new Date(e.target.value).toISOString()),
                })
              }
            />
          </div>
          <div style={{ width: '200px', display: 'flex' }}>
            <label>Hasta:</label>
            <Input
              type='date'
              placeholder=''
              onChange={(e) => {
                setInitFilters((state) => ({
                  ...state,
                  createdBefore: encodeURIComponent(new Date(e.target.value).toISOString()),
                }));
                console.log(initFilters);
              }}
            />
          </div>
          <p>Conteos encontrados: {plantCountData?.length}</p>
        </div>
        <div style={{ display: 'flex', gap: '2px' }}>
          <p>Filtros:</p>
          <div>
            <Input
              placeholder='Nombre de usuario'
              value={searchUser}
              onFocus={() => {
                setSearchIsOpen(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setSearchIsOpen(false);
                }, 200);
              }}
              onChange={(e) => {
                setSearchUser(e.target.value);
                setInitFilters({ ...initFilters, username: e.target.value });
              }}
            />

            <ul className={styles.searchBox} style={{ display: searchIsOpen ? 'grid' : 'none' }}>
              {!usersLoading &&
                usersData.map((user: IUserSearch) => (
                  <li
                    key={user.usuarioId}
                    onClick={() => {
                      setSearchUser(user.nombre);
                    }}
                  >
                    {user.nombre}
                  </li>
                ))}
              {usersFetching && <Spinner size={SpinnerSize.SMALL} />}
            </ul>
          </div>
          <Input
            placeholder='Id de usuario'
            value={initFilters.userId}
            onChange={(e) => setInitFilters({ ...initFilters, userId: e.target.value })}
          />
          <Input
            placeholder='Nombre de conteo'
            value={initFilters.name}
            onChange={(e) => setInitFilters({ ...initFilters, name: e.target.value })}
          />
          <Input
            placeholder='Id de conteo'
            value={initFilters.plantCountTaskId}
            onChange={(e) => setInitFilters({ ...initFilters, plantCountTaskId: e.target.value })}
          />
          <InputSelect
            placeholder='Estado'
            value={initFilters.status}
            onChange={(e) => setInitFilters({ ...initFilters, status: e.target.value })}
          >
            {Object.keys(PLANTCOUNT_STAGE).map((key: string, index: number) =>
              // dont render NaN value
              isNaN(+key) ? null : (
                <option key={key} value={+key}>
                  {PLANTCOUNT_STAGE[+key]}
                </option>
              ),
            )}
            <option key={'Allvalues'} value={'0,1,2,3,4,5,7,9,10'}>
              TODOS
            </option>
          </InputSelect>
        </div>
      </header>
      {plantCountFetching ? (
        <div className={styles.loadingList}>
          <Spinner size={SpinnerSize.STANDARD} />
        </div>
      ) : (
        <section className={styles.taskTable}>
          <header>
            <p>ID</p>
            <p>Usuario</p>
            <p>Estado</p>
            <p>Nombre</p>
            <p>Lote</p>
            <p>Campania</p>
            <p>Fecha de creacion</p>
          </header>
          <ul className={styles.taskList}>
            {plantCountData &&
              plantCountData.map((plantCount: PlantCountTask) => (
                <PlantCountTableItem key={plantCount.conteosPlantasId} plantCount={plantCount} />
              ))}
            {plantCountData.length == 0 && (
              <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h3 style={{ color: styles.rojo }}>No se encontraron Conteos</h3>
              </li>
            )}
          </ul>
        </section>
      )}
    </section>
  );
}

const PlantCountTableItem = ({ plantCount }: { plantCount: PlantCountTask }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <li key={plantCount.conteosPlantasId} onClick={() => setIsOpen(!isOpen)}>
        <p style={{ color: styles.rojo }}>{plantCount.conteosPlantasId}</p>
        <p>{plantCount.creator?.nombre}</p>
        <p>{PLANTCOUNT_STAGE[plantCount.estadoId] || 'No definido'}</p>
        <p>{plantCount.nombre}</p>
        <p>{plantCount.lot?.name}</p>
        <p>{plantCount.campaign?.name}</p>
        <p>{plantCount.createdAt?.toLocaleString().split('T')[0]}</p>
      </li>
      {isOpen && <PlantCountInfo plantCount={plantCount} />}
    </>
  );
};

import {ReactComponent as EditIcon} from 'assets/icons/edit-ico.svg'
import { DownloadLink } from 'components/DownloadLink/DownloadLink';

const PlantCountInfo = ({ plantCount }: { plantCount: PlantCountTask }) => { 
  const [updateStatus] = useUpdateStatusCountTaskMutation()

  const [modalIsOpen, setModalIsOpen] = useState({ isOpen: false , newStatus: 0})
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)

  const [copyDisabled,setCopyDisabled] = useState<boolean>(false)
  const [deleteFiles,{data}] = useDeleteFilesMutation()

  const [density,setDensity] = useState<DensityJson>(JSON.parse(plantCount.densidadesJson))
  const [editDensity,setEditDensity] = useState<boolean>(false)
  const [updateDensity,{isSuccess:isDensitySuccess}] = useUpdateDensityMutation()

  const [editDistance,setEditDistance] = useState<boolean>(false)
  const [distance,setDistance] = useState<number>(plantCount.dSurco)
  const [updateDistance, {isSuccess: isDistanceSuccess}] = useUpdateDistanceMutation()

  const handleCopyPath = () => {
    navigator.clipboard.writeText(
      `/home/ubuntu/backend/uploads/0/${plantCount.creator?.usuarioId}/${plantCount.lot?.id}/conteo_de_plantas/${plantCount.conteosPlantasId}`,
    );
    setCopyDisabled(true)
    setTimeout(() => {
      setCopyDisabled(false)
    }, 2000);
  }

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setModalIsOpen({isOpen:true, newStatus: +e.target.value})
  }

  const handleEditDensity = () => {
    if(!editDensity) {
      setEditDensity(true)
      return
    }
    setDensity(JSON.parse(plantCount.densidadesJson))
    setEditDensity(false)
  }

  const handleEditDistance = () => {
    if(!editDistance) {
      setEditDistance(true)
      return
    }
    setDistance(plantCount.dSurco)
    setEditDistance(false)
  }

  const handleSaveDistance = () => {
    setEditDistance(false)
    updateDistance({taskId:String(plantCount.conteosPlantasId), distance: +distance})

  }

  const handleSaveDensity = () => {
    setEditDensity(false)
    updateDensity({taskId: plantCount.conteosPlantasId, density:density})
  }


  const handleChangeDensity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDensity({...density,[e.target.name]: Number(e.target.value)})
  }



  return (
    <li className={styles.info} key={`plantCountInfo:${plantCount.conteosPlantasId}`}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
            <div style={{display:'flex',flexDirection:'row', margin:0,padding:0}}>
              <p
                  className={styles.phover}
              >
                  Ruta:
                  <span>{`/home/ubuntu/backend/uploads/0/${plantCount.creator?.usuarioId}/${plantCount.lot?.id}/conteo_de_plantas/${plantCount.conteosPlantasId}`}</span>
              </p>
              <Button disabled={copyDisabled} variant={'SUCCESS'} style={{height:'30px',fontSize:'13px'}} onClick={handleCopyPath} text={!copyDisabled ? 'Copiar' : 'Copiado..'} />
            </div>
          <p>
            Email: <span>{plantCount.creator?.email}</span>
          </p>
          <div style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            margin:0,
            padding:0,
          }}>
            <p>
              Densidades: <span>{JSON.stringify(density)}</span>
            </p>
            {
              isDensitySuccess &&
              <p style={{color: styles.verdeVistaguay}}>
                Densidad editada con exito
              </p>
            }
            {
              editDensity ? 
              <>
              <Button 
                text='CANCELAR' 
                variant='ERROR' 
                type='OUTLINE' 
                onClick={() => handleEditDensity()}
                style={{fontSize:'12px',height:'25px'}}
              />
               <Button 
                text='GUARDAR' 
                variant='SUCCESS' 
                type='OUTLINE' 
                onClick={() => handleSaveDensity()}
                style={{fontSize:'12px',height:'25px'}}
              />
              </>
              :
              <Button 
                text='' 
                variant='SUCCESS' 
                type='OUTLINE' 
                icon={<EditIcon style={{fill:styles.dark ,height:'30px'}}/>}
                onClick={() => handleEditDensity()}
                style={{fontSize:'12px',height:'35px',width:'35px',padding:'5px',margin:0}}
              />
            }
          </div>
          {
            editDensity &&
            (
              Object.entries(JSON.parse(plantCount.densidadesJson)).map(([key,value]) => {
              
                return <div key={`${key}-${value}`} style={{display:'flex',flexDirection:'row',alignItems:'center',margin:0,padding:0}}>
                  <p>
                    {key} :
                  </p>
                  <input type='number' name={key} style={{width:'180px',height:'30px',margin:0,padding:0}} onChange={(e) => handleChangeDensity(e)} value={Number(density[key])}  placeholder='densidad'/>
                </div>
              })
            )
            
          }
          <div style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            margin:0,
            padding:0,
          }}>
            <p>
              Distancia de surco: <span>{distance}</span>
            </p>
            {
              isDistanceSuccess &&
              <p style={{color: styles.verdeVistaguay}}>
                Distancia editada con exito
              </p>
            }
            {
              editDistance ? 
              <>
                            <Button 
                text='CANCELAR' 
                variant='ERROR' 
                type='OUTLINE' 
                onClick={() => handleEditDistance()}
                style={{fontSize:'12px',height:'25px'}}
              />
               <Button 
                text='GUARDAR' 
                variant='SUCCESS' 
                type='OUTLINE' 
                onClick={() => handleSaveDistance()}
                style={{fontSize:'12px',height:'25px'}}
              />
              </>
              :
              <Button 
              text='' 
              variant='SUCCESS' 
              type='OUTLINE' 
              icon={<EditIcon style={{fill:styles.dark ,height:'30px'}}/>}
              onClick={() => handleEditDistance()}
              style={{fontSize:'12px',height:'35px',width:'35px',padding:'5px',margin:0}}
            />
            }

          </div>
          {
            editDistance &&
            <input type="number" value={distance} onChange={(e) => setDistance(+e.target.value)} />
          }
          <p>
            Fecha de vuelo: <span>{plantCount.flightDate as any}</span>
          </p>
          <p>Eliminar archivos :</p>
          
          <Button text={'Eliminar archivos'} variant={'ERROR'}  style={{fontSize:'12px',height:'25px',width:'200px'}} onClick={() => setDeleteModalIsOpen(true)}/>
          {
            data && data === true && <p style={{color: styles.rojo, fontWeight:'normal'}}>Eliminados con exito...</p>
          }
          <div style={{display:'flex',margin:0,padding:0,maxWidth:'200px'}}>
            <label style={{fontWeight:'bold'}} id='estado'>Estado: </label>
            <InputSelect
              placeholder='Estado'
              sx={{ margin:0}}
              value={plantCount.estadoId}
              onChange={(e) => {
                handleStatusChange(e)
              }}
            >
              {Object.keys(PLANTCOUNT_STAGE).map((key: string, index: number) =>
                // dont render NaN value
                isNaN(+key) ? null : (
                  <option key={key} value={+key}>
                    {PLANTCOUNT_STAGE[+key]}
                  </option>
                ),
              )}
            </InputSelect>
          </div>
          <p>
                Reporte:
                <DownloadLink
                  fileName='reporteConteo.pdf'
                  linkUrl={`task/${plantCount.conteosPlantasId}/result/reporteConteo.pdf`}
                >
                  PDF
                </DownloadLink>
              </p>
              <p>
                Log:{' '}
                <DownloadLink
                  fileName='conteo.log'
                  linkUrl={`task/${plantCount.conteosPlantasId}/result/conteo.log`}
                >
                  Link
                </DownloadLink>
              </p>
              <p>
                Log reporte:{' '}
                <DownloadLink
                  fileName='conteo.log'
                  linkUrl={`task/${plantCount.conteosPlantasId}/result/conteo-finish.log`}
                >
                  Link
                </DownloadLink>
              </p>
        </div>
        <Modal 
          isOpen={deleteModalIsOpen} 
          style={{
              content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: styles.verdeOscuro,
                  borderRadius:'12px',
                  border: 'none',
                  width: '300px', // or any custom width
                  height: 'auto', // or any custom height
              },
              overlay: {
                  backgroundColor: 'none',
              }
          }} >
            <div className={styles.modal} style={{color:styles.light}}  >
                <p >Estas seguro de eliminar la carpeta <span style={{fontSize:'15px', color:styles.verdeVistaguay, fontWeight:'bold'}}>{`/home/ubuntu/backend/uploads/0/${plantCount.creator?.usuarioId}/${plantCount.lot?.id}/conteo_de_plantas/${plantCount.conteosPlantasId}`}</span>?</p>
                <div style={{display:'flex',justifyContent:'space-between',width:'100%',marginTop:'5px'}} >
                    <Button variant={'ERROR'} style={{height:'30px',fontSize:'13px'}} onClick={() => setDeleteModalIsOpen(false)} text='Cancelar' />
                    <Button variant={'SUCCESS'} style={{height:'30px',fontSize:'13px'}} onClick={() => {
                        deleteFiles({taskId: String(plantCount.conteosPlantasId)})
                        setDeleteModalIsOpen(false)
                    }} text='Aceptar' />
                </div>
            </div>
        </Modal>
        <Modal 
          isOpen={modalIsOpen.isOpen} 
          style={{
              content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: styles.verdeOscuro,
                  borderRadius:'12px',
                  border: 'none',
                  width: '300px', // or any custom width
                  height: 'auto', // or any custom height
              },
              overlay: {
                  backgroundColor: 'none',
              }
          }} >
            <div className={styles.modal}  style={{color:styles.light}} >
                <p >Estas seguro de cambiar el estado a <span style={{fontSize:'15px', color:styles.verdeVistaguay, fontWeight:'bold'}}>{PLANTCOUNT_STAGE[modalIsOpen.newStatus]}</span>?</p>
                <div style={{display:'flex',justifyContent:'space-between',width:'100%',marginTop:'5px'}} >
                    <Button variant={'ERROR'} style={{height:'30px',fontSize:'13px'}} onClick={() => setModalIsOpen({isOpen:false, newStatus:0})} text='Cancelar' />
                    <Button variant={'SUCCESS'} style={{height:'30px',fontSize:'13px'}} onClick={() => {
                        updateStatus({taskId:String(plantCount.conteosPlantasId), status: String(modalIsOpen.newStatus)})
                        setModalIsOpen({isOpen:false, newStatus:0})
                    }} text='Aceptar' />
                </div>
            </div>
        </Modal>
      </div>
    </li>
  );
};
