import { useEffect, useRef, useState } from 'react';
import { API_URL } from 'services/configurations';
import { useAppSelector } from 'state/hooks';

import spinner from '../../../assets/img/vistaguay-spinner.gif';

interface IProps {
  linkUrl: string;
  style?: React.CSSProperties;
}

export const SecureImg = ({ linkUrl, style }: IProps) => {
  const accessToken = useAppSelector((state) => state.data.accessToken);
  const imagenRef = useRef<HTMLImageElement | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const downloadImage = async () => {
      if (
        accessToken &&
        imagenRef.current != null &&
        linkUrl &&
        // imagenRef.current.src === '' &&
        linkUrl !== imagenRef.current.src
      ) {
        try {
          const response = await fetch(API_URL + linkUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const blob = await response.blob();
          let objectUrl: string;
          if (imagenRef.current != null) {
            objectUrl = window.URL.createObjectURL(blob);
            imagenRef.current.src = objectUrl;
          }
          setLoaded(true);
          return () => {
            // console.log('clean::revoking object url from img' + linkUrl);
            window.URL.revokeObjectURL(objectUrl);
          };
        } catch (e) {
          console.error(e);
        }
      } else {
        return () => {
          console.log('empty');
        };
      }
    };

    const f = downloadImage();
    return;
  }, [accessToken, linkUrl]);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const handleImageError = () => {
    setLoaded(false);
  };

  return (
    <>
      {!loaded && (
        <div
          style={{
            width: '100%',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={spinner} style={{ width: '100px' }} />
        </div>
      )}

      <img
        style={{ ...style, display: loaded ? 'block' : 'none' }}
        ref={imagenRef}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </>
  );
};
