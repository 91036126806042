import {ReactComponent as UserIcon} from 'assets/icons/user-icon.svg'
import cx from 'classnames';
import { useState } from 'react';
import { API_URL } from 'services/configurations';

import styles from './Avatar.module.scss';
interface Props {
  name?: string;
  loading?: boolean;
  className?: string;
  usuarioId?: string
}

export default function Avatar({ usuarioId, name = 'V', loading, className }: Props) {
  const [profileImgError, setProfileImgError] = useState<boolean>(true)

  return (
    <div className={styles.avatar}>
      <UserIcon height={30}/>
    </div>
  );
  

}
