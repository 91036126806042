export enum TASK_TYPE {
  'mosaico',
  'conteo',
  'satelital',
  'mission',
  'analytics',
}

export class Task {
  id: number;
  nombre: string;
  type: TASK_TYPE;

  // TODO analizar si es necesario
  // lotId: number;
  // projectId: number;

  constructor(id: number, nombre: string, type: TASK_TYPE) {
    this.id = id;
    this.nombre = nombre;
    this.type = type;
  }

  // constructor(id: number, nombre: string, type: TASK_TYPE, lotId: number, projectId: number) {
  //   this.id = id;
  //   this.nombre = nombre;
  //   this.type = type;
  //   this.lotId = lotId;
  //   this.projectId = projectId;
  // }
}
