import { Icon } from '@blueprintjs/core';

import { typeDescription } from '../PhotoDescriptionSection';
import style from '../PhotoDescriptionSection.module.scss';

interface IPhotoDescriptionSection {
    typeDescription: { Min: string, Max: string };
    type: typeDescription;
    ComparationOperators: string;
}

export const HeaderModal = ({
    type,
    typeDescription,
    ComparationOperators,
}: IPhotoDescriptionSection) => {
    return (
        <div className={type === typeDescription.Min ? style.containerMin : style.constainerMax}>
            <div className={type === typeDescription.Min ? style.headerMin : style.headerMax}>
                <h1>
                    Planting Quality
                </h1>
                <h2>
                    Imagen de {type === typeDescription.Min ? 'MENOR' : 'MAYOR'} población de plantas
                </h2>
            </div>
            <div className={type === typeDescription.Min ? style.styleMin : style.styleMax}>
                <div className={type === typeDescription.Min ? style.styleMin : style.styleMax}>
                    <div className={`${style.squares} ${style[type === typeDescription.Min ? 'minus' : 'max']}`}>
                        {
                            type === typeDescription.Min?
                             <Icon icon={'small-minus'} size={20} color='white' />
                             :   <Icon icon={'small-plus'} size={20} color='white' />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
