import { Polygon } from '@turf/turf';
import { LayerType } from 'models/AnalyticsLayer';
import { AnalyticsTask } from 'models/AnalyticsTask';
import { GeoJSONFeature } from 'models/GeoJSON';
import { GeoJSONGeometry } from 'ol/format/GeoJSON';

import { VistaguayApi, VistaguayApiResponse } from './VistaguayAPI';

const enhanced = VistaguayApi.enhanceEndpoints({
    addTagTypes: ['Analytics']
})

interface AddGridBody {
    rows: number;
    ranges: number;
    width: number;
    height: number;
    drows: number;
    drange: number;
    rotation: number;
    centroid: any;
}

export interface CreateLayerInterface {
    analyticsId: number;
    name: string;
    type: LayerType;
    geography: GeoJSONGeometry;
}

export interface createAnalyticsBody {
    nombre: string;
    descripcion: string;
    campaignId: string;
    lotId: string | number;
}

const analyticsApi = enhanced.injectEndpoints({
    endpoints: (builder) => ({
        getAnalyticsByLotId: builder.query<AnalyticsTask[], {lotId: string | number}>({
            query: ({lotId}) => `analytics/lot/${lotId}`,
            providesTags: ['Analytics']
        }),
        getAllAnalytics: builder.query<AnalyticsTask[], void>({
            query: () => 'analytics',
            providesTags: ['Analytics']
        }),
        addGrid: builder.mutation<GeoJSONFeature<Polygon>,AddGridBody>({
            query: (body) => ({
                url: 'grid-generator',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['Analytics']
        }),
        createAnalytics: builder.mutation<VistaguayApiResponse<AnalyticsTask>, createAnalyticsBody>({
            query: (body) => ({
                url: 'analytics/create',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['Analytics']
        }),
        getAnalyticsById: builder.query<AnalyticsTask, number>({
            query: (id) => `analytics/${id}`,
            providesTags: ['Analytics']
        }),
        createLayer: builder.mutation<VistaguayApiResponse<AnalyticsTask>, CreateLayerInterface>({
            query: (body) => ({
                url: 'analytics/create-layer',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['Analytics']
        }),
        changeIntersectionMode: builder.mutation<void,{layerId:number, intersection:boolean}>({
            query: ({layerId,intersection}: {layerId:number, intersection:boolean}) => ({
                url:`${layerId}/${intersection}`,
                method:'GET',
            }),
            invalidatesTags: ['Analytics']
        })

    })
})

export const {
    useAddGridMutation,
    useGetAnalyticsByIdQuery,
    useGetAnalyticsByLotIdQuery,
    useCreateAnalyticsMutation,
    useCreateLayerMutation,
    useChangeIntersectionModeMutation,
} = analyticsApi