import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { CheckBox } from 'components/CheckBox/CheckBox';
import { Languages } from 'components/DataLists/DataLists';
import i18n from 'i18n';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import styles from '../ProfileModal.module.scss';

const customStyles = {
    input: (base: any) => ({
        ...base,
        padding: '7px', 
        '& input': {
          font: 'inherit', 
        },
    }),
    menu: (base: any) => ({
      ...base,
      maxHeight: '150px',
      overflowY: 'auto', 
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: '150px', 
    }),
};

const unitSystemOption = [
    {
        id: 1,
        name: 'metric',
    },
    {
        id: 2,
        name: 'imperial',
    }
]

const laguagesOptions = Languages.map((l)=> {
    return {
        value: l.code,
        label: (
            <div>
                <img src={l.iconPath} height={20} style={{ marginRight: '8px' }} />
                {l.label}
            </div>
        )
    }
})

const filterOption = (option: any, inputValue: string) => {
    return option.label.props.children[1].toLowerCase().includes(inputValue.toLowerCase());
};

export default function ProfileSettings () {
    const { t } = useTranslation();

    const [selectedUnit, setSelectedUnit] = useState(1) 
    const [selectedLanguage, setSelectedLanguage] = useState('es') 
    
    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <div className={styles.label}>
                    <span>{t('langugage')}</span>
                </div>
                
                <Select 
                    value={laguagesOptions.filter((opt) => opt.value === selectedLanguage)}
                    styles={customStyles}
                    options={laguagesOptions}
                    filterOption={filterOption}
                    onChange={(selectedOption) => { 
                        if(selectedOption?.value){
                            setSelectedLanguage(selectedOption.value);
                            i18n.changeLanguage(selectedOption.value);
                        }
                    }}
                />
            </div>

            <div className={styles.inputGroup}>
                <div className={styles.label}>
                    <span>{t('unit-sistem')}</span>
                </div>
                

                <div style={{ display: 'flex', gap: '20px' }}>
                    {unitSystemOption.map((unit) => {
                        return (
                        <CheckBox
                            key={unit.id}
                            label={unit.name}
                            checked={unit.id === 1}
                            name={unit.name}
                            id={unit.id + unit.name }
                            value={selectedUnit}
                            disabled={unit.id === 2}
                            onChange={(e) => {setSelectedUnit(+e.target.value)}}
                        />
                        );
                    })}
                </div>
            </div>
        </div>
    )
}