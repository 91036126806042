import { configureStore } from '@reduxjs/toolkit';
import { VistaguayApi } from 'api/Vistaguay/VistaguayAPI';
import { dataSlice } from 'state/slices/dataSlice';
import { mapSlice } from 'state/slices/mapSlice';
import { preferencesSlice } from 'state/slices/preferencesSlice';

import { analyticsSlice } from './slices/analyticsSlice';
import { campaignSlice } from './slices/campaignSlice';
import { extraDataSlice } from './slices/extraDataSlice';
import { mosaicSlice } from './slices/mosaicSlice';
import { pqFormSlice } from './slices/pqFormSlice';
import { prescriptionSlice } from './slices/prescriptionSlice';
import { taskSlice } from './slices/taskSlice';
import { uiSlice } from './slices/uiSlice';
import { userSlice } from './slices/userSlice';
import { volareSlice } from './slices/volareSlice';

const store = configureStore({
  reducer: {
    data: dataSlice.reducer,
    preferences: preferencesSlice.reducer,
    map: mapSlice.reducer,
    ui: uiSlice.reducer,
    prescription: prescriptionSlice.reducer,
    analytics: analyticsSlice.reducer,
    campaign: campaignSlice.reducer,
    volare: volareSlice.reducer,
    task: taskSlice.reducer,
    mosaic: mosaicSlice.reducer,
    user: userSlice.reducer,
    extradata: extraDataSlice.reducer,
    pqFormState: pqFormSlice.reducer,
    [VistaguayApi.reducerPath]: VistaguayApi.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(VistaguayApi.middleware),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
