import { useDeleteCampaignMutation } from 'api/Vistaguay/CampaignAPI';
import Button from 'components/Button/Button';
import { ErrorToaster, SuccesToaster } from 'components/Toaster/AppToaster';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../CampaignContextual.module.scss';

export default function TrashOption({ campaignId }: { campaignId: number }) {
  const { t } = useTranslation();
  
  const [confirm, setConfirm] = useState(false);

  const [
    deleteCampaign, 
    { 
      isLoading: isLoadingDeleteCampaign,
      isSuccess: isSuccessDeleteCampaign,
      isError: isErrorDeleteCampaign
    }
  ] = useDeleteCampaignMutation();

  useEffect(() => {
    if(isErrorDeleteCampaign) {ErrorToaster(t('error-deleting'))}
  }, [isErrorDeleteCampaign])

  useEffect(() => {
    if(isSuccessDeleteCampaign) {SuccesToaster(`${t('campaign')} ${t('success-deleted')}`)}
  }, [isSuccessDeleteCampaign])

  return (
    <div
      style={{ textAlign: 'center' }}
      className={style.body}
    >
      <h4 style={{ fontSize: '14px', fontWeight: '600' }}>
        {t('delete-campaign-title')}
      </h4>
      <p style={{ fontSize: '12px' }}>
        {t('delete-task-text')}
      </p>

      <div className={style.contextualControls}>
        <Button
          onClick={() => setConfirm(false)}
          text='Volver'
          variant={'NEUTRAL-GRAY'}
          style={{ fontSize: '12px' }}
          height='30px'
        />
        <Button
          onClick={() => deleteCampaign({campaignId})}
          text={t('errase')}
          variant={'ERROR'}
          style={{ fontSize: '12px' }}
          height='30px'
          isLoading={isLoadingDeleteCampaign}
          disabled={isLoadingDeleteCampaign}
        />
      </div>
              
    </div>
  );
}
