import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { PrescriptionPolygons } from 'components/Domain/Prescription/PrescriptionPolygons';
import MPoint from 'components/Map/Components/MPoint';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import MPolygon from 'components/Map/Components/Polygon/MPolygon';
import MapContext from 'components/Map/Context/MapContext';
import { Prescription } from 'models/Prescription';
import { PrescriptionModal2 } from 'pages/TaskList/PrescriptionModal2';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fitToCoords } from 'services/olUtils';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setDensidadesJson } from 'state/slices/pqFormSlice';
import { BLACK_LINE, LOT_COLOR } from 'styles/variables';


interface Point {
  name?: string,
  lat: any[];
  lng: any[];
}

export const AddCountMap = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const map = useContext(MapContext);

  const { projectId, lotId } = useParams<{ projectId: string; lotId: string }>();
  if (!projectId || !lotId) {
    return null;
  }
  const { data: lotData } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });

  const [showLot, setShowLot] = useState(true);

  const imgPoints: Array<Point> | undefined = useAppSelector((state) => state.map.imgPoints);
  const hoverImg: number | null = useAppSelector((state) => state.map.hoverImg);

  const {
    prescription,
    showPrescription,
    prescriptionEditable
  } = useAppSelector((state) => state.pqFormState)

  return (
    <>
      <MVectorLayer isVisible={showPrescription} zIndex={50}>
        {prescription && <PrescriptionPolygons prescription={prescription} />}
      </MVectorLayer>

      <MVectorLayer zIndex={60}>
        {imgPoints && 

          imgPoints.map((point, index) => {
            return <MPoint 
              key={index} 
              color={'#cf4941'} 
              zIndex={70} 
              hovered={index == hoverImg} 
              overlayContent={{title:'',content:<div style={{padding:'2px',width:'100px'}}>{point.name}</div>}} 
              onLoadCenter={true} 
              lat={point.lat[0]} 
              lng={point.lng[0]} 
              // marker={MarkerIcon}
            />;
        })}
      </MVectorLayer>

      <MVectorLayer isVisible={showLot} zIndex={100}>
        <MPolygon onLoadCenter={true} polygon={lotData?.polygon?.geometry} color={LOT_COLOR} stroke={BLACK_LINE} height={1}/>
      </MVectorLayer>

      { prescription && showPrescription &&
        <PrescriptionModal2 
          prescriptions={[prescription] as Prescription[]} 
          showPrescription={showPrescription}
          selectedPrescriptionId={prescription.id}
          prescriptionEditable={prescriptionEditable}
          handleDensityValues={(values) => dispatch(setDensidadesJson(values))}
        />
      }
    </>
  );
};