import { useGetPricesQuery } from 'api/Vistaguay/PricesAPI';
import { ReactComponent as AuditoryIcon } from 'assets/icons/360auditory.svg';
import { ReactComponent as CreditCalculatorIcon } from 'assets/icons/credit-calculator.svg';
import { ReactComponent as PlantCountIcon } from 'assets/icons/Cultivo-comodin.svg';
import { ReactComponent as CreditsIcon } from 'assets/icons/ico-credits-outline.svg';
import { ReactComponent as VipIcon } from 'assets/icons/ico-vip.svg';
import { ReactComponent as MalezasIcon } from 'assets/icons/malezaIcon.svg';
import { ReactComponent as MosaicIcon } from 'assets/icons/Mosaico-comodin.svg';
import { ReactComponent as PlusCircleIcon } from 'assets/icons/pluscricle-icon.svg';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './Credits.module.scss';
import { GoToProps } from './CreditTab';

interface Props {
  goTo: ({page, quantity, vpack}: GoToProps) => void;
}    

export default function BuyVpack({ 
  goTo,
}: Props) {
  const { t } = useTranslation();

  const { data: pricesData, isLoading: pricesLoading } = useGetPricesQuery();
  const {data , isLoading} = useGetPricesQuery()

  return (
    <div>
      {/* VP HEADER */}
      <div
        style={{
          display: 'flex', 
          alignItems: 'center',
          gap: '10px',
          borderBottom: '1px solid gray',
          paddingBottom: '10px'
        }}
      >
        <VipIcon />

        <div >
          <p 
            style={{ 
                fontSize: '12px',
                fontWeight: '500', 
                color: styles.c4, 
                textDecoration: 'line-through' 
            }}
          >u$s 
              {pricesData && pricesData?.vpackPrice}
          </p>

          <p 
            style={{ 
              fontSize: '12px',
              fontWeight: '500' 
            }}
          > 
            u$s {pricesData && pricesData?.vpackPrice - (pricesData?.vpackDiscount * pricesData?.vpackPrice / 100)}
          </p>
        </div>
      </div>

      {/* MOSAIC */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          paddingBottom: '10px',
          paddingTop: '10px',
          borderBottom: '1px solid gray',
        }}
      >
        <div 
          style={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: '5px' 
          }}
        >
          <MosaicIcon style={{ width: '30px', height: 'auto', fill: styles.darkdisabled }} />
          
          <div 
            style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 0 
            }}
          >
            <p
              style={{ 
                fontSize: '14px', 
                fontWeight: 500
              }}
            >
              {t('mosaic')}
            </p>
            <p 
              style={{ 
                fontSize: '9px', 
                fontWeight: 400 
              }}
            >
              u$s {data && data.mosaic} / mapa (maximo 1500 img)
            </p>
          </div>
        </div>

        <div 
          style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 0 
          }}
        >
          <p 
            style={{
              fontSize: '14px', 
              fontWeight: 600, 
              textAlign: 'center'
            }}
          >
            50
          </p>
          <p 
            style={{ 
              fontSize: '12px', 
              fontWeight: 400,  
              textAlign: 'left' 
            }}
          >
            Mapas
          </p>
        </div>
      </div>
      
      {/* MALEZAS */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          paddingBottom: '10px',
          paddingTop: '10px',
          borderBottom: '1px solid gray',
        }}
      >
        <div 
          style={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: '5px' 
          }}
        >
          <MalezasIcon style={{ width: '30px', height: 'auto', fill: styles.darkdisabled }} />
          
          <div 
            style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 0 
            }}
          >
            <p 
              style={{ 
                fontSize: '14px', 
                fontWeight: 500 
              }}
            >
              {t('plague-control')}
            </p>

            <p 
              style={{ 
                fontSize: '9px', 
                fontWeight: 400 
              }}
            >
              u$s {data && data.weeds} / ha (minimo 25 ha)
            </p>
          </div>
        </div>

        <div 
          style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 0 
          }}
        >
          <p 
            style={{
              fontSize: '14px', 
              fontWeight: 500, 
              textAlign: 'center'
            }}
          >
            500
          </p>

          <p 
            style={{ 
              fontSize: '12px', 
              fontWeight: 400,  
              textAlign: 'center' 
            }}
          >
            Ha
          </p>
        </div>
      </div>
      
      {/* PLANTING */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          paddingBottom: '10px',
          paddingTop: '10px',
          borderBottom: '1px solid gray',
        }}
      >
        <div 
          style={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: '5px' 
          }}
        >
          <PlantCountIcon 
            style={{ 
              width: '30px', 
              height: 'auto', 
              fill: styles.darkdisabled 
            }} 
          />
          
          <div 
            style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 0 
            }}
          >
            <p 
              style={{ 
                fontSize: '14px', 
                fontWeight: 500 
              }}
            >
              {t('planting-quality')}
            </p>

            <p 
              style={{ 
                fontSize: '9px', 
                fontWeight: 400 
              }}
            >
              u$s {data && data.planting_quality} / imagen 
            </p>
          </div>
        </div>

        <div 
          style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 0 
          }}
        >
          <p 
            style={{ 
              fontSize: '14px', 
              fontWeight: 500,
              textAlign: 'center' 
            }}
          >
            400
          </p>

          <p 
            style={{ 
              fontSize: '12px', 
              fontWeight: 500,
              textAlign: 'center' 
            }}
          >
            Muestras
          </p>
        </div>
      </div>

      {/* AUDIT */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          paddingBottom: '10px',
          paddingTop: '10px'
        }}
      >
        <div 
          style={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: '5px' 
          }}
        >
          <AuditoryIcon 
            style={{ 
              width: '30px', 
              height: 'auto', 
              fill: styles.darkdisabled 
            }} 
          />
          
          <div 
            style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 0 
            }}
          >
            <p 
              style={{ 
                fontSize: '14px', 
                fontWeight: 500 
              }}
            >
              {t('audit')} 360
            </p>

            <p 
              style={{ 
                fontSize: '9px', 
                fontWeight: 400 
              }}
            >
              u$s {data && data.auditory} / imagen
            </p>
          </div>
        </div>

        <div 
          style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 0 
          }}
        >
          <p 
            style={{ 
              fontSize: '14px', 
              fontWeight: 500,
              textAlign: 'center' 
            }}
          >
            20
          </p>

          <p 
            style={{ 
              fontSize: '12px', 
              fontWeight: 500,
              textAlign: 'center' 
            }}
          >
            {t('pictures')}
          </p>
        </div>
      </div>

      {/* CREDITS */}
      <div
        style={{
          padding: '10px 10px',
          display: 'flex',
          background: styles.verdeVistaguay,
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '8px',
          color: 'white'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <CreditsIcon    
            style={{ 
              width: '20px', 
              height: 'auto', 
              stroke: 'white' 
            }} 
          />

          <p style={{ fontSize: '14px', fontWeight: 500 }}>
            {t('credits')}
          </p>
        </div>

        <p style={{ fontSize: '14px'}}>
          {pricesData && pricesData.vpackPrice}
        </p>
      </div>

      {/* PAYMENT AMOUNT */}
      <div 
        style={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', 
          marginTop: '10px',
          marginBottom: '10px' 
        }}
      >
        <p 
          style={{ fontSize: '14px', fontWeight: 500 }}
        >
          {t('total-payment')}
        </p>

        <p style={{ fontSize: '14px'}}>
          u$s {pricesData && pricesData?.vpackPrice - (pricesData?.vpackDiscount * pricesData?.vpackPrice / 100)}
        </p>
      </div>

      {/* FOOTER BUTTONS */}
      <div 
        style={{ 
          paddingTop: '10px',
          justifyContent: 'space-between', 
          display: 'flex',
          borderTop: '1px solid gray', 
          gap: '80px'
        }}
      >
        <Button 
          height='30px'
          style={{fontSize: '12px'}}
          text={t('back')}
          variant='NEUTRAL-GRAY' 
          onClick={() => {goTo({page: 'home'})}} 
        />

        <Button 
          height='30px'
          style={{fontSize: '12px'}}
          text={t('purchase')}
          variant='SUCCESS' 
          onClick={() => {goTo({page: 'buy', quantity: 2550, vpack: true})}} 
        />
      </div>
    </div>
  );
}
