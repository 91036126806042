import { centerOfMass } from '@turf/turf';
import { useGetLotsQuery } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import MPoint from 'components/Map/Components/MPoint';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import AddLoteButtonOverlay from 'components/Map/MapOverlays/AddLotButtonOverlay';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';

function LotListMap() {
  const { projectId } = useParams<{ projectId: string }>();
  const hoveredLot = useAppSelector((state) : any => state.ui.hoveredLotId);
  const { data: lotsData, isLoading, error, isSuccess } = useGetLotsQuery(projectId as unknown as number);
  const { data: projectData } = useGetProjectQuery(projectId as unknown as number);


  return (
    <>
      {/* <AddLoteButtonOverlay /> */}
      {lotsData && projectData && (
        <MVectorLayer>
          {lotsData.map((lot) => {
            // get center point from polygon
            const point = centerOfMass(lot.polygon?.geometry);
            return (
              <MPoint
                key={lot.id}
                color={projectData?.color}
                lat={point.geometry.coordinates[1]}
                lng={point.geometry.coordinates[0]}
                hovered={hoveredLot === lot.id}
              />
            );
          })}
        </MVectorLayer>
      )}
    </>
  );
}

export default LotListMap;
