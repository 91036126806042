import { Popover2 } from '@blueprintjs/popover2';
import { ReactComponent as AddIcon } from 'assets/icons/ico-folder.svg';
import { ReactComponent as ColorPickerIcon } from 'assets/icons/ico-picker.svg';
import cx from 'classnames';
import ColorPicker from 'components/ColorPicker/ColorPicker';

import styles from './VistaguayColorPicker.module.scss';

const colors = ['#6E0034', '#C1272D', '#F15A24', '#F7931E', '#2B8E29', '#22B573'];

interface Props {
  color: string;
  onChange: (color: string) => void;
  width?: string;
  height?: string;
  disabled?: boolean
}

export const ColorPickerButton = ({ 
  onChange, 
  color,
  width,
  height 
}: Props) => {
  return (
    <Popover2
      interactionKind='click'
      usePortal={true}
      content={
        <ColorPicker
          color={color}
          onChange={(color) => {
            onChange(color);
          }}
        />
      }
    >
      <ColorPickerIcon
        className={styles.colorPickerIcon}
        style={{ 
          height: height ? height : '35px', 
          width: width ? width : '35px' 
        }}
      />
    </Popover2>
  );
};

export const ColorPickerButtonSquares = ({ onChange, color, disabled }: Props) => {
  const ColorVisualizer = () => {
    return <div style={{ width: '25px',  marginLeft:'auto', cursor: 'pointer',marginTop:'5px', marginBottom:'auto', marginRight:'auto', height: '25px', border: '2px solid #222222', borderRadius: '5px', backgroundColor: color }}/>
  }

  return (
    <>
      { disabled ? (
        <ColorVisualizer />
      ) : (
        <Popover2
          interactionKind='click'
          usePortal={true}
          content={
            <ColorPicker
              color={color}
              onChange={(color) => {
                onChange(color);
              }}
            />
          }
          >
            <ColorVisualizer />
          </Popover2>
      )}
    </>
    
  );
};


function VistaguayColorPicker({ onChange, color }: Props) {
  return (
    <div >
      <div className={styles.projectColorContainer}>
        { Array(5).fill(0).map((_, i) => (
          <AddIcon
            key={i}
            width={30}
            onClick={() => {
              onChange(colors[i]);
            }}
            className={cx(
              styles.folderPlusIcon,
              (colors[i] == color) ? styles.folderSelected : ''
            )}
          />
        ))}
        <ColorPickerButton color={color} onChange={onChange} />
      </div>
    </div>
  );
}

export default VistaguayColorPicker;
