import Tooltip from 'components/Tooltip/Tooltip';
import { useEffect } from 'react';
import { useAppSelector } from 'state/hooks';

import { ReactComponent as Arrow } from '../../../../assets/icons/arrow.svg';
import { typeDescription } from '../PhotoDescriptionSection';
import style from '../PhotoDescriptionSection.module.scss';
import { getPrescriptionAmbienteColor, getPrescriptionAmbienteColors } from '../PlotDataTable';
import { photoMetadata } from './GetPhoto';
import myCustomClassStyles from './myCustomClass.module.scss';
import { PrescriptionData } from './PrescriptionData';
import TooltipModal from './Tooltipmodal';

export const AmbienteSelector = ({ task, selectedAmbiente, setSelectedAmbiente, prescription, densidades, photosMetadata, type,densidadesidx }: any) => {
    const prescriptionTheme = useAppSelector((state) => {
        return state.ui.prescriptionTheme;
    });
    if (
        task.tipoProceso == 1 ||
        !prescription ||
        !prescription.prescription ||
        !prescription.prescription.features
    ) {
        return null
    }
    
  
    const data = PrescriptionData(prescription, densidadesidx, type);

 
    const photo = data.map((result: photoMetadata, idx: number) => result.fileName);
    const densidadesAmbientes = data.map((result: photoMetadata, idx: number) => result.densidad);

    const numericDensidades = densidadesAmbientes.filter((densidad: any) => typeof densidad === 'number' && !isNaN(densidad));
    const maxDensidad = numericDensidades.length > 0 ? Math.max(...numericDensidades) : 0;

    const percentages = densidadesAmbientes.map((densidad: any, idx: number) => densidad !== 0 ? ((densidad / maxDensidad) * 10) / 2 : 0.1);

    useEffect(() => { /* Start selecting Ambiente */
        if (selectedAmbiente === -1) { /* If selectedAmbiente === -1, it is "Siembra fija"; however, if selectedAmbiente !== -1, it is "Siembra variable" */

            const initialIndex = type === typeDescription.Min
                ? densidadesAmbientes.findIndex((densidad: number) => densidad === Math.min(...numericDensidades))
                : densidadesAmbientes.findIndex((densidad: number) => densidad === Math.max(...numericDensidades));
            setSelectedAmbiente(initialIndex);
        }
    }, [selectedAmbiente, type, densidadesAmbientes, numericDensidades, setSelectedAmbiente]);
    

    return (
        <div className={style.selector}>

            <h2 className={style.h2_selector}>Ambientes</h2>


            <div className={style.ambientesSelector}
                style={{
                    gap: prescription.prescription.features.length > 4 ? '2%' : '10%'
                }}>
                {prescription.prescription.features.map((feature: any, idx: number) => (

                    <div

                        onClick={() => setSelectedAmbiente(idx)}
                        key={`${feature.id}-${idx}`}
                    >
                        <TooltipModal 
                            ambiente={idx}
                            text={`${densidadesAmbientes[idx]} pl/ha`}
                            image={photo[idx].replace(/^\d+\//, '').replace('.JPG', '')}
                            className={`${myCustomClassStyles.tooltiptext}`}
                            customTextStyle={{}} >
                            <div className={`${style.densitySelector} ${selectedAmbiente === idx ? style.select : style.densitySelector}`}>
                                {densidadesAmbientes[idx] !== undefined && !isNaN(densidadesAmbientes[idx]) ? `${Math.round((densidadesAmbientes[idx]) / 1000)}K` : <p style={{ lineHeight: '1vw', fontSize: '0.7vw' }}>sin datos</p>}</div>
                            <div
                                style={{
                                    minHeight: '0.08vw',
                                    height: `${percentages[idx]}vw`,
                                    width: prescription.prescription.features.length <= 4 ? '3vw' : `calc(${3}- ${(idx - 4) * 1})vw`,
                                    minWidth: '1.5vw', 
                                    WebkitBorderTopLeftRadius: '0.3vw',
                                    WebkitBorderTopRightRadius: '0.3vw',
                                    gap: '3%',
                                    boxShadow: selectedAmbiente === idx ? '0px 4px 4px rgba(0, 0, 0, 0.5)' : 'none',
                                    
                                    backgroundColor: selectedAmbiente === idx ? getPrescriptionAmbienteColor(
                                        prescriptionTheme,
                                        idx,
                                        task,
                                        prescription
                                    ) :  getPrescriptionAmbienteColors(
                                        prescriptionTheme,
                                        idx,
                                        task,
                                        prescription
                                    ),

                                    color: 'white',
                                }}
                            />
                            <div>{idx}</div>
                        </TooltipModal>

                        <div className={`${style.arrow}`}>
                            {selectedAmbiente === idx ? <Arrow /> : null}
                        </div>
                        <div>{selectedAmbiente === idx}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
