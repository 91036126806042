import { title } from 'process';

import style from './SimpleModal.module.scss';

interface ISimpleModalProps {
  closeFn: () => void;
  children: React.ReactNode;
  photosMetadata: any;
  title?: string;
}

export const SimpleModal = ({ closeFn, children, photosMetadata, title }: ISimpleModalProps) => {
  return (
    <div
      className={style.modal}
      /* // onClick={() => {
      //   closeFn();
      // }} */
    >
      <div className={style.modalContent}>
        <p>{title}</p>
        <button
          className={style.close}
          onClick={() => {
            closeFn();
          }}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};
