// #region IMPORTS
// #region REACT
// #endregion
// #region Icons
import { Icon } from '@blueprintjs/core';
// #endregion
// #region COMPONENTS
import { Popover2 } from '@blueprintjs/popover2';
// #endregion
// #region API
import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import { useStartCountMutation } from 'api/Vistaguay/TaskAPI';
import { ReactComponent as CropIcon } from 'assets/icons/Cultivo-comodin.svg';
import { ReactComponent as EnsayoIcon } from 'assets/icons/ensayoIcon.svg';
import { ReactComponent as SatelliteIcon } from 'assets/icons/ico-auditoria.svg';
import { ReactComponent as Dots } from 'assets/icons/ico-dots.svg';
import { ReactComponent as InspectLayersIcon } from 'assets/icons/ico-inspect-layers.svg';
import { ReactComponent as MosaicIcon } from 'assets/icons/Mosaico-comodin.svg';
import ReorderIcon from 'components/Buttons/ReorderIcon/ReorderIcon';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
// #endregion
// #region STYLES
import { Reorder, useDragControls } from 'framer-motion';
// #endregion
// #region Models
import { PlantCountTask } from 'models/PlantCountTask';
import { TaskStatus } from 'models/TaskStatus';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
// #endregion
// #region State
import {
  IAllTasks,
  toggleLayersVisibility,
  updateVisualization,
  VisualizationPlantCountTask,
} from 'state/slices/taskSlice';
import { setHoveredLotId } from 'state/slices/uiSlice';

import PqContextual from './TaskListContextual/PqContextual/PqContextual';
import style from './TaskListItem.module.scss';
// #endregion
// #endregion

// #region ICONS
const StyledCropIcon = () => {
  return <CropIcon className={style.taskIcon} />;
};

const StyledSatelliteIcon = () => {
  return <SatelliteIcon className={style.taskIcon} />;
};

const StyledMosaicIcon = () => {
  return <MosaicIcon className={style.taskIcon} />;
};

const StyledAnalyticsIcon = () => {
  return <EnsayoIcon className={style.taskIcon} />;
};
// #endregion

interface TypeIconProps {
  type: string;
}

export const TypeIcon = ({ type }: TypeIconProps) => {
  switch (type) {
    case 'cultivo':
      return <StyledCropIcon />;
    case 'satelital':
      return <StyledSatelliteIcon />;
    case 'mapeo':
      return <StyledMosaicIcon />;
    case 'analytics':
      return <StyledAnalyticsIcon />;
    default:
      return <StyledCropIcon />;
  }
};

const cropStatus = (taskStatus: TaskStatus[]) => {
  const aux = taskStatus.filter((status: TaskStatus) => status.name.startsWith('DB_CONTEO_STATUS'));
  return aux.reduce((acc: any, curr: TaskStatus) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});
};

const transformStatus = (input: string): string => {
  const mapping: { [key: string]: string } = {
    DB_CONTEO_STATUS_NEW: 'nuevo',
    DB_CONTEO_STATUS_PROCESSING: 'procesando',
    DB_CONTEO_STATUS_PUBLISHED: 'publicado',
    DB_CONTEO_STATUS_TO_MANUAL: 'procesando',
    DB_CONTEO_STATUS_INTERNAL_ERROR: 'error',
    DB_CONTEO_STATUS_FROM_MANUAL: 'procesando',
    DB_CONTEO_STATUS_PROCESSING_FROM_MANUAL: 'procesando',
    DB_CONTEO_STATUS_INTERNAL_ERROR_FROM_MANUAL: 'error',
    DB_CONTEO_STATUS_FAILED: 'error',
    DB_CONTEO_STATUS_DONE: 'procesando',
  };

  return mapping[input] || input;
};

export const getStatus = (statusId: number) => {
  if (statusId == 2) {
    return 'Publicado';
  } else if (statusId == 9) {
    return 'Error';
  } else {
    return 'En proceso';
  }
};

interface taskItemProps {
  task: IAllTasks;
}

function CountTaskListItem({ task }: taskItemProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dragControls = useDragControls();
  const navigate = useNavigate();

  const visualization = task.visualization as VisualizationPlantCountTask;
  const taskData = task.task as PlantCountTask;
  const { projectId, lotId } = useParams<{
    projectId: string;
    lotId: string;
  }>();

  const { data: extraData } = useGetExtraDataQuery();
  const [startCount, { isLoading: isLoadingStartCount }] = useStartCountMutation();
  
  const [zoomLayers, setZoomLayers] = useState(false);

  const taskId = '' + task.taskId;

  if (!projectId || !lotId) {
    return null;
  }

  // #region HANDLERS
  const handleToggleAllTasks = (newTask: any) => {
    dispatch(updateVisualization(newTask));
    dispatch(toggleLayersVisibility(task.taskId));
  };

  const filteredCropStatus = cropStatus(extraData?.cropTaskStatus || []);

  const handleStartCount = () => {
    if (taskId) {
      startCount({ taskId });
    }
  };

  function getFlightDate() {
    if (typeof taskData.fechaVuelo === 'string') {
      return new Date(taskData.fechaVuelo).toLocaleDateString();
    }
    return taskData.fechaVuelo;
  }

  const handleToggle = (status: boolean) => {
    const newTask = {
      ...task,
      visualization: {
        ...visualization,
        visible: status,
      },
    };
    handleToggleAllTasks(newTask);
  };
  // #endregion

  return (
    <Reorder.Item
      className={style.taskItem}
      value={task}
      key={task as any}
      dragControls={dragControls}
    >
      <div className={style.taskinfo} >
        <div className={style.reorderIcon}>
          <ReorderIcon dragControls={dragControls} />
        </div>

        <TypeIcon type={String(task.type)} />

        <div className={style.taskInfoText}>
          <p>Planting Quality (#{taskData.conteosPlantasId})</p>
          <p onClick={handleStartCount} className={style.taskInfoSubtitle}>
            {`${t('flight')}: ${getFlightDate()} `}
          </p>
        </div>
      </div>

      <div className={style.taskItemStatus}>
        { getStatus(taskData.estadoId) === 'Publicado' && (
            <InspectLayersIcon 
              stroke={zoomLayers ? style.azul : style.gris} 
              style={{marginRight: '10px'}}
              onClick={() => {
                setZoomLayers((prevState) => !prevState)
              }}
            />
          )
        }

        { getStatus(taskData.estadoId) === 'Publicado' && (
          <SwitchButton 
            value={visualization.visible} 
            handleOnChange={(status) => handleToggle(status)} 
            switchWidth={34}
            switchHeight={18}
            showLayersIcon
          />
        )}

        { getStatus(taskData.estadoId) !== 'Publicado' && (
          <Popover2
            interactionKind='hover'
            usePortal={false}
            content={<span className={style.statusPopover}>{getStatus(taskData.estadoId)}</span>}
            position='top'
          >
            <StatusIcon status={getStatus(taskData.estadoId)} />
          </Popover2>
        )}
      </div>

      <Popover2
        position='right'
        interactionKind='click'
        usePortal={true}
        content={<PqContextual task={task} projectId={projectId} extradata={extraData} />}
        className={style.popover}
      >
        <div className={style.dotwrap}>
          <Dots className={style.icon}/>
        </div>
      </Popover2>
    </Reorder.Item>
  );
}

// #region STATUS ICON - COMPONENT
export const StatusIcon = ({ status }: { status: string }) => {
  const size = 25;
  const styleIcon = {
    display: 'flex',
    alignItems: 'center',
  };
  switch (status) {
    case 'En proceso':
      return <Icon icon='history' size={size} style={styleIcon} />;
    case 'Publicado':
      return null;
    case 'Error':
      return <Icon icon='cross-circle' size={size} style={styleIcon} />;
    default:
      return <Icon icon='history' size={size} style={styleIcon} />;
  }
};
// #endregion

export default CountTaskListItem;
