import MMeasureTool from './Components/Measure/Measures';
import styles from './Components/Measure/MMeasure.module.scss'
import MGeolocateTool from './Components/MGeolocateTool';
import BaseMpp from './SwitcherButton';

export const ButtonsMap = () => {
    return(
        <div className={styles.toolTip}> 
            <MGeolocateTool/>
            <MMeasureTool/>        
        </div>
    );
}
