import { polygon } from '@turf/turf';
import { useGetPrescriptionsQuery } from 'api/Vistaguay/LotAPI';
import { ReactComponent as BatteryIcon } from 'assets/icons/BatteryIconGreen.svg'
import { ReactComponent as HaIcon } from 'assets/icons/HaIconGreen.svg'
import { ReactComponent as ImageIcon } from 'assets/icons/ico-image.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/TimeIconGreen.svg'
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import Lot from 'models/Lot';
import { MappingMission, ScoutingMission } from 'models/Mission';
import { Prescription } from 'models/Prescription';
import {useEffect,useState} from 'react'
import { useTranslation } from 'react-i18next';
import { getFlightPlan } from 'services/mappingMissionGenerator';
import { getScoutingPlan , IScoutingPlan, PlanOptions} from 'services/scoutingMissionGenerator';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { IAllTasks, updateVisualization,VisualizationMission } from 'state/slices/taskSlice';
import { IFlightPlanInfo } from 'state/slices/volareSlice';

import styles from '../../PqContextual/Contextual.module.scss'
interface ContextualOptionMissionProps {
    lot?: Lot
    lotId: number,
    projectId: number,
    taskId: string
}

export default function LayersOption ({lot, lotId, projectId, taskId} : ContextualOptionMissionProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const [flightInfo, setFlightInfo] = useState<any>()
    const [prescriptionMission, setPrescriptionMission] = useState<Prescription | undefined>()

    // its necesary to get the task from the store because its properties are updated across the app
    const allTasks = useAppSelector((state) => state.task.tasks);
    const task: IAllTasks | undefined = allTasks.find((t) => t.taskId == taskId);

    if(!task) {return <></>}

    const taskData = task.task as ScoutingMission | MappingMission
    const visualization = task.visualization as VisualizationMission;

    const { 
        data: prescriptionsData 
    } = useGetPrescriptionsQuery({lotId: lotId, projectId: projectId});
    

    useEffect(() => {
        const vertex = taskData.polygon_vertices.map((value, index,array) => {
            return [value.lat,value.lng]
        })
        
        const poly = polygon([[...vertex]])
        const points = [...vertex]

        let planOptions; 
        let info;

        if(taskData.type === 'MAPPING'){
            const data = taskData as MappingMission;
            planOptions = {
                flyHeight: (Number(data.altitude) / 1000),
                speed: data.speed,
                direction: data.flight_direction,
                camera: data.camera_name || 'default',
                /* eslint-disable camelcase */
                ov_frontal: data.frontal_overlap,
                ov_lateral: data.lateral_overlap,
            };

            info = getFlightPlan(poly.geometry, planOptions);
        } else {
            planOptions = {
                speed: taskData.speed,
                direction: String(taskData.flight_direction),
                // TODO: devolver el conteo de fotos     
            }

            info = getScoutingPlan(points, planOptions)
        }

        setFlightInfo(info)
    },[task,lot])

    useEffect(() => {
        console.log('pres data scouting', prescriptionsData)
        if(prescriptionsData && taskData.type == 'SCOUTING') {
            const prescriptionMission: Prescription | undefined = prescriptionsData.find(
                (p) => p.id == (taskData as ScoutingMission).prescriptionId
            );
            console.log('prescriptionMission', prescriptionMission)
            setPrescriptionMission(prescriptionMission);
        }
    }, [])
    
    return (
        <div className={styles.body}>
            <div 
                className={styles.contextualButton} 
                style={{
                    borderBottom:`1px solid ${styles.verdeVistaguay}`, 
                    marginBottom: '5px'
                }} 
            >
                <div className={styles.flightPlanInfo}>
                    <div >
                        <HaIcon height={25} />
                        <p style={{marginTop: '3px'}}>
                            { (taskData.type === 'MAPPING') 
                                ? flightInfo?.area 
                                : flightInfo?.planInfo?.area
                            }
                        </p>
                    </div>
                    <div>
                        <TimeIcon height={25} />
                        <p style={{marginTop: '3px'}}>
                            { (taskData.type === 'MAPPING') 
                                ?flightInfo?.minutes
                                :flightInfo?.planInfo?.minutes
                            }
                        </p>
                    </div>
                    <div>
                        <ImageIcon height={25} stroke={styles.verdeVistaguay}/>
                        <p style={{marginTop: '3px'}}>
                            { (taskData.type === 'MAPPING')
                                ? flightInfo?.photos
                                : flightInfo?.planInfo?.photos
                            }
                        </p>
                    </div>
                    <div>
                        <BatteryIcon height={25} />
                        <p style={{marginTop: '3px'}}>
                            { (taskData.type === 'MAPPING')
                                ? flightInfo?.bateries
                                : flightInfo?.planInfo?.bateries
                            }
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.contextualButton}>
                <p>{t('flight-plan')}</p>
                <div className={styles.actions}>
                    <SwitchButton
                        value={visualization.showFlightPlan} 
                        handleOnChange={(status) => {
                            const newTask = {...task, visualization: {...visualization, showFlightPlan: status}};
                            dispatch(updateVisualization(newTask))
                        }} 
                    />
                </div>
            </div>

            <hr style={{margin: '5px 0 5px 0'}}/>

            {   prescriptionMission &&
                <>
                    <div className={styles.contextualButton}>
                        <p>{t('prescription')}</p>
                        <div className={styles.actions}>
                            <SwitchButton
                                value={visualization.showPrescription} 
                                handleOnChange={(status) => {
                                    const newTask = {...task, visualization: {...visualization,  showPrescription: status}};
                                    dispatch(updateVisualization(newTask))
                                }} 
                            />
                        </div>
                    </div>

                    <hr style={{margin: '5px 0 5px 0'}}/>
                </>
            }
        </div>
    )
}