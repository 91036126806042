import {
  Button,
  ButtonGroup,
  HandleType,
  MultiSlider,
  NumberRange,
  RangeSlider,
} from '@blueprintjs/core';
import { useGetAlgorithmStyleQuery, useGetWmsQuery,useSaveAlgorithmStyleMutation,WmsObject } from 'api/Vistaguay/MosaicAPI';
import AnimatedFetchButton from 'components/Button/AnimatedFetchButton';
import VistaguayButton from 'components/Button/Button';
import { Histogram } from 'components/Histogram/Histogram';
import Select from 'components/Select/Select';
import * as d3 from 'd3';
import { COLOR_RAMP, HistogramStyle } from 'models/Algorithm';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { MosaicTaskView, setHistogramData } from 'state/slices/mosaicSlice';

import style from '../Contextual.module.scss';
import styleHistogram from './Histogram.module.scss';



const defaultColorRamps = ['RdYlGn', 'Blues', 'Spectral', 'Grays'];
export const getColorInterpolators = (colorRamps: string) => {
  switch (colorRamps) {
    case 'RdYlGn':
      return d3.interpolateRdYlGn;
    case 'Blues':
      return d3.interpolateBlues;
    case 'Spectral':
      return d3.interpolateSpectral;
    case 'Grays':
      return d3.interpolateGreys;
    default:
      return d3.interpolateRdYlGn;
  }
};

const interpolatedValue = (value: number, min: number, max: number) => {
  const ret = Math.abs((1 / (min - max)) * (value - min));
  if (value < min) {
    return 0;
  }
  if (value > max) {
    return 1;
  }
  return ret;
};

const interpolateValue = (value: number, min: number, max: number) => {
  // return d3.interpolateNumber(min, max)(value); // error
  return interpolatedValue(value, min, max);
};

export const getHexaColorFromRgb = (rgb: string) => {
  const d3COlor = d3.color(rgb);
  return d3COlor?.formatHex();
};

interface HistogramOptionProps {
  mosaicView: MosaicTaskView;
}
export default function HistogramOptionStepOne({ mosaicView }: HistogramOptionProps) {
  const dispatch = useAppDispatch();

  const { data: wmsData } = useGetWmsQuery({ taskId: mosaicView.id }, { skip: !mosaicView.id });

  const [layer, setLayer] = useState('');
  const [render, setRender] = useState(''); // not used
  const [clipLote, setClipLote] = useState(false);

  const layersName = mosaicView.layers.map((layer) => {
    return layer.layerName;
  });

  const [showHistogram, setShowHistogram] = useState(false);

  const [selectedBand, setSelectedBand] = useState(0);

  const getRenderizadoOption = (layer: string) => {
    const options = [];

    if (layer === 'mosaico') {
      options.push({
        value: '0',
        label: 'Red',
      });
      options.push({
        value: '1',
        label: 'Green',
      });
      options.push({
        value: '2',
        label: 'Blue',
      });
    } else {
      options.push({
        value: '0',
        label: 'Monobanda',
      });
    }
    return options;
  };

  const handleClipLot = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value === 'lot' ? setClipLote(true) : setClipLote(false);
  };

  const handdleShowHistogram = () => {
    dispatch(
      setHistogramData({
        layer: layer,
        render: render,
        clipLote: clipLote,
        id: mosaicView.id,
        min: -1,
        max: 1,
        steps: 2,
        stepValues: [-1, 1],
        colorRamp: defaultColorRamps[0],
        grouped: true,
        cutValues: false,
      }),
    );
    setShowHistogram(true);
  };

  const handleRenderCHange = (value: number) => {
    setSelectedBand(value);
  };

  return (
    <>
      {!showHistogram && (
        <div className={style.body}>
          <div className={style.histogramMenu}>
            <h3>Histograma</h3>
            <label htmlFor=''>Capa</label>
            <Select
              options={layersName.map((layer) => {
                return {
                  value: layer,
                  label: layer,
                };
              })}
              value={layer}
              onChange={(value) => setLayer(value)}
              className={'no-drag'}
            />
            <label style={{ marginTop: '10px' }} htmlFor=''>
              Renderizado
            </label>
            <Select
              className={'no-drag'}
              options={getRenderizadoOption(layer)}
              value={selectedBand}
              onChange={(value: string) => handleRenderCHange(+value)}
              disabled={layer === ''}
            />

            <label style={{ marginTop: '10px' }} htmlFor=''>
              Area de Interes
            </label>
            <div className='interestAreaInput'>
              <input
                onChange={handleClipLot}
                type='radio'
                id='interestAreaAll'
                name='interestArea'
                value='all'
                defaultChecked={!clipLote}
              />
              <label htmlFor='interestAreaAll' style={{ marginRight: '20px' }}>
                Lote
              </label>
              <input
                onChange={handleClipLot}
                type='radio'
                id='interestAreaLot'
                name='interestArea'
                value='lot'
                defaultChecked={clipLote}
              />
              <label htmlFor='interestAreaLot'>Completo</label>
            </div>
            <VistaguayButton
              text='Siguiente'
              variant='INFO'
              style={{ marginTop: '10px', backgroundColor: 'white' }}
              disabled={layer === ''}
              onClick={() => {
                handdleShowHistogram();
              }}
            />
          </div>
        </div>
      )}
      {showHistogram && wmsData && (
        <>
          <HistogramOptionStepTwo
            wmsData={wmsData}
            layer={layer}
            render={render}
            mosaicViewId={mosaicView.id}
            selectedBand={selectedBand}
          />
          {/* <Button
            text='Atras'
            variant='NEUTRAL'
            style={{ marginTop: '30px' }}
            className={styleHistogram.backButton}
            onClick={() => {
              setShowHistogram(false);
            }}
          /> */}
        </>
      )}
    </>
  );
}

interface HistogramProps {
  layer?: string;
  render?: string;
  wmsData: WmsObject;
  mosaicViewId: number;
  selectedBand?: number;
}

const HistogramOptionStepTwo = ({
  wmsData,
  layer,
  render,
  mosaicViewId,
  selectedBand = 0,
}: HistogramProps) => {
  const [min, setMin] = useState(-1);
  const [max, setMax] = useState(1);
  const [zones, setZones] = useState(2);
  const [grouped, setGrouped] = useState(false);
  const [cutValues, setCutValues] = useState(false);
  const [colorRamp, setColorRamp] = useState(defaultColorRamps[0]);
  const [stepValues, setStepValues] = useState<number[]>([]);
  const [saveStyle, {isLoading,isError,isSuccess}] = useSaveAlgorithmStyleMutation()
  const dispatch = useAppDispatch();
  
  if (!layer) {
    return <></>;
  }
  const {data: algoStyle, isLoading: styleLoading, isError: styleError, isSuccess: styleSuccess} = useGetAlgorithmStyleQuery(
    {
      mosaicId: mosaicViewId,
      algoName: layer,
    }
  )

  useEffect(() => {
    if(layer == 'ndwig') {
      setColorRamp('Blues');
    }
    const step = zones - 1;
    const values = [min];
    for (let i = 0; i < step; i++) {
      values.push(((max - min) / zones) * (i + 1) + min);
    }
    values.push(max);
    setStepValues(values);
  }, [min, max, zones]);

  useEffect(() => {
    dispatch(
      setHistogramData({
        id: mosaicViewId,
        layer: layer,
        render: render || '',
        clipLote: false,
        min: min,
        max: max,
        steps: zones,
        stepValues: stepValues,
        grouped: grouped,
        colorRamp: colorRamp,
        cutValues: cutValues,
      }),
    );
  }, [layer, render, min, max, zones, grouped, colorRamp, cutValues, stepValues]);

  useEffect(() => {
    if(algoStyle) {
      console.log(algoStyle)
      dispatch(
        setHistogramData({
          id: mosaicViewId,
          layer: layer,
          render: render || '',
          clipLote: false,
          min: algoStyle.min ? algoStyle.min : min,
          max: algoStyle.max ? algoStyle.max : max,
          steps: algoStyle.steps ? algoStyle.steps : zones,
          stepValues: algoStyle.stepValues ? algoStyle.stepValues : stepValues,
          grouped: grouped,
          colorRamp: algoStyle.colorRamp ? algoStyle.colorRamp : colorRamp,
          cutValues: cutValues
        })
      )
      setMin(algoStyle.min ? algoStyle.min : min)
      setMax(algoStyle.max ? algoStyle.max : max)
      setStepValues(algoStyle.stepValues ? algoStyle.stepValues : stepValues)
      setZones(algoStyle.steps ? algoStyle.steps : zones)
      setColorRamp(algoStyle.colorRamp ? algoStyle.colorRamp : colorRamp)
    }
  },[styleLoading,styleSuccess,algoStyle])

  useEffect(() => {
    const wmsInfo = wmsData[layer];
    const gdalInfo = wmsInfo.gdalInfo;
    const band = gdalInfo?.bands[selectedBand]; // FIXME
    const min = band?.histogram.min || 0;
    const max = band?.histogram.max || 1;
    setMin(min);
    setMax(max);
  }, [wmsData, layer]);

  let globalMin = -2000;
  let globalMax = 2000;

  const wmsInfo = wmsData[layer];
  const gdalInfo = wmsInfo.gdalInfo;

  globalMin = gdalInfo.bands[selectedBand].histogram.min;
  globalMax = gdalInfo.bands[selectedBand].histogram.max;

  const bins = gdalInfo.bands[selectedBand].histogram.buckets.length;
  const delta = (max - min) / bins;

  const pixelSize = Math.abs(gdalInfo ? gdalInfo.geoTransform[1] * gdalInfo.geoTransform[5] : 1);
  const totalArea =
    gdalInfo.bands[selectedBand].histogram.buckets.reduce((a, b) => a + b, 0) * pixelSize;

  const handleValueChange = (range: NumberRange) => {
    const [min, max] = range;
    setMin(min);
    setMax(max);
  };

  const handleChangeZone = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setZones(+event.target.value);
  };

  const handleGroupedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGrouped(event.target.value === 'yes' ? true : false);
  };

  const handleCutValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCutValues(event.target.value === 'yes' ? true : false);
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setColorRamp(event.target.value);
  };

  const handleStepChange = (value: number, index: number) => {
    setStepValues(
      stepValues.map((oldValue, i) => {
        if (i === 0 || i === stepValues.length - 1 || i !== index) {
          return oldValue;
        }
        return value;
      }),
    );
  };

  const mosaics = useAppSelector((state) => state.mosaic.mosaics);
  const mosaicView = mosaics.find((m) => m.id === mosaicViewId);
  const histogramView = mosaicView?.histogramView


  const handleSaveStyle = () => {
    console.log(histogramView)
    if(histogramView == null) return

    const histogramStyle: HistogramStyle = {
      min: histogramView?.min ? histogramView.min : -1,
      max: histogramView?.max ? histogramView.max :  1,
      colorRamp: histogramView?.colorRamp ? histogramView.colorRamp as COLOR_RAMP : COLOR_RAMP.RdYIGn,
      steps: histogramView?.steps ? histogramView.steps : 0,
      stepValues: histogramView?.stepValues ? histogramView.stepValues : [],
    }

    saveStyle({
      mosaicId: mosaicViewId,
      algoName: layer,
      body: histogramStyle
    })
    
  }



  const barStyle = (value: number, index: number, grouped: boolean) => {
    const interpolatorColor = getColorInterpolators(colorRamp);
    if (index > 0) {
      if (!grouped) {
        const initialColor = interpolatorColor(interpolateValue(stepValues[index - 1], min, max));
        const lastColor = interpolatorColor(interpolateValue(stepValues[index], min, max));
        return { backgroundImage: `linear-gradient(to right, ${initialColor}, ${lastColor})` };
      } else {
        const color = interpolatorColor(
          interpolateValue((stepValues[index] + stepValues[index - 1]) / 2, min, max),
        );
        return { backgroundColor: color };
      }
    }
    return {};
  };



  return (
    <>
      {/* <label style={{ marginTop: '10px' }} htmlFor=''>
        Zonas
      </label> */}
      <div className={styleHistogram.zoneOptions}>
        <span className={styleHistogram.zoneLabel}>Cantidad de zonas</span>
        <ButtonGroup className={`${styleHistogram.zoneButtonGroup} no-drag`}>
          {[...Array(6)].map((_, i) => {
            const zone = i + 2;
            return (
              <Button
                key={zone}
                onClick={() => setZones(zone)}
                className={styleHistogram.zoneButton}
                active={zone === zones}
              >
                {zone}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>

      <div className='groupInput'>
        <span style={{ marginTop: '10px', marginRight: '20px' }}>Agrupar</span>
        <input
          onChange={handleGroupedChange}
          type='radio'
          id='groupedYes'
          name='groupedArea'
          value='yes'
          defaultChecked={grouped}
        />
        <label htmlFor='groupedYes' style={{ marginRight: '20px' }}>
          Si
        </label>
        <input
          onChange={handleGroupedChange}
          type='radio'
          id='groupedNo'
          name='groupedArea'
          value='no'
          defaultChecked={!grouped}
        />
        <label htmlFor='groupedNo'>No</label>
      </div>

      <div className='groupInput'>
        <span style={{ marginTop: '10px', marginRight: '20px' }}>Cortar valores</span>
        <input
          onChange={handleCutValues}
          type='radio'
          id='cutYes'
          name='cutValues'
          value='yes'
          defaultChecked={cutValues}
        />
        <label htmlFor='cutYes' style={{ marginRight: '20px' }}>
          Si
        </label>
        <input
          onChange={handleCutValues}
          type='radio'
          id='cutNo'
          name='cutValues'
          value='no'
          defaultChecked={!cutValues}
        />
        <label htmlFor='cutNo'>No</label>
      </div>

      <div>
        <label style={{ marginTop: '10px', marginRight: '20px' }} htmlFor=''>
          Color
        </label>
        <select className={`${styleHistogram.select} no-drag`} onChange={handleColorChange}>
          {defaultColorRamps.map((cr) => (
            <option key={cr} value={cr} selected={cr === colorRamp}>
              {cr}
            </option>
          ))}
        </select>
      </div>
      <Histogram
        gdalInfo={gdalInfo}
        selectedMin={min}
        selectedMax={max}
        colorRamp={colorRamp}
        grouped={grouped}
        steps={stepValues}
        selectedBand={selectedBand}
      />
      <MultiSlider
        className={`${styleHistogram.slider} no-drag`}
        min={globalMin}
        max={globalMax}
        labelValues={[0]}
        stepSize={0.01}
      >
        {stepValues.map((value, index) => (
          <MultiSlider.Handle
            key={value}
            value={value}
            type={HandleType.FULL}
            trackStyleBefore={barStyle(value, index, grouped)}
            // trackStyleBefore={{ backgroundImage: 'linear-gradient(to right, red, yellow)' }}
            onChange={(newValue) => handleStepChange(newValue, index)}
          />
        ))}
      </MultiSlider>
      <RangeSlider
        className={`${styleHistogram.slider} no-drag`}
        min={globalMin}
        max={globalMax}
        labelRenderer={false}
        onChange={handleValueChange}
        stepSize={0.01}
        value={[min, max]}
        handleHtmlProps={{
          start: { 'aria-label': 'example start' },
          end: { 'aria-label': 'example end' },
        }}
      />
      <div style={{margin:'20px',display:'flex',gap:'10px'}}>
        <AnimatedFetchButton onClick={() => handleSaveStyle()} isLoading={isLoading} isError={isError} isSuccess={isSuccess} >
          {
            isLoading && <>
              Guardando...
            </>
          }
          {
            isError && <>
              Reintentar
            </>
          }
          {
            isSuccess && <>
              Guardado!
            </>
          }
          {
            !isLoading && !isSuccess && !isError && <>
              Guardar estilo
            </>
          }
        </AnimatedFetchButton>
        <AnimatedFetchButton >
          <>
          Resetear estilo
          </>
        </AnimatedFetchButton>
      </div>
      {
        /*
        <div className={styleHistogram.zoneTable}>
          {stepValues.map((value, index) => {
            if (index === 0) {
              return null;
            }
            const xStart = stepValues[index - 1];
            const xEnd = stepValues[index];

            let pixelCount = 0;
            for (
              let dataIter = Math.floor((xStart - min) / delta);
              dataIter < (xEnd - min) / delta;
              dataIter++
            ) {
              pixelCount += gdalInfo?.bands[selectedBand].histogram.buckets[dataIter];
            }
            const supArea = pixelCount * pixelSize;
            return (
              <>
                <div className={styleHistogram.zoneContent}>
                  <div className={styleHistogram.zoneHeader}>Zona {index}</div>
                  <div className={styleHistogram.zoneSup}>{(supArea / 100000).toFixed(1)} ha</div>
                  <div className={styleHistogram.zoneSup}>
                    {((supArea * 100) / totalArea).toFixed(1)} %
                  </div>
                </div>
              </>
            );
          })}
        </div>
        */
      }
    </>
  );
};
