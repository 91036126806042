
import { ReactComponent as CalendarIcon } from 'assets/icons/ico-calendar-white.svg';
import clsx from 'classnames';
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';

import styles from './Input.module.scss';


interface inputProp {
  value?: number | string | undefined
  name?: string
  id?: string
  type?: string
  placeholder: string;
  icon?: React.ReactNode;
  sx?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  onBlur?: () => void
  onFocus?: () => void
  className?: string;
  maxLenght?: number;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void | undefined
  defaultValue?: number | string | undefined
  disabled?: boolean
  children?: React.ReactNode
  list?: string
  inputStyle?: string
}


export function Input({
  value,
  name,
  id,
  type,
  placeholder,
  sx,
  className,
  inputStyle,
  icon,
  onChange,
  onBlur,
  maxLenght,
  onKeyPress,
  defaultValue,
  disabled,
  children,
  onFocus,
  list
}: inputProp) {
  return (
    <>
      <div 
        style={sx} 
        className={className ? className : styles.inputwrapper}
      >
        <input
          list={list}
          value={value}
          name={name}
          id={id}
          type={type}
          placeholder={placeholder}
          className={inputStyle ? inputStyle : styles.input}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLenght}
          onKeyDown={onKeyPress}
          defaultValue={defaultValue}
          disabled={disabled}
          onFocus={onFocus}
          // autoComplete='off'
        />
        {icon ? <span className={styles.icon}>{icon}</span> : <></>}
        {children}
      </div>
    </>
  );
}
interface selectProp {
  value?: number | string
  name?: string
  id?: string
  type?: string
  placeholder: string;
  sx?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void | undefined;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void | undefined
  className?: string;
  options?: Array<{ value: number | string; name: string; icon?: string }>;
  children?: React.ReactNode;
  disabled?: boolean
  defaultValue?: number | string | undefined;
  hasIcon?: boolean;
}

export function InputSelect({
  value = undefined,
  name,
  id,
  placeholder,
  sx,
  className,
  onChange,
  onBlur,
  options,
  children,
  disabled,
  defaultValue,
  hasIcon
}: selectProp) {
  const [selectedOption, setSelectedOption] = useState(options?.filter(opt => opt.value === value)[0])

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e)
    }
    setTimeout(() => e.target.blur(), 100)

  }

  return (
    <>
      <div 
        style={sx} 
        className={clsx(
          styles.inputselectwrapper, 
          className,
          disabled ? styles.disabled : ''
        )}
      >
        <select
          value={value}
          name={name}
          onFocus={(e) => e.target.size}
          // placeholder={placeholder}
          className={styles.inputselect}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {children}
        
          {options?.map((option, i) => {
            return (
              <option key={i} disabled={disabled} id={option.name} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </select>

        {selectedOption && selectedOption.icon && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <img src={selectedOption.icon} alt={selectedOption.name} style={{ marginRight: '8px' }} />
            <span>{selectedOption.name}</span>
          </div>
        )}
      </div>
    </>
  );
}

interface textAreaProps {
  placeholder: string;
  sx?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void | undefined;
  value?: string | number | undefined;
  id?: string
  name?: string
}

export function TextArea({ placeholder, name, id, sx, onChange, value }: textAreaProps) {


  return (
    <textarea
      style={sx}
      onChange={onChange}
      value={value}
      name={name}
      id={id}
      className={styles.textarea}
      placeholder={placeholder}
    ></textarea>
  );
}


interface DatePickerProps {
  label: string;
  sx?: React.CSSProperties;
  onChange?: (newDate: Date) => void | undefined;
  value?: string | number | Date;
  datepickerInputStyle?: string;
}

export function DatePicker({ label, sx, onChange, value, datepickerInputStyle }: DatePickerProps) {
  const parsedValue = value ? new Date(value).toISOString().split('T')[0] : undefined;
  return (
    <div className={styles.datepickerwrapper} >
      <span className={styles.datepickerlabel}>{label}</span>
      <input
        style={sx}
        onChange={(e) => {
          if (onChange) {
            onChange(new Date(e.target.value));
          }
        }
        }
        placeholder='dd/mm/aaaa'
        value={parsedValue}
        className={clsx(datepickerInputStyle, styles.datepicker)}
        type='date'
      />
    </div>
  );
}


export enum InputRangeVariant {
  VOLARE = 'Volare',
  DEFAULT = 'Default',
}

interface IInputRangeProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: InputRangeVariant
}

export function InputRange ({variant = InputRangeVariant.DEFAULT ,...props}: IInputRangeProps) {
  return (
    <input className={variant == InputRangeVariant.DEFAULT ? styles.inputRange : styles.volare } type='range' {...props} />
  )
}