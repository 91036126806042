// #region REACT
import { useGetCreditQuery } from 'api/Vistaguay/CreditAPI';
import { buildAddCountTaskFormData } from 'api/Vistaguay/TaskAPI';
// #endregion
// #region SERVICES
import axios, { CancelTokenSource } from 'axios';
import { ErrorToaster } from 'components/Toaster/AppToaster';
// #endregion
// # region MODELS
import { PlantCountTask } from 'models/PlantCountTask';
import { Prescription } from 'models/Prescription';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from 'services/configurations';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { clearImgPoints } from 'state/slices/mapSlice';
import { setShowPrescriptionTable } from 'state/slices/uiSlice';

// #endregion
// #region STYLES
import style from '../../AddTask.module.scss';
// #endregion

interface StepProps {
  files: File[];
  projectId: string;
  lotId: string;
  selectedCampaignId?: number;
  isLoadingAddTask: boolean;
  isSuccessAddTask: boolean;
  handleIsLoadingAddTask: (status: boolean) => void;
  handleIsSuccessAddTask: (status: boolean) => void;
  handleError: (error: boolean) => void;
  handleShowCancelModal:  (error: boolean) => void;
}

interface ISendTask {
  countTask: Partial<PlantCountTask>;
  campaignId: number;
  lotId: string;
  projectId: string;
  prescription: Prescription | null;
}

export default function StepFinish ({
  projectId,
  lotId,
  selectedCampaignId,
  files,
  isLoadingAddTask,
  isSuccessAddTask,
  handleIsLoadingAddTask,
  handleIsSuccessAddTask,
  handleError,
  handleShowCancelModal,
}: StepProps) {
    // #region HOOKS
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    // #endregion

    // #region STATES
    const [uploadProgress, setUploadProgress] = useState(0);
    // #endregion

    const { pqForm } = useAppSelector((state) => state.pqFormState);
    const token = useAppSelector((state) => state.data.accessToken);
    const prescription = useAppSelector((state) => state.ui.selectedPrescription)

    const { refetch } = useGetCreditQuery()

    const cancelTokenSource = useRef<CancelTokenSource | null>(null);

    const sendTaskData = async (data: ISendTask) => {
        // Assign the cancel token source to the ref
        cancelTokenSource.current = axios.CancelToken.source();
        handleIsLoadingAddTask(true);
        handleIsSuccessAddTask(false)
        try {
          const formdata = buildAddCountTaskFormData(
            data.countTask,
            data.campaignId,
            data.lotId,
            data.projectId,
            data.prescription
          );
          await axios.post(`${API_URL}task/count`, formdata, {
            cancelToken: cancelTokenSource.current.token,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (upload) => {
              let uploadProgress = 0;
              if (upload.total) {
                uploadProgress = Math.round((100 * upload.loaded) / upload.total);
                setUploadProgress(uploadProgress);
              }
            },
          });
          // Rest of your code...
        } catch (error) {
          console.error('Step Finish PQ: ', error);
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          }
          handleIsSuccessAddTask(false)
          handleIsLoadingAddTask(false)
          dispatch(setShowPrescriptionTable(false))
          handleError(true);
          handleShowCancelModal(true);
          ErrorToaster('Algo salio mal en el proceso formulario conteo de plantas');
        }finally {
          refetch()
          dispatch(setShowPrescriptionTable(false))
          handleIsLoadingAddTask(false)
          handleIsSuccessAddTask(true)
        }
    };

    useEffect(() => {
      if (isSuccessAddTask) {
        dispatch(clearImgPoints())
      }
    }, [isSuccessAddTask]);

    useEffect(() => {
      if(selectedCampaignId){
        sendTaskData({
          projectId,
          lotId,
          countTask: {...pqForm, files},
          campaignId: selectedCampaignId,
          prescription
        })
      }
    }, [])

    return (
      <div className={style.bodyContent}>
        <div >
          <p>Progreso: {uploadProgress}%</p>
          <div className={style.progressbars}>
          <span style={{ width: `${uploadProgress}%` }}></span>
          </div>
        </div>
      </div>
    )
}