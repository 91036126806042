import { url } from 'inspector';
import { BuyCreditDto, Credit, GetAllCredits } from 'models/Credit';
import { PaginatedResponse } from 'models/PaginatedResponse';
import { Transaction, TransactionRequest } from 'models/Transaction';

import { VistaguayApi } from './VistaguayAPI';

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Credit','Prices'],
});

const creditApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getCredit: builder.query<Credit, void>({
      query: () => 'credits',
      providesTags: ['Credit'],
    }),
    getTransactions: builder.mutation<PaginatedResponse<Transaction>, TransactionRequest>({
      query: (request) => ({ 
        url: 'credits/transactions',
        method: 'POST',
        body: request,
          
      })
      
    }),
    buy: builder.mutation<any, void>({
      query: () => 'buy',
      invalidatesTags: ['Credit'],
    }),
    getAllCredits: builder.mutation<PaginatedResponse<Credit>, GetAllCredits>({
      query: (filter) => ({
        url: 'credits/all',
        method: 'POST',
        body: filter,
      }),
    }),
    addCredits: builder.mutation<any, BuyCreditDto>({
      query: (credit) => ({
        url: 'credits/add',
        method: 'POST',
        body: credit,
      }),
      invalidatesTags: ['Credit'],
    }),
    mpPreference: builder.mutation<any,any>({
      query: (body) => ({
        url: 'mercadopago/preference',
        method: 'POST',
        body: body,
      }) 
    }),
    paypalOrder: builder.mutation<any,any>({
      query: (body) => ({
        url: 'paypal/order',
        method: 'POST',
      })
    }),
    paypalApprove: builder.mutation<any,any>({
      query: (orderId) => ({
        url: `paypal/${orderId}/capture`,
        method: 'POST',
      })
    }),
    getPrices: builder.query<any,void>({
      query: () => 'prices',
      providesTags: ['Prices']
    })
  }),
});

export const { useGetPricesQuery, useBuyMutation, useGetCreditQuery, useGetTransactionsMutation, useGetAllCreditsMutation, useAddCreditsMutation,useMpPreferenceMutation, usePaypalOrderMutation,usePaypalApproveMutation } = creditApi;
