import { Tooltip } from '@mui/material';
import { ReactComponent as VisibilityIcon } from 'assets/icons/visibility.svg';
import { ReactComponent as VisibilityHiddenIcon } from 'assets/icons/visibilityHidden.svg';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import {InputRange} from 'components/Inputs/Input';
import { t } from 'i18next';
import { ChangeEvent } from 'react';
import { useAppDispatch } from 'state/hooks';
import { LayerInfo, MosaicTaskView, setToggleShowLayer, toggleShowPhotoPoints, updateMosaic } from 'state/slices/mosaicSlice';

import style from '../Contextual.module.scss';

export default function LayerSelector({ mosaicView }: { mosaicView: MosaicTaskView }) {
  const dispatch = useAppDispatch();

  const togglePhotoLayer = () => {
    dispatch(toggleShowPhotoPoints(mosaicView.id))
  }

  const handleToggleVisible = ({selectedLayer, index}: {selectedLayer: LayerInfo, index: number}) => {
    dispatch(setToggleShowLayer({ index }))
    dispatch(
      updateMosaic({
        ...mosaicView,
        layers: mosaicView.layers.map((layer: any) => {
          if (layer.layerName === selectedLayer.layerName) {
            return {
              ...layer,
              visible: !layer.visible,
            };
          }
          return layer;
        }),
      }),
    );
    return;
  };

  if (!mosaicView) {
    return (
      <div className={style.body}>
        <div className={style.contextualButton} style={{justifyContent:'center'}}>
          <p>Las capas no se encuentran :(</p>
        </div>
      </div>
    );
  }

  return (
    <div className={style.body}>

      <div className={style.contextualMenu} style={{padding:'5x 100px'}}>
        {/* PICTURES */}
        <div className={style.contextualButton} style={{marginBottom:'0px'}}>
          <p style={{fontWeight:'bold'}}>{t('pictures')}</p>
          <div className={style.actions}>
            <SwitchButton 
              value={mosaicView.showPhotoPoints}
              handleOnChange={togglePhotoLayer}
            />
          </div>
        </div>
        
        <hr style={{margin: '5px 0 5px 0'}}/>

        {/* LAYERS */}
        {mosaicView && mosaicView.layers.map((layer, index) => (
          <div key={index}>
            <div className={style.contextualButton} style={{marginBottom:'0px'}}>
              <p style={{fontWeight:'bold', textTransform:'uppercase'}}>{layer.layerName}</p>
              <div className={style.actions}>
                <SwitchButton 
                  value={layer.visible}
                  handleOnChange={() => {handleToggleVisible({selectedLayer: layer, index})}}
                />
              </div>
            </div>
        
            <hr style={{margin: '5px 0 5px 0'}}/> 
          </div>
        ))} 
      
        {/* {mosaicView &&
          mosaicView.layers.map((layer) => (
            <LayerActions
              key={`${mosaicView.id}:${layer.layerName}`}
              layerName={layer.layerName}
              visible={layer.visible}
              opacity={layer.opacity}
              mosaicView={mosaicView}
            />
          ))}  */}
      </div>
    </div>
  );
}

const LayerActions = ({ layerName, visible, opacity, mosaicView }: any) => {
  const dispatch = useAppDispatch();

  const handleToggleVisible = () => {
    dispatch(
      updateMosaic({
        ...mosaicView,
        layers: mosaicView.layers.map((layer: any) => {
          if (layer.layerName === layerName) {
            return {
              ...layer,
              visible: !layer.visible,
            };
          }
          return layer;
        }),
      }),
    );
    return;
  };
  const handleTransparency = (value: number) => {
    dispatch(
      updateMosaic({
        ...mosaicView,
        layers: mosaicView.layers.map((layer: any) => {
          if (layer.layerName === layerName) {
            return {
              ...layer,
              opacity: value,
            };
          }
          return layer;
        }),
      }),
    );
  };

  return (
    <div className={style.contextualMenu} style={{gap:'0px',marginBottom:'0px'}}>
      <div className={style.contextualButton} style={{marginBottom:'0px'}}>
        <p style={{fontWeight:'bold',textTransform:'uppercase'}}>{layerName}</p>
        <div className={style.actions}>
          {visible ? (
            <VisibilityIcon
              style={{ fill: style.verdeVistaguay, cursor: 'pointer' }}
              className={style.icon}
              onClick={handleToggleVisible}
            />
          ) : (
            <VisibilityHiddenIcon
              style={{ fill: style.gris, cursor: 'pointer' }}
              className={style.icon}
              onClick={handleToggleVisible}
            />
          )}
        </div>
      </div>
        <Tooltip 
          title={`${opacity * 100} %`}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -20],
                  },
                },
              ],
            },
          }}
        >
      <div className={style.contextualButton} >
          <InputRange
            style={{margin:'0px'}}
            min={0}
            step={0.1}
            max={1}
            disabled={!visible}
            type='range'
            onChange={(e : ChangeEvent<HTMLInputElement>) => handleTransparency(+e.target.value)}
            value={opacity}
          />
      </div>
        </Tooltip>
    </div>
  );
};
