import { useAddNewCameraMutation } from 'api/Vistaguay/MosaicAPI';
import { buildMosaicTaskFormData } from 'api/Vistaguay/TaskAPI';
import axios, { CancelTokenSource } from 'axios';
import { Cameras } from 'models/Cameras';
import MosaicTask from 'models/MosaicTask';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from 'services/configurations';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { clearImgPoints } from 'state/slices/mapSlice';

import { ErrorList, MosaicFormProps, MosaicStepProps } from '../AddMosaic'
import style from '../MosaicForm.module.scss'

interface StepUploadProps extends MosaicStepProps {
    selectedCampaignId?: number;
    lotId?: string;
    projectId?: string;
    isSuccessAddTask: boolean;
    isLoadingAddTask: boolean;
    handleShowCancelModal: (show: boolean) => void;
    handleIsLoadingAddTask: (status: boolean) => void;
    handleIsSuccessAddTask: (status: boolean) => void;
}

export default function StepUpload({ 
    form, 
    step, 
    currentStep,
    selectedCampaignId,
    lotId,
    projectId,
    isSuccessAddTask,
    isLoadingAddTask,
    handleIsLoadingAddTask,
    handleIsSuccessAddTask,
    handleShowCancelModal,
    handleError
}: StepUploadProps) {
    // #region HOOKS
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    // #endregion
    const [ addNewCamera, {data: camera, isLoading,isError, isSuccess} ] = useAddNewCameraMutation()
    const [ uploadProgress, setUploadProgress ] = useState(0);
    const [ currentFile, setCurrentFile ] = useState<string>('')
    const [ errorsList, setErrorList ] = useState<ErrorList[]>([]);

    const token = useAppSelector((state) => state.data.accessToken);

    const cancelTokenSource = useRef<[CancelTokenSource] | []>([]);

    const sendTask = async (data: MosaicFormProps) => {
        handleIsLoadingAddTask(true);

        try {
            const formData = buildMosaicTaskFormData(
                data,
                selectedCampaignId,
                lotId,
                projectId
            )

            const {data: response} : {data: MosaicTask} = await axios.post(`${API_URL}mosaic/add`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'Application/json',
                    'Access-Control-Allow-Origin': '*', // FIXME
                },
            })
   
            data.files.forEach(async (file, index) => {
                cancelTokenSource.current[index] = axios.CancelToken.source()
                const form = new FormData();
                form.append('file', file);
                
                if(response.mosaicoId === undefined) throw new Error('Error al enviar la solicitud');

                await axios.post(`${API_URL}mosaic/upload/${response.mosaicoId}`, form, {
                    cancelToken: cancelTokenSource.current[index].token,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': '*', // FIXME
                    },
                })

                .then((res) => {
                    if(res.status === 201) {
                        setCurrentFile(file.name)
                        setUploadProgress(res.data.uploadProgress)
                        handleIsLoadingAddTask(false);
                        handleIsSuccessAddTask(true);
                    } else {
                        handleIsLoadingAddTask(false);
                        handleIsSuccessAddTask(false);
                    }
                }).catch((error) => {
                    if (axios.isCancel(error)) {
                        handleError(true);
                        handleShowCancelModal(true);
                        console.log('Request canceled', error.message);
                    } else {
                        throw new Error('Error al enviar la solicitud')
                    }
                })
     
                if(index === data.files.length - 1) {
                    handleIsLoadingAddTask(false);
                    handleIsSuccessAddTask(true);
                }
            });
        } catch (error) {
            handleIsLoadingAddTask(false);
            handleIsSuccessAddTask(false);
            handleShowCancelModal(true);
        } 
    }

    useEffect(() => {
        if(form.cameraId == '') {
            const newCamera: Partial<Cameras> = {
                marca: form.cameraBrand,
                modelo: form.cameraModel,
                tipoCamara: form.cameraType
            }
            addNewCamera(newCamera)
        }

        sendTask(form);
    }, [])

    useEffect(() => {
        return () => {
            dispatch(clearImgPoints());
            if (cancelTokenSource.current) {
                cancelTokenSource.current.map((source) => source.cancel('Request canceled'));
            }
        };
    }, []);
console.log('isSuccessAddTask', isSuccessAddTask)
    useEffect(() => {
        if(currentStep === step) {
            const errors: ErrorList[] = [];
            let error = false; 

            if(!isLoadingAddTask && !isSuccessAddTask){
                errors.push({ message: t('form-upload-error'), propName: 'form-upload-error'})
            }
  
            if(errors.length > 0) { error = true };

            setErrorList(errors);
            handleError(error); 
        }
    }, [isLoadingAddTask, isSuccessAddTask])

    return (
        <div className={style.bodyContent}>
          <div >
            <p>Progreso: {uploadProgress}%</p>
            <div className={style.progressbars}>
            <span style={{ width: `${uploadProgress}%` }}></span>
            </div>
          </div>
        </div>
    )
}