import styles from './List.module.scss'
interface ListProps {
    children?: React.ReactNode
}


export default function List({ children }: ListProps) {
    return (
        <div className={styles.list}>
            {children}
        </div>
    )
}