import { Icon } from '@blueprintjs/core';
import { ReactComponent as Less } from 'assets/icons/ico-square-less.svg';
import { ReactComponent as Plus } from 'assets/icons/ico-square-plus.svg';
import { SimpleModal } from 'components/Modal/SimpleModal';
import { useState } from 'react';

import style from './CountTaskReport.module.scss';
import {
  PhotoDescriptionSection,
  typeDescription,
} from './PhotoDescriptionSection';
import { getPhoto } from './photoModal/GetPhoto';
import { getPhotoUrl } from './photoModal/PhotoUrl';

export const PhotoDescriptionSectionConteiner = ({ task, photosMetadata, prescription }: any) => {
  return (
    <div className={style.sectorConteiners}>
      <SectorConteiner
        task={task}
        text='Muestra con MENOR población'
        color='#c1594b'
        photosMetadata={photosMetadata}
        typeDescription={typeDescription.Min}
        icon = 'small-minus'
        prescription={prescription}
      />
      <SectorConteiner
        task={task}
        text='Muestra con MAYOR población'
        color='#329978'
        photosMetadata={photosMetadata}
        icon = 'small-plus'
        typeDescription={typeDescription.Max}
        prescription={prescription}
      />
    </div>
  );
};

const SectorConteiner = ({
  task,
  text,
  color,
  photosMetadata,
  typeDescription,
  icon,
  prescription,
}: any) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };
  const selectedPhoto = getPhoto(photosMetadata, -1, typeDescription);
  console.log('typeDescription', typeDescription);
  return (
    <>
      <div 
          onClick={() => {
            setOpen(true);
          }}>
        <div className={style.textCoteiner} style={{ backgroundColor: '#353535' }}>
          <div className={style.svg1}>
            {
              typeDescription === 'max'?  <div><Plus className={style.svgimage}/></div> :  <div><Less className={style.svgimage}/></div>
            }
          </div>
          <div className={style.textTitle}>{text}</div>
       
        <div className={style.image}>
          <img src={getPhotoUrl(selectedPhoto, task.conteosPlantasId)} alt='semaphore' />
        </div>
        </div>
     
      </div>
      {open && (
        <SimpleModal
          closeFn={() => {
            setOpen(false);
          }}
          photosMetadata= {photosMetadata}
        >
          <PhotoDescriptionSection
            task={task}
            photosMetadata={photosMetadata}
            taskId={task.conteosPlantasId}
            closeFunction={closeModal}
            type={typeDescription}
            prescription={prescription}
          />
        </SimpleModal>
      )}
    </>
  );
};
