import { Tab,Tabs} from '@mui/material';
import { ReactComponent as Prescriptionsvg } from 'assets/icons/prescription.svg';
import { Prescription } from 'models/Prescription';
import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';

import { ReactComponent as Close } from '../../assets/icons/ico-close.svg';
import {ReactComponent as Minimize} from  '../../assets/icons/minimize.svg'
import style from './PrescriptionModal.module.scss';
import { TabPanel } from './PrescriptionTabPanel';

interface IPrescriptionModalProps {
  prescriptions: Prescription[];
  selectedPrescriptionId: number | null | undefined; 
  showPrescription: boolean;
  prescriptionEditable?: boolean;
  handleDensityValues?: (values: any) => void;
}

export const PrescriptionModal2 = ({ 
  prescriptions,
  selectedPrescriptionId,
  showPrescription,
  prescriptionEditable = false,
  handleDensityValues
}: IPrescriptionModalProps) => {
  const dispatch = useDispatch();
  
  const [activeTab, setActiveTab] = useState<number | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [lastActiveTab, setLastActiveTab] = useState<number | undefined>(undefined); 
  
  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (prescriptions.length !== 0) {
      const indexToSetActive = prescriptions.findIndex((pres) => pres && pres.id === selectedPrescriptionId);
      if (indexToSetActive !== -1) {
        setActiveTab(indexToSetActive);
        console.log(indexToSetActive)
      }
    }
  }, [prescriptions]);

  if(activeTab == undefined) return <></>

  const shouldShowDraggable = showPrescription;

  const handleMinimize = () => {
    setLastActiveTab(activeTab);
    setIsModalVisible(false);
  };

  const handleShowModal = () => {
    setActiveTab(lastActiveTab);
    setIsModalVisible(true);
  };

  return (
    <>
      {isModalVisible && shouldShowDraggable ? (
        <Draggable>
          <div className={style.modal}>
            <Tabs 
              value={activeTab} onChange={handleChangeTab}
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
              }}
            >
              {prescriptions && activeTab != undefined && prescriptions.map((item: Prescription, index: number) => {
                if (showPrescription) {
                  return (
                    <Tab
                      sx={{ 
                        bgcolor: activeTab === index ? '#020202' : '#6C6C6C',
                        borderTopLeftRadius: 15, 
                        borderTopRightRadius: 15,
                        minWidth: '5rem',
                        minHeight: '2rem',
                        width: '5rem',
                        height: '2rem',
                        marginLeft: 2, 
                        marginTop: '5vh',
                        color: 'white !important',
                      }} 
                      key={index}
                      onClick={() => setActiveTab(index)}
                      label={
                        <div className={style.labelStyle}>
                          <span className={style.span}>#{item.id}</span>
                          <div style={{ marginLeft: '15px' }}>
                            <Close
                              className={style.close}
                              // onClick={() => handleToggleShowPrescriptionData(dispatch, item.task.taskId, activeTab, setActiveTab, data)}
                            />
                          </div>
                        </div>
                      }
                    />
                  );
                }
                return null;
              })}
              <Tab
                sx={{ 
                  bgcolor: '#020202',
                  borderTopLeftRadius: '0.4vh', 
                  borderTopRightRadius: '0.4vh',
                  width: '0.2vh', height: '0.1vh', 
                  minWidth: '0.2vh', 
                  minHeight: '0.1vh',
                  marginLeft: 'auto',
                  marginRight: '1vh',
                  marginTop: 'auto',
                  color: 'white !important',
                }} 
                onClick={handleMinimize}
                label={
                  <div>
                    <Minimize
                      className={style.close}
                    />
                  </div>
                }
              />
            </Tabs>
            { prescriptions && prescriptions.length !== 0 && 
              // prescriptions[activeTab]?.id === selectedPrescriptionId &&
              <div className={style.divTabPanel}>
                <TabPanel 
                  prescription={prescriptions[activeTab]} 
                  value={0} 
                  index={activeTab} 
                  dispatch={dispatch}
                  editable={prescriptionEditable}
                  handleDensityValues={handleDensityValues}
                />
              </div>
            }
          </div>
        </Draggable>
      ) : (
        !isModalVisible && shouldShowDraggable && (
          <button className={style.floatingButton} onClick={handleShowModal}>
            <Prescriptionsvg
            className={style.icon}
            fill={style.verdeVistaguay}
          />
          </button>
        )
      )}
    </>
  );
};