import { useGetAnalyticsByIdQuery } from 'api/Vistaguay/AnalyticsApi';
import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import { ReactComponent as Scouting } from 'assets/icons/ico-scouting.svg';
import { ReactComponent as Timer } from 'assets/icons/TimerIcon.svg';
import Button from 'components/Button/Button';
import useToggle from 'hooks/useToggle';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import SidebarHeader from 'layout/Sidebar/SidebarHeader';
import { SlimHeader, SubHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';

import ListItems from '../list/ListItems';
import ModalAnalitycs from '../list/Modal/ModalAnalitycs';

export default function Analytics() {
    const selectedCampaign: Campaign | null = useAppSelector(
        (state) => state.campaign.selectedCampaign,
      );
    const { projectId, lotId, analyticsId } = useParams<{ projectId: string; lotId: string; analyticsId: string }>();
    const navigate = useNavigate();

    // Data SlimHeader
    if (!projectId || !lotId || !analyticsId) {
        return null; // FIXME handle error
    }
    const { data: project } = useGetProjectQuery(projectId as unknown as number);
    const { data: lot } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });

    // Analitycs Componet
    const { isActive: isActiveModal, toggle: toggleModal } = useToggle();
    const handleClick = () => {
        !isActiveModal && toggleModal();
        if (isActiveCapas) { navigate(`/projects/${projectId}/lots/${lotId}/analytics/${analyticsId}/process`); }
    };

    const {
        data: projectAnalitycs,
        isLoading: isLoadingProjectAnalitycs,
    } = useGetAnalyticsByIdQuery(+analyticsId);

    const { isActive: isActiveCapas, toggle: toggleCapas } = useToggle();
    return (
        <>
            <Sidebar>
                <SidebarHeader />
                <SubHeader noBack={true}>
                    <SlimHeader
                        campaignName={selectedCampaign?.name}
                        projectName={project?.nombre}
                        projectColor={project?.color}
                        lotName={lot?.name}
                        backTo={`/projects/${projectId}/lots/${lotId}`}
                        backText='Cancelar ensayo'
                    />
                </SubHeader>
                <SidebarBody>
                    <div
                        style={{
                            borderTop: '2px solid #47c278',
                            borderBottom: '2px solid #47c278',
                            display: 'flex',
                            justifyContent: 'start',
                            gap: '5px',
                            padding: '0 2px',
                        }}
                    >
                        <Scouting style={{ width: '30px', fill: '#47c278' }} />
                        {
                            isLoadingProjectAnalitycs
                                ?
                                <Timer style={{ height: 'auto', width: '30px', fill: '#b2b2b2' }} />
                                :
                                <h5 key={projectAnalitycs?.id} style={{ margin: '0', padding: '5px', fontSize: '20px' }}>{projectAnalitycs?.nombre}</h5>
                        }
                    </div>
                    {<ListItems isActive={isActiveCapas} toggle={toggleCapas} />}
                </SidebarBody>
                <SidebarFooter sx={{ display: isActiveCapas ? '' : 'flex', justifyContent: isActiveCapas ? '' : 'center' }}>
                    <Button
                        style={{ display: isActiveCapas ? 'flex' : 'none' }}
                        variant='ERROR'
                        text='Cancelar'
                        onClick={() => navigate(`/projects/${projectId}/lots/${lotId}`)}
                    />
                    <Button
                        style={{ display: isActiveCapas ? 'flex' : 'none' }}
                        variant='SUCCESS'
                        text='Procesar'
                        onClick={handleClick}
                    />
                    <Button
                        style={{ width: 'auto', display: isActiveCapas ? 'none' : 'flex' }}
                        variant='SUCCESS'
                        text='Nueva Capa'
                        onClick={handleClick}
                    />
                </SidebarFooter>
            </Sidebar>
                {
                    <ModalAnalitycs
                        isActiveModal={isActiveModal}
                        toggleModal={toggleModal}
                        isActiveCapas={isActiveCapas}
                        toggleCapas={toggleCapas}
                    />
                }
        </>
    );
}
