import { Popover2 } from '@blueprintjs/popover2';
import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import { useUpdateMosaicUserMutation } from 'api/Vistaguay/MosaicAPI';
import { usePostUpdateDescriptionMutation } from 'api/Vistaguay/TaskAPI';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import Button from 'components/Button/Button';
import { Input, TextArea } from 'components/Inputs/Input';
import { MenuItem } from 'components/MenuItem/MenuItem';
import Tooltip from 'components/Tooltip/Tooltip';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import MosaicTask from 'models/MosaicTask';
import { ChangeEvent, ChangeEventHandler, ReactHTMLElement, useEffect, useState } from 'react';
import { setGenotypeId } from 'state/slices/pqFormSlice';

import style from '../Contextual.module.scss';
export default function EditOption({ task, taskId }: { task: MosaicTask; taskId: string }) {
  const {isActive: isEditing, toggle: toggleEdit} = useToggle()
  const [updateMosaic, {data:mosaicData, isLoading, isSuccess,isError }] = useUpdateMosaicUserMutation()
  const {data: extraData } = useGetExtraDataQuery();
  const extradata = extraData as any;
  const [mosaic,setMosaic] = useState<MosaicTask>(task)
  
  const [editActive, setEditActive] = useState(false);
  const [showSavePopUp, setShowSavePopUp] = useState(false);
  const [mosaicName, setMosaicName] = useState(mosaic?.nombre);
  const [fechaDeVuelo, setFechaDeVuelo] = useState(mosaic?.fechaVuelo);
  const [sowingDate, setSowingDate] = useState(mosaic?.fechaSiembra);
  const [selectedCrop, setSelectedCrop] = useState(mosaic?.cultivoId || '');
  const [selectedGenotype, setselectedGenotype] = useState< {name: string, value:string} | null >(null);
  const [selectedState, setState] = useState(mosaic?.estadioId || '');
  const [cuit, setCuit] = useState(mosaic?.cuitCliente || '');

  const handleSave = () => {
    updateMosaic({ mosaicId: +taskId, mosaic: mosaic });
  };

  /**
   * Find in the extradata set an item and return the id + name
   * @param propertyName | Name of the property to search in extrada set of data - example: crops
   * @param id | Id of the property - example: for crops we pass cropId
   * @param subPropertyName | Name of the sub property - example: crops -> hybrids / hybrids its the children property
   * @param subId | Id of the property - example: for hybrids we pass hybridId
   * @returns An object - example for crops {value: '2', name: 'Maiz'} | value correspond with the id
  */
  function matchExtraDataFromId ( propertyName: string, id: string, subPropertyName?: string, subId?: string): {value:string, name:string} {
    const emptyItem = {value: '', name: ''};
    if(extradata) {
      const item = extradata[propertyName].filter((e: any) => e.id == id)[0];

      if(item && subPropertyName && subId) {
          const subItem = item[subPropertyName];
          const sub = subItem.filter((sub:any) => sub.id == subId)[0];
          return sub ? {value: sub.id, name: sub.name} : emptyItem;
      }

      return item ? {value: item.id, name: item.name} : emptyItem;
    } else {
      return emptyItem
    }
  }

  /**
   * Get the inside list of extradata item | example: inside crops list -> crop 'Corn' -> corn hybrids list
   * @param propertyName example: crops
   * @param subPropertyName example: hybrids
   * @param id example: cropsId
   * @returns Array | a list of items
 */
  function obtainSubPropertyList (propertyName: string, subPropertyName: string, id?: string): Array<{name: string, id: string}> {
    if(id) {
      const item = extradata[propertyName].filter((e: any) => e.id == id)[0];

      if(item && subPropertyName){
        const subItems = item[subPropertyName];
        return (subItems && subItems.length) > 0 ? subItems : []; 
      }
    }
    return []
  }

  useEffect(() => {
    if(isSuccess){
      toggleEdit()
  
    }
  },[isSuccess])

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target)
    setMosaic(
      (prevState) => {
        return {
          ...prevState,
          [e.target.id]: Number(e.target.value)
        }
      }
    )
  }

  // return (
  //   <>
  //     {
  //       !isEditing ?
  //       <div
  //         style={{ textAlign: 'center', gap: '5px', display: 'flex', flexDirection: 'column',padding:'0px 5px' }}
  //         className={style.body}
  //       >
  //         <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //           <p>
  //             Cultivo
  //           </p>
  //           <p style={{fontWeight:'normal',color:style.light}}>
  //             {extraData && extraData.crops.find(crop => crop.id == mosaic.cultivoId)?.name}
  //           </p>
  //         </div>
  //         <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //           <p>
  //             Genotipo
  //           </p>
  //           <Tooltip text={extraData && extraData.crops.find(crop => crop.id == mosaic.cultivoId)?.hybrids.find(hybrid => hybrid.id == mosaic.hibridoId)?.name || ''}>
  //             <p style={{fontWeight:'normal',color:style.light,overflow:'hidden',whiteSpace:'nowrap' ,textOverflow:'ellipsis', maxWidth:'100px',wordWrap:'break-word'}}>
  //               {extraData && extraData.crops.find(crop => crop.id == mosaic.cultivoId)?.hybrids.find(hybrid => hybrid.id == mosaic.hibridoId)?.name}
  //             </p>
  //           </Tooltip>
  //         </div>
  //         <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //           <p>
  //             Estadio fenologico
  //           </p>
  //           <p style={{fontWeight:'normal',color:style.light}}>
  //             {extraData && extraData.crops.find(crop => crop.id == mosaic.cultivoId)?.stages.find(stage => stage.id == mosaic.estadioId)?.name}
  //           </p>
  //         </div>
  //         <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //           <p>
  //             Fecha de siembra
  //           </p>
  //           <p style={{fontWeight:'normal',color:style.light}}>
  //             {mosaic.fechaSiembra}
  //           </p>
  //         </div>
  //         <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //           <p>
  //             Fecha de vuelo
  //           </p>
  //           <p style={{fontWeight:'normal',color:style.light}}>
  //             {mosaic.fechaVuelo}
  //           </p>
  //         </div>
  //         <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //           <p>
  //             Cuit
  //           </p>
  //           <p style={{fontWeight:'normal',color:style.light}}>
  //             {mosaic.cuitCliente}
  //           </p>
  //         </div>
  //         <Button height='30px' variant={'SUCCESS'} onClick={toggleEdit} text='Editar' style={{ fontSize: '12px' }}/>
  //       </div>
  //       :
  //       <div
  //       style={{ textAlign: 'center', gap: '5px', display: 'flex', flexDirection: 'column',padding:'0px 5px' }}
  //       className={style.body}
  //     >
  //       <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //         <p>
  //           Cultivo
  //         </p>
  //         <select
  //           style={{backgroundColor:style.azul,border:'2px solid', borderColor: style.dark,color:style.light,fontSize:'12px',marginBottom:'5px'}}
  //           id='cultivoId'
  //           value={Number(mosaic.cultivoId)}
  //           onChange={handleChange}
  //         >
  //           {
  //             extraData?.crops.map(value => {
  //               return (
  //                 <option key={value.id + value.name} value={value.id}>{value.name}</option>
  //               )
  //             })
  //           }
  //         </select>
          
  //       </div>
  //       <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //         <p>
  //           Genotipo
  //         </p>
  //         <select
  //           style={{backgroundColor:style.azul,border:'2px solid',maxWidth:'100px',borderColor: style.dark,color:style.light,fontSize:'12px',marginBottom:'5px'}}
  //           id='hibridoId'
  //           value={Number(mosaic.hibridoId)}
  //           onChange={handleChange}
  //         >
  //           {
  //             extraData?.crops.find((value,index) => value.id ==  mosaic.cultivoId)?.hybrids.map((hybrid) => {
  //               return (
  //                 <option key={hybrid.id + hybrid.name} value={hybrid.id}>
  //                   {hybrid.name}
  //                 </option>
  //               )
  //             })
  //           }
  //         </select>
  //       </div>
  //       <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //         <p>
  //           Estadio fenologico
  //         </p>
  //         <select
  //          value={Number(mosaic.estadioId)}
  //          id='estadioId'
  //          style={{backgroundColor:style.azul,border:'2px solid',maxWidth:'100px',borderColor: style.dark,color:style.light,fontSize:'12px',marginBottom:'5px'}}
  //          onChange={handleChange}
  //         >
  //           {
  //             extraData?.crops.find((value,index) => value.id ==  mosaic.cultivoId)?.stages.map(stage => {
  //               return (
  //                 <option key={stage.id + stage.name} value={stage.id}>
  //                   {stage.name}
  //                 </option>
  //               )
  //             })
  //           }
  //         </select>
          
  //       </div>
  //       <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //         <p>
  //           Fecha de siembra
  //         </p>
  //         <p style={{fontWeight:'normal',color:style.light}}>
  //         {task.fechaSiembra}
  //         </p>
  //       </div>
  //       <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //         <p>
  //           Fecha de vuelo
  //         </p>
  //         <p style={{fontWeight:'normal',color:style.light}}>
  //         {task.fechaVuelo}
  //         </p>
  //       </div>
  //       <div className={style.info} style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom:'1px solid', borderColor:style.dark}}>
  //         <p>
  //           Cuit
  //         </p>
  //         <p style={{fontWeight:'normal',color:style.light}}>
  //           {task.cuitCliente ? task.cuitCliente : 'Empty'}
  //         </p>
  //       </div>
  //       <div style={{display:'flex', justifyContent:'space-around'}}>

  //         <Button variant={'SUCCESS'} type={'OUTLINE'} text='Guardar' disabled={isLoading} onClick={handleSave} style={{ fontSize: '12px', padding: '5px', width: 'auto', minWidth:'80px', marginTop: '5px',alignSelf:'flex-end' }}/>
  //         <Button variant={'ERROR'} type={'OUTLINE'} text='Cancelar' disabled={isLoading} onClick={toggleEdit} style={{ fontSize: '12px', padding: '5px', width: 'auto', minWidth:'80px', marginTop: '5px',alignSelf:'flex-end' }}/>
  //       </div>
  //     </div>
  //     }
      
  //   </>
  // );

  // #region POP UP MODALS
  const SavePopUpCard = () => {
    return (
        <div className={style.savePopUp}>
            <h4 className={style.savePopUpLabel}
                style={{fontWeight: 600}}
            >
              Actualizar mosaico con los nuevos cambios
            </h4>
            <p className={style.savePopUpLabel}>Esto inhabilitará la descarga del mismo hasta que se haya generado nuevamente.</p>
            <div className={style.savePopUpButtonWrapper}>
              <Button text={'Aceptar'} onClick={() => handleSave()} type={'FILL'} variant={'SUCCESS'} style={{fontSize:'13px',padding:'5px', marginTop:'10px', width:'48%'}} />
              <Button text={'Cancelar'} onClick={() => setEditActive(false)} type={'OUTLINE'} variant={'NEUTRAL-GRAY'} style={{fontSize:'13px',padding:'5px', marginTop:'10px', width:'48%'}} />
            </div>
        </div>
    )
}
// #endregion

  return (
    <div 
      style={{width: '300px', padding: '0.5rem', gap:'5px',display:'flex',flexDirection:'column'}} 
      className={style.body}
    >
      <MenuItem
        title={t('name')} 
        inputValue={mosaicName}
        editActive={editActive}
        handleChange={(e: any) => setMosaicName(e)}
      />

      <MenuItem
        title={t('date-of-flight')} 
        inputValue={fechaDeVuelo}
        editActive={editActive}
        inputType='datepicker'
        handleChange={(newValue: string) => setFechaDeVuelo(newValue)}
      />

      <MenuItem
        title={t('form-seeding-date')} 
        inputValue={sowingDate}
        editActive={editActive}
        inputType='datepicker'
        handleChange={(newValue: string) => setSowingDate(newValue)}
      />

      <MenuItem
        title={t('camera')} 
        inputValue={`${mosaic?.camara?.marca} - ${mosaic.camara?.modelo}`}
      />

      <MenuItem
        title={t('camera-type')} 
        inputValue={`${mosaic?.camara?.tipoCamara}`}
      />

      <MenuItem
        title={t('crop')} 
        inputType='dropdown' 
        editActive={editActive}
        option={ matchExtraDataFromId('crops', selectedCrop.toString())}
        listItems={extradata?.crops || []}
        handleChange={(newValue: any) => setSelectedCrop(newValue)}
      />

      <MenuItem
        title={t('genotype')} 
        option={selectedGenotype}
        editActive={editActive}
        inputType='dropdown'
        listItems={obtainSubPropertyList('crops', 'hybrids', selectedCrop.toString())}
        handleChange={(newValue: any) => setGenotypeId(newValue)}
      />
      
      <MenuItem
        title={t('phenological-state')}
        inputType='dropdown' 
        editActive={editActive}
        option={matchExtraDataFromId('crops', selectedCrop.toString(), 'stages', selectedState.toString())}
        listItems={obtainSubPropertyList('crops', 'stages', selectedCrop.toString())}
        handleChange={(newValue: any) => setState(newValue)}
      />

      <MenuItem
        title={'CUIT'} 
        inputValue={cuit}
        editActive={editActive}
        handleChange={(newValue: any) => setCuit(newValue)}
      />

      {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        { editActive && (
          <Popover2
            interactionKind='click'
            usePortal={true}
            content={<SavePopUpCard />}
            className={style.popover}
          >
            <div
              style={{
                backgroundColor: showSavePopUp ? style.verdeVistaguay : style.light, 
                width: '28px', 
                height:'28px', 
                borderRadius: '50%', 
                cursor:'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '5px'
              }}
              onClick={() => setShowSavePopUp((prevState) => !prevState)}
            >
              <SaveIcon 
                style={{
                    fill: style.azul,
                    width: '16px', 
                }}
                className={style.tabicon} 
              />
            </div>
          </Popover2>
        )}
          <div
            style={{
              backgroundColor: editActive ? style.verdeVistaguay : style.light, 
              width: '28px', 
              height:'28px', 
              borderRadius: '50%', 
              cursor:'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={() => setEditActive((prevState) => !prevState)}
          >
            <EditIcon 
              style={{fill: style.azul}}
              className={style.tabicon} 
            />
          </div>
      </div> */}
    </div>
  )
}
