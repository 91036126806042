import { useCheckCameraExistMutation } from 'api/Vistaguay/MosaicAPI';
import Alert from 'components/Alert/Alert'
import { Input, InputSelect } from 'components/Inputs/Input';
import { CAMERA_TYPE } from 'models/MosaicTask'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorList, MosaicFormProps, MosaicStepProps, MosaicStepValidate } from '../AddMosaic'
import style from '../MosaicForm.module.scss'

export default function StepFlight({ 
    form, 
    setForm, 
    handleError,
    step,
    currentStep,
    nextStepTouched
}: MosaicStepProps) {
    const { t } = useTranslation();

    const [checkCameraExist, { data: camara, isLoading, isSuccess, isError }] = useCheckCameraExistMutation()
    const [errorsList, setErrors] = useState<ErrorList[]>([]);
    
    useEffect(() => {
        checkCameraExist({ cameraBrand: form.cameraBrand, cameraModel: form.cameraModel })
    }, [checkCameraExist])

    useEffect(() => {
        if (isError) return
        if (isSuccess && camara) {
            setForm(
                {
                    ...form,
                    cameraId: String(camara.id),
                    cameraType: camara.tipoCamara
                }
            )
            return
        }
    }, [camara, isLoading, isSuccess, isError])

    useEffect(() => {
        if(currentStep === step) {
            const errors: ErrorList[] = [];
            let error = false; 
      
            if(!form.flightDate) {
              errors.push({ message: t('date-of-flight-required'), propName: 'flight-date'});
            } else if (new Date(form.flightDate) > new Date()) {
              errors.push({ message: t('date-of-flight-error'), propName: 'flight-date'});
            } else if (form.sowDate && (new Date(form.flightDate) <= new Date(form.sowDate))) {
                errors.push({ message: t('date-of-flight-error2'), propName: 'flight-date'});
            } else if (!form?.cameraId) {
                errors.push({ message: t('form-camera-brand-error'), propName: 'camera-brand'});
            } 
      
            if(errors.length > 0) { 
              error = true;
            } 
      
            setErrors(errors);
            handleError(error);
        }
    }, [
        form?.flightDate,
        form?.cameraId
    ])

    return (
        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {/* NOMBRE */}
            <div>
                <label>
                    {t('name')}
                    <span>({t('opcional')})</span>
                </label>
                <Input
                    placeholder={t('name')}
                    value={form.nombre}
                    onChange={(e) => setForm({ ...form, nombre: e.target.value })}
                />
            </div>

            {/* FECHA DE VUELO */}
            <div>
                <label>{t('date-of-flight')}</label>
                <Input
                    placeholder={t('date-of-flight')}
                    value={form.flightDate && !isNaN(form.flightDate.getTime())
                        ? form.flightDate.toISOString().slice(0, 10) 
                        : ''
                    }
                    type='date'
                    onChange={
                        (e) => {
                            const newDate = new Date(e.target.value);
                            setForm({ ...form, flightDate: newDate })
                        }
                    }
                />
                {
                    nextStepTouched && errorsList.map((error, index) => {
                    if (error.propName === 'flight-date') {
                        return <Alert key={index} type='error' text={error.message} />;
                    } else {
                        return null;
                    }
                    })
                }
            </div>

            {/* CAMARA */}
            <div>
                <label>Camara</label>
                <Input 
                    type='text' 
                    placeholder='Tipo de camara' 
                    disabled={true} 
                    defaultValue={`${form.cameraBrand} | ${form.cameraModel}`} 
                />
            </div>
            
            {/* MODELO DE CAMARA */}
            <div>
                { nextStepTouched && errorsList.map((error, index) => {
                    if (error.propName === 'camera-brand') {
                        return <Alert key={index} type='warning' text={t('form-camera-brand-not-found')} />;
                    } else {
                        return null;
                    }
                })}

                { !form.cameraId && (
                    <div>
                        <label>{t('form-camera-brand-label')}</label>
                        <InputSelect 
                            value={form.cameraType} 
                            placeholder='cameratype'
                            onChange={(e) => setForm({ ...form, cameraType: e.target.value as CAMERA_TYPE })}
                        >
                            <option value={CAMERA_TYPE.NIR}>
                                NIR-G-B
                            </option>
                            <option value={CAMERA_TYPE.RGB}>
                                RGB
                            </option>
                            <option value={CAMERA_TYPE.NULL}>
                                Seleccione una opcion
                            </option>
                        </InputSelect>

                        { nextStepTouched && errorsList.map((error, index) => {
                            if (error.propName === 'camera-brand') {
                                return <Alert key={index} type='error' text={error.message} />;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}