import { typeDescription } from '../PhotoDescriptionSection';
import { photoMetadata } from './GetPhoto';

export function PrescriptionData(prescription: any, densidadesidx: photoMetadata[], type: typeDescription) {
    return prescription.prescription.features.map((feature: any, idx: number) => {
      const filteredPhotos = densidadesidx.filter((photo: photoMetadata) => {
        return parseInt(photo.fileName.split('/')[0]) === idx;
      });
  
      let result;
      if (type === typeDescription.Min) {
        result = filteredPhotos.reduce((prev: photoMetadata, current: photoMetadata) => {
          return prev.densidad < current.densidad ? prev : current;
        });
      } else {
        result = filteredPhotos.reduce((prev: photoMetadata, current: photoMetadata) => {
          return prev.densidad > current.densidad ? prev : current;
        });
      }
      return result;
    });
  }
  