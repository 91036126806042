/* eslint-disable camelcase */
import { feature, Polygon, polygon } from '@turf/turf';
import MarkerIcon from 'assets/icons/ico-flag.svg';
import MPoint from 'components/Map/Components/MPoint';
import MPolyLine from 'components/Map/Components/MPolyline';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import MPolygon from 'components/Map/Components/Polygon/MPolygon';
import MPolygonEdit from 'components/Map/Components/Polygon/MPolygonEdit';
import Lot from 'models/Lot';
import { MappingMission } from 'models/Mission';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFlightPlan } from 'services/mappingMissionGenerator';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  IFlightPlanInfo,
  setFlightPlanInfo,
  setMappingPoints,
  setMissionPolygon,
} from 'state/slices/volareSlice';
import { BLACK_LINE, LOT_COLOR, MISSION_POLYGON_COLOR } from 'styles/variables';

import { IMappingMissionParams } from './MappingMissionForm';

export function AddMappingMap() {
  const dispatch = useAppDispatch();
  const missionPoints = useAppSelector((state) => state.volare.missionPolygon);
  const polyPoints = useAppSelector((state) => state.volare.mappingPoints);
  const flightParams = useAppSelector((state) => state.volare.missionBuilderParams);
  const currentMission = (useAppSelector((state) => state.volare.currentMission) as MappingMission);
  const currentLot = useAppSelector((state) => state.volare.currentLot);

  const [error, setError] = useState(false);
  const { missionId } = useParams<{
    missionId?: string;
  }>();

  function isMappingMissionParams(params: any): params is IMappingMissionParams {
    return 'map_mis_altitude' in params;
  }

  useEffect(() => {
    const handlePlanInfoUpdate = (planInfo: IFlightPlanInfo) => {
      dispatch(setFlightPlanInfo(planInfo));
    };
    const polygon = missionPoints?.geometry;

    if (polygon && polygon.coordinates[0].length >= 3) {
      try {
        const planOptions = {
          flyHeight: currentMission?.altitude ? (Number(currentMission.altitude) / 1000) : 0.07,
          speed: currentMission?.speed || 10,
          direction: currentMission?.flight_direction || 0,
          camera: currentMission?.camera_name || 'MAVIC_PRO',
          ov_frontal: currentMission?.frontal_overlap || 75,
          ov_lateral: currentMission?.lateral_overlap || 75,
        };

        const mappingFlightPlan = getFlightPlan(missionPoints.geometry, planOptions);

        handlePlanInfoUpdate({
          minutes: mappingFlightPlan?.minutes || '-',
          area: mappingFlightPlan?.area || '-',
          photos: Number(mappingFlightPlan?.photos),
          bateries: Number(mappingFlightPlan?.bateries),
        });
        
        dispatch(
          setMappingPoints(
            mappingFlightPlan?.line.geometry.coordinates.map((p) => [p[0], p[1]]) || [],
          ),
        );

        setError(false);
      } catch (error) {
        console.error(error);
        handlePlanInfoUpdate({ minutes: '-', area: '-', photos: -1, bateries: -1 });
      }
    } else {
      handlePlanInfoUpdate({ minutes: '-', area: '-', photos: -1, bateries: -1 });
      setError(false);
    }
  }, [currentMission, missionPoints, flightParams, dispatch]);

  return (
    <MVectorLayer>
      <MPolygon
        color={LOT_COLOR}
        onLoadCenter 
        polygon={currentLot?.polygon?.geometry} 
        stroke={BLACK_LINE}
        height={1}
        zIndex={0}
      />
      <MPolygon
        color={MISSION_POLYGON_COLOR}
        stroke={BLACK_LINE}
        height={3}
        isEditable
        polygon={missionPoints?.geometry}
        onEdit={(coordinates) => {
          dispatch(
            setMissionPolygon(
              polygon(coordinates),
            ),
          );
        }}
        zIndex={10}
      />
      <MPolyLine coordinates={polyPoints} zIndex={100}/>

      <MVectorLayer zIndex={1000}>
        {polyPoints && polyPoints.length > 0 && (
          <>
            <MPoint
              marker={MarkerIcon}
              // editable={editing}
              onEdit={(newCoords) => null}
              key={`${polyPoints[0][0]}-${polyPoints[0][1]}`}
              lat={polyPoints[0][1] as number}
              lng={polyPoints[0][0] as number}
              pointOrder="1"
            />
            <MPoint
              marker={MarkerIcon}
              // editable={editing}
              onEdit={(newCoords) => null}
              key={`${polyPoints[polyPoints.length - 1][0]}-${polyPoints[polyPoints.length - 1][1]}`}
              lat={polyPoints[polyPoints.length - 1][1] as number}
              lng={polyPoints[polyPoints.length - 1][0] as number}
              pointOrder="2"
            />
          </>
        )}
      </MVectorLayer>
    </MVectorLayer>
  );
}
