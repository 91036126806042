import { feature, Polygon } from '@turf/turf';
import Button from 'components/Button/Button';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import MPolygon from 'components/Map/Components/Polygon/MPolygon';
import MPolygonDraw from 'components/Map/Components/Polygon/MPolygonDraw';
import MPolygonEdit from 'components/Map/Components/Polygon/MPolygonEdit';
import MapContext from 'components/Map/Context/MapContext';
import AddLoteOverlay from 'components/Map/MapOverlays/AddLote/AddLotOverlay';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import { SubHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import { GeoJSONFeature } from 'models/GeoJSON';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleFileSelect } from 'services/loadKml';
import { makeOlPolygonFromGeoJson } from 'services/olUtils';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setPolygon } from 'state/slices/mapSlice';
import { BLACK_LINE, LOT_COLOR } from 'styles/variables';

import { ReactComponent as CalendarIcon } from '../../assets/icons/ico-calendar.svg';
import headerStyles from './../ProjectListPage/ProjectList.module.scss';

function AddLotMap() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentLotePolygon = useAppSelector((state) => state.map.currentPolygon);
  const selectedCampaign: Campaign | null = useAppSelector((state) => state.campaign.selectedCampaign);
  const addPolygon = useAppSelector((state) => state.map.addPolygon);
  const editPolygon = useAppSelector((state) => state.map.editPolygon);
  const map = useContext(MapContext);

  useEffect(() => {
    handleClickFindInMap();
  }, [currentLotePolygon]);

  function setNewPolygon(polygon: any) {
    dispatch(setPolygon(polygon.features[0] as GeoJSONFeature<Polygon>));
  }

  function handleClickFindInMap() {
    if (currentLotePolygon) {
      const olPolygon = makeOlPolygonFromGeoJson(currentLotePolygon);
      if (!olPolygon) return;
      const padding = 400;
      map?.getView().fit(olPolygon?.getExtent(), {
        padding: [padding, padding, padding, padding], // [0, 0, 0, 0].map(() => padding)
        duration: 600,
      });
    }
  }

  return (
    <>
      {/* <AddLoteOverlay
        onClickAddPolygon={handleClickAddPolygon}
        onClickLoad={handleClickLoad}
        onClickFindInMap={handleClickFindInMap}
        onClickErase={handleClickErase}
      /> */}
      {/* Functionality */}

      <MVectorLayer>
        <MPolygon polygon={currentLotePolygon?.geometry}
          color={LOT_COLOR}
          stroke={BLACK_LINE}
          height={1}
        />

        {addPolygon && !currentLotePolygon && (
          <MPolygonDraw
            onDrawEnd={(polygon) => {
              dispatch(setPolygon(polygon));
            }}
          ></MPolygonDraw>
        )}

        {currentLotePolygon && editPolygon && (
          <MPolygonEdit
            onChange={(polygon) => {
              dispatch(setPolygon(feature(polygon!)));
            }}
          />
        )}
      </MVectorLayer>
    </>
  );
}

export default AddLotMap;
