import { useDeleteCampaignMutation } from 'api/Vistaguay/CampaignAPI';
import { useRemoveProjectFromCampaignMutation } from 'api/Vistaguay/ProjectAPI';
import Button from 'components/Button/Button';
import { ErrorToaster, SuccesToaster } from 'components/Toaster/AppToaster';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../ProjectContextual.module.scss';

export default function TrashOption({ 
    campaignId,
    projectId 
}: { 
    campaignId: number,
    projectId: number 
}) {
    const { t } = useTranslation();

    const [
        removeProjectFromCampaign, 
        removeProjectFromCampaignResult,
    ] = useRemoveProjectFromCampaignMutation();

    useEffect(() => {
        if(removeProjectFromCampaignResult.error) {ErrorToaster(t('error-deleting'))}
    }, [removeProjectFromCampaignResult.error])

    useEffect(() => {
        if(removeProjectFromCampaignResult.isSuccess) {SuccesToaster(`${t('campaign')} ${t('success-deleted')}`)}
    }, [removeProjectFromCampaignResult.isSuccess])

    return (
        <div
            style={{ textAlign: 'center' }}
            className={style.body}
        >
        <h4 style={{ fontSize: '14px', fontWeight: '600' }}>
            {t('delete-project-title')}
        </h4>
        <p style={{ fontSize: '12px' }}>
            {t('delete-task-text')}
        </p>

        <div className={style.contextualControls}>
            <Button
                onClick={() => null}
                text='Volver'
                variant={'NEUTRAL-GRAY'}
                style={{ fontSize: '12px' }}
                height='30px'
            />
            <Button
                onClick={() => removeProjectFromCampaign({campaignId, projectId})}
                text={t('errase')}
                variant={'ERROR'}
                style={{ fontSize: '12px' }}
                height='30px'
                isLoading={removeProjectFromCampaignResult.isLoading}
                disabled={removeProjectFromCampaignResult.isLoading}
            />
        </div>
                
        </div>
    );
}
