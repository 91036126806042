import argFlagPath from 'assets/flags/ico-flag-arg.svg';
import brasilFlagPath from 'assets/flags/ico-flag-brasil.svg';
import chileflagPath from 'assets/flags/ico-flag-chile.svg';
import colombiaFlagPath from 'assets/flags/ico-flag-colombia.svg';
import mexicoFlagPath from 'assets/flags/ico-flag-mexico.svg';
import spainFlagPath from 'assets/flags/ico-flag-spain.svg';
import uruguayFlagPath from 'assets/flags/ico-flag-uruguay.svg';
import usaFlagPath from 'assets/flags/ico-flag-usa.svg';
import { t } from 'i18next';
import React from 'react';

export interface ICountries {
  id: number,
  name: string,
  shortName: string,
  prefix: string,
  flag: string
}

export interface ILanguage {
  id: number,
  label: string,
  code: 'en' | 'es',
  iconPath: string
}

export interface Option {
  value: number,
  label: string
}

const Countries: ICountries[] = [
  {
    id: 1,
    name: 'México',
    shortName: 'mx',
    prefix: '+52',
    flag: mexicoFlagPath
  },
  {
    id: 1,
    name: 'Argentina',
    shortName: 'arg',
    prefix: '+54',
    flag: argFlagPath
  },
  {
    id: 1,
    name: 'Brasil',
    shortName: 'br',
    prefix: '+55',
    flag: brasilFlagPath
  },
  {
    id: 1,
    name: 'Chile',
    shortName: 'cl',
    prefix: '+56',
    flag: chileflagPath
  },
  {
    id: 1,
    name: 'Colombia',
    shortName: 'cl',
    prefix: '+57',
    flag: colombiaFlagPath
  },
  {
    id: 1,
    name: 'Uruguay',
    shortName: 'ur',
    prefix: '+598',
    flag: uruguayFlagPath
  },
  {
    id: 1,
    name: 'USA',
    shortName: 'usa',
    prefix: '+1',
    flag: usaFlagPath
  }
]

const Languages: ILanguage[] = [
  {
    id: 1, 
    label: t('spanish'),
    code: 'es',
    iconPath: spainFlagPath
  },
  {
    id: 2,
    label: t('english'),
    code: 'en',
    iconPath: usaFlagPath
  }
]

const Industries: Option[] = [
  {
    value: 1, 
    label: t('agro-industry'),
  },
]

export { Countries, Languages, Industries }