import { useGetPricesQuery } from 'api/Vistaguay/PricesAPI';
import { ReactComponent as AuditoryIcon } from 'assets/icons/360auditory.svg';
import { ReactComponent as AltimetryIcon } from 'assets/icons/altimetry-icon.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/checkcircle-icon.svg';
import { ReactComponent as CreditIcon } from 'assets/icons/coin-icon.svg';
import { ReactComponent as PlantCountIcon } from 'assets/icons/Cultivo-comodin.svg';
import { ReactComponent as BackIcon } from 'assets/icons/ico-back.svg'
import { ReactComponent as ProyectIcon } from 'assets/icons/ico-folder.svg';
import { ReactComponent as MalezasIcon } from 'assets/icons/malezaIcon.svg';
import { ReactComponent as MinusCircleIcon } from 'assets/icons/minuscircle-icon.svg';
import { ReactComponent as OrtomosaicoIcon } from 'assets/icons/Mosaico-comodin.svg';
import { ReactComponent as PlusCircleIcon } from 'assets/icons/pluscricle-icon.svg';
import { ReactComponent as PrescriptionMapIcon } from 'assets/icons/prescriptionmap-icon.svg';
import { ReactComponent as SeederIcon } from 'assets/icons/seeder-icon.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/shareIcon.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/support-icon.svg';
import { ReactComponent as UserCircleIcon } from 'assets/icons/user-icon.svg';
import { ReactComponent as VegetationIndexIcon } from 'assets/icons/vegetatibeindex-icon.svg';
import { ReactComponent as VolareIcon } from 'assets/icons/volareIcon.svg';
import Button from 'components/Button/Button';
import BuyCredits from 'components/Credits/BuyCredits';
import { Input } from 'components/Inputs/Input';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import style from './Pricing.module.scss';
export function PricingTable(props: { scrollToAsk: () => void }) {
  const [mosaics, setMosaics] = useState<number>(0);
  const [weeds, setWeeds] = useState<number>(0);
  const [plantCount, setPlantCount] = useState<number>(0);
  const [auditory, setAuditory] = useState<number>(0);
  const { data, isLoading } = useGetPricesQuery();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [vpack, setVpack] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    if (data) {
      const amount =
        mosaics * data.mosaic +
        weeds * data.weeds +
        plantCount * data.planting_quality +
        auditory * data.auditory;
      setTotalAmount(amount);
      console.log(
        mosaics * data.mosaic +
        weeds * data.weeds +
        plantCount * data.planting_quality +
        auditory * data.auditory,
      );
    }
  }, [mosaics, weeds, plantCount, auditory, data]);

  const handleOpenModal = (vpack: boolean) => {
    setVpack(vpack);
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
    setVpack(false);
  };

  return (
    <>
      <div className={style.header} id='top'>
        <motion.div
          whileHover={'hoverBack'}
          style={{ width: '100%' }}
          className={style.left}
        >
          <div
            style={{ display: 'flex', alignSelf: 'flex-start' }}
            onClick={() => {
              navigate('/');
            }}
            className={style.backContainer}
          >
            <motion.svg
              className={style.icon}
              variants={{
                hoverBack: {
                  x: -10,
                  transition: {
                    duration: 0.3,
                    repeat: Infinity,
                    repeatType: 'reverse',
                  },
                },
              }}
            >
              <BackIcon />
            </motion.svg>
            <p>Volver</p>
          </div>
        </motion.div>
        <h1>Arma el plan perfecto para vos</h1>
        <p>
          Nuestra plataforma funciona con creditos precargados validos por un anio. Podes cargar a
          medida que necesites o comprar un{' '}
          <strong>V-Pack con un {data?.vpackDiscount}% de descuento.</strong>
        </p>
        <p className={style.frequentlyAsked} onClick={props.scrollToAsk}>
          Preguntas frecuentes
        </p>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => handleCloseModal()}
        style={{
          overlay: { background: 'rgba(0, 0, 0, 0.308)' },
          content: { margin: '0px 400px' },
        }}
      >
        <BuyCredits vpack={vpack} amount={totalAmount} />
      </Modal>
      <table className={style.table}>
      <thead>
        <tr>
          <th style={{ borderBottom: '5px solid', borderColor: style.light, verticalAlign: 'top', textAlign: 'center', width: '200px',  border: 'none' }}></th>
          <th style={{color: style.verdeOscuro, verticalAlign: 'top', textAlign: 'center', width: '200px', border: 'none' }}>
            <h6>On demand</h6>
            <p>Agrega procesos para calcular tu plan</p>
            {!isLoading && data && <p className={style.price}>u$s {totalAmount}</p>}
          </th>
          <th style={{color: '#DCB34A', verticalAlign: 'top', textAlign: 'center', width: '200px' }}>
            <h6>V-Pack</h6>
            <p>Nuestro recomendado y con {data?.vpackDiscount}% off</p>
            <p className={style.price} style={{ color: '#DCB34A', textDecoration: 'line-through' }}>
              u$s {data && data.vpackPrice}
            </p>
            <p className={style.price}>
              u$s {data && data?.vpackPrice - (data?.vpackDiscount * data?.vpackPrice) / 100}
            </p>
          </th>
          
          <th style={{color: '#3351B5', verticalAlign: 'top', textAlign: 'center', width: '200px', border: 'none' }}>
            <h6>V-Business</h6>
            <p>Multiusuario mas plataforma a medida</p>
            <p className={style.price}>Consultar</p>
          </th>
        </tr>
        <tr>
    <td style={{ borderBottom: '5px solid', borderColor: 'rgb(230, 232, 232)', verticalAlign: 'top', textAlign: 'center', width: '200px' }}></td>
    <td style={{ borderBottom: '5px solid',borderColor: style.verdeOscuro, color: style.verdeOscuro, verticalAlign: 'top', textAlign: 'center', width: '200px' }}></td>
    <td style={{ borderBottom: '5px solid', borderColor: '#DCB34A', backgroundColor: '#DCB34A',color: 'white', verticalAlign: 'top', textAlign: 'center', width: '200px' }}>
      <p style={{fontWeight: 'bold'}}>40% OFF</p>
    </td>
    <td style={{ borderBottom: '5px solid',borderColor: '#3351B5', color: '#3351B5', verticalAlign: 'top', textAlign: 'center', width: '200px' }}></td>
  </tr>

      </thead>

        <tbody>
          <tr>
            <td style={{ width: '45%', border: 'none'  }}>
              <UserCircleIcon className={style.icon} />
              <div>
                <h6>Usuarios</h6>
              </div>
            </td>
            <td>1</td>
            <td>1</td>
            <td>Ilimitado</td>
          </tr>
          <tr>
            <td style={{ width: '50%', border: 'none' }}>
              <ProyectIcon className={style.icon} />
              <div>
                <h6>Proyectos</h6>
              </div>
            </td>
            <td>Ilimitado</td>
            <td>Ilimitado</td>
            <td>Ilimitado</td>
          </tr>
          <tr>
            <td style={{ width: 'auto', border: 'none' }}>
              <OrtomosaicoIcon className={style.icon} />
              <div>
                <h6>Ortomosaico</h6>
                <p>u$s {data?.mosaic} / mapa(maximo 1500 img)</p>
              </div>
            </td>
            <td className={style.selector} >
              <MinusCircleIcon
                className={mosaics <= 0 ? style.icondisabled : style.icon}
                onClick={() => (mosaics <= 0 ? setMosaics(0) : setMosaics(mosaics - 1))}
              />
              <Input
                className={style.input}
                onChange={(e) => setMosaics(Number(e.target.value))}
                placeholder='0'
                value={mosaics}
              />
              <PlusCircleIcon className={style.icon} onClick={() => setMosaics(mosaics + 1)} />
            </td>
            <td>50 Mapas</td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td style={{ width: 'auto', border: 'none' }}>
              <MalezasIcon className={style.icon} />
              <div>
                <h6>Control de malezas</h6>
                <p>u$s {data?.weeds} / ha (minimo 25ha)</p>
              </div>
            </td>
            <td className={style.selector}>
              <MinusCircleIcon
                className={weeds <= 0 ? style.icondisabled : style.icon}
                onClick={() => (weeds <= 0 ? setWeeds(0) : setWeeds(weeds - 1))}
              />
              <Input
                className={style.input}
                placeholder='0'
                value={weeds}
                onChange={(e) => setWeeds(Number(e.target.value))}
              />
              <PlusCircleIcon className={style.icon} onClick={() => setWeeds(weeds + 1)} />
            </td>
            <td>500 Ha</td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td  style={{ width: 'auto', border: 'none' }}>
              <PlantCountIcon className={style.icon}  />
              <div>
                <h6>Conteo de plantas</h6>
                <p>u$s {data?.planting_quality} / Imagen</p>
              </div>
            </td>
            <td className={style.selector}>
              <MinusCircleIcon
                className={plantCount <= 0 ? style.icondisabled : style.icon}
                onClick={() => (plantCount <= 0 ? setPlantCount(0) : setPlantCount(plantCount - 1))}
              />
              <Input
                className={style.input}
                placeholder='0'
                value={plantCount}
                onChange={(e) => setPlantCount(Number(e.target.value))}
              />
              <PlusCircleIcon
                className={style.icon}
                onClick={() => setPlantCount(plantCount + 1)}
              />
            </td>
            <td>400 Muestras</td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td  style={{ width: 'auto', border: 'none' }}>
              <AuditoryIcon className={style.icon} />
              <div>
                <h6>Auditoria 360</h6>
                <p>u$s {data?.auditory} / Imagen</p>
              </div>
            </td>
            <td className={style.selector}>
              <MinusCircleIcon
                className={auditory <= 0 ? style.icondisabled : style.icon}
                onClick={() => (auditory <= 0 ? setAuditory(0) : setAuditory(auditory - 1))}
              />
              <Input
                className={style.input}
                placeholder='0'
                value={auditory}
                onChange={(e) => setAuditory(Number(e.target.value))}
              />
              <PlusCircleIcon className={style.icon} onClick={() => setAuditory(auditory + 1)} />
            </td>
            <td>20 Fotos</td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td  style={{ width: 'auto', border: 'none' }}>
              <VolareIcon className={style.icon} />
              <div>
                <h6>App de missiones de vuelo</h6>
              </div>
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td  style={{ width: 'auto', border: 'none' }}>
              <VegetationIndexIcon className={style.icon}  style={{ width: 'auto', border: 'none' }} />
              <div>
                <h6>Indice de vegetacion</h6>
              </div>
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td  style={{ width: 'auto', border: 'none' }}>
              <AltimetryIcon className={style.icon}   style={{ width: 'auto', border: 'none' }}/>
              <div>
                <h6>Altimetria</h6>
              </div>
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td  style={{ width: 'auto', border: 'none' }}>
              <PrescriptionMapIcon className={style.icon} />
              <div>
                <h6>Mapa de prescripcion</h6>
              </div>
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td  style={{ width: 'auto', border: 'none' }}>
              <SeederIcon className={style.icon} />
              <div>
                <h6>Mapa de rendimiento</h6>
              </div>
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td  style={{ width: 'auto', border: 'none' }}>
              <ShareIcon className={style.icon} />
              <div>
                <h6>Exportar compartir</h6>
              </div>
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr>
            <td  style={{ width: 'auto', border: 'none' }}>
              <SupportIcon className={style.icon}  />
              <div>
                <h6>Soporte</h6>
              </div>
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
            <td>
              <CheckCircleIcon className={style.checkicon} />
            </td>
          </tr>
          <tr className={style.credits}>
            <td  style={{ width: 'auto', border: 'none' }}>
              <CreditIcon className={style.icon} />
              <div>
                <h6>Creditos</h6>
              </div>
            </td>
            <td>{totalAmount}</td>
            <td>{data && data.vpackPrice}</td>
            <td></td>
          </tr>
          <tr className={style.total}>
            <td>Monto a pagar</td>
            <td>u$s {totalAmount}</td>
            <td>u$s {data && data?.vpackPrice - (data?.vpackDiscount * data?.vpackPrice) / 100}</td>
            <td></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td>
              <div>
                <Button
                  disabled={totalAmount < 15}
                  onClick={() => handleOpenModal(false)}
                  text='Comprar'
                  variant='SUCCESS'
                />
              </div>
            </td>
            <td>
              <div>
                <Button text='Comprar' variant='SUCCESS' onClick={() => handleOpenModal(true)} />
              </div>
            </td>
            <td>
              <div>
                <Button text='Consultar' variant='NEUTRAL-GRAY' />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div style={{ marginTop: '50px' }}></div>
    </>
  );
}
