import sempahore from 'assets/sem.png';
import sempahoreYellow from 'assets/semAmarillo.png';
import sempahoreRed from 'assets/semRojo.png';
import sempahoreGreen from 'assets/semVerde.png';

import style from './CountTaskReport.module.scss';

interface ISemaphoreProp {
  type: string;
}
export const Semaphore = ({ type }: ISemaphoreProp) => {
  return (
    <>
      <img
        style={{ width: 'auto', maxHeight: 'fit-content' }}
        src={getSemaphore(type)}
        alt='semaphore'
        className={style.semaphoreImage}
      />
    </>
  );
};

const getSemaphore = (type: string) => {
  switch (type) {
    case 'red':
      return sempahoreRed;
    case 'yellow':
      return sempahoreYellow;
    case 'green':
      return sempahoreGreen;
    default:
      return sempahore;
  }
};

export const getSempahoreColor = (type: string, value: number) => {
  if (type === 'densidad') {
    if (value <= 6) {
      return 'green';
    } else if (value > 6 && value <= 11) {
      return 'yellow';
    } else if (value > 11) {
      return 'red';
    }
  }
  if (type === 'esparcimiento') {
    if (value <= 30) {
      return 'green';
    } else if (value > 30 && value <= 40) {
      return 'yellow';
    } else if (value > 40) {
      return 'red';
    }
  }
  if (type === 'cobertura') {
    if (value <= 50) {
      return 'green';
    } else if (value > 50 && value <= 65) {
      return 'yellow';
    } else if (value > 65) {
      return 'red';
    }
  }
  return 'red';
};
