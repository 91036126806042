import {createSlice, PayloadAction} from '@reduxjs/toolkit'


export interface UserState {
    name: string,
    email: string,
}

const initialState: UserState = {
    name: '',
    email:'',
}


const reducer = {
    setName: (state: UserState, action: PayloadAction<string>) => {
        state.name = action.payload
    }
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers:reducer,
})

export const {setName} = userSlice.actions

export default userSlice.reducer
