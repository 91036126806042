import { useGetPricesQuery } from 'api/Vistaguay/PricesAPI';
import { ReactComponent as AuditoryIcon } from 'assets/icons/360auditory.svg';
import { ReactComponent as CreditCalculatorIcon } from 'assets/icons/credit-calculator.svg';
import { ReactComponent as PlantCountIcon } from 'assets/icons/Cultivo-comodin.svg';
import { ReactComponent as CreditsIcon } from 'assets/icons/ico-credits-outline.svg';
import { ReactComponent as MalezasIcon } from 'assets/icons/malezaIcon.svg';
import { ReactComponent as MosaicIcon } from 'assets/icons/Mosaico-comodin.svg';
import Button from 'components/Button/Button';
import { Input } from 'components/Inputs/Input';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './Credits.module.scss';
import { GoToProps } from './CreditTab';
interface Props {
    goTo: ({page, quantity, vpack}: GoToProps) => void;
}

export default function CreditCalculator({ goTo }: Props) {
    const { t } = useTranslation();

    const [mosaics, setMosaics] = useState<number>(0);
    const [weeds, setWeeds] = useState<number>(0);
    const [plantCount, setPlantCount] = useState<number>(0);
    const [auditory, setAuditory] = useState<number>(0);
    const {data, isLoading} = useGetPricesQuery()
    const [totalAmount,setTotalAmount] = useState<number>(0)

    useEffect(() => {
        if (!data) return
        const amount = mosaics * data.mosaic + weeds * data.weeds + plantCount * data.planting_quality + auditory * data.auditory
        setTotalAmount(amount)
    }, [mosaics, weeds, plantCount, auditory])
    
    return (
        <div>
            {/* HEADER */}
            <div
                style={{
                    display: 'flex', 
                    alignItems: 'center',
                    gap: '10px',
                    borderBottom: '1px solid gray',
                    paddingBottom: '10px'
                }}
            >
                <CreditCalculatorIcon style={{ width: '30px' }} />
                
                <h5>{t('calculator')}</h5>
            </div>

            {/* MOSAIC */}
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '70% 30%', 
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    borderBottom: '1px solid gray',
                }}
            >
                <div 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: '5px' 
                    }}
                >
                    <MosaicIcon 
                        style={{ 
                            width: '30px', 
                            height: 'auto', 
                            fill: styles.darkdisabled 
                        }} 
                    />
                    
                    <div 
                        style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 0 
                        }}
                    >
                        <p
                        style={{ 
                            fontSize: '14px', 
                            fontWeight: 500
                        }}
                        >
                            {t('mosaic')}
                        </p>

                        <p 
                        style={{ 
                            fontSize: '9px', 
                            fontWeight: 400 
                        }}
                        >
                            u$s {isLoading ? '...' : data?.mosaic} / mapa(maximo 1500 img)
                        </p>
                    </div>
                </div>

                <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                    }}
                >
                    <input
                        type='number'
                        min={5}
                        max={3000}
                        value={mosaics}
                        onChange={(e) => {setMosaics(+e.target.value)}}
                        style={{
                            outline: 'none',
                            border: '1px solid',
                            borderColor: styles.verdeVistaguay,
                            width: '100%',
                            borderRadius: '4px',
                            padding: '4px 6px'
                        }}
                    />

                    <p 
                        style={{
                            fontSize:'16px',
                            fontWeight:'500',
                            color:styles.verdeVistaguay,
                        }}
                    >
                        {data && mosaics * data?.mosaic}
                    </p>
                </div>
            </div>

            {/* MALEZA */}
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '70% 30%', 
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    borderBottom: '1px solid gray',
                }}
            >
                <div 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: '5px' 
                    }}
                >
                    <MalezasIcon 
                        style={{ 
                            width: '30px', 
                            height: 'auto', 
                            fill: styles.darkdisabled 
                        }} 
                    />
                    
                    <div 
                        style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 0 
                        }}
                    >
                        <p
                        style={{ 
                            fontSize: '14px', 
                            fontWeight: 500
                        }}
                        >
                            {t('plague')}
                        </p>

                        <p 
                        style={{ 
                            fontSize: '9px', 
                            fontWeight: 400 
                        }}
                        >
                            u$s {isLoading ? '...' : data?.weeds} / ha(minimo 25 ha)
                        </p>
                    </div>
                </div>

                <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                    }}
                >
                    <input
                        type='number'
                        min={5}
                        max={3000}
                        value={weeds}
                        onChange={(e) => {setWeeds(+e.target.value)}}
                        style={{
                            outline: 'none',
                            border: '1px solid',
                            borderColor: styles.verdeVistaguay,
                            width: '100%',
                            borderRadius: '4px',
                            padding: '4px 6px'
                        }}
                    />

                    <p 
                        style={{
                            fontSize:'16px',
                            fontWeight:'500',
                            color:styles.verdeVistaguay,
                        }}
                    >
                        {data && weeds * data?.weeds}
                    </p>
                </div>
            </div>

            {/* PLANTING */}
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '70% 30%', 
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    borderBottom: '1px solid gray',
                }}
            >
                <div 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: '5px' 
                    }}
                >
                    <PlantCountIcon 
                        style={{ 
                            width: '30px', 
                            height: 'auto', 
                            fill: styles.darkdisabled 
                        }} 
                    />
                    
                    <div 
                        style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 0 
                        }}
                    >
                        <p
                        style={{ 
                            fontSize: '14px', 
                            fontWeight: 500
                        }}
                        >
                            {t('planting-quality')}
                        </p>

                        <p 
                        style={{ 
                            fontSize: '9px', 
                            fontWeight: 400 
                        }}
                        >
                            u$s {isLoading ? '...' : data?.planting_quality} / imagen
                        </p>
                    </div>
                </div>

                <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                    }}
                >
                    <input
                        type='number'
                        min={5}
                        max={3000}
                        value={plantCount}
                        onChange={(e) => {setPlantCount(+e.target.value)}}
                        style={{
                            outline: 'none',
                            border: '1px solid',
                            borderColor: styles.verdeVistaguay,
                            width: '100%',
                            borderRadius: '4px',
                            padding: '4px 6px'
                        }}
                    />

                    <p 
                        style={{
                            fontSize:'16px',
                            fontWeight:'500',
                            color:styles.verdeVistaguay,
                        }}
                    >
                        {data && plantCount * data?.planting_quality}
                    </p>
                </div>
            </div>

            {/* AUDIT */}
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '70% 30%', 
                    paddingBottom: '10px',
                    paddingTop: '10px'
                }}
            >
                <div 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: '5px' 
                    }}
                >
                    <AuditoryIcon 
                        style={{ 
                            width: '30px', 
                            height: 'auto', 
                            fill: styles.darkdisabled 
                        }} 
                    />
                    
                    <div 
                        style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 0 
                        }}
                    >
                        <p
                        style={{ 
                            fontSize: '14px', 
                            fontWeight: 500
                        }}
                        >
                            {t('audit')}
                        </p>

                        <p 
                        style={{ 
                            fontSize: '9px', 
                            fontWeight: 400 
                        }}
                        >
                            u$s {isLoading ? '...' : data?.auditory}/ imagen
                        </p>
                    </div>
                </div>

                <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                    }}
                >
                    <input
                        type='number'
                        min={5}
                        max={3000}
                        value={auditory}
                        onChange={(e) => {setAuditory(+e.target.value)}}
                        style={{
                            outline: 'none',
                            border: '1px solid',
                            borderColor: styles.verdeVistaguay,
                            width: '100%',
                            borderRadius: '4px',
                            padding: '4px 6px'
                        }}
                    />

                    <p 
                        style={{
                            fontSize:'16px',
                            fontWeight:'500',
                            color:styles.verdeVistaguay,
                        }}
                    >
                        {data && auditory * data?.auditory}
                    </p>
                </div>
            </div>

            {/* CREDITS */}
            <div
                style={{
                padding: '10px 10px',
                display: 'flex',
                background: styles.verdeVistaguay,
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '8px',
                color: 'white'
                }}
            >
                <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px'
                }}
                >
                <CreditsIcon    
                    style={{ 
                    width: '20px', 
                    height: 'auto', 
                    stroke: 'white' 
                    }} 
                />

                <p style={{ fontSize: '14px', fontWeight: 500 }}>
                    {t('credits')}
                </p>
                </div>

                <p style={{ fontSize: '14px'}}>
                    {totalAmount}
                </p>
            </div>

            {/* PAYMENT AMOUNT */}
            <div 
                style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center', 
                marginTop: '10px',
                marginBottom: '10px' 
                }}
            >
                <p 
                    style={{ fontSize: '14px', fontWeight: 500 }}
                >
                    {t('total-payment')}
                </p>

                <p style={{ fontSize: '14px'}}>
                    u$s {totalAmount}
                </p>
            </div>

            {/* FOOTER BUTTONS */}
            <div 
                style={{
                    paddingTop: '10px',
                    justifyContent: 'space-between', 
                    display: 'flex',
                    borderTop: '1px solid gray', 
                    gap: '80px'
                }}
            >
                <Button 
                    height='30px'
                    style={{fontSize: '12px'}}
                    text={t('back')}
                    variant='NEUTRAL-GRAY'
                    onClick={() => {goTo({page: 'home'})}}
                />

                <Button 
                    height='30px'
                    style={{fontSize: '12px'}}
                    text={t('purchase')} 
                    variant='SUCCESS'
                    onClick={() => { goTo({page: 'buy', quantity: totalAmount, vpack: false})}}
                />
            </div>
        </div>
    );
}
