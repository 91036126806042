import { ReactComponent as AddImagIcon } from 'assets/icons/addphotoIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ico-delete.svg';
import { ReactComponent as ImgIcon } from 'assets/icons/ico-image.svg';
import clsx from 'classnames'
import Alert from 'components/Alert/Alert';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import exifr from 'exifr';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { addImgPoints, removeImgPoints, setHoverImg } from 'state/slices/mapSlice';
import { setImageFiles } from 'state/slices/pqFormSlice';

import style from '../../AddTask.module.scss';
import { ErrorList, ErrorProps } from '../AddPlantCount';

interface StepProps {
  nextStepTouched: boolean;
  currentStep: string | number;
  handleError: (error: boolean) => void;
  fileList: File[];
  handleUploadImages: (files: File[]) => void;
}

export default function StepImageUpload({
  nextStepTouched,
  currentStep,
  handleError,
  fileList, 
  handleUploadImages
}: StepProps) {
  return (
    <div className={clsx(style.bodyContent)}>
      <div className={style.input}>
        <FileUploadMultiple
          nextStepTouched={nextStepTouched}
          currentStep={currentStep}
          handleError={handleError}
          fileList={fileList}
          handleUploadImages={handleUploadImages}
        />
      </div>
    </div>
  );
}

function FileUploadMultiple({
  nextStepTouched,
  currentStep,
  handleError,
  handleUploadImages, 
  fileList
}: StepProps) {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [errorsList, setErrorList] = useState<ErrorList[]>([]);

  const removeDuplicates = (arr: File[]) => {
    return arr.filter((value, index) => {
      const _value = JSON.stringify(value.name);
      return (
        index ===
        arr.findIndex((obj) => {
          return JSON.stringify(obj.name) === _value;
        })
      );
    });
  };

  useEffect(() => {
    if (fileList.length >= 0) {
      fileList.map((file: File, i) => {
        const mimeType = file.type;
        if(mimeType != 'image/png' && mimeType != 'image/jpeg') {
          ErrorToaster(`El formato ${file.type} no esta permitido`)
          const list = fileList
          list.splice(i,1)
          handleUploadImages(list);
          return
        }
        exifr.parse(file).then((res) => {
          if (res?.latitude && res?.longitude && file.size / 1024 / 1024 <= 50 && fileList.length <= 50) {
            const point = {
              name: file.name,
              lat: [res.latitude],
              lng: [res.longitude],
            };
            dispatch(addImgPoints(point));
            return
          } else {
            if(file.size / 1024 / 1024 > 50) ErrorToaster(`El archivo ${file.name} es muy pesado`);
            if(fileList.length > 50) ErrorToaster('Solo se permiten 50 imagenes');
            if(!res?.latitude || !res?.longitude) ErrorToaster(`El archivo ${file.name} no tiene georeferencia`);
            const list = fileList
            list.splice(i,1)
            handleUploadImages(list);
            return
          }
        });
      });
      handleUploadImages(fileList);
    } 
  }, [fileList, dispatch]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const flieListFromWeb = e.target.files;
    const file = flieListFromWeb ? Array.from(flieListFromWeb) : [];
    const list = file.concat(file, fileList);
    handleUploadImages(removeDuplicates(list));
  };

  const handleDeleteFile = (i: number) => {
    const newFiles = [...fileList];
    newFiles.splice(i, 1)
    handleUploadImages(newFiles);
    dispatch(removeImgPoints(i));
  };

  const onButtonClick = () => {
    if (inputFile.current == null) return;
    inputFile.current.click();
  };

  useEffect(() => {
    if(currentStep == 3) {
      const errors: ErrorList[] = [];
      let error = true; 
    
      if (fileList.length == 0) {
        errors.push({ message: 'Debe subir al menos una foto', propName: 'emptyFileList' });
      } 
      
      error = errors.length > 0 ?? true;
    
      setErrorList(errors);
      handleError(error);
    }
  }, [fileList])

  return (
    <div>
      <input
        id='fileupload'
        type='file'
        onChange={handleFileChange}
        hidden
        multiple
        ref={inputFile}
        value={''}
        accept='image/png,image/jpeg,image/JPG'
        style={{ visibility: 'hidden' }}
      />

      <div className={style.uploadImageHeader}>
        <p>
          {t('uploaded-images')}
          <span style={{ color: style.gris, marginLeft: '5px' }}>[{fileList.length < 0 ? '0' : fileList.length}]</span>
        </p>
        <div className={style.uploadImageBtn} onClick={onButtonClick}>
          <AddImagIcon stroke={style.azul} />
        </div>
      </div>

      {
        nextStepTouched && errorsList.map((error, index) => {
          if (error.propName === 'emptyFileList') {
            return <Alert key={index} type='error' text={error.message} />;
          } else {
            return null; // Return null if the condition is not met
          }
        })
      }
   
      <ul className={style.upload}>
        {fileList.length > 0 && fileList.map((file, i) => (
          <li
            onMouseOver={() => dispatch(setHoverImg(i))}
            onMouseOut={() => dispatch(setHoverImg(i))}
            className={style.uploaditem}
            key={i}
          >
            <div className={style.top}>
              <div className={style.info}>
                <ImgIcon className={style.icon} width={30} fill='none' stroke='black'/>
                <p>{file.name}</p>
              </div>
              <DeleteIcon
                className={style.delete}
                onClick={() => {
                  handleDeleteFile(i);
                }}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
