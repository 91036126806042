import Project from 'models/Project';

import { VistaguayApi, VistaguayApiResponse } from './VistaguayAPI';

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Projects'],
});

const projectAPI = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<Project[], boolean>({
      query: () => 'projects',
      providesTags: ['Projects'],
    }),
    getProject: builder.query<Project, number>({
      query: (id: number) => `projects/${id}`,
      providesTags: ['Projects'],
    }),
    searchProjects: builder.mutation<Project[], string>({
      query: (search: string) => ({
        method: 'POST',
        body: { search },
        url: 'projects/search',
        // params: { search },
      }),
    }),
    addProject: builder.mutation<Project, Partial<Project>>({
      query: (body: Partial<Project>) => ({
        url: 'projects',
        method: 'POST',
        body,
      }),
      // invalidate the query when the mutation is successful
      invalidatesTags: ['Projects'],
    }),
    editProject: builder.mutation<Project, Partial<Project>>({
      query: (body: Partial<Project>) => ({
        url: `projects/${body.proyectoId}`,
        method: 'PATCH',
        body,
      }),
      // invalidate the query when the mutation is successful
      invalidatesTags: ['Projects'],
    }),
    addProjectToCampaign: builder.mutation<Project, { projectId: number; campaignId: number }>({
      query: ({ projectId, campaignId }) => ({
        url: `projects/${projectId}/campaigns/${campaignId}`,
        method: 'POST',
      }),
      // invalidate the query when the mutation is successful
      invalidatesTags: ['Projects'],
    }),
    removeProjectFromCampaign: builder.mutation<Project, { projectId: number; campaignId: number }>(
      {
        query: ({ projectId, campaignId }) => ({
          url: `projects/${projectId}/campaigns/${campaignId}`,
          method: 'DELETE',
        }),
        // invalidate the query when the mutation is successful
        invalidatesTags: ['Projects'],
      },
    ),
    patchProject: builder.mutation({
      query: ({projectId, body}: {projectId: number,  body: {nombre?: string, descripcion?:string, color?:string}}) => ({
        url: `projects/${projectId}`,
        method: 'PATCH',
        body: body,
      }),
    })
  }),
});

export const {
  useGetProjectsQuery,
  useAddProjectMutation,
  useSearchProjectsMutation,
  useGetProjectQuery,
  useAddProjectToCampaignMutation,
  useRemoveProjectFromCampaignMutation,
  useEditProjectMutation,
  usePatchProjectMutation
} = projectAPI;
