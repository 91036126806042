// #region React & Hooks
// #endregion
// #region Icons
import { ReactComponent as InfoIcon } from 'assets/icons/ico-info.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashIcon.svg';
import Project from 'models/Project';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import InfoOption from './Options/InfoOption';
import TrashOption from './Options/TrashOption';
// #endregion
// #region Styles
import styles from './ProjectContextual.module.scss';
// #endregion

export default function ProjectContextual ({
    campaignId,
    projectId,
    project
}:{
    campaignId: number,
    projectId: number,
    project: Project  
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [option, setOption] = useState(0);

    const options = [
        <InfoOption key={0} project={project}/>,
        <TrashOption key={1} campaignId={campaignId} projectId={projectId}/>
    ];

    return (
        <div className={styles.contextualMenu}>
            <div className={styles.contextualContent}>
                {options[option]}
            </div>

            <div className={styles.tabBar}>
                <div className={styles.tabBarIcons}>
                    <div
                        className={option == 0 ? styles.bubble : styles.bubbleOff}
                        onClick={() => {setOption(0)}}
                    >
                        <InfoIcon className={styles.tabicon} />
                    </div>
                    <div
                        className={option == 1 ? styles.bubble : styles.bubbleOff}
                        // onClick={() => {setOption(1)}}
                    >
                        <TrashIcon 
                            className={styles.tabicon} 
                            height={30}
                            fill={styles.lightdisabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}