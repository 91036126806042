// #region React
// #endregion
// #region Icons
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowIcon.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/ico-close.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/ico-folder.svg';
import { ReactComponent as LotIcon } from 'assets/icons/polygonsquare.svg';
// #endregion
// #region Components
import { Skeleton } from 'components/Skeleton/Skeleton';
// #endregion
// #region Styles
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from '../../assets/icons/ico-calendar.svg';
import style from './SubHeader.module.scss';
// #endregion

interface Props {
  noBack?: boolean;
  backTo?: string;
  backText?: string;
  sx?: React.CSSProperties;
  children?: React.ReactNode;
}

interface DefaultProps {
  title?: string;
  subtitle?: string;
  Icon: React.ReactNode;
}

interface PropsLotProjectItem {
  backTo?: string;
  sx?: React.CSSProperties;
  campaignName: string | undefined;
  projectName: string | undefined;
  projectColor: string | undefined;
  lotName?: string | undefined;
  backText?: string;
}

interface PropsLotProjectItem2 {
  backTo?: string;
  sx?: React.CSSProperties;
  projectName: string | undefined;
  projectColor: string | undefined;
  campaignName: string | undefined;
  backText?: string;
}

interface TaskHeaderProps {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  handleCancel?: () => void,
  showCancelBtn?: boolean
}

export function DefaultItem({ title, Icon, subtitle }: DefaultProps) {
  return (
    <div className={style.headertitle}>
      {Icon}
      {
        title ?
          <h2 title={title}>{title}</h2>
          :
          <Skeleton sx={{ width: '40%', height: '16px', borderRadius: '5px' }} />
      }
      {
        subtitle && (
          <p>{subtitle}</p>
        )
      }
    </div>
  );
}

export function TaskHeader({ 
  icon, 
  title,
  subtitle,
  showCancelBtn = true,
  handleCancel,
}: TaskHeaderProps) {
  return (
    <div className={style.taskheader}>
      <div className={style.taskicon}>{icon}</div>
      <div className={style.taskinfo}>
        <div>
          <h2 style={{fontSize: subtitle ? '1rem' : '1.3rem'}}>
            {title}
          </h2>
          { subtitle && 
            <span>
              {subtitle}
            </span>
          }
        </div>

        { showCancelBtn && handleCancel && (
          <div 
            className={style.cancelBtnWrapper}
            onClick={() => handleCancel()}
          >
            <CancelIcon style={{fill: 'white', height: '15px'}}/>
          </div>
        )}
      </div>
    </div>
  );
}

export function SlimHeader({
  projectName,
  projectColor,
  lotName,
  sx,
  backTo,
  backText
}: PropsLotProjectItem) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={style.SlimHeader}>
      <div style={sx} className={style.LotProject}>
        <div className={style.Project}>
          <FolderIcon
            style={{
              fill: projectColor,
              height: '25px',
              width: '25px',
              // stroke: 'white',
              // strokeWidth: '4px',
            }}
          />
          <h2 title={projectName}>{projectName}</h2>
        </div>
        <ArrowIcon
          className={style.ArrowIcon}
          style={{
            fill: style.gris,
            height: '25px',
            width: '25px',
            strokeWidth: '4px',
          }}
        />
        <div className={style.Lot}>
          <LotIcon
            style={{
              fill: style.verdeOscuro,
              minHeight: '25px',
              maxWidth: '25px',
              strokeWidth: '4px',
            }}
          />
          <h2 title={lotName}>{lotName}</h2>
        </div>
      </div>
      {/* <Button
        text={backText ? backText : t('cancelar-conteo')}
        variant='ERROR'
        style={{ padding: '0px', fontSize: '10px', width: '30%', margin: '0px', color: style.light }}
        onClick={() => navigate(backTo ? backTo : '/projects')}
      /> */}
    </div>
  );
}

export function SlimHeader2({
  projectName,
  projectColor,
  campaignName,
  sx,
  backTo,
  backText
}: PropsLotProjectItem2) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={style.SlimHeader}>
      <div style={sx} className={style.LotProject}>
        <div className={style.Project}>
          <CalendarIcon
            style={{
              height: '25px',
              width: '25px',
            }}
          />
          <h2 title={campaignName}>{campaignName}</h2>
        </div>
        <ArrowIcon
          className={style.ArrowIcon}
          style={{
            fill: style.gris,
            height: '25px',
            width: '25px',
            strokeWidth: '4px',
          }}
        />
        <div className={style.Lot}>
          <FolderIcon
            style={{
              fill: projectColor,
              minHeight: '25px',
              maxWidth: '25px',
            }}
          />
          <h2 title={projectName}>{projectName}</h2>
        </div>
      </div>
      {/* <Button
        text={backText ? backText : t('cancelar-conteo')}
        variant='ERROR'
        style={{ padding: '0px', fontSize: '10px', width: '30%', margin: '0px', color: style.light }}
        onClick={() => navigate(backTo ? backTo : '/projects')}
      /> */}
    </div>
  );
}

export function LotProjectItem({ campaignName, projectName, projectColor, lotName, sx }: PropsLotProjectItem) {
  return (
    <div style={sx} className={style.LotProject}>
      <div className={style.Campaign}>
        <CalendarIcon
          style={{
            height: '25px',
            width: '25px',
          }}
        />
        <h2 title={campaignName}>{campaignName}</h2>
      </div>

      <ArrowIcon
        style={{
          fill: style.gris,
          height: '25px',
          width: '25px',
          strokeWidth: '4px',
        }}
      />

      <div className={style.Project}>
        <FolderIcon
          style={{
            fill: projectColor,
            height: '30px',
            width: '30px',
          }}
        />
        <h2 title={projectName}>{projectName}</h2>
      </div>

      {lotName && (
        <>
          <ArrowIcon
            style={{
              fill: '#b2b2b2',
              height: '25px',
              width: '25px',
            }}
          />

          <div className={style.Lot}>
            <LotIcon
              style={{
                fill: style.verdeOscuro,
                minHeight: '25px',
                maxWidth: '25px',
              }}
            />
            <h2 title={lotName}>{lotName}</h2>
          </div>
        </>
      )}

    </div>
  );
}

export function SubHeader({ noBack = false, backTo = '', backText, children, sx }: Props) {
  const navigate = useNavigate();

  return (
    <div style={sx} className={style.header}>
      <motion.div
        whileHover={'hoverBack'}
        style={{ width: noBack ? '100%' : '' }}
        className={style.left}
      >
        {/* {backTo && (
          <div
            onClick={() => {
              navigate(backTo);
            }}
            className={style.backContainer}
          >
            <motion.svg
              className={style.icon}
              variants={{
                hoverBack: {
                  x: -3,
                  transition: {
                    duration: 0.2,
                    repeat: Infinity,
                    repeatType: 'reverse',
                  },
                },
              }}
            >
              <BackIcon />
            </motion.svg>
            <p>{backText}</p>
          </div>
        )} */}
        {children}
      </motion.div>
      {/* {!backTo && (
        <div className={style.projectContextualContainer}>
          <Dots
            style={{
              fill: 'black',
            }}
            className={style.icon}
          />
        </div>
      )} */}
    </div>
  );
}
