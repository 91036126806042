// #region React & Hooks
// #endregion
import { Polygon } from '@turf/turf';
import { useAddLotMutation } from 'api/Vistaguay/LotAPI';
import { ReactComponent as CutIcon } from 'assets/icons/ico-cut.svg';
import { ReactComponent as EraseIcon } from 'assets/icons/ico-erase.svg';
import { ReactComponent as NewPolygonIcon } from 'assets/icons/ico-polygon.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/ico-upload.svg';
import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import MapContext from 'components/Map/Context/MapContext';
// #endregion
// #region Layout & Components
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import { SubHeader } from 'layout/Sidebar/SubHeader';
// #endregion
// #region Models
import { Campaign } from 'models/Campaign';
import { GeoJSONFeature } from 'models/GeoJSON';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { handleFileSelect } from 'services/loadKml';
import { makeOlPolygonFromGeoJson } from 'services/olUtils';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setPolygon, toggleAddPolygon, toggleEditPolygon } from 'state/slices/mapSlice';

// #region Icons
import { ReactComponent as CalendarIcon } from '../../assets/icons/ico-calendar.svg';
import { ReactComponent as LotIcon } from '../../assets/icons/ico-polygon-blue.svg';
// #endregion
// #region Styles
import headerStyles from './../ProjectListPage/ProjectList.module.scss';
import styles from './AddLot.module.scss'
// #endregion

function AddLote() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [lotName, setLotName] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // const [addPolygon, setAddPolygon] = useState(false);
  
  const currentLotePolygon = useAppSelector((state) => state.map.currentPolygon);
  const selectedCampaign: Campaign | null = useAppSelector((state) => state.campaign.selectedCampaign);
  const polygon = useAppSelector((state) => state.map.currentPolygon);

  const map = useContext(MapContext);

  const [projectId, setProjectId] = useState(Number(searchParams.get('projectId')) || -1);

  const [
    addLot,
    { 
      isLoading: isAddLotLoading, 
      isError: isAddLotError, 
      isSuccess: isAddLotSuccess 
    },
  ] = useAddLotMutation();

  function handleAddPolygon() {
    dispatch(toggleAddPolygon(true));
  }

  function handleEditPolygon () {
    dispatch(toggleEditPolygon(true));
  }

  function setNewPolygon(polygon: any) {
    dispatch(setPolygon(polygon.features[0] as GeoJSONFeature<Polygon>));
  }

  function handleClickLoad() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.kml';
    fileInput.addEventListener('change', (e) => {
      handleFileSelect(e, setNewPolygon);
    });
    fileInput.click();
  }

  function handleClickFindInMap() {
    if (currentLotePolygon) {
      const olPolygon = makeOlPolygonFromGeoJson(currentLotePolygon);
      if (!olPolygon) return;
      const padding = 400;
      map?.getView().fit(olPolygon?.getExtent(), {
        padding: [padding, padding, padding, padding], // [0, 0, 0, 0].map(() => padding)
        duration: 600,
      });
    }
  }

  function handleClickErase() {
    dispatch(setPolygon(null));
    dispatch(toggleAddPolygon(false));
  }

  function handleAsign() {
    if (projectId === -1) {
      setError('Debes seleccionar un proyecto');
      return;
    }else if (!lotName) {
      setError('Debes ingresar un nombre de lote');
      return;
    }else if (!polygon) {
      setError('Debes dibujar un polígono');
      return;
    } else {
      setError(null);
    }

    addLot({
      lot: {
        name: `Lote ${lotName}`,
        polygon: polygon,
        prescription: null,
      },
      projectId: Number(projectId),
    });
  }

  useEffect(() => {
    // 1. Clean preview polygon states at component mount
    dispatch(setPolygon(null));

    if (!projectId) {
      navigate('/projects')
    }
  }, [])

  useEffect(() => {
    handleClickFindInMap();
  }, [currentLotePolygon]);

  useEffect(() => {
    if(isAddLotSuccess) {
      setLotName('')
      setSuccessAlert(true)
      
      const timer = setTimeout(() => {
        setSuccessAlert(false);
        navigate(-1);
      }, 2000);
  
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isAddLotSuccess])

  return(
    <>
      <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
        <SubHeader sx={{ padding: '0px' }} noBack={true}>
          <div className={headerStyles.header}>
            <div className={headerStyles.headerContent}>
              <CalendarIcon height={25}/>

              <div className={headerStyles.headerText}>
                <div className={headerStyles.titleBox}>
                  <h2 className={headerStyles.title}>{selectedCampaign?.name?.split('-')[0] || ''}</h2>
                  <h2 className={headerStyles.title} style={{marginLeft: '5px',fontWeight: '400'}}>-{selectedCampaign?.name?.split('-')[1] || ''}</h2>
                </div>
              </div>
            </div>
          </div>
        </SubHeader>

        <SidebarBody>
          <div className={styles.wrapper}>
            <div>
              <LotIcon style={{marginBottom: '20px'}}/>
              <h2 className={styles.title}>{t('add-lot')}</h2>
            </div>

            <Input 
              prefixText={t('lote').toString()}
              label={t('name') || ''}
              value={lotName}
              onChange={(e) => {
                setLotName(e.target.value)
              }}
            />

            <div style={{marginTop: '30px'}}>
              <div className={styles.gridContainer}>
                <div className={styles.gridItem} onClick={() => handleAddPolygon()}>
                  <NewPolygonIcon height={40}/>
                  <p>{t('draw')}</p>
                </div>

                <div className={styles.gridItem} onClick={() => handleEditPolygon()}>
                  <CutIcon height={35}/>
                  <p>{t('cut')}</p>
                </div>
              </div>

              <div className={styles.gridContainer}>
                <div className={styles.gridItem} onClick={() => handleClickErase()}>
                  <EraseIcon height={35} fill='white'/>
                  <p>{t('errase')}</p>
                </div>

                <div className={styles.gridItem} onClick={() => handleClickLoad()}>
                  <UploadIcon height={35} />
                  <p>{t('upload-kml')}</p>
                </div>
              </div>
            </div>

            { error && (
              <Alert type='error' text={error}/>
            )}
            { isAddLotError && (
              <Alert type='error' text={t('error-add-lot')}/>
            )}

            { successAlert && (
              <Alert type='success' text={`${t('lot')} ${t('success-added')}`}/>
            )}
          </div>
        </SidebarBody>

        <SidebarFooter>
          <div className={styles.footerGridContainer}>
            <Button
              onClick={() => navigate(`/projects/${projectId}`)}
              text={t('cancel')}
              variant={'NEUTRAL-GRAY'}
            />
            <Button
              onClick={() => handleAsign()}
              text={t('save')}
              variant={'SUCCESS'}
              disabled={isAddLotLoading}
              isLoading={isAddLotLoading}
            />
          </div>
        </SidebarFooter>
     </Sidebar>
    </>
  )
}

export default AddLote;
