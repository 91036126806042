import { useMpPreferenceMutation } from 'api/Vistaguay/CreditAPI';
import { useGetPricesQuery } from 'api/Vistaguay/PricesAPI';
import Button from 'components/Button/Button';
import ButtonMp from 'components/MercadoPago/ButtonMp'
import CustomPaypal from 'components/Paypal/CustomPaypal';
import { Stepper } from 'components/Steppers/Stepper/Stepper'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import styles from './Credits.module.scss'
import { GoToProps } from './CreditTab';

interface Props {
    amount: number;
    vpack: boolean;
    goTo?: ({page, quantity, vpack}: GoToProps) => void;
    stepper?: boolean;
}

export default function BuyCredits({ goTo, amount, vpack, stepper = true }: Props ) {
    const { t } = useTranslation();
    const { data: pricesData, isLoading: pricesLoading } = useGetPricesQuery();

    const [getPreference, { isLoading, isError, data, error }] = useMpPreferenceMutation()
    
    useEffect(() => {
        getPreference({ quantity: amount, type: vpack ? 'vpack' : 'none' })
    }, [amount, getPreference, vpack])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            { stepper && (
                <div>
                    <h5 style={{ fontWeight: 600 }}>Pagar</h5>
                    <Stepper title='Pagar' StepAmount={1} StepCurrent={1} color={styles.c6} noHeader={true} />
                </div>
            )}
          
            <p style={{ fontSize: '18px', marginTop: '20px' }}>Elegi una plataforma de pago para continuar</p>
            
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '3px solid', borderColor: styles.light, fontWeight: 600 }}>
                <p>Total a pagar</p>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                    <p style={{ color: vpack ? styles.rojo : styles.dark, textDecorationLine: vpack ? 'line-through' : 'none' }}>u$s  {vpack ? pricesData?.vpackPrice : amount}</p>
                    {
                        vpack && pricesData && <p>u$s  {pricesData?.vpackPrice - (pricesData?.vpackDiscount * pricesData?.vpackPrice / 100)}</p>
                    }
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {
                    !isError && data &&
                    <ButtonMp preferenceId={data.id} />
                }
                {
                    isError &&
                    <>
                        <p style={{ fontSize: '16px', color: styles.rojo, textAlign: 'center' }}>Recuerda que la primera compra debe ser de minimo 15 creditos</p>
                    </>
                }
                {
                    !isError &&
                    <CustomPaypal amount={amount} vpack={vpack} />
                }
            </div>

            <div style={{ padding: '10px', paddingTop: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <p style={{ fontWeight: 600, fontSize: '14px' }}>Transferencia bancaria</p>
                <p style={{ fontWeight: 400, fontSize: '12px' }}>Las operaciones por Transferencia
                    no tienen acreditacion inmediata por favor contactate con soporte@vistaguay.com
                </p>
            </div>
           
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button 
                    height='30px'
                    style={{fontSize: '12px'}}
                    text={t('back')}
                    variant='NEUTRAL-GRAY'
                    onClick={() => {if(goTo){ goTo({page: 'home'}) }} }
                />
            </div>
        </div>
    )
}