import { ReactComponent as Icon } from 'assets/icons/ico-add-lote.svg';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './MapOverlay.module.scss';

function AddLoteButtonOverlay() {
  const navigate = useNavigate();

  const { projectId } = useParams<{ projectId: string }>();

  return (
    <div className={styles.mapOverlay}>
      <div className={styles.addLoteButton}>
        <button
          onClick={() => {
            if (projectId) navigate(`/lots/new?projectId=${projectId}`);
            else navigate('/lots/new');
          }}
        >
          <Icon className={styles.addLoteButtonIcon} />
        </button>
      </div>
    </div>
  );
}

export default AddLoteButtonOverlay;
