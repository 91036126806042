
import { useRef } from 'react';

import { AsksPricing } from './AsksPricing';
import { PricingTable } from './PricingTable';


export default function Pricing() {
  const ref = useRef<HTMLSpanElement>(null)

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

      <PricingTable scrollToAsk={handleClick} />
      <AsksPricing />
      <span ref={ref}>
      </span>
    </div>
  );
}
