import MarkerIcon from 'assets/icons/ico-map-marker.svg';
import { Popover } from 'bootstrap';
import { Collection, Feature, MapBrowserEvent, Overlay } from 'ol';
import { Circle, Point as OlPoint, Point } from 'ol/geom';
import { Modify } from 'ol/interaction';
import { transform } from 'ol/proj';
import { Fill, Icon, RegularShape, Style, Text } from 'ol/style';
import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { dataProjection, featureProjection } from 'services/configurations';
import { ZIndexMarker } from 'styles/pointStyle';

import MapContext from '../Context/MapContext';
import VectorLayerContext from '../Context/VectorLayerContext';
interface PointProps {
  lat: number;
  lng: number;
  editable?: boolean;
  onEdit?: (coordinates: number[]) => void;
  hovered?: boolean;
  color?: string;
  overlayContent?: { title?: string; content: JSX.Element };
  onClick?: (coordinates: number[]) => void;
  onLoadCenter?: boolean;
  marker?: string
  zIndex?: number;
  circle?: boolean;
  pointOrder?: string
}
export const randomString = () => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 10; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};
function MPoint({
  lat,
  lng,
  editable,
  onEdit,
  hovered,
  color,
  overlayContent,
  onClick,
  onLoadCenter = false,
  zIndex,
  marker,
  circle,
  pointOrder
}: PointProps) {
  const map = React.useContext(MapContext);
  const vectorLayer = React.useContext(VectorLayerContext);
  const featureRef = React.useRef<Feature>(new Feature());
  const feature = featureRef.current;
  const popup = useRef<Overlay>();
  const [lngLat, setLngLat] = React.useState<number[]>([lng, lat]);
  useEffect(() => {
    setLngLat([lng, lat]);
  }, [lat, lng]);
  const [isShiftKeyPressed, setIsShiftKeyPressed] = useState(false);
  useEffect(() => {
    if (map) {
      const handleShiftKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Shift') {
          setIsShiftKeyPressed(true);
        }
      };
      const handleShiftKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'Shift') {
          setIsShiftKeyPressed(false);
        }
      };
      const handleMapMouseMove = (e: MapBrowserEvent<any>) => {
        map.getViewport().style.cursor = isShiftKeyPressed ? 'not-allowed' : 'default';
      };
      document.addEventListener('keydown', handleShiftKeyDown);
      document.addEventListener('keyup', handleShiftKeyUp);
      map.on('pointermove', handleMapMouseMove);
      return () => {
        document.removeEventListener('keydown', handleShiftKeyDown);
        document.removeEventListener('keyup', handleShiftKeyUp);
        map.un('pointermove', handleMapMouseMove);
      };
    }
  }, [map, isShiftKeyPressed]);
  useEffect(() => {
    if (map) {
      if (onClick) {
        const handleClick = (e: MapBrowserEvent<any>) => {
          const features = map.getFeaturesAtPixel(e.pixel, {
            hitTolerance: 8,
          });
          if (features && features.length > 0) {
            const currentFeat = features.filter((feat) => feat === feature);
            if (currentFeat.length > 0) {
              onClick(lngLat);
            }
          }
        };
        map.on('singleclick', handleClick);
        return () => {
          map.un('singleclick', handleClick);
        };
      }
    }
  }, [feature, lngLat, map, onClick]);
  useEffect(() => {
    if (overlayContent && map) {
      const elementx = document.getElementById('popup');
      const idToRemove = randomString();
      const element = document.createElement('div');
      element.id = idToRemove;
      elementx?.appendChild(element);
      const handleClickOutside = (e: MouseEvent) => {
        const popoverInstance = Popover.getInstance(element);
        if (popoverInstance && !element.contains(e.target as Node)) {
          popoverInstance.dispose();
        }
      };
      const onClickFn = (e: MapBrowserEvent<any>) => {
        const features = map.getFeaturesAtPixel(e.pixel, {
          hitTolerance: 8,
        });
        if (features && features.length > 0) {
          const currentFeat = features.filter((feat) => feat === feature);
          if (currentFeat.length > 0) {
            Popover.getInstance(element)?.dispose();
            if (popup.current) {
              map.removeOverlay(popup.current);
              popup.current.dispose();
              popup.current = undefined;
            }
            const newPopup = new Overlay({
              element,
            });
            popup.current = newPopup;
            map.addOverlay(newPopup);
            const Content = overlayContent.content;
            const div = document.createElement('div');
            const root = createRoot(div);
            root.render(Content!);
            const newPopover = new Popover(element, {
              animation: true,
              container: element,
              content: div,
              html: true,
              placement: 'top',
              title: overlayContent.title,
              sanitize: false,
            });
            newPopover.show();
            newPopup.setOffset([0, -10]);
            newPopup.setPosition((features[0].getGeometry() as Point).getCoordinates());
            newPopup.setPositioning('top-center');
          }
        }
      };
      const click = map.on('click', onClickFn);
      document.addEventListener('mousedown', handleClickOutside); // Add the click listener
      return () => {
        if (popup.current) {
          map.removeOverlay(popup.current);
          popup.current.dispose();
          popup.current = undefined;
        }
        Popover.getInstance(element)?.dispose();
        document.removeEventListener('mousedown', handleClickOutside); // Remove the click listener
        document.getElementById(idToRemove)?.remove();
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        map.un('click', onClickFn);
      };
    }
  }, [hovered, feature, map, overlayContent]);
  useEffect(() => {
    const style = feature.getStyle() as Style;
    if (hovered && style) {
      style.getImage()?.setScale(1.2);
      feature.changed();
    } else if (!hovered && style) {
      style.getImage()?.setScale(1);
      feature.changed();
    }
  }, [feature, hovered]);
  useEffect(() => {
    const PointStyle = new Style({
      zIndex: ZIndexMarker,
      image: new Icon({
        src: marker ? marker : MarkerIcon,
        // size: [24, 24],
        offset: [0, 0],
        anchor: marker ? [0.5, 0.5] : [0.5, 1],
        scale: 1,
        crossOrigin: 'anonymous',
        color: color,
      }),
    });
    const TextStyle = new Style({
      zIndex: 100000000000,
      text: new Text({
        font: '12px Roboto',
        textAlign: 'center',
        justify: 'center',
        text: pointOrder,
        offsetY: -4,
        fill: new Fill({color: 'white'})
      }),
    });

    const CircleStyle = new Style({
      image: new RegularShape({
        radius: 8,
        points: 15,
        fill: new Fill({ color: 'rgba(222, 222, 222, 0.4)' }),
      }),
    });
    feature.setGeometryName('coordinates');
    const point = new OlPoint(transform([lng, lat], dataProjection, featureProjection));
    feature.setGeometry(point);
    const source = vectorLayer?.getSource();
    const CombinedStyle = [CircleStyle, PointStyle];
    feature.setStyle(PointStyle);
    if(circle === true){
      feature.setStyle(CombinedStyle);
    }
    if (source) {
      const Style = [PointStyle, TextStyle];
      feature.setStyle(Style);
      source.addFeature(feature);
    }
    // if(onLoadCenter) {
    //   map?.getView().fit((point).getExtent(), {
    //     padding: [50,50,50,50],
    //     duration: 0,
    //   });
    //   map?.getView().setZoom(15)
    // }
    return () => {
      vectorLayer?.getSource()?.removeFeature(feature);
      // URL.revokeObjectURL(coloredSvgUrl); // Clean up the Blob URL
    };
  }, [circle, color, feature, lat, lng, marker, vectorLayer]);
  useEffect(() => {
    if (editable) {
      const modify = new Modify({
        features: new Collection([feature]),
      });
      modify.on('modifyend', (e) => {
        const coordinates = (e.features.getArray()[0].getGeometry() as OlPoint).getCoordinates();
        onEdit?.(transform(coordinates, featureProjection, dataProjection));
      });
      map?.addInteraction(modify);
      return () => {
        map?.removeInteraction(modify);
      };
    }
  }, [editable, feature, map, onEdit]);
  return <></>;
}
export default MPoint;