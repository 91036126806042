/* eslint-disable camelcase */
import { ReactComponent as AirplaneIcon} from 'assets/icons/ico-airplane.svg'
import { ReactComponent as RefreshIcon} from 'assets/icons/ico-refresh.svg'
import Alert from 'components/Alert/Alert';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import { Input, InputSelect } from 'components/Inputs/Input'
import { MappingMission } from 'models/Mission';
import { ErrorList } from 'pages/Tasks/Mosaics/AddMosaic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cameras } from 'services/mappingMissionGenerator';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setCurrentMission, setDiffentialAltitude, setMappingFirstLastPoints, setMappingPoints } from 'state/slices/volareSlice';

import { cameraKeys, IMappingMissionParams } from '../MappingSidebar';
import style from './../MappingSidebar.module.scss';

export default function StepParams ({
    step,
    nextStepTouched,
    handleError
} : {
    step: number,
    nextStepTouched?: boolean,
    // mappingMissionParams: IMappingMissionParams,
    // setMappingMissionParams: (values: IMappingMissionParams) => void,
    handleError: (isFormValid: boolean) => void
}) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const {
        differentialAltitude, 
        mappingPoints,
        mappingFirstLastPoints
    } = useAppSelector((state) => state.volare);
    const currentMission = (useAppSelector((state) => state.volare.currentMission)) as MappingMission;
    
    const [errorsList, setErrors] = useState<ErrorList[]>([]);
    const [name, setName] = useState(currentMission?.name || '');
    const [cameraName, setCameraName] = useState(currentMission?.camera_name);

    function errorHandling () {
        const errors: ErrorList[] = []; 

        if(!currentMission?.name) { 
            errors.push({propName: 'name-required', message: t('form-mission-name-required')})
        }

        setErrors(errors);
        handleError(errors.length > 0);
    }

    const reverseMappingOrder = () => {
        if (mappingPoints) {
            const newOrder = [...mappingPoints].reverse(); 
            dispatch(setMappingPoints(newOrder));
        } 
    }

    useEffect(() => {
        setName(currentMission?.name);
        setCameraName(currentMission?.camera_name)
    }, [currentMission])

    useEffect(() => {
       errorHandling()
    }, [currentMission?.name])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px', gap: '20px', overflow: 'hidden' }}>
            {/* TASKNAME */}
            <div>
                <span>{t('taskName')}</span>
                <Input
                    name='mission_name'
                    placeholder=''
                    value={name}
                    onChange={(e) => { 
                        setName(e.target.value);
                        dispatch(setCurrentMission({
                            ...currentMission,
                            name: e.target.value
                        }))
                    }}
                />
            </div>

            {/* TASKNAME ERROR */}
            { nextStepTouched && errorsList.map((error, index) => {
                return (error.propName === 'name-required') 
                    ? <Alert key={index} type='error' text={error.message} /> 
                    : null
                }
            )}

            {/* CAMERA SETTINGS */}
            <div>
                <h6>{t('camera')}</h6>
                <InputSelect
                    name='camera_name'
                    id='map_mis_camera'
                    value={cameraName}
                    placeholder=''
                    options={ cameraKeys.map((cameraId) => {
                        return { value: cameraId, name: cameras[cameraId as keyof typeof cameras].name }
                    })}
                    onChange={(e) => {
                        setCameraName(e.target.value);
                        dispatch(setCurrentMission({
                            ...currentMission,
                            camera_name: e.target.value
                        }));
                    }}
                />
            </div>

            {/* DIFF ALTITUDE */}
            <div 
                style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '10px', 
                    margin: '20px 0px',
                    fontWeight: '500',
                    color: style.lightdisabled
                }}
            >
                <AirplaneIcon height={25} fill={style.lightDisabled}/>
                <p>{t('diferential-altitude')}</p>
                <SwitchButton 
                    switchWidth={34}
                    switchHeight={18}
                    value={differentialAltitude} 
                    handleOnChange={(status) => dispatch(setDiffentialAltitude(!differentialAltitude))}
                />
            </div>

            {/* Revert Starting Point */}
            <div 
                style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '10px', 
                    margin: '20px 0px',
                    fontWeight: '500',
                    color: style.lightdisabled
                }}
            >
                <RefreshIcon height={25} fill={style.lightDisabled}/>
                <p>{t('revert-starting-point')}</p>
                <SwitchButton 
                    switchWidth={34}
                    switchHeight={18}
                    value={false} 
                    handleOnChange={reverseMappingOrder}
                />
            </div>

            <Alert  type='info' text={t('edit-mode-tip2')} />
        </div >
    )
}