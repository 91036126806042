import { VistaguayApi, VistaguayApiResponse } from './VistaguayAPI';

// export interface ExtraDataResponse {
//   seeders: Seeder[];
//   seedings: Seeding[];
//   crops: Crop[];
//   taskStatus: TaskStatus[];
//   cropTaskStatus: TaskStatus[];
//   distances: CropDistance[];
//   seederMeterBrands: SeederMeterBrand[];
//   seederBrands: SeederBrand[];
// }

// interface GetCuitsResponse {
//   cuits: string;
//   id: number;
//   nombre: string;
// }

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Task'],
});

const extraDataApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getReportData: builder.query({
      query: ({ token }: { token: string }) => `report/${token}`,
      providesTags: ['Task'],
    }),
  }),
});

export const { useGetReportDataQuery } = extraDataApi;
