import { ReactComponent as GridIcon } from 'assets/icons/ensayoIcon.svg'
import { ReactComponent as Dots } from 'assets/icons/ico-dots.svg';
import { ReactComponent as LayerIcon } from 'assets/icons/ico-layers.svg'
import { ReactComponent as Overlap } from 'assets/icons/ico-overlap.svg';
import { ReactComponent as Visibility } from 'assets/icons/visibility.svg';
import { ReactComponent as VisibilityHiden } from 'assets/icons/visibilityHidden.svg';
import useToggle from 'hooks/useToggle';
import { useState } from 'react';

import SwitchButton from '../components/SwitchButton/SwitchButton';
import styles from './ListItems.module.scss'
import ModalMenuOptionsAnalitycs from './MenuModal/ModalMenuOptionsAnalitycs';

interface IForItemsAnalitycs {
  type: string;
  variant: string;
  cultivoId: number | undefined;
  hibridoId: number | undefined;
  coords: [number | undefined],
}

const initValues: IForItemsAnalitycs = {
  type: '',
  variant: '',
  cultivoId: undefined,
  hibridoId: undefined,
  coords: [undefined],
};

const valuesItemGrid: IForItemsAnalitycs[] = [
  {
    type: 'Grid',
    variant: 'Nitrogeno',
    cultivoId: 1,
    hibridoId: 1,
    coords: [123 - 422],
  },
  {
    type: 'Grid',
    variant: 'Hibrido',
    cultivoId: 2,
    hibridoId: 2,
    coords: [123 - 45435],
  },
  {
    type: 'Grid',
    variant: 'Nitrogeno',
    cultivoId: 3,
    hibridoId: 3,
    coords: [15345 - 44522],
  },
];
const valuesItemLayers: IForItemsAnalitycs[] = [
  {
    type: 'Layer',
    variant: 'Nitrogeno',
    cultivoId: 1,
    hibridoId: 1,
    coords: [123 - 422],
  },
  {
    type: 'Layer',
    variant: 'Hibrido',
    cultivoId: 2,
    hibridoId: 2,
    coords: [123 - 45435],
  },
  {
    type: 'Layer',
    variant: 'Nitrogeno',
    cultivoId: 3,
    hibridoId: 3,
    coords: [15345 - 44522],
  },
];

export default function ListItems({ isActive, toggle }: { isActive: boolean, toggle: any }) {

  const { isActive: isActiveModal, toggle: toggleModal } = useToggle();
  const { isActive: isActiveMenuOptions, toggle: toggleMenuOptions } = useToggle();
  const { isActive: isActiveCheck, toggle: toggleCheck } = useToggle();

  const handleMenuOptions = () => {
    toggleMenuOptions();
  };
  const handleCheck = () => {
    toggleCheck();
  };
  const [shown, setShown] = useState<boolean>(false);
  const shownStats = () => {
    setShown(!shown)
  };

  return (
    <>
      <div style={{ width: '100%', height: 'auto' }}>
        <div className={styles.list} style={{ display: isActive ? 'flex' : 'none' }}>
          <div style={{ borderBottom: '2px solid #47c278' }} className={styles.container}>
            {
              !isActiveModal
                ?
                valuesItemGrid.map(function items(item, i) {
                  return (
                    <div key={i} className={styles.itemsGrid}>
                      <div className={styles.items}>
                        <GridIcon className={styles.itemIcon} />
                        <p>{item.type} | {item.variant}</p>
                      </div>
                      <div className={styles.listGrey}>
                        <button className={styles.buttonContainer} onClick={shownStats}>
                          {shown ? <Visibility className={styles.buttonIcon} /> : <VisibilityHiden className={styles.buttonIcon} />}
                        </button>
                        <button className={styles.listRight} onClick={handleCheck}>
                          <Overlap className={styles.icon} />
                        </button>
                        <button className={styles.listRight} onClick={handleMenuOptions} >
                          <Dots className={styles.icon} />
                        </button>
                      </div>
                    </div>
                  )
                })
                :
                <h5>error</h5>
            }

          </div>
          {
            isActiveMenuOptions && <ModalMenuOptionsAnalitycs isActive={isActiveMenuOptions} toggle={toggleMenuOptions} />
          }
          <div className={styles.containerSwitch} style={{ display: isActiveCheck ? 'flex' : 'none' }}>
            <h5 style={{ fontSize: '10px' }}>Capa base de intersección</h5>
            <div className={styles.content}>
              <p style={{ fontSize: '10px' }}>No</p>
              {
                isActiveCheck && <SwitchButton />
              }
              <p style={{ fontSize: '10px' }}>Si</p>
            </div>
          </div>
          <div className={styles.container}>
            {
              !isActiveModal
                ?
                valuesItemLayers.map(function items(item, i) {
                  return (
                    <div key={i} className={styles.itemsGrid}>
                      <div className={styles.items}>
                        <LayerIcon className={styles.itemIcon} />
                        <p>{item.type} | {item.variant}</p>
                      </div>
                      <div className={styles.listGrey}>
                        <button className={styles.buttonContainer} onClick={shownStats}>
                          {shown ? <Visibility className={styles.buttonIcon} /> : <VisibilityHiden className={styles.buttonIcon} />}
                        </button>
                        <button className={styles.listRight} onClick={handleCheck}>
                          <Overlap className={styles.icon} />
                        </button>
                        <button className={styles.listRight} onClick={handleMenuOptions} >
                          <Dots className={styles.icon} />
                        </button>
                      </div>
                    </div>
                  )
                })
                :
                <h5>error</h5>
            }
          </div>
        </div>
      </div>
    </>
  );
}