import 'react-datepicker/dist/react-datepicker.css';

import DatePicker from 'react-datepicker';

export interface DateInputProps {
    value?: Date;
    wrapperClassName?: string;
    calendarClassName?: string;
    inputclassName?: string;
    popperClassName?: string;
    minDate?: Date; 
    maxDate?: Date; 
    dateFormat: string | string[]; 
    handleChange: (newValue: Date) => void;
    
}

export default function DateInput ({
    value,
    wrapperClassName,
    calendarClassName,
    inputclassName,
    popperClassName,
    dateFormat,
    minDate,
    maxDate,
    handleChange
}: DateInputProps) {

  return (
    <DatePicker 
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        wrapperClassName={wrapperClassName}
        calendarClassName={calendarClassName}
        className={inputclassName}
        popperClassName={popperClassName}
        selected={value} 
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown  
        yearDropdownItemNumber={10} 
        onChange={(newValue) => {if(newValue){handleChange(newValue)}}} 
    />
  );
};
