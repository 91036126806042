import { useGetAnalyticsByIdQuery, useGetAnalyticsByLotIdQuery } from 'api/Vistaguay/AnalyticsApi';
import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetMosaicsQuery } from 'api/Vistaguay/MosaicAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import { useGetTasksQuery } from 'api/Vistaguay/TaskAPI';
import { useGetMissionsQuery } from 'api/Vistaguay/VolareAPI';
import { ReactComponent as LayersIcon } from 'assets/icons/ico-layers.svg';
import Button from 'components/Button/Button';
import Spinner from 'components/Spinner/Spinner';
import { Reorder } from 'framer-motion';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import SidebarHeader from 'layout/Sidebar/SidebarHeader';
import { LotProjectItem, SubHeader } from 'layout/Sidebar/SubHeader';
import { AnalyticsTask } from 'models/AnalyticsTask';
import { Campaign } from 'models/Campaign';
import { MappingMission, ScoutingMission } from 'models/Mission';
import MosaicTask from 'models/MosaicTask';
import { PlantCountTask } from 'models/PlantCountTask';
import { Task, TASK_TYPE } from 'models/Task';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { addTasks, clearTasks, IAllTasks,updateTasks} from 'state/slices/taskSlice';

import AnalyticsListItem from './AnalyticsListItem';
import TaskListItem from './CountTaskListItem';
import { LotItem } from './LotItem';
import { MissionListItem } from './MissionListItem';
import { MosaicListItem } from './MosaicListItem';
import style from './TaskList.module.scss';

export function TaskList() {
  // #region HOOKS
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // #endregion

  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );
  const { projectId, lotId } = useParams<{ projectId: string; lotId: string }>();

  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery(
    projectId as unknown as number,
  );
  const { data: lot, isLoading: isLoadingLots } = useGetLotQuery({
    projectId: Number(projectId),
    lotId: Number(lotId),
  });
  const allTasks = useAppSelector((state) => state.task.tasks);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState(allTasks);

  const {
    data: tasksData,
    isLoading: isLoadingTasks,
    refetch: refetchTask,
  } = useGetTasksQuery({
    campaignId: String(selectedCampaign?.id),
    lotId: String(lotId),
  },{skip: selectedCampaign == undefined});

  const {
    data: mosaics,
    isLoading: isLoadingMosaics,
    refetch: refetchMosaic,
  } = useGetMosaicsQuery({
    campaignId: String(selectedCampaign?.id),
    lotId: String(lotId),
  },{skip: selectedCampaign == undefined});

  const {
    data: missions,
    isLoading: isLoadingMissions,
    refetch: refetchMissions,
  } = useGetMissionsQuery({
    campaignId: String(selectedCampaign?.id),
    lotId: String(lotId),
  },{skip: selectedCampaign == undefined});

  const {
    data: analytics,
    isLoading: isLoadingAnalytics,
    refetch: refetchAnalytics,
  } = useGetAnalyticsByLotIdQuery({
    lotId:String(lotId),
  });

  const goToNewTask = () => {
    navigate(`/projects/${projectId}/lots/${lotId}/tasks/new`);
  };

  const handleReOrder = (i: any) => {
    setItems(i)
    dispatch(updateTasks(i))
  }
  
  function isPlantCountTask(task: any): task is PlantCountTask {
    return 'createdAt' in task;
  }
  
  function isMappingMission(task: any): task is MappingMission {
    return 'creationDateTime' in task;
  }
  
  function isScoutingMission(task: any): task is ScoutingMission {
    return 'creationDateTime' in task;
  }

  function getCreationDate(task: PlantCountTask | MappingMission | ScoutingMission): Date | null {
    if (isPlantCountTask(task)) {
      return (task as PlantCountTask).createdAt ?? null;
    } else if (isMappingMission(task)) {
      return (task as MappingMission).creationDateTime ?? null;
    } else if (isScoutingMission(task)) {
      return (task as ScoutingMission).creationDateTime ?? null;
    }
    return null;
  }

  useEffect(() => {
    if(selectedCampaign !== null) {
      refetchTask();
      refetchMosaic();
      refetchMissions();
      refetchAnalytics();
    }
  }, [refetchAnalytics, refetchMissions, refetchMosaic, refetchTask, selectedCampaign]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearTasks());
  //   };
  // }, [dispatch]);

  useEffect(() => {
    if (
      isLoadingLots ||
      isLoadingProject ||
      isLoadingTasks ||
      isLoadingMosaics ||
      isLoadingMissions ||
      isLoadingAnalytics
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingLots, isLoadingProject, isLoadingTasks, isLoadingMosaics, isLoadingMissions, isLoadingAnalytics]);

  useEffect(() => {
    if (!isLoading) {
      let _missions: Array<IAllTasks> = [];
      let plantCountTasks: Array<IAllTasks> = [];
      let _mosaics: Array<IAllTasks> = [];
      let _analytics: Array<IAllTasks> = [];

      if (missions) {
        _missions = missions.map(mission => ({
          taskId: String(mission.id),
          name: mission.name,
          type: TASK_TYPE.mission,
          task: mission,
          visualization: {
            visible: false,
            showPrescription: true,
            showPrescriptionData: false,
            showFlightPlan: false
          },
          lotId: String(lotId)
        }));
      }

      if (tasksData) {
        plantCountTasks = tasksData.map(plantCountTask => ({
          taskId: String(plantCountTask.conteosPlantasId),
          name: plantCountTask.name,
          type: TASK_TYPE.conteo,
          task: plantCountTask,
          visualization: {
            visible: false,
            showPrescription: true,
            showPrescriptionData: false,
            density: true,
            spatialVariability: true,
            temporalVariability: true
          },
          lotId: String(lotId)
        }));
      }

      if (mosaics) {
        _mosaics = mosaics.map(mosaic => ({
          taskId: String(mosaic.mosaicoId),
          name: mosaic.nombre,
          type: TASK_TYPE.mosaico,
          task: mosaic,
          lotId: String(lotId),
          visualization: {
            visible: false,
            showPrescription: true,
            showPrescriptionData: false
          }
        }));
      }

      if (analytics) {
        _analytics = analytics.map(analytic => ({
          taskId: String(analytic.id),
          name: analytic.nombre,
          type: TASK_TYPE.analytics,
          task: analytic,
          lotId: String(lotId),
          visualization: {
            visible: false,
            showPrescription: false,
            showPrescriptionData: false
          }
        }));
      }

      const _allTasks = [..._missions, ...plantCountTasks, ..._mosaics, ..._analytics];
      setItems(_allTasks);
      dispatch(addTasks(_allTasks));
    }
  }, [tasksData, analytics, mosaics, missions, isLoading, lotId, dispatch]);

  return (
    <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
      {isLoading && <Spinner />}
      <SidebarHeader />

      <SubHeader >
        <LotProjectItem
          campaignName={selectedCampaign?.name}
          projectName={project?.nombre}
          projectColor={project?.color}
          lotName={lot?.name}
        />
      </SubHeader>
      
      <SidebarBody>
        {lot && lotId && projectId && (
          <LotItem lotName={lot?.name} lotId={lotId} projectId={projectId} />
        )}
        <div className={style.taskListHeader}>
          <div>
            <LayersIcon className={style.layerIcon}/>
            <h2>{`${t('capas')} de ${t('tareas')}`}</h2>
          </div>
        </div>

        <div className={style.list}>
          <Reorder.Group values={items} onReorder={(e) => handleReOrder(e)}>
            {isLoading || (items.map((task: any, index: number) => {      
              if (task.type == TASK_TYPE.conteo) {
                return (
                  <TaskListItem key={task.taskId} task={task}/>
                );
              } else if (task.type == TASK_TYPE.mission) {
                return (
                  <MissionListItem key={task.taskId} task={task} />
                );
              } else if ( task.type == TASK_TYPE.mosaico ) {
                return (
                  <MosaicListItem key={task.taskId} mosaicTask={task} />
                )
              } else if (task.type == TASK_TYPE.analytics) {
                <AnalyticsListItem key={task.taskId} task={task} />
              }
            }))}
          </Reorder.Group>
        </div>
      </SidebarBody>

      <SidebarFooter >
        <div className={style.gridContainer}>
          <Button
            onClick={() => navigate(`/projects/${projectId}`)}
            text={t('back')}
            variant={'INFO'}
            iconBack
          />

          <Button
            icon={<LayersIcon style={{ width: '20px', height: '20px', stroke: style.light }}/>}
            text={t('add')}
            variant={'INFO'}
            onClick={() => goToNewTask()}
          />
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}
