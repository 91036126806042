import { ReactComponent as AddImagIcon } from 'assets/icons/addphotoIcon.svg';
import {ReactComponent as DeleteIcon} from 'assets/icons/ico-delete.svg'
import {ReactComponent as ImgIcon} from 'assets/icons/ico-image.svg'
import Alert from 'components/Alert/Alert';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import exifr from 'exifr';
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'state/hooks'
import { addImgPoints, removeImgPoints, setHoverImg } from 'state/slices/mapSlice'

import style from '../../AddTask.module.scss';
import { ErrorList, MosaicFormProps, MosaicStepProps, MosaicStepValidate } from '../AddMosaic'


export default function StepImageUpload ({
    form, 
    setForm, 
    handleError,
    step,
    currentStep,
    nextStepTouched
}: MosaicStepProps) {
    const inputFile = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const [errorsList, setErrorList] = useState<ErrorList[]>([]);
    
    const uploadImagesError: ErrorList = {message: t('upload-images-error'), propName: 'upload-images-error'};

    const handleOpenInput = () => {
        if (inputFile.current) {
            inputFile.current.value = ''
            inputFile.current.click()
        }
    }

    const removeDuplicates = (arr: File[]) => {
        return arr.filter((value, index) => {
            const _value = JSON.stringify(value.name);
            const mimeType = value.type;
            if (mimeType !== 'image/png' && mimeType !== 'image/jpeg') {
                return true;
            }
            return ( index === arr.findIndex((obj) => {
                return JSON.stringify(obj.name) === _value;
            }));
        });
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const _files = Array.from(files);
            const list = [...form.files, ..._files];
            const _filesFiltered = removeDuplicates(list);
            setForm({ ...form, files: _filesFiltered });
        }
    };

    const handleRemoveFile = (name: string, i: number) => {
        const list = form.files;
        const newList = list.filter((file) => file.name !== name);
        setForm({ ...form, files: newList });
        dispatch(removeImgPoints(i));
    };

    useEffect(() => {
        if (form.files.length > 0) {
            form.files.forEach((file: File, i) => {
                const mimeType = file.type;
                if (mimeType !== 'image/png' && mimeType !== 'image/jpeg') {
                    const error: ErrorList = {message: `El formato ${file.type} no está permitido`, propName: 'invalid-format'}
                    setErrorList((prevState) => [...prevState, error]);
                    handleError(errorsList.length > 0);
                    ErrorToaster(error.message);
                    const list = [...form.files];
                    list.splice(i, 1);
                    setForm({ ...form, files: list });
                    return;
                }
                exifr.parse(file).then((res) => {
                    if (res?.longitude && res?.latitude) {
                        setForm({ ...form, cameraBrand: res.Make, cameraModel: res.Model });
                        const point = {
                            name: file.name,
                            lat: [res.latitude],
                            lng: [res.longitude],
                        };
                        dispatch(addImgPoints(point));
                    } else {
                        ErrorToaster(`El archivo ${file.name} no tiene georeferencia`);
                        const list = [...form.files];
                        list.splice(i, 1);
                        setForm({ ...form, files: list });
                    }
                });
            });
        }
    }, [form.files]);

    useEffect(() => {
        if(currentStep == step) {
            const errors: ErrorList[] = [];
            let error = false; 

            if(form.files.length == 0) {
                errors.push(uploadImagesError);
            }

            if(errors.length > 0) {  error = true; }

            setErrorList(errors);
            handleError(error); 
        }
    },[form.files])

    return (
        <div className={style.bodyContent}>
            <input 
                type="file" 
                id='fileupload' 
                hidden 
                multiple 
                ref={inputFile} 
                accept='image/png,image/jpeg,image/JPG'
                onChange={handleFileChange}
            />

            <div className={style.uploadImageHeader}>
                <p> 
                    {t('uploaded-images')}
                    <span style={{ color: style.gris, marginLeft: '5px' }}>
                        [{form.files.length < 0 ? '0' : form.files.length}]
                    </span>
                </p>
                <div className={style.uploadImageBtn} onClick={handleOpenInput}>
                <AddImagIcon fill={style.azul} />
                </div>
            </div>
        
            { nextStepTouched && errorsList.map((error, index) => {
                if (error.propName === 'upload-images-error') {
                    return <Alert key={index} type='error' text={error.message}/>;
                } else if (error.propName === 'invalid-format') {
                    return <Alert key={index} type='error' text={error.message}/>;
                } else {
                    return null;
                }
                })
            }

            <ul className={style.upload}> 
                { form.files.length > 0 && form.files.map((file, i) => (
                    <li 
                        key={i} 
                        className={style.uploaditem}
                        onMouseOver={() => dispatch(setHoverImg(i))}
                        onMouseOut={() => dispatch(setHoverImg(null))}
                    >
                        <div className={style.top}>
                            <div className={style.info}>
                                <ImgIcon className={style.icon} />
                                <p>{file.name}</p>
                            </div>
                            <DeleteIcon 
                                onClick={() => {handleRemoveFile(file.name,i)}}
                                className={style.delete}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
