import { ReactComponent as GridIcon } from 'assets/icons/ensayoIcon.svg'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { useState } from 'react';

import styles from './Steps.module.scss'

export default function StepOne() {
    const [selectedLayer, setSelectedLayer] = useState('');

    const ListItem = ({ name  }: { name: string;  }) => {
        const handleChange = () => {
            setSelectedLayer(name);
        };
        return (
            <div className={styles.listItem}>
                <div className={styles.listInfo}>
                    <GridIcon className={styles.icon} />
                    <p>{name}</p>
                </div>
                <div className={styles.listRight}>
                    <CheckBox checked={selectedLayer === name} onClick={handleChange}/>
                </div>
            </div>
        )
    }

    return (
        <div style={{ marginTop: '15px' }}>
            <div>
                <h5 style={{ fontWeight: ' 600' }}>Selecciona capa de Intersección</h5>
                <p style={{ fontSize: '12px', color: '#47C278' }}>*Recuerda que debes seleccionar al menos una capa</p>
            </div>
            <div className={styles.listForm}>
                <ListItem name='Grid | Hibrido ' />
                <ListItem name='Grid | Nitrogeno' />
            </div>
        </div>
    )
}
