import { ReactComponent as BatteryIcon } from 'assets/icons/batteryIcon.svg'
import { ReactComponent as HaIcon } from 'assets/icons/haIcon.svg'
import { ReactComponent as ImgIcon } from 'assets/icons/ico-image.svg'
import { ReactComponent as TimeIcon } from 'assets/icons/timeIcon.svg'
import { useAppSelector } from 'state/hooks';

import style from './FlightInfo.module.scss';

interface IFlightInfoProp {
  scouting?: boolean
}

export const FlightInfo = ({ scouting = false }: IFlightInfoProp) => {
  const flightPlanInfo = useAppSelector((state) => state.volare.flightPlanInfo);

  return (
    <div className={scouting ? style.flightInfoScouting : style.flightInfo}>
      <div >
        <HaIcon height={30} />
        <p>{flightPlanInfo?.area}</p>
      </div>
      <div>
        <TimeIcon height={30} />
        <p>{flightPlanInfo?.minutes}</p>
      </div>
      { scouting &&
        <div>
          <ImgIcon height={30} stroke={style.dark} fill='none' />
          <p>{flightPlanInfo?.photos}</p>
        </div>
      }
      <div>
        <BatteryIcon height={30} />
        <p>{flightPlanInfo?.bateries}</p>
      </div>
    </div>
  )
};
