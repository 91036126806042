import { Popover2 } from '@blueprintjs/popover2';
import { usePatchUpdateTaskMutation, usePostUpdateDescriptionMutation, useStartCountMutation } from 'api/Vistaguay/TaskAPI';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import Button from 'components/Button/Button';
import {MenuItem} from 'components/MenuItem/MenuItem';
import { t } from 'i18next';
import { PLANTCOUNT_STAGE } from 'models/PlantCountTask';
import { useCallback, useEffect, useState } from 'react';

import style from '../Contextual.module.scss';

export default function EditOption ({
    task,
    taskId,
    extradata,
    showEditButton,
    backTo
} : {
    task : any, 
    taskId: string,
    extradata: any,
    showEditButton: boolean,
    backTo: any 
}) {
    const {
        siembraId,
        cultivoId,
        hibridoId,
        estadioId,
        fechaSiembra,
        fechaVuelo,
        cuitCliente,
        dSurco,
        seederBrandId,
        seederMeterId,
        sembradoraId,
        seederModelId
    } = task; 

    // const [description,setDescription] = useState('')
    const [cuit, setCuit] = useState<string>(cuitCliente);
    const [fechaDeSiembra, setFechaDeSiembra] = useState<string>(fechaSiembra);
    const [fechaDeVuelo, setFechaDeVuelo] = useState<string>(fechaVuelo);
    const [hybridOption, setHybridOption] = useState< {name: string, value:string} | null >(null);
    const [seederMeterOpt, setSeederMeterOpt] = useState<{name: string, value:string} | null >(null);
    const [seederOpt, setSeederOpt] = useState<{name: string, value:string} | null >(null);
    const [seederBrandOpt, setSeederBrandOpt] = useState<{name: string, value:string} | null >(null);
    const [seederModelOpt, setSeederModelOpt] = useState<{name: string, value:string} | null >(null);
    const [editActive, setEditActive] = useState(false);
    const [showSavePopUp, setShowSavePopUp] = useState(false);
    // const [updateDescription, {isLoading}] = usePostUpdateDescriptionMutation()
    const [startCount, { isLoading: isLoadingStartCount }] = useStartCountMutation();
    const [updateTask, {isLoading}] = usePatchUpdateTaskMutation();
    // console.log('PLANTCOUNT_STAGE', PLANTCOUNT_STAGE)
    const saveReportNewChanges = async () => {
        const formData = {
            cuitCliente: cuit,
            fechaSiembra: fechaDeSiembra,
            fechaVuelo: fechaDeVuelo,
            hibridoId: Number(hybridOption?.value),
            seederMeterId: Number(seederMeterOpt?.value),
            sembradoraId: Number(seederOpt?.value),
            seederBrandId: Number(seederBrandOpt?.value),
            seederModelId: Number(seederModelOpt?.value),
            estadoId: PLANTCOUNT_STAGE.REPROCESREPORT,
        }
        console.log('formData', formData)
        await updateTask({taskId, formData})
        backTo(1);
    }

    // #region HANDLRES
    const handleCancelSave = () => {
        setEditActive((prevState) => false)
    }
    const handleCuitChange = useCallback((newValue: string) => {
        setCuit(newValue);
    }, []);
    const handleHibridoChange = (newValue: string) => {
        setHybridOption(matchExtraDataFromId( 'crops', cultivoId, 'hybrids', newValue))
    }
    const handleSeederMeterChange = (newValue: string) => {
        setSeederMeterOpt(matchExtraDataFromId('seederMeterBrands', newValue));
    }
    const handleSeederChange = (newValue: string) => {
        setSeederOpt(matchExtraDataFromId('seeders', newValue));
    }
    const handleSeederBrandChange = (newValue: string) => {
        setSeederBrandOpt(matchExtraDataFromId('seederBrands', newValue));
    }
    const handleSeederModelChange = (newValue: string) => {
        setSeederModelOpt(matchExtraDataFromId('seederBrands', seederBrandId, 'seederModels', seederModelId));
    }
    const handleFechaSiembraChange = (newValue: string) => {
        console.log('setFechaDeSiembra', newValue)
        setFechaDeSiembra(newValue); 
    }
    const handleFechaVueloChange = (newValue: string) => {
        console.log('setFechaDeVuelo', newValue)
        setFechaDeVuelo(newValue); 
    }
    // #endregion

    // #region UTILS
    /**
     * Find in the extradata set an item and return the id + name
     * @param propertyName | Name of the property to search in extrada set of data - example: crops
     * @param id | Id of the property - example: for crops we pass cropId
     * @param subPropertyName | Name of the sub property - example: crops -> hybrids / hybrids its the children property
     * @param subId | Id of the property - example: for hybrids we pass hybridId
     * @returns An object - example for crops {value: '2', name: 'Maiz'} | value correspond with the id
     */
    function matchExtraDataFromId ( propertyName: string, id: string, subPropertyName?: string, subId?: string): {value:string, name:string} {
        const item = extradata[propertyName].filter((e: any) => e.id == id)[0];

        if(item && subPropertyName && subId) {
            const subItem = item[subPropertyName];
            const sub = subItem.filter((sub:any) => sub.id == subId)[0];
            return sub ? {value: sub.id, name: sub.name} : {value: '', name: ''};
        }

        return item ? {value: item.id, name: item.name} : {value: '', name: ''};
    }

    /**
     * Get the inside list of extradata item | example: inside crops list -> crop 'Corn' -> corn hybrids list
     * @param propertyName example: crops
     * @param subPropertyName example: hybrids
     * @param id example: cropsId
     * @returns Array | a list of items
     */
    function obtainSubPropertyList (propertyName: string, subPropertyName: string, id?: string): Array<{name: string, id: string}> {
        if(id) {
            const item = extradata[propertyName].filter((e: any) => e.id == id)[0];
   
            if(item && subPropertyName){
                const subItems = item[subPropertyName];
                return (subItems && subItems.length) > 0 ? subItems : []; 
            }
        }
       return []
    }
    // #endregion

    // #region POP UP MODALS
    const SavePopUpCard = () => {
        return (
            <div className={style.savePopUp}>
                <h4 className={style.savePopUpLabel}
                    style={{fontWeight: 600}}
                >
                    Tu reporte PDF se va a actualizar con los nuevos cambios
                </h4>
                <p className={style.savePopUpLabel}>Esto inhabilitará la descarga del mismo hasta que se haya generado nuevamente.</p>
                <div className={style.savePopUpButtonWrapper}>
                    <Button text={'Aceptar'} onClick={() => saveReportNewChanges()} type={'FILL'} variant={'SUCCESS'} style={{fontSize:'13px',padding:'5px', marginTop:'10px', width:'48%'}} />
                    <Button text={'Cancelar'} onClick={handleCancelSave} type={'OUTLINE'} variant={'NEUTRAL-GRAY'} style={{fontSize:'13px',padding:'5px', marginTop:'10px', width:'48%'}} />
                </div>
            </div>
        )
    }
    // #endregion

    // #region USE EFFECTS
    useEffect(() => {
        setHybridOption(matchExtraDataFromId( 'crops', cultivoId, 'hybrids', hibridoId));
        setSeederMeterOpt(matchExtraDataFromId('seederMeterBrands', seederMeterId)); 
        setSeederOpt(matchExtraDataFromId('seeders', sembradoraId));
        setSeederBrandOpt(matchExtraDataFromId('seederBrands', seederBrandId));
        setSeederModelOpt(matchExtraDataFromId('seederBrands', seederBrandId, 'seederModels', seederModelId));
    }, []) 
    // #endregion

    return (
        <div 
            style={{width: '300px', padding: '0.5rem', gap:'5px',display:'flex',flexDirection:'column'}} 
            className={style.body}
        >
            <MenuItem
                title={t('crop-type')} 
                option={matchExtraDataFromId( 'seedings', siembraId)}
            />
            <MenuItem
                title={t('crop')} 
                option={ matchExtraDataFromId('crops', cultivoId)}
            />
            <MenuItem
                title={t('genotype')} 
                option={hybridOption}
                editActive={editActive}
                inputType='dropdown'
                listItems={obtainSubPropertyList('crops', 'hybrids', cultivoId,)}
                handleChange={(newValue: string) => handleHibridoChange(newValue)}
            />
            <MenuItem
                title={t('phenological-state')} 
                option={matchExtraDataFromId('crops', cultivoId, 'stages', estadioId)}
            />
            <MenuItem
                title={t('seedtime')} 
                inputValue={fechaDeSiembra}
                editActive={editActive}
                inputType='datepicker'
                handleChange={(newValue: string) => handleFechaSiembraChange(newValue)}
            />
            <MenuItem
                title={t('date-of-flight')} 
                inputValue={fechaDeVuelo}
                editActive={editActive}
                inputType='datepicker'
                handleChange={(newValue: string) => handleFechaVueloChange(newValue)}
            />
            <MenuItem
                title={t('tax-id')} 
                inputValue={cuit}
                editActive={editActive}
                handleChange={(e: any) => handleCuitChange(e)}
            />
            <MenuItem
                title={'Dist. entre surcos'} 
                inputValue={dSurco}
            />
            <MenuItem
                title={'Marca de Dosificador'} 
                option={seederMeterOpt}
                editActive={editActive}
                inputType='dropdown'
                listItems={extradata.seederMeterBrands}
                handleChange={(newValue: string) => handleSeederMeterChange(newValue)}
            />
            <MenuItem
                title={t('seeder-meter-brand')} 
                option={seederOpt}
                editActive={editActive}
                inputType='dropdown'
                listItems={extradata.seeders}
                handleChange={(newValue: string) => handleSeederChange(newValue)}
            />
            <MenuItem
                title={t('seeder-brand')} 
                option={seederBrandOpt}
                editActive={editActive}
                inputType='dropdown'
                listItems={extradata.seederBrands}
                handleChange={(newValue: string) => handleSeederBrandChange(newValue)}
            />
            <MenuItem
                title={t('seeder-model')} 
                option={seederModelOpt}
                editActive={editActive}
                inputType='dropdown'
                listItems={obtainSubPropertyList('seederBrands', 'seederModels', seederBrandOpt?.value)}
                handleChange={(newValue: string) => handleSeederModelChange(newValue)}
            />
            
            {/* {
                showEditButton && (
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {
                            editActive && (
                                <Popover2
                                    // disabled={showSavePopUp}
                                    interactionKind='click'
                                    usePortal={true}
                                    content={<SavePopUpCard />}
                                    className={style.popover}
                                >
                                    <div
                                        style={{
                                            backgroundColor: showSavePopUp ? style.verdeVistaguay : style.light, 
                                            width: '28px', 
                                            height:'28px', 
                                            borderRadius: '50%', 
                                            cursor:'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: '5px'
                                        }}
                                        onClick={() => setShowSavePopUp((prevState) => !prevState)}
                                    >
                                        <SaveIcon 
                                            style={{
                                                fill: style.azul,
                                                width: '16px', 
                                            }}
                                            className={style.tabicon} 
                                        />
                                    </div>
                                </Popover2>
                            )
                        }
                        <div
                            style={{
                                backgroundColor: editActive ? style.verdeVistaguay : style.light, 
                                width: '28px', 
                                height:'28px', 
                                borderRadius: '50%', 
                                cursor:'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            onClick={() => setEditActive((prevState) => !prevState)}
                        >
                            <EditIcon 
                                style={{
                                    fill: style.azul,
                                }}
                                className={style.tabicon} 
                            />
                        </div>
                    </div>
                )
            } */}
        </div>
    )
} 