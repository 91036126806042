import { ReactComponent as Grilla } from 'assets/icons/ico-add-lote.svg';
import { ReactComponent as LayerIcon } from 'assets/icons/ico-layers.svg';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Modal.module.scss';

export default function ModalAnalitycs({ isActiveModal, toggleModal, isActiveCapas, toggleCapas }: { isActiveModal: boolean, toggleModal: any, isActiveCapas: boolean, toggleCapas: any }) {
  const { projectId, lotId, analyticsId } = useParams<{ projectId: string; lotId: string; analyticsId: string }>();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => isActiveModal ? toggleModal() : ''}
      className={styles.darkDisabled}
      style={{ display: isActiveModal ? 'flex' : 'none' }}
    >
      <div className={styles.positionModal}>
        <div>
          <h5 style={{ fontSize: '20px' }}>Seleccione una opción de importación</h5>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <button
              style={{ background: 'grey' }}
              className={styles.buttonIcons}
              onClick={event => {
                // if (isActiveModal) {
                //   toggleModal();
                // }
                // toggleCapas();
                // event.preventDefault();
                navigate(`/projects/${projectId}/lots/${lotId}/analytics/${analyticsId}/new/layer`)
              }}
            >
              <Grilla className={styles.icon} />
              <h5 className={styles.text}>Crear Grilla</h5>
            </button>
            <button
              className={styles.buttonIcons}
              onClick={event => {
                if (isActiveModal) {
                  toggleModal();
                }
                toggleCapas();
                event.preventDefault()
              }}
            >
              <LayerIcon className={styles.icon} />
              <h5 className={styles.text}>Cargar Capa</h5>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}


