import { Spinner } from '@blueprintjs/core';
import { useForgotPasswordMutation } from 'api/Vistaguay/VistaguayAPI';
import Button from 'components/Button/Button';
import { Input } from 'components/Inputs/Input';
import { useState } from 'react';

import styles from './ForgotPassword.module.scss';

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [sendForgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      await sendForgotPassword({ email }).unwrap();
      setSuccess('Se envió un email a tu casilla para que puedas recuperar tu contraseña');
    } catch (error: any) {
      if (error.data) {
        setError(error.data.message);
      } else if (error.message) {
        setError(error.message);
      } else setError('Ocurrió un error');
    }
  };

  return (
    <div className={styles.mainArea}>
      <div className={styles.block}>
        <h4>Recuperar contraseña</h4>
        <form onSubmit={handleSubmit}>
          <Input
            placeholder='Email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button color='white' text='Enviar' variant='SUCCESS' 
            icon={isLoading ? <Spinner size={20} /> : null} disabled={isLoading}
          />
        </form>
        {error && <p className={styles.error}>{error}</p>}
        {success && <p className={styles.success}>{success}</p>}
      </div>
    </div>
  );
};
