/* eslint-disable camelcase */
// #region IMPORTS
// #region React
// #endregion
// #region API
import { useGetLotQuery, useGetPrescriptionsQuery, useUpdatePrescriptionsMutation } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import {
  DifferentialRequestData,
  useAddScoutingMissionMutation,
  useEditScoutingMissionMutation,
  useGetDifferentialAltitudeMutation,
  useGetMissionQuery,
  useGetMissionsQuery,
} from 'api/Vistaguay/VolareAPI';
// #endregion
// #region Icons
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as ScoutingIcon } from 'assets/icons/ico-scouting.svg';
import { ReactComponent as MosaicIcon } from 'assets/icons/Mosaico-comodin.svg';
// #endregion
// #region Layout & Components
import Alert from 'components/Alert/Alert';
import Spinner from 'components/Spinner/Spinner';
import { Stepper2 } from 'components/Steppers/Stepper2/Stepper2';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import CancelPopup from 'components/Vistaguay/CancelPopup/CancelPopup';
import StepsFormFooter from 'components/Vistaguay/StepsFormFooter/StepsFormFooter';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import { LotProjectItem, SubHeader, TaskHeader } from 'layout/Sidebar/SubHeader';
// #endregion
// #region Models
import { ScoutingMission } from 'models/Mission';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
// #endregion
// #region States
import { setShowPrescription } from 'state/slices/mapSlice';
import { setSelectedPrescription, setSelectedPrescriptionId, setShowPrescriptionTable } from 'state/slices/uiSlice';
import { setCurrentLot, setCurrentMission, setEditMissionPolygon, setMissionPolygon } from 'state/slices/volareSlice';

import { validateMission } from '../utils/MissionValidator';
import EditForm from './EditForm';
// #endregion
// #region Styles
import style from './ScoutingSidebar.module.scss';
// #endregion
// #region Steps
import StepFligthInfo from './Steps/StepFligthInfo';
import StepParams from './Steps/StepParams';
// #endregion
// #endregion

export interface ScoutingMissionParams {
  altitude: number;
  speed: number;
  sco_photo_count: number;
  gridSize?: string;
  direction: number;
  name: string;
  prescriptionId?: number
}

export interface GenerateMissionObjectProps {
  point_list: any[];
  polygon_vertices: number[][];
  currentMission: ScoutingMission;
  differentialAltitude: boolean;
  projectId?: string;
}

const generateMissionObject = ({
  point_list,
  polygon_vertices,
  currentMission,
  differentialAltitude,
  projectId,
} : GenerateMissionObjectProps) => {
  return {
    project_id: projectId,
    type: 'SCOUTING',
    name: currentMission.name,
    altitude: currentMission.altitude,
    speed: currentMission.speed,
    flight_direction: currentMission.flight_direction,
    waypoints: point_list.map((point, idx) => {
      return {
        index: idx,
        lat: point[1],
        lng: point[0],
        altitude: currentMission.altitude,
        speed: currentMission.speed,
        actions: [
          {
            index: 0,
            action: 'GIMBAL',
            value: -90,
            altitude: currentMission.altitude,
            speed: currentMission.speed,
          },
          {
            index: 1,
            action: 'WAIT',
            value: 3,
            altitude: currentMission.altitude,
            speed: currentMission.speed,
          },
          {
            index: 2,
            action: 'PHOTO',
            value: null,
            altitude: currentMission.altitude,
            speed: currentMission.speed,
          },
        ],
      };
    }),
    prescriptionId: currentMission.prescriptionId,
    polygon_vertices: polygon_vertices.map((point, idx) => ({
      index: idx,
      lat: point[1],
      lng: point[0],
    })),
    lastModifiedDatetime: new Date(),
    // overlay_geojson: currentMission ? currentMission.overlay_geojson : null,
    take_off_lat: differentialAltitude ? point_list[0][1] : null,
    take_off_lng: differentialAltitude ? point_list[0][0] : null,
    photoCount: currentMission.photoCount
  };
};

export const AddScoutingSidebar = () => {
  // #region Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // #endregion

  // #region Redux States
  const globalState = useAppSelector((state) => state.volare);
  const campaign = useAppSelector((state) => state.campaign.selectedCampaign);
  const currentMission = useAppSelector((state) => state.volare.currentMission) as ScoutingMission;
  // #endregion

  // #region Params
  const { projectId, lotId, missionId } = useParams<{
    projectId: string;
    lotId: string;
    missionId?: string;
  }>();
  
  const campaignId = campaign?.id;

  if (!projectId || !lotId || !campaignId) {
    return null;
  }
  // #endregion

  const initialState: ScoutingMission = {
    waypoints: [],
    polygon_vertices: [],
    altitude: 60,
    speed: 10,
    photoCount: 10,
    gridSize: '',
    flight_direction: 0,
    name: '',
    prescriptionId: undefined,
    lot: lotId,
    campaign: campaignId,
    type: 'SCOUTING',
    creationDateTime: new Date(),
    lastModifiedDatetime: new Date()
  }

  // #region States
  const [errors, setErrors] = useState<string[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [missionName, setMissionName] = useState('');
  const [ nextStepTouched, setNextStepTouched ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [scoutingMissionParams, setScoutingMissionParams] = useState<ScoutingMission>(initialState);
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [showCancelModal, setShowCancelModal] = useState(false);
  // #endregion

  // #region Queries & Mutations
  const { data: project, isLoading: isProjectLoading, isError: isProjectError } = useGetProjectQuery(+projectId)
  
  const {
    data: lot,
    isLoading: isLotLoading,
    error: isLotError,
  } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });

  const {
    data: missionToEdit,
    isLoading: isCurrentMissionLoading,
    error: isCurrentMissionError,
  } = useGetMissionQuery(+missionId!, {
    skip: !missionId,
  });

  const [
    addMission,
    { 
      data: addMissionData, 
      error: addMissionError, 
      isLoading: addMissionLoading,
      isSuccess: addMissionSuccess 
    },
  ] = useAddScoutingMissionMutation();

  const [
    editMission,
    { 
      data: editMissionData, 
      error: editMissionError, 
      isLoading: editMissionLoading,
      isSuccess: editMissionSuccess 
    },
  ] = useEditScoutingMissionMutation();

  const [
    getElevation,
    { data: elevationData, error: elevationError, isLoading: elevationLoading },
  ] = useGetDifferentialAltitudeMutation();

  const { 
    data: prescriptions, 
    isSuccess: isPrescriptionSuccess 
  } = useGetPrescriptionsQuery({ lotId: +lotId, projectId: +projectId })

  const [
    updatePrescription, 
    { isSuccess: isUpdatePrescriptionSucesss }
  ] = useUpdatePrescriptionsMutation()

  const {
    data: missions,
    isLoading: isLoadingMissions,
    refetch: refetchMissions,
  } = useGetMissionsQuery({
    campaignId: String(campaignId),
    lotId: String(lotId),
  },{skip: campaignId == undefined});

  // #endregion

  // #region Effects
  useEffect(() => {
    dispatch(setEditMissionPolygon(false));
    if(!missionId) {
      dispatch(setCurrentMission(initialState))
    }
  }, []);

  useEffect(() => {
    if (lot) {
      dispatch(setCurrentLot(lot));
      if (lot.polygon) {
        dispatch(setMissionPolygon(lot.polygon));
      }
    }
  }, [lot, dispatch]);

  useEffect(() => {
    if(missionId && missionToEdit) {
      dispatch(setCurrentMission(missionToEdit))
    }
  }, [missionToEdit])

  useEffect(() => {
    if(editMissionSuccess || addMissionSuccess) {
      refetchMissions();
      navigate(`/projects/${projectId}/lots/${lotId}`);
    }
  }, [editMissionSuccess, addMissionSuccess]);

  useEffect(() => {
    if(editMissionError) {
      ErrorToaster(`${t('error-editing')} ${t('scouting')}`)
    } else if (addMissionError) {
      ErrorToaster(`${t('error-creating')} ${t('scouting')}`)
    }
  }, [editMissionError, addMissionError]);
  // #endregion

  // #region Handlers
  async function handleGenereteMissionObject ({
    lotId, 
    campaignId
  }: {
    lotId: number, 
    campaignId: number
  }) {
    let errors = [];

    const mission = generateMissionObject({
      point_list: globalState.scoutingPoints,
      polygon_vertices: globalState.missionPolygon?.geometry.coordinates[0] ?? [],
      currentMission: currentMission,
      differentialAltitude: globalState.differentialAltitude,
      projectId,
    });

    errors = validateMission(mission);
    setErrors(errors)

    if (errors.length == 0) {
      // check if differential altitude is enabled
      if (globalState.differentialAltitude) {
        const pointsForElevation: DifferentialRequestData = {
          locations: [],
        };
        globalState.scoutingPoints.forEach((element) => {
          pointsForElevation['locations'].push({ lat: element[1], lng: element[0] });
        });

        // Get elevation from backend API
        const pointsWithElevation = await getElevation(pointsForElevation).unwrap();
        //   if (pointsWithElevation.error) {
        //     setErrors(['La cantidad de puntos para altura diferencial debe de ser menor a 350']);
        //     setIsError(true);
        //     console.log('There has been an error with Elevations Api');
        //     return;
        //   }
        const referencePoint = globalState.scoutingPoints[0];
        let referenceElevation = 0;
        console.log(pointsWithElevation);
        // toFixed(10) is used because elevation api responds sometimes with a small difference
        for (let i = 0; i < pointsWithElevation['results'].length; i++) {
          if (
            pointsWithElevation['results'][i]['location']['lat'].toFixed(10) ==
            referencePoint[1].toFixed(10) &&
            pointsWithElevation['results'][i]['location']['lng'].toFixed(10) ==
            referencePoint[0].toFixed(10)
          ) {
            referenceElevation = pointsWithElevation['results'][i]['elevation'];
            break;
          }
        }
        referenceElevation = Math.round(referenceElevation);
        const originalAltitude = mission['altitude'];
        // Update altitude in mission acording to elevation
        for (let i = 0; i < mission.waypoints.length; i++) {
          console.log(i);
          pointsWithElevation['results'].forEach((element) => {
            if (
              element['location']['lat'].toFixed(10) == mission.waypoints[i]['lat'].toFixed(10) &&
              element['location']['lng'].toFixed(10) == mission.waypoints[i]['lng'].toFixed(10)
            ) {
              const pointElevation = element['elevation'];
              const diff = pointElevation - referenceElevation;
              const correctedAltitude = Math.round(originalAltitude + diff);
              mission.waypoints[i]['altitude'] = correctedAltitude;
            }
          });
        }
      }

      const missionToAdd: ScoutingMission = {
        ...mission,
        creationDateTime: new Date(),
        lot: {id: lotId},
        campaign: {id: campaignId},
      };

      dispatch(setCurrentMission(missionToAdd as ScoutingMission))
    }
  }

  const handleSend = async () => {
    if (missionId) {
      if(currentMission) {
        editMission({...currentMission, id: +missionId}).then((res) => {
          resetParameters();
          navigate(`/projects/${projectId}/lots/${lotId}`);
        });
      }
    } else {
      if(currentMission) {
        addMission(currentMission).then((res) => {
          resetParameters();
          navigate(`/projects/${projectId}/lots/${lotId}`);
        });
      }
    }
  }

  const resetParameters = () => {
    dispatch(setShowPrescription());
    dispatch(setSelectedPrescription(null))
    dispatch(setSelectedPrescriptionId(null))
    dispatch(setShowPrescriptionTable(false))
    dispatch(setEditMissionPolygon(false));
    dispatch(setCurrentMission(null));
  }

  const handleExit = () => {
    resetParameters()

    if(missionId) {
      navigate(`/projects/${projectId}/lots/${lotId}`);
    } else {
      navigate(`/projects/${projectId}/lots/${lotId}/tasks/new`);
    }
  }

  const handleError = useCallback((hasError: boolean) => {
    setHasError(hasError);
  },[]);

  const handleNextStep = async () => {
    setNextStepTouched(true);
    if(!hasError){
      if (currentStep === (steps.length - 1)) {
        dispatch(setShowPrescriptionTable(false))
        dispatch(setEditMissionPolygon(true));
        handleGenereteMissionObject({lotId: +lotId, campaignId})
      }
      
      if (currentStep === steps.length) {
        handleSend();
      } else {
        setCurrentStep(currentStep + 1);
      }
      setNextStepTouched(false);
    }
  }

  const handlePreviousStep = () => {
    return setCurrentStep((prevState) => prevState - 1)
  }
  // #endregion

  const steps = [
    {
      index: 1,
      step: <StepParams 
        step={1}
        currentStep={currentStep}
        handleError={handleError}
        prescriptions={prescriptions}
        isPrescriptionSuccess={isPrescriptionSuccess}
        projectId={+projectId}
        lotId={+lotId}
        nextStepTouched={nextStepTouched}
      />,
      stepName: t('requirements'),
      icon: <FileIcon />
    },
    {
      index: 2,
      step: <StepFligthInfo 
        step={2}
        handleError={handleError}
      />,
      stepName: t('flight'),
      icon: <MosaicIcon width={30} />
    }, 
    {
      index: 3,
      step: <EditForm />,
      stepName: t('polygon'),
      icon: <MosaicIcon width={30} />
    }
  ]
  
  return (
    <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
      {isCurrentMissionLoading || (isLoading && <Spinner />)}
      <SubHeader >
        <LotProjectItem
          campaignName={campaign?.name}
          projectName={project?.nombre}
          projectColor={project?.color}
          lotName={lot?.name}
        />
      </SubHeader>

      <SubHeader noBack={true} sx={{ backgroundColor: style.dark }}>
        <TaskHeader 
          title={t('mission')}
          subtitle='Scouting'
          icon={<ScoutingIcon style={{ width: '45px', height: '45px', marginTop: '10px', marginRight: '10px' }} />}
          handleCancel={() => {setShowCancelModal(true)}}
        />
      </SubHeader>

      <SidebarBody>
        <Stepper2 StepCurrent={currentStep} StepAmount={steps.length} steps={steps}/>
        { steps[currentStep - 1].step }

        { showCancelModal && (
          <CancelPopup 
            text={t('cancel-modal-text')}
            handleCancelModalclose={() => setShowCancelModal(false)} 
            handleExit={() => {handleExit()}}
            showCancelBtn
          />
        )}

        { errors.length > 0 && errors.map((error, index) => {
          return <Alert key={index} type='error' text={error} />
        })}
    
        { currentStep !== steps.length && (
          <div >
            <Alert  type='info' text={t('edit-mode-tip2')} />
          </div>
        )}
      </SidebarBody>

      <StepsFormFooter 
        currentStep={currentStep}
        stepsAmount={steps.length}
        disableBackBtn={isCurrentMissionLoading}
        disableNextBtn={hasError || isCurrentMissionLoading}
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
      />
    </Sidebar>
  );
};
