import { createSlice, PayloadAction } from '@reduxjs/toolkit';
type UnitSystem = 'metric' | 'imperial';
// Define a type for the slice state
export interface PreferencesState {
  unitSystem: UnitSystem;
}

// Define the initial state using that type
const initialState: PreferencesState = {
  unitSystem: 'metric',
} as PreferencesState;

const reducer = {
  setUnitSystem: (state: PreferencesState, action: PayloadAction<UnitSystem>) => {
    state.unitSystem = action.payload;
  },
};
export const preferencesSlice = createSlice({
  name: 'Preferences',
  initialState,
  reducers: reducer,
});

// Action creators are generated for each case reducer function
export const { setUnitSystem } = preferencesSlice.actions;

export default preferencesSlice.reducer;
