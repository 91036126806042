import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowIcon.svg';
import { ReactComponent as PlantCountIcon } from 'assets/icons/Cultivo-comodin.svg'
import { ReactComponent as MappingIcon } from 'assets/icons/ico-mapeo.svg';
import { ReactComponent as ScoutingIcon } from 'assets/icons/ico-scouting.svg';
import { ReactComponent as MosaicIcon } from 'assets/icons/Mosaico-comodin.svg'
import { ReactComponent as VolareIcon } from 'assets/icons/volareIcon.svg';
import CancelPopup from 'components/Vistaguay/CancelPopup/CancelPopup';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import { LotProjectItem, SlimHeader, SubHeader, TaskHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';

import style from '../AddTask.module.scss';

export default function AddVolare() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    
    const selectedCampaign: Campaign | null = useAppSelector(
        (state) => state.campaign.selectedCampaign,
    );

    const { lotId, projectId } = useParams<{ lotId: string; projectId: string }>();
    if (!lotId || !projectId) {
        return <div>invalid-params</div>;
    }
    const { data: lot } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });
    const { data: project } = useGetProjectQuery(projectId as unknown as number);
    const [showCancelModal, setShowCancelModal] = useState(false);

    const handleExit = () => {
        navigate(`/projects/${projectId}/lots/${lotId}/tasks/new`);
    }

    return (
        <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
           <SubHeader >
                <LotProjectItem
                    campaignName={selectedCampaign?.name}
                    projectName={project?.nombre}
                    projectColor={project?.color}
                    lotName={lot?.name}
                />
            </SubHeader>

            <SubHeader noBack={true} sx={{ backgroundColor: style.dark }}>
                <TaskHeader
                    title={'Mision de vuelo'}
                    icon={
                        <VolareIcon
                            style={{ width: '50px', height: '50px', marginTop: '10px', marginRight: '10px' }}
                        />
                    }
                    handleCancel={() => {setShowCancelModal(true)}}
                />
            </SubHeader>

            <SidebarBody>
                <p style={{ textAlign: 'center', fontSize: '14px', color: style.gris }}>Seleccionar un tipo de mision para continuar</p>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        gap: '10px',
                        padding: '0px 20px',
                        marginTop: '10px'
                    }}
                >
                    <div
                        onClick={() => navigate('./mapping')}
                        className={style.volareItem}
                    >
                        <div>
                            <MappingIcon className={style.icon} />
                            <ArrowIcon className={style.iconArrow} />
                            <MosaicIcon className={style.iconTask} />
                        </div>
                        <h6 style={{ fontWeight: '800' }}>MAPPING</h6>
                        <p>Para mosaicos</p>
                    </div>

                    <hr />

                    <div
                        onClick={() => navigate('./scouting')}
                        className={style.volareItem}
                    >
                        <div>
                            <ScoutingIcon className={style.icon} />
                            <ArrowIcon className={style.iconArrow} />
                            <PlantCountIcon className={style.iconTask} />
                        </div>
                        <h6 style={{ fontWeight: '800' }}>SCOUTING</h6>
                        <p>Para conteo de plantas</p>
                    </div>

                </div>

                { showCancelModal && (
                    <CancelPopup 
                        text={t('cancel-modal-text')}
                        handleCancelModalclose={() => setShowCancelModal(false)} 
                        handleExit={() => {handleExit()}}
                        showCancelBtn={true}
                    />
                )}
            </SidebarBody>
            <SidebarFooter sx={{ alignItems: 'center', textAlign: 'center', padding: '20px', justifyContent: 'center', background: style.verdeSuave }}>
                <a style={{ fontWeight: '600', textAlign: 'center', color: style.verdeVistaguay, fontSize: '14px' }}>
                    ¿Como planificar una mision exitosa?
                </a>
            </SidebarFooter>
        </Sidebar>
    );
}
