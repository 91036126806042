import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import MPoint from 'components/Map/Components/MPoint';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import MPolygon from 'components/Map/Components/Polygon/MPolygon';
import MapContext from 'components/Map/Context/MapContext';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';
import { BLACK_LINE, LOT_COLOR } from 'styles/variables';
interface Point {
    name?: string,
    lat: any[];
    lng: any[];
  }
export default function AddMosaicMap() {
    const map = useContext(MapContext);

    const { projectId, lotId } = useParams<{ projectId: string; lotId: string }>();
    if (!projectId || !lotId) {
        return null;
    }
    const { data: lotData } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });
    const imgPoints: Array<Point> | undefined = useAppSelector((state) => state.map.imgPoints);
    const hoverImg: number | null = useAppSelector((state) => state.map.hoverImg);

    return (
        <>
            <MVectorLayer zIndex={60}>
                {imgPoints &&

                    imgPoints.map((point, index) => {
                        return <MPoint 
                            color={'#cf4941'} 
                            zIndex={70} 
                            hovered={index == hoverImg} 
                            overlayContent={{ 
                                title: '', 
                                content: <div style={{ padding: '2px', width: '100px' }}>{point.name}</div> 
                            }} 
                            onLoadCenter={true} 
                            key={index} 
                            lat={point.lat[0]} 
                            lng={point.lng[0]} 
                        />;
                    })
                }
            </MVectorLayer>
            <MVectorLayer zIndex={100}>
                <MPolygon onLoadCenter={true} polygon={lotData?.polygon?.geometry} color={LOT_COLOR} stroke={BLACK_LINE} height={1}/>
            </MVectorLayer>
        </>
    )
}