import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { IProfileForm } from 'components/Modals/ProfileModal/ProfileModal';
import User from 'models/User';
import { API_URL } from 'services/configurations';
import { getAccessToken, logout, setAccessToken, setRefreshToken } from 'state/slices/dataSlice';
import { RootState } from 'state/store';

interface LoginBody {
  email: string;
  password: string;
}
interface RegisterBody {
  // name: string;
  email: string;
  password: string;
}
export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export interface RegisterResponse {
  status: string;
  message: string; 
  code: string; 
  data?: {
    accessToken: string;
    refreshToken: string;
  }
}

export interface VistaguayApiResponse<T> {
  message: string;
  success: boolean;
  data: T;
}

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders(headers, api) {
    const jwt = getAccessToken()
    jwt && headers.set('Authorization', `Bearer ${jwt}`);
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${(api.getState() as RootState).data.refreshToken}`,
        },
      },
      api,
      extraOptions,
    );
    if (refreshResult.data) {
      // store the new token
      api.dispatch(setAccessToken((refreshResult.data as LoginResponse).accessToken));
      api.dispatch(setRefreshToken((refreshResult.data as LoginResponse).refreshToken));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }
  return result;
};

export const VistaguayApi = createApi({
  reducerPath: 'VistaguayApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Me'],
  endpoints: (builder) => ({
    googleLogin: builder.mutation<LoginResponse, string>({
      query: (body) => ({
        url: 'auth/google/callback',
        method: 'POST',
        body: {
          token: body,
        },
      }),
      invalidatesTags: ['Me']
    }),
    login: builder.mutation<LoginResponse, LoginBody>({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Me']
    }),
    me: builder.query<User, void>({
      query: () => ({
        url: 'users/me',
        method: 'GET',
      }),
      providesTags: ['Me'],
    }),
    updateMe: builder.mutation<User, Partial<IProfileForm>>({
      query: (user) => ({
        url: 'users/me',
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: ['Me'],
    }),
    register: builder.mutation<RegisterResponse, RegisterBody>({
      query: (body) => ({
        url: 'auth/demo/register',
        method: 'POST',
        body: body,
      }),
    }),
    uploadImg: builder.mutation<any, any>({
      query: (body) => ({
        url: 'users/me/upload/profile-img',
        method: 'POST',
        body,
        formData: true,
      }),
    }),
    changePassword: builder.mutation<void, { currentPassword: string; newPassword: string }>({
      query: ({ currentPassword, newPassword }) => ({
        url: 'auth/changePassword',
        method: 'PUT',
        body: { currentPassword, newPassword },
      }),
    }),
    confirmEmail: builder.mutation<void, { token: string; email: string }>({
      query: ({ token, email }) => ({
        url: 'auth/confirm',
        method: 'POST',
        body: { token, email },
      }),
    }),
    forgotPassword: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: 'auth/forgotPassword',
        method: 'POST',
        body: { email },
      }),
    }),
    resetPassword: builder.mutation<void, { token: string; password: string }>({
      query: ({ token, password }) => ({
        url: 'auth/resetPassword',
        method: 'POST',
        body: { token, newPassword: password },
      }),
    }),
  }),
});

export const {
  useUploadImgMutation,
  useGoogleLoginMutation,
  useLoginMutation,
  useMeQuery,
  useUpdateMeMutation,
  useChangePasswordMutation,
  useRegisterMutation,
  useConfirmEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = VistaguayApi;
