import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import style from './../../layout/Topbar/Topbar.module.scss';
import BuyCredits from './BuyCredits'
import BuyVpack from './BuyVpack'
import CreditCalculator from './CreditCalculator'
import CreditResume from './CreditResume'
import styles from './Credits.module.scss'

export interface GoToProps {
    page: 'home' | 'calculator' | 'vpack' | 'buy', 
    quantity?:number, 
    vpack?: boolean
}

export default function CreditTab() {
    const { t } = useTranslation();

    const [index, setIndex] = useState(0)
    const [amount, setAmount] = useState(0)
    const [vPack, setVPack] = useState(false)

    const goTo = ({
        page, 
        quantity, 
        vpack 
    }: GoToProps) => {
        switch (page) {
            case 'home':
                setIndex(0)
                break;
            case 'calculator':
                setIndex(0)
                break;
            case 'vpack':
                setIndex(0)
                break;
            case 'buy':
                if (quantity) {setAmount(quantity)}
                if (vpack) {setVPack(vpack)}
                setIndex(2)
                break;
        
            default:
                setIndex(0)
                break;
        }
    }

    const pages = [
        <CreditResume key={0} goTo={goTo} />,
        <CreditCalculator key={1} goTo={goTo}/>,
        <BuyCredits key={2} goTo={goTo} amount={amount} vpack={vPack} stepper={false}/>,
        <BuyVpack key={3} goTo={goTo}/>
    ]

    return (
        <div className={style.menu}>
            <div className={style.menuTitle}>
                <h2>{t('my-credits')}</h2>
            </div>

            <div className={style.menuList} style={{padding: '20px'}}>
                {pages[index]}
            </div>
        </div>
    )
} 