import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as TickIcon } from 'assets/icons/ico-circle-tick.svg';
import clsx from 'classnames'
import { ColorPickerButton } from 'components/ColorPicker/VistaguayColorPicker';
import { DatePicker, Input } from 'components/Inputs/Input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formattedDate } from 'services/helpers';

import styles from './MenuItemSingleEdit.module.scss';

export default function MenuItemSingleEdit ({
    label, 
    inputValue = '',
    editable = false,
    inputType = 'input',
    laoding,
    handleChange,
    handleSaveChange,
} : {
    label : string, 
    inputValue: any,
    editable?: boolean, 
    laoding?: boolean, 
    inputType?: 'input' | 'datepicker' | 'colorpicker',
    handleChange?: (value: any) => void,
    handleSaveChange?: (value: any) => void,
}) { 
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);

    return (
        <div>
            <div className={styles.wrapper} >
                <div className={styles.labelWrapper}>
                    <p>{label}</p>

                    { editable && (
                        <>
                            { inputType !== 'colorpicker' ? (
                                <div 
                                    className={clsx(
                                        styles.extraBtn,
                                        edit ? styles.active : styles.inactive
                                    )}
                                    onClick={() =>{ 
                                        if(edit && handleSaveChange) {
                                            handleSaveChange(inputValue)
                                        }
                                        setEdit((prevState) => !prevState)}
                                    }
                                >
                                    <EditIcon fill={edit ? styles.verdeVistaguay : styles.light}/>
                                </div>
                            ) : (
                                <div style={{marginLeft: '10px', cursor: 'pointer'}}>
                                    <ColorPickerButton
                                        color={inputValue}
                                        onChange={(color: string) => { if(handleChange){handleChange(color)} }}
                                        height='20px'
                                        width='20px'
                                    />
                                    
                                    <EditIcon 
                                        style={{marginLeft: '5px'}}
                                        fill={laoding ? styles.verdeVistaguay : styles.light}
                                        width={20}
                                        onClick={() =>{
                                            if(handleSaveChange) {
                                                handleSaveChange(inputValue)
                                            }
                                        }
                                    } />
                                </div>
                            )}
                        </>
                    )}
                </div>
                
                { edit ? (
                    <>
                        { inputType === 'input' && (
                            <Input 
                                className={styles.menuInputWraper}
                                inputStyle={styles.menuItemInput}
                                onChange={(e) => {if(handleChange){handleChange(e.target.value)}}}
                                placeholder={inputValue}
                                value={inputValue}
                            />
                        )}

                        { inputType === 'datepicker' && (
                            <DatePicker
                                label=''
                                onChange={(date) => {if(handleChange){handleChange(date)}}}
                                value={inputValue}
                                datepickerInputStyle={styles.menuItemInput}
                            />
                        )}
                    </>
                ) : (
                    <>
                        { inputType === 'input' &&  <p>{inputValue}</p>}
                        { inputType === 'datepicker' &&  <p>{formattedDate(inputValue)}</p>}
                    </>
                )}

                { inputType === 'colorpicker' && (
                    <div className={styles.colorpickerWrapper}>
                        <div 
                            className={styles.colorpicker}
                            style={{ backgroundColor: inputValue ? inputValue : 'transparent'}}
                        />
                    </div>
                )}

                         
            </div>
            <hr style={{margin: '5px 0 5px 0'}}/>
        </div>
    )
}