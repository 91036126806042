import { useGetPrescriptionsQuery, useUpdatePrescriptionsMutation } from 'api/Vistaguay/LotAPI';
import MenuItemSingleEdit from 'components/Vistaguay/MenuItemSingleEdit/MenuItemSingleEdit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { splitFirstOccurrence } from 'services/helpers';

import style from '../../LotContextual.module.scss';

export default function EditOption({
    lotId,
    projectId
}: {
    lotId: number;
    projectId: number;
}) {
    const { t } = useTranslation();

    const [
        updatePrescription,
        {
            isError,
            isSuccess,
            isLoading
        }
    ] = useUpdatePrescriptionsMutation();

    const { 
        data: prescriptionsData 
    } = useGetPrescriptionsQuery({lotId: lotId, projectId: projectId});

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleSaveRxName = ({value, extension, id}: {value: string, extension: string, id: number}) => {
        if(prescriptionsData) {
            const prescriptionData = prescriptionsData.filter((p) => p.id === id)[0];
            const prescriptionFullName = `${value}.${extension}`
            const prescription = {...prescriptionData, name: prescriptionFullName}

            updatePrescription({
                lotId,
                projectId,
                prescription,
                prescriptionId: id
            })
        }
    }

    useEffect(() => {
        let timer: any;
        if (isSuccess) {
            setSuccess(true);
            timer = setTimeout(() => {
                setSuccess(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isSuccess]);

    useEffect(() => {
        let timer: any;
        if (isError) {
            setError(true);
            timer = setTimeout(() => {
                setError(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isError]);

    return(
        <div>
            { prescriptionsData && prescriptionsData.map((p) => (
                <PrescriptionItem
                    key={p.id}
                    label={t('name')} 
                    value={p?.name || ''}
                    handleSave={({value, extension, id}) => handleSaveRxName({value, extension, id})}
                    id={p.id}
                />
            ))}

            <div style={{height: '20px'}}>
                { isLoading && (
                    <p className={style.bounce}>{t('saving')}...</p>
                )}

                { success && (
                    <p style={{color: style.verdeVistaguay}}>{t('saved')}</p>
                )}

                { error && (
                    <p style={{color:  style.rojo}}>{t('error')}</p>
                )}
            </div>
        </div>
    )
}

const PrescriptionItem = ({ 
    label,
    value, 
    id,
    handleSave 
}:{ 
    label: string,
    value:string,
    handleSave: ({value, extension, id}: {value: string, extension: string, id: number}) => void; 
    id: number
}) => {
    const splittedName = splitFirstOccurrence({str: value, delimiter: '.'});
    const extension = splittedName[1]

    const [itemValue, setItemValue] = useState(splittedName[0]);

    return(
        <div style={{flexDirection: 'column', alignItems: 'flex-start'}}>
            <MenuItemSingleEdit
                inputType='input'
                label={label} 
                inputValue={itemValue}
                handleChange={(value: any) => setItemValue(value)}
                handleSaveChange={(value: any) => handleSave({value, extension, id})}
                editable
            />
        </div>
    )
}