import { WmsData } from 'api/Vistaguay/MosaicAPI';
import MWms from 'components/Map/Components/MSimpleWms';
import { max } from 'lodash';
import { useAppSelector } from 'state/hooks';

interface WmsAlgorithmProps {
  wmsData: WmsData;
  visible: boolean;
  opacity: number;
}

interface WmsDynamicStyleProps {
  wmsData: WmsData;
  visible: boolean;
  opacity: number;
  min: number;
  max: number;
  params: string[];
}

/*
const getQuantityValues = (min: number, max: number, cantValores: number) => {
  const distance = Math.abs((max - min) / cantValores);
  const quantityWmsValues = [];
  for (let i = 0; i <= cantValores; i++) {
    quantityWmsValues.push(+min + i * distance);
  }
  return quantityWmsValues;
}
*/


const getQuantityValues = (min: number, max: number, cantValores: number) => {

  const distance = Math.abs((max - min) / cantValores);
  const quantityWmsValues = [];
  for (let i = 0; i <= cantValores; i++) {
    quantityWmsValues.push(+min + i * distance);
  }
  return quantityWmsValues;
}

const getStyleName = (algorithm: string) => {
  switch (algorithm) {
    case 'vari':
      return 'vari62';
    case 'mosaico':
      return 'raster';
    case 'ndwig':
      return 'ndwig62';
    case 'dem':
      return 'dem62';
    default:
      // return `${algorithm}62`;
      return 'raster';
  }
}

const getAmountOfStep = (algorithm: string) => {
  switch (algorithm) {
    case 'vari':
      return 8;
    case 'mosaico':
      return 8;
    case 'ndwig':
      return 8;
    case 'dem':
      return 4;
    default:
      return 8;
  }
}

export const WmsAlgorithm = ({ wmsData, visible, opacity }: WmsAlgorithmProps) => {

  return (
    <MWms
      layer={wmsData.layer}
      url={wmsData.url}
      visible={visible}
      quantityValues={getQuantityValues(wmsData.gdalInfo.bands[0].min, wmsData.gdalInfo.bands[0].max, getAmountOfStep(wmsData.algorithm))}
      min={wmsData.gdalInfo.bands[0].min}
      max={wmsData.gdalInfo.bands[0].max}
      styleName={getStyleName(wmsData.algorithm)}
      opacity={opacity}
    />
  );
};



export const WmsDynamicStyle = ({
  visible,
  wmsData,
  opacity,
  min,
  max,
  params,
}: WmsDynamicStyleProps) => {
  const cantValores = 9;
  const distance = Math.abs((max - min) / cantValores);

  const quantityWmsValues = [];
  for (let i = 0; i <= cantValores; i++) {
    quantityWmsValues.push(Number(Math.trunc(+min + i * distance).toFixed(1)));
  }

  return (
    <MWms
      layer={wmsData.layer}
      url={wmsData.url}
      visible={visible}
      quantityValues={quantityWmsValues}
      min={min}
      max={max}
      styleName={'DynamicStyle'}
      opacity={opacity}
      params={params}
    />
  );
};

// http://v2.vistaguay.com:8080/geoserver/mosaic-1670/wms?
// REQUEST=GetMap
// &SERVICE=WMS
// &VERSION=1.1.1
// &FORMAT=image/png
// &STYLES=IndiceDinamico
// &TRANSPARENT=true
// &tiled=true
// &LAYERS=mosaic-1670:vari
// &exceptions=application/vnd.ogc.se_inimage
// &serverType=geoserver
// &env=step1:-1;step2:0;step3:0;step4:0;step5:0;step6:0;step7:0;step8:0;step9:0;step10:1;
// &WIDTH=256
// &HEIGHT=256
// &SRS=EPSG:3857
// &BBOX=-7088770.003167246,-3608592.1053431705,-7088617.129110675,-3608439.2312866
