import { useAddCreditsMutation, useGetAllCreditsMutation } from 'api/Vistaguay/CreditAPI';
import { useGetPricesQuery } from 'api/Vistaguay/PricesAPI';
import Button from 'components/Button/Button';
import { IconButton } from 'components/Buttons/IconButton/IconButton';
import Modal from 'components/Modal/Modal';
import { PaginatorFooter } from 'components/PaginatorFooter/PaginatorFooter';
import Spinner2 from 'components/Spinner/Spinner';
import { Table } from 'components/Table/Table';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import style from './AdminCredits.module.scss';

const COLUMNS = [
  // {
  //   property: 'id',
  //   label: 'ID',
  // },
  {
    property: 'user.email',
    label: 'Email',
  },
  {
    property: 'amount',
    label: 'Monto',
  },
  {
    property: 'expiration',
    label: 'Expira',
  },
  {
    property: 'vpack',
    label: 'V-Pack',
  },
];

export const AdminCredits = () => {
  const { t } = useTranslation();
  const [getAllCredits, { data: getAllCreditsData, isLoading: isLoadingGetAllCredits }] =
    useGetAllCreditsMutation();

  const [addCredits, { data: addCreditsData, isLoading: isLoadingAddCredits }] =
    useAddCreditsMutation();
  const {data, isLoading} = useGetPricesQuery()
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [emailFilter, setEmailFilter] = useState('');
  const [total, setTotal] = useState(0);

  const [addCreditsModalIsOpen, setAddCreditsModalIsOpen] = useState(false);
  const [changePasswordModalIsOpen, setChangePasswordModalIsOpen]= useState(false)
  const [vPack,setVPack] = useState<boolean>(false)
  const handleAddCredits = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = e.currentTarget.email.value;

    const vpack = e.currentTarget.vpack.checked;
    vpack ? e.currentTarget.amount.value = data?.vpackPrice : e.currentTarget.amount.value
    const amount = e.currentTarget.amount.value
    addCredits({
      userEmail: email,
      amount,
      vpack,
    }).then((res) => {
      if ('error' in res) {
        return;
      }
      setAddCreditsModalIsOpen(false);
      getAllCredits({
        pageNumber: page,
        pageSize: pageSize,
        emailFilter: emailFilter === '' ? undefined : emailFilter,
      });
    });

  };

  useEffect(() => {
    if (getAllCreditsData) {
      setTotal(getAllCreditsData.total);
    }
  }, [getAllCreditsData]);

  useEffect(() => {
    getAllCredits({
      pageNumber: page,
      pageSize: pageSize,
      emailFilter: emailFilter === '' ? undefined : emailFilter,
    });
  }, [emailFilter, getAllCredits, page, pageSize]);
  
  return (
    <div className={style.container}>
      <div className={ style.searchContainer}>
        <input
          type='text'
          placeholder='Filtrar por Email'
          value={emailFilter}
          onChange={(e) => setEmailFilter(e.target.value)}
        />
        <Button onClick={() => setAddCreditsModalIsOpen(true)}
          text='Añadir creditos'
          color='white'
          style={{
            placeSelf: 'center',
          }}
          />
        <Button onClick={() => setChangePasswordModalIsOpen(true)}
          text='Cambiar Contraseña'
          color='white'
          style={{
            placeSelf: 'center',
          }}
          />
          </div>
      {isLoadingGetAllCredits && <Spinner />}
      {getAllCreditsData && (
        <>
          <Table
            data={getAllCreditsData?.data.map((credit) => ({
              ...credit,
              expiration: credit.expiration
                ? new Date(credit.expiration).toLocaleDateString()
                : '-',
              vpack: credit.vpack ? 'Si' : 'No',
            }))}
            columns={COLUMNS}
          />
        </>
      )}
      <PaginatorFooter
        handleNextPage={() => setPage(page + 1)}
        handlePrevPage={() => setPage(page - 1)}
        page={page}
        totalPages={Math.ceil(total / pageSize)}
      />
      <Modal
        isOpen={addCreditsModalIsOpen}
        header={<h1>Añadir creditos</h1>}
        closeFn={() => {
          setAddCreditsModalIsOpen(false);
        }}
      >
        <div>
          <form
            className={style.addCreditsForm}
            onSubmit={(e) => {
              handleAddCredits(e);
            }}
          >
            <div className={style.formGroup}>
              <input id='vpack' type='checkbox' onChange={(e) => setVPack(e.target.checked)} />
              <label htmlFor='email'>Email</label>
              <input id='email' type='text' placeholder='Email'
              disabled={isLoadingAddCredits}
              />
            </div>
            <div className={style.formGroup}>
              <label htmlFor='amount'>Monto</label>
              <input id='amount' type='text' placeholder='Monto'
              disabled={isLoadingAddCredits || vPack}
              />
            </div>
            <Button
              disabled={isLoadingAddCredits}
              icon={isLoadingAddCredits ? <Spinner /> : null}
              color='white'
              text='Agregar'
            />
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={changePasswordModalIsOpen}
        header={<h1>Cambiar contraseña de usuario</h1>}
        closeFn={() => {
          setChangePasswordModalIsOpen(false);
        }}
      >
        <div>
          <form
            className={style.addCreditsForm}
            onSubmit={(e) => {
              // TODO conectar con API /auth/changePassword/{userId}
            }}
          >
            <div className={style.formGroup}>
              <label htmlFor='id'>User ID</label>
              <input id='uid' type='text' placeholder='UserID'
              disabled={isLoadingAddCredits}
              />
            </div>
            <div className={style.formGroup}>
              <label htmlFor='newpass'>Nueva Contraseña</label>
              <input id='newpass' type='text' placeholder='Nueva Contraseña'
              disabled={isLoadingAddCredits || vPack}
              />
            </div>
            <Button
              color='white'
              text='Agregar'
            />
          </form>
        </div>
      </Modal>
    </div>
  );
};
