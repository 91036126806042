import { GoogleLogin } from '@react-oauth/google';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RegisterResponse, useGoogleLoginMutation, useRegisterMutation, VistaguayApi } from 'api/Vistaguay/VistaguayAPI';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowIcon.svg';
import { ReactComponent as CircleIcon} from 'assets/icons/ico-circle.svg'
import { ReactComponent as CircleTickIcon} from 'assets/icons/ico-circle-tick.svg'
import { ReactComponent as EyeIcon} from 'assets/icons/visibility.svg'
import { ReactComponent as VistaguayIcon } from 'assets/icons/vistaguay.svg';
import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';
import EmailAutosuggest from 'components/Inputs/Email/EmailInput';
import { Input } from 'components/Inputs/Input';
import useToggle from 'hooks/useToggle';
import CentralArea from 'layout/Centralarea/Centralarea';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { checkPassRule, checkValidEmail } from 'services/helpers';
import { useAppDispatch } from 'state/hooks';
import { setLoginData } from 'state/slices/dataSlice';

import spinner from '../../assets/img/vistaguay-spinner.gif';
import style from './../Login/Login.module.scss';


export function RegisterSidebar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { t } = useTranslation();

    const [googleLogin, { data: dataGoogle, isLoading: isLoadingGoogle, error: hasGoogleError, isSuccess: isSuccesGoogle }] = useGoogleLoginMutation()
    const [
        register, 
        { 
            isLoading, 
            isSuccess, 
            isError: isRegisterError, 
            data: registerData, 
            error: registerError 
        }
    ] = useRegisterMutation()

 
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [passVisibility, setPassVisibility] = useState(false)
    const [confirmPassVisibility, setConfirmPassVisibility] = useState(false)
    const [clickSubmit, setClickSubmit] = useState(0)
    const [formErrorList, setFormErrorList] = useState<Array<{field: string}>>([]);
    
    const isEmailValid = checkValidEmail({email});

    const {
      min, 
      hasSpecialCharacter, 
      hasUppercase
    } = checkPassRule({
      str:password, 
      minLength: 8, 
      specialCharacter: true, 
      uppercase: true
    })

    useEffect(() => {
      handleFormvalidation(clickSubmit);
    }, [isEmailValid, clickSubmit, password, confirmPass, email])
    
    useEffect(() => {
      if (isSuccess || isSuccesGoogle) {
        setClickSubmit(0);
      } 
    }, [isSuccess, isSuccesGoogle])

    useEffect(() => {
      console.log('registerData', registerData)
      if (registerData && registerData?.data) {
        const toDispatch = {
          accessToken: registerData.data.accessToken,
          refreshToken: registerData.data.refreshToken,
        };
        dispatch(VistaguayApi.util.resetApiState())
        dispatch(setLoginData(toDispatch));
      }
    }, [registerData, dispatch]);

    const handleFormvalidation = (click: number) => {
      const errors = [];
      
      if(click > 0) {
        if(!isEmailValid) {
          errors.push({field: 'email'})
        } 
        if(password.length === 0) {
          errors.push({field: 'password-required'})
        }
        if(!min || !hasSpecialCharacter || !hasUppercase){
            errors.push({field: 'password-rules'})
        }
        if(confirmPass.length === 0) {
            errors.push({field: 'confirm-password-required'})
        }
        if(password !== confirmPass) {
            errors.push({field: 'confirm-password'})
        }
          
        setFormErrorList(errors);
      }

      return (errors.length == 0);
    }

    const handleChange = (newValue: string) => {
      setEmail(newValue)
    }

    const handleSubmitForm = () => {
      const click = clickSubmit;
      setClickSubmit(click + 1);
      const isFormValid = handleFormvalidation(click)
      console.log('isFormValid', isFormValid)
      if(isFormValid && isEmailValid) {   
        register({email, password})
      }
    }
  
    return (
      <div className={style.box}>
        <Sidebar className={style.LoginSidebar}>
          <SidebarBody sx={{ padding: '20px' }}>
            { isSuccess || isSuccesGoogle ? (
              <div className={style.container}>
                <h2 style={{marginBottom: '20px'}}>{t('check-your-email-title')}</h2>
                <p style={{marginBottom: '10px'}}>{`${t('check-your-email-text1')} ${email} ${t('check-your-email-text2')}.`}</p>
                <p style={{color: 'red', fontWeight: 500, marginBottom: '10px'}}>{t('check-your-email-text3')}.</p>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Button 
                    height='40px'
                    width='100px'
                    text={t('acept')}
                    variant='SUCCESS'
                    onClick={() => navigate('/')}
                  />
                </div>
                
              </div>
            ) : (
              <div className={style.container}>
                <h2>{t('signin')}</h2>
    
                <form className={style.form} onSubmit={(e) => e.preventDefault()}>
                  <div className={style.formGroup}>
                      <span>{t('email')}</span>
    
                      <EmailAutosuggest handleChange={handleChange}/>

                      { (email.length > 0 || clickSubmit > 0) && formErrorList.find((err) => err.field === 'email') && (
                        <Alert type='error' text={t('invalid-email')}/>
                      )}
                  </div>
                  
                  <div className={style.formGroup}>
                      <span>{t('password')}</span>
                      <Input 
                          placeholder='' 
                          type={!passVisibility ? 'password' : 'text'} 
                          value={password} onChange={(e) => setPassword(e.target.value)} name='password' 
                          inputStyle={style.inputStyle}
                      >
                          <div 
                              style={{marginRight:'10px', cursor: 'pointer'}} 
                              onClick={() => setPassVisibility((prevState: boolean) => !prevState)}
                          >
                              <EyeIcon style={{stroke: passVisibility ? style.verdeVistaguay : style.light, width:'30px'}} />
                          </div>
                      </Input>

                      { (clickSubmit > 0) && formErrorList.find((err) => err.field === 'password-required') && (
                          <Alert type='error' text={t('required-field')}/>
                      )}
                  </div>

                  <div className={style.formGroup}>
                      <span>{t('confirm-password')}</span>
                      <Input 
                      placeholder='' 
                      name='confirm-password'
                      type={!confirmPassVisibility ? 'password' : 'text'} 
                      value={confirmPass} 
                      onChange={(e) => setConfirmPass(e.target.value)} 
                      inputStyle={style.inputStyle}
                      >
                          <div 
                              style={{marginRight:'10px', cursor: 'pointer'}} 
                              onClick={() => setConfirmPassVisibility((prevState: boolean) => !prevState)}
                          >
                              <EyeIcon style={{stroke: confirmPassVisibility ? style.verdeVistaguay : style.light, width:'30px'}} />
                          </div>
                      </Input>

                      { (clickSubmit > 0) && formErrorList.find((err) => err.field === 'confirm-password-required') && (
                          <Alert type='error' text={t('required-field')}/>
                      )}

                      { (clickSubmit > 0) && formErrorList.find((err) => err.field === 'confirm-password') && (
                          <Alert type='error' text={t('confirm-password-error')}/>
                      )}
                  </div>

                  { password.length > 0 &&(
                      <div className={style.passValidationsContainer}>
                      <div className={style.passValidations}>
                          { !min ? <CircleIcon /> : <CircleTickIcon />}
                          <p>{t('passwordRule1')}</p>
                      </div>

                      <div className={style.passValidations}>
                          { !hasSpecialCharacter ? <CircleIcon /> : <CircleTickIcon />}
                          <p>{t('passwordRule2')}</p>
                      </div>

                      <div className={style.passValidations}>
                          { !hasUppercase ? <CircleIcon /> : <CircleTickIcon />}
                          <p>{t('passwordRule3')}</p>
                      </div>
                      </div>
                  )}
          
                  
                  { registerError && 
                    ((registerError as FetchBaseQueryError).status === 409) && (
                      <Alert type='error' text={t('error-user-already-exists')}/>
                    )
                  }

                  {  registerError && 
                    (registerError as FetchBaseQueryError).status === 500 && (
                      <Alert type='error' text={t('something-went-wrong')}/>
                    )
                  }

                  {  registerError && 
                    (registerError as FetchBaseQueryError).status === 400 && (
                      <Alert type='error' text={t('400-form-error')}/>
                    )
                  }

                  <div style={{marginBottom: '5px', marginTop: '20px'}}>
                    <Button
                      variant={'NEUTRAL'}
                      onClick={() => {handleSubmitForm()}}
                      text={t('acceder')}
                    />
                  </div>
    
                  <p style={{marginTop: '20px'}}>{t('or-continue-with')}:</p>
                
                  <GoogleLogin
                    onSuccess={async (credentialsResponse) => {
                      if (credentialsResponse.credential) {
                        googleLogin(credentialsResponse.credential)
                      }
                    }}
                    onError={() => console.log('Login failed')}
                    text='signin'
                  />
                </form>
              </div>
            )}
            
          </SidebarBody>
  
          <SidebarFooter 
            sx={{ 
              width: '100%', 
              background: '#47c2785e', 
              color: style.light, 
              display: 'flex', 
              justifyContent: 'center', 
              padding: '30px 30px', 
            }}
          >
            <p 
              style={{ fontSize: '16px', fontWeight: 600, cursor: 'pointer' }}
              onClick={() => navigate('/')}
            >
                {t('login')}
            </p>
          </SidebarFooter> 
        </Sidebar>

        { (isLoading || isLoadingGoogle) && (
          <div className={style.spinnerContainer}>
            <img src={spinner} style={{width: '100px'}} />
          </div>
        )}
      </div>
    );
}
  
export const RegisterCentralArea = () => {
    const { t } = useTranslation();
  
    return (
      <div className={style.wrapper}>
        <CentralArea sx={{height: '100%'}}>
          <RegisterSidebar />
  
          <div className={style.imageContainer} >
            <div className={style.imageText}>
              <p>{t('vistaguay-business')}</p>
  
              <h1>{t('comenza-a-tomar-decisiones-agtech')}</h1>
            </div>
          </div>
        </CentralArea >
      </div>
    );
};