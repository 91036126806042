import { Modal, useMediaQuery } from '@mui/material';
import * as Plot from '@observablehq/plot';
import { ReactComponent as PanIcon } from 'assets/icons/panableIcon.svg';
import useToggle from 'hooks/useToggle';
import { useEffect,useState } from 'react';

import styles from './Plot.module.scss';
import PlotFigure from './PlotFigure';

export enum PlotSizes {
  sm = 'small',
  md = 'medium',
  lg = 'large'
}

interface PlotStyledProps {
  title: string;
  unitText: string;
  options: Plot.PlotOptions;
  size: PlotSizes;
}

export default function PlotStyled({ title, unitText, options, size }: PlotStyledProps) {
  const { toggle, isActive } = useToggle();
  const [plotSize, setPlotSize] = useState({ height: 0, width: 0 });

  const getSizes = () => {
    const root = getComputedStyle(document.documentElement);
    const height = parseInt(root.getPropertyValue('--plot-height').trim().replace('px', 'vh'), 10);
    const width = parseInt(root.getPropertyValue('--plot-width').trim().replace('px', 'vw'), 10);
    setPlotSize({ height, width });
  };

  useEffect(() => {
    getSizes(); // Initial size calculation
    window.addEventListener('resize', getSizes); // Update on window resize

    return () => window.removeEventListener('resize', getSizes); // Cleanup listener on unmount
  }, []);

  return (
    <section className={styles.plotConteiner}>
      <div className={styles.plot} style={{ height: plotSize.height, width: plotSize.width }}>
        <PlotFigure options={{ ...options, height: plotSize.height, width: plotSize.width }} />
      </div>
      <div className={styles.plotHeader}>
        <div className={styles.plotTitle}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h2 style={{ fontSize: '2vh' }}>{title}</h2>
            <span style={{ fontSize: '1.5vh' }}>{unitText}</span>
          </div>
          <div className={styles.plotLabelIcon}>
            <PanIcon onClick={toggle} />
          </div>
        </div>
      </div>

      <Modal
        open={isActive}
        onClose={toggle}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '20px' }}
      >
        <div className={styles.plot} style={{ height: '500px', width: '900px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', backgroundColor: '#f2f2f2' }}>
          <PlotFigure options={{ ...options, height: 500, width: 800 }} />
        </div>
      </Modal>
    </section>
  );
}
