import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import { useGetReportDataQuery } from 'api/Vistaguay/ReportApi';
import Spinner from 'components/Spinner/Spinner';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import { useParams } from 'react-router';

import { CountTaskReport } from '../CountReport/CountTaskReport';

export function PublicTaskView() {
  const { token } = useParams<{
    token: string;
  }>();

  if (!token) {
    return null; // FIXME handle error
  }
  const { data: reportData, isLoading: isLoadingReportData } = useGetReportDataQuery({ token });
  const { data: extraData, isLoading: isLoadingExtraData } = useGetExtraDataQuery();

  const IS_LOADING = isLoadingReportData || isLoadingExtraData;
  const SHOW_CONTENT = !IS_LOADING && reportData;

  return (
    <>
      <Sidebar style={{ width: '180%', padding: '0px' }}>
        {IS_LOADING && <Spinner />}
        {
          <>
            <SidebarBody sx={{ padding: '0px' }}>
              {IS_LOADING && <Spinner />}
              {SHOW_CONTENT && (
                <>
                  <CountTaskReport
                    project={reportData.lot.project}
                    lot={reportData.lot}
                    task={reportData}
                    photosMetadata={reportData.photosMetadata}
                    countMetadata={reportData.countMetadata}
                    extraData={extraData}
                    selectedCampaign={reportData.campaign}
                    prescription={reportData.prescription}
                  />
                </>
              )}
            </SidebarBody>
          </>
        }
      </Sidebar>
    </>
  );
}
