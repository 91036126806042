import { Input } from 'components/Inputs/Input'
import { useState } from 'react';

import styles from './Steps.module.scss'

export default function StepOne() {

    const [rotation, setRotation] = useState(60);
    const handleRotationChange = (event: any) => {
        setRotation(event.target.value);
    };
    return (
        <div style={{ marginTop: '10px', gap: '5px' }}>
            <Input placeholder={'Escribe el nombre de la capa'} />
            <div className={styles.containerOne}>
                <div className={styles.columns}>
                    <Input placeholder={'Rows'} type='number' />
                    <Input placeholder={'Width'} type='number' />
                    <Input placeholder={'Range gap'} type='number' />
                </div>
                <div className={styles.columns}>
                    <Input placeholder={'Ranges'} type='number' />
                    <Input placeholder={'Height'} type='number' />
                    <Input placeholder={'Row gap'} type='number' />
                </div>
            </div>
            <label style={{ marginTop: '5px' }}>Rotation <span>{rotation}</span>°</label>
            <input style={{ margin: '0' }} type='range' min='0' max='120' value={rotation} onChange={handleRotationChange}></input>
        </div>
    )
}
