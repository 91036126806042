import * as Plot from '@observablehq/plot';
import { useEffect, useRef } from 'react';

export default function PlotFigure ({options} : {options: Plot.PlotOptions}) {

    const containerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const plot = Plot.plot({
            ...options
        })
        containerRef.current?.append(plot)
        return () => plot.remove();
    },[options])

    return <div ref={containerRef}/>
}