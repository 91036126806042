import React, { useCallback, useState } from 'react';

import styles from './Modal.module.scss';

interface ModalProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  isOpen: boolean;
  closeComponent?: React.ReactElement | string;
  closeFn: () => void;
}

function Modal({ children, isOpen, closeFn, closeComponent, header }: ModalProps) {
  
  if (!isOpen) {
    return null;
  }

  // on ESC key press close modal
  const escFunction = useCallback((event:any) => {
    if (event.keyCode === 27) {
      closeFn();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  // On click outside modal close modal
  const handleClickOutside = (event: any) => {
    if (event.target.className === styles.modal) {
      closeFn();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <button className={styles.modalClose} onClick={closeFn}>
            {closeComponent || 'X'}
          </button>
          <div className={styles.modalTitle}>{header}</div>
        </div>
        <div className={styles.modalBody}> {children}</div>
      </div>
    </div>
  );
}
export default Modal;
