// Adeministar créditos de sus usuarios
// crear usuarios en su grupo
// modificar y elminar usuarios de su grupo
// moodificar y eliminar campañas proyectos lotes y tareas de su grupo
// ver resúmenes (resta definir)

import { Icon } from '@blueprintjs/core';
import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import {
  useAddToGroupMutation,
  useGetGroupInfoQuery,
  useGetUsersGroupQuery,
  useRemoveToGroupMutation,
  useTransferMutation,
} from 'api/Vistaguay/UserGroupAPI';
import { useMeQuery } from 'api/Vistaguay/VistaguayAPI';
import Button from 'components/Button/Button';
import { Table } from 'components/Table/Table';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { AddUser } from './AddUser';

// servicios:
//     transferir créditos
//     crear usuario en grupo
//     ver suarios de grupo
//     agregar usuario externo a grupo (admin general) -> en front se podría meter en el panel contro admin gral créditos
//     remover usuario de grupo

//     get resumen (cant de tareas, cant de usuarios, cant de lotes, cant de campanas?)

//   useGetUsersGroupQuery
// useGetGroupInfoQuery
// useAddToGroupMutation
// useRemoveToGroupMutation
// const addUser = () => {

interface IColumn {
  property: string;
  label: string;
}

const COLUMNS = [
  {
    property: 'email',
    label: 'Email',
  },

  {
    property: 'nombre',
    label: 'Nombre',
  },
  {
    property: 'apellido',
    label: 'Apellido',
  },
  {
    property: 'razonSocial',
    label: 'Razon Social',
  },
  {
    property: 'country',
    label: 'País',
  },
  {
    property: 'cuit',
    label: 'Cuit',
  },
  {
    property: 'credit.amount',
    label: 'Créditos',
  },
  {
    property: 'loadCredits',
    label: 'Transferir Creditos',
  },
  {
    property: 'quitGroup',
    label: 'Sacar grupo',
  },
];

const UserTable = ({ users }: any) => {
  const [removeToGroup, { isLoading: isLoadingRemoveToGroup }] = useRemoveToGroupMutation();
  const [transfer, { isLoading: isLoadingTransfer }] = useTransferMutation();
  const { data: me } = useMeQuery();

  const handleAddCredit = (userId: number) => {
    const monto = prompt('Monto: ', '0');
    if (!monto || !me) return;
    const resp = transfer({ userFrom: me?.usuarioId, userTo: userId, amount: +monto });
  };

  const handleRemoveCredit = (userId: number) => {
    const monto = prompt('Monto: ', '0');
    if (!monto || !me) return;
    const resp = transfer({ userFrom: userId, userTo: me?.usuarioId, amount: +monto });
  };

  return (
    <>
      {users && (
        <>
          <Table
            data={users?.map((user: any) => ({
              ...user,
              loadCredits: (
                <>
                  <Icon
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleAddCredit(user.usuarioId)}
                    icon='add'
                    size={20}
                  />
                  <Icon
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveCredit(user.usuarioId)}
                    icon='delete'
                    size={20}
                  />
                </>
              ),
              quitGroup: (
                <Icon
                  style={{ cursor: 'pointer' }}
                  onClick={() => removeToGroup({ userId: '' + user.usuarioId })}
                  icon='delete'
                  size={20}
                />
              ),
            }))}
            columns={COLUMNS}
          />
        </>
      )}
      {/* // <PaginatorFooter
    //   handleNextPage={() => setPage(page + 1)}
    //   handlePrevPage={() => setPage(page - 1)}
    //   page={page}
    //   totalPages={Math.ceil(total / pageSize)}
    // /> */}
    </>
  );
};

const TASKS_COLUMNS = [
  {
    property: 'conteosPlantasId',
    label: 'Id',
  },
  {
    property: 'nombre',
    label: 'Nombre',
  },
  {
    property: 'superficie',
    label: 'Superficie',
  },
  {
    property: 'superficie',
    label: 'Superficie',
  },
  {
    property: 'estadoId',
    label: 'Estado',
  },
  {
    property: 'crop',
    label: 'Cultivo',
  },
  {
    property: 'estadio',
    label: 'Estadio',
  },
  {
    property: 'hibrid',
    label: 'Hibrido',
  },
  {
    property: 'sowing',
    label: 'Sembradora',
  },
  {
    property: 'creator.email',
    label: 'Usuario',
  },
  {
    property: 'link',
    label: 'Link',
  },
];

const TaskTable = ({ tasks }: any) => {
  const { data: extraData, isLoading: isLoadingExtraData } = useGetExtraDataQuery();

  const extractData = (task: any) => {
    const crop = extraData?.crops.find((c) => c.id === task.cultivoId)?.name || ' ';
    const estadio =
      extraData?.crops
        .find((c) => c.id === task.cultivoId)
        ?.stages.find((s) => s.id === task.estadioId)?.name || ' ';
    const hibrid =
      extraData?.crops
        .find((c) => c.id === task.cultivoId)
        ?.hybrids.find((h) => h.id === task.hibridoId)?.name || ' ';
    const sowing = extraData?.seeders.find((s) => s.id === task.sembradoraId)?.name;
    return {
      ...task,
      crop,
      estadio,
      hibrid,
      sowing,
      link: <a href={`/public/task/${task.encriptedData}`}>Ver Tarea</a>,
    };
  };

  if (!extraData) {
    return <Spinner />;
  }
  return (
    <>
      {tasks && (
        <>
          <div></div>
          <Table data={tasks.tasks?.map(extractData)} columns={TASKS_COLUMNS} />
          <Table
            data={tasks.mosaics?.map((task: any) => ({
              ...task,
            }))}
            columns={TASKS_COLUMNS}
          />
        </>
      )}
    </>
  );
};

export const GroupAdmin = () => {
  const [showAddUser, setShowAddUser] = useState(false);
  const { data: users, isLoading: isLoadingUsers } = useGetUsersGroupQuery();
  const { data: tasks, isLoading: isLoadingGroupInfo } = useGetGroupInfoQuery();
  const [addToGroup, { isLoading: isLoadingAddToGroup }] = useAddToGroupMutation();

  const taskCount = tasks?.tasks?.length;
  const mosaicCount = tasks?.mosaics?.length;
  const userCount = users?.length;
  const area = tasks?.tasks.reduce((acc: number, task: any) => acc + task.superficie, 0);

  return (
    <>
      <div>
        <h1>Users</h1>
      </div>
      <div>{users && <UserTable users={users} />}</div>
      <div>
        <Button
          variant={'SUCCESS'}
          text='Crear Usuario'
          onClick={() => setShowAddUser(!showAddUser)}
        />
        {showAddUser && (
          <AddUser
            handdleSend={() => {
              setShowAddUser(!showAddUser);
            }}
          />
        )}
        {/* <pre>--::{users != undefined && JSON.stringify(users, null, 2)}</pre> */}
      </div>
      <div>Group Info</div>
      <div> Cantidad de ususarios: {userCount}</div>
      <div> Cantidad de conteos: {taskCount}</div>
      <div> Cantidad de mosaicos: {mosaicCount}</div>
      <div> Cantidad de hectareas: {area}</div>
      <div>
        <pre>{tasks != undefined && <TaskTable tasks={tasks} />}</pre>
      </div>
    </>
  );
};
