import { useGetWmsQuery } from 'api/Vistaguay/MosaicAPI';
import { interpolateValue } from 'components/Histogram/Histogram';
import { getColorInterpolators, getHexaColorFromRgb } from 'pages/TaskList/TaskListContextual/MosaicContextual/Options/HistogramOption';
import { WmsAlgorithm, WmsDynamicStyle } from 'pages/Tasks/Mosaics/MosaicsViewMap/WmsAlgoritms';
import { useAppSelector } from 'state/hooks';
import { MosaicTaskView } from 'state/slices/mosaicSlice';

import PhotoPointsMosaic from '../PhotoPoints/PhotoPointsMosaic';

interface IMosaicMap {
    mosaicView: MosaicTaskView;
}

function MosaicsMaps () {
    const mosaics = useAppSelector((state) => state.mosaic.mosaics);

    return (
      <>
        {mosaics.map((mosaic, index) => {
          return <MosaicMap key={index} mosaicView={mosaic} />;
        })}
      </>
    );
};
    
  
function MosaicMap ({ mosaicView }: IMosaicMap) {
    const { data: wmsData } = useGetWmsQuery({ taskId: mosaicView.id }, { skip: !mosaicView.id });
    const histogramView = mosaicView.histogramView;
    const params = [];
  
    const allTasks = useAppSelector((state) => state.task.tasks);
    const visible = allTasks.filter((task) => task.taskId === mosaicView.id.toString())[0]?.visualization?.visible;
  
    if (histogramView) {
      const colorInterpolator = getColorInterpolators(histogramView?.colorRamp || 'RdYlGn');
      const colorZones = [];
      for (let i = 1; i < histogramView?.stepValues.length; i++) {
        colorZones.push(
          colorInterpolator(
            interpolateValue(
              (histogramView.stepValues[i - 1] + histogramView.stepValues[i]) / 2,
              histogramView.min,
              histogramView.max,
            ),
          ),
        );
      }
      const cantValores = 9;
      const distance = Math.abs((histogramView.max - histogramView.min) / cantValores);
      // FIXME hay un problema con los límites de colores
      // doc de geoserver https://docs.geoserver.org/main/en/user/styling/sld/reference/rastersymbolizer.html
      if (histogramView.grouped && histogramView.steps) {
        params.push(`step1Color:${getHexaColorFromRgb(colorZones[0])}`);
        params.push(`step1Qty:${(histogramView.stepValues[0] - 1).toFixed(2)}`);
        params.push(`step1Opa:${histogramView.cutValues ? 0 : 1}`);
  
        params.push(`step2Color:${getHexaColorFromRgb(colorZones[0])}`);
        params.push(`step2Qty:${histogramView.stepValues[0]}`);
        params.push('step2Opa:1');
        for (let i = 1; i < histogramView.stepValues.length; i++) {
          // params.push(`step${i + 2}Color:${getHexaColorFromRgb(colorZones[i])}`);
          params.push(`step${i + 2}Color:${getHexaColorFromRgb(colorZones[i - 1])}`);
          params.push(`step${i + 2}Qty:${histogramView.stepValues[i].toFixed(2)}`);
          params.push(`step${i + 2}Opa:1`);
        }
        for (let i = histogramView.stepValues.length; i <= cantValores - 1; i++) {
          params.push(`step${i + 2}Color:${getHexaColorFromRgb(colorZones[colorZones.length - 1])}`);
          params.push(
            `step${i + 2}Qty:${(histogramView.stepValues[histogramView.stepValues.length - 1] + i).toFixed(2)}`,
          );
          params.push(`step${i + 2}Opa:${histogramView.cutValues ? 0 : 1}`);
        }
      } else {
        for (let i = 0; i <= cantValores; i++) {
          params.push(`step${i + 1}Color:${getHexaColorFromRgb(colorInterpolator(i / cantValores))}`);
          params.push(`step${i + 1}Qty:${(+histogramView.min + i * distance).toFixed(2)}`);
          params.push(`step${i + 1}Opa:1`);
        }
      }
    }
  
    return (
      <>
        { wmsData && mosaicView.layers.find((l) => l.visible === true) && (
          <>
            {histogramView && (
              <WmsDynamicStyle
                key={histogramView.layer}
                wmsData={wmsData[histogramView.layer]}
                visible={true}
                opacity={1}
                max={histogramView.max}
                min={histogramView.min}
                params={params}
              />
            )}
  
            { wmsData &&
              !histogramView &&
              mosaicView?.layers.map((layer, index) => (
                <WmsAlgorithm
                  key={layer.layerName + index}
                  wmsData={wmsData[layer.layerName]}
                  visible={mosaicView.layers[index].visible}
                  opacity={mosaicView.layers[index].opacity}
                />
            ))}
  
            {
              mosaicView.showPhotoPoints && <PhotoPointsMosaic taskId={String(mosaicView.id)}/>
            }
          </>
        )}
      </>
    )
};

export {
    MosaicsMaps,
    MosaicMap
}