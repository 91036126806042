import Button from 'components/Button/Button'
import { useNavigate } from 'react-router-dom'

import style from './Auxiliar.module.scss'
export default function NotFound () {
    const navigate = useNavigate()
    return (
        <div className={style.notfound}>
            <h1>404</h1>
            <h4>Page not found</h4>
            <Button text='GO BACK TO OUR HOMEPAGE' onClick={() => navigate('/')} variant={'SUCCESS'} style={{width:'300px'}}/>
        </div>
    )
}