import { Polygon } from '@turf/turf';
import { GeoJSONFeature } from 'models/GeoJSON';
import GeoJSON from 'ol/format/GeoJSON';
import { Draw } from 'ol/interaction';
import React, { useContext, useEffect } from 'react';
import { dataProjection, featureProjection } from 'services/configurations';

import MapContext from '../../Context/MapContext';
import VectorLayerContext from '../../Context/VectorLayerContext';

interface DrawProps {
  onDrawEnd: (polygon: GeoJSONFeature<Polygon>) => void;
}

function MPolygonDraw({ onDrawEnd }: DrawProps) {
  const vectorLayer = useContext(VectorLayerContext);
  const map = useContext(MapContext);

  useEffect(() => {
    const source = vectorLayer?.getSource();
    const geojson = new GeoJSON({
      dataProjection: dataProjection,
      featureProjection: featureProjection,
    });

    if (source) {
      const draw = new Draw({
        type: 'Polygon',
      });
      draw.on('drawend', (e) => {
        const polygon = geojson.writeFeatureObject(e.feature) as GeoJSONFeature<Polygon>;
        onDrawEnd(polygon);
        // (e.feature.getGeometry() as Polygon).appendLinearRing(
        //   new LinearRing([
        //     [0, 0],
        //     []
        map?.removeInteraction(draw);
      });
      map?.addInteraction(draw);

      return () => {
        map?.removeInteraction(draw);
      };
    }
  }, [map, onDrawEnd, vectorLayer]);

  return <></>;
}

export default MPolygonDraw;
