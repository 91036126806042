import { ReactComponent as Arrow } from 'assets/icons/ico-next.svg';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './PaginatorFooter.module.scss';



interface PaginatorFooterProps {
    page: number;
    totalPages: number;
    handleNextPage: () => void;
    handlePrevPage: () => void;
}


export const PaginatorFooter = (props: PaginatorFooterProps) => {
    const { t } = useTranslation();
    return (
    <div className={styles.paginator}>
                    <Arrow
                      onClick={props.page>1 ? props.handlePrevPage : undefined}
                      style={{ transform: 'rotate(180deg)' }}
                      className={cx(styles.icon, styles.prevArrow, props.page===1 && styles.disabled)}
                    />
                   
                      <p>
                        {props.page} {t('of')} {props.totalPages}
                      </p>
                    
        <Arrow onClick={
          props.page < props.totalPages ? props.handleNextPage : undefined} className={cx(styles.icon, styles.nextArrow,
          props.page===props.totalPages && styles.disabled
          )} />
                  </div>
)
}