import { useGetLotQuery } from 'api/Vistaguay/LotAPI'
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI'
import Button from 'components/Button/Button'
import { Stepper } from 'components/Steppers/Stepper/Stepper'
import Sidebar from 'layout/Sidebar/Sidebar'
import SidebarBody from 'layout/Sidebar/SidebarBody'
import SidebarFooter from 'layout/Sidebar/SidebarFooter'
import SidebarHeader from 'layout/Sidebar/SidebarHeader'
import { SlimHeader, SubHeader } from 'layout/Sidebar/SubHeader'
import { Campaign } from 'models/Campaign'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'state/hooks'

import StepOne from './Steps/StepOne'
import StepThree from './Steps/StepThree'
import StepTwo from './Steps/StepTwo'

export default function FormFinishProcess() {
    const selectedCampaign: Campaign | null = useAppSelector(
        (state) => state.campaign.selectedCampaign,
      );
    const { projectId, lotId, analyticsId } = useParams<{ projectId: string; lotId: string; analyticsId: string }>();
    // Data SlimHeader
    if (!projectId || !lotId || !analyticsId) {
        return null; // FIXME handle error
    }
    const { data: project } = useGetProjectQuery(projectId as unknown as number);
    const { data: lot } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });
    const [currentStep, setCurrentStep] = useState<number>(1);
    const MAX_STEP = 3;
    const navigate = useNavigate();

    const handleNextStep = () => {
        if (currentStep < MAX_STEP) {
            setCurrentStep((value) => value + 1)
        }
        else if (currentStep == 3) {
            navigate(`/projects/${projectId}/lots/${lotId}/analytics/${analyticsId}`)
        }
    }

    const handlePreviousStep = () => {
        if (currentStep == 1) {
            navigate(`/projects/${projectId}/lots/${lotId}/analytics/${analyticsId}`)
        }
        setCurrentStep((value) => value - 1)

    }

    const steps = [
        <StepOne key={0} />,
        <StepTwo key={1} />,
        <StepThree key={2} />
    ]
    return (
        <Sidebar>
            <SidebarHeader></SidebarHeader>
            <SubHeader noBack={true}>
                <SlimHeader
                    campaignName={selectedCampaign?.name}
                    projectName={project?.nombre}
                    projectColor={project?.color}
                    lotName={lot?.name}
                    backTo={`/projects/${projectId}/lots/${lotId}`}
                    backText='Cancelar ensayo'
                />
            </SubHeader>
            <SidebarBody>
                <Stepper StepAmount={MAX_STEP} StepCurrent={currentStep} title={''} noHeader={true} />
                {steps[currentStep - 1]}
            </SidebarBody>
            <SidebarFooter>
                <Button variant='ERROR' text='Anterior' onClick={handlePreviousStep} />
                <Button variant='SUCCESS' text='Siguiente' onClick={handleNextStep} />
            </SidebarFooter>
        </Sidebar>
    )
}
