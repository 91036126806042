import MapWrapper from 'components/Map/Map';
import ModalGlobalComponent from 'components/Modals/ModalGlobalComponent';
import { useAppSelector } from 'state/hooks';

import CentralArea from './Centralarea/Centralarea';

interface SidebarAndMapProps {
  Sidebar: () => JSX.Element | null;
  Map: () => JSX.Element | null;
  haveMap: boolean;
}

export default function SidebarAndMap({ Sidebar, Map, haveMap }: SidebarAndMapProps) {
  const { modalGlobalState } = useAppSelector((state) => state.ui);

  if (haveMap) {
    return (
      <>
        <Sidebar />
        <CentralArea>
          <MapWrapper>
            <Map />
          </MapWrapper>
        </CentralArea>

        {
          modalGlobalState.isOpen && (
            <ModalGlobalComponent />
        )}
      </>
    );
  }
  return (
    <>
      <Sidebar />
      <CentralArea>
        <Map />
      </CentralArea>
      
      {
        modalGlobalState.isOpen && (
          <ModalGlobalComponent />
      )}
    </>
  );
}
