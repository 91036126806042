

export const LOG_LEVEL: string = process.env.REACT_APP_LOGGER_LEVEL || 'debug';
export const API_URL: string = process.env.REACT_APP_VISTAGUAY_BACKEND_URL || 'http://localhost:8000/api/';

export const MP_PUBLIC_KEY: string = process.env.MP_PUBLIC_KEY || 'TEST-bcc21a46-3e2f-4237-9ed4-9738a85c0130';
export const PAYPAL_PUBLIC_KEY: string = process.env.PAYPAL_PUBLIC_KEY || 'AbgP8YBTet1dYhsDgDzdTVI6kWYciNtaou1qJd8vXcU06dKoqNCyxRtNTir0JzvYFaj4WgVPB5M3c_qS'
export const GOOGLE_AUTH_CLIENT_ID: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || '1000000000000-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx.apps.googleusercontent.com'


export const dataProjection = process.env.REACT_DATA_PROJECTION || 'EPSG:4326';
export const featureProjection = process.env.REACT_FEATURE_PROJECTION ||'EPSG:3857';

