import { useGetCreditQuery, useGetPricesQuery } from 'api/Vistaguay/CreditAPI';
import { ReactComponent as CreditsIcon } from 'assets/icons/ico-credits-outline.svg';
import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';
import BuyCredits from 'components/Credits/BuyCredits';
import Skeleton2 from 'components/Skeleton2/Skeleton2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorList, ErrorProps } from '../AddPlantCount';
import styles from '../Map/AddCountMap.module.scss';

interface StepProps {
  nextStepTouched: boolean;
  currentStep: number;
  step: number;
  handleError: (error: boolean) => void;
  fileList: File[];
}

export default function StepCredit({ 
  currentStep,
  step,
  nextStepTouched,
  handleError, 
  fileList 
}: StepProps) {
  const { t } = useTranslation();

  const { data: credit, isLoading: creditsLoading, isSuccess: creditSuccess } = useGetCreditQuery();
  const { data: prices, isLoading: pricesLoading, isSuccess: pricesSuccess } = useGetPricesQuery();
  const [buy, setBuy] = useState<boolean>(false);
  const [errorsList, setErrors] = useState<ErrorList[]>([]);
  const isPricesInvalid = !prices || Object.keys(prices).length === 0;

  useEffect(() => {
    if(currentStep == step) {
      const errors: ErrorList[] = [];
      let error = false; 

      if(creditsLoading) {
        errors.push({ message: t('form-credits-error'), propName: 'loadingCredits' });
      }

      if(isPricesInvalid) {
        errors.push({ message: t('form-prices-error'), propName: 'prices' });
      }

      if(!creditSuccess || !pricesSuccess) {
        errors.push({ message: t('form-credits-error'), propName: 'credits'});
      } else if(prices && credit && fileList.length * prices.planting_quality >= credit.amount) {
        errors.push({ message: t('form-credits-not-enougth'), propName: 'credits'});
      }

      error = errors.length > 0;

      setErrors(errors);
      handleError(error);
    }
  }, [
    isPricesInvalid,
    currentStep,
    creditsLoading,
    creditSuccess,
    pricesSuccess,
    prices,
    credit,
    fileList,
    handleError,
  ]);

  return (
    <>
      {buy && fileList && prices && credit ? (
        <BuyCredits
          vpack={false}
          stepper={false}
          amount={Math.abs(fileList.length * prices.planting_quality)}
        />
      ) : (
        <>
          <div>
            {creditsLoading || pricesLoading ? (
              <Skeleton2 />
            ) : isPricesInvalid ? (
              <div style={{marginTop: '20px'}}>  
                <Alert type='error' text={t('something-went-wrong')}/>
              </div>
            ) : (
              <ul className={styles.creditList}>
                <li className={styles.creditItem}>
                  <div className={styles.row}>
                    <div className={styles.icon}></div>
                    <p>{t('available-credits')}</p>
                  </div>
                  <p>{credit?.amount}</p>
                </li>
                <li className={styles.creditItem}>
                  <div className={styles.row}>
                    <div className={styles.icon}><CreditsIcon /></div>
                    <p>{t('necesary-credits')}</p>
                  </div>
                  {fileList && prices && <p>{fileList.length * prices.planting_quality}</p>}
                </li>
                <li className={styles.creditItem}>
                  <div className={styles.row}>
                    <div className={styles.icon}></div>
                    <p>{t('remaining-credits')}</p>
                  </div>
                  {fileList && credit && (
                    <p
                      style={{
                        color: fileList.length * (prices?.planting_quality ?? 0) >= credit.amount
                            ? styles.rojo
                            : styles.dark,
                      }}
                    >
                      {credit.amount - fileList.length * (prices?.planting_quality ?? 0)}
                    </p>
                  )}
                </li>
              </ul>
            )}
          </div>
          {nextStepTouched && errorsList.map((error, index) => {
            if (error.propName === 'credits') {
              return <Alert key={index} type='error' text={error.message} />;
            } else {
              return null;
            }
          })}
          {fileList && prices && credit && fileList.length * prices.planting_quality >= credit.amount && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '40px',
              }}
            >
              <Button text='Comprar' onClick={() => setBuy(true)} variant='SUCCESS' />
            </div>
          )}
        </>
      )}
    </>
  );
}
