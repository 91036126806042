import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Campaign } from 'models/Campaign';

export interface CampaignState {
  selectedCampaign: Campaign | null;
  searchIsOpen: boolean;
}

const initialState: CampaignState = {
  selectedCampaign: Campaign.getSelectedCampaignFromLocalStorage(),
  searchIsOpen: false,
} as CampaignState;

const reducer = {
  resetCampaignStatus: (state: CampaignState, action: PayloadAction<void>) => {
    localStorage.removeItem('selectedCampaign')
    state.selectedCampaign = initialState.selectedCampaign
  },
  setSelectCampaign: (state: CampaignState, action: PayloadAction<Campaign>) => {
    state.selectedCampaign = action.payload;
    Campaign.setSelectedCampaignFromLocalStorage(action.payload);
  },
  setSearchToggle: (state: CampaignState) => {
    state.searchIsOpen = !state.searchIsOpen;
  },
};

export const campaignSlice = createSlice({
  name: 'campaignSlice',
  initialState,
  reducers: reducer,
});

// Action creators are generated for each case reducer function
export const { setSelectCampaign, setSearchToggle, resetCampaignStatus } = campaignSlice.actions;

export default campaignSlice.reducer;
