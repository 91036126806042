// #region IMPORTS
// #region React
import { ReactComponent as LotIcon } from 'assets/icons/polygonsquare.svg';
// #endregion
// #region Models
import { Campaign } from 'models/Campaign';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
// #endregion
// #region Redux States
import { useAppDispatch, useAppSelector } from 'state/hooks';

// #endregion
// #region Icons
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowIcon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/ico-calendar.svg'
import { ReactComponent as FolderrIcon } from '../../assets/icons/ico-folder.svg'
// #endregion
// #region Styles
import styles from './Sidebar.module.scss';
// #endregion
// #endregion
interface SidebarHeaderProps {
  children?: React.ReactNode;
  onlyBackground?: boolean;
}

const SidebarHeader = ({ children, onlyBackground = false }: SidebarHeaderProps) => {
  // #region Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  // #endregion

  // #region Redux States
  const selectedCampaign: Campaign | null = useAppSelector((state) => state.campaign.selectedCampaign);
  const currentProjectId = useAppSelector((state) => state.ui.currentProjectId);
  // #endregion

  // #region Variables
  const isCurrentRouteProject = location.pathname.includes('projects');
  const lots = Number(location.pathname.split('/')[2])
  const currentLot = typeof lots === 'number' && !isNaN(lots) ? true : false;
  const redirectToLots = `/projects/${currentProjectId}`;
  // #endregion

  return (
    <div className={styles.sidebarHeader}>
      {/* {onlyBackground && children} */}
      <div className={styles.header}>
        {/* CAMPAIGNS */}
        <div style={{display: 'flex'}}  >
          <div className={styles.headerItem} onClick={() => navigate('/campaign/search')}>
            <div style={{minHeight: '20px'}}>
              <CalendarIcon width={20}/>
            </div>
            
            <b className={styles.headerLabel}>{t('campaigns')}</b>
          </div>
         
          { selectedCampaign != null && isCurrentRouteProject && (
            <div className={styles.headerArrow}>
              <ArrowIcon height={30}/>
            </div>
          )}
        </div>

        {/* PROJECTS  */}
        { selectedCampaign != null && isCurrentRouteProject && (
          <div style={{display: 'flex'}}  >
            <div className={styles.headerItem} onClick={() => navigate('/projects')}> 
              <div style={{minHeight: '20px'}}>   
                <FolderrIcon />
              </div>
              <b className={styles.headerLabel}>{t('projects')}</b>
            </div>

            {selectedCampaign != null && currentLot && (
              <div className={styles.headerArrow}>
                <ArrowIcon height={30}/>
              </div>
            )}
          </div>
          
        )}

        {/* LOTS  */}
        { selectedCampaign != null && currentLot && (
          <div className={styles.headerItem} onClick={() => navigate(redirectToLots)}>
            <div style={{minHeight: '20px'}}>   
              <LotIcon />
            </div>
            <b className={styles.headerLabel}>{t('lots')}</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarHeader;
