
import { Icon, Spinner } from '@blueprintjs/core';
import {ReactComponent as CheckIcon} from 'assets/icons/checkcircle-icon.svg'
import {ReactComponent as ErrorIcon} from 'assets/icons/minusCircleIcon.svg'
import clsx from 'classnames'
import React from 'react';

import styles from './Button.module.scss'
export enum ButtonSizesEnum {
    Small = 0,
    Medium = 1,
    Big = 2,
    Xxl = 3
}

interface AnimatedButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    children?: React.ReactNode
    size?: ButtonSizesEnum
}


export default function AnimatedFetchButton({size,children,isLoading,isSuccess,isError,...props} : AnimatedButtonProps) {
    return (
        <button
            className={
                clsx(
                    styles.animatedButton,
                    isSuccess && styles.animatedSuccess,
                    isError && styles.animatedError,
                    isLoading && styles.animatedLoading,
                    size == ButtonSizesEnum.Small && styles.animatedSmall,
                    size == ButtonSizesEnum.Medium && styles.animatedMedium,
                    size == ButtonSizesEnum.Big && styles.animatedBig,
                    size == ButtonSizesEnum.Xxl && styles.animatedXxl
                )
            }
            disabled={isLoading}
            {...props}
        >
            {
                children && isError || isSuccess || isLoading ?  
                (
                    <>
                        <div className={styles.icon}>
                            {
                                isSuccess && <CheckIcon/>
                            }
                            {
                                isError && <Icon icon='cross-circle'/>
                            }
                            {
                                isLoading && <Spinner color={'#f2f2f2'} size={20}/>
                            }

                        </div>
                        {children}
                    </>
                )
                :
                (
                    <>
                        {
                            children
                        }
                    </>
                ) 
            }
           
        </button>
    )
}