
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon.svg'
import Feature from 'ol/Feature.js';
import Geolocation from 'ol/Geolocation.js';
import { Geometry } from 'ol/geom';
import Point from 'ol/geom/Point.js';
import { Size } from 'ol/size';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style.js';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { dataProjection, featureProjection } from 'services/configurations';

import MapContext from '../Context/MapContext';
import VectorLayerContext from '../Context/VectorLayerContext';
import styles from './Measure/MMeasure.module.scss'


export default function MGeolocateTool() {
    const map = useContext(MapContext)
    const vectorLayer = useContext(VectorLayerContext)
    const buttonGps = useRef<HTMLButtonElement>(null)
    const view = map?.getView()
    const source = vectorLayer?.getSource()

    const [isActive, setIsActive] = useState<boolean>(false)

    const positionFeature = useMemo(() => {
        return new Feature(
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: '#3399CC',
                    }),
                    stroke: new Stroke({
                        color: '#fff',
                        width: 2,
                    }),
                }),
            })
        );
    }, [])



    const geolocation = useMemo(() => {
        return new Geolocation({
            trackingOptions: {
                enableHighAccuracy: false,
            },
            projection: featureProjection,

        });
    }, [view]);

    const accuracyFeature = useMemo(() => {
        return new Feature(
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: '#3399CC',
                    }),
                    stroke: new Stroke({
                        color: '#fff',
                        width: 2,
                    }),
                }),
            })
        );
    }, [])




    vectorLayer?.setStyle([
        new Style({
            stroke: new Stroke({
                color: '#636fb3',
                width: 3,
            }),
            fill: new Fill({
                color: '#54a0d346',
            }),
        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: '#f2f2f2',
                    width: 1,
                }),
                fill: new Fill({
                    color: '#47c278',
                }),
            }),
        }),
    ])

    useEffect(() => {
        return () => {
            source?.removeFeature(positionFeature)
            source?.removeFeature(accuracyFeature)
        }
    }, [accuracyFeature, positionFeature, source])

    useEffect(() => {

        if (isActive) {
            geolocation.setTracking(true)
            source?.addFeatures([positionFeature, accuracyFeature])
            const positionExtent = positionFeature?.getGeometry()?.getExtent()
            if (positionExtent != undefined) {
                map?.getView().fit(positionExtent, { size: map?.getSize() as Size, padding: [100, 100, 100, 100], maxZoom: 12 })
            }

        } else {
            source?.removeFeature(positionFeature)
            source?.removeFeature(accuracyFeature)
            geolocation.setTracking(false)
        }
        geolocation.once('change:accuracyGeometry', function () {
            accuracyFeature.setGeometry(geolocation.getAccuracyGeometry() as Geometry);
        });

        geolocation.once('change:position', function () {
            const coordinates = geolocation.getPosition();
            positionFeature.setGeometry(coordinates ? new Point(coordinates) : undefined);
        });

    }, [isActive, accuracyFeature, geolocation, source, positionFeature])


    positionFeature.once('change', () => {
        const positionExtent = positionFeature?.getGeometry()?.getExtent()
        if (positionExtent != undefined) {
            map?.getView().fit(positionExtent, { size: map?.getSize() as Size, padding: [100, 100, 100, 100], maxZoom: 15 })
        }

    })

    return (
            <button ref={buttonGps} className={`${styles.buttons} ${styles[!isActive ?'buttonclose':'buttonopen']}`}  onClick={() => setIsActive(!isActive)}>
                <div>
                <LocationIcon  style={{ fill:  'white', width: 18, height: 18}}/>
                </div>
            </button>
    )
}


