import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import style from './ProjectContextual/ProjectContextual.module.scss';

function ArchivedProjectOptions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={style.projectContextualMenu}>
      <div
        onClick={() => {
          navigate('/projects/new');
        }}
        className={style.contextualButton}
      >
        <p>{t('new-project')}</p>
      </div>
      <div
        onClick={() => {
          navigate('/projects');
        }}
        className={style.contextualButton}
      >
        <p>{t('not-archived')}</p>
      </div>
    </div>
  );
}

export default ArchivedProjectOptions;
