// #region React
// #endregion
// #region Icons
import {ReactComponent as DeleteIcon} from 'assets/icons/ico-delete.svg'
import {ReactComponent as EditIcon} from 'assets/icons/ico-info.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';

// #endregion
// #region Styles
import style from './../LotContextual.module.scss'
// #endregion
// #region Options
import EditOption from './Options/EditOption';
import TrashOption from './Options/TrashOption';
// #endregion


export default function LotContextual({
  lotId,
  projectId,
  lot
}: {
  lotId: number,
  projectId: number
  lot: any
}) {
  const { t } = useTranslation();

  const [option, setOption] = useState(0);

  const options = [
    <EditOption key={0} lotId={lotId} projectId={projectId} lot={lot}/>,
    <TrashOption key={1} lotId={lotId} projectId={projectId}/>
  ];

  return (
    <div className={style.lotContextualMenu}>
      <div className={style.contextualContent}>
        {options[option]}
      </div>
      
      <div className={style.tabBar}>
        <div className={style.tabBarIcons}>
          {/* EDIT */}
          <div
            className={option == 0 ? style.bubble : style.bubbleOff}
            onClick={() => {setOption(0)}}
          >
            <EditIcon
              width={25}
              style={{ fill: style.azul}}
            />
          </div>

          {/* DELETE */}
          <div
            className={option == 1 ? style.bubble : style.bubbleOff}
            // onClick={() => {setOption(1)}}
          >
            <DeleteIcon
              width={15}
              style={{ fill: style.lightdisabled}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

