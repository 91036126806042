import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExtraDataResponse } from 'api/Vistaguay/ExtraData';


const initialState: ExtraDataResponse = {
    seeders: [],
    seedings: [],
    crops: [],
    taskStatus: [],
    cropTaskStatus: [],
    distances: [],
    seederMeterBrands: [],
    seederBrands: []
}

const reducer = {
    setExtraData(state: ExtraDataResponse, action: PayloadAction<any>) {
        return { ...state, ...action.payload };
    },
    addExtraData: (state: ExtraDataResponse, action: PayloadAction<any>) => {
        state.seeders.push(action.payload)
        state.seedings.push(action.payload)
    }
}

export const extraDataSlice = createSlice({
    name:'extradata',
    initialState,
    reducers: reducer,
})

export const {
    setExtraData,
    addExtraData
} = extraDataSlice.actions

export default extraDataSlice.reducer