import { ReactComponent as TrashIcon } from 'assets/icons/trashIcon.svg';

import style from './MPolygon.module.scss';

interface MPolygonDeleteVertexProps {
  deletePoint: () => void;
}

export const MPolygonDeleteVertex = ({ deletePoint }: MPolygonDeleteVertexProps) => {
  return (
    <div className={style.trashIconContainer} onClick={deletePoint}>
      <TrashIcon fill='#444444' height={25} />
    </div>
  );
};
