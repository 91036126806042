import { Popover2 } from '@blueprintjs/popover2';
import { useGetNotInCampaignProjectsQuery } from 'api/Vistaguay/CampaignAPI';
// import { useGetProjectsQuery } from 'api/Vistaguay/ProjectAPI';
import { ReactComponent as Dots } from 'assets/icons/ico-dots.svg';
import { ReactComponent as Icon } from 'assets/icons/ico-folder.svg';
import { ReactComponent as Arrow } from 'assets/icons/ico-next.svg';
import cx from 'classnames';
import Spinner from 'components/Spinner/Spinner';
import { motion } from 'framer-motion';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarHeader from 'layout/Sidebar/SidebarHeader';
import { Campaign } from 'models/Campaign';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'state/hooks';

import ArchivedContextual from './ArchivedContextual';
import ArchivedProjectOptions from './ArchivedProjectOptions';
import styles from './ProjectList.module.scss';

function ArchivedProjectList() {
  const { t } = useTranslation();
  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );

  const {
    data: projectList,
    isLoading,
    error,
    isSuccess,
  } = useGetNotInCampaignProjectsQuery(selectedCampaign?.id || 1, {
    skip: !selectedCampaign,
  });

  const navigate = useNavigate();

  return (
    <>
      <Sidebar>
        <SidebarHeader></SidebarHeader>
        {isLoading && <Spinner />}

        {isSuccess && (
          <>
            {/* <div className={styles.archivedHeader}>
              <p>Archivados</p>
            </div> */}
            <div className={styles.header}>
              <div className={styles.title}>
                <h2>{t('proyectos-no-linkeados-a-campana-actual')}</h2>
                <p>
                  [
                  {projectList?.length}
                  ]
                </p>
              </div>
              <Popover2
                interactionKind='click'
                usePortal={true}
                content={<ArchivedProjectOptions />}
              >
                <div className={styles.projectContextualContainer}>
                  <Dots className={cx(styles.icon, styles.dots)}></Dots>
                </div>
              </Popover2>
            </div>
            <SidebarBody>
              <div className={styles.body}>
                <ul className={styles.projectList}>
                  {projectList?.map((project, index) => {
                    return (
                      <li key={project.proyectoId}>
                        <div className={styles.projectItem}>
                          <motion.div
                            onClick={() => {
                              navigate(`/projects/${project.proyectoId}`);
                            }}
                            className={styles.left}
                            whileHover={{ scale: 1.05 }}
                          >
                            <Icon style={{ fill: project.color }} className={styles.icon} />
                            {project.nombre}
                          </motion.div>
                          <Popover2
                            interactionKind='click'
                            usePortal={true}
                            content={<ArchivedContextual project={project} />}
                          >
                            <div className={styles.projectOptionsContainer}>
                              <Dots className={styles.optionsIcon} />
                            </div>
                          </Popover2>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className={styles.footer}>
                  <Arrow style={{ transform: 'rotate(180deg)' }} className={styles.icon} />
                  <p>{t('ver-mas')}</p>
                  <Arrow className={styles.icon} />
                </div>
              </div>
            </SidebarBody>
          </>
        )}
      </Sidebar>
    </>
  );
}

export default ArchivedProjectList;
