import clsx from 'classnames';
import AnimatedFetchButton from 'components/Button/AnimatedFetchButton'
import Button from 'components/Button/Button'
import { Notification,NOTIFICATION_STATUS, NOTIFICATION_TYPE } from 'models/Notification'
import { title } from 'process'

import styles from './Topbar.module.scss'

const getClassName = (notifiStatus: NOTIFICATION_STATUS) => {
    switch(notifiStatus) {
        case NOTIFICATION_STATUS.SUCCESS:
            return styles.successNoti
            break
        case NOTIFICATION_STATUS.ERROR:
            return styles.errorNoti
            break
        case NOTIFICATION_STATUS.PENDING: 
            return styles.pendingNoti
            break
    }
}

const NotificationItem = ({notification}: {notification: Notification}) => {

    const handleClick = (url:string) => {
        window.open(url)
    }
    
    return (
        <li 
            className={clsx(
                styles.menuItem,
                getClassName(notification.status)
            )}
            style={{justifyContent: 'space-between'}}
        >
            <span 
                style={{fontWeight: 500}}
            >
                {notification.title}
            </span>
            <span 
                style={{fontSize: '10px', marginLeft: '5px'}}
            >
                {notification.createdAt}
            </span>
            
            {
                notification.type == NOTIFICATION_TYPE.WITH_LINK &&
                <div>
                <AnimatedFetchButton style={{
                    fontSize:'12px',
                    padding: '5px 20px'
                }}
                onClick={(e) => handleClick(notification.link)}
                >
                    Ver mas...
                </AnimatedFetchButton>
                </div>
            }
        </li>
    )
}




export default function NotificationPopover ({notifications} : {notifications: Notification[]}) {
    return (
        <div className={styles.menu}>
            <div className={styles.menuTitle}>
                <h2>Notificaciones</h2>
            </div>
         
            <ul>
                {
                    notifications.map((notification) => {
                        return (
                            <NotificationItem key={notification.id} notification={notification}/>
                        )
                    })
                }
                {
                    notifications.length == 0 &&
                    <li>
                        No tienes notificaciones :(
                    </li>
                }
            </ul>
         
        </div>
    )
}