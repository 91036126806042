import React from 'react';

import style from './Spinner.module.scss';

function Spinner() {
  return (
    <div className={style.spinnerContainer}>
      <div className={style.bounce1} />
      <div className={style.bounce2} />
      <div className={style.bounce3} />
    </div>
  );
}

export default Spinner;