// import { Feature } from 'ol';
// import { Polygon } from 'ol/geom';
import { bbox } from '@turf/turf';
import { GeoJSONFeature } from 'models/GeoJSON';
import { Polygon } from 'models/Polygon';
import { Map } from 'ol';
import { extendCoordinates } from 'ol/extent';
import { Polygon as OlPolygon } from 'ol/geom';
import { transform } from 'ol/proj';

export const makeOlPolygonFromGeoJson = (geoJson: GeoJSONFeature<Polygon>) => {
  if (!geoJson) return null;
  const coordinates = geoJson.geometry?.coordinates;
  const transformedCoords = coordinates?.map((coords) => {
    return coords.map((coord) => {
      return transform([coord[0], coord[1]], 'EPSG:4326', 'EPSG:3857');
    });
  });

  if (!transformedCoords) return null;
  const poligono = new OlPolygon(transformedCoords);

  return poligono;
};

export const fitToCoords = (map: Map | null, coords: any) => {
  console.log('fitToGeom', coords);
  if (!map) return;

  const coordinates = coords;
  console.log('coordinates:', coordinates);

  const transformedCoords = coordinates?.map((coords: any) => {
    console.log('coords:', coords);
    return coords.map((coord: any) => {
      console.log('coord:', coord);
      return transform([coord[0], coord[1]], 'EPSG:4326', 'EPSG:3857');
    });
  });
  console.log('transformedCoords', transformedCoords);
  // map.getView().setCenter(transformedCoords[0]);
  const poligono = new OlPolygon(transformedCoords);

  map.getView().fit(poligono.getExtent(), {
    padding: [50, 50, 50, 50],
  });
};
