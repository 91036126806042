import style from './Table.module.scss';

interface TableProps {
  data: any[];
  columns: {
    property: string;
    label: string;
  }[];
  /**
   * If true, the rows will be colored according to the color property of each row.
   */
  colored?: boolean;
}
//
export const Table = (props: TableProps) => {
  // Function to get the nested data
  const getNestedData = (obj: any, property: string) => {
    return property.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj);
  };

  return (
    <table className={style.table}>
      <thead>
        <tr>
          {props.columns.map((column, index) => (
            <th key={index}>{column.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.data.map((row, index) => (
          <tr
            key={index}
            style={{
              backgroundColor: props.colored && row.color ? row.color : 'white',
            }}
          >
            {props.columns.map((column, index) => (
              <td key={index}>{getNestedData(row, column.property)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
