import { Popover2 } from '@blueprintjs/popover2';
import { useGetPrescriptionsQuery } from 'api/Vistaguay/LotAPI';
import { ReactComponent as Dots } from 'assets/icons/ico-dots.svg';
import { ReactComponent as LotIcon } from 'assets/icons/polygonsquare.svg';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import LotItemContextual from 'pages/LotList/LotContextual/LotItemContextual/LotItemContextual';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { toggleShowLot } from 'state/slices/mapSlice';

import style from './TaskListItem.module.scss';

interface LotItem {
  lotId: string;
  projectId: string;
  lotName: string | undefined;
}

export function LotItem({ lotName = '...', projectId, lotId }: LotItem) {
  const dispatch = useAppDispatch();
  const isActive = useAppSelector((state) => state.map.showLot);

  const { data: prescriptionsData } = useGetPrescriptionsQuery({
    lotId: +lotId,
    projectId: +projectId,
  });

  return (
    <div className={style.taskItem}>
      <div className={style.taskinfo}>
        <LotIcon style={{ height: '25px', width: '35px' }} className={style.taskIcon} />
        <div>
          <p>{lotName}</p>
          <p style={{ fontSize: '0.7em' }}>...</p>
        </div>
      </div>

      <div className={style.taskItemStatus}>
        <SwitchButton
          value={isActive} 
          handleOnChange={(status) => dispatch(toggleShowLot())} 
          switchWidth={34}
          switchHeight={18}
          showLayersIcon
        />
      </div>

      <Popover2
        disabled
        interactionKind='click'
        usePortal={true}
        content={<LotItemContextual lotId={+lotId} projectId={+projectId}/>}
        className={style.popover}
      >
        <div className={style.dotwrap}>
          {/* <Dots className={style.icon}></Dots> */}
        </div>
      </Popover2>
    </div>
  );
}
