import AddLoteButtonOverlay from 'components/Map/MapOverlays/AddLotButtonOverlay';
import Sidebar from 'layout/Sidebar/Sidebar';
import AddCampaign from 'pages/AddCampaign/AddCampaign';
import AddLote from 'pages/AddLote/AddLot';
import AddLotMap from 'pages/AddLote/AddLotMap';
import AddProject from 'pages/AddProject/AddProject';
import { AddTask } from 'pages/AddTask/AddTask';
import AddAnalitycs from 'pages/Analitycs/AddAnalitycs';
import AddAnalitycsMap from 'pages/Analitycs/AddAnalitycsMap';
import FormFinishProcess from 'pages/Analitycs/components/FormFinishProcess/FormFinishProcess';
import GridCreatorForm from 'pages/Analitycs/components/GridCreatorForm/GridCreatorForm';
import Analytics from 'pages/Analitycs/pages/Analitycs';
import ConfirmEmail from 'pages/AuxiliarPages/ConfirmEmail';
import { ForgotPassword } from 'pages/AuxiliarPages/ForgotPassword/ForgotPassword';
import { ResetPassword } from 'pages/AuxiliarPages/ForgotPassword/ResetPassword';
import NotAuthorized from 'pages/AuxiliarPages/NoAuthorized';
import NotFound from 'pages/AuxiliarPages/NotFound';
import ComponentTesting from 'pages/ComponentTesting/ComponentTesting';
import ComponentTestingMap from 'pages/ComponentTesting/ComponentTestingMap';
import { ControlPanel } from 'pages/ControlPanel/ControlPanel';
import MosaicTable from 'pages/ControlPanel/MosaicTable';
import PlantCountTable from 'pages/ControlPanel/PlantCountTable';
import { ShowCountFilter } from 'pages/ControlPanel/ShowCountFilter';
import Home from 'pages/Home/Home';
import { LoginCentralArea, LoginSidebar } from 'pages/Login/Login';
import LotList from 'pages/LotList/LotList';
import LotListMap from 'pages/LotList/LotListMap';
import Pay from 'pages/Pay/Pay';
import Pricing from 'pages/Pricing/Pricing';
// import { Profile } from 'pages/Profile/Profile';
import ArchivedProjectList from 'pages/ProjectListPage/ArchivedProjectList';
import { ProjectListMap } from 'pages/ProjectListPage/ProjectListMap/ProjectListMap';
import ProjectListPage from 'pages/ProjectListPage/ProjectListPage';
import { RegisterCentralArea, RegisterSidebar } from 'pages/Register/Register';
import Search from 'pages/Search/Search';
import SelectCampaign from 'pages/SelectCampaign/SelectCampaign';
import { TaskListMap } from 'pages/TaskList/TaksListMap/TaskListMap';
import { TaskList } from 'pages/TaskList/TaskList';
import AddMosaic from 'pages/Tasks/Mosaics/AddMosaic';
import AddMosaicMap from 'pages/Tasks/Mosaics/MosaicsViewMap/AddMosaicMap';
import { MosaicsViewMap } from 'pages/Tasks/Mosaics/MosaicsViewMap/MosaicsViewMap';
import { MosiacView } from 'pages/Tasks/Mosaics/MosiacView';
import AddPlantCount from 'pages/Tasks/PlantCount/AddPlantCount';
import { AddCountMap } from 'pages/Tasks/PlantCount/Map/AddCountMap';
import AddVolare from 'pages/Tasks/Volare/AddVolare';
import AddVolareMap from 'pages/Tasks/Volare/AddVolareMap';
import { AddMappingMap } from 'pages/Tasks/Volare/Mapping/MappingMap';
import { MappingSidebar } from 'pages/Tasks/Volare/Mapping/MappingSidebar';
import { AddScoutingMap } from 'pages/Tasks/Volare/Scouting/ScoutingMap';
import { AddScoutingSidebar } from 'pages/Tasks/Volare/Scouting/ScoutingSidebar';
import { PublicTaskView } from 'pages/TaskView/PublicReport/PublicTaskView';
import { PublicTaskViewMap } from 'pages/TaskView/PublicReport/PublicTaskViewMap';
import { TaskView } from 'pages/TaskView/TaskView';
import { TaskViewMap } from 'pages/TaskView/TaskViewMap/TaskViewMap';
import { testShpRoute } from 'pages/TestShp';
import { GroupAdminPage } from 'pages/UserGroups/GroupAdmin.page';

export interface IRoute {
  path: string;
  centralArea: () => JSX.Element | null;
  sidebar: () => JSX.Element | null;
  protected: boolean;
  layout: boolean;
  map: boolean;
}

// const DummyComponent = () => <div>TODO :)</div>;

export const routes: IRoute[] = [
  {
    path: '/home',
    centralArea: () => <AddLoteButtonOverlay />,
    sidebar: () => <Home />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '*',
    centralArea: () => <NotFound />,
    sidebar: () => null,
    layout: false,
    protected: false,
    map: false,
  },
  {
    path: '/',
    centralArea: () => <LoginCentralArea />,
    sidebar: () => <LoginSidebar />,
    map: false,
    layout: false,
    protected: false,
  },
  {
    path: '/register',
    centralArea: () => <RegisterCentralArea />,
    sidebar: () => <RegisterSidebar />,
    map: false,
    layout: false,
    protected: false,
  },
  {
    path: '/search',
    centralArea: () => null,
    sidebar: () => <Search />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/',
    centralArea: () => <ProjectListMap />,
    sidebar: () => <ProjectListPage />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/archived',
    centralArea: () => null,
    sidebar: () => <ArchivedProjectList />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/new',
    centralArea: () => null,
    sidebar: () => <AddProject />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/edit',
    centralArea: () => null,
    sidebar: () => <AddProject />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId',
    centralArea: () => <LotListMap />,
    sidebar: () => <LotList />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId',
    centralArea: () => <TaskListMap />,
    sidebar: () => <TaskList />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/tasks/new',
    centralArea: () => <></>,
    sidebar: () => <AddTask />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/addTask/plantCount',
    centralArea: () => <AddCountMap />,
    sidebar: () => <AddPlantCount />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/plantCount/:taskId',
    centralArea: () => <TaskViewMap />,
    sidebar: () => <TaskView />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/public/task/:token',
    centralArea: () => <PublicTaskViewMap />,
    sidebar: () => <PublicTaskView />,
    map: true,
    layout: true,
    protected: false,
  },
  {
    path: '/projects/:projectId/lots/:lotId/mosaic/:taskId',
    centralArea: () => <MosaicsViewMap />,
    sidebar: () => <MosiacView />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/addTask/mosaic',
    centralArea: () => <AddMosaicMap/>,
    sidebar: () => <AddMosaic />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/lots/new',
    centralArea: () => <AddLotMap />,
    sidebar: () => <AddLote />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/campaign/new',
    centralArea: () => null,
    sidebar: () => <AddCampaign />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/campaign/new/:newUser',
    centralArea: () => null,
    sidebar: () => <AddCampaign />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/campaign/search',
    centralArea: () => null,
    sidebar: () => <SelectCampaign />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/addTask/volare',
    centralArea: () => <AddVolareMap />,
    sidebar: () => <AddVolare />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/addTask/volare/mapping',
    centralArea: () => <AddMappingMap />,
    sidebar: () => <MappingSidebar />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/addTask/volare/scouting',
    centralArea: () => <AddScoutingMap />,
    sidebar: () => <AddScoutingSidebar />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/volare/mapping/:missionId',
    centralArea: () => <AddMappingMap />,
    sidebar: () => <MappingSidebar />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/volare/scouting/:missionId',
    centralArea: () => <AddScoutingMap />,
    sidebar: () => <AddScoutingSidebar />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/testing',
    centralArea: () => <ComponentTestingMap />,
    sidebar: () => <ComponentTesting />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/controlVistaguay',
    centralArea: () => <ControlPanel />,
    sidebar: () => <></>,
    map: false,
    layout: false,
    protected: true,
  },
  {
    path: '/groupAdmin',
    centralArea: () => <GroupAdminPage />,
    sidebar: () => <></>,
    map: false,
    layout: false,
    protected: true,
  },
  {
    path: '/controlVistaguay/showFiltroConteo/:statusId?',
    centralArea: () => <PlantCountTable/>,
    sidebar: () => <></>,
    map: false,
    layout: false,
    protected: true,
  },
  {
    path: '/controlVistaguay/showFiltroMosaico/:statusId?',
    centralArea: () => <MosaicTable/>,
    sidebar: () => <></>,
    map: false,
    layout: false,
    protected: true,
  },
  {
    path: '/pay/:status/',
    sidebar: () => <Pay />,
    centralArea: () => <></>,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/pricing',
    sidebar: () => null,
    centralArea: () => <Pricing />,
    map: false,
    layout: false,
    protected: true,
  },
  testShpRoute,
  {
    path: '/not-authorized',
    centralArea: () => <NotAuthorized />,
    sidebar: () => null,
    map: false,
    layout: false,
    protected: false,
  },
  {
    path: '/auth/confirm/:token/:email',
    centralArea: () => <ConfirmEmail />,
    sidebar: () => null,
    map: false,
    layout: false,
    protected: false,
  },
  {
    path: '/projects/:projectId/lots/:lotId/addTask/analytics',
    centralArea: () => <AddAnalitycsMap />,
    sidebar: () => <AddAnalitycs />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/analytics/:analyticsId',
    centralArea: () => null,
    sidebar: () => <Analytics/>,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/analytics/:analyticsId/process',
    centralArea: () => null,
    sidebar: () => <FormFinishProcess />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/projects/:projectId/lots/:lotId/analytics/:analyticsId/new/layer',
    centralArea: () => null,
    sidebar: () => <GridCreatorForm />,
    map: true,
    layout: true,
    protected: true,
  },
  {
    path: '/forgotPassword',
    centralArea: () => <ForgotPassword />,
    sidebar: () => null,
    map: false,
    layout: true,
    protected: false,
  },
  {
    path: '/resetPassword',
    centralArea: () => <ResetPassword />,
    sidebar: () => null,
    map: false,
    layout: true,
    protected: false,
  }
];
