import { PlantCountTask } from 'models/PlantCountTask';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'services/configurations';

import { ReactComponent as Cultivo } from '../../../assets/icons/Cultivo-comodin.svg';
import { ReactComponent as BackIcon } from '../../../assets/icons/ico-back.svg';
import { ShareConteiner } from '../TaskViewMap/CountMapLegend';
import { CountReportHeaderDataWithIcons } from './CountReportHeaderDataWithIcons';
import style from './CountTaskReport.module.scss';
import { PhotoDescriptionSectionConteiner } from './PhotoDescriptionSectionConteiner';
import { PlotsSection } from './PlotsSection';
interface ICountTaskReport {
  project: any;
  lot: any;
  task: PlantCountTask;
  photosMetadata: any;
  countMetadata: any;
  extraData: any;
  selectedCampaign: any;
  prescription: any;
}

export const resultImageBasePath = (taskId: string) => {
  return `${API_URL}report/task/${taskId}/result`;
};

export function CountTaskReport({
  project,
  lot,
  task,
  photosMetadata,
  countMetadata,
  extraData,
  selectedCampaign,
  prescription,
}: ICountTaskReport) {
  const conteo = task;


  const SHOW_REPORT =
    project && task && photosMetadata && countMetadata && extraData && selectedCampaign != null;


    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(true)
  return (
    <>
    <div style={{width: '100%'}}>
      {SHOW_REPORT && (
        <>
          <div className={style.header}>
            <button className={style.button}  
              onClick={() => { navigate(`/projects/${project.proyectoId}/lots/${lot.id}`);}}>
              <BackIcon className={style.svg}/>
              Tareas
            </button>
              <h2 className={style.title}>
                <Cultivo className={style.cultivo} /> Planting Quality {'('} #{conteo.conteosPlantasId} {')'} | <span className={style.subtitle}> {conteo.nombre}</span>
              </h2>
            {/* <div className={style.shareContainer}>
              <ShareConteiner task={task} />
            </div> */}
          </div>


          <div className={style.content}>
            <CountReportHeaderDataWithIcons
              conteo={conteo}
              project={project}
              extraData={extraData}
              selectedCampaign={selectedCampaign}
              lot={lot}
              setOpen={setOpen}
              open={open}
            />
            <PlotsSection
              task={task}
              countMetadata={countMetadata}
              photosMetadata={photosMetadata}
              prescription={prescription}
            />
            <PhotoDescriptionSectionConteiner
              task={task}
              photosMetadata={photosMetadata}
              prescription={prescription}
            />
          </div>
        </>
      )}
    </div>
    </>
  );
};


