// #region React & hooks
import { useAddPrescriptionsMutation, useGetPrescriptionsQuery } from 'api/Vistaguay/LotAPI';
import { ReactComponent as PlusIcon } from 'assets/icons/ico-plus.svg';
// #endregion
// #region Components
import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';
import { Input, InputSelect } from 'components/Inputs/Input';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import { GeoJSONFeatureCollection } from 'models/GeoJSON';
import { ScoutingMission } from 'models/Mission';
// #endregion
// #region Models
import { Prescription } from 'models/Prescription';
// #endregion
// #region Interfaces
import { ErrorList } from 'pages/Tasks/Mosaics/AddMosaic';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateGeoJSONasPolygonOrMultipolygon } from 'services/geojsonValidation';
// #endregion
// #region Services
import { readFileAsAJson } from 'services/helpers';
import { useAppDispatch, useAppSelector } from 'state/hooks';
// #endregion
// #region Redux states
import { setSelectedPrescription, setSelectedPrescriptionId, setShowPrescriptionTable } from 'state/slices/uiSlice';
import { setCurrentMission } from 'state/slices/volareSlice';
// #endregion

export interface StepProps {
    prescriptions?: Prescription[];
    isPrescriptionSuccess?: boolean;
    projectId: number;
    lotId: number;
    currentStep: number;
    step: number;
    nextStepTouched: boolean;
    handleError: (error: boolean) => void;
}

export default function StepParams ({
    prescriptions,
    isPrescriptionSuccess,
    lotId,
    projectId,
    currentStep,
    step,
    nextStepTouched,
    handleError,
}: StepProps) { 
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const currentMission = useAppSelector((state) => state.volare.currentMission) as ScoutingMission;

    const inputFile = useRef<HTMLInputElement | null>(null);
    const debounceRef = useRef<number | null>(null);

    const [errorsList, setErrors] = useState<ErrorList[]>([]);
    const [name, setName] = useState(currentMission?.name);

    const token = useAppSelector((state) => state.data.accessToken);
    const selectedPrescription: Prescription | null = useAppSelector(
        (state) => state.ui.selectedPrescription,
    );

    const [
        addPrescription, 
        { isSuccess: isAddPrescriptionSuccess, data: addPrescriptionData }
    ] =useAddPrescriptionsMutation();

    const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
          if (event.target.files) {
            const parsedData: GeoJSONFeatureCollection = await readFileAsAJson({ file: event.target.files[0], token});
    
            if (event.target.files[0].name.length > 45)
              return ErrorToaster('El nombre de archivo no debe tener mas de 45 caracteres');
    
            if (validateGeoJSONasPolygonOrMultipolygon(parsedData)) {
                const prescriptionNew: Partial<Prescription> = {
                    prescription: parsedData,
                    createdAt: String(Date.now()),
                    name: event.target.files[0].name,
                };
                addPrescription({
                    lotId: Number(lotId),
                    projectId: Number(projectId),
                    prescription: prescriptionNew,
                });
            } else {
              ErrorToaster('Error al cargar prescripción.');
            }
          }
        } catch (e) {
          console.log('error al cargar prescipción:', e);
          return false;
        }
    
        return false;
    };

    const debounce = (func: () => void, delay: number) => {
        if (debounceRef.current !== null) {
            clearTimeout(debounceRef.current);
        }
        debounceRef.current = window.setTimeout(func, delay);
    };

    useEffect(() => {
        if(currentStep == step) {
          const errors: ErrorList[] = [];
          let error = false; 
            
          if (!currentMission?.name) {
            errors.push({ message: t('form-mission-name-required'), propName: 'mission-name' });
          } 
          
          error = errors.length > 0 ?? true;
        
          setErrors(errors);
          handleError(error);
        }
    }, [
        currentMission?.name, 
        currentMission?.prescriptionId
    ])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', gap: '20px' }}>
            
            <div>
                <span>{t('taskName')}</span>
                <Input 
                    name='mission-name' 
                    placeholder=''
                    value={name} 
                    onChange={(e) => {
                        const value = e.target.value;
                        setName(value)
                        // reduce times dispatch is excuted and wait to the user to complete writing down the name
                        debounce(() => {
                            dispatch(setCurrentMission({
                                ...currentMission, 
                                name: value
                            }));
                        }, 500);
                    }}
                />
            </div>
            
            { nextStepTouched && errorsList.map((error, index) => {
                return (error.propName === 'mission-name') ? <Alert key={index} type='error' text={error.message} /> : null}
            )}

            <div>
                <span>{t('prescription')}</span>

                <div style={{display: 'flex', gap: '5px'}}>
                    <InputSelect
                        placeholder='Prescripcion'
                        value={currentMission?.prescriptionId ? currentMission.prescriptionId : ''}
                        onChange={(e) => {
                            if (prescriptions) {
                                const pres = prescriptions.find((p) => p.id === +e.target.value);
                               
                                if (pres) {
                                    // setPrescription(pres)
                                    dispatch(setSelectedPrescription(pres))
                                    dispatch(setSelectedPrescriptionId(pres.id))
                                    dispatch(setShowPrescriptionTable(true))
                                    dispatch(setCurrentMission({...currentMission, prescriptionId: pres.id}))
                                }
                            }
                        }}
                    >
                        <option value={''} disabled>{t('select-placeholder')}</option>

                        { isPrescriptionSuccess && prescriptions && prescriptions.map((prescription: Prescription) => (
                            <option
                                key={prescription.id}
                                value={prescription.id}
                            >
                                {prescription.createdAt.slice(0, 10)}
                            </option>
                            ))
                        }
                    </InputSelect>

                    <Button
                        width='45px'
                        style={{padding: '0px'}}
                        text=''
                        type='OUTLINE'
                        variant='INFO'
                        icon={<PlusIcon style={{ stroke: 'white' }} />}
                        onClick={() => inputFile.current?.click()} 
                    />
                </div>

                { nextStepTouched && errorsList.map((error, index) => {
                    if (error.propName === 'prescription-required') {
                        return <Alert key={index} type='error' text={error.message} />;
                    } else if (error.propName === 'upload-prescription') {
                        return <Alert key={index} type='error' text={error.message} />;
                    } else {
                        return null; 
                    }
                })}
            </div>
    
            <input
                type='file'
                id='file'
                name='files'
                ref={inputFile}
                style={{ display: 'none' }}
                accept='.geojson,application/zip,application/x-zip-compressed,.shp,.shx,.dbf'
                onChange={(e) => onChangeFile(e)}
            />
        </div>
    )
}