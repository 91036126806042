import { ReactComponent as BackIcon } from 'assets/icons/ico-back.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/shareIcon.svg';
// import { px } from 'framer-motion';
import { TASK_TYPE } from 'models/Task';
import ShareOption from 'pages/TaskList/TaskListContextual/PqContextual/Options/ShareOption';
import { ReactNode, useState } from 'react';
import { ColorCodes, ColorCoverage, cvRadius } from 'styles/pointStyle';

import style from './CountMapLegend.module.scss';

interface IProps {
  showShareButton?: boolean;
  showFullLegend?: boolean;
  task?: any;
  showName?: boolean;
}

export const CountMapLegend = ({ showShareButton, showFullLegend, task }: IProps) => {
  const [showLegend, setShowLegend] = useState(!showFullLegend);
  const toggleShowLegend = () => {
    setShowLegend(!showLegend);
  };

  return (
    <div className={style.countLegend}>
      <div className={style.titleConteiner} onClick={toggleShowLegend}>
        <h3  className={style.h3Subtitle} >
          <BackIcon
          className={style.backIconLegend}
            style={{
              transform: showLegend ? 'rotate(-90deg)' : 'rotate(90deg)',
            }}
          />
          Planting Quality
        </h3>
      </div>
      {showLegend && (
        <>
          <div className={style.denConteiner}>
            <div  className={style.subdiv}>
              <Title>Densidad</Title>
              <span  className={style.spanStyle}>[plantas/ha]</span>
            </div>
            <hr className={style.line}/>
          </div>

          <div className={style.gapConteiner}>
          <div  className={style.subdiv}>
            <Title>Espaciamiento</Title>
            <span className={style.spanStyle}>[CV (%)]</span>
          </div>
            <div className={style.gapValuesConteiner}>
              <GapValue color={ColorCodes.GREEN} label='≤ 25' />
              <GapValue color={ColorCodes.LIGHT_GREEN} label='25-35' />
              <GapValue color={ColorCodes.YELLOW} label='35-40' />
              <GapValue color={ColorCodes.ORANGE} label='40-50' />
              <GapValue color={ColorCodes.RED} label=' > 50' />
            </div>
            <hr className={style.line}/>
          </div>
          
          <div className={style.covConteiner}>
            <div className={style.subdiv}>
              <Title>Cobertura</Title>
              <span  className={style.spanStyle}>[CV (%)]</span>
            </div>
            <div className={style.gapValuesConteiner}>
              <CovValue size={(cvRadius.small *2)*2} />
              <CovValue size={(cvRadius.medium *2)*2}/>
              <CovValue size={(cvRadius.big *2)*2} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '48px', fontSize: '12px' }}>
              <span style={{ marginLeft: '25px'}}>&le; 50</span>
              <span>50 - 60</span>
              <span>&gt; 60</span>
            </div>
          </div>
          {/* {showShareButton && <ShareConteiner task={task} />} */}
        </>
      )}
    </div>
  );
};

const Title = ({ children }: { children: ReactNode }) => {
  return <h4 className={style.h4Subtitle}>{children}</h4>;
};
const GapValue = ({ color, label }: any) => {
  return (
    <div className={style.gapValue}>
      <div className={style.gapColor} style={{ backgroundColor: color }}></div>
      <div className={style.gapLabel}>{label}</div>
    </div>
  );
};

const CovValue = ({ size, label }: any) => {
  return (
    <div className={style.gapValue}>
      <div
        className={style.gapColor}
        style={{ height: size, width: size, backgroundColor: ColorCoverage.color }}
      ></div>
      <div className={style.gapLabel}>{label}</div>
    </div>
  );
};

 export const ShareConteiner = ({ task }: any) => {
  const [showOptions, setShowOptions] = useState(false);
  const showName  = false;
  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <>  
      <div onClick={toggleShowOptions}>
      {!showOptions && (
        <Title>
          <ShareIcon
            style={{
              marginLeft: '350px',
              fill: 'white',
              height: '18px',
            }}
          />
        </Title>
      )}
      </div>
      {showOptions && (
        <div >
          <ShareOption
            showHorizontal
            showName={showName}
            task={{
              name: task.name,
              taskId: task.countTaskId,
              lotId: 'string',
              type: TASK_TYPE.conteo,
              task: task,
              visualization: {
                visible: true,
                density: true,
                spatialVariability: true,
                temporalVariability: true,
                showPrescription: true,
                showPrescriptionData: true,
              }
            }}
          />
        </div>
      )}
    </>
  );
};
