import { ReactComponent as ArrowPrev } from 'assets/icons/arrow-prev.svg';
import clsx from 'classnames'
import { motion } from 'framer-motion';
import { Spinner } from 'react-bootstrap';

import styles from './Button.module.scss'

interface ButtonProps {
    onClick?: (e: any) => void;
    variant?: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO' | 'NEUTRAL' | 'NEUTRAL-GRAY';
    disabled?: boolean;
    isLoading?: boolean;
    text: string;
    icon?: React.ReactNode;
    iconRigth?: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    type?: 'GHOST' | 'OUTLINE' | 'FILL';
    color?: string;
    children?: React.ReactNode;
    iconBack?: boolean;
    width?: string;
    height?: string;
}

export default function Button({
    onClick,
    variant = 'NEUTRAL',
    disabled,
    text,
    style,
    className,
    icon,
    iconBack,
    iconRigth,
    type = 'FILL',
    color,
    children,
    width,
    height,
    isLoading
}: ButtonProps) {
    return (
        <motion.div 
            whileHover={'hoverBack'}
            onClick={(e) => { onClick ? onClick(e) : null}}
            className={clsx(
                styles.buttonWrapper,
                variant === 'SUCCESS' && styles.success,
                variant === 'ERROR' && styles.error,
                variant === 'WARNING' && styles.warning,
                variant === 'INFO' && styles.info,
                variant === 'NEUTRAL-GRAY' && styles.neutralgray,
                variant === 'NEUTRAL' && styles.neutral,
                type === 'FILL' && styles.fill,
                type === 'GHOST' && styles.ghost,
                type === 'OUTLINE' && styles.outline,
                disabled ? styles.disabled : '',
                style
            )} 
            style={{
                width: width ? width : '100%',
                height: height ? height : '50px'
            }}
        >
            {icon}

            { iconBack && (
                <motion.svg
                    style={{width: '20px', height: '15px'}}
                    variants={{
                    hoverBack: {
                        x: -3,
                        transition: {
                        duration: 0.2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        },
                    },
                    }}
                >
                    <ArrowPrev />
                </motion.svg>
            )}

            <button
                // onClick={onClick}
                disabled={disabled}
                className={clsx(
                    styles.button,
                    
                )} 
                style={{
                    color: color,
                    ...style,
                }}
            >
                {text}
                {children}
            </button>

            { iconRigth }

            { isLoading && (
                <Spinner style={{width: '15px', height: '15px'}}/>
            )}
        </motion.div>
        
    )
}