import NotAuthorized from 'pages/AuxiliarPages/NoAuthorized'
import { useAppSelector } from 'state/hooks';
export default function ProtectedRoute({ children, isProtected }: { children: React.ReactNode, isProtected: boolean }) {
    
    const userLogged = useAppSelector((state) => state.data.refreshToken) !== null;
    if (userLogged) {
        return <>
            {children}
        </>
    }

    if (!userLogged && isProtected) {
        return <>
            <NotAuthorized />
        </>
    }

    return (
        <>
            {children}
        </>
    )
}