import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsTask } from 'models/AnalyticsTask';
import { MappingMission, Mission, ScoutingMission } from 'models/Mission';
import MosaicTask from 'models/MosaicTask';
import { PlantCountTask } from 'models/PlantCountTask';
import { Task, TASK_TYPE } from 'models/Task';


export interface VisualizationPlantCountTask {
  visible: boolean;
  density: boolean;
  spatialVariability: boolean;
  temporalVariability: boolean;
  showPrescription: boolean;
  showPrescriptionData: boolean;
}
interface VisualizationMosaicTask {
  visible: boolean;
  showPrescription: boolean;
  showPrescriptionData: boolean;
}

export interface VisualizationMission {
  visible: boolean;
  showPrescription: boolean;
  showPrescriptionData: boolean;
  showFlightPlan: boolean;
}

export interface IAllTasks {
  taskId: string;
  name: string;
  type: TASK_TYPE;
  task: PlantCountTask | MappingMission | ScoutingMission | MosaicTask | AnalyticsTask
  visualization: VisualizationPlantCountTask | VisualizationMosaicTask | VisualizationMission | VisualizationMosaicTask | null ;
  lotId: string,
}

export interface TaskState {
  tasks: Array<IAllTasks>;
}

const initialState: TaskState = {
  tasks: [],
} as TaskState;

export interface SwapTaskPayload {
  dragItem: {
    index: number,
    taskId: string
  }
  dragOverItem: {
    index: number,
    taskId: string
  }
} 
// this slice used to show prescription and task in map --- togle show prescription ---

const reducer = {
  addTasks: (state: TaskState, action: PayloadAction<IAllTasks[]>) => {
    // add tasks and remove duplicates 
    const _taskItems = [...state.tasks]
    action.payload.forEach((task) => {
      const index = _taskItems.findIndex((taskItem) => taskItem.taskId == task.taskId)  
      if(index == -1){
        _taskItems.push(task)
      }else {
        _taskItems[index] = task
      }
    })
    state.tasks = _taskItems

  },
  addTask: (state: TaskState, action: PayloadAction<IAllTasks>) => {
    const index = state.tasks.findIndex((task) => task.taskId == action.payload.taskId)  
    if(index == -1){
      state.tasks.push(action.payload)
    }else {
      state.tasks[index] = action.payload
    }
  },
  removeTask: (state: TaskState, action: PayloadAction<string>) => {
    state.tasks.filter((value, index, array) => {
      if (value.taskId == action.payload) {
        array.splice(index, 1);
        return true;
      }
      return false;
    });
  },
  updateVisualization: (state: TaskState, action: PayloadAction<IAllTasks>) => {
    const objIndex = state.tasks.findIndex((obj) => obj.taskId === action.payload.taskId);
    state.tasks[objIndex].visualization = action.payload.visualization;
  },
  toggleDensity: (state: TaskState, action: PayloadAction<string>) => {
    const objIndex = state.tasks.findIndex((obj) => obj.taskId === action.payload);
    if(state.tasks[objIndex].type == TASK_TYPE.conteo ) {
      const visualization = state.tasks[objIndex].visualization as VisualizationPlantCountTask
      visualization.density = !visualization.density
    }
  },
  toggleSpatialVariability: (state: TaskState, action: PayloadAction<string>) => {
    const objIndex = state.tasks.findIndex((obj) => obj.taskId === action.payload);
    if(state.tasks[objIndex].type == TASK_TYPE.conteo ) {
      const visualization = state.tasks[objIndex].visualization as VisualizationPlantCountTask
      visualization.spatialVariability = !visualization.spatialVariability
    }

  },
  toggleTemporalVariability: (state: TaskState, action: PayloadAction<string>) => {
    const objIndex = state.tasks.findIndex((obj) => obj.taskId === action.payload);
    if(state.tasks[objIndex].type == TASK_TYPE.conteo ) {
      const visualization = state.tasks[objIndex].visualization as VisualizationPlantCountTask
      visualization.temporalVariability = !visualization.temporalVariability
    }
  },
  toggleShowPrescription: (state: TaskState, action: PayloadAction<string>) => {
    const objIndex = state.tasks.findIndex((obj) => obj.taskId === action.payload);
    if(state.tasks[objIndex].type == TASK_TYPE.conteo ) {
      const visualization = state.tasks[objIndex].visualization as VisualizationPlantCountTask
      visualization.showPrescription = !visualization.showPrescription
    }
  },
  toggleShowPrescriptionData: (state: TaskState, action: PayloadAction<string>) => {
    const objIndex = state.tasks.findIndex((obj) => obj.taskId === action.payload);
    if(state.tasks[objIndex].type == TASK_TYPE.conteo ) {
      const visualization = state.tasks[objIndex].visualization as VisualizationPlantCountTask
      visualization.showPrescriptionData = !visualization.showPrescriptionData
    }
  },
  toggleLayersVisibility: (state: TaskState, action: PayloadAction<string>) => {
    const objIndex = state.tasks.findIndex((obj) => obj.taskId === action.payload);
    if(state.tasks[objIndex].type == TASK_TYPE.conteo ) {
      const visualization = state.tasks[objIndex].visualization as VisualizationPlantCountTask;
      if( visualization.visible ) {
        visualization.density = true;
        visualization.spatialVariability = true;
        visualization.temporalVariability = true;
        visualization.showPrescription = true;
      } else if (!visualization.visible) {
        visualization.density = false;
        visualization.spatialVariability = false;
        visualization.temporalVariability = false;
        visualization.showPrescription = false;
      }
    }
  },
  clearTasks: (state: TaskState) => {
    state.tasks = [];
  },
  swapIndex: (state: TaskState, action: PayloadAction<SwapTaskPayload>) => {
    const _taskItems = [...state.tasks]
    const draggedItemContent = _taskItems.splice(action.payload.dragItem.index,1)[0]
    _taskItems.splice(action.payload.dragOverItem.index,0,draggedItemContent)
    state.tasks = _taskItems  
  },
  updateTasks: (state: TaskState, action: PayloadAction<IAllTasks[]>) => {
    state.tasks = action.payload;
  }
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: reducer,
});
export const {
  addTask: addTaskToShowList,
  swapIndex,
  removeTask,
  addTasks,
  clearTasks,
  toggleDensity,
  toggleTemporalVariability,
  toggleSpatialVariability,
  toggleShowPrescription,
  toggleShowPrescriptionData,
  toggleLayersVisibility,
  updateVisualization,
  updateTasks
} = taskSlice.actions;
