import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DataState {
  accessToken: string | null;
  refreshToken: string | null;
  name: string;
  surname: string;
}

const initialState: DataState = {
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  name: '',
  surname: '',
} as DataState;



export const getAccessToken = (): string => {
  const token = localStorage.getItem('accessToken')
  if(token) {
    return token
  } else {
    return ''
  }
}


const reducer = {
  setAccessToken: (state: DataState, action: PayloadAction<string>) => {
    state.accessToken = action.payload;
    localStorage.setItem('accessToken', action.payload);
  },

  setRefreshToken: (state: DataState, action: PayloadAction<string>) => {
    state.refreshToken = action.payload;
    localStorage.setItem('refreshToken', action.payload);
  },

  setLoginData: (
    state: DataState,
    action: PayloadAction<{
      accessToken: string | null;
      refreshToken: string | null;
    }>,
  ) => {
    state.accessToken = null;
    state.refreshToken = null;
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('selectedCampaign')

    state.accessToken = action.payload.accessToken;
    state.refreshToken = action.payload.refreshToken;
    // save to local storage
    if (action.payload.refreshToken && action.payload.accessToken) {
      localStorage.setItem('accessToken', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
    }
  },
  logout: (state: DataState) => {
    state.accessToken = null;
    state.refreshToken = null;
    state.name = '';
    state.surname = '';
    // delete from local storage
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('selectedCampaign')

  },
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: reducer,
});

// Action creators are generated for each case reducer function
export const { setLoginData, logout, setAccessToken, setRefreshToken } = dataSlice.actions;

export default dataSlice.reducer;
