import { PaginatedResponse } from 'models/PaginatedResponse';
import { PlantCountTask } from 'models/PlantCountTask';
import { Task } from 'models/Task';

import { VistaguayApi } from './VistaguayAPI';

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Task'],
});

interface Iparams {
  [key: string]: string | undefined;
}

export interface IUserSearch {
  nombre:string;
  usuarioId:string;
  apellido:string;
  email:string;
}



const buildParamsQueryUrl = (params: Iparams) => {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      queryParams.append(key, value);
    }
  });
  return queryParams.toString();
};

const taskAdminApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getTasksSumary: builder.query({
      query: () => 'task/admin/summary',
      providesTags: ['Task'],
    }),

    countServerStatus: builder.query({
      query: () => 'task/admin/statusPython',
    }),

    countInstanceStatus:builder.query({
      query: () => 'aws/instances'
    }),

    getTasksAdmin: builder.query<
      PaginatedResponse<Task>,
      {
        campaignId?: string;
        userId?: string;
        projectId?: string;
        countTaskId?: string;
        status?: string;
        lotId?: string;
        page?: number;
      }
    >({
      query: ({
        campaignId,
        userId,
        projectId,
        countTaskId,
        status,
        lotId,
        page,
      }: {
        campaignId?: string;
        lotId?: string;
        userId?: string;
        projectId?: string;
        countTaskId?: string;
        status?: string;
        page?: number;
      }) =>
        `task/admin/all?${buildParamsQueryUrl({
          campaignId: campaignId,
          userId: userId,
          projectId: projectId,
          countTaskId: countTaskId,
          status: status,
          lotId: lotId,
          page: page?.toString(),
        })}`,
      providesTags: ['Task'],
    }),
    updateStatus: builder.mutation({
      query: ({ taskId, statusId }: { taskId: string; statusId: string }) => {
        return {
          url: 'task/admin/updateStatus',
          method: 'PATCH',
          body: {
            statusId,
            taskId,
          },
        };
      },
      invalidatesTags: ['Task'],
    }),
    updateDistance: builder.mutation({
      query: ({ taskId, distance }: { taskId: string; distance: number }) => {
        return {
          url: 'task/admin/updateDistance',
          method: 'PATCH',
          body: { taskId, distance },
        };
      },
    }),
    updateDensity: builder.mutation({
      query: ({ taskId, density }: { taskId: number; density: object }) => {
        return {
          url: 'task/admin/updateDensity',
          method: 'PATCH',
          body: { taskId, density },
        };
      }
    }),
    getUserByUsername: builder.query({
      query: ({ username }: { username: string })  => {
        return {
          url: `users/username/${username}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useCountInstanceStatusQuery,
  useGetTasksSumaryQuery,
  useGetTasksAdminQuery,
  useUpdateDistanceMutation,
  useUpdateStatusMutation,
  useUpdateDensityMutation,
  useCountServerStatusQuery,
  useGetUserByUsernameQuery
} = taskAdminApi;
