import ScaleLine from 'ol/control/ScaleLine';
import { useEffect, useRef } from 'react';

import styles from './Map.module.scss'
export function CustomScaleControl({ map }: any) {

  useEffect(() => {
    if (map) {
      const scaleControl = new ScaleLine({
        className: `${styles.customScaleLine} ol-scale-line`,
      // bar: true,
        steps: 4,
        text: true,
        maxWidth: 100,
      });

      map.addControl(scaleControl);
    }
  }, [map]);

  return null;
}
