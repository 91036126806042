import { useGetPrescriptionsQuery, usePatchLotMutation } from 'api/Vistaguay/LotAPI';
import {ReactComponent as EditIcon} from 'assets/icons/editIcon.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import clsx from 'classnames'
import { Input } from 'components/Inputs/Input';
import { MenuItem } from 'components/MenuItem/MenuItem';
import MenuItemSingleEdit from 'components/Vistaguay/MenuItemSingleEdit/MenuItemSingleEdit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../../LotContextual.module.scss';

export default function EditOption({
    lotId,
    projectId,
    lot
}: {
    lotId: number;
    projectId: number;
    lot: any
}) {
    const { t } = useTranslation();

    const [ 
        patchLot,
        {
            isLoading,
            isSuccess,
            isError
        } 
    ]= usePatchLotMutation();

    const [lotName, setLotName] = useState(lot?.name || '-');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleSaveName = () => {
        patchLot({
            projectId,
            lotId,
            body: {name: lotName}
        })
    }

    useEffect(() => {
        let timer: any;
        if (isSuccess) {
            setSuccess(true);
            timer = setTimeout(() => {
                setSuccess(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isSuccess]);

    useEffect(() => {
        let timer: any;
        if (isError) {
            setError(true);
            timer = setTimeout(() => {
                setError(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isError]);

    return(
        <div>
            <MenuItemSingleEdit
                inputType='input'
                label={t('name')} 
                inputValue={lotName}
                handleChange={(value: string) => setLotName(value)}
                handleSaveChange={handleSaveName}
                editable
            />

            <MenuItem 
                title={t('surface')} 
                inputValue={'110ha'}
            />

            <MenuItem 
                title={t('surface')} 
                inputValue={'110ha'}
            />

            <MenuItem 
                title={t('tasks')} 
                inputValue={'6'}
            />

            <ul>
                <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                    <p>Conteo de plantas</p>
                    <p>2</p>
                </li>
                <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                    <p>Mosaico</p>
                    <p>1</p>
                </li>
                <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                    <p>Misiones</p>
                    <p>3</p>
                </li>
            </ul>

            <hr style={{margin: '5px 0 5px 0'}}/>

            <div style={{height: '20px'}}>
                { isLoading && (
                    <p className={style.bounce}>{t('saving')}...</p>
                )}

                { success && (
                    <p style={{color: style.verdeVistaguay}}>{t('saved')}</p>
                )}

                { error && (
                    <p style={{color:  style.rojo}}>{t('error')}</p>
                )}
            </div>
        </div>
    )
}