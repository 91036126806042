import { Campaign } from 'models/Campaign';
import Project from 'models/Project';

import { VistaguayApi, VistaguayApiResponse } from './VistaguayAPI';


interface CampaignBody {
  name: string,
  begin: string,
  end: string,
  createdAt: string;
}

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Campaign', 'Projects'],
});

const campaignApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query({
      query: () => 'campaign',
      transformResponse: (response: Campaign[]) => {
        return response;
      },
      providesTags: ['Campaign'],
    }),
    getCampaign: builder.query({
      query: ({ projectId, lotId }: { projectId: string; lotId: string }) =>
        `projects/${projectId}/lots/${lotId}`,
      providesTags: ['Campaign'],
    }),
    addCampaign: builder.mutation<Campaign, CampaignBody>({
      query: (body) => ({
        url: 'campaign',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Campaign']
    }),
    addNextCampaign: builder.mutation({
      query: () => ({
        url: 'campaign/next',
        method: 'POST',
      }),
      invalidatesTags: ['Campaign'],
    }),
    getCampaignProjects: builder.query<Project[], number>({
      query: (campaignId) => `campaign/${campaignId}/projects`,
      providesTags: ['Projects'],
    }),
    getCampaignProjectsWithLot: builder.query<Project[], number>({
      query: (campaignId) => `campaign/${campaignId}/projectsWithLots`,
      providesTags: ['Projects'],
    }),
    getNotInCampaignProjects: builder.query<Project[], number>({
      query: (campaignId) => `campaign/${campaignId}/notInCampaingProjects`,
      providesTags: ['Projects'],
    }),
    deleteCampaign: builder.mutation({
      query: ({campaignId}: {campaignId: number}) => ({
        url: `campaign/${campaignId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Campaign']
    }),
    patchCampaign: builder.mutation({
      query: ({campaignId, body}: {campaignId: number,  body: {name?: string, begin?:string, end?:string}}) => ({
        url: `campaign/${campaignId}`,
        method: 'PATCH',
        body: body,
      }),
    })
  }),
});

export const {
  useGetCampaignsQuery,
  useAddNextCampaignMutation,
  useGetCampaignProjectsQuery,
  useGetNotInCampaignProjectsQuery,
  useGetCampaignProjectsWithLotQuery,
  useAddCampaignMutation,
  useDeleteCampaignMutation,
  usePatchCampaignMutation
} = campaignApi;
