import AnimatedFetchButton from 'components/Button/AnimatedFetchButton';
import Button from 'components/Button/Button';
import { Input } from 'components/Inputs/Input';
import { Stepper } from 'components/Steppers/Stepper/Stepper';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import SidebarHeader from 'layout/Sidebar/SidebarHeader';
import { SlimHeader, SubHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import { InputHTMLAttributes, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';

import styles from './Testing.module.scss';


export default function ComponentTesting() {
  const navigate = useNavigate();
  
  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );

  const [rangeValue, setRangeValue] = useState();
  const rangeChange = (e: any) => {
    const newRange = e.target.value;
    setRangeValue(newRange);
  }

  const [currentStep, setCurrentStep] = useState<number>(1);
  const MAX_STEP = 3;


  const handleNextStep = () => {
    if (currentStep < MAX_STEP) {
      setCurrentStep((value) => value + 1)
    }
  }
  const handlePreviousStep = () => {
    setCurrentStep((value) => value - 1)
  }

  const StepOne = () => {
    return <></>;
  };
  const StepTwo = () => {
    return <></>;
  };
  const StepThree = () => {
    return <></>;
  };

  const steps = [<StepOne key={0} />, <StepTwo key={1} />, <StepThree key={2} />];
  return (
    <>
      <Sidebar>
        <SidebarHeader />
        <SubHeader noBack={true}>
          <SlimHeader  
            campaignName={selectedCampaign?.name} 
            projectColor={'Proyecto1'} 
            projectName={'#f2f2f2'} 
            lotName={'Lote 1'} 
          />
        </SubHeader>
        <SidebarBody>
            <h5 style={{ fontSize: '15px' }}>Generacion de Grilla</h5>
            <Stepper StepAmount={MAX_STEP} StepCurrent={currentStep} title={''} noHeader={true} />
            {steps[currentStep - 1]}

            <div className={styles.containerStepOne}>
              <Input className={styles.input} placeholder='Escribe el nombre de capa' />
              <div className={styles.gridInputs}>
    
                <Input className={styles.input} type='number' placeholder={'Rows'} />
                <Input className={styles.input} type='number' placeholder={'Width'} />
                <Input className={styles.input} type='number' placeholder={'Range gap'} />
                <Input className={styles.input} type='number' placeholder={'Ranges'} />
                <Input className={styles.input} type='number' placeholder={'Height'} />
                <Input className={styles.input} type='number' placeholder={'Row gap'} />
              </div>
              <div style={{ marginTop: '10px' }}>
                <label htmlFor="" style={{ fontSize: '15px', color: '#bebebe' }}>Rotation: <span style={{ fontSize: '15px' }}>{rangeValue}°</span></label>
                <input
                  className={styles.inputRange}
                  type='range'
                  value={rangeValue}
                  onChange={rangeChange}
                  max={'180'}
                />
              </div>
            </div>
        </SidebarBody>
        <SidebarFooter>
                <Button variant='ERROR' text='Anterior' onClick={handlePreviousStep} />
                <Button variant='SUCCESS' text='Siguiente' onClick={handleNextStep} />
        </SidebarFooter>
      </Sidebar>
    </>
  );
}
