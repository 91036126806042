import { SecureImg } from 'components/Secure/SecureImg/SecureImg';

import style from './CountTaskReport.module.scss';
import { MetadataAmbiente, PlotDataTable } from './PlotDataTable';
import { Semaphore } from './Sempahore';

interface IPlotProps {
  task: PlantCountTask;
  linkUrl: string;
  title: string;
  semaphoreType: string;
  unit: string;
  countMetadata: { result: MetadataAmbiente[] };
  prescription: Prescription;
  report: ConteosDePlantasReporte;
  photosMetadata: any
}
import * as Plot from '@observablehq/plot';
import { symbol, tickFormat } from 'd3';
import { LegacyRef, useEffect, useRef, useState } from 'react';

function PlotFigure({ options }: { options: Plot.PlotOptions }) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [data, setData] = useState()

  useEffect(() => {
    if (data === undefined && containerRef.current === null) return
    const plot = Plot.plot({

      ...options
    })

    containerRef.current?.append(plot)
    return () => plot.remove()
  }, [options])



  return <div ref={containerRef} />;
}

interface InfoSiembra {
  cobertura: [number],
  densidad: [number],
  distanciamiento: [number],
}

interface InfoSiembraVariable {
  cobertura: Array<number[]>,
  densidad: number[][],
  distanciamiento: Array<number[]>
}


import { Modal, useMediaQuery } from '@mui/material';
import { ReactComponent as PanIcon } from 'assets/icons/panableIcon.svg'
import { getColor, getColorModal } from 'components/Domain/Prescription/PrescriptionPolygons';
import PlotStyled, { PlotSizes } from 'components/Plots/PlotStyled';
import RectY from 'components/Plots/RectY';
import * as d3 from 'd3'
import useToggle from 'hooks/useToggle';
import { fill } from 'lodash';
import { ConteosDePlantasReporte } from 'models/ConteosDePlantasReporte';
import { PlantCountTask } from 'models/PlantCountTask';
import { Prescription } from 'models/Prescription';
import { useAppSelector } from 'state/hooks';

function calcularPercentil(datos: number[], percentil: number): number {
  // Paso 1: Ordenar el array de números


  // Paso 1: Ordenar el array de números
  const datosOrdenados = datos.slice().sort((a, b) => a - b);

  // Paso 2: Calcular la posición del percentil
  const posicion = percentil * (datosOrdenados.length);

  // Paso 3: Interpolación lineal si la posición no es un número entero
  if (posicion % 1 !== 0) {
    const lowerIndex = Math.floor(posicion);
    const upperIndex = Math.ceil(posicion);
    const lowerValue = datosOrdenados[lowerIndex];
    const upperValue = datosOrdenados[upperIndex];
    const result = lowerValue + (upperValue - lowerValue) * (posicion - lowerIndex);
    console.log(result)
    return result
  } else {
    // Paso 4: Si la posición es un número entero, tomar el valor en esa posición
    console.log(posicion)
    console.log(datosOrdenados[posicion - 1])
    return datosOrdenados[posicion - 1]; // Ajuste para índices basados en cero
  }
}

const PercentilMarks = (data : [number]) => {
  return [
    Plot.ruleX([calcularPercentil(data, 0.05)], { strokeDasharray: 5, stroke: 'black', strokeWidth: 1 }),
    Plot.text([calcularPercentil( data, 0.05)],{x:(d) => d ,text: ['5%'], lineAnchor: 'top',textAnchor:'end',fontSize:12, fontWeight:'bold',frameAnchor:'top'}),
    Plot.ruleX([calcularPercentil(data, 0.25)], { strokeDasharray: 5, stroke: 'black', strokeWidth: 1 }),
    Plot.text([calcularPercentil( data, 0.25)],{x:(d) => d,text: ['25%'], lineAnchor: 'top',textAnchor:'end',fontSize:12, fontWeight:'bold',frameAnchor:'top'}),
    Plot.ruleX([calcularPercentil(data, 0.50)], { strokeDasharray: 5, stroke: 'black', strokeWidth: 1 }),
    Plot.text([calcularPercentil( data, 0.50)],{x:(d) => d,text: ['50%'], lineAnchor: 'top',textAnchor:'end',fontSize:12, fontWeight:'bold',frameAnchor:'top'}),
    Plot.ruleX([calcularPercentil(data, 0.95)], { strokeDasharray: 5, stroke: 'black', strokeWidth: 1 }),
    Plot.text([calcularPercentil( data, 0.95)],{x:(d) => d,text: ['95%'], lineAnchor: 'top',textAnchor:'end',fontSize:12, fontWeight:'bold',frameAnchor:'top'}),
  ]
}

const PlotPQ = ({ report, prescription, photosMetadata, countMetadata, title, task }: IPlotProps) => {
  const isSm = useMediaQuery('(min-width:400px)')
  const isMd = useMediaQuery('(min-width:900px)')
  const isLg = useMediaQuery('(min-width:1300px)')

  const infoSiembra: InfoSiembra = JSON.parse(report.infosiembra)
  const variableInfo: InfoSiembraVariable = JSON.parse(report.infosiembra)
  if(!infoSiembra || !variableInfo) return <></>
  const prescriptionTheme = useAppSelector((state) => {
    return state.ui.prescriptionTheme;
  });
  if (!prescription) return <></>

  const sowType = task.tipoProceso == 1 ? 'Fija' : 'Variable'

  const densidadMinima = d3.min(infoSiembra.densidad)

  const getSize = () => {
    if (isSm) {
      return PlotSizes.sm
    }
    if (isMd) {
      return PlotSizes.md
    }
    if (isLg) {
      return PlotSizes.lg
    }

    return PlotSizes.md
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
      {
        title == 'Densidad' && sowType == 'Fija' && infoSiembra.densidad &&
        <PlotStyled
          title={title}
          unitText={unitText(title)}
          size={getSize()}
          options={{
            x: {
              label:'Densidad'
            },
            
            y:{
              label:'Cantidad'
            },
            marks: [
              RectY({
                data: infoSiembra.densidad, options: {
                  ...Plot.binX({ y: 'count' }),
                  ry: 8,
                  insetBottom: -8,
                  tip:{
                    pointer:'x',
                    fill:'#D9D9D9'
                  }
                }
              }),
              Plot.ruleX([densidadMinima ? densidadMinima - 5000 : 0]),
              Plot.ruleY([0]),
              Plot.ruleX([+countMetadata.result[0].densidadObjetivo], { strokeDasharray: 5, stroke: '#7878FB', strokeWidth: 3, tip: { pointer:'x',pointerSize:1,fill:'#D9D9D9',format: {x:(d) => `Objetivo: ${d}`, }}}),
              Plot.ruleX([+countMetadata.result[0].densidadMediana], { strokeDasharray: 5, stroke: '#FF5050', strokeWidth: 3, tip: {  pointer:'x',pointerSize:1,fill:'#D9D9D9', format: {x:(d) => `Mediana: ${d}`}} }),
              Plot.ruleX([+countMetadata.result[0].densidadMedia], {strokeDasharray: 5, stroke: '#03B625', strokeWidth: 3,tip: {      pointer:'x',pointerSize:1,fill:'#D9D9D9',format: {x:(d) => `Media: ${d}`},frameAnchor:'bottom'} }),
              ...PercentilMarks(infoSiembra.densidad)
            ]
          }}
        />
      }
      {
        title == 'Espaciamiento' && sowType == 'Fija' && infoSiembra !== undefined &&
        <PlotStyled
          title={title}
          unitText={unitText(title)}
          size={getSize()}
          options={{
            x: {
              label:'Espaciamiento'
            },
            y:{
              label:'Plantas'
            },
            marks: [
              Plot.ruleY([0]),
              Plot.ruleX([0]),
              RectY({
                data: infoSiembra.distanciamiento, options: {
                  ...Plot.binX({ y: 'count'}),
                  ry: 2,
                  insetBottom: -2,
                  tip:{
                    pointer:'x',
                    fill:'#D9D9D9'
                  }
                }
              }),
              Plot.ruleX([+countMetadata.result[0].gapObjetivo], { strokeDasharray: 5, stroke: '#7878FB', strokeWidth: 2 , tip: {pointer:'x',pointerSize:1,fill:'#D9D9D9',format: {x:(d) => `Objetivo: ${d}`,frameAnchor:'top'}}}),
              Plot.ruleX([+countMetadata.result[0].gapMediana], { strokeDasharray: 5, stroke: '#FF5050', strokeWidth: 2  , tip: {pointer:'x',pointerSize:1,fill:'#D9D9D9',format: {x:(d) => `Media: ${d}` }}}),
              Plot.ruleX([+countMetadata.result[0].gapMedia], { strokeDasharray: 5, stroke: '#03B625', strokeWidth: 2    , tip: {pointer:'x',pointerSize:1,fill:'#D9D9D9',format: {x:(d) => `Mediana: ${d}`},frameAnchor:'bottom'}}),
              ...PercentilMarks(infoSiembra.distanciamiento)
            ]
          }}
        />
      }
      {
        title == 'Cobertura' && sowType == 'Fija' && infoSiembra !== undefined &&
        <PlotStyled
          size={getSize()}
          title={title}
          unitText={unitText(title)}
          options={{
            x: {
              label:'Cobertura'
            },
            y:{
              label:'Plantas'
            },
            marks: [
              Plot.ruleY([0]),
              Plot.ruleX([0]),
              RectY({
                data: infoSiembra.cobertura, options: {
                  ...Plot.binX({ y: 'count' }),
                  ry: 2,
                  insetBottom: -2,
                  tip:{
                    pointer:'x',
                    fill:'#D9D9D9'
                  }
                }
              }),
              Plot.ruleX([+countMetadata.result[0].coberturaMediana], { strokeDasharray: 5, stroke: '#FF5050', strokeWidth: 2, tip: {pointer:'x',pointerSize:1,fill:'#D9D9D9',format: {x:(d) => `Mediana: ${d}`},anchor:'bottom'}}),
              Plot.ruleX([+countMetadata.result[0].coberturaMedia], { strokeDasharray: 5, stroke: '#03B625', strokeWidth: 2, tip: {pointer:'x',pointerSize:1,fill:'#D9D9D9',format: {x:(d) => `Media: ${d}`},frameAnchor:'bottom'}}),
              ...PercentilMarks(infoSiembra.cobertura)
            ]
          }}
        />
      }
      {
        title == 'Densidad' && variableInfo !== undefined && sowType == 'Variable' &&
        <PlotStyled
          size={getSize()}
          title={title}
          unitText={unitText(title)}
          options={{
            x: {
              label:'Ambientes'
            },
            y:{
              label:'Imagenes'
            },
            marks: [
              Plot.ruleY([0]),
              variableInfo.densidad.map((densidadAmbiente, index) => {
                return (
                  Plot.barY(densidadAmbiente, { x: index, y: (d: number) => d, fill: `${getColor(prescription.prescription?.features[index].properties, index)}`, tip: true })
                )
              })
            ]
          }}
        />
      }
      
      {
        title == 'Espaciamiento' && variableInfo !== undefined && sowType == 'Variable' &&
        <PlotStyled
          size={getSize()}
          title={title}
          unitText={unitText(title)}
          options={{
            x: {
              label:'Ambientes'
            },
            y:{
              label:'Plantas/ha*1000'
            },
            marks: [
              Plot.ruleY([0]),
              variableInfo.distanciamiento.map((distanciamientoAmbiente, index) => {
                return (
                  Plot.boxY(distanciamientoAmbiente, { x: index, y: (d) => d, fill: `${getColor(prescription.prescription?.features[index].properties, index)}`, tip: true })
                )
              })
            ]
          }}
        />
      }
      {
        title == 'Cobertura' && variableInfo !== undefined && sowType == 'Variable' &&
        <PlotStyled
          size={getSize()}
          title={title}
          unitText={unitText(title)}
          options={{
            x: {
              label:'Ambientes'
            },
            y:{
              label:'Cm2/Planta'
            },
            marks: [
              Plot.ruleY([0]),
              variableInfo.cobertura.map((coberturaAmbiente, index) => {
                return (
                  Plot.boxY(coberturaAmbiente, { x: index, y: (d) => d, fill: `${getColor(prescription.prescription?.features[index].properties, index)}`, tip: true })
                )
              })
            ]
          }}
        />
      }
    </div>
  )
}

export const Plots = ({
  task,
  linkUrl,
  title,
  semaphoreType,
  unit,
  countMetadata,
  prescription,
  photosMetadata,
  report
}: IPlotProps) => {

  const { toggle, isActive } = useToggle()
  const sowType = task.tipoProceso == 1 ? 'Fija' : 'Variable'
  // fix
  return (
    <div>
      <PlotPQ
        unit={unit}
        semaphoreType={semaphoreType}
        linkUrl={linkUrl}
        report={report}
        countMetadata={countMetadata}
        photosMetadata={photosMetadata}
        prescription={prescription}
        title={title}
        task={task}
      />
      <PlotDataTable
        unit={title}
        countMetadata={countMetadata}
        type={sowType}
        prescription={prescription}
        task={task}
      />
    </div>
  )

};

const unitText = (title: string) => {
  let textToReturn = '';
  if (title === 'Densidad') {
    textToReturn = '(plantas / ha x 1000)';
  } else if (title === 'Espaciamiento') {
    textToReturn = '(cm)';
  } else if (title === 'Cobertura') {
    textToReturn = '(cm²)';
  } else {
    textToReturn = '';
  }
  return textToReturn;
};
