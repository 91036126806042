import styles from './Centralarea.module.scss';

interface CentralAreaProps {
  children: any;
  sx?: React.CSSProperties,
}

function CentralArea({ children,sx }: CentralAreaProps) {
  return <div style={sx} className={styles.centralArea}>{children}</div>;
}

export default CentralArea;
