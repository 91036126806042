import './MousePosition.css'; 

import { MousePosition } from 'ol/control';
import { createStringXY } from 'ol/coordinate';
import { useContext, useEffect } from 'react';

import MapContext from '../Context/MapContext';
const MMousePosition = ({ projection }: any) => {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const mousePositionControl = new MousePosition({
      projection: projection,
      coordinateFormat: function (coordinate) {
        const formattedCoords = createStringXY(5)(coordinate);
        // Separar las coordenadas por comas
        const coordsArray = formattedCoords.split(', ');
        // Invertir el orden de las coordenadas (asumiendo que están en formato "latitud, longitud")
        const reversedCoords = `${coordsArray[1]}, ${coordsArray[0]}`;
        return reversedCoords;
      },
      className: 'custom-mouse-position', 
    });

    map.addControl(mousePositionControl);

    return () => {
      map.removeControl(mousePositionControl);
    };
  }, [map, projection]);

  return (
    <div id="mouse-position"></div>
  );
};

export default MMousePosition;
