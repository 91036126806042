import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import {
  useGetCountMetadataQuery,
  useGetPrescriptionQuery,
  useGetTaskQuery,
  useGetTaskUploadedMetadataQuery,
} from 'api/Vistaguay/TaskAPI';
import { ReactComponent as BackIcon } from 'assets/icons/ico-back.svg';
import Spinner from 'components/Spinner/Spinner';
import { motion } from 'framer-motion';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import { useParams } from 'react-router';
import { useAppSelector } from 'state/hooks';

import { CountTaskReport } from './CountReport/CountTaskReport';
import style from './TaskView.module.scss';

// const getFileNameWithoutExtension = (fileName: string) => {
//   const lastDotIndex = fileName.lastIndexOf('.');
//   return fileName.substring(0, lastDotIndex);
// };

export function TaskView() {
  const { projectId, lotId, taskId } = useParams<{
    projectId: string;
    lotId: string;
    taskId: string;
  }>();

  if (!projectId || !lotId || !taskId) {
    return null; // FIXME handle error
  }
  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery(
    projectId as unknown as number,
  );

  const { data: lot, isLoading: isLoadingLots } = useGetLotQuery({
    projectId: +projectId,
    lotId: +lotId,
  });

  const { data: task, isLoading: isLoadingTask } = useGetTaskQuery({ taskId });

  const { data: photosMetadata, isLoading: isLoadingPhotoMetadata } =
    useGetTaskUploadedMetadataQuery({ taskId, lotId });
  const { data: countMetadata, isLoading: isLoadingCountMetadata } = useGetCountMetadataQuery({
    taskId,
  });
  const { data: extraData, isLoading: isLoadingExtraData } = useGetExtraDataQuery();

  const { data: prescription } = useGetPrescriptionQuery({ taskId });

  const selectedCampaign = useAppSelector((state) => state.campaign.selectedCampaign);

  const IS_LOADING =
    isLoadingProject ||
    isLoadingTask ||
    isLoadingPhotoMetadata ||
    isLoadingCountMetadata ||
    isLoadingExtraData;

  // const IS_LOADING = isLoadingProject || isLoadingLots || isLoadingTask;
  const SHOW_CONTENT = !IS_LOADING && project && lot && task;

  return (
    <>
      <Sidebar className={style.sideBarSubHeader}>
        {isLoadingProject && <Spinner />}
        {
          <>
            <SidebarBody sx={{ padding: '0px' }}>
              {IS_LOADING && <Spinner />}
              {SHOW_CONTENT && (
                <>
                  <CountTaskReport
                    project={project}
                    lot={lot}
                    task={task}
                    photosMetadata={photosMetadata}
                    countMetadata={countMetadata}
                    extraData={extraData}
                    selectedCampaign={selectedCampaign}
                    prescription={prescription}
                  />
                </>
              )}
            </SidebarBody>
          </>
        }
      </Sidebar>
    </>
  );
}

export const AnimatedBackIcon = () => {
  return (
    <motion.svg
      className={style.icon}
      variants={{
        hoverBack: {
          x: -10,
          transition: {
            duration: 0.3,

            repeat: Infinity,
            repeatType: 'reverse',
          },
        },
      }}
    >
      <BackIcon />
    </motion.svg>
  );
};
