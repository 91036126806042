// #region React
import {ReactComponent as ExportIcon} from 'assets/icons/exportIcon.svg'
import {ReactComponent as AddRxIcon} from 'assets/icons/ico-add-rx.svg'
// #endregion
// #region Icons
import {ReactComponent as DeleteIcon} from 'assets/icons/ico-delete-rx.svg'
import {ReactComponent as EditIcon} from 'assets/icons/ico-edit-rx.svg'
import {ReactComponent as LayersIcon} from 'assets/icons/ico-layers.svg';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';

// #endregion
// #region Styles
import style from './../LotContextual.module.scss'
// #endregion
// #region Options
import AddPrescriptionOption from './Options/AddPrescriptionOption'
import EditOption from './Options/EditOption'
import ExportOption from './Options/ExportOption'
import LayerOption from './Options/LayerOption'
import TrashOption from './Options/TrashOption'
// #endregion


export default function LotContextual({
  lotId,
  projectId
}: {
  lotId: number,
  projectId: number
}) {
  const { t } = useTranslation();

  const [option, setOption] = useState(0);

  const options = [
    <LayerOption key={0} lotId={lotId} projectId={projectId}/>,
    <ExportOption key={1} lotId={lotId} projectId={projectId}/>,
    <AddPrescriptionOption key={2} lotId={lotId} projectId={projectId}/>,
    <EditOption key={3} lotId={lotId} projectId={projectId}/>,
    <TrashOption key={4} lotId={lotId} projectId={projectId}/>
  ];

  return (
    <div className={style.lotContextualMenu}>
      <div className={style.contextualContent}>
        {options[option]}
      </div>
      
      <div className={style.tabBar}>
        <div className={style.tabBarIcons} style={{justifyContent: 'space-between'}}>
          {/* LAYERS */}
          <div
            className={option == 0 ? style.bubble : style.bubbleOff}
            onClick={() => {setOption(0)}}
          >
            <LayersIcon
              style={{ stroke: style.azul}}
              className={style.tabicon}
            />
          </div>

          {/* EXPORT */}
          <div
            className={option == 1 ? style.bubble : style.bubbleOff}
            onClick={() => {setOption(1)}}
          >
            <ExportIcon
              style={{ stroke: style.azul}}
              className={style.tabicon}
            />
          </div>

          {/* ADD RX */}
          <div
            className={option == 2 ? style.bubble : style.bubbleOff}
            onClick={() => {setOption(2)}}
          >
            <AddRxIcon width={25} />
          </div>
          
          {/* EDIT */}
          <div
            className={option == 3 ? style.bubble : style.bubbleOff}
            onClick={() => {setOption(3)}}
          >
            <EditIcon
              width={25}
              style={{ fill: style.azul}}
            />
          </div>

          {/* DELETE */}
          <div
            className={option == 4 ? style.bubble : style.bubbleOff}
            onClick={() => {setOption(4)}}
          >
            <DeleteIcon
              width={25}
              style={{ fill: style.azul}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

