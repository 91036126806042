import styles from './myCustomClass.module.scss'

type Props = {
    children: string | JSX.Element | JSX.Element[] | false
    text: string
    className?: string
    customTextStyle?: React.CSSProperties
    image: string
    ambiente: number
}

export default function TooltipModal ({ children, ambiente,image,text, className = '', customTextStyle = {} }: Props) {
    return (
      <div className={`${styles.tooltip}  ${className}`}>
      {children}
      <div className={styles.tooltiptext} style={customTextStyle}>
        <span className={styles.bold}>Ambiente:</span> {ambiente} <br/>
        <span className={styles.bold}>Densidad:</span> {text} <br/>
        <span className={styles.bold}>Imagen:</span> {image}
      </div>
    </div>
    )
}
