import clsx from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import style from './SwitchButton.module.scss';

export interface SwitchButtonProps {
    value: boolean;
    handleOnChange: (status: boolean) => void;
    switchWidth?: number;
    switchHeight?: number;
    disabled?: boolean;
    showLayersIcon?: boolean;
    overlapDisabled?: boolean;
}

const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30
}

export default function SwitchButton({
    value, 
    handleOnChange,
    switchWidth,
    switchHeight,
    disabled,
    showLayersIcon,
    overlapDisabled
}: SwitchButtonProps) {
    const [isOn, setIsOn] = useState(value);

    const toggleSwitch = () => {
        if (!disabled) {
            const value = !isOn;
            handleOnChange(value);
            if (!overlapDisabled) {
                setIsOn(value);
            }
        }
    }

    useEffect(() => {
        if (overlapDisabled) {
            setIsOn(value);
        }
    }, [value, overlapDisabled]);

    return (
        <div className={style.flexBetween}>
            <div 
                data-ison={isOn} // Changed to lowercase
                onClick={toggleSwitch}
                className={clsx(
                    style.switch, 
                    isOn ? style.switchOn : style.switchOff 
                )}
                style={{
                    width: switchWidth ? `${switchWidth}px` : '24px',
                    height: switchHeight ? `${switchHeight}px` : '14px',
                }}
            >
                <motion.div 
                    className={style.handle} 
                    layout 
                    transition={spring}
                    style={{
                        width: switchHeight ? `${switchHeight - 2}px` : '12px',
                        height: switchHeight ? `${switchHeight - 2}px` : '12px',
                    }}
                />
            </div>
        </div>
    )
}
