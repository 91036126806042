import { useGetCuitsQuery, useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import { useMeQuery } from 'api/Vistaguay/VistaguayAPI';
import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';
import { Input, InputSelect } from 'components/Inputs/Input';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import { PlantCountTask } from 'models/PlantCountTask';
import { Stages } from 'models/Stages';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { cuitPatternMatch } from 'services/helpers';
import { useAppSelector } from 'state/hooks';
import { setCropId, setCuit, setFligthDate, setGenotypeId, setPhenologicalStageId, setTaxIdType } from 'state/slices/pqFormSlice';

import style from '../../AddTask.module.scss';
import { ErrorList, ErrorProps } from '../AddPlantCount';
interface StepProps {
  nextStepTouched: boolean;
  handleError: (error: boolean) => void;
  currentStep: number | string;
}

export default function StepCrops({ handleError, currentStep, nextStepTouched }: StepProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: extraData } = useGetExtraDataQuery();
  const {pqForm} = useAppSelector((state) => state.pqFormState);

  const [errorsList, setErrors] = useState<ErrorList[]>([]);

  function isValidStageForCrop(stage: Stages): boolean {
    const regex = /^(v2|v3|v4)$/i;
    return regex.test(stage.name);
  }

  useEffect(() => {
    dispatch(setTaxIdType('CUIT'));
  }, [])
 

  useEffect(() => {
    if(currentStep == 5) {
      const errors: ErrorList[] = [];
      let error = false; 
    
      if (!pqForm.cropId) {
        errors.push({ message: t('form-crops-required'), propName: 'crop'})
      } else if (!pqForm.phenologicalStateId) {
        errors.push({ message: t('form-phenological-stage-required'), propName: 'phenologicalStateId'})
      } else if (!pqForm.fechaVuelo) {
        errors.push({ message: t('date-of-flight-required'), propName: 'fechaVuelo'})
      } else if ((new Date(pqForm.fechaVuelo)) > new Date()) {
        errors.push({ message: t('date-of-flight-error'), propName: 'fechaVuelo'})
      } else if(pqForm.fechaSiembra && (new Date(pqForm.fechaVuelo)) < (new Date(pqForm.fechaSiembra))) {
        errors.push({ message: t('date-of-flight-error2'), propName: 'fechaVuelo'})
      }
      
      setErrors(errors);
  
      if(errors.length > 0) { error = true };
    
      handleError(error); 
    }
  }, [
    pqForm.cropId,
    pqForm.phenologicalStateId,
    pqForm.fechaVuelo
  ])

  return (
    <div className={style.bodyContent} style={{ gap: '5px' }}>
      {/* === CULTIVO ===*/}
      <div style={{marginBottom: '10px'}}>
        <label>{t('crops')}</label>
        <InputSelect
          placeholder={t('select-placeholder')}
          id={t('crop').toString()}
          options={
            extraData?.crops
              ?.filter((crop) => {
                return crop.name.startsWith('Maíz') || crop.name.startsWith('Girasol');
              })
              .map((crop) => ({ value: crop.id, name: crop.name })) || []
          }
          value={pqForm?.cropId || ''}
          onChange={(e) => {
            dispatch(setCropId(+e.target.value))
          }}
        >
          <option value={''} disabled>{t('select-placeholder')}</option>
        </InputSelect>
        
        {/* ERROR MSG */}
        { nextStepTouched && errorsList.map((error, index) => {
          if (error.propName === 'crop') {
            return <Alert key={index} type='error' text={error.message} />;
          } else {
            return null;
          }
        })}
      </div>

      {/* === GENOTIPO ===*/}
      <div style={{marginBottom: '10px'}}>
        <label htmlFor=''>
          {t('genotype')} 
          <span className={style.smallLegend}>({t('opcional')})</span>
        </label>
        <InputSelect
          placeholder={t('select-placeholder')}
          id={t('genotype').toString()}
          options={
            extraData?.crops
              .find((crop) => crop.id === (pqForm?.cropId ? +pqForm?.cropId : -1))
              ?.hybrids.map((hybrid) => ({
                value: hybrid.id,
                name: hybrid.name,
              }))
              .sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              }) || []
          }
          value={pqForm?.genotypeId || ''}
          onChange={(e) => {
            dispatch(setGenotypeId(+e.target.value))
          }}
          disabled={!pqForm?.cropId ?? true}
        >
          <option value={''} disabled>{t('select-placeholder')}</option>
        </InputSelect>
      </div>

      {/* === ESTADIO FENOLOGICO ===*/}
      <div style={{marginBottom: '10px'}}>
        <label htmlFor=''>{t('phenological-state')}</label>
        <InputSelect
          placeholder={t('phenological-state')}
          id={t('phenological-state').toString()}
          options={
            extraData?.crops
              // .filter((crop: Crop) => validForCount(crop))
              .find((crop) => crop.id === (pqForm?.cropId ? +pqForm.cropId : -1))
              ?.stages.filter((stage: Stages) => isValidStageForCrop(stage))
              .map((stage: Stages) => ({
                value: stage.id,
                name: stage.name,
              }))
              .sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              }) || []
          }
          value={pqForm?.phenologicalStateId || ''}
          onChange={(e) => {
            dispatch(setPhenologicalStageId(+e.target.value))
          }}
          disabled={!pqForm?.cropId ?? true}
        >
          <option value={''} disabled>{t('select-placeholder')}</option>
        </InputSelect>
        
        {/* ERROR MSG */}
        { nextStepTouched && errorsList.map((error, index) => {
          if (error.propName === 'phenologicalStateId') {
            return <Alert key={index} type='error' text={error.message} />;
          } else {
            return null;
          }
        })}
      </div>

      {/* === FECHA DE VUELO ===*/}
      <div style={{marginBottom: '10px'}}>
        <p>{t('date-of-flight')}</p>
        <Input
          placeholder={t('date-of-flight')}
          value={pqForm?.fechaVuelo ? pqForm?.fechaVuelo.slice(0, 10) : ''}
          type='date'
          id={t('date-of-flight').toString()}
          onChange={(e) => {
            const newDate = new Date(e.target.value);
            const newDateString = newDate.toISOString()
            dispatch(setFligthDate(newDateString))
          }}
        />
        {/* ERROR MSG */}
        { nextStepTouched && errorsList.map((error, index) => {
          if (error.propName === 'fechaVuelo') {
            return <Alert key={index} type='error' text={error.message} />;
          } else {
            return null;
          }
        })}
      </div>

      {/* === INFO TRIBUTARIA ===*/}
      <div style={{marginBottom: '10px'}}>
        <label >
          {t('tax-id')} 
          <span className={style.smallLegend}>({t('opcional')})</span>
        </label>
        <div className={style.taxIdWrapper}>
          <Input
            id={t('cuit').toString()}
            placeholder=''
            maxLenght={13}
            type='text'
            value={pqForm?.CUIT || ''}
            disabled={pqForm.taxIdType === ''}
            onChange={(e) => {
              let taxId = e.target.value;
              if(pqForm.taxIdType === 'CUIT') {
                taxId = cuitPatternMatch(e.target.value, 'xx-xxxxxxxx-x');
              }
              
              dispatch(setCuit(taxId))
            }}
            onKeyPress={(event) => {
              if (event.key == 'Backspace') {
                return;
              } else if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          <InputSelect
            id={'tax-id-type'}
            placeholder=''
            options={[{value:'CUIT', name: 'CUIT'}]}
            value={pqForm.taxIdType || ''}
            className={style.taxIdTypeSelect}
            onChange={(e) => {dispatch(setTaxIdType(e.target.value))}}
          >
            <option value={''} disabled></option>
          </InputSelect>
        </div>
        
      </div>
    </div>
  );
}