/* eslint-disable camelcase */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCampaignProjectsQuery } from 'api/Vistaguay/CampaignAPI';
import { ReactComponent as CrossIcon } from 'assets/icons/ico-close.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/ico-folder.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ico-search.svg';
import Button from 'components/Button/Button';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import { Campaign } from 'models/Campaign';
import Project from 'models/Project';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';

import style from './Search.module.scss';

function Search() {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<Project[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState(0);
  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );

  const { t } = useTranslation();

  const { data, isSuccess } = useGetCampaignProjectsQuery(selectedCampaign?.id ?? skipToken, {});
  const navigate = useNavigate();
  useEffect(() => {
    if (inputRef.current) {
      setWidth(inputRef.current.offsetWidth);
    }
  }, [inputRef]);

  const handleSearch = async (searchText: string) => {
    setSearchText(searchText);
    setSearchResults(search(searchText));
  };

  const search = (searchText: string) => {
    if (isSuccess) {
      return data.filter(
        (project) =>
          searchText.toLocaleLowerCase() && project.nombre.toLocaleLowerCase().includes(searchText),
      );
    } else {
      return [];
    }
  };

  return (
    <>
      <Sidebar>
        <div className={style.searchHeader}>
          <div
            ref={inputRef}
            id='searchInput'
            className={style.input}
            style={{
              borderBottomRightRadius: searchText && searchResults.length ? 0 : 20,
              borderBottomLeftRadius: searchText && searchResults.length ? 0 : 20,
            }}
          >
            <input
              placeholder={t('busca-un-proyecto-solo-en-esta-campana') ?? ''}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {searchText && (
              <CrossIcon className={style.crossIcon} onClick={() => handleSearch('')} />
            )}

            {!searchText && <SearchIcon className={style.searchIcon} />}
          </div>
          {searchResults?.length > 0 && (
            <div
              style={{
                width: width,
              }}
              className={style.searchResults}
            >
              {searchResults.map((project) => (
                <div key={project.proyectoId} className={style.searchResult}>
                  <div
                    className={style.searchResultTitle}
                    onClick={() => {
                      navigate(`/projects/${project.proyectoId}`);
                    }}
                  >
                    <p>{project.nombre}</p>
                    <FolderIcon
                      style={{
                        height: '25px',
                        width: '25px',
                        fill: project.color,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <SidebarBody>
          
        </SidebarBody>
        <SidebarFooter sx={{ display: 'flex', alignSelf: 'flex-end' }}>
          <Button
            text={t('cancelar')}
            variant='ERROR'
            style={{ width: '100%' }}
            onClick={() => {
              navigate('/campaign/search');
            }}
          />
        </SidebarFooter>
      </Sidebar>
    </>
  );
}

export default Search;
