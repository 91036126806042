import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COLOR_RAMP } from 'models/Algorithm';


export interface LayerInfo {
  layerName: string;
  minValue: number;
  maxValue: number;
  visible: boolean;
  opacity: number;
}

export interface MosaicTaskView {
  id: number;
  name: string;
  layers: LayerInfo[];
  showPhotoPoints: boolean;
  data: any;
  histogramView: HistogramView | null;
}

export interface HistogramView {
  id: number;
  layer: string;
  render: string;
  min: number;
  max: number;
  steps: number;
  stepValues: number[];
  clipLote: boolean; // pueden ser las coordenadas https://openlayers.org/en/latest/examples/layer-clipping-vector.html
  colorRamp: string | COLOR_RAMP;
  grouped: boolean;
  cutValues: boolean;
}

export interface MosaicState {
  variMin: number; // remove
  variMax: number; // remove
  layersToShow: boolean[];
  layers: string[];
  activeLayers: LayerInfo[];
  mosaics: MosaicTaskView[];
}

const initialState: MosaicState = {
  variMin: -1,
  variMax: 1,
  layersToShow: [],
  layers: [],
  activeLayers: [],
  mosaics: [],
} as MosaicState;

const reducer = {
  addMosaic: (state: MosaicState, action: PayloadAction<MosaicTaskView>) => {
    state.mosaics.push(action.payload);
  },
  removeMosaic: (state: MosaicState, action: PayloadAction<string>) => {
    state.mosaics = state.mosaics.filter((value, index, array) => {
      if (value.name == action.payload) {
        array.splice(index, 1);
        return true;
      }
      return false;
    });
  },
  updateMosaic: (state: MosaicState, action: PayloadAction<MosaicTaskView>) => {
    const index = state.mosaics.findIndex((l) => l.id === action.payload.id);
    if (index === -1) {
      state.mosaics.push(action.payload);
      return;
    }
    state.mosaics[index] = action.payload;
  },
  updateMaxMin: (
    // remove
    state: MosaicState,
    action: PayloadAction<{ variMin: number; variMax: number }>,
  ) => {
    state.variMin = action.payload.variMin;
    state.variMax = action.payload.variMax;
  },
  setLayers: (state: MosaicState, action: PayloadAction<{ layers: string[] }>) => {
    (state.layers = action.payload.layers),
      (state.layersToShow = action.payload.layers.map((layer) => {
        return true;
      }));
  },
  setMosaicTaskLayers: (
    state: MosaicState,
    action: PayloadAction<{ id: number; layers: LayerInfo[] }>,
  ) => {
    const index = state.mosaics.findIndex((l) => l.id === action.payload.id);
    state.mosaics[index].layers = action.payload.layers;
  },
  setToggleShowLayer: (state: MosaicState, action: PayloadAction<{ index: number }>) => {
    state.layersToShow[action.payload.index] = !state.layersToShow[action.payload.index];
  },
  setHistogramData: (state: MosaicState, action: PayloadAction<HistogramView>) => {
    const index = state.mosaics.findIndex((l) => l.id === action.payload.id);
    if (index === -1) {
      console.warn('setHistogramData:: index not found');
      return;
    }

    state.mosaics[index].histogramView = action.payload;
  },
  toggleShowPhotoPoints:(state:MosaicState, action: PayloadAction<number>) => {
    const index = state.mosaics.findIndex((l) => l.id === action.payload)
    state.mosaics[index].showPhotoPoints = !state.mosaics[index].showPhotoPoints
  },
  clearHistogramData: (state: MosaicState, action: PayloadAction<number>) => {
    const index = state.mosaics.findIndex((l) => l.id === action.payload);
    if (index === -1) {
      console.warn('setHistogramData:: index not found');
      return;
    }
    state.mosaics[index].histogramView = null;
  },
};

export const mosaicSlice = createSlice({
  name: 'mosaic',
  initialState,
  reducers: reducer,
});
export const {
  addMosaic,
  removeMosaic,
  updateMaxMin,
  setLayers,
  setMosaicTaskLayers,
  setToggleShowLayer,
  updateMosaic,
  setHistogramData,
  clearHistogramData,
  toggleShowPhotoPoints,
} = mosaicSlice.actions;

export default mosaicSlice.reducer;
