
import cx from 'classnames';

import styles from './Skeleton2.module.scss'

export default function Skeleton2 () {

    return (
        <div>
            <div className={styles.wrapper}>
                <div className={cx(styles.bar, styles.skeletonContent)}/>
                <div className={cx(styles.bar, styles.skeletonContent)}/>
                <div className={cx(styles.bar, styles.skeletonContent)}/>
            </div>
        </div>
       
    )
}