import clsx from 'classnames'
import { CSSProperties } from 'react';

import style from './SidebarFooter.module.scss';

interface SidebarFooterProps {
  children: React.ReactNode;
  sx?: CSSProperties;
  oneChildContainer?: boolean
}

export default function SidebarFooter({ children, sx = {}, oneChildContainer }: SidebarFooterProps) {
  return (
    <div 
        style={sx} 
        className={clsx(
            style.Footer,
            !oneChildContainer ? style.multiChildrenContainer : style.oneChildrenContainer
        )}
    >
      {children}
    </div>
  );
}
