import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';

import style from '../../../pages/Tasks/AddTask.module.scss';

interface PopupProps {
    handleCancelModalclose: () => void;
    handleExit: () => void;
    text: string;
    showCancelBtn?: boolean
}

export default function CancelPopup ({
    handleCancelModalclose,
    handleExit,
    text,
    showCancelBtn
}: PopupProps) {
    const { t } = useTranslation();

    return (
        <div className={style.cancelModal}>
            <p style={{marginBottom: '20px', textAlign:'center'}}>{text}</p>
            <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                {
                    showCancelBtn && (
                        <Button
                            style={{width: '100%'}}
                            text={t('cancel')}
                            variant='NEUTRAL-GRAY'
                            onClick={() => handleCancelModalclose()}
                        />
                    )
                }
                
                <Button
                    style={{width: '100%'}}
                    text={t('exit')}
                    variant='ERROR'
                    onClick={() => handleExit()}
                />
            </div>
        </div>
    )
}