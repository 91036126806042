import { ReactComponent as InfoIcon } from 'assets/icons/ico-info.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashIcon.svg';
import { useState } from 'react';

import styles from './CampaignContextual.module.scss'
import InfoOption from './Options/InfoOption';
import TrashOption from './Options/TrashOption';

export default function CampaignContextual ({
    campaign
}: {
    campaign: any
}) {
    const [option, setOption] = useState(0);

    const options = [
        <InfoOption key={0} editable={false} campaign={campaign}/>,
        <TrashOption key={1} campaignId={campaign.id}/>
    ];

    return (
        <div className={styles.contextualMenu}>
            <div className={styles.contextualContent}>
                {options[option]}
            </div>

            <div className={styles.tabBar}>
                <div className={styles.tabBarIcons}>
                    <div
                        className={option == 0 ? styles.bubble : styles.bubbleOff}
                        onClick={() => {setOption(0)}}
                    >
                        <InfoIcon className={styles.tabicon} />
                    </div>
                    <div
                        className={option == 1 ? styles.bubble : styles.bubbleOff}
                        onClick={() => {setOption(1)}}
                    >
                        <TrashIcon className={styles.tabicon} height={30}/>
                    </div>
                </div>
            </div>
        </div>
    )
}