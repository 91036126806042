/* eslint-disable react/prop-types */
import { Icon, IconSize } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { current } from '@reduxjs/toolkit';
import { ReactComponent as AltitudeIcon } from 'assets/icons/altitudeIcon.svg'
import { ReactComponent as GimbalIcon} from 'assets/icons/GimbalIcon.svg'
import { ReactComponent as InclinationIcon} from 'assets/icons/ico-inclination.svg'
import {ReactComponent as TimeIcon} from 'assets/icons/ico-time.svg';
import { ReactComponent as ImgIcon } from 'assets/icons/imgIcon.svg';
import {ReactComponent as SpeedIcon} from 'assets/icons/speedIcon.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trashIcon.svg';
import Button from 'components/Button/Button';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import { CheckBox } from 'components/CheckBox/CheckBox';
import { Input } from 'components/Inputs/Input';
import { style } from 'components/Map/Components/Measure/MeasureLib';
import {Modal, ModalBody} from 'components/Modal/SidebarModal';
import { MappingMission, ScoutingMission, ScoutingMissionPointAction } from 'models/Mission';
import React, { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { IAction, setCurrentMission, setDiffentialAltitude } from 'state/slices/volareSlice';

import ListInputItem from '../Components/InputList/ListInputItem';
import styles from './MissionPointForm.module.scss';

interface IWaypoint {
  altitude?: number,
  speed?: number,
  gimbal?: number,
  wait?: number,
  photo?: number
}

interface MissionPointFormProps {
  index: number;
  mission: ScoutingMission;
  // handleAddAction: (action: any) => void;
  // handleEditAction: (action: any) => void;
  // handleRemoveAction: (index: number) => void;
  // removeCurrentPoint: () => void;
  // handleChangeWaypointParams: (params: any) => void;
  // handleChangeAllWaypointParams: (params: any) => void;
}

export const MissionPointForm = ({
  index,
  mission,
  // handleAddAction,
  // handleRemoveAction,
  // removeCurrentPoint,
  // handleChangeWaypointParams,
  // handleChangeAllWaypointParams,
}: MissionPointFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const speedElement = () => <SpeedIcon height={25} />
  const inclinationIcon = () => <InclinationIcon height={25} />
  const timeIcon = () => <TimeIcon height={25} />

  // const [changeAll, setChangeAll] = useState<boolean>(false);
  // const [selectedAction, setSelectedAction] = useState<string>('Wait');
  // const [actionValue, setActionValue] = useState<number>();
  // const [showModal, setShowModal] = useState(false);
  const [waypoint, setWaypoint] = useState<IWaypoint>({
    speed: mission.waypoints[index]?.speed || 10,
    altitude: mission.waypoints[index]?.altitude,
    gimbal: mission.waypoints[index]?.actions.find((action) => action.action == 'GIMBAL')?.value || 0,
    wait: mission.waypoints[index]?.actions.find((action) => action.action == 'WAIT')?.value || 0,
    photo: mission.waypoints[index]?.actions.find((action) => action.action == 'PHOTO')?.value || 1,
  });

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  const handleInputWaypointParamsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = Number(target.value);

    const newWp = {
      ...waypoint,
      [name]: value,
    };
  
    setWaypoint(newWp);
    handleCurrentMissionWaypointValuesChange({wp: newWp});
    // handleChangeWaypointParams(newWp);

    // if (changeAll) {
    //   setTimeout(() => {
    //     handleChangeAllWaypointParamsChange();
    //   }, 200)
    // }
  };

  const handleCurrentMissionWaypointValuesChange = ({
    wp,
    modifyAll = false
  } : {
    wp: IWaypoint,
    modifyAll?: boolean
  }) => {
    // Create a shallow copy of the waypoints array
    let waypoints = [...mission.waypoints];
  
    const actions = [
      {
        index: 0,
        action: 'GIMBAL',
        value: wp?.gimbal || null
      },
      {
        index: 1,
        action: 'WAIT',
        value: wp?.wait || null
      },
      {
        index: 2,
        action: 'PHOTO',
        value: wp?.photo || null
      }
    ]; 
  
    if (modifyAll) {
      // Update all elements in the array with the new waypoint values
      waypoints = waypoints.map((waypoint, i) => ({
        ...waypoint,
        index: i, 
        actions: actions
      }));
    } else {
      // Update only the waypoint at the specified index
      waypoints[index] = {
        index: index,
        lat: waypoints[index].lat,
        lng: waypoints[index].lng,
        altitude: waypoints[index].altitude,
        speed: waypoints[index].speed,
        actions: actions
      };
    }

    // Update fligth plan total photo display
    let photoCount = 0;

    waypoints.forEach((w) => {
      w.actions.forEach((a) => {
        if(a.action == 'PHOTO') {
          const value = a?.value ? a?.value : 1; 
          photoCount += value;
        } 
      });
    })

    // Dispatch the new mission object with the updated waypoints array
    dispatch(setCurrentMission({
      ...mission,
      photoCount: photoCount,
      waypoints: waypoints
    }));
  }

  const handleApplyToAll = () => {
    handleCurrentMissionWaypointValuesChange({wp: waypoint, modifyAll: true})
  }

  useEffect(() => {
    setWaypoint({
      speed: mission.waypoints[index]?.speed || 10,
      altitude: mission.waypoints[index]?.altitude,
      gimbal: mission.waypoints[index]?.actions.find((action) => action.action == 'GIMBAL')?.value || 0,
      wait: mission.waypoints[index]?.actions.find((action) => action.action == 'WAIT')?.value || 0,
      photo: mission.waypoints[index]?.actions.find((action) => action.action == 'PHOTO')?.value || 1,
    })
  }, [index])

  // const handleChangeAllWaypointParamsChange = () => {
  //   handleChangeAllWaypointParams({
  //     altitude: waypoint.altitude,
  //     speed: waypoint.speed,
  //     actions: [
  //       {
  //         index: 0,
  //         action: 'GIMBAL',
  //         value: waypoint.gimbal
  //       }, 
  //       {
  //         index: 1,
  //         action: 'WAIT',
  //         value: waypoint.wait
  //       }, 
  //       {
  //         index: 2,
  //         action: 'PHOTO',
  //         value: waypoint.photo  
  //       }
  //     ],
  //   });
  // };

  // const handleAddActionForm = (index : number) => {
  //   const action: IAction = {
  //     action: selectedAction,
  //     value: actionValue ?? 0,
  //     index: index,
  //   };
  //   setActionValue(0);
  //   handleAddAction(action);
  // };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {/* { showModal && (
          <div style={{display:'flex', position:'absolute', background:styles.darkdisabled, height:'112%', width:'100%' ,zIndex:2, justifyContent:'center',alignItems:'center',padding:'10px'}}>
            <div style={{width:'100%',background:styles.azul,padding:'15px',display:'flex', justifyContent:'center',flexDirection:'column',alignItems:'center',borderRadius:'10px'}}>
              <h3 style={{color:'white'}}>Agregar Accion</h3>
              <div>
                {selectedAction == 'Gimbal' ?
                  <div style={{display:'flex', alignItems:'center'}}>
                    <GimbalIcon style={{ width: '20px', height: '20px', fill: styles.verdeVistaguay }} />
                    <p style={{color:styles.verdeVistaguay, margin:'0px 0px 0px 10px'}}>Gimbal</p>
                  </div>
                  :
                  selectedAction == 'Wait' ?
                  <div style={{display:'flex', alignItems:'center'}}>
                    <TimeIcon style={{ width: '20px', height: '20px', fill: styles.verdeVistaguay }} />
                    <p style={{color:styles.verdeVistaguay, margin:'0px 0px 0px 10px'}}>Esperar</p>
                  </div>
                  :
                  selectedAction == 'Photo' ?
                  <div style={{display:'flex', alignItems:'center'}}>
                    <ImgIcon style={{ width: '20px', height: '20px', fill: styles.verdeVistaguay }} />
                    <p style={{color:styles.verdeVistaguay, margin:'0px 0px 0px 10px'}}>Foto</p>
                  </div>
                  :
                  ''
                }
              </div>
              <div>
                <input
                  placeholder=''
                  type='number'
                  min={0}
                  max={15}
                  value={actionValue}
                  style={{width:'100%',color:styles.light, height:'30px', borderRadius:'0px', border:'2px solid',borderColor:styles.lightDisabled,background:'transparent', padding:'5px', margin:'10px 0px 10px 0px'}}
                  onChange={(e) => {
                    setActionValue(Number(e.target.value));
                  }}
                />
                <div style={{display:'flex', gap:'20px'}}>
                  <Button
                    style={{fontSize: '12px',height: '30px', width: '100%', margin: '0px 0px 0px 0px',textAlign: 'center',alignItems: 'center',justifyContent: 'center',display: 'flex'}}
                    type='FILL'
                    color='white'
                    text='Agregar'
                    variant='SUCCESS'
                    onClick={() => {
                      // handleAddActionForm(index);
                      handleCloseModal();
                    }}
                  />
                  <Button
                    style={{fontSize: '12px',height: '30px', width: '100%', margin: '0px 0px 0px 0px',textAlign: 'center',alignItems: 'center',justifyContent: 'center',display: 'flex'}}
                    type='FILL'
                    variant='ERROR'
                    color='white'
                    text='Cancelar'
                    onClick={() => {
                      handleCloseModal();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
      )} */}

      <p style={{marginBottom: '20px'}} >
        Punto <strong>{index + 1}</strong> de {mission.waypoints.length}
      </p>

      <div className={styles.form}>
        {/* <ListInputItem 
          Icon={speedElement}
          amount={waypoint.speed} 
          min={1} 
          max={15} 
          text={`${t('speed')}`}
          unity='m/s' 
          name='speed'  
          handleAction={(e) => {handleInputWaypointParamsChange(e)}} 
        />

        <ListInputItem 
          Icon={altitudeElement}
          amount={waypoint.altitude} 
          min={5} 
          max={200} 
          text={`${t('altitude')}`}
          unity='m' 
          name='altitude'  
          handleAction={(e) => {handleInputWaypointParamsChange(e)}} 
        /> */}

        <ListInputItem 
          Icon={inclinationIcon}
          amount={waypoint?.gimbal || 0} 
          min={-180} 
          max={180} 
          text={`${t('angle')}`}
          unity='°' 
          name='gimbal'  
          handleAction={(e) => {handleInputWaypointParamsChange(e)}} 
        />

        <ListInputItem 
          Icon={timeIcon}
          amount={waypoint?.wait || 0} 
          min={0} 
          max={15} 
          text={`${t('wait')}`} 
          unity='seg' 
          name='wait'  
          handleAction={(e) => {handleInputWaypointParamsChange(e)}} 
        />

        <ListInputItem 
          Icon={speedElement}
          amount={waypoint?.photo || 1 } 
          min={1} 
          max={15} 
          text={`${t('pictures')}`}
          unity='' 
          name='photo'  
          handleAction={(e) => {handleInputWaypointParamsChange(e)}} 
        />
      </div>

      <Button
        color='white'
        text='Aplicar a todos los puntos' 
        onClick={() => handleApplyToAll()} 
      />

      {
        /*
        Action list  add button
      <div className={styles.actionListHeader}>
        <h3>Acciones</h3>
        <Button
          type='FILL'
          color='white'
          text='' onClick={handleOpenModal} className={styles.add_project_btn}
          icon={<Icon className={styles.addIcon} icon='plus' size={ 32} />}
        />
      </div>
        */
      }
      {/* <div className={styles.actionsConteiner}>

        {mission.waypoints[index] && mission.waypoints[index].actions.map((a, idx) => {
          return (
            <ActionItem
              key={`${idx}:${a.action}:${a.value}`}
              action={a.action}
              value={a.value ?? 0}
              actionIndex={idx}
            />
          );
        })}
      </div>
      <form className={styles.addActionsConteiner}>
        <div onClick={() => handleOpenModal()}>

          <p>Agregar</p>
        </div>
        <div className={styles.actionSelector}>
          {
            Actions.map((actionName) => {

              return (
                <div className={styles.actionSelectorItem} key={actionName}>
                  <input
                    hidden={true}
                    type='radio'
                    id={actionName}
                    name='action'
                    value={actionName}
                    checked={selectedAction == actionName}
                    onChange={() => setSelectedAction(actionName)}
                  />
                    

                  <label htmlFor={actionName}>
                    {
                      actionName == 'Gimbal' ? 
                      <div
                      onClick={() => setSelectedAction(actionName)} >

                        <GimbalIcon 
                         
                          style={{ width: '20px', height: '20px', fill: selectedAction == actionName ? styles.verdeVistaguay : styles.light }} /> 
                      </div>
                      :
                      actionName == 'Wait' ? 
                      <div
                      onClick={() => setSelectedAction(actionName)} >

                        <TimeIcon 
                         
                          style={{ width: '20px', height: '20px', fill: selectedAction === actionName ? styles.verdeVistaguay : styles.light }} 
                        /> 
                      </div>
                      :
                      actionName == 'Photo' ? 
                      <div
                      onClick={() => setSelectedAction(actionName)} >
                      
                        <ImgIcon 
                          onClick={() => setSelectedAction(actionName)} 
                          style={{ width: '20px', height: '20px',fill: selectedAction === actionName ? styles.verdeVistaguay : styles.light  }} 
                        /> 
                      </div>
                      : 
                      ''
                    }
                  </label>
                </div>
              )
            })
          }
        </div>
      </form>
      <br />
      <div style={{display:'flex',gap:'10px',margin:0,padding:0}}>
        <p>Aplicar a todos los puntos</p>  
        <CheckBox
          checked={changeAll}
          onClick={() => setChangeAll(!changeAll)}
        />
      </div> */}
    </div>
  );
};
