import { ReactComponent as ArrowIcon } from 'assets/icons/arrowIcon.svg'
import { ReactComponent as LayerIcon } from 'assets/icons/ico-layers.svg'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { InputSelect } from 'components/Inputs/Input'
import { useState } from 'react'

import styles from './Steps.module.scss'

export default function StepThree() {

    const ResumeListItem = ({ name }: { name: string }) => {

        const [options, setOptions] = useState<boolean>(false)

        const Check = ({ name }: { name: string }) => {
            return (
                <div className={styles.check}>
                    <CheckBox />
                    <h5 style={{ margin: '0', fontWeight: '300', fontSize: '12px' }}>{name}</h5>
                </div>
            );
        }
        const Menu = () => {
            return (
                <div className={styles.menu} style={{ display: options ? 'flex' : 'none' }}>
                    <h5 style={{ fontWeight: '300', fontSize: '12px' }}>Predicado geometrico</h5>
                    <div className={styles.menuItems}>
                        <Check name='Intriseca' />
                        <Check name='Solapa' />
                        <Check name='Intriseca' />
                    </div>
                    <div>
                        <InputSelect
                            className={styles.menuOptions}
                            placeholder='Bandas a resumir' >
                                <option value="" >Bandas a resumir</option>
                        </InputSelect>
                        <InputSelect
                            className={styles.menuOptions}
                            placeholder='Estadisticas a calcular' >
                                <option value="" id=''>Estadisticas a calcular</option>
                        </InputSelect>
                        {
                            /*  
                                            
                                <div className={styles.menuOptions}>
                                    <h5 style={{ margin: '0', fontWeight: '300', fontSize: '12px' }}>Bandas a resumir</h5>
                                    <ArrowIcon className={styles.icon} />
                                </div>
                                <div className={styles.menuOptions}>
                                    <h5 style={{ margin: '0', fontWeight: '300', fontSize: '12px' }}>Estadisticas a calcular</h5>
                                    <ArrowIcon className={styles.icon} />
                                </div>
                            */
                        }
                    </div>
                </div>
            )
        }
        const handleOptions = () => {
            setOptions(!options)
        }

        return (
            <>
                <div className={styles.listItem}>
                    <div className={styles.listInfo}>
                        <LayerIcon className={styles.icon} />
                        <p>{name}</p>
                    </div>
                    <div className={styles.listGrey}>
                        <button className={styles.listRight} onClick={handleOptions}>
                            <ArrowIcon style={{ transform: options ? 'rotate(270deg)' : 'rotate(90deg)' }} className={styles.iconDropDownItem} />
                        </button>
                    </div>
                </div>
                <Menu />
            </>
        )
    }

    return (
        <div style={{ marginTop: '15px' }}>
            <div>
                <h5 style={{ fontWeight: ' 600' }}>Seleccione campos a resumir</h5>
            </div>
            <div className={styles.listForm}>
                <ResumeListItem name='Layer | VARI' />
                <ResumeListItem name='Layer | RENDIMIENTO' />
                <ResumeListItem name='Layer | VERIS' />
            </div>
        </div>
    )
}

