import { ReactComponent as LayerIcon } from 'assets/icons/ico-layers.svg'
import { CheckBox } from 'components/CheckBox/CheckBox'

import styles from './Steps.module.scss'

export default function StepTwo() {

    const DropDownListItem = ({ name }: { name: string }) => {
        return (
            <div className={styles.listItem}>
                <div className={styles.listInfo}>
                    <LayerIcon className={styles.icon} />
                    <p>{name}</p>
                </div>
                <div className={styles.listRight}>
                    <CheckBox />
                    {/* <ArrowIcon className={styles.iconDropDownItem} /> */}
                </div>
            </div>
        )
    }
    return (


        <div style={{ marginTop: '15px' }}>
            <div>
                <h5 style={{ fontWeight: ' 600' }}>Selecciona las capas a unir</h5>
                <p style={{ fontSize: '12px', color: '#47C278' }}>*Recuerda que debes seleccionar al menos una capa</p>
            </div>
            <div className={styles.listForm}>
                <DropDownListItem name='Layer | Nitrogeno' />
                <DropDownListItem name='Layer | Nitrogeno' />
                <DropDownListItem name='Layer | Nitrogeno' />
            </div>
        </div>
    )
}

