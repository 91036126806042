export const ColorCodes = {
  GREEN: '#018836',
  LIGHT_GREEN: '#3df50f',
  YELLOW: '#FCEA1F',
  ORANGE: '#EA8324',
  RED: '#DD0701',
};

export const ColorCoverage = {
  color: 'rgba(170, 168, 168, 0.6)',
  stroke: '#202124',
};
export const cvRadius = {
  small: 5,
  medium: 10,
  big: 15,
};

export const ZIndexMarker = 8;
export const ZIndexLot = 10000;
