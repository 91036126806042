import { BuyCreditDto } from 'models/Credit';
import { EditPricesRequest, Price } from 'models/Prices';

import { VistaguayApi } from './VistaguayAPI';

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Prices'],
});

const creditApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getPrices: builder.query<Price, void>({
      query: () => 'prices',
      providesTags: ['Prices'],
    }),
    editPrices: builder.mutation<any, EditPricesRequest>({
      query: (credit) => ({
        url: 'prices',
        method: 'POST',
        body: credit,
      }),
      invalidatesTags: ['Prices'],
    }),
  }),
});

export const { 
    useGetPricesQuery,
    useEditPricesMutation,
 } = creditApi;
