import styles from './SwitchButton.module.scss'

export default function SwitchButton() {
    return (
        <>
            <label className={styles.switch}>
                <input type="checkbox" />
                <span className={styles.slider}></span>
            </label> 
        </>
    )
}
