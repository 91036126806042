import { MultiPoint, Polygon } from 'ol/geom';
import Modify from 'ol/interaction/Modify';
import Select from 'ol/interaction/Select';
import Translate from 'ol/interaction/Translate';
import VectorLayerOL from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import React, { useEffect } from 'react';

import MapContext from '../Context/MapContext';
import VectorLayerContext from '../Context/VectorLayerContext';

interface LayerProps {
  children: React.ReactNode;
  editable?: boolean;
  isVisible?: boolean;
  layerName?: string;
  zIndex?: number;
  handleChangeOrder?: () => void;
}

/**
 *
 * @zIndex the larger the zIndex more on top will the layer be. Useful to control the order of the layers
 * @returns
 */
function MVectorLayer({ 
  children, 
  editable, 
  layerName, 
  zIndex, 
  isVisible = true,
  handleChangeOrder 
}: LayerProps) {
  // const mapClass = MapSingleton.getInstance();
  
  const map = React.useContext(MapContext);

  const vectorSourceRef = React.useRef<VectorSource>(
    new VectorSource({
      features: [],
    }),
  );

  const vectorLayerRef = React.useRef<VectorLayerOL<VectorSource>>(
    new VectorLayerOL({
      // declutter: true,

      source: vectorSourceRef.current,
      zIndex: zIndex,
      className: layerName,
      style: [

        /* We are using two different styles for the polygons:
         *  - The first style is for the polygons themselves.
         *  - The second style is to draw the vertices of the polygons.
         *    In a custom `geometry` function the vertices of a polygon are
         *    returned as `MultiPoint` geometry, which will be used to render
         *    the style.
         */
        new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 3,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
          zIndex: zIndex,
        }),
        new Style({
          zIndex: zIndex,
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({
              color: 'orange',
            }),
          }),
          geometry: function (feature) {
            // return the coordinates of the first ring of the polygon
            const geometry = feature.getGeometry();
            if (geometry instanceof Polygon) {
              const coordinates = geometry.getCoordinates()[0];
              if (coordinates) {
                return new MultiPoint(coordinates);
              }
            }
            return;
          },
        }),
      ],
      properties: {
        name: layerName,
        zIndex: zIndex
      },
    }),
  );
  const selectInteractionRef = React.useRef<Select>();
  const modifyInteractionRef = React.useRef<Modify>();
  const translateInteractionRef = React.useRef<Translate>();

  useEffect(() => {
    vectorLayerRef.current.setVisible(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if(zIndex) {

      vectorLayerRef.current.setZIndex(zIndex)
    }
  },[zIndex])

  useEffect(() => {
    map?.addLayer(vectorLayerRef.current);
    return () => {
      if (vectorLayerRef.current) {
        map?.removeLayer(vectorLayerRef.current);
      }
    };
  }, [map,zIndex]);

  useEffect(() => {
    if (editable && vectorLayerRef.current) {
      selectInteractionRef.current = new Select({
        layers: [vectorLayerRef.current],
      });

      // selectInteractionRef.current.on('select', (e: any) => {
      //   console.log(e);
      // });
      modifyInteractionRef.current = new Modify({
        features: selectInteractionRef.current?.getFeatures(),
      });
      translateInteractionRef.current = new Translate({
        features: selectInteractionRef.current?.getFeatures(),
      });
      map?.addInteraction(selectInteractionRef.current);
      map?.addInteraction(modifyInteractionRef.current);
      map?.addInteraction(translateInteractionRef.current);
    }

    return () => {
      if (
        editable &&
        selectInteractionRef.current &&
        modifyInteractionRef.current &&
        translateInteractionRef.current
      ) {
        map?.removeInteraction(selectInteractionRef.current);
        map?.removeInteraction(modifyInteractionRef.current);
        map?.removeInteraction(translateInteractionRef.current);
      }
    };
  }, [editable, map]);

  return (
    <VectorLayerContext.Provider value={vectorLayerRef.current}>
      {children}
    </VectorLayerContext.Provider>
  );
  // return <GeoJson vectorLayer={vectorLayer} geojson={layer.geojson} />;
}

export default MVectorLayer;
