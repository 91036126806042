// #region React & Hooks
// #endregion
// #region API
import { useGetMosaicsQuery } from 'api/Vistaguay/MosaicAPI';
import { useDeleteTaskMutation } from 'api/Vistaguay/TaskAPI';
// #endregion
// #region Components
import Button from 'components/Button/Button';
// #endregion
// #region Models
import { Campaign } from 'models/Campaign';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';

// #endregion
// #region Stylesheet
import style from '../Contextual.module.scss';
// #endregion

export default function TrashOption({ taskId }: { taskId: string }) {
  // #region HOOKS
  const { t } = useTranslation();
  // #endregion

  // #region Params
  const { projectId, lotId } = useParams<{ projectId: string; lotId: string }>();
  // #endregion

  // #region Redux States
  const selectedCampaign: Campaign | null = useAppSelector((state) => state.campaign.selectedCampaign);
  // #endregion

  if(selectedCampaign == null  ) return <></>

  // #region API mutations
  const [deleteTask, { isLoading }] = useDeleteTaskMutation();

  const {refetch: refetchMosaic} = useGetMosaicsQuery({
    campaignId: String(selectedCampaign?.id),
    lotId: String(lotId),
  },{skip: selectedCampaign == undefined});
  // #endregion

  // #region Handlers
  const handleDelete = () => {
    deleteTask({ taskId: taskId }).then((response) => {
      refetchMosaic();
    });
  }
  // #endregion

  return (
    <div
      style={{ textAlign: 'center' }}
      className={style.body}
    >
      <h4 style={{ fontSize: '14px', fontWeight: '600' }}>
        {t('delete-task-title')}
      </h4>
      <p style={{ fontSize: '12px' }}>
        {t('delete-task-text')}
      </p>

      <div className={style.contextualControls}>
        <Button
          onClick={() => null}
          text='Volver'
          variant={'NEUTRAL-GRAY'}
          style={{ fontSize: '12px' }}
          height='30px'
        />
        <Button
          onClick={() => handleDelete()}
          text={t('errase')}
          variant={'ERROR'}
          style={{ fontSize: '12px' }}
          height='30px'
        />
      </div>
              
    </div>
  );
}
