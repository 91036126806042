import { ReactComponent as Edit } from 'assets/icons/edit-ico.svg';
import { ReactComponent as Download } from 'assets/icons/ico-download.svg';
import { ReactComponent as Delete } from 'assets/icons/trashIcon.svg';
import useToggle from 'hooks/useToggle';

import styles from './Menu.module.scss';
import DefaultOptions from './MenuModalOptions/DefaultOptions';
import DeleteLayer from './MenuModalOptions/DeleteLayer';
import EditLayer from './MenuModalOptions/EditLayer';

export default function ModalMenuOptionsAnalitycs({ isActive, toggle }: { isActive: boolean, toggle: any }) {

    const { isActive: isActivedefaultOptions, toggle: toggledefaultOptions } = useToggle();
    const shownDefaultOptions = () => {
      if (isActiveDeleteLayer == true) { toggleDeleteLayer(); };
      if (isActiveEditLayer == true) { toggleEditLayer(); };
      toggledefaultOptions();
    };
    const { isActive: isActiveDeleteLayer, toggle: toggleDeleteLayer } = useToggle();
    const shownDeleteLayer = () => {
      if (isActiveEditLayer == true) { toggleEditLayer(); };
      if (isActivedefaultOptions == false) { toggledefaultOptions(); };
      toggleDeleteLayer();
    };
    const { isActive: isActiveEditLayer, toggle: toggleEditLayer } = useToggle();
    const shownEditLayer = () => {
      if (isActivedefaultOptions == false) { toggledefaultOptions(); };
      if (isActiveDeleteLayer == true) { toggleDeleteLayer(); };
      toggleEditLayer();
    };
    return (
      <div className={styles.ModalPosition} style={{ display: isActive ? 'flex' : 'none' }}>
        <div className={styles.ModalContainer}>
          <div className={styles.containerButtons}>
            {
              !isActivedefaultOptions && <DefaultOptions isActivedefaultOptions={isActivedefaultOptions} />
            }
            {
              isActiveDeleteLayer && <DeleteLayer isActiveDeleteLayer={isActiveDeleteLayer} />
            }
            {
              isActiveEditLayer && <EditLayer isActiveEditLayer={isActiveEditLayer} />
            }
            <div className={styles.footerModal}>
              <button
                className={styles.footerButtonModal}
                onClick={shownDefaultOptions}
                style={{ background: !isActivedefaultOptions ? 'white' : 'none', borderRadius: !isActivedefaultOptions ? '5px' : '0' }}
              >
                <Download className={styles.iconModal} />
              </button>
              <button
                className={styles.footerButtonModal}
                onClick={shownDeleteLayer}
                style={{ background: isActiveDeleteLayer ? 'white' : 'none', borderRadius: isActiveDeleteLayer ? '5px' : '0' }}
              >
                <Delete className={styles.iconModal} />
              </button>
              <button
                className={styles.footerButtonModal}
                onClick={shownEditLayer}
                style={{ background: isActiveEditLayer ? 'white' : 'none', borderRadius: isActiveEditLayer ? '5px' : '0' }}
              >
                <Edit className={styles.iconModalEdit} />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  
  }