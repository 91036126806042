import { useParams } from 'react-router-dom';

import style from './Pay.module.scss'

export default function Pay () {
    const {status} = useParams()
    return (
        <>
                <div style={{marginTop:'30px',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    {
                        status == 'success' &&
                        <>
                            <h2 style={{textAlign:'center',color: style.verdeVistaguay}}>El pago se completo con exito</h2>
                            <p style={{marginTop:'20px'}}>se acreditara su saldo en la brevedad</p>
                        </>
                    }
                    {
                        status == 'pending' &&
                        <>
                            <h2 style={{textAlign:'center',color: style.verdeOscuro}}>El pago esta pendiente</h2>
                            <p style={{marginTop:'20px',textAlign:'center'}}>se acreditara su saldo cuando se confirme la transacion, en caso de rechazarse recibira una notificacion</p>
                        </>
                    }
                    {
                        status == 'error' &&
                        <>
                            <h2 style={{textAlign:'center',color: style.rojo}}>El pago fue rechazado</h2>
                            <p style={{marginTop:'20px',textAlign:'center'}}>Intenta de nuevo mas tarde, disculpe el inconveniente</p>
                        </>
                    }
                </div>

        </>
    )
}
