export function validateGeoJSONasPolygonOrMultipolygon(json: any): boolean {
  if (json.type == 'FeatureCollection') {
    const features = json.features as any[];
    return features.reduce((prev, curr) => {
      return prev && validateGeoJSONasPolygonOrMultipolygon(curr);
    }, true);
  }
  if (json.type == 'Feature' && json.geometry) {
    const { type, coordinates } = json.geometry;

    if (type === 'Polygon') {
      if (!Array.isArray(coordinates) || !coordinates.length) {
        return false;
      }
      return coordinates.every((ring: any) => Array.isArray(ring) && ring.length >= 4);
    }

    if (type === 'MultiPolygon') {
      if (!Array.isArray(coordinates) || !coordinates.length) {
        return false;
      }
      return coordinates.every(
        (polygon: any) =>
          Array.isArray(polygon) &&
          polygon.every((ring: any) => Array.isArray(ring) && ring.length >= 4),
      );
    }
  }

  return false;
}
