import { GoogleLogin } from '@react-oauth/google';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ReactComponent as EyeIcon} from 'assets/icons/visibility.svg'
import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';
import EmailAutosuggest from 'components/Inputs/Email/EmailInput';
import { Input } from 'components/Inputs/Input';
import CentralArea from 'layout/Centralarea/Centralarea';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { checkPassRule, checkValidEmail } from 'services/helpers';
import { setLoginData } from 'state/slices/dataSlice';

import { useGoogleLoginMutation, useLoginMutation,VistaguayApi } from '../../api/Vistaguay/VistaguayAPI';
import spinner from '../../assets/img/vistaguay-spinner.gif';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import style from './Login.module.scss';


export function LoginSidebar() {
  const [login, { data, isLoading, error, isSuccess }] = useLoginMutation();
  const [googleLogin, { data: dataGoogle, isLoading: isLoadingGoogle, error: hasGoogleError, isSuccess: isSuccesGoogle }] = useGoogleLoginMutation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { t } = useTranslation();
  const userLogged = useAppSelector((state) => state.data.refreshToken) != null;
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passVisibility, setPassVisibility] = useState(false)
  const [clickSubmit, setClickSubmit] = useState(0)
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrorList, setFormErrorList] = useState<Array<{field: string}>>([]);
  
  const isEmailValid = checkValidEmail({email: username});

  const {
    min, 
    hasSpecialCharacter, 
    hasUppercase
  } = checkPassRule({
    str:password, 
    minLength: 8, 
    specialCharacter: true, 
    uppercase: true
  })

  useEffect(() => {
    if (data) {
      const toDispatch = {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      };
      dispatch(VistaguayApi.util.resetApiState())
      dispatch(setLoginData(toDispatch));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (userLogged) {
      navigate('/campaign/search')
    } else {
      return
    }
  }, [isSuccesGoogle, userLogged, navigate])

  useEffect(() => {
    if (dataGoogle) {
      const toDispatch = {
        accessToken: dataGoogle.accessToken,
        refreshToken: dataGoogle.refreshToken,
      };
      dispatch(VistaguayApi.util.resetApiState())
      dispatch(setLoginData(toDispatch));
    }
  }, [dataGoogle, dispatch])

  useEffect(() => {
    const errors = [];
   
    if(clickSubmit > 0){
      if(!isEmailValid) {
        errors.push({field: 'email'})
      } 
   
      setFormErrorList(errors);
    }
    setIsFormValid(errors.length == 0)
  }, [isEmailValid, clickSubmit])
  

  const handleChange = (newValue: string) => {
    setUsername(newValue)
  }

  const handleSubmitForm = () => {
    setClickSubmit((prevState) => prevState + 1);

    if(isFormValid && isEmailValid) {
      setClickSubmit(0)
      login({ email: username, password: password })
    }
  }

  return (
    <div className={style.box}>
      <Sidebar className={style.LoginSidebar}>
        <SidebarBody sx={{ padding: '20px' }}>
          <div className={style.container}>
            <h2>Ingresar</h2>

            <form className={style.form} onSubmit={(e) => e.preventDefault()}>
              <div className={style.formGroup}>
                <span>{t('email')}</span>
       
                <EmailAutosuggest handleChange={handleChange}/>

                { (username.length > 0 || clickSubmit > 0) && formErrorList.find((err) => err.field === 'email') && (
                  <Alert type='error' text={t('invalid-email')}/>
                )}
              </div>
              
              <div className={style.formGroup}>
                <span>{t('password')}</span>
                <Input 
                  placeholder='' 
                  type={!passVisibility ? 'password' : 'text'} 
                  value={password} onChange={(e) => setPassword(e.target.value)} name='password' 
                  inputStyle={style.inputStyle}
                >
                  <div 
                    style={{marginRight:'10px', cursor: 'pointer'}} 
                    onClick={() => setPassVisibility((prevState: boolean) => !prevState)}
                  >
                    <EyeIcon style={{stroke: passVisibility ? style.verdeVistaguay : style.light, width:'30px'}} />
                  </div>
                </Input>
              </div>
          
              {error && (error as FetchBaseQueryError).status === 401 && (
                <Alert type='error' text={t('invalid-credentials')}/>
              )}

              {error && (error as FetchBaseQueryError).status === 500 && (
                <Alert type='error' text={t('something-went-wrong')}/>
              )}

              <div style={{marginBottom: '5px', marginTop: '20px'}}>
                <Button
                  variant={'NEUTRAL'}
                  text={t('acceder')}
                  onClick={() => handleSubmitForm()}
                />
              </div>

              <a 
                style={{textDecoration: 'none', color: 'white'}}
                href='/forgotPassword'
              >
                {t('forgot-password')}
              </a>

              <p style={{marginTop: '20px'}}>{t('or-continue-with')}:</p>
            
              <GoogleLogin
                onSuccess={async (credentialsResponse) => {
                  if (credentialsResponse.credential) {
                    googleLogin(credentialsResponse.credential)
                  }
                }}
                onError={() => console.log('Login failed')}
                text='signin'
              />
            </form>
          </div>
        </SidebarBody>

        <SidebarFooter 
          sx={{ 
            width: '100%', 
            background: '#47c2785e', 
            color: style.light, 
            display: 'flex', 
            justifyContent: 'center', 
            padding: '30px 30px', 
          }}
        >
          <p onClick={() => navigate('/register')} 
          style={{ fontSize: '16px', fontWeight: 600, cursor: 'pointer' }}>{t('create-account')}</p>
        </SidebarFooter> 
      </Sidebar>
      
      { (isLoading || isLoadingGoogle) && (
        <div className={style.spinnerContainer}>
          <img src={spinner} style={{width: '100px'}} />
        </div>
      )}
    </div>
  );
}

export const LoginCentralArea = () => {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <CentralArea sx={{height: '100%'}}>
        <LoginSidebar />

        <div className={style.imageContainer} >
          <div className={style.imageText}>
            <p>{t('vistaguay-business')}</p>

            <h1>{t('comenza-a-tomar-decisiones-agtech')}</h1>
          </div>
        </div>
      </CentralArea >
    </div>
  );
};
