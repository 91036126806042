import { Intent, OverlayToaster, Position, ToastProps } from '@blueprintjs/core';
// ToastProps

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = OverlayToaster.create({
  className: 'recipe-toaster',
  position: Position.TOP,
  autoFocus: false,
  usePortal: true,
  //   intent: Intent.DANGER,
});

export const ErrorToaster = (msg: string) => {
  AppToaster.show({
    message: msg,
    intent: Intent.DANGER,
  });
};

export const MessageToeaster = (msg: string) => {
  AppToaster.show({
    message: msg,
    intent: Intent.PRIMARY,
  });
};

export const SuccesToaster = (msg: string) => {
  AppToaster.show({
    message: msg,
    intent: Intent.SUCCESS,
  });
};

// AppToaster.getToasts().forEach((toast) => (toast.message = 'lalala'));
