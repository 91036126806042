import { useGetMissionQuery } from 'api/Vistaguay/VolareAPI';
import Button from 'components/Button/Button'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IAllTasks } from 'state/slices/taskSlice';


export default function EditOption ({
    task
}: {
    task: IAllTasks
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { projectId, lotId } = useParams<{ projectId: string, lotId: string }>();

    const {
        data: mission,
        isLoading: isCurrentMissionLoading,
        error: isCurrentMissionError,
    } = useGetMissionQuery(+task.taskId!, {
        skip: !task?.taskId,
    });

    const handleEditLot = () => {
        let path = `/project/${projectId}/lots/${lotId}`
        if(mission && mission?.type == 'SCOUTING'){
            path = `/projects/${projectId}/lots/${lotId}/volare/scouting/${task.taskId}`
        } else {
            path = `/projects/${projectId}/lots/${lotId}/volare/mapping/${task.taskId}`
        }

        navigate(path);
    }

    return (
        <div
            style={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            margin: '15px 0px'
            }}
        >
            <h4 style={{ fontSize: '14px', fontWeight: '600' }}>
                {t('edit-mode')}
            </h4>

            <Button
                onClick={() => handleEditLot()}
                text={t('start')}
                variant={'NEUTRAL'}
                style={{ fontSize: '12px' }}
                height='30px'
                width='100px'
                // disabled={isLoading}
                // isLoading={isLoading}
            />
        </div>
    )
}