import { MosaicTaskView } from 'state/slices/mosaicSlice';

import HistogramOptionStepOne from './HistogramOption';

export default function Histogram ({mosaicView}: {mosaicView: MosaicTaskView}) {
    return (
        <>
            <HistogramOptionStepOne mosaicView={mosaicView} />
        </>
    )
}