import { useUploadImgMutation } from 'api/Vistaguay/VistaguayAPI';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico-question.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-icon.svg';
import { Industries } from 'components/DataLists/DataLists';
import { Input } from 'components/Inputs/Input';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { IProfileForm } from '../ProfileModal';
import styles from '../ProfileModal.module.scss';

const customStyles = {
    input: (base: any) => ({
        ...base,
        padding: '7px', // Padding for the input
        '& input': {
          font: 'inherit', // Inherit font styles from parent
        },
    }),
    menu: (base: any) => ({
      ...base,
      maxHeight: '150px', // Altura máxima del menú
      overflowY: 'auto', // Habilitar scroll vertical
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: '150px', 
    }),
};

const filterOption = (option: any, inputValue: string) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
};
  

export default function CompanyData ({
    profileImg,
    form,
    setForm,
}:{
    profileImg: string,
    form: Partial<IProfileForm>,
    setForm: (form: Partial<IProfileForm>) => void,
}) {
    const { t } = useTranslation();

    const inputFile = useRef<HTMLInputElement | null>(null);
    const [imageUrl, setImageUrl] = useState(profileImg);
    const [file, setFile] = useState<File | null>(null)

    const [
        uploadImg, 
        { 
          isSuccess: uploadSucces, 
          isLoading: uploadLoading 
        }
    ] = useUploadImgMutation();

    const handleUploadImage = (event: any) => {
        console.log('logo upload event: ', event)
        const file = event.target.files[0];
        if (file) {
            setFile(file)
            const url = URL.createObjectURL(file);
            setImageUrl(url);
        }
    }

    const handleSaveProfileImage = () => {
        if(file) {
            const newImg = new FormData();
            newImg.append('file', file);
            uploadImg(newImg);
        }
    }

    return (
        <div className={styles.section}>
            <div className={styles.inputGroup}>
                <div className={styles.label}>
                    <span>{t('logo')}</span>
                    {/* <div className={styles.editBtn} >
                        <InfoIcon height={20} fill='gray'/>
                    </div> */}

                    {/* { file && (
                        <div className={styles.editBtn} >
                            <SaveIcon height={20} fill='gray' onClick={() => handleSaveProfileImage()}/>
                        </div>
                    )} */}
                </div>
                
                <div style={{marginTop: '10px'}}>
                    {/* { imageUrl ? (
                        <img 
                            src={imageUrl} 
                            width={60} 
                            height={60} 
                            style={{borderRadius: '50%'}}
                            onClick={() => inputFile.current?.click()}
                        />
                    ) : (
                        <UserIcon 
                            width={60} 
                            fill={styles.dark} 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => inputFile.current?.click()}
                        />
                    )} */}

                    <UserIcon 
                        width={60} 
                        fill={styles.dark} 
                        style={{ cursor: 'pointer' }} 
                        // onClick={() => inputFile.current?.click()}
                    />
                </div>
            </div>

            {/* COMPANY NAME */}
            <div className={styles.inputGroup}>
                <div className={styles.label}>
                    <span>{t('company-name')}</span>
                    {/* <div className={styles.editBtn} >
                        <EditIcon height={20} fill='gray'/>
                    </div> */}
                </div>
                
                <Input 
                    placeholder=''
                    value={form.razonSocial}
                    onChange={(e) => setForm({ ...form, razonSocial: e.target.value})}
                />
            </div>

            <div className={styles.inputGroup}>
                <div className={styles.label}>
                    <span>{t('industry')}</span>
                    {/* <div className={styles.editBtn} >
                        <EditIcon height={20} fill='gray'/>
                    </div> */}
                </div>
                
                <Select 
                    value={Industries.filter((opt) => opt.value === form.industry)}
                    styles={customStyles}
                    filterOption={filterOption}
                    options={Industries}
                    onChange={(selectedOption) => { 
                        if(selectedOption?.value){
                            setForm({...form, industry: selectedOption?.value})
                        }
                    }}
                />
            </div>

            {/* FILE SELECTOR */}
            <input
                type='file'
                id='file'
                name='files'
                ref={inputFile}
                style={{ display: 'none' }}
                accept='.png,.jpeg,.jpg'
                onChange={(e) => handleUploadImage(e)}
            />
        </div>
    )
}