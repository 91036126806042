import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import { InputSelect } from 'components/Inputs/Input';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'state/hooks';
import { setSeederBrandId, setSeederMeterId, setSeederModelId, setSeederType } from 'state/slices/pqFormSlice';

import style from '../../AddTask.module.scss';


export default function StepSeeder() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: extraData } = useGetExtraDataQuery();
  
  const { pqForm } = useAppSelector((state) => state.pqFormState);

  return (
    <div className={style.bodyContent}>
      {/* SEEDER METER BRAND */}
      <div className={style.bodyContentRow}>
        <label>
          {t('seeder-meter-brand')}
          <span className={style.smallLegend}>({t('opcional')})</span>
        </label>
        <InputSelect
          id='seeder-meter-brand'
          placeholder={t('seeder-meter-brand')}
          value={pqForm.seederMeterId || ''}
          options={
            extraData?.seederMeterBrands
              ?.map((seederMeterBrand) => ({
                value: seederMeterBrand.id,
                name: seederMeterBrand.name,
              }))
              .sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              }) || []
          }
          onChange={(e) => { dispatch(setSeederMeterId(+e.target.value)) }}
        >
          <option value={''} disabled>{t('select-placeholder')}</option>
        </InputSelect>
      </div>

      {/* SEEDERS */}
      <div className={style.bodyContentRow}>
        <label>
          {t('seeder-type')}
          <span className={style.smallLegend}>({t('opcional')})</span>
        </label>
        <InputSelect
          id='seeder-type'
          placeholder={t('seeder-type')}
          options={
            extraData?.seeders
            ?.map((seeder) => ({ value: seeder.id, name: seeder.name }))
              .sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              }) || []
          }
          value={pqForm?.seederId || ''}
          onChange={(e) => {dispatch(setSeederType(+e.target.value))}}
        >
          <option value={''} disabled>{t('select-placeholder')}</option>
        </InputSelect>
      </div>

      {/* SEEDER BRAND */}
      <div className={style.bodyContentRow}>
        <label>
          {t('seeder-brand')}
          <span className={style.smallLegend}>({t('opcional')})</span>
        </label>
        <InputSelect
          placeholder={t('seeder-brand')}
          id='seeder-brand'
          value={pqForm?.seederBrandId || ''}
          options={
            extraData?.seederBrands
              ?.map((seederBrand) => ({
                value: seederBrand.id,
                name: seederBrand.name,
              }))
              .sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              }) || []
          }
          onChange={(e) => { dispatch(setSeederBrandId(+e.target.value)) }}
        >
          <option value={''} disabled>{t('select-placeholder')}</option>
        </InputSelect>
      </div>

      {/* SEEDER MODEL */}
      <div className={style.bodyContentRow}>
        <label>
          {t('seeder-model')}
          <span className={style.smallLegend}>({t('opcional')})</span>
        </label>
        <InputSelect
          placeholder={t('seeder-model')}
          id='seeder-model'
          value={pqForm?.seederModelId || ''}
          options={
            extraData?.seederBrands
              .find(
                (seederBrand) =>
                  seederBrand.id ===
                  (pqForm.seederBrandId ? Number(pqForm.seederBrandId) : -1),
              )
              ?.seederModels.map((seederModel) => ({
                value: seederModel.id,
                name: seederModel.name,
              }))
              .sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              }) || []
          }
          onChange={(e) => { dispatch(setSeederModelId(+e.target.value))}}
        >
          <option value={''} disabled>{t('select-placeholder')}</option>
        </InputSelect>
      </div>
    </div>
  );
}
