import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetCampaignProjectsQuery,
  useGetCampaignProjectsWithLotQuery,
} from 'api/Vistaguay/CampaignAPI';
import { ReactComponent as ArrowPrev } from 'assets/icons/arrow-prev.svg';
import { ReactComponent as Foldervg } from 'assets/icons/foldervg.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/ico-folder-white.svg';
import Button from 'components/Button/Button';
import { PaginatorFooter } from 'components/PaginatorFooter/PaginatorFooter';
import { Skeleton } from 'components/Skeleton/Skeleton';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import SidebarHeader from 'layout/Sidebar/SidebarHeader';
import { SubHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import Project from 'models/Project';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'state/hooks';

import { ReactComponent as CalendarIcon } from '../../assets/icons/ico-calendar.svg';
import styles from './ProjectList.module.scss';
import ProjectListItem from './ProjectListItem';

const PAGE_SIZE = 7;

function ProjectListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );

  const {
    data: projectList,
    isLoading,
    isSuccess,
  } = useGetCampaignProjectsQuery(selectedCampaign?.id ?? skipToken);

  const {
    data: projectsWithLotList,
  } = useGetCampaignProjectsWithLotQuery(selectedCampaign?.id ?? skipToken);

  const list = useMemo(() => {
    if (projectList) {
      return projectList.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    }
  }, [projectList, page]);


  function handleNextPage() {
    if (projectList && page < projectList.length / PAGE_SIZE) {
      setPage(page + 1);
    }
  }

  function handlePrevPage() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  return (
    <>
      { selectedCampaign?.id && (
        <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
          <SidebarHeader/>
          <>
            <SubHeader sx={{ padding: '0px' }} noBack={true}>
              <div className={styles.header}>
                <div className={styles.headerContent}>
                  <CalendarIcon height={25}/>

                  <div className={styles.headerText}>
                    <div className={styles.titleBox}>
                      <h2 className={styles.title}>{selectedCampaign?.name?.split('-')[0] || ''}</h2>
                      <h2 className={styles.title} style={{marginLeft: '5px',fontWeight: '400'}}>-{selectedCampaign?.name?.split('-')[1] || ''}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </SubHeader>

            <SidebarBody>
              <div className={styles.body}>
                {projectList?.length != 0 ? (
                  <ul className={styles.projectList}>
                    { isLoading ?  (
                      <>
                        <Skeleton sx={{width:'100%',height:'50px',marginTop:'5px',borderRadius:'5px'}}/>
                        <Skeleton sx={{width:'100%',height:'50px',marginTop:'5px',borderRadius:'5px'}}/>
                        <Skeleton sx={{width:'100%',height:'50px',marginTop:'5px',borderRadius:'5px'}}/>
                        <Skeleton sx={{width:'100%',height:'50px',marginTop:'5px',borderRadius:'5px'}}/>
                        <Skeleton sx={{width:'100%',height:'50px',marginTop:'5px',borderRadius:'5px'}}/>
                        <Skeleton sx={{width:'100%',height:'50px',marginTop:'5px',borderRadius:'5px'}}/>
                        <Skeleton sx={{width:'100%',height:'50px',marginTop:'5px',borderRadius:'5px'}}/>
                        <Skeleton sx={{width:'100%',height:'50px',marginTop:'5px',borderRadius:'5px'}}/>
                      </>
                    ) : (
                      list?.map((project: Project, index) => {
                        return <ProjectListItem 
                          key={index} 
                          project={project} 
                          lotsAmount={projectsWithLotList?.length}
                          campaignId={selectedCampaign.id}
                        />
                          
                      })
                    )}
                  
                    
                  </ul>
                ) : (
                  <div className={styles.emptyProjects}>
                    <Foldervg
                      style={{
                        height: '65px',
                        marginLeft: '10px',
                        padding: '0px',
                        position: 'relative',
                        top: '-5px',
                      }}
                    />

                    <h1>Comencemos!</h1>
                    <p>
                      {t(
                        'para-tener-todo-bien-organizado-te-recomendamos-crear-un-proyecto-y-luego-agregarle-todos-los-lotes-que-necesites',
                      )}
                    </p>
                    <Button
                      // style={{ width: 'auto', marginTop: '20px' }}
                      text={t('crear-proyecto')}
                      variant='NEUTRAL'
                      color='#f2f2f2'
                      onClick={() => navigate('/projects/new')}
                    />
                  </div>
                )}
              </div>
            </SidebarBody>

            <SidebarFooter >
              <div className={styles.gridContainer}>
                <Button
                  text={t('back')}
                  variant='INFO'
                  onClick={() => navigate('/campaign/search')}
                  iconBack
                />
                <Button
                  text={t('add')}
                  variant={'INFO'}
                  onClick={() => navigate('/projects/new')}
                  iconRigth={<FolderIcon
                    style={{
                      fill: 'white',
                      minHeight: '25px',
                      maxWidth: '25px',
                    }}
                  />}
                />
              </div> 
            
            </SidebarFooter>
          </>
        </Sidebar>
      )}
      
    </>
  );
}

export default ProjectListPage;
