import { Popover2 } from '@blueprintjs/popover2';
import { ReactComponent as Dots } from 'assets/icons/ico-dots.svg'
import { ReactComponent as LayersIcon } from 'assets/icons/ico-layers.svg';
import { ReactComponent as PolygonIcon } from 'assets/icons/polygonsquare.svg'
import { motion } from 'framer-motion';
import Lot from 'models/Lot';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'state/hooks';
import { setHoveredLotId } from 'state/slices/uiSlice';

import LotListContextual from './LotContextual/LotListContextual/LotListContextual';
import style from './LotList.module.scss';
interface LotItemProps {
  lot: Lot;
  projectId: string;
}

function LotItem({ lot, projectId }: LotItemProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const navigateToLot = () => {
    navigate(`/projects/${projectId}/lots/${lot.id}`);
  };

  return (
    <div
      className={style.lotItem}
      // onMouseOver={() => {
      //   if(!openMenu){
      //     dispatch(setHoveredLotId(lot.id))
      //   }
      // }}
      // onMouseOut={() =>{ 
      //   if(!openMenu){
      //     dispatch(setHoveredLotId(null))
      //   }
      // }}
    >
 
      <PolygonIcon  style={{ fill: style.c3 }} className={style.lotIcon} onClick={navigateToLot} />
      <p onClick={navigateToLot}> 
        {lot.name}
      </p>
  
      <Popover2
        position='right'
        interactionKind='click'
        usePortal={true}
        className={style.popover}
        content={<LotListContextual lotId={lot.id} projectId={+projectId} lot={lot}/>}
      >
        <div>
          <Dots className={style.layersIcon} />
        </div>
      </Popover2>
    </div>
  );
}

export default LotItem;
