import { Prescription } from 'models/Prescription';
import { Task } from 'models/Task';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'state/hooks';
import { ReactComponent as Line } from '../../../assets/icons/line.svg';

import style from './CountTaskReport.module.scss';

interface IPlotDataTablePorps {
  type: string;
  countMetadata: any;
  unit: string;
  prescription: Prescription;
  task: Task;
}

interface CountMetadataAmbiente {
  ambiente: string;
  densidadObjetivo: number;
  densidadMedia:number; 
  densidadMediana:number; 
  densidadDesvio: number;
  densidadCv: number;
  gapObjetivo: number; 
  gapMedia: number;
  gapMediana: number; 
  gapDesvio: number;
  gapCv: number;
  coberturaMedia: number; // Agregar propiedades de cobertura
  coberturaMediana: number;
  coberturaDesvio: number;
  coberturaCv: number;
}

export interface MetadataAmbiente {
  ambiente: string;
  densidadObjetivo: number;
  densidadMedia: number;
  densidadMediana: number;
  densidadDesvio: number;
  densidadCv: number;
  gapObjetivo: number;
  gapMedia: number;
  gapMediana: number;
  gapDesvio: number;
  gapCv: number;
  coberturaMedia: number; // Agregar propiedades de cobertura
  coberturaMediana: number;
  coberturaDesvio: number;
  coberturaCv: number;
}
export const PlotDataTable = ({
  type,
  countMetadata,
  prescription,
  unit,
  task,
}: IPlotDataTablePorps) => {
  const { t } = useTranslation();
  const prescriptionTheme = useAppSelector((state) => {
    return state.ui.prescriptionTheme;
  });

  
  console.log('unit', unit);


  const values = countMetadata?.result.map((countMetadataAmbiente: MetadataAmbiente) => {
    return getTableData(countMetadataAmbiente, unit);
  }) || [{ amb: 0, obj: 0, prom: 0, med: 0, desv: 0, coef: 0 }];

  return (
    <>
      <div className={style.tableCountData}>
        <div className={style.tableColumn}   style={{ borderTopLeftRadius: '5px'}}>
          <div className={style.tft}>{t('goal').toUpperCase()} <Line/></div>
          <div className={style.tft}>{t('mean').toUpperCase()} <Line stroke='#74D486'/></div>
          <div className={style.tft}>{t('median').toUpperCase()}<Line stroke='#FF5050'/></div>
          <div className={style.tft}>{t('standard_deviation_summarized')}</div>
          <div className={style.tft}>{t('coefficient_of_variation_summarized')}</div>
          {unit === 'Densidad'? 
            <div className={style.tft} >{t('IMG / AMB')}</div>
            :  null
          }
          {
            unit === 'Espaciamiento'? 
            <div className={style.tft}>{t('SINGULACIÓN %')}</div>
            :  null
          }
        </div>
        <div style={{ display: 'flex', overflowX: 'auto' }}>
          {values &&
            values.map((column: any, idx: number) => {
              const borderColor = getPrescriptionAmbienteColor(
                prescriptionTheme,
                idx,
                task,
                prescription,
              );
  
              return (
                <div
                  key={`${column.amb}_${column.title}_${idx}`}
                  className={style.tableColumn}
                  style={{ borderTopColor: borderColor }}
                >
                  <div className={style.tableField}>{formatFieldOutput(column.obj)}</div>
                  <div className={style.tableField}>{formatFieldOutput(column.prom)}</div>
                  <div className={style.tableField}>{formatFieldOutput(column.med)}</div>
                  <div className={style.tableField}>{formatFieldOutput(column.desv)}</div>
                  <div className={style.tableField}>{formatFieldOutput(column.coef)}</div>
                  {unit === 'Densidad'? 
                    <div className={style.tableField}>{formatFieldOutput(column.imgAmb)}</div>
                    :   null
                  }
                  {unit === 'Espaciamiento'? 
                    <div className={style.tableField}>{formatFieldOutput(column.sing)}</div>
                    :   null
                  }
                </div>
              );
              
            })}
        </div>
      </div>
    </>
  );
  
};

const formatFieldOutput = (value: string|number) => {
  if (value === '0') {
    return '-';
  }
  if (value === 0) {
    return '-';
  }
  return value;
};

const getVacioTableData = () => {
  return {
    amb: 0,
    obj: 0,
    prom: 0,
    med: 0,
    desv: 0,
    coef: 0,
    title: 'X',
  };
};

const getDensidadesTableData = (metadataAmbiente: MetadataAmbiente) => {
  return {
    amb: metadataAmbiente.ambiente,

    obj: typeof metadataAmbiente.densidadObjetivo === 'string'  && 
    ((metadataAmbiente.densidadObjetivo)/1000).toFixed(1),

    prom: typeof metadataAmbiente.densidadMedia === 'string' &&
     ((metadataAmbiente.densidadMedia)/1000).toFixed(1),

    med: typeof metadataAmbiente.densidadMediana === 'string' &&
      ((metadataAmbiente.densidadMediana)/1000).toFixed(1),
        
    desv: typeof metadataAmbiente.densidadDesvio === 'string' &&
      ((metadataAmbiente.densidadDesvio)/1000).toFixed(1),

    coef: metadataAmbiente.densidadCv,
    imgAmb: '0',
    title: 'densidad',
  };
}

const getEsparcimientoTableData = (metadataAmbiente: MetadataAmbiente) => {
  return {
    amb: metadataAmbiente.ambiente,
    obj: metadataAmbiente.gapObjetivo,
    prom: metadataAmbiente.gapMedia,
    med: metadataAmbiente.gapMediana,
    desv: metadataAmbiente.gapDesvio,
    coef: metadataAmbiente.gapCv,
    sing: '0',
    title: 'esparcimiento',
  };
};

const getCoberturaTableData = (metadataAmbiente: MetadataAmbiente) => {
  return {
    amb: metadataAmbiente.ambiente,
    obj: 0,
    prom: metadataAmbiente.coberturaMedia,
    med: metadataAmbiente.coberturaMediana,
    desv: metadataAmbiente.coberturaDesvio,
    coef: metadataAmbiente.coberturaCv,
    title: 'cobertura',
  };
};




const getTableData = (metadataAmbiente: MetadataAmbiente, unit: string) => {  
  switch (unit.toLowerCase()) {

    case 'densidad':
      return getDensidadesTableData(metadataAmbiente);
    case 'espaciamiento':
      return getEsparcimientoTableData(metadataAmbiente);
    case 'cobertura':
      return getCoberturaTableData(metadataAmbiente);
    default:
      return getVacioTableData();
  }
};

function getBorderColor(
  prescriptionTheme: string[],
  idx: number,
  taskData: any,
  prescription?: any,
) {
  let borderColor = prescriptionTheme[idx];

  // if(taskData.tipoProceso == 1 ? 'Fija' : 'Variable';)
  if (taskData.tipoProceso == 1) {
    borderColor = '#003A3C';
  } else {
    const features = prescription?.prescription.features;
    if (features && features[idx] && features[idx].properties && features[idx].properties.fill) {
      borderColor = features[idx].properties.fill;
    } else if (
      features &&
      features[idx] &&
      features[idx].properties &&
      features[idx].properties.color
    ) {
      borderColor = features[idx].properties.color;
    }
  }

  return borderColor;
}

export function getPrescriptionAmbienteColor(
  prescriptionTheme: string[],
  idx: number,
  taskData: any,
  prescription?: any,
) {
  const borderColor = getBorderColor(prescriptionTheme, idx, taskData, prescription);

  if (borderColor.includes('rgba')) {
    return `${borderColor.slice(0, -6)}, 1)`;
  } else {
    return borderColor;
  }
}



export function getPrescriptionAmbienteColors(
  prescriptionTheme: string[],
  idx: number,
  taskData: any,
  prescription?: any,
) {
  let borderColor = getBorderColor(prescriptionTheme, idx, taskData, prescription);
  borderColor = borderColor.replace(/0.6/g, '0.4');
  return borderColor;
}
