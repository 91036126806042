import {createSlice,PayloadAction} from '@reduxjs/toolkit'
import { Polygon } from '@turf/turf';
import { GeoJSONFeature } from 'models/GeoJSON';
export interface AnalyticsState {
    layers: any[],
    visibleLayers: any[],
    isEditable: boolean,
    drawPolygon: boolean,
    currentPolygon: GeoJSONFeature<Polygon> | null;
}

const initialState: AnalyticsState = {
    layers: [],
    visibleLayers: [],
    isEditable: false,
    drawPolygon: false,
    currentPolygon: null
}

const reducer = {
    addAnalyticsLayer: (state: AnalyticsState, action: PayloadAction<any>) => {
       
        state.layers.push(action.payload)
        state.visibleLayers.push(action.payload)
    },
    removeAnalyticsLayer: (state: AnalyticsState, action: PayloadAction<any>) => {
        const index = state.layers.findIndex((l : any) => l.id === action.payload);
        if (index !== -1) {
          state.layers.splice(index, 1);
        }
        const indexv = state.visibleLayers.findIndex((l : any) => l.id === action.payload)
        if(indexv !== -1) {
            state.visibleLayers.splice(indexv,1)
        }
    },
    hiddeAnalyticsLayer: (state: AnalyticsState, action: PayloadAction<any>) => {
        const index = state.visibleLayers.findIndex((l) => l.id === action.payload)
        if(index !== -1) {
            state.visibleLayers.splice(index,1)
        }
    },
    showAnalyticsLayer: (state: AnalyticsState, action: PayloadAction<any>) => {
        state.visibleLayers.push(action.payload)
    },
    toggleEditableAnalytics: (state: AnalyticsState) => {
        state.isEditable = !state.isEditable
    },
    setAnalyticsPolygon: (state: AnalyticsState, action: PayloadAction<GeoJSONFeature<Polygon>>) => {
        state.currentPolygon = action.payload;
    },
    drawAnalyticsPolygon:(state:AnalyticsState) => {
        state.drawPolygon = !state.drawPolygon
    }
}

export const analyticsSlice = createSlice({
    name:'analytics',
    initialState,
    reducers: reducer,
})


export const {
    addAnalyticsLayer,
    removeAnalyticsLayer,
    hiddeAnalyticsLayer,
    showAnalyticsLayer,
    toggleEditableAnalytics,
    setAnalyticsPolygon,
    drawAnalyticsPolygon
} = analyticsSlice.actions

export default analyticsSlice.reducer