import { useTranslation } from 'react-i18next';

import DronImg from '../../../../assets/img/dron.png';
import style from '../../AddTask.module.scss';

export default function StepRequirements() {
    const { t } = useTranslation();

    return (
        <div className={style.bodyContent}>
            <ul className={style.requirementsList}>
                <li className={style.requirementsItem}>{t('pq-form-requirements-item1')}</li>
                <li className={style.requirementsItem}>{t('pq-form-requirements-item2')}</li>
                <li className={style.requirementsItem}>{t('pq-form-requirements-item3')}</li>
                <li className={style.requirementsItem}>{t('pq-form-requirements-item4')}</li>
                <li className={style.requirementsItem}>{t('pq-form-requirements-item5')}</li>
            </ul>
            <div className={style.requirementsLinkWrapper}>
                <div className={style.circleWrapper}>
                    <img className={style.circle} src={DronImg}/>
                </div>
                
                <p className={style.requirementsLink}>🔗{t('pq-form-requirements-link')}</p>
            </div>
        </div>
    )
}