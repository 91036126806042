import  IconRefresh from 'assets/icons/ico-refresh.svg';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { MultiLineString } from 'ol/geom';
import { transform } from 'ol/proj';
import { Icon,Stroke, Style } from 'ol/style';
import { useContext, useEffect } from 'react';
import { dataProjection, featureProjection } from 'services/configurations';

import VectorLayerContext from '../Context/VectorLayerContext';

interface PolyLineProps {
  coordinates: Array<number[]>;
  zIndex?: number;
}

// Misssion Polygon
function MPolyLine({ coordinates,zIndex }: PolyLineProps) {
  const vectorLayer = useContext(VectorLayerContext);
  const PolyLineStyle = new Style({
    zIndex: zIndex,
    stroke: new Stroke({
      color: '#ffcc33',
      width: 3,
    }),
  });

  useEffect(() => {
    const feature = new Feature();

    feature.setGeometryName('coordinates');
    // transform coordinates to the map projection
    const coords = coordinates.map((coord) => transform(coord, dataProjection, featureProjection));

    const polyline = new MultiLineString([coords]);
    
    feature.setGeometry(polyline);

    const source = vectorLayer?.getSource();

    feature.setStyle(PolyLineStyle);

    if (source) {
      source.addFeature(feature);
    }

    return () => {
      vectorLayer?.getSource()?.removeFeature(feature);
    };
  }, [coordinates, vectorLayer]);

  return <></>;
}

export default MPolyLine;
