import { kml } from '@tmcw/togeojson';

export function handleFileSelect(event: any, setNewPolygon: any) {
  const file = event.target.files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    if (!e.target) return console.error('Error loading file');
    const kmlData = e.target.result as string;
    const kmlDOM = new DOMParser().parseFromString(kmlData, 'text/xml');
    const geojson = kml(kmlDOM);
    setNewPolygon(geojson);

    // Do whatever you need with the GeoJSON
    console.log(JSON.stringify(geojson));
  };

  reader.readAsText(file);
}
