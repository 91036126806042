import React, { useEffect, useState } from 'react';

interface SelectProps {
  options: { value: string | number; label: string }[];
  onChange: (value: string) => void;
  value?: string | number;
  className?: string;
  disabled?: boolean;
}

function Select({ options, value, onChange, className, disabled }: SelectProps) {
  const [selectValue, setSelectValue] = useState(value);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectValue(value);
    onChange(value);
  };

  return (
    <select
      className={className}
      value={selectValue ?? ''}
      onChange={handleChange}
      disabled={disabled === undefined ? false : disabled}
    >
      <option value='' disabled>
        Seleccione una opción
      </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Select;
