import MPolygon from 'components/Map/Components/Polygon/MPolygon';
import { Prescription } from 'models/Prescription';
import { useAppSelector } from 'state/hooks';
import { BLACK_LINE } from 'styles/variables';
export const getColor = (properties: any, idx?: number) => {
  const prescriptionTheme = useAppSelector((state) => {
    return state.ui.prescriptionTheme;
  });
  if(idx) {
    console.log( properties.fill || properties.color || prescriptionTheme[idx])
    return properties.fill || properties.color || prescriptionTheme[idx]
  }
  return properties.fill || properties.color || 'black';
};

export const getColorModal = (properties: any) => {
  return properties.fill + '2b' || properties.color + '2b' || 'black';
};

export const PrescriptionPolygons = ({
  prescription,
  zIndex,
  onLoadCenter = false,
}: {
  prescription: Prescription;
  zIndex?: number;
  onLoadCenter?: boolean;
}) => {
  if (!prescription) return null;

  const {prescriptionTheme} = useAppSelector((state) => {return state.ui});

  const prescPols = prescription.prescription?.features.map((feature: any, idx: number) => {
  const poly = feature.geometry as GeoJSON.Polygon;
  if (prescription.prescription?.features) {
    for (let i = 0; i < prescription.prescription.features.length; i++) {
        const feature = prescription.prescription.features[i];
        let fill = feature.properties?.fill;
        if (!fill || !isRgbColor(fill)) {
            // Convertir a RGBA si no es un color RGB válido
            fill = convertToRgba(fill, 0.6); // 0.6  de opacidad
        }
        // console.log('El color es RGBA:', fill);
    }
  }

function isRgbColor(color: string) {
    return /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/.test(color) || /^rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d*\.?\d+)\s*\)$/.test(color);
}

function hexToRgba(hex: string, alpha: number): string {
  // Eliminar el # del inicio si está presente
  hex = hex.replace(/^#/, '');

  // Separar el valor en componentes RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Devolver el resultado en formato RGBA
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


function convertToRgba(color: string, alpha: number): string {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
        return hexToRgba(color, alpha);
    }
    // Si el color no es válido, puedes devolver un color por defecto o manejarlo según tus necesidades
    return `rgba(255, 255, 255, ${alpha})`;
}
    return (
      <MPolygon
        zIndex={0}
        onLoadCenter={onLoadCenter}
        key={`${idx}-${getColor(feature.properties)}`}
        polygon={poly}
        color={
          feature.properties?.fill || feature.przoperties?.color
            ? convertToRgba(getColor(feature.properties), 0.6) 
            : prescriptionTheme[idx]
        }
        stroke={BLACK_LINE}
        height={1}
        filled={true}
      />
    );
  });
  return <>{prescPols}</>;
};
