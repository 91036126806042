import { useAddPrescriptionsMutation } from 'api/Vistaguay/LotAPI';
import { ReactComponent as ErrorIcon } from 'assets/icons/ico-error.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/ico-plus.svg';
import { ReactComponent as TickIcon } from 'assets/icons/ico-tick.svg';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { validateGeoJSONasPolygonOrMultipolygon } from 'services/geojsonValidation';
import { readFileAsAJson } from 'services/helpers';
import { useAppSelector } from 'state/hooks';

import style from '../../LotContextual.module.scss';

export default function AddPrescriptionOption({
    lotId, 
    projectId
}: {
    lotId:number,
    projectId: number
}) {
    const { t } = useTranslation();

    const token = useAppSelector((state) => state.data.accessToken);

    const [
        addPrescription, 
        { 
            isSuccess: isAddPrescriptionSuccess, 
            isLoading: isLoadingAddPrescription,
            isError: isErrorAddPrescription,
            data: addPrescriptionData 
        }
    ] = useAddPrescriptionsMutation();

    const inputFile = useRef<HTMLInputElement | null>(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const onChangeFile = async (event: any) => {
        try {
            if (event.target.files) {
                const parsedData = await readFileAsAJson({
                    file: event.target.files[0],
                    token,
                });
        
                if (event.target.files[0].name.length > 45) {
                    ErrorToaster('El nombre de archivo no debe tener mas de 45 caracteres');
                    return;
                }
        
                if (validateGeoJSONasPolygonOrMultipolygon(parsedData)) {
                    if(parsedData?.features && parsedData.features.length > 15){
                        ErrorToaster(t('max-num-records-allowed'));
                        setError(true);
                        return
                    }

                    const prescriptionNew = {
                        prescription: parsedData,
                        createdAt: String(Date.now()),
                        name: event.target.files[0].name,
                    };
                    addPrescription({
                        lotId: Number(lotId),
                        projectId: Number(projectId),
                        prescription: prescriptionNew,
                    });
                } else {
                    ErrorToaster('Error al cargar prescripción.');
                }
            }
        } catch (e) {
            ErrorToaster('Error al cargar prescripción.');
            console.error(`Error al cargar prescipción: ${e}`);
        }
    };

    useEffect(() => {
        let timer: any;
        if (isAddPrescriptionSuccess) {
            setSuccess(true);
            timer = setTimeout(() => {
                setSuccess(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isAddPrescriptionSuccess]);

    useEffect(() => {
        let timer: any;
        if (isErrorAddPrescription) {
            setError(true);
            timer = setTimeout(() => {
                setError(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isErrorAddPrescription]);

    return(
        <div className={style.body} style={{textAlign: 'center'}}>
            <h4 style={{ fontSize: '14px', fontWeight: '600' }}>
                {t('upload-rx-text')}
            </h4>

            <div 
                style={{
                    width: '40px', 
                    height: '40px', 
                    border: '2px solid white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}
                onClick={() => inputFile.current?.click()}
            >
                { !isLoadingAddPrescription && !success && !error && <PlusIcon style={{ stroke: 'white' }}/>} 
                { isLoadingAddPrescription && <Spinner />} 
                { success && <TickIcon style={{ fill: style.verdeVistaguay }} width={20}/>} 
                { error && <ErrorIcon style={{ stroke: style.rojo }}/>} 
            </div>

            {/* FILE SELECTOR */}
            <input
                type='file'
                id='file'
                name='files'
                ref={inputFile}
                style={{ display: 'none' }}
                accept='.geojson,application/zip,application/x-zip-compressed,.shp,.shx,.dbf'
                onChange={(e) => onChangeFile(e)}
            />
        </div>
    )
}