import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
// #region ICONS
import { ReactComponent as PlantIcon } from 'assets/icons/Cultivo-comodin.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as CreditsIcon } from 'assets/icons/ico-credits-outline.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/ico-image.svg';
import { ReactComponent as PlantIconOutline } from 'assets/icons/ico-plant-outline.svg';
import { ReactComponent as SeederIcon } from 'assets/icons/ico-seeder.svg';
import { ReactComponent as SeedsIcon } from 'assets/icons/ico-seeds.svg';
import { ReactComponent as TickIcon } from 'assets/icons/ico-tick.svg';
// #endregion
import { CancelTokenSource } from 'axios';
import Spinner from 'components/Spinner/Spinner';
import { Stepper2 } from 'components/Steppers/Stepper2/Stepper2';
import SuccessScreen from 'components/SuccessScreen/SuccessScreen';
import CancelPopup from 'components/Vistaguay/CancelPopup/CancelPopup';
import StepsFormFooter from 'components/Vistaguay/StepsFormFooter/StepsFormFooter';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import { LotProjectItem, SubHeader, TaskHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { clearImgPoints } from 'state/slices/mapSlice';
import { 
  clearForm, 
  setCollapsePrescriptionTable, 
  setPrescriptionEditable, 
} from 'state/slices/pqFormSlice';

// #endregion
import style from '../AddTask.module.scss';
// #region STEPS
import StepCredit from './Steps/StepCredit';
import StepCrops from './Steps/StepCrops';
import StepFinish from './Steps/StepFinish';
import StepImageUpload from './Steps/StepImageUpload';
import StepRequirements from './Steps/StepRequirements';
import StepSeeder from './Steps/StepSeeder';
import StepSowing from './Steps/StepSowing';

export interface ErrorList {
  message: string;
  propName: string;
}
export interface ErrorProps {
  isFormValid: boolean,
  errors: ErrorList[]
}

export interface HandleErrorProps {
  isFormValid: boolean,
  error: ErrorList
}

function AddPlantCount() {
  // #region HOOKS
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // #endregion

  // #region PARAMS
  const { lotId, projectId } = useParams<{ lotId: string; projectId: string }>();
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);
  // #endregion

  // #region STATES
  const [fileList, setFileList] = useState<File[]>([]);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [isLoadingAddCountTask, setIsLoadingAddCountTask] = useState(false);
  const [isSuccessAddCountTask, setisSuccessAddCountTask] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [nextStepTouched, setNextStepTouched] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [step, setStep] = useState(1);
  // #endregion

  // #region REDUX STATES
  const selectedCampaign: Campaign | null = useAppSelector((state) => state.campaign.selectedCampaign);
  // #endregion

  // #region QUERIES
  if (!lotId || !projectId) {
    return <div>{t('invalid-params')}</div>;
  }

  const {
    data: lot,
    isLoading: isLoadingLot,
    isSuccess: isSuccesLot,
  } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });

  const { data: project } = useGetProjectQuery(projectId as unknown as number);
  // #endregion

  // #region HANDLERS
  const handleExit = () => {
    dispatch(clearImgPoints())
    dispatch(clearForm());
    navigate(`/projects/${projectId}/lots/${lotId}/tasks/new`);
  }

  const handleError = useCallback((isFormValid: boolean) => {
    setHasError(isFormValid);
  }, []);

  const handleUploadImages = useCallback((files: File[]) => {
    setFileList(files);
  }, []);

  const handlePreviousStep = () => {
    if (step > 1) { setStep(step - 1) }
  };
  
  const handleNextStep = () => {
    setNextStepTouched(true);
    if(!hasError){
      if (step < MAX_STEP ) {
        setStep(step + 1);
        setNextStepTouched(false);
      } else if (step == MAX_STEP) {
        setShowSuccessScreen(true);
      }
    }
  };
  // #endregion

  // #region EFFECTS
  useEffect(() => {
    if (isSuccessAddCountTask) {
      dispatch(clearImgPoints());
      dispatch(clearForm());
    }
  }, [isSuccessAddCountTask]);

  useEffect(() => {
    if(step == 3) {
      dispatch(setPrescriptionEditable(false));
      dispatch(setCollapsePrescriptionTable(true))
    }
  }, [step])

  // useEffect(() => {
  //   return () => {
  //     if (cancelTokenSource.current) {
  //       cancelTokenSource.current.cancel();
  //     }
  //   };
  // }, []);
  // #endregion

  // #region STEPS
  const steps = [
    {
      index: 1,
      step: <StepRequirements key={1}/>,
      stepName: t('requirements'),
      icon: <FileIcon />
    },
    {
      index: 2,
      step: <StepSowing key={2} hasError={hasError} handleError={handleError} currentStep={step} nextStepTouched={nextStepTouched}/>,
      stepName: t('sowing'),
      icon: <SeedsIcon />
    },
    {
      index: 3,
      step: <StepImageUpload key={3} fileList={fileList} handleUploadImages={handleUploadImages}  handleError={handleError} currentStep={step} nextStepTouched={nextStepTouched}/>,
      stepName: t('upload-images'),
      icon: <ImageIcon />
    },
    {
      index: 4,
      step: <StepCredit 
        key={4} 
        step={4} 
        currentStep={step} 
        nextStepTouched={nextStepTouched}
        fileList={fileList}
        handleError={handleError} 
      />,
      stepName: t('credits'),
      icon: <CreditsIcon /> 
    },
    {
      index: 5,
      step: <StepCrops key={5} 
        handleError={handleError} 
        currentStep={step} 
        nextStepTouched={nextStepTouched}
      />,
      stepName: t('crops'),
      icon: <PlantIconOutline /> 
    },
    {
      index: 6,
      step: <StepSeeder key={6} />,
      stepName: t('seeder'),
      icon: <SeederIcon /> 
    },
    {
      index: 7,
      step: <StepFinish 
        key={8} 
        projectId={projectId} 
        lotId={lotId} 
        files={fileList}
        selectedCampaignId={selectedCampaign?.id} 
        isLoadingAddTask={isLoadingAddCountTask}
        isSuccessAddTask={isSuccessAddCountTask}
        handleError={handleError}
        handleShowCancelModal={() => setShowCancelModal(true)}
        handleIsLoadingAddTask={(status: boolean) => setIsLoadingAddCountTask(status)}
        handleIsSuccessAddTask={(status: boolean) => setisSuccessAddCountTask(status)}
      />,
      stepName: t('finish-upload'),
      icon: <TickIcon fill={style.verdeOscuro} width={15}/> 
    }
  ]

  const MAX_STEP = steps.length;
  // #endregion


  return (
    <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
      <SubHeader >
        <LotProjectItem
          campaignName={selectedCampaign?.name}
          projectName={project?.nombre}
          projectColor={project?.color}
          lotName={lot?.name}
        />
      </SubHeader>

      <SubHeader noBack={true} sx={{ backgroundColor: style.dark }}>
        <TaskHeader 
          title={t('plant-count')} 
          icon={<PlantIcon />} 
          handleCancel={() => {setShowCancelModal(true)}}
        />
      </SubHeader>

      {isLoadingLot && <Spinner />}
      <SidebarBody>
        {!showSuccessScreen ? (
            <>
              <Stepper2 StepAmount={steps.length} StepCurrent={step} steps={steps}/>

              {steps[step - 1].step}
            </>
          ) : (
            <SuccessScreen redirectTo={`/projects/${projectId}/lots/${lotId}`}/>
          )
        }

        { showCancelModal && (
          <CancelPopup 
            text={(!isLoadingAddCountTask && !isSuccessAddCountTask) ? t('form-upload-error') : t('cancel-modal-text')}
            handleCancelModalclose={() => setShowCancelModal(false)} 
            handleExit={() => {handleExit()}}
            showCancelBtn={(!isLoadingAddCountTask && !isSuccessAddCountTask && step == steps.length) ? false : true}
          />
        )}
      </SidebarBody>

      { !showSuccessScreen && (
        <StepsFormFooter 
          currentStep={step}
          stepsAmount={steps.length}
          disableBackBtn={step == steps.length || isLoadingAddCountTask}
          disableNextBtn={hasError || isLoadingAddCountTask}
          loading={isLoadingAddCountTask}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
        />
      )}
    </Sidebar>
  );
}

export default AddPlantCount;
