import { usePatchCampaignMutation } from 'api/Vistaguay/CampaignAPI';
import MenuItemSingleEdit from 'components/Vistaguay/MenuItemSingleEdit/MenuItemSingleEdit'
import { Campaign } from 'models/Campaign';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formattedDate, splitFirstOccurrence } from 'services/helpers';

import styles from './../CampaignContextual.module.scss'

export default function InfoOption ({
    campaign,
    editable
}: {
    campaign: Campaign,
    editable?: boolean
}) {
    const { t } = useTranslation();

    const campaignNameSplited= splitFirstOccurrence({str: campaign?.name, delimiter: '-'});
    const campaignNameDuration = campaignNameSplited[0];
    const [campaignName, setCampaignName] = useState<string | undefined>(campaignNameSplited[1] || '-')
    const [startDate, setStartDate] = useState(campaign.begin)
    const [endDate, setEndDate] = useState(campaign.end)
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const [
        patchCampaign, 
        {
            isLoading,
            isSuccess,
            isError
        }
    ] = usePatchCampaignMutation();

    const handleSaveName = (value: any) => { 
        patchCampaign({
            campaignId: campaign.id,
            body: {name: `${campaignNameDuration} - ${campaignName}`}
        })
    }

    const handleSaveStartDate = (value: Date) => { 
        const date = value.toISOString();
        patchCampaign({
            campaignId: campaign.id,
            body: {begin: date}
        })
    }

    const handleSaveEndDate = (value: any) => { 
        const date = value.toISOString();
        patchCampaign({
            campaignId: campaign.id,
            body: {end: date}
        })
    }

    useEffect(() => {
        let timer: any;
        if (isSuccess) {
            setSuccess(true);
            timer = setTimeout(() => {
                setSuccess(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isSuccess]);

    useEffect(() => {
        let timer: any;
        if (isError) {
            setError(true);
            timer = setTimeout(() => {
                setError(false);
            }, 2000);
        }

        return () => clearTimeout(timer);
    }, [isError]);

    return (
        <div>
            <MenuItemSingleEdit 
                label={t('name')}
                inputValue={campaignName}
                handleChange={(value: string) => setCampaignName(value)}
                handleSaveChange={handleSaveName}
                editable
            />

            <MenuItemSingleEdit 
                label={t('start-date')}
                inputValue={startDate}
                inputType='datepicker'
                handleChange={(value: any) => setStartDate(value)}
                handleSaveChange={handleSaveStartDate}
                editable
            />

            <MenuItemSingleEdit 
                label={t('end-date')}
                inputValue={endDate}
                inputType='datepicker'
                handleChange={(value: any) => setEndDate(value)}
                handleSaveChange={handleSaveEndDate}
                editable
            />
            
            <div style={{height: '20px'}}>
                { isLoading && (
                    <p className={styles.bounce}>{t('saving')}...</p>
                )}

                { success && (
                    <p style={{color: styles.verdeVistaguay}}>{t('saved')}</p>
                )}

                { error && (
                    <p style={{color:  styles.rojo}}>{t('error')}</p>
                )}
            </div>
        </div>
    )
}