import cx from 'classnames';
import { motion } from 'framer-motion';

import styles from './Sidebar.module.scss';

interface SideBarProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

function Sidebar({ children, style, className }: SideBarProps) {
  return (
    <div className={cx(styles.sidebar, className)} style={style}>
      {children}
    </div>
  );
}

export default Sidebar;
