import React from 'react';
import { useAppSelector } from 'state/hooks';

import styles from './Modal.module.scss'
import PlansModal from './PlansModal/PlansModal';
import { ProfileModal } from './ProfileModal/ProfileModal'
import PurchaseHistoryModal from './PurchaseHistoryMotal/PurchaseHistoryModal';

export interface ModalGlobalState {
    isOpen: boolean,
    type: string | null
}

export interface GlobalModal {
    type: 'profile' | 'purchase-history' | 'plans',
    component: React.ReactNode 
}

export default function ModalGlobalComponent () {
    const { modalGlobalState } = useAppSelector((state) => state.ui)

    if(!modalGlobalState.isOpen || modalGlobalState.type === undefined) {
        return <></>
    }

    const modals: GlobalModal[] = [
        {
            type: 'profile',
            component: <ProfileModal />
        },
        {
            type: 'purchase-history',
            component: <PurchaseHistoryModal />
        },
        // {
        //     type: 'plans',
        //     component: <PlansModal />
        // }
    ] 

    const modal = modals.filter((modal) => modal.type === modalGlobalState.type)[0]; 

    if(modal) {
        return (
            <div className={styles.modal}>
                { modal.component }
            </div>
        )
    } else {
        return (<></>)
    }
}