import { useGetCampaignsQuery } from 'api/Vistaguay/CampaignAPI';
import Button from 'components/Button/Button';
import { Skeleton } from 'components/Skeleton/Skeleton';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import SidebarHeader from 'layout/Sidebar/SidebarHeader';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setSearchToggle } from 'state/slices/campaignSlice';

import { ReactComponent as CalendarIcon } from '../../assets/icons/ico-calendar-white.svg'
import CampaignItem from './CampaignItem';
import styles from './SelectCampaign.module.scss';

export default function SelectCampaign() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const campaignRef = useRef<HTMLDivElement>(null);
  const { data: campaignQuery, isLoading: isLoadingCampaigns } = useGetCampaignsQuery({});
  
  const selectedCampaign = useAppSelector((state) => state.campaign.selectedCampaign);

  useEffect(() => {
    if (campaignRef.current) {
      campaignRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [campaignRef.current])

  useEffect(() => {
    if (campaignQuery?.length === 0) {
      navigate('/campaign/new')
    }
  }, [campaignQuery])

  return (
    <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
      <SidebarHeader />

      <SidebarBody sx={{ backgroundColor: styles.verdeSuave }}>
        <div className={styles.campaignList}>
          {!isLoadingCampaigns ? (
            campaignQuery?.map((campaign) => {
              return (
                <CampaignItem
                  key={campaign.id}
                  selected={campaign.id == selectedCampaign?.id}
                  campaign={campaign}
                  campaignRef={campaignRef}
                />
              );
            })
          ) : (
            <>
              <Skeleton
                sx={{
                  width: '100%',
                  height: '50px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  opacity: '30%',
                }}
              />
              <Skeleton
                sx={{
                  width: '100%',
                  height: '50px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  opacity: '30%',
                }}
              />
              <Skeleton
                sx={{
                  width: '100%',
                  height: '50px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  opacity: '30%',
                }}
              />
              <Skeleton
                sx={{
                  width: '100%',
                  height: '50px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  opacity: '30%',
                }}
              />
              <Skeleton
                sx={{
                  width: '100%',
                  height: '50px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  opacity: '30%',
                }}
              />
              <Skeleton
                sx={{
                  width: '100%',
                  height: '50px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  opacity: '30%',
                }}
              />
              <Skeleton
                sx={{
                  width: '100%',
                  height: '50px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  opacity: '30%',
                }}
              />
              <Skeleton
              sx={{
                  width: '100%',
                  height: '50px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  opacity: '30%',
                }}
              />
            </>
          )}
        </div>
      </SidebarBody>

      <SidebarFooter >
        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            variant={'INFO'}
            onClick={() => {
              dispatch(setSearchToggle());
              navigate('/campaign/new');
            }}
            width='40%'
            text={`${t('add')}`}
            iconRigth={<CalendarIcon style={{marginLeft: '5px'}}/>}
          >
          </Button>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}
