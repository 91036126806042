import { useTranslation } from 'react-i18next';

import DronImg from '../../../../assets/img/dron.png';
import styles from '../../AddTask.module.scss';

export default function StepRequirements() {
    const { t } = useTranslation();

    return (
        <div className={styles.bodyContent}>
            <ul className={styles.requirementsList}>
                <li className={styles.requirementsItem}>{t('mosaic-form-requirement1')}</li>
                <li className={styles.requirementsItem}>{t('mosaic-form-requirement2')}</li>
                <li className={styles.requirementsItem}>{t('mosaic-form-requirement3')}</li>
                <li className={styles.requirementsItem}>{t('mosaic-form-requirement4')}</li>
            </ul>
            
            <div className={styles.requirementsLinkWrapper}>
                <div className={styles.circleWrapper}>
                    <img className={styles.circle} src={DronImg}/>
                </div>
                
                <p className={styles.requirementsLink}>🔗{t('pq-form-requirements-link')}</p>
            </div>
            <p style={{fontSize:'11px',textAlign:'center',marginTop:'10px'}}>Para mas información, consulta nuestro protocolo de vuelo</p>
        </div>
    )
}