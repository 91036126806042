import { Layer, Tile } from 'ol/layer';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import { ImageWMS, TileWMS } from 'ol/source';
import { getLegendUrl } from 'ol/source/wms';
import { useContext, useEffect, useRef, useState } from 'react';

import MapContext from '../Context/MapContext';

interface LayerProps {
  visible?: boolean;
  origin?: string;
  url?: string;
  layer?: string;
  quantityValues: number[];
  min: number;
  max: number;
  styleName?: string;
  opacity?: number;
  params?: string[];
}

export default function MWms({
  url,
  layer,
  visible,
  origin,
  quantityValues,
  min,
  max,
  styleName,
  opacity,
  params,
}: LayerProps) {
  const map = useContext(MapContext);
  const [imageSrc,setImageSrc] = useState<string|undefined>()

  useEffect(() => {
    const wmsParams: any = {
      FORMAT: 'image/png',
      VERSION: '1.1.1',
      tiled: true,
      STYLES: styleName,
      LAYERS: layer,
      exceptions: 'application/vnd.ogc.se_inimage',
      serverType: 'geoserver',
      LEGEND_OPTION: 'forceLabels:on;fontAntiAliasing:true',
      LAYER: layer,
    };

    const wmsLegendParams : any = {
      FORMAT: 'image/png',
      REQUEST: 'GetLegendGraphic',
      VERSION: '1.1.1',
      STYLES: styleName,
      HEIGHT:10,
      WIDTH: 20,
      LEGEND_OPTION: 'forceLabels:on;fontAntiAliasing:true',
      LAYER: layer,
    }

    if (quantityValues.length > 0) {
      let envQueryStr = '';
      for (let i = 0; i < quantityValues.length; i++) {
        envQueryStr = envQueryStr.concat(`step${i + 1}:${quantityValues[i].toFixed(2)};`);
      }
      wmsParams['env'] = envQueryStr;
      wmsLegendParams['env'] = envQueryStr;
    }
    
    if (params && params.length > 0) {
      let envQueryStr = '';
      for (let i = 0; i < params.length; i++) {
        envQueryStr = envQueryStr.concat(`${params[i]};`);
      }
      wmsParams['env'] = envQueryStr;
      wmsLegendParams['env'] = envQueryStr;
    }

    const wmsSource = new TileWMS({
      url: url,
      params: wmsParams,
    });

    const wmsSourceLegend = new ImageWMS({
      url,
      params: wmsLegendParams
    })


    const tiled = new TileLayer({
      visible: visible,
      source: wmsSource,
      opacity: opacity ? opacity : 1.0,
    });

    const imageLegend = new ImageLayer({
      visible: true,
      extent:[-13884991, 2870341, -7455066, 6338219],
      source: wmsSourceLegend,
      opacity: 1.0,

    })

    const updateLegend = function (resolution: any) {
      const graphicUrl = wmsSourceLegend.getLegendUrl(resolution);
      setImageSrc(graphicUrl)
    };
    tiled.set('name', url);

    const mapClickHandler = (evt: any) => {
      if (!map) {

        return null;
      }
      const view = map.getView();
      const viewResolution = view.getResolution();
      const source = tiled.getSource();
      if (!source || !viewResolution) {

        return null;
      }

      const url = source.getFeatureInfoUrl(evt.coordinate, viewResolution, view.getProjection(), {
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 50,
      });

    };
    const resolution = map?.getView().getResolution();
    updateLegend(resolution);

    // Update the legend when the resolution changes
    map?.getView().on('change:resolution', function (event) {
      const resolution = event.target.getResolution();
      updateLegend(resolution);
    });

    // map?.addLayer(webGlLayer);
    map?.addLayer(tiled);
    map?.addLayer(imageLegend)
    map?.on('singleclick', mapClickHandler);

    return () => {
      map?.removeLayer(tiled);
      map?.removeLayer(imageLegend)
      map?.un('singleclick', mapClickHandler);
      // map?.removeLayer(webGlLayer);
    };
  }, [visible, min, max, opacity, params, layer, styleName,quantityValues,url,map]);

  return <>
    <div>
      <img src={imageSrc} style={{position:'fixed',zIndex:10000,bottom:10,right:10}} />
    </div>
  </>;
}
