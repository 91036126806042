/* eslint-disable camelcase */
// #region ICONS
import { ReactComponent as AltitudeIcon } from 'assets/icons/altitudeIcon.svg'
import { ReactComponent as DroneFrontalIcon } from 'assets/icons/droneFrontal.svg'
import { ReactComponent as DroneLateralIcon } from 'assets/icons/droneLateral.svg'
import { ReactComponent as GradesIcon } from 'assets/icons/gradesIcon.svg'
import { ReactComponent as SpeedIcon } from 'assets/icons/speedIcon.svg'
import { InputRange, InputRangeVariant } from 'components/Inputs/Input'
import { MappingMission } from 'models/Mission'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setCurrentMission } from 'state/slices/volareSlice'

// #endregion
// #region CONPONENTS
import { FlightInfo } from '../../Components/FlightInfo/FlightInfo'
import List from '../../Components/InputList/List'
import ListInputItem from '../../Components/InputList/ListInputItem'
// #endregion
// #region INTERFACE
import { IMappingMissionParams } from '../MappingSidebar'
// #endregion

export default function StepFlightInfo ({
    step,
    nextStepTouched,
    handleError
} : {
    step: number,
    nextStepTouched?: boolean,
    handleError: (isFormValid: boolean) => void
}) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const currentMission = (useAppSelector((state) => state.volare.currentMission)) as MappingMission;
    
    const [altitude, setAltitude] = useState(currentMission?.altitude || 100);
    const [speed, setSpeed] = useState(currentMission?.speed || 10);
    const [direction, setDirection] = useState(currentMission?.flight_direction || 0);
    const [frontalOverlap, setFrontalOverlap] = useState(currentMission?.frontal_overlap || 75);
    const [lateralOverlap, setLateralOverlap] = useState(currentMission?.lateral_overlap || 75);

    return (
        <div>
            <FlightInfo />
            <List>
                <ListInputItem 
                    min={1} 
                    max={200} 
                    text={`${t('height')}`} 
                    unity='m' 
                    name='altitude'
                    amount={altitude}  
                    handleAction={(e) => {
                        setAltitude(+e.target.value);
                        dispatch(setCurrentMission({
                            ...currentMission,
                            altitude: +e.target.value
                        }))
                    }} 
                    Icon={() => <AltitudeIcon style={{ width: '20px', height: '20px' }} />} 
                />

                <ListInputItem 
                    min={1} 
                    max={15} 
                    text={`${t('speed')}`} 
                    unity='m/s' 
                    name='speed'
                    Icon={() => <SpeedIcon style={{ width: '20px', height: '20px' }} />} 
                    amount={speed} 
                    handleAction={(e) => {
                        setSpeed(+e.target.value);
                        dispatch(setCurrentMission({
                            ...currentMission,
                            speed: +e.target.value
                        }))
                    }}
                />

                <ListInputItem 
                    min={-180} 
                    max={180} 
                    text={`${t('direction')}`}
                    name='direction' 
                    unity='°' 
                    Icon={() => <GradesIcon style={{ width: '20px', height: '20px' }} />} 
                    amount={direction} 
                    handleAction={(e) => {
                        setDirection(+e.target.value);
                        dispatch(setCurrentMission({
                            ...currentMission,
                            flight_direction: +e.target.value
                        }))
                    }}
                />
            </List>

            {/* FLIGHT DIRECTION SETTINGS */}
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '5px' }}>
                <h6>Solapamiento</h6>

                <hr style={{ margin: '0px 0px', marginBottom: '10px' }} />

                <div style={{ display: 'flex', width: '100%', margin: 0, padding: 0, justifyContent: 'space-between',gap:'10px' }}>
                    <div style={{ display: 'flex', gap: 0, flexDirection: 'column' }}>
                        <p style={{ alignSelf: 'center', fontSize: '12px', marginBottom: '10px' }}>Frontal</p>
                        <DroneFrontalIcon 
                            style={{ 
                                width: '40px', 
                                height: '40px',
                                display: 'flex', 
                                alignSelf: 'center' 
                            }} 
                        />

                        <InputRange   
                            min={0}
                            max={100}
                            variant={InputRangeVariant.VOLARE} 
                            name='map_mis_ov_frontal'
                            id='map_mis_ov_frontal'
                            value={frontalOverlap}
                            onChange={(e) => {
                                setFrontalOverlap(+e.target.value);
                                dispatch(setCurrentMission({
                                    ...currentMission,
                                    frontal_overlap: +e.target.value
                                }))
                            }}
                        />

                        <label
                            style={{ 
                                position: 'relative', 
                                fontWeight: 700, 
                                left: `${(+currentMission.frontal_overlap)}px` 
                            }}
                        >
                            { currentMission.frontal_overlap}%
                            { currentMission.frontal_overlap == 75 &&
                                <p style={{ display: 'flex', fontSize: '10px', alignSelf: 'center' }}>Recomendado</p>
                            }
                        </label>
                    </div>
    
                    <div style={{ display: 'flex', gap: 0, flexDirection: 'column' }}>
                        <p style={{ alignSelf: 'center', fontSize: '12px', marginBottom: '10px' }}>Lateral</p>
                        <DroneLateralIcon style={{ width: '40px', height: '40px', display: 'flex', alignSelf: 'center' }} />
                        <InputRange
                            variant={InputRangeVariant.VOLARE}
                            type='range'
                            name='map_mis_ov_lateral'
                            id='map_mis_ov_lateral'
                            min={0}
                            max={100}
                            width={'100%'}
                            value={lateralOverlap}
                            onChange={(e) => {
                                setLateralOverlap(+e.target.value);
                                dispatch(setCurrentMission({
                                    ...currentMission,
                                    lateral_overlap: +e.target.value
                                }))
                            }}
                        />

                        <label 
                            style={{ 
                                width: '100%', 
                                textAlign: 'start', 
                                position: 'relative', 
                                fontWeight: 700, 
                                left: `${+currentMission.lateral_overlap}px` 
                            }}
                        >
                            { currentMission.lateral_overlap }%
                            { currentMission.lateral_overlap == 75 &&
                                <p style={{ display: 'flex', fontSize: '10px', alignSelf: 'center' }}>Recomendado</p>
                            }
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}