import { useDeleteLotMutation } from 'api/Vistaguay/LotAPI';
import { useDeleteTaskMutation } from 'api/Vistaguay/TaskAPI';
import Button from 'components/Button/Button';
import { ErrorToaster, SuccesToaster } from 'components/Toaster/AppToaster';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../../LotContextual.module.scss';

export default function TrashOption({ lotId, projectId }: { lotId: number, projectId: number }) {
  const { t } = useTranslation();

  const [confirm, setConfirm] = useState(false);

  const [
    deleteLot, 
    { 
      isLoading: isLoadingDeleteLot,
      isSuccess: isSuccessDeleteLot,
      isError: isErrorDeleteLot
    }
  ] = useDeleteLotMutation()

  const handleDeleteLot = () => {
    deleteLot({lotId, projectId})
  }

  useEffect(() => {
    if(isErrorDeleteLot) {ErrorToaster(t('error-deleting'))}
  }, [isErrorDeleteLot])

  useEffect(() => {
    if(isSuccessDeleteLot) {SuccesToaster(`${t('lote')} ${t('success-deleted')}`)}
  }, [isSuccessDeleteLot])

  return (
    <div
      style={{ textAlign: 'center' }}
      className={style.body}
    >
      <h4 style={{ fontSize: '14px', fontWeight: '600' }}>
        {t('delete-lot-title')}
      </h4>

      <p style={{ fontSize: '12px' }}>
        {t('delete-task-text')}
      </p>

      <div className={style.contextualControls}>
        <Button
          onClick={() => setConfirm(false)}
          text='Volver'
          variant={'NEUTRAL-GRAY'}
          style={{ fontSize: '12px' }}
          height='30px'
        />
        <Button
          onClick={() => handleDeleteLot()}
          text={t('errase')}
          variant={'ERROR'}
          style={{ fontSize: '12px' }}
          height='30px'
          disabled={isLoadingDeleteLot}
          isLoading={isLoadingDeleteLot}
        />
      </div>   
    </div>
  );
}
