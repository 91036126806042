import { Cameras } from './Cameras';
import { Campaign } from './Campaign';
import Lot from './Lot';
import { Task, TASK_TYPE } from './Task';
import User from './User';

export enum CAMERA_TYPE {
  RGB = 'R-G-B',
  NIR = 'NIR-G-B',
  NULL = ''
}

export enum MOSAIC_STAGE {
  NEW = 0,
  PROCESSING = 1,
  PUBLISHED = 2,
  REPROCESS = 3,
  BROKEN = 6,
  DISCARD = 7,
}
interface ICultivo {
  cultivoId:number,
  enAppAsesor:number,
  enAppTasador:number,
  enWeb:number,
  nombre: string,
  orden: number
}
interface IEstadio {
  id: number,
  name: string
}

interface IHibrido {
  id: number,
  name:string,
}
class MosaicTask extends Task {
  crop: string;
  hybrid: string;
  stage: string;
  sowDate: Date;
  pilot: string;
  drone: string;
  clientCUIT: string;
  mosaicoId: number;
  nombre: string;
  fechaVuelo?: string;
  estadio?: IEstadio;
  cuitCliente?: string | null;
  resolucion?: number | null;
  fechaSiembra?: string | null;
  alturaVuelo?: number | null;
  dSurco?: number | null;
  cultivo?: ICultivo
  lat?: number | null;
  lon?: number | null;
  superficie?: number | null;
  estadoId: number;
  fechaUpdate?: string ;
  tipoCamara?: CAMERA_TYPE;
  camaraId?: number | null;
  camara?: Cameras;
  deletedAt?: Date | null;
  hibrido?: IHibrido;
  cultivoId?: number | null;
  estadioId?: number | null;
  hibridoId?: number | null;
  tipoMosaicoId?: number;
  nImagenes?: number | null;
  creator?: User;
  lot?: Lot;
  campaign?: Campaign;
  createdAt: string | null;
  uploadFinished: boolean;

  constructor(
    mosaicoId: number,
    nombre: string,
    name: string,
    cameraType: string,
    flightDate: Date,
    crop: string,
    hybrid: string,
    stage: string,
    sowDate: Date,
    pilot: string,
    drone: string,
    clientCUIT: string,
    estadoId: number,
    uploadFinished: boolean
  ) {
    super(mosaicoId, name, TASK_TYPE.mosaico);
    this.nombre = nombre;
    this.createdAt = new Date().toISOString();
    this.mosaicoId = mosaicoId;
    this.id = mosaicoId;
    this.estadoId = estadoId
    this.sowDate = sowDate;
    this.crop = crop;
    this.hybrid = hybrid;
    this.stage = stage;
    this.pilot = pilot;
    this.drone = drone;
    this.clientCUIT = clientCUIT;
    this.uploadFinished = uploadFinished
  }
}

export default MosaicTask;
