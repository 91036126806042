import { Popover2 } from '@blueprintjs/popover2';
import { useGetCreditQuery, useGetTransactionsMutation } from 'api/Vistaguay/CreditAPI';
import { useMeQuery } from 'api/Vistaguay/VistaguayAPI';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Currency } from '../../../assets/icons/currency.svg';
import style from './CreditInfo.module.scss'

export const CreditInfo = () => {
  const { t } = useTranslation();

  const { data: credit, isLoading } = useGetCreditQuery();
  const [getTransactions, { data: transactions }] = useGetTransactionsMutation();
  const {data: me} = useMeQuery()
  
  useEffect(() => {
    if (me) { 
      getTransactions({
        userId: me.usuarioId,
        pageNumber: 0,
        pageSize: 10,
      });
    }
  }, [getTransactions, me]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
      <div className={style.credit}>
        <div className={style.currency}>
          <Currency />
        </div>

        <div className={style.dot}>
          <p style={{fontSize: '12px', fontWeight: '700'}}>
            {credit?.amount}
          </p>
        </div>
      </div>
  );
};
// Path: src\components\Domain\Credit\BuyCredit.ts
