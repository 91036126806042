import { Geometry, polygon } from '@turf/turf';
import MarkerIcon from 'assets/icons/ico-flag.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashIcon.svg';
import { PrescriptionPolygons } from 'components/Domain/Prescription/PrescriptionPolygons';
import { MClickHandler } from 'components/Map/Components/MClickHandler';
import MPoint from 'components/Map/Components/MPoint';
import MPolyLine from 'components/Map/Components/MPolyline';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import MPolygon from 'components/Map/Components/Polygon/MPolygon';
import { isScouting, ScoutingMission } from 'models/Mission';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getScoutingPlan,
  getScoutingPlanInfo,
  IScoutingPlan,
} from 'services/scoutingMissionGenerator';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  IFlightPlanInfo,
  setCurrentMission,
  setCurrentPoint,
  setFlightPlanInfo,
  setMissionPolygon,
  setScoutingPoints,
} from 'state/slices/volareSlice';
import { BLACK_LINE, LOT_COLOR, MISSION_POLYGON_COLOR } from 'styles/variables';

import style from './ScoutingMap.module.scss';

const editingPolygon = (mission: any) => {
  // console.log(`Editando scouting ${JSON.stringify(mission)}`)
  if (mission.polygon_vertices) {
    // console.log(`Imprimiendo polygon vertices`)
    return [mission.polygon_vertices.map((bbddPoint: any) => [bbddPoint.lng, bbddPoint.lat])];
  } else {
    return [];
  }
};

export const AddScoutingMap = () => {
  const { projectId, missionId } = useParams();
  
  const editing = useAppSelector((state) => state.volare.editMissionPolygon);
  const builderParam = useAppSelector((state) => state.volare.missionBuilderParams);
  const missionPolygon = useAppSelector((state) => state.volare.missionPolygon);
  const currentMission = (useAppSelector((state) => state.volare.currentMission)) as ScoutingMission;
  const prescription = useAppSelector((state) => state.volare.prescription)
  const selectedPrescription = useAppSelector((state) => state.ui.selectedPrescription);
  const currentLot = useAppSelector((state) => state.volare.currentLot);
  const scoutingPoints = useAppSelector((state) => state.volare.scoutingPoints);
  const [plan, setPlan] = useState<IScoutingPlan>();
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();

  const handlePlanInfoUpdate = (planInfo: IFlightPlanInfo) => {
    dispatch(setFlightPlanInfo(planInfo));
  };

  useEffect(() => {
    if(scoutingPoints && !currentMission?.photoCount){
      dispatch(setCurrentMission({...currentMission, photoCount: scoutingPoints.length}))
    }
  }, [scoutingPoints])

  useEffect(() => {
    const points = [...(missionPolygon?.geometry?.coordinates[0] || [])];
    const planOptions = {
      speed: currentMission?.speed,
      photoCount: currentMission?.photoCount,
      direction: currentMission?.flight_direction?.toString()
    };

    try {
      const scoutingPlan = getScoutingPlan(points, planOptions);
      setPlan(scoutingPlan);

      dispatch(
        setScoutingPoints(
          scoutingPlan.photoPoints
            .map((feature) => (feature.geometry as Geometry).coordinates)
            .map((coords) => [coords[0], coords[1]]),
        ),
      );
      handlePlanInfoUpdate(scoutingPlan.planInfo);
      setError(false);
    } catch (error) {
      console.error(error);
      handlePlanInfoUpdate({ minutes: '-', area: '-', photos: -1, bateries: -1 });
      setError(true);
    }
    
  
      // const planInfo = getScoutingPlanInfo(planOptions, currentMission);
  }, [currentMission, missionPolygon?.geometry?.coordinates[0]]);

  const Lines = () => {
    if (plan) {
      // const photoPoints = plan.photoPoints
      //   .map((feature) => (feature.geometry as Geometry).coordinates)
      //   .map((coords, index) => {

      const photoPoints = scoutingPoints
      .map((coords, index) => {
        return (
          <MPoint
            marker={MarkerIcon}
            editable={editing}
            onEdit={(newCoords) => {
              const newPoints = [...scoutingPoints];
              const index = newPoints.findIndex(
                (point) => point[1] === coords[1] && point[0] === coords[0],
              );
              newPoints[index] = [newCoords[0], newCoords[1]];
              dispatch(setScoutingPoints(newPoints));
            }}
            key={`${coords[0]}-${coords[1]}`}
            lat={coords[1] as number}
            lng={coords[0] as number}
            pointOrder={`${index + 1}`}
          />
        );
      });

      return (
        <>
          {photoPoints}
          <MPolyLine
            coordinates={plan.photoPoints.map((feature) => {
              const coords = (feature.geometry as Geometry).coordinates as number[];
              return [coords[0], coords[1]];
            })}
          />
        </>
      );
    } else return null;
  };

  const PolygonOrLine = () => {
    if (editing && currentMission?.waypoints) {
      return (
        <>
          <MVectorLayer>
            <MPolygon color={LOT_COLOR} polygon={currentLot?.polygon?.geometry} stroke={BLACK_LINE} height={1}/>
            <MPolyLine
              coordinates={currentMission.waypoints.map((waypoint: any) => [
                waypoint.lng,
                waypoint.lat,
              ])}
            />
            <MPolygon color={'black'} coordinates={editingPolygon(currentMission)}  stroke={BLACK_LINE} height={1} zIndex={1000}/>
          </MVectorLayer>
          <MVectorLayer>
            { currentMission.waypoints.map((waypoint: any, index) => {
              const DeletePointDiv = () => {
                const deletePoint = () => {
                  dispatch(setCurrentPoint({ index: -1, position: [0, 0] }));

                  // delete waypoint
                  const newPoints = JSON.parse(JSON.stringify(currentMission.waypoints));
                  const index = newPoints.findIndex(
                    (point: any) => point.lat === waypoint.lat && point.lng === waypoint.lng,
                  );
                  newPoints.splice(index, 1);
                  // reindex
                  newPoints.forEach((point: any, index: number) => {
                    point.index = index;
                  });
                  dispatch(setCurrentMission({ ...currentMission, waypoints: newPoints }));
                };
                // Trash icon in red color
                return (
                  <div className={style.trashIconContainer} onClick={deletePoint}>
                    <TrashIcon fill='#444444' height={25} />
                  </div>
                );
              };
              return (
                <MPoint
                  marker={MarkerIcon}
                  editable={editing}
                  onEdit={(newCoords) => {
                    if (isScouting(currentMission)) {
                      const newPoints = [...currentMission.waypoints];
                      const index = newPoints.findIndex(
                        (point) => point.lat === waypoint.lat && point.lng === waypoint.lng,
                      );
                      newPoints[index] = {
                        ...newPoints[index],
                        lat: newCoords[1],
                        lng: newCoords[0],
                      };
                      dispatch(setCurrentMission({ ...currentMission, waypoints: newPoints }));
                    }
                  }}
                  key={`${waypoint.lat}-${waypoint.lng}`}
                  lat={waypoint.lat}
                  lng={waypoint.lng}
                  onClick={() => {
                    dispatch(setCurrentPoint(waypoint));
                  }}
                  overlayContent={{
                    title: '',
                    content: <DeletePointDiv />,
                  }}
                  pointOrder={`${index + 1}`}
                />
              );
            })}
            {editing && (
              <MClickHandler
                onClick={(coordinates) => {
                  if (currentMission && isScouting(currentMission) && currentMission.waypoints) {
                    const waypoints = [
                      ...currentMission.waypoints,
                      {
                        index: currentMission.waypoints.length,
                        lat: coordinates[1],
                        altitude: currentMission.altitude,
                        speed: currentMission?.speed,
                        lng: coordinates[0],
                        actions: [],
                      },
                    ];
                    const currentMissionToSet = {
                      ...currentMission,
                      waypoints: waypoints,
                    };
                    dispatch(setCurrentMission(currentMissionToSet));
                  }
                }}
              />
            )}
          </MVectorLayer>
        </>
      );
    } else {
      return (
        <>
          <MVectorLayer>
            <MPolygon color={LOT_COLOR} polygon={currentLot?.polygon?.geometry} stroke={BLACK_LINE} height={1}/>
            <MPolygon
              color={MISSION_POLYGON_COLOR}
              stroke={BLACK_LINE}
              height={1}
              polygon={missionPolygon?.geometry}
              isEditable
              onEdit={(coordinates) => {
                dispatch(setMissionPolygon(polygon(coordinates)));
              }}
            />
            <Lines />
          </MVectorLayer>
        </>
      );
    }
  };

  return (
    <>
      <PolygonOrLine />

      {
        selectedPrescription && (
          <MVectorLayer>
            <PrescriptionPolygons 
              prescription={selectedPrescription} 
              onLoadCenter={true} 
              zIndex={0}
            />
          </MVectorLayer>
        )
      }
      {error && (
        <div className='error notification'>
          Polígono inválido. Mueva los vértices para garantizar que sus lados no se crucen
        </div>
      )}
    </>
  );
};
