import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCampaignProjectsWithLotQuery } from 'api/Vistaguay/CampaignAPI';
import { useGetLotsQuery } from 'api/Vistaguay/LotAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import { ReactComponent as ArrowPrev } from 'assets/icons/arrow-prev.svg';
import { ReactComponent as AddLotIcon } from 'assets/icons/ico-add-lote.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/ico-folder.svg';
import { ReactComponent as PolygonIcon } from 'assets/icons/ico-polygon-white.svg'
import Button from 'components/Button/Button';
import { Skeleton } from 'components/Skeleton/Skeleton';
import VistaguaySpinner from 'components/Spinner/VistaguaySpinner/VistaguaySpiner';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import SidebarHeader from 'layout/Sidebar/SidebarHeader';
import { DefaultItem, LotProjectItem, SlimHeader, SlimHeader2, SubHeader } from 'layout/Sidebar/SubHeader';
import { Campaign } from 'models/Campaign';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector } from 'state/hooks';

import LotItem from './LotItem';
import style from './LotList.module.scss';

function EmptyLotList() {
  const { t } = useTranslation();
  return (
    <div className={style.emptylotlist}>
      <div className={style.iconwrapper}>
        <AddLotIcon className={style.icon} />
      </div>
      <h1>{t('agregar-lote')}</h1>
      <p>{t('busca-este-icono-en-el-mapa-y-agrega-un-nuevo-lote-a-tu-proyecto')}</p>
    </div>
  );
}

function LotList() {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const selectedCampaign: Campaign | null = useAppSelector((state) => state.campaign.selectedCampaign);

  const {
    data: project,
    isLoading: isLoadingProject,
    isError: isProjectError,
  } = useGetProjectQuery(projectId as unknown as number);
  
  const {
    data,
    isLoading: isLoadingLots,
    isSuccess,
  } = useGetLotsQuery(projectId as unknown as number);

  useEffect(() => {
    if (isProjectError) {
      navigate('/projects');
    }
  }, [isProjectError, navigate]);

  useEffect(() => {
    if (isLoadingLots || isLoadingProject) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingLots, isLoadingProject]);

  const IconProp: React.FC = () => {
    return (
      <FolderIcon
        style={{
          fill: project?.color,
          height: '30px',
          width: '30px',
          marginRight: '5px',
          // stroke: 'white',
          strokeWidth: '4px',
        }}
      />
    );
  };


  const numberOfSkeletons = 7; // Define el número de esqueletos que deseas mostrar

  const skeletons = Array.from({ length: numberOfSkeletons }).map((_, index) => (
      <Skeleton key={index} sx={{width:'100%',height:'50px',marginTop:'5px',borderRadius:'5px'}}/>
  ));


  return (
    <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
      <SidebarHeader></SidebarHeader>
      {projectId && (
        <>
          <SubHeader >
            <LotProjectItem
              campaignName={selectedCampaign?.name}
              projectName={project?.nombre}
              projectColor={project?.color}
            />
          </SubHeader>

          <SidebarBody
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <div className={style.lotList}>
              {
                isSuccess && !isLoading ?
                (
                  data.length > 0 ? (
                    data.map((lot) => <LotItem key={lot.id} lot={lot} projectId={projectId} />)
                  ) : (
                    <EmptyLotList />
                  )
                )
                :
                <>
                 <>{skeletons}</>
                </>
              }
            </div>
          </SidebarBody>

          <SidebarFooter >
            <div className={style.gridContainer}>
              <Button
                iconBack
                text={t('back')}
                variant='INFO'
                onClick={() => navigate('/projects')}
              />
              <Button
                text={t('add')}
                variant={'INFO'}
                onClick={() => {
                  if (projectId) navigate(`/lots/new?projectId=${projectId}`);
                  else navigate('/lots/new');
                }}
                iconRigth={<PolygonIcon fill='#FFFFFF'  style={{ fill: 'white'}} />}
              />
            </div>
          </SidebarFooter>
        </>
      )}
    </Sidebar>
  );
}

export default LotList;
