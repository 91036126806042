export enum TRANSACTION_TYPE {
  CREDIT = 'CREDIT', // Credits income
  DEBIT = 'DEBIT', // Credits outcome
}

export enum TRANSACTION_REASON {
  TASK_PLANTING_QUALITY = 'TASK_PLANTING_QUALITY',
  TASK_MOSAIC = 'TASK_MOSAIC',
  TASK_MALEZAS = 'TASK_MALEZAS',
  TRANSFER = 'TRANSFER',
  BUY = 'BUY',
  EXPIRATION = 'EXPIRATION',
}

export class Transaction {
  id: string;
  amount: number;
  type: TRANSACTION_TYPE;
  createdAt: Date;

  constructor(id: string, amount: number, type: TRANSACTION_TYPE, createdAt: Date) {
    this.id = id;
    this.amount = amount;
    this.type = type;
    this.createdAt = createdAt;
  }
}


export class TransactionRequest {
  userId: number;
  pageNumber: number;
  pageSize: number;
  type?: TRANSACTION_TYPE;
  startDate?: Date;
  endDate?: Date;

  constructor(
    userId: number,
    pageNumber: number,
    pageSize: number,
    type?: TRANSACTION_TYPE,
    startDate?: Date,
    endDate?: Date,
  ) {
    this.userId = userId;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.type = type;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}