import { Box } from '@mui/material';
import { PlantCountTask } from 'models/PlantCountTask';
import { Prescription } from 'models/Prescription';
import { PrescriptionTable } from 'pages/Tasks/PlantCount/PrescriptionTable';
import { IAllTasks } from 'state/slices/taskSlice';

interface IPrescriptionModalProps {
  value: number; 
  index: number; 
  task?: IAllTasks, 
  dispatch: any; 
  prescription: Prescription;
  editable?: boolean;
  handleDensityValues?: (values: any) => void;
}

export const TabPanel = ({ 
  value, 
  index, 
  task, 
  dispatch, 
  prescription,
  editable = false,
  handleDensityValues 
}: IPrescriptionModalProps) => {
    return (
      <Box sx={{
        overflowX: 'hidden',
        overflowY: 'hidden',
        maxHeight: '30vh',
        '&:hover': {
          overflowX: 'auto',
          overflowY: 'auto',
        },
        borderRadius: 2,
        '&::-webkit-scrollbar': {
          backgroundColor: '#BABABA',
          height: '10px',
          cursor: 'default',
          borderRadius: 20,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#8A8A8A',
          borderRadius: 4,
          width: 2,
          cursor: 'default'
        },
      }}>
        <PrescriptionTable 
          prescription={prescription} 
          plantCountTask={task?.task as PlantCountTask} 
          handleDensityValues={handleDensityValues}
          editable={editable}
        />
      </Box>
    );
  };