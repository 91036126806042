import { Spinner } from '@blueprintjs/core';
import { useResetPasswordMutation } from 'api/Vistaguay/VistaguayAPI';
import Button from 'components/Button/Button';
import { Input } from 'components/Inputs/Input';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './ForgotPassword.module.scss';

export const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    
    // url is like /resetPassword?token=123 use react router dom to get the token
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();

    const [resetPassword, { isLoading }] = useResetPasswordMutation();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        try {
            if (password !== password2) {
                setError('Las contraseñas no coinciden');
                return;
            }
            if (password.length < 8) {
                setError('La contraseña debe tener al menos 8 caracteres');
                return;
            }
            if(!token) {
                setError('No se encontró el token');
                return;
            }
            await resetPassword({ token, password }).unwrap();
            setSuccess('Se cambió la contraseña con éxito, en breve será redirigido');
            setTimeout(() => {
                navigate('/');
            }, 2000);
        } catch (error: any) {
            if (error.data) {
                setError(error.data.message);
            } else if (error.message) {
                setError(error.message);
            } else setError('Ocurrió un error');
        }
    };


    if(success) {
        return (
            <div className={styles.mainArea}>
                <div className={styles.block}>
                    <h4>Recuperar contraseña</h4>
                    <p className={styles.success}>{success}</p>
                    <Button color='white' text='Volver al inicio' variant='SUCCESS' 
                    style={{
                        marginTop: '20px',
                        width: '100%'
                    }}
                        onClick={
                            () => navigate('/')
                        }
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.mainArea}>
            <div className={styles.block}>
                <h4>Recuperar contraseña</h4>
                <form onSubmit={handleSubmit}>
                    <Input
                        placeholder='Contraseña'
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Input
                        placeholder='Repetir contraseña'
                        type='password'
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                    <Button color='white' text='Enviar' variant='SUCCESS'
                        icon={isLoading ? <Spinner size={20} /> : null} disabled={isLoading}
                    />
                </form>
                {error && <p className={styles.error}>{error}</p>}
                {success && <p className={styles.success}>{success}</p>}
            </div>
        </div>
    );
}