import { skipToken } from '@reduxjs/toolkit/dist/query';
import { centerOfMass } from '@turf/turf';
import { useGetCampaignProjectsWithLotQuery } from 'api/Vistaguay/CampaignAPI';
import { ReactComponent as Folder } from 'assets/icons/ico-folder.svg';
import MPoint from 'components/Map/Components/MPoint';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import { Campaign } from 'models/Campaign';
import Lot from 'models/Lot';
import Project from 'models/Project';
import { useAppSelector } from 'state/hooks';

import style from './ProjectListMap.module.scss';

const svgFolder = (color: string) => {
  return `<svg
      xmlns='http://www.w3.org/2000/svg'
      xmlns:xlink='http://www.w3.org/1999/xlink'
      style='overflow:visible;enable-background:new 0 0 63 61'
      xml:space='preserve'
      viewBox='9.5 9.5 40 40'
      width='1.5em'
      height='1.5em'
      fill='${color}'
    >
      <path d='M41.27 13.88H24.81c-.94 0-1.86.32-2.59.92l-7.11 5.73c-.99.8-1.56 1.99-1.56 3.26V39.7c0 2.3 1.86 4.18 4.15 4.18h23.58c2.29 0 4.15-1.87 4.15-4.18V18.05c-.01-2.3-1.87-4.17-4.16-4.17z' />
    </svg>`;
};

const Title = ({ project }: { project: Project }) => {
  return (
    <div className={style.popupTitle}>
      <p>{project.nombre}</p>
      <Folder
        style={{
          width: '1.5rem',
          height: '1.5rem',
          fill: project.color,
        }}
      />
    </div>
  );
};

const Body = ({ lot }: { lot: Lot }) => {
  return (
    <div className={style.popupBody}>
      <p>{lot.name}</p>
    </div>
  );
};

const overlayTitle = ({ project }: { project: Project }) => {
  return `<div class=${style.popupTitle}>
      <p>${project.nombre}</p>
      ${svgFolder(project.color)}
    </div>`;
};

const OverlayBody = ({ lot }: { lot: Lot }) => {
  return <div className={style.popupBody}>
  <p>{lot.name}</p>
</div>
};

export const ProjectListMap = () => {
  const selectedCampaign: Campaign | null = useAppSelector(
    (state) => state.campaign.selectedCampaign,
  );
  const hoveredProject = useAppSelector((state) => state.ui.hoveredProjectId);
  const {
    data: projectsWithLotList,
    isLoading: isLoadingProjectsWithLots,
    error: errorProjectsWithLots,
    isSuccess: isSuccessProjectsWithLots,
  } = useGetCampaignProjectsWithLotQuery(selectedCampaign?.id ?? skipToken);

  return (
    <>
      <MVectorLayer>
        {projectsWithLotList?.map((projectWithLot) => {
          return projectWithLot.lots.map((lot) => {
            const point = centerOfMass(lot.polygon?.geometry);
            return (
              <MPoint
                key={lot.id}
                color={projectWithLot.color}
                lat={point.geometry.coordinates[1]}
                lng={point.geometry.coordinates[0]}
                hovered={hoveredProject === projectWithLot.proyectoId}
                onLoadCenter={false}
                overlayContent={{
                  // title: ReactDOMServer.renderToString(<Title project={projectWithLot} />),
                  // content: ReactDOMServer.renderToString(<Body lot={lot} />),
                  title: overlayTitle({ project: projectWithLot }),
                  content: OverlayBody({ lot }),
                }}
              />
            );
          });
        })}
      </MVectorLayer>
    </>
  );
};
