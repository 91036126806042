import { ExtraDataResponse } from 'api/Vistaguay/ExtraData';
import Project from 'models/Project';
import { useTranslation } from 'react-i18next';

import style from './CountReportHeaderDataWithIcons.module.scss';

const ReportInfoItem = ({ fieldName, fieldValue }: any) => (
  <div>
    <span>{fieldName}</span>
    <p>{fieldValue}</p>
  </div>
);

interface ICountReportHeaderData {
  project: Project;
  conteo: any;
  extraData: ExtraDataResponse;
  selectedCampaign: any;
  lot: any;
  setOpen: (open: boolean) => void;
  open: boolean;
}

export const CountReportHeaderDataWithIcons = ({
  project,
  conteo,
  extraData,
  selectedCampaign,
  lot,
  setOpen,
  open,
}: ICountReportHeaderData) => {
  const { t } = useTranslation();
  console.log('selectedCampaign_info', selectedCampaign);
  let nameCampaign: unknown;
  if (selectedCampaign.name !== undefined) {
    nameCampaign = selectedCampaign.name;
  } else {
    const data = JSON.parse(selectedCampaign);
    nameCampaign = data.name;
  }
  const projectName = project?.nombre;
  const sowDate = conteo.fechaSiembra;
  const crop = extraData?.crops.find((c) => c.id === conteo.cultivoId)?.name || ' ';
  const estadio =
    extraData?.crops
      .find((c) => c.id === conteo.cultivoId)
      ?.stages.find((s) => s.id === conteo.estadioId)?.name || ' ';
  const hibrid =
    extraData?.crops
      .find((c) => c.id === conteo.cultivoId)
      ?.hybrids.find((h) => h.id === conteo.hibridoId)?.name || ' ';
  const sowing = extraData?.seeders.find((s) => s.id === conteo.sembradoraId)?.name;
  const sowType = conteo.tipoProceso == 1 ? 'Fija' : 'Variable';
  const distance = conteo.dSurco;
  console.log('selectedCampaign_name', selectedCampaign.name);
  const campaignName = nameCampaign;
  const lotName = lot?.name;
  const flightDate = conteo.fechaVuelo;
  const seederMeter =
    extraData?.seederMeterBrands.find((c) => c.id === conteo.seederMeterId)?.name || ' ';
  const sembradoraMetadata = extraData?.seederBrands.find((c) => c.id === conteo.seederBrandId);
  const sembradora = sembradoraMetadata?.name ? sembradoraMetadata?.name : '-';
  const sembradoraModel = sembradoraMetadata?.seederModels.find(
    (s) => s.id === conteo.seederModelId,
  )?.name;
  const sembradoraLabel = sembradoraModel ? `${sembradora}/${sembradoraModel}` : sembradora;
  const photoCount = conteo.muestrasProcesadas
    ? `${conteo.muestrasProcesadas}/${conteo.muestras}`
    : `${conteo.muestras}/${conteo.muestras}`;
  return (
    <>
      <div className={style.countDescriptionConteiner}>
        <ReportInfoItem fieldName={t('campaign')} fieldValue={campaignName} />
        <ReportInfoItem fieldName={t('hybrid')} fieldValue={hibrid} />
        <ReportInfoItem fieldName={t('Dosificador')} fieldValue={`${sowing}/${seederMeter}`} />
        <ReportInfoItem fieldName={t('Proyecto')} fieldValue={projectName} />
        <ReportInfoItem fieldName={t('fecha-de-siembra')} fieldValue={sowDate} />
        <ReportInfoItem fieldName={t('Sembradora')} fieldValue={sembradoraLabel} />
        <ReportInfoItem fieldName={t('lote')} fieldValue={lotName} />
        <ReportInfoItem fieldName={t('tipo-de-siembra')} fieldValue={`${sowType}`} />
        <ReportInfoItem fieldName={t('date-of-flight')} fieldValue={flightDate} />
        <ReportInfoItem
            fieldName={[`
              ${t('crop')} / ${t('Estadio fenológico')}`
            ]}
          fieldValue={`${crop} / ${estadio}`}
        />
        <ReportInfoItem fieldName={t('distancing')} fieldValue={distance} />
        <ReportInfoItem fieldName={t('photos')} fieldValue={photoCount} />

      </div> 
    </>
  );
};
