import Axios from 'axios';
import { API_URL } from 'services/configurations';

/**
 * Exports GeoJSON data for a specific task.
 *
 * @param {Object} options - An object containing the task ID and user token.
 * @param {string} options.taskId - The ID of the task to export GeoJSON for.
 * @param {string|null} options.token - The user token. Can be null.
 * @return {Promise<boolean>} A promise that resolves to false if the export is successful, or true if there is an error.
 */
export async function exportGeoJson({ taskId, token }: { taskId: string; token: string | null }) {
  return Axios({
    url: `${API_URL}task/${taskId}/export/geojson`,
    method: 'GET',
    responseType: 'arraybuffer', // Important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response: any) => {
      const url = new Blob([response.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(url);
      link.setAttribute('download', `plantcount-${taskId}-geojson.zip`);
      document.body.appendChild(link);
      link.click();
      return false;
    })
    .catch((e) => {
      return true;
    });
}
/**
 * Export Shapefile based on the provided task ID and token.
 * 
 * @param {string} taskId - The ID of the task.
 * @param {string | null} token - The authentication token, can be null.
 * @return {Promise<boolean>} - A promise that resolves to a boolean indicating if the export was successful.
*/
export async function exportShp({ taskId, token }: { taskId: string; token: string | null }) {
  return Axios({
    url: `${API_URL}task/${taskId}/export/shp`,
    method: 'GET',
    responseType: 'arraybuffer', // Important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response: any) => {
      const url = new Blob([response.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(url);
      link.setAttribute('download', `plantcount-${taskId}-shp.zip`);
      document.body.appendChild(link);
      link.click();
      return false;
    })
    .catch((e) => {
      return true;
    });
}
/**
 * Exports a PDF for a given task.
 *
 * @param {string} taskId - The ID of the task.
 * @param {string | null} token - The access token.
 * @return {boolean} Returns `false` if the PDF export is successful, `true` if there is an error.
 */
export async function exportPdf({ taskId, token }: { taskId: string; token: string | null }) {
  return Axios({
    url: `${API_URL}task/${taskId}/export/pdf`,
    method: 'GET',
    responseType: 'arraybuffer', // Important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response: any) => {
      const url = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(url);
      link.setAttribute('download', `plantcount-${taskId}-geojson.pdf`);
      document.body.appendChild(link);
      link.click();
      return false;
    })
    .catch((e) => {
      return true;
    });
}

/**
 * Exports a PNG file for a given task ID and token.
 *
 * @param {string} taskId - The ID of the task.
 * @param {string | null} token - The authentication token.
 * @return {Promise<boolean>} A promise that resolves to a boolean indicating if the export was successful.
 */
export async function exportPng({ taskId, token }: { taskId: string; token: string | null }) {
  return Axios({
    url: `${API_URL}task/${taskId}/export/png`,
    method: 'GET',
    responseType: 'arraybuffer', // Important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response: any) => {
      const url = new Blob([response.data], { type: 'application/png' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(url);
      link.setAttribute('download', `plantcount-${taskId}-geojson.png`);
      document.body.appendChild(link);
      link.click();
      return false;
    })
    .catch((e) => {
      return true;
    });
}
