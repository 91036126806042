import { useGetCampaignsQuery } from 'api/Vistaguay/CampaignAPI';
import Spinner from 'components/Spinner/Spinner';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import style from './Home.module.scss';

function Home() {
  const { t } = useTranslation();
  const { data, isSuccess, isLoading } = useGetCampaignsQuery({});


  return (
    <Sidebar>
      {isLoading && <Spinner />}
      {isSuccess && data?.length === 0 && (
        <SidebarBody>
          <Navigate to={'/profile/newUser'} />
        </SidebarBody>
      )}
      {isSuccess && data?.length > 0 && <Navigate to={'/campaign/search'} />}
    </Sidebar>
  );
}

export default Home;
