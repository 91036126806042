import { GeoJSON } from 'models/GeoJSON';

const shapeFileApiServer = 'http://localhost:2999';
const shapeFileApiServerInternalPath = '/';

export const getShapefile = (geoJson: GeoJSON) => {
  return fetch(`${shapeFileApiServer}${shapeFileApiServerInternalPath}`, {
    method: 'POST',
    body: JSON.stringify(geoJson),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    return response.blob();
  });
};
