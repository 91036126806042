import Button from 'components/Button/Button';

import styles from '../Menu.module.scss';

export default function DeleteLayer({ isActiveDeleteLayer }: { isActiveDeleteLayer: boolean }) {

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h5 style={{ fontSize: '15px' }}>¿Estás seguro que quieres eliminar esta capa?</h5>
        <Button text='Eliminar Capa' variant='ERROR' />
      </div>
    );
  };