import { ReactComponent as PrescriptionIcon } from 'assets/icons/prescription.svg';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import { PlantCountTask } from 'models/PlantCountTask';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  IAllTasks,
  toggleDensity,
  toggleShowPrescription,
  toggleShowPrescriptionData,
  toggleSpatialVariability,
  toggleTemporalVariability,
  VisualizationPlantCountTask,
} from 'state/slices/taskSlice';

import style from '../Contextual.module.scss';


export const handleToggleShowPrescriptionData = (dispatch: any, taskId: string, activeTab: number, setActiveTab: (index: number) => void, data: any[]) => {
  const prevActiveTabIndex = activeTab;
  dispatch(toggleShowPrescriptionData(taskId));

  let newActiveTab: number;

  if (data.length === 1) {
    newActiveTab = 0;
  } else if (activeTab === 0) {
    // If we are in the first tab and there is more than one, go to the next  
    newActiveTab = 1;
  } else if (activeTab > 0 && activeTab === data.length - 1) {
    // If we are in the last tab, go to the previous one
    newActiveTab = prevActiveTabIndex - 1;
  } else {
    // if we are in another tab, go to the first tab
    newActiveTab = 0;
  }

  // Activar la nueva pestaña
  setTimeout(() => {
    setActiveTab(newActiveTab);
  }, 0);
};


export default function LayersOption({ task }: { task: IAllTasks }) {
  const dispatch = useAppDispatch();
 
  const allTasks = useAppSelector((state) => state.task.tasks)
  const filteredTask = allTasks.find((t: IAllTasks) => t?.taskId === task?.taskId);
  const taskData = filteredTask?.task as PlantCountTask
  const taskVisualization = filteredTask?.visualization as VisualizationPlantCountTask


  const handleToggle = (type: 'density' | 'spatial' | 'temporal' | 'prescription' | 'prescriptionData') => {
    switch (type) {
      case 'density':
        dispatch(toggleDensity(task.taskId));
        break;
      case 'spatial':
        dispatch(toggleSpatialVariability(task.taskId));
        break;
      case 'temporal':
        dispatch(toggleTemporalVariability(task.taskId));
        break;
      case 'prescription':
        dispatch(toggleShowPrescription(task.taskId));
        break;
      case 'prescriptionData':
        dispatch(toggleShowPrescriptionData(task.taskId));
        break;
      default:
        break;
    }
  };

  if (!task) {
    return (
      <div className={style.body}>
        <div className={style.contextualButton}>
          <p>La tarea no se encuentra</p>
        </div>
      </div>
    );
  }

  return (
    <div className={style.body}>
      <div className={style.contextualButton}>
        <p>Densidad</p>
        <div className={style.actions}>
          <SwitchButton value={taskVisualization.density} handleOnChange={() => handleToggle('density')}/>
        </div>
      </div>
      <hr style={{margin: '5px 0 5px 0'}}/>

      <div className={style.contextualButton}>
        <p>Espaciamiento</p>
        <div className={style.actions}>
          <SwitchButton value={taskVisualization.temporalVariability} handleOnChange={() => handleToggle('temporal')}/>
        </div>
      </div>
      <hr style={{margin: '5px 0 5px 0'}}/>

      <div className={style.contextualButton}>
        <p>Cobertura</p>
        <div className={style.actions}>
          <SwitchButton value={taskVisualization.spatialVariability} handleOnChange={() => handleToggle('spatial')}/>
        </div>
      </div>

      {taskData.tipoProceso == 2 &&   <hr style={{margin: '5px 0 5px 0'}}/>}

      {taskData.tipoProceso == 2 && (
        <div className={style.contextualButton}>
          <div style={{width:'100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <p>Prescripción (Rx)</p>
            <PrescriptionIcon
              className={style.icon}
              fill={taskVisualization.showPrescriptionData ? style.verdeVistaguay : style.gris}
              onClick={() => handleToggle('prescriptionData')}
              height={18}
            />
          </div>
          
          <div className={style.actions}>
            <SwitchButton 
              value={taskVisualization.spatialVariability} 
              handleOnChange={() => 
              handleToggle('prescription')}
            />
          </div>
        </div>
      )}
    </div>
  );
}
