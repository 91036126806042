import { ReactComponent as Download } from 'assets/icons/dowload.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico-info.svg';
import { ReactComponent as LayersIcon } from 'assets/icons/ico-layers.svg';
import { ReactComponent as Report } from 'assets/icons/report.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/shareIcon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashIcon.svg';
import { PlantCountTask } from 'models/PlantCountTask';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAllTasks, VisualizationPlantCountTask } from 'state/slices/taskSlice';

import style from './Contextual.module.scss';
import EditOption from './Options/EditOption';
import ExportOption from './Options/ExportOption';
import LayersOption from './Options/LayersOption';
import ShareOption from './Options/ShareOption';
import TrashOption from './Options/TrashOption';

export default function PqContextual({
  projectId,
  task,
  extradata
}: {
  projectId: string;
  task: IAllTasks;
  extradata: any, // change type later
}) {
  const [option, setOption] = useState(0);
  const [ menuContext, setMenuContext ] = useState({
    report: false,
    layers: false,
    download: false,
    share: false, 
    info: true,
    trash: false
  })
  const [showEditButton, setShowEditButton] = useState(false);
  const navigate = useNavigate();

  const PqTask = task?.task as PlantCountTask;
  const taskStatus = PqTask?.estadoId; 
  
  const taskView = task;
  const visualization = taskView.visualization as VisualizationPlantCountTask;
  
  useEffect(() => {
    if(taskStatus == 2) {
      // PUBLICADO
      setOption(0)
      menuContext.report = true; 
      menuContext.layers = true; 
      menuContext.download = true;
      menuContext.share = false;
      menuContext.trash = false; 
      setMenuContext(menuContext)
      setShowEditButton(true);
    } else {
      menuContext.download = false;
      setMenuContext(menuContext)
      setOption(3)
    }
  }, [taskStatus])

  const showName  = true;
  const options = [
    <LayersOption task={taskView} key={0} />,
    <ExportOption key={1} task={taskView} taskId={task.taskId}/>,
    <ShareOption task={taskView} key={2} showName ={showName } />,
    <EditOption task={task.task} taskId={task.taskId} key={3} extradata={extradata} showEditButton={showEditButton} backTo={(key:any) => handleChangeMenuOption(key)}/>,
    <TrashOption taskId={task.taskId} key={4}/>,
  ];

  const handleChangeMenuOption = (key: any) => {
    switch (key) {
      case 0:
        setOption(0);
        break;
      case 1:
        setOption(1);
        break;
      case 2:
        setOption(2);
        break;
      case 3:
        setOption(3);
        break;
      case 4:
        setOption(4);
        break;
      default:
        break;
    }
  }

  return (
    <div className={style.contextualMenu}>
      {options[option]}
      <div className={style.tabBar}>
        <div className={style.tabIcons}>
          {/* LAYERS */}
          <div
            className={option == 0 ? style.bubble : style.bubbleOff}
            onClick={() => {
              if (menuContext.layers) {
                setOption(0);
              }
            }}
          >
            <LayersIcon
              style={{
                stroke: menuContext.layers ? style.azul : style.lightdisabled
              }}
              className={style.tabicon}
            />
          </div>

          {/* REPORT */}
          <div
            className={style.bubbleOff}
            onClick={() => {
              if (menuContext.report) {
                navigate(`/projects/${projectId}/lots/${task.lotId}/plantCount/${task.taskId}`);
              }
            }}
          >
            <Report 
              style={{
                stroke: menuContext.report ? style.azul : style.lightdisabled
              }}
              className={style.tabicon}
            />
          </div>
          
          {/* EXPORT */}
          <div
            className={option == 1 ? style.bubble : style.bubbleOff}
            onClick={() => {
              if (menuContext.download) {
                setOption(1);
              }
            }}
          >
            <Download
              style={{
                fill: menuContext.download ? style.azul : style.lightdisabled,
              }}
              className={style.tabicon}
            />
          </div>

          {/* INFO/EDIT */}
          <div
            className={option == 3 ? style.bubble : style.bubbleOff}
            onClick={() => setOption(3)}
          >
            <InfoIcon className={style.tabicon} />
          </div>

          {/* SHARE */}
          <div
            className={option == 2 ? style.bubble : style.bubbleOff}
            onClick={() => {
              if (menuContext.share) {
                // setOption(2);
              }
            }}
          >
            <ShareIcon
              style={{
                fill: menuContext.share ? style.azul : style.lightdisabled,
              }}
              className={style.tabicon}
            />
          </div>

          {/* TRASH */}
          <div
            className={option == 4 ? style.bubble : style.bubbleOff}
            // onClick={() => setOption(4)}
          >
            <TrashIcon 
              style={{
                fill: menuContext.trash ? style.azul : style.lightdisabled,
              }}
              className={style.tabicon} 
            />
          </div>
        </div>
      </div>
    </div>
  );
}
