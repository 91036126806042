import { DatePicker, Input } from 'components/Inputs/Input';
import { useCallback, useState } from 'react';

import styles from './MenuItem.module.scss';


export function MenuItem ({
    title, 
    inputValue = '',
    option = {value: '', name: ''},
    editActive = false,
    inputType = 'input',
    handleChange,
    listItems = []
} : {
    title : string, 
    inputValue?: any,
    option?: {value: string, name:string} | null,
    editActive?: boolean, 
    inputType?: 'input' | 'dropdown' | 'datepicker',
    handleChange?: any,
    listItems?: Array<{name: string, id:string}>
}) { 
    const memoizedHandleChange = useCallback((value: any) => {
        handleChange(value);
    }, [handleChange]);

    const InputComp = () => {
        return (
            <Input 
                className={styles.menuInputWraper}
                inputStyle={styles.menuItemInput}
                onChange={(e) => handleChange(inputValue)}
                placeholder={inputValue}
                value={inputValue}
            />
        )
    }

    const InputComponent = useCallback(() => {
        switch (inputType) {
            case 'dropdown':
                return (
                    <select
                        className={styles.menuItemInput}
                        id={title}
                        value={option?.value}
                        onChange={(e) => memoizedHandleChange(e.target.value)}
                    >
                        {listItems && listItems.map(opt => (
                            <option key={opt.id + opt.name} value={opt.id}>{opt.name}</option>
                        ))}
                    </select>
                );

            case 'datepicker':
                return (
                    <DatePicker
                        label=''
                        onChange={(date) => memoizedHandleChange(date)}
                        value={new Date(inputValue)}
                        datepickerInputStyle={styles.menuItemInput}
                    />
                );

            default:
                return null;
        }
    }, [inputType, inputValue, option, title, listItems, memoizedHandleChange]);

    return (
        <div>
            <div style={{display: 'flex', justifyContent:'space-between'}} >
                <p style={{textAlign: 'left', width: '180px'}}>{title}</p>
                {
                    editActive ? (
                        <>
                            { inputType !== 'input' ? (
                                <InputComponent />
                            ):(
                                <Input 
                                    className={styles.menuInputWraper}
                                    inputStyle={styles.menuItemInput}
                                    onChange={(e) => handleChange(e.target.value)}
                                    placeholder={inputValue}
                                    value={inputValue}
                                />
                            )}
                        </>
                    ) : (
                        <p style={{textAlign: 'right', width: '120px'}}>{option?.name || inputValue}</p>
                    )
                }
            </div>
            <hr style={{margin: '5px 0 5px 0'}}/>
        </div>
    )
}