// #region Imports
// #region React & Hooks
// #endregion
// #region Queries
import { useGetLotQuery, useGetPrescriptionsQuery } from 'api/Vistaguay/LotAPI';
import { PrescriptionPolygons } from 'components/Domain/Prescription/PrescriptionPolygons';
import { MMission } from 'components/Map/Components/MMission';
// #endregion
// #region Components
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import MPolygon from 'components/Map/Components/Polygon/MPolygon';
// #endregion
// #region Models
import { MappingMission, ScoutingMission } from 'models/Mission';
import { PlantCountTask } from 'models/PlantCountTask';
import { Prescription } from 'models/Prescription';
import { TASK_TYPE } from 'models/Task';
import { CountMapLegend } from 'pages/TaskView/TaskViewMap/CountMapLegend';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
// #endregion
// #region States
import { setPresciptionToShow } from 'state/slices/mapSlice';
import {
  clearTasks,
  IAllTasks,
  VisualizationMission,
  VisualizationPlantCountTask,
} from 'state/slices/taskSlice';
// #endregion
// #region Styles
import { ZIndexLot } from 'styles/pointStyle';
import { LINE, LOT_COLOR } from 'styles/variables';

// #endregion
// #region Utils
import { getStatus } from '../CountTaskListItem';
import { PrescriptionModal2 } from '../PrescriptionModal2';
import { MosaicsMaps } from './MosaicMap/MosaicMap';
import PhotoPoints from './PhotoPoints/PhotoPoints';
import style from './TaskListMap.module.scss';
// #endregion
// #endregion


// const BACKEND_URL = 'localhost:3000';
const BACKEND_URL = process.env.REACT_APP_VISTAGUAY_BACKEND_URL;

const getImageUrl = (taskId: string, lotId: string, fileName: string) => {
  return `http://${BACKEND_URL}/task/${taskId}/photos/name/${fileName}?lotId=${lotId}`;
};

export function TaskListMap() {
  // #region Hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // #endregion

  // #region Params
  const { projectId, lotId } = useParams<{ projectId: string; lotId: string }>();
  
  if (!projectId || !lotId) {
    return null;
  }
  // #endregion

  // #region Redux States
  const tasks: Array<IAllTasks> = useAppSelector((state): Array<IAllTasks> => state.task.tasks);
  const showLot: boolean = useAppSelector((state) => state.map.showLot);
  const { 
    showPrescription, 
    showPrescriptionTable,
    selectedPrescription 
  } = useAppSelector((state) => state.prescription)
  // #endregion

  // #region Queries
  const { data: lotData } = useGetLotQuery({ projectId: +projectId, lotId: +lotId });
  const { data: prescriptionsData } = useGetPrescriptionsQuery({
    lotId: +lotId,
    projectId: +projectId,
  });
  // #endregion

  // #region Effects
  useEffect(() => {
    return function cleanup() {
      dispatch(clearTasks());
      dispatch(setPresciptionToShow(-1));
    };
  }, [dispatch]);
  // #endregion


  return (
    <>
      { prescriptionsData && tasks.map((task: IAllTasks, index: number) => {
        const arrayLenght = tasks.length - 1 - index; // invert array render
        const iteration = arrayLenght + 1;
        const c1 = arrayLenght + 1 + iteration;
        const c2 = arrayLenght + iteration;

        if ((
          task.task as any).estadoId && 
          getStatus((task.task as any).estadoId) !== 'Publicado'
        ) {
          return null;
        }

        if (task.type == TASK_TYPE.conteo) {
          const data = task.task as PlantCountTask;
          const visualization = task.visualization as VisualizationPlantCountTask;
          const prescriptionToShow: Prescription | undefined = prescriptionsData.find((p) => p.id == data.prescriptionId);
          
          return (
            <div key={task.taskId + task.lotId}>
              { task.visualization?.visible && <CountMapLegend />}
              
              <MVectorLayer
                isVisible={visualization.visible}
                layerName={task.name + TASK_TYPE[task.type]}
                zIndex={100000}
              >
                {/* photo metadata */}
                <PhotoPoints
                  zIndex={c1}
                  taskId={task.taskId}
                  activeDensity={visualization.density}
                  activeSpatialVariability={visualization.spatialVariability}
                  activeTemporalVariability={visualization.temporalVariability}
                />
              </MVectorLayer>

              {visualization.showPrescription && prescriptionToShow && (
                <MVectorLayer
                  layerName={task.name + TASK_TYPE[task.type] + 'Prescription'}
                  zIndex={10}
                  key={`prescription${task.taskId}`}
                  isVisible={visualization.visible}
                >
                  {/* prescription task */}
                  <PrescriptionPolygons prescription={prescriptionToShow} />
                </MVectorLayer>
              )}
            </div>
          );
        } else if (task.type == TASK_TYPE.mission) {
          const data = task.task as MappingMission | ScoutingMission;
          const visualization = task.visualization as VisualizationMission;

          let prescriptionMission = null;
          if(data.type == 'SCOUTING' && visualization.visible) {
            prescriptionMission = prescriptionsData.find(
              (p) => p.id == (data as ScoutingMission).prescriptionId,
            );
          }
          
          return (
            <div key={task.taskId + task.lotId + 'pres-mission'}>
              <MVectorLayer
                key={task.taskId + task.lotId}
                layerName={task.name + TASK_TYPE[task.type]}
                isVisible={visualization.showFlightPlan}
                zIndex={100000}
              >
                <MMission mission={data} zIndex={c1} isVisible={visualization.showFlightPlan}/>
              </MVectorLayer>
              
              {data.type == 'SCOUTING' &&
                visualization.showPrescription &&
                prescriptionMission && (
                  <MVectorLayer
                    key={task.taskId + task.lotId + 'prescripcion'}
                    layerName={task.name + TASK_TYPE[task.type]}
                    isVisible={visualization.showPrescription}
                    zIndex={c2}
                  >
                    <PrescriptionPolygons prescription={prescriptionMission} />
                  </MVectorLayer>
                )}
            </div>
          );
        }
      })}

      {/* lot */}
      <MVectorLayer isVisible={showLot} zIndex={ZIndexLot}>
        <MPolygon 
          onLoadCenter={true} 
          polygon={lotData?.polygon?.geometry} 
          color={LOT_COLOR} 
          stroke={LINE} 
          height={3}
        />
      </MVectorLayer>

      {/* Show Lot Prescription */}
      <MVectorLayer isVisible={showPrescription} zIndex={1}>
        {showPrescription && selectedPrescription && (
          <PrescriptionPolygons prescription={selectedPrescription} />
        )}
      </MVectorLayer>

      {/* { prescriptionsData && tasks &&
        <PrescriptionsModals 
          prescriptionsData={prescriptionsData as Prescription[]} 
          tasks={tasks} 
        />
      } */}

      {/* Show prescription modal table with multiple tabs */}
      { prescriptionsData && 
        selectedPrescription && 
        showPrescription && (
          <PrescriptionModal2 
            prescriptions={prescriptionsData as Prescription[]} 
            selectedPrescriptionId={selectedPrescription.id}
            showPrescription={showPrescription}
          />
      )}
      
      <MosaicsMaps />
    </>
  );
}

// const PrescriptionsModals = ({
//   prescriptionsData,
//   tasks,
// }: {
//   prescriptionsData: Prescription[];
//   tasks?: IAllTasks[];
// }) => {
//   if (!prescriptionsData) return null;
//   if (!tasks) return null;



//   return <PrescriptionModal prescriptions={prescriptionsData} tasks={tasks} />;
// };

// #region Overlay Content 
export const overlayTitle = ({
  photoMetadata,
  lotId,
  taskId,
}: {
  photoMetadata: any;
  lotId: string;
  taskId: string;
}) => {
  return `<div class=${style.popupTitle}>
      <a href='${getImageUrl(taskId, lotId, photoMetadata.fileName)}' target="_blank"><h1>${
    photoMetadata.fileName
  }</h1></a>
    </div>`;
};

export const OverlayBody = ({ photoMetadata }: { photoMetadata: any }) => {
  return (
    <div className={style.popupBody}>
      <pre>{JSON.stringify(photoMetadata, null, 2)}</pre>
    </div>
  );
};
 // #endregion