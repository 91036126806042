import { GeoJSONFeatureCollection } from 'models/GeoJSON';

import { API_URL } from './configurations';

export function cuitPatternMatch(input: string | undefined, template: string): string {
    try {
      let j = 0;
      let plaintext = '';
      let countj = 0;
      if (input) {
        while (j < template.length) {
          if (countj > input.length - 1) {
            template = template.substring(0, j);
            break;
          }
  
          if (template[j] == input[j]) {
            j++;
            countj++;
            continue;
          }
  
          if (template[j] == 'x') {
            template = template.substring(0, j) + input[countj] + template.substring(j + 1);
            plaintext = plaintext + input[countj];
            countj++;
          }
          j++;
        }
      } else {
        return '';
      }
  
      return template;
    } catch {
      return '';
    }
}

export function splitFirstOccurrence({str, delimiter}: {str: string, delimiter: string}) {
  const idx = str.indexOf(delimiter);
  if(idx === -1 ){
    return [str];
  }

  const firstPart = str.substring(0, idx);
  const secondPart = str.slice(idx + delimiter.length);
  return [firstPart, secondPart];
}

// #region Date
export function formattedDate(inputDate: string) {
  const date = new Date(inputDate);

  if (isNaN(date.getTime())) {
    // Handle invalid date case
    return 'Invalid Date';
  }

  const day = ('0' + date.getUTCDate()).slice(-2);
  const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
  const year = date.getUTCFullYear().toString();

  return `${day}-${month}-${year}`;
}
// #endregion

// #region File Reading
export const getFileExtension = (filename: string) => {
  return filename.split('.').pop();
};

export const isGeoJson = (fileExtension: string) => {
  return fileExtension.toLowerCase().includes('json');
};

export const isShapeFile = (fileExtension: string) => {
  return fileExtension.toLowerCase().includes('zip');
};

export const readFileAsAJson = ({
  file, 
  token,
}: {
  file: File,
  token: string | null,
}): Promise<GeoJSONFeatureCollection> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const extension = getFileExtension(file.name);
    if (!extension) return reject('Archivo inválido');

    if (isGeoJson(extension)) {
      fileReader.onload = function () {
        try {
          resolve(JSON.parse(fileReader.result as string));
        } catch (error) {
          reject('Archivo inválido');
        }
      };
      fileReader.readAsText(file);
    } else if (isShapeFile(extension) && token) {
      fileReader.onload = function () {
        try {
          const foromData = new FormData();
          foromData.append('file', new Blob([fileReader.result as ArrayBuffer]));
          fetch(`${API_URL}projects/{projectId}/lots/{lotId}/prescriptions/shapefile`, {
            method: 'POST',
            headers: {
              accept: '*/*',
              Authorization: `Bearer ${token}`,
            },
            body: foromData,
          })
            .then((response) => {
              resolve(response.json());
            })
            .catch((error) => {
              reject('Archivo inválido');
            });
        } catch (error) {
          reject('Archivo inválido');
        }
      };
      fileReader.readAsArrayBuffer(file);
    } else {
      reject('Archivo inválido');
    }
    fileReader.onerror = (error) => reject(error);
});

// #endregion

export function checkPassRule ({
  str,
  minLength,
  maxLength,
  specialCharacter,
  uppercase,
}: {
  str: string,
  minLength?: number,
  maxLength?: number,
  specialCharacter?: boolean,
  uppercase?: boolean
}) {
  const specialCharRegExp = /[!@#$%^&*(),.?":{}|<>]/;
  const uppercaseRegExp = /[A-Z]/;

  let min = false;
  let max = false;
  let hasSpecialCharacter = false;
  let hasUppercase = false;

  if(minLength) { min = str.length >= minLength;}
  if(maxLength) { max = str.length <= maxLength;}
  if(specialCharacter) {hasSpecialCharacter = specialCharRegExp.test(str);}
  if(uppercase) {hasUppercase = uppercaseRegExp.test(str);}
  
  return {min, max, hasSpecialCharacter, hasUppercase}
}

export function checkValidEmail ({email}: {email: string}) {
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return emailRegExp.test(email);
}