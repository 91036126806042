import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalGlobalState } from 'components/Modals/ModalGlobalComponent';
import { Prescription } from 'models/Prescription';


export interface UiState {
  hoveredLotId: number | null;
  currentProjectId: number | null;
  hoveredProjectId: number | null;
  showPrescriptionTable: boolean; 
  collapsePrescriptionTable: boolean;
  selectedPrescriptionId: number | null;
  selectedPrescription: Prescription | null;
  selectedPrescriptionChanged: boolean;
  prescriptionTheme: Array<string>;
  modalGlobalState: ModalGlobalState
}

const initialState: UiState = {
  modalGlobalState: {
    isOpen: false,
    type: null
  },
  hoveredLotId: null,
  currentProjectId: null,
  hoveredProjectId: null,
  selectedPrescriptionId: null,
  collapsePrescriptionTable: false,
  selectedPrescription: null,
  selectedPrescriptionChanged: false,
  showPrescriptionTable: false,
  prescriptionTheme: [
    'rgba(193, 39, 45, 0.6)',
    'rgba(241, 90, 36, 0.6)',
    'rgba(247, 147, 30, 0.6)',
    'rgba(110, 0, 52, 0.6)',
    'rgba(34, 181, 115, 0.6)',
    'rgba(0, 96, 104, 0.6)',
    'rgba(131, 139, 197, 0.6)',
    'rgba(41, 171, 226, 0.6)',
    'rgba(25, 93, 230, 0.6)',
    'rgba(46, 49, 146, 0.6)',
  ]
} as UiState;

const reducer = {
  setHoveredLotId: (state: UiState, action: PayloadAction<number | null>) => {
    state.hoveredLotId = action.payload;
  },
  setHoveredProjectId: (state: UiState, action: PayloadAction<number | null>) => {
    state.hoveredProjectId = action.payload;
  },
  setCurrentProjectId: (state: UiState, action: PayloadAction<number | null>) => {
    state.currentProjectId = action.payload;
  },
  setSelectedPrescriptionId: (state: UiState, action: PayloadAction<number | null>) => {
    state.selectedPrescriptionId = action.payload;
  },
  setSelectedPrescription: (state: UiState, action: PayloadAction<Prescription | null>) => {
    state.selectedPrescription = action.payload;
  },
  setSelectedPrescriptionChanged: (state: UiState, action: PayloadAction<boolean>) => {
    state.selectedPrescriptionChanged = action.payload;
  },
  setShowPrescriptionTable: (state: UiState, action: PayloadAction<boolean>) => {
    state.showPrescriptionTable = action.payload;
  },
  setCollapsePrescriptionTable: (state: UiState, action: PayloadAction<boolean>) => {
    state.collapsePrescriptionTable = action.payload;
  },
  setModalGlobalState: (state: UiState, action: PayloadAction<ModalGlobalState>) => {
    state.modalGlobalState = action.payload;
  }
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: reducer,
});
export const {
  setHoveredLotId,
  setHoveredProjectId,
  setCurrentProjectId,
  setSelectedPrescriptionId,
  setSelectedPrescription,
  setSelectedPrescriptionChanged,
  setShowPrescriptionTable,
  setCollapsePrescriptionTable,
  setModalGlobalState
} = uiSlice.actions;

export default uiSlice.reducer;
