import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, Polygon, Properties } from '@turf/turf';
import { GeoJSONFeature } from 'models/GeoJSON';
import Lot from 'models/Lot';
import { MappingMission, ScoutingMission } from 'models/Mission';
import { Point } from 'models/Point';
import { Prescription } from 'models/Prescription';
import { IMappingMissionParams } from 'pages/Tasks/Volare/Mapping/MappingMissionForm';
import { ScoutingMissionParams } from 'pages/Tasks/Volare/Scouting/ScoutingSidebar';

export interface IAction {
  action: string;
  value: number;
  index: number;
}
export interface IWaypoint {
  altitude: number;
  speed: number;
  actions: IAction[];
}

export interface IMission {
  waypoints: IWaypoint[];
}

export interface IFlightPlanInfo {
  minutes: string;
  area: string;
  photos: number;
  bateries: number;
}
export interface VolareState {
  flightPlanInfo: IFlightPlanInfo | null;
  currentMission: MappingMission | ScoutingMission | null;
  missionBuilderParams: IMappingMissionParams | ScoutingMissionParams | null;
  differentialAltitude: boolean;
  mappingPoints: number[][];
  mappingFirstLastPoints: number[][];
  scoutingPoints: any[];
  missionPolygon: Feature<Polygon, Properties | undefined> | null;
  currentPoint: any;
  currentLot: Lot | null;
  prescription: Prescription | null;
  editMissionPolygon: boolean;
}

const initialState: VolareState = {
  flightPlanInfo: null,
  currentMission: null,
  missionBuilderParams: null,
  differentialAltitude: false,
  mappingPoints: [],
  mappingFirstLastPoints: [],
  scoutingPoints: [],
  missionPolygon: null,
  currentPoint: null,
  currentLot: null,
  editMissionPolygon: false,
  prescription: null
};

const reducer = {
  setMissionBuilderParams: (
    state: VolareState,
    action: PayloadAction<IMappingMissionParams | ScoutingMissionParams | null>,
  ) => {
    state.missionBuilderParams = action.payload;
  },
  setMissionPolygon: (
    state: VolareState,
    action: PayloadAction<Feature<Polygon, Properties | undefined> | null>,
  ) => {
    state.missionPolygon = action.payload;
  },
  setMappingPoints: (state: VolareState, action: PayloadAction<number[][]>) => {
    state.mappingPoints = action.payload;
  },
  setMappingFirstLastPoints: (state: VolareState, action: PayloadAction<number[][]>) => {
    state.mappingFirstLastPoints = action.payload;
  },
  setFlightPlanInfo: (state: VolareState, action: PayloadAction<IFlightPlanInfo>) => {
    state.flightPlanInfo = action.payload;
  },
  setCurrentMission: (state: VolareState, action: PayloadAction<MappingMission|ScoutingMission|null>) => {
    state.currentMission = action.payload;
  },
  setCurrentPoint: (state: VolareState, action: PayloadAction<any>) => {
    state.currentPoint = action.payload;
  },
  setScoutingPoints: (state: VolareState, action: PayloadAction<any[]>) => {
    state.scoutingPoints = action.payload;
  },
  setCurrentLot: (state: VolareState, action: PayloadAction<Lot | null>) => {
    state.currentLot = action.payload;
  },
  setDiffentialAltitude: (state: VolareState, action: PayloadAction<boolean>) => {
    state.differentialAltitude = action.payload;
  },
  setPrescription: (state: VolareState, action: PayloadAction<Prescription | null>) => {
    console.log('volare slice: pres ->', action.payload)
    state.prescription = action.payload;
  },
  setEditMissionPolygon: (state: VolareState, action: PayloadAction<boolean>) => {
    state.editMissionPolygon =action.payload;
  }
};

export const volareSlice = createSlice({
  name: 'volare',
  initialState,
  reducers: reducer,
});

export const {
  setMissionBuilderParams,
  setMissionPolygon,
  setMappingPoints,
  setFlightPlanInfo,
  setCurrentMission,
  setCurrentPoint,
  setScoutingPoints,
  setCurrentLot,
  setDiffentialAltitude,
  setPrescription,
  setEditMissionPolygon,
  setMappingFirstLastPoints
} = volareSlice.actions;

export default volareSlice.reducer;
