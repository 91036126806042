import { CheckBox } from 'components/CheckBox/CheckBox';
import { TextArea } from 'components/Inputs/Input';

import styles from '../Menu.module.scss';

export default function EditLayer({ isActiveEditLayer }: { isActiveEditLayer: boolean }) {

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'start'
      }}>
        <label htmlFor="">Nombre</label>
        <input placeholder={'Nombre'} style={{ backgroundColor: '#00FF0000', border: '2px solid #d6d6d6', color: 'white' }} />
        <label htmlFor="">Descripcion</label>
        <TextArea placeholder='Descripcion' sx={{ padding: '1px 2px', backgroundColor: '#00FF0000', color: 'white' }} />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '5px'
        }}>
          <h5 style={{ fontSize: '10px', margin: '0' }}>Capa base de Intersección</h5>
          <CheckBox sx={{ }}/>
        </div>
        <hr style={{ margin: '3px 0' }} />
      </div>
    );
  };