import User from './User';



export enum NOTIFICATION_TYPE {
    WITH_LINK = 0,
    NO_LINK = 1,
}

export enum NOTIFICATION_STATUS {
    SUCCESS = 0,
    PENDING = 1,
    ERROR = 2,
}

export class Notification {
    id: number;
    status: NOTIFICATION_STATUS;
    title: string;
    message: string;
    createdAt: string;
    checked: boolean
    link: string;
    type: NOTIFICATION_TYPE;
    user?: User;
    constructor(
        id: number,
        usuarioId: number,
        status: NOTIFICATION_STATUS,
        title:string,
        message: string,
        createdAt: string,
        checked: boolean,
        link: string,
        type: NOTIFICATION_TYPE,
    ) {
        this.id = id;
        this.status = status;
        this.title = title
        this.message = message;
        this.createdAt = createdAt;
        this.checked = checked;
        this.link = link;
        this.type = type
    }
}