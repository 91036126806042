import * as turf from '@turf/turf';
import { Units } from '@turf/turf';

import { Polygon } from '../models/Polygon';

const unit = 'meters';
const MIN_CELL_SIDE = 0.015;

export const cameras = 
{
 'MAVIC_PRO' :  { name : 'Mavic Pro' ,  hfov: 66.6, vfov: 52.5 },
 'MAVIC_2_ENTERPRISE' :  { name : 'Mavic 2 Enterprise' ,  hfov: 72.5, vfov: 57.6 },
 'MAVIC_2_PRO' :  { name : 'Mavic 2 Pro' ,  hfov: 67.0, vfov: 47.6 },
 'MAVIC_2_ZOOM' :  { name : 'Mavic 2 Zoom' ,  hfov: 70.6, vfov: 55.9 },
 'MAVIC_AIR': { name: 'Mavic Air', hfov: 72.5, vfov: 57.6 },
 'MAVIC_AIR_2': { name: 'Mavic Air 2', hfov: 71.5, vfov: 56.8 },
 'MAVIC_AIR_2S' :  { name : 'Mavic Air 2S' ,  hfov: 80.2, vfov: 50.7 },
 'PHANTOM_3_4K' :  { name : 'Phantom 3 4K' ,  hfov: 81.3, vfov: 65.5 },
 'PHANTOM_3_ADVANCE' :  { name : 'Phantom 3 Advance' ,  hfov: 81.3, vfov: 65.5 },
 'PHANTOM_3_PROFESSIONAL' :  { name : 'Phantom 3 Professional' ,  hfov: 81.3, vfov: 65.5 },
 'PHANTOM_3_STANDARD' :  { name : 'Phantom 3 Standard' ,  hfov: 81.3, vfov: 65.5 },
 'PHANTOM_4' :  { name : 'Phantom 4' ,  hfov: 81.3, vfov: 65.5 },
 'PHANTOM_4_ADVANCE' :  { name : 'Phantom 4 Advance' ,  hfov: 71.5, vfov: 56.8 },
 'PHANTOM_4_PRO' :  { name : 'Phantom 4 Pro' ,  hfov: 71.5, vfov: 56.8 },
 'PHANTOM_4_PRO_V2' :  { name : 'Phantom 4 Pro V2' ,  hfov: 71.5, vfov: 56.8 },
 'ZENMUSE_X3' :  { name : 'Zenmuse X3' ,  hfov: 81.3, vfov: 65.5 },
 'ZENMUSE_X4S' :  { name : 'Zenmuse X4S' ,  hfov: 71.5, vfov: 56.8 },
 'ZENMUSE_X5' :  { name : 'Zenmuse X5 - DJI MFT 15 mm F1.7 ASPH' ,  hfov: 60.3, vfov: 47.1 },
 'ZENMUSE_X5R' :  { name : 'Zenmuse X5R - DJI MFT 15 mm F1.7 ASPH' ,  hfov: 60.3, vfov: 47.1 },
 'ZENMUSE_X5S' :  { name : 'Zenmuse X5S - DJI MFT 15 mm F1.7 ASPH' ,  hfov: 60.3, vfov: 47.1 },
 'ZENMUSE_X7_16MM' :  { name : 'Zenmuse X7 - DJI DL-S 16mm F2.8 ND ASPH' ,  hfov: 48.8, vfov: 28.8 },
 'ZENMUSE_X7_24MM' :  { name : 'Zenmuse X7 - DJI DL 24mm F2.8 LS ASPH' ,  hfov: 33.6, vfov: 19.4 },
 'ZENMUSE_X7_35MM' :  { name : 'Zenmuse X7 - DJI DL 35mm F2.8 LS ASPH' ,  hfov: 23.4, vfov: 13.4 },
 'ZENMUSE_X7_50MM' :  { name : 'Zenmuse X7 - DJI DL 50mm F2.8 LS ASPH' ,  hfov: 16.5, vfov: 9.4 },
 'MICASENSE_REDEDGE_MX': { name: 'MicaSense RedEdge MX', hfov: 47.0, vfov: 37.0 },
 'MICASENSE_ALTUM': { name: 'MicaSense Altum', hfov: 48.0, vfov: 37.0 },
}

type CameraKey = keyof typeof cameras;

const getCameraVFOV = (camera: CameraKey) => {

  return cameras[camera].vfov;
};

const getCameraHFOV = (camera: CameraKey) => {

  return cameras[camera].hfov;
};

const gradosToRadianes = function (grados: number) {
  return grados * (Math.PI / 180);
};
const formatTwoDigitsInteger = (number: number) => ('0' + number).slice(-2);

const secondsToMinuteSecondsString = (seconds: number) => {
  const s = Math.ceil(seconds);
  const res = `${formatTwoDigitsInteger(Math.floor(s / 60))}:${formatTwoDigitsInteger(s % 60)}`;
  return res;
};

export const getCellWidthInMeters = function (
  flightAltitude: number,
  cameraHorizontalFieldofView: number,
  lateralOverlap: number,
) {
  return (
    (1.0 - lateralOverlap) *
    Math.tan(gradosToRadianes(cameraHorizontalFieldofView / 2.0)) *
    2.0 *
    flightAltitude
  );
};

export const calculateTimeBetweenPicturesInSeconds = function (
  cameraVerticalFieldofView: number,
  flightAltitude: number,
  frontOverlap: number,
  velocity: number,
) {
  return (
    calculateDistanceBetweenPicturesInMeters(
      cameraVerticalFieldofView,
      flightAltitude,
      frontOverlap,
    ) / velocity
  );
};

function calculateDistanceBetweenPicturesInMeters(
  cameraVerticalFieldofView: number,
  flightAltitude: number,
  frontOverlap: number,
) {


  return (
    2 *
    flightAltitude *
    Math.tan(gradosToRadianes(cameraVerticalFieldofView / 2.0)) *
    (1 - frontOverlap)
  );
}

/**
 *
 * SIZE OF THE CELL IN FUNCTION OF THE HEIGHT
 * To determine this function, values obtained from the DroneDeploy application were taken.
 * We generated a mapping mission from various heights and obtained the following data:
 * Height (feet) / Cell (meters)
 * 200 25.58
 * 160 20.31
 * 100 13.17
 * 50 6.40
 * We saw that these values are in a linear relationship, and that the function that
 * relate is 'cell = heightEnMeters * 0.42'
 *
 * @param {Float} heightInKilometers
 */
const HEIGHT_TO_CELL_SIZE_FACTOR = 0.42;
export function getCellSize(heightInKilometers: number) {
  return heightInKilometers * HEIGHT_TO_CELL_SIZE_FACTOR; // empirical value
}

/**
 * METERS BETWEEN CONSECUTIVE PHOTOS DEPENDING ON THE HEIGHT
 * To determine this function, values obtained from the DroneDeploy application were taken
 * We generated a mapping mission from various heights and obtained the following data:
 * Height (feet) / Distance between images (meters)
 * 200 14.81
 * 180 13.33
 * 160 11.90
 * 100 7.43
 * 50 3.78
 * We saw that these values are in a linear relationship, and that the function that
 * relate is 'metersEntreImagenes = alturaEnMetros * 0.58'
 *
 * @param {Float} heightInKilometers
 */
const HEIGHT_TO_PHOTO_FACTOR = 0.58;
export function metersBetweenPhotos(heightInKilometers: number) {
  return heightInKilometers * HEIGHT_TO_PHOTO_FACTOR * 1000; // empirical value
}

const CM_PX_FACTOR = 32.6969696969697;
export function centimetersPerPixel(heightInMeters: number) {
  const res = heightInMeters / CM_PX_FACTOR;
  return res.toFixed(1);
}

/**
 * We have a issue with the grid square
 */
export function minFlyHeight() {
  return MIN_CELL_SIDE / HEIGHT_TO_CELL_SIZE_FACTOR;
}

/**
 *
 * <b>planOptions:</b>
 *   planOptions.googleFormat => the points are in form [{lat:x, lng:y},...] to
 *   flipPointsCoordinates => change the points of from [[x,y],...] to [[y,x]]
 *   planOptions.gridSide => the grid side
 *   planOptions.flyHeight => the fly height in km
 *  planOptions.velocity
 * @param {Array} points
 * @param {Object} planOptions
 */
// export function getFlightPlan(points: number[][], planOptions: any) {
export function getFlightPlan(poly: Polygon, planOptions: any) {
  const flyHeight = planOptions.flyHeight * 1000 || 10;
  const overlapFrontal = (planOptions.ov_frontal * 1) / 100 || 0.3;
  const overlapLateral = (planOptions.ov_lateral * 1) / 100 || 0.3;
  const camera = planOptions.camera;

  //   const cellSideViejo = (planOptions.gridSide || getCellSize(planOptions.flyHeight)) * 1;
  //   const cellSide = getCellWidthInMeters(flyHeight, getCameraHFOV(camera), overlapLateral); //* 15
  const angle = parseInt(planOptions.direction, 10) * -1 || 0;

  // add first point to make polygon

  // const first = points[0];
  // points.push(first);
  // const processedPoints = [points.map((p) => [p[1], p[0]])];
  // const polygonFeature = turf.polygon(processedPoints);

  if (poly === undefined) {
    console.error('poly is undefined')
    return;
  }
  const polygonFeature = turf.polygon(poly != null ? poly.coordinates : []);
 
  const pivot = [...polygonFeature.geometry.coordinates[0][0]];
  const rotateOptions = { pivot: pivot };
  const rotatedPolygonFeature = turf.transformRotate(polygonFeature, angle, rotateOptions);
  const bbox = turf.bbox(rotatedPolygonFeature);
  const [minLng, minLat, maxLng, maxLat] = bbox;

  const from = turf.point([minLng, minLat]);
  const to = turf.point([maxLng, minLat]);
  const options: { units: Units } = { units: 'meters' };
  const polygonWidthInMeters = turf.distance(from, to, options);

  const pathlinesDistanceInMeters = getCellWidthInMeters(
    flyHeight,
    getCameraHFOV(camera),
    overlapLateral,
  );

  // 3) Calculo la cantidad de pasadas que tengo que hacer para cubrir el ancho del poligono
  const pathlinesCount = Math.ceil(polygonWidthInMeters / pathlinesDistanceInMeters) + 1;

  // 4) Calculo el excedente de ancho cubierto, respecto al ancho del poligono
  const excedente = (pathlinesCount - 1) * pathlinesDistanceInMeters - polygonWidthInMeters;

  // console.log(`pathlinesDistanceInMeters: ${Math.ceil(pathlinesDistanceInMeters)}`)
  // console.log(`polygonWidthInMeters:      ${Math.ceil(polygonWidthInMeters)}`)
  // console.log(`pathlinesCount:            ${Math.ceil(pathlinesCount)}`)
  // console.log(`excedente:                 ${Math.ceil(excedente)}`)

  // 5) Reparto el excedente a ambos lados del polígono, dejando la mitad al principio, y la otra mitad al final

  const bboxVerticalLine = turf.lineString([
    [minLng, minLat],
    [minLng, maxLat],
  ]);
  const firstPathlineLng = turf.transformTranslate(bboxVerticalLine, excedente / 2, -90, {
    units: 'meters',
  });

  // 6) Empiezo a recorrer todas las pathlines, y para cada pathline tengo que calcular la latitud min y la max.
  let currentPath = firstPathlineLng;
  //   const debugLines = [];

  const flightPath = [];
  const linesForPhotos = [];
  for (let i = 0; i < pathlinesCount; i++) {
    // debugLines.push(currentPath)
    const listInterseccionesFeaturesCollection = turf.lineIntersect(
      currentPath,
      rotatedPolygonFeature,
    );

    const listIntersecciones = listInterseccionesFeaturesCollection.features;

    currentPath = turf.transformTranslate(currentPath, pathlinesDistanceInMeters, 90, {
      units: 'meters',
    }); // degreesPerIteration; pathlinesDistanceInMeters
    // si no hay intersecciones, esta transecta no la tomo en cuenta
    if (listIntersecciones.length == 0) {
      continue;
    }
    if (listIntersecciones.length == 1) {
      const point = listIntersecciones[0];
      // console.log(`UNA interseccion LineIntersect::${JSON.stringify(listIntersecciones)}`)
      flightPath.push(point.geometry.coordinates);
    } else {
      let pointMin = listIntersecciones[0]; // .geometry.coordinates
      let pointMax = listIntersecciones[0]; // .geometry.coordinates

      listIntersecciones.forEach((pointFeature) => {
        if (pointFeature.geometry.coordinates[1] < pointMin.geometry.coordinates[1]) {
          pointMin = pointFeature;
        }
        if (pointFeature.geometry.coordinates[1] > pointMax.geometry.coordinates[1]) {
          pointMax = pointFeature;
        }
      });
      linesForPhotos.push([pointMin.geometry.coordinates, pointMax.geometry.coordinates]);
      if (i % 2 == 0) {
        flightPath.push(pointMin.geometry.coordinates);
        flightPath.push(pointMax.geometry.coordinates);
      } else {
        flightPath.push(pointMax.geometry.coordinates);
        flightPath.push(pointMin.geometry.coordinates);
      }
    }
  }
  //   console.log(`JSON.stringify(flightPath):${JSON.stringify(flightPath, null, 2)}`);
  let linePathFeature = turf.lineString(flightPath);
  linePathFeature = turf.transformRotate(linePathFeature, angle * -1, rotateOptions);

  const length = turf.length(linePathFeature, { units: unit });
  const retraso = (flightPath.length - 2) * 2; // 0 // linePathFeature.coordinates.lenght
  const time = length / planOptions.speed + retraso; // seconds
  let distanceBetweenPicturesInMeters = 0;

  //   console.log(`length:::${length.toFixed(2)}`);
  //   console.log(`time:::${time}`);
  // console.log(`calculateDistanceBetweenPicturesInMeters(${getCameraVFOV(planOptions.camera)}, ${planOptions.flyHeight}, ${planOptions.ov_frontal})`)
  try {
    distanceBetweenPicturesInMeters = calculateDistanceBetweenPicturesInMeters(
      getCameraVFOV(camera),
      flyHeight,
      overlapFrontal,
    );
  } catch (error) {
    console.log(`Error al calcular distqancia entre fotos:${error}`);
    // distanceBetweenPicturesInMeters = -1
  }
  //   console.log(`distanceBetweenPicturesInMeters:::${distanceBetweenPicturesInMeters}`);
  const distanceForPhotos = linesForPhotos.reduce((prev, current) => {
    return prev + turf.distance(current[0], current[1], { units: 'meters' });
  }, 0);


  const photosCount = Math.ceil(distanceForPhotos / distanceBetweenPicturesInMeters);
  const bateries = Math.ceil(time / 900);

  // console.log(`*grid:${JSON.stringify(grid.features.length)}`)

  return {
    line: linePathFeature,
    minutes: secondsToMinuteSecondsString(time),
    area: (turf.area(polygonFeature) / 10000).toFixed(2),
    photos: photosCount,
    bateries: bateries,
    // rectangle: rectangle,
    // grid: grid
    // debugLines: debugLines,
  };
}
