import { exportShp } from 'api/Vistaguay/ExportAPI';
import { useGetPrescriptionsQuery } from 'api/Vistaguay/LotAPI';
import {ReactComponent as KmlIcon} from 'assets/icons/ico-kml.svg'
import {ReactComponent as SHPFileIcon} from 'assets/icons/ico-shp.svg';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'state/hooks';

import style from '../../LotContextual.module.scss';


export default function ExportOption({
    lotId,
    projectId 
}: {
    lotId: number,
    projectId: number 
}) {
    const { t } = useTranslation();

    const token = useAppSelector((state) => state.data.accessToken);

    const { 
        data: prescriptionsData 
    } = useGetPrescriptionsQuery({lotId: lotId, projectId: projectId});

    const handleDownloadKML = () => {
        return
    }

    const handleShpDownload = async () => {
       return
    };

    console.log('prescriptionsData', prescriptionsData)

    return(
        <div >
            <div className={style.contextualButton} onClick={() => handleDownloadKML()}>
                <p>{`${t('lote')} (KML)`}</p>

                <KmlIcon />
            </div>

            <hr style={{margin: '5px 0 5px 0'}}/>

            { prescriptionsData && prescriptionsData.map((p) => { return (
                <div key={p.id}>
                    <div className={style.contextualButton} onClick={() => handleShpDownload()}>
                        <p>{`${p.name} (SHP)`}</p>
                        <SHPFileIcon />
                    </div>

                    <hr style={{margin: '5px 0 5px 0'}}/>
                </div>
            )})}
        </div>
    )
}