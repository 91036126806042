import { useConfirmEmailMutation } from 'api/Vistaguay/VistaguayAPI'
import { ReactComponent as CheckIcon } from 'assets/icons/checkcircle-icon.svg'
import Button from 'components/Button/Button'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import styles from './Auxiliar.module.scss'
export default function ConfirmEmail() {
    const { token, email } = useParams()
    const [confirmEmail, { isSuccess, isLoading }] = useConfirmEmailMutation()
    const navigate = useNavigate()
    useEffect(() => {
        if (token && email) {
            const text = atob(token)
            confirmEmail({ token: text, email })
        }
    }, [confirmEmail, token, email])
    return (
        <div className={styles.mainArea}>
            <div className={styles.block}>
                <h4 style={{ textAlign: 'center' }}>Email confirmado con exito!</h4>
                <div style={{ display: 'flex', gap: '30px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <CheckIcon style={{ height: '60px', width: '60px', fill: styles.verdeVistaguay }} />
                    <Button text='Ingresa ahora!' variant='SUCCESS' style={{ width: '190px' }} onClick={(e) => navigate('/')} />
                </div>
            </div>
        </div>
    )
}