import { Prescription } from 'models/Prescription';
import { Task } from 'models/Task';
import {useState } from 'react';

import { getPhoto, photoMetadata } from './photoModal/GetPhoto';
import { PhotoDescription } from './photoModal/PhotoDescription';


export enum typeDescription {
  Min = 'min',
  Max = 'max',
}

interface IPhotoDescriptionSection {
  task: Task;
  photosMetadata: photoMetadata[];
  taskId: number;
  closeFunction: any;
  type: typeDescription;
  prescription: Prescription;
}

export const PhotoDescriptionSection = ({
  task,
  photosMetadata,
  taskId,
  closeFunction,
  type,
  prescription,
}: IPhotoDescriptionSection) => {
  const [selectedAmbiente, setSelectedAmbiente] = useState(-1);

  const selectedPhoto = getPhoto(photosMetadata, selectedAmbiente, type);
  const densidades = photosMetadata.map((photo: { densidad: any; }) => photo.densidad);

  if (!selectedPhoto) return null;
  return (
    <PhotoDescription
      task={task}
      photoMetadata={selectedPhoto}
      taskId={taskId}
      closeFunction={closeFunction}
      setSelectedAmbiente={setSelectedAmbiente}
      type={type}
      selectedAmbiente={selectedAmbiente}
      prescription={prescription}
      densidades={densidades}
      densidadesidx={photosMetadata}
    />
  );
};