import { PayPalButtons,PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ErrorToaster } from 'components/Toaster/AppToaster';
import { API_URL, PAYPAL_PUBLIC_KEY } from 'services/configurations';
import { useAppSelector } from 'state/hooks';
export default function CustomPaypal({amount, vpack = false} : {amount:number, vpack?: boolean}) {

  const initialOptions = {
    clientId: PAYPAL_PUBLIC_KEY,
    currency: 'USD',
    intent: 'capture',
  };
  const token = useAppSelector((state) => state.data.accessToken);

  return (
    <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            style={{ shape: 'rect', layout: 'vertical'}}
            createOrder={async () => {
              try {
                const response = await fetch(`${API_URL}paypal/order`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                  // use the "body" param to optionally pass additional order information
                  // like product ids and quantities
                  body: JSON.stringify({
                    cart: [
                      {
                        id: '1',
                        quantity: amount,
                        type: vpack ? 'vpack' : 'none'
                      },
                    ],
                  }),
                });
                const orderData = await response.json();
                if (orderData.id) {
                  return orderData.id;
                } else {
                  const errorDetail = orderData?.details?.[0];
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);
                  throw new Error(errorMessage);
                }
              } catch (error) {
                console.error(error);
              }
            }}
            onApprove={async (data, actions) => {
              try {
                const response = await fetch(`${API_URL}paypal/order/${data.orderID}/capture`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                });
                const orderData = await response.json();
                // Three cases to handle:
                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                //   (2) Other non-recoverable errors -> Show a failure message
                //   (3) Successful transaction -> Show confirmation or thank you message
                const errorDetail = orderData?.details?.[0];
                if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                  // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                  return actions.restart();
                } else if (errorDetail) {
                  // (2) Other non-recoverable errors -> Show a failure message
                  throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                } else {
                  // (3) Successful transaction -> Show confirmation or thank you message
                  // Or go to another URL:  actions.redirect('thank_you.html');
                  return
                }
              } catch (error) {
                console.error(error);
              }
            }}
            onError={async (data) => {
              ErrorToaster('Error first buy minimum is 15 credits')
            }}
          />
      </PayPalScriptProvider>
  );
}
