import { getShapefile } from 'api/ShapefileApi';
import { FileUpload } from 'components/Forms/FileUpload';
import { TextInput } from 'components/Forms/TextInput';
import MGeoJson from 'components/Map/Components/MGeoJson';
import MPoint from 'components/Map/Components/MPoint';
import MVectorLayer from 'components/Map/Components/MVectorLayer';
import { useState } from 'react';
import { IRoute } from 'routes';

const geojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [
          [
            [-53.1900177564863, -9.716300676975692],
            [-54.76316951935206, -14.320569622916679],
            [-46.068328227414526, -16.65221834500865],
            [-38.72417979421189, -12.037195094130354],
            [-41.71162427338959, -3.665360355737832],
            [-53.1900177564863, -9.716300676975692],
          ],
        ],
        type: 'Polygon',
      },
    },
    {
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [
          [
            [-50.90144130436087, -4.568416986225458],
            [-54.25284311835975, -16.716610821235875],
            [-49.374501857238386, -21.267390220978427],
            [-40.48586319833811, -20.477965186073433],
            [-38.53036203694296, -7.715942482947611],
            [-50.90144130436087, -4.568416986225458],
          ],
        ],
        type: 'Polygon',
      },
    },
    {
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [
          [
            [-57.160829037784794, -30.04404890803508],
            [-58.809957153657265, -34.337962191402084],
            [-54.862254841951255, -35.203445546020305],
            [-53.62084621222533, -32.545051411228464],
            [-57.160829037784794, -30.04404890803508],
          ],
        ],
        type: 'Polygon',
      },
    },
  ],
} as GeoJSON.FeatureCollection<GeoJSON.Polygon>;

// const geoJsonTestStr = JSON.stringify(geoJsonTest);

export const testShpRoute: IRoute = {
  path: '/testShp',
  centralArea: () => <TestCentralArea />,
  sidebar: () => <TestSidebar />,
  map:true,
  layout:true,
  protected: true,
};

export const TestCentralArea = () => {
  return (
    <div>
      <MVectorLayer key={39}>
        <MGeoJson geojson={geojson}></MGeoJson>
      </MVectorLayer>
      <MVectorLayer>
        <MPoint lat={-33} lng={-54}></MPoint>
      </MVectorLayer>
    </div>
  );
};

export const TestSidebar = () => {
  const [data, setData] = useState(null);

  if (data) {
    const downloadUrl = URL.createObjectURL(data);
    return (
      <a href={downloadUrl} download='shapefile.zip'>
        Download File
      </a>
    );
  } else {
    return (
      <div>
        <h1>Side bar</h1>
        <TextInput callbackTextChange={(text) => console.info(text)} />
        <FileUpload callbackFileChange={(file) => console.info(file)} />
        {/* <pre>{JSON.stringify(geoJsonTest, null, 2)}</pre> */}
        {/* <pre>{data}</pre> */}
        <button
          onClick={() => {
            getShapefile(geojson)
              .then((res: any) => {
                setData(res);
              })
              .catch((e) => {
                console.error(e);
              });
          }}
        >
          conversor
        </button>
      </div>
    );
  }
};
