import { ReactComponent as PickerIcon } from 'assets/icons/ico-picker.svg';
import { ColorPickerButton, ColorPickerButtonSquares } from 'components/ColorPicker/VistaguayColorPicker';
import { getColor, getColorModal } from 'components/Domain/Prescription/PrescriptionPolygons';
import { PlantCountTask } from 'models/PlantCountTask';
import { Prescription } from 'models/Prescription';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setSelectedPrescription, setSelectedPrescriptionChanged } from 'state/slices/uiSlice';

import style from './Map/AddCountMap.module.scss';

export interface StringMap {
  [key: string]: any;
}

export interface PrescriptionTableProps {
  onChangeColor?: (prescription: Prescription) => void
  prescription: Prescription | null;
  plantCountTask?: Partial<Omit<PlantCountTask, 'id'>>;
  setPlantCountTask?: (plantCountTask: Partial<Omit<PlantCountTask, 'id'>>) => void;
  editable?: boolean;
  showCollapseButton?: boolean;
  handleCollapseButton?: any;
  handleDensityValues?: (values: any) => void;  
}

export function PrescriptionTable({
  prescription,
  plantCountTask,
  setPlantCountTask,
  editable,
  onChangeColor,
  showCollapseButton,
  handleCollapseButton,
  handleDensityValues
  } : PrescriptionTableProps) {
  const dispatch = useAppDispatch();
  
  const {prescriptionTheme} = useAppSelector((state) => state.prescription);
  const densityValues = useAppSelector((state) => state.pqFormState.pqForm.densidadesJson);
  
  const [tableDensityValues, setTableDensityValues] = useState<any[]>([]);
  
  useEffect(() => {
    if (densityValues && densityValues.length > 0) {
      setTableDensityValues(densityValues);
    } else if (prescription?.prescription?.features && prescription.prescription.features.length > 0) {
      const initializedValues = prescription.prescription.features.map((p) => ({ density: (p.properties && p.properties['density']) ? p.properties['density'] : 0 }));
      setTableDensityValues(initializedValues);
    } else {
      setTableDensityValues([]);
    }
  }, [prescription]);
  
  
  const handleInputChange = (idx: number, value: number) => {
    setTableDensityValues((prevState) => {
      const newState = prevState.map((item, index) => {
        if (index === idx) {
          return { ...item, density: value };
        }
        return item;
      });
      
      if(handleDensityValues) {
        handleDensityValues(newState)
      }
      
      return newState;
    });
  
    if (plantCountTask && setPlantCountTask) {
      setPlantCountTask({ ...plantCountTask, densidadesJson: tableDensityValues });
    }

    
  };
  
  if (!prescription || !prescription.prescription?.features) {
    return <p>Error: Prescription data is not available.</p>;
  }
  
  return (
    <div>
      <div className={style.container}>
        <table className={style.table}>
          <thead className={style.thead}>
            <tr>
              <th>Clase</th>
              <th>Color</th>
              {/* {isPrescriptionEditable || onChangeColor ? (
                <th>
                  <PickerIcon style={{ width: '30px', height: '30px' }} />
                </th>
              ) : null} */}
              { prescription.prescription.features[0].properties && 
                mapProperties(prescription.prescription.features[0].properties) 
              } 
              {tableDensityValues && tableDensityValues.length > 0 && (
                <th>Densidad objetivo (pl/ha)</th>
              )}
            </tr>
          </thead>
  
          <tbody className={style.tbody}>
            {prescription.prescription.features.map((feature, idx) => (
              <tr
                key={idx}
                style={{
                  backgroundColor: feature.properties?.fill || feature.properties?.color
                    ? getColorModal(feature.properties)
                    : prescriptionTheme[idx]?.replace('0.6', '0.2') || ''
                }}
              >
                <th>{idx}</th>
  
                {!editable && (
                  <td style={{ width: '40px' }} className={style.picker}>
                    <div
                      style={{
                        width: '25px',
                        marginLeft: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 'auto',
                        border: '1px solid #000000',
                        borderRadius: '5px',
                        height: '25px',
                        backgroundColor:
                          feature.properties?.fill || feature.properties?.color
                            ? getColor(feature.properties)
                            : prescriptionTheme[idx].slice(0, -6),
                      }}
                    ></div>
                  </td>
                )}
  
                {editable && (
                  <td style={{ width: '40px' }} className={style.picker}>
                    <ColorPickerButtonSquares
                      disabled={!editable}
                      color={
                        feature.properties?.fill || feature.properties?.color
                          ? getColor(feature.properties)
                          : prescriptionTheme[idx]
                      }
                      onChange={(color: string) => {
                        const newFeature = prescription?.prescription?.features.map((f, i) => {
                          if (i === idx) {
                            const newProps = { ...f.properties, fill: color };
                            return { ...f, properties: newProps };
                          }
                          return f;
                        });
  
                        if (newFeature && prescription) {
                          const newPrescriptionFeature: any = {
                            ...prescription.prescription,
                            features: newFeature,
                          };
                          const newPrescription: Prescription = {
                            ...prescription,
                            prescription: newPrescriptionFeature,
                          };
                          dispatch(setSelectedPrescription(newPrescription));
                          dispatch(setSelectedPrescriptionChanged(true));
                        }
                      }}
                    />
                  </td>
                )} 
  
                {/* {onChangeColor && (
                  <td style={{ width: '40px' }} className={style.picker}>
                    <ColorPickerButton
                      color={
                        feature.properties?.fill || feature.properties?.color
                          ? getColor(feature.properties)
                          : prescriptionTheme[idx]
                      }
                      onChange={(color: string) => {
                        const newFeature = prescription?.prescription?.features.map((f, i) => {
                          if (i === idx) {
                            const newProps = { ...f.properties, fill: color };
                            return { ...f, properties: newProps };
                          }
                          return f;
                        });
  
                        if (newFeature && prescription) {
                          const newPrescriptionFeature: any = {
                            ...prescription.prescription,
                            features: newFeature,
                          };
                          const newPrescription: Prescription = {
                            ...prescription,
                            prescription: newPrescriptionFeature,
                          };
                          onChangeColor(newPrescription);
                        }
                      }}
                    />
                  </td>
                )}
                */}
  
                {feature.properties && mapTd(feature)}
  
                {!editable && tableDensityValues[idx] && (
                  <td style={{ width: '40px' }} >
                    {tableDensityValues[idx].density}
                  </td>
                )}
  
                {editable && tableDensityValues && tableDensityValues[idx] && (
                  <td>
                    <input
                      value={tableDensityValues[idx].density}
                      placeholder='0'
                      onKeyDown={(event) => {
                        if (event.key === 'Backspace') {
                          return;
                        } else if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                        if (Number(event.currentTarget.value) <= 0 && event.key === '0') {
                          event.preventDefault();
                        }
                        if (String(event.currentTarget.value).length >= 6) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        handleInputChange(idx, value);
                      }}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  }

const mapProperties = (properties: StringMap) => {
  const { color, fill, ...restproperties } = properties;
  const proper: string[] = Object.keys(restproperties);
  return (
    <>
      {proper.map((item, i) => {
        return <th key={i}>{item}</th>;
      })}
    </>
  );
};

const mapTd = (feature: StringMap) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { color, fill, ...restproperties } = feature.properties;
  const proper: string[] = Object.values(restproperties);
  return (
    <>
      {proper.map((item: string, i: number) => {
        return <td key={i}>{item}</td>;
      })}
    </>
  );
};
