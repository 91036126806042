import { ReactComponent as CloseIcon } from 'assets/icons/ico-close.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ico-error.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico-info.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/ico-success.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/ico-warning.svg';
import clsx from 'classnames';

import style from './Alert.module.scss';

export interface AlertProps {
    type: 'error' | 'info' | 'warning' | 'success';
    text: string;
    title?: string;
    showCloseBtn?: boolean;
    handleClose?: () => void
}

export default function Alert ({title, type, text, showCloseBtn, handleClose}: AlertProps) {
    const Icon = () => {
        switch (type) {
            case 'error':
                return (<ErrorIcon fill={style.errorDark} width={12}/>)
            case 'info':
                return (<InfoIcon fill={style.infoDark} width={25}/>)
            case 'warning':
                return(<WarningIcon fill={style.azul} width={25}/>)
            case 'success':
                return(<SuccessIcon fill={style.azul} width={25}/>)
            default:
                return(<></>)
                break;
        }
    }

    return (
        <div className={
            clsx(
                style.alertWrapper,
                type === 'info' ? style.info : '',
                type === 'warning' ? style.warning : '',
                type === 'error' ? style.error : '',
                type === 'success' ? style.success : '',
            )
        }>
            <div style={{width: '25px', height: '100%'}}>
                <Icon />
            </div>

            <div className={style.textWrapper}>
                { title && <p className={clsx(style.text, style.title)}>{title}</p>}
                <p className={clsx(style.text)}>{text}</p>
            </div>

            {
                showCloseBtn && (
                    <div className={style.closeBtn} onClick={handleClose}>
                        <CloseIcon width={10}/>
                    </div>
                )
            }
        </div>
    )
}