// #region ICONS
// #endregion
import { useGetLotQuery } from 'api/Vistaguay/LotAPI';
import { useAddNewCameraMutation, useMosaicDeleteMutation } from 'api/Vistaguay/MosaicAPI';
import { useGetProjectQuery } from 'api/Vistaguay/ProjectAPI';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as CreditsIcon } from 'assets/icons/ico-credits-outline.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/ico-image.svg';
import { ReactComponent as PlantIconOutline } from 'assets/icons/ico-plant-outline.svg';
import { ReactComponent as TickIcon } from 'assets/icons/ico-tick.svg';
import { ReactComponent as TruckIcon } from 'assets/icons/ico-truck-outline.svg';
import { ReactComponent as MosaicIcon } from 'assets/icons/Mosaico-comodin.svg';
import { Stepper2 } from 'components/Steppers/Stepper2/Stepper2';
import SuccessScreen from 'components/SuccessScreen/SuccessScreen';
import CancelPopup from 'components/Vistaguay/CancelPopup/CancelPopup';
import StepsFormFooter from 'components/Vistaguay/StepsFormFooter/StepsFormFooter';
import Sidebar from 'layout/Sidebar/Sidebar';
import SidebarBody from 'layout/Sidebar/SidebarBody';
import { LotProjectItem, SlimHeader, SubHeader, TaskHeader } from 'layout/Sidebar/SubHeader';
import { Cameras } from 'models/Cameras';
import MosaicTask, { CAMERA_TYPE } from 'models/MosaicTask';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from 'services/configurations';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { clearImgPoints } from 'state/slices/mapSlice';

import style from './MosaicForm.module.scss'
import StepCredit from './Steps/StepCredit';
import StepFlight from './Steps/StepFlight';
import StepImageUpload from './Steps/StepImageUpload';
import StepRequirements from './Steps/StepRequirements';
import StepSowing  from './Steps/StepSowing';
import StepUpload from './Steps/StepUpload';

export interface ErrorList {
    message: string;
    propName: string;
}

export interface MosaicStepProps {
    nextStepTouched: boolean;
    currentStep: number;
    step: number;
    handleError: (error: boolean) => void;
    form: MosaicFormProps;
    setForm: Dispatch<SetStateAction<MosaicFormProps>>;
}

export interface MosaicStepValidate {
    (form: MosaicFormProps) : { isValid: boolean, message: string, propName:string }
}

export interface MosaicFormProps {
    nombre: string;
    files: File[];
    flightDate: Date | undefined;
    cameraType: CAMERA_TYPE;
    cameraId: string;
    cultivoId: number | undefined;
    estadioId: number | undefined;
    hibridoId: number | undefined;
    sowDate: Date | undefined;
    clientCuit: string;
    cameraModel: string;
    cameraBrand: string;
    taxIdType?: string;
}

const initialState: MosaicFormProps = {
    nombre:'',
    files: [],
    flightDate: undefined,
    cameraType: CAMERA_TYPE.NULL ,
    cameraId: '',
    cameraBrand: '',
    cameraModel: '',
    cultivoId: undefined,
    estadioId: undefined,
    hibridoId: undefined,
    sowDate: undefined,
    clientCuit: '',
}

export default function AddMosaic() {
    // #region HOOKS
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation();
    // #endregion

    const { lotId, projectId } = useParams<{ lotId: string; projectId: string }>();
    
    const selectedCampaign = useAppSelector((state) => state.campaign.selectedCampaign)
   
    const project = projectId ? useGetProjectQuery(+projectId).data : undefined;
    const lot = projectId && lotId ? useGetLotQuery({ projectId: +projectId, lotId: +lotId }).data : undefined;
    
    // #region STATES
    const [ form, setForm ] = useState<MosaicFormProps>(initialState)
    const [ showCancelModal, setShowCancelModal ] = useState(false);
    const [ showSuccessScreen, setShowSuccessScreen ] = useState(false);
    const [ hasError,setHasError ] = useState(false);
    const [ step, setStep ] = useState(1);
    const [ nextStepTouched, setNextStepTouched ] = useState(false);
    const [ isSuccessAddTask, setIsSuccessAddTask ] = useState(false);
    const [ isLoadingAddTask, setIsLoadingAddTask ] = useState(false);
    // #endregion

    // #region HANDLERS
    const handlePreviousStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    }

    const handleNextStep = () => {
        setNextStepTouched(true);
        if(!hasError){
            if (step < steps.length ) {
                setStep(step + 1);
                setNextStepTouched(false);
            } else if (step == steps.length) {
                setShowSuccessScreen(true);
            }
        }
    };

    const handleExit = () => {
        navigate(`/projects/${projectId}/lots/${lotId}/tasks/new`);
    }

    const handleError = useCallback((hasError: boolean) => {
        setHasError(hasError);
    },[]);
    // #endregion

    const steps = [
        {
            index: 1,
            step: <StepRequirements key={1}/>,
            stepName: t('requirements'),
            icon: <FileIcon />
        },
        {
            index: 2,
            step: <StepImageUpload 
                key={2} 
                step={2}
                currentStep={step}
                nextStepTouched={nextStepTouched}
                form={form} 
                setForm={setForm} 
                handleError={handleError}
            />,
            stepName: t('upload-images'),
            icon: <ImageIcon />
        },
        {
            index: 3,
            step: <StepCredit 
                key={3} 
                step={3}
                currentStep={step}
                nextStepTouched={nextStepTouched}
                form={form} 
                setForm={setForm} 
                handleError={handleError}
            />,
            stepName: t('credits'),
            icon: <CreditsIcon />
        },
        {
            index: 4,
            step: <StepSowing 
                key={4} 
                step={4}
                currentStep={step}
                nextStepTouched={nextStepTouched}
                form={form} 
                setForm={setForm} 
                handleError={handleError}
            />,
            stepName: t('crops'),
            icon: <PlantIconOutline /> 
        },
        {
            index: 5,
            step: <StepFlight 
                key={5} 
                step={5}
                currentStep={step}
                nextStepTouched={nextStepTouched}
                form={form} 
                setForm={setForm} 
                handleError={handleError}
            />,
            stepName: t('flight'),
            icon: <MosaicIcon fill={style.azul} width={30}/> 
        },
        {
            index: 6,
            step: <StepUpload 
                key={6} 
                step={6}
                currentStep={step}
                nextStepTouched={nextStepTouched}
                form={form} 
                setForm={setForm} 
                handleError={handleError}
                lotId={lotId}
                projectId={projectId}
                selectedCampaignId={selectedCampaign?.id}
                isLoadingAddTask={isLoadingAddTask}
                isSuccessAddTask={isSuccessAddTask}
                handleShowCancelModal={() => setShowCancelModal(true)}
                handleIsLoadingAddTask={(status) => setIsLoadingAddTask(status)}
                handleIsSuccessAddTask={(status) => setIsSuccessAddTask(status)}
            />,
            stepName: t('finish-upload'),
            icon: <TickIcon fill={style.verdeOscuro} width={15}/> 
        }
    ]

    if (!lotId || !projectId) { return <div>invalid-params</div> }

    if(!selectedCampaign) { return <div>no-campaign</div> }

    return (
        <Sidebar style={{width: '400px', minWidth: '400px', maxWidth: '400px'}}>
            <SubHeader >
                <LotProjectItem
                    campaignName={selectedCampaign?.name}
                    projectName={project?.nombre}
                    projectColor={project?.color}
                    lotName={lot?.name}
                />
            </SubHeader>

            <SubHeader noBack={true} sx={{ backgroundColor: style.dark }}>
                <TaskHeader 
                    title={'Mosaico'} 
                    icon={<MosaicIcon />} 
                    handleCancel={() => {setShowCancelModal(true)}}
                />
            </SubHeader>

            <SidebarBody>
                {!showSuccessScreen ? (
                    <>
                        <Stepper2 StepAmount={steps.length} StepCurrent={step} steps={steps}/>
                        {steps[step - 1].step}
                    </>
                ) : (
                    <SuccessScreen redirectTo={`/projects/${projectId}/lots/${lotId}`}/>
                )}

                { showCancelModal && (
                    <CancelPopup 
                        text={(!isLoadingAddTask && !isSuccessAddTask && step == steps.length) ? t('form-upload-error') : t('cancel-modal-text')}
                        handleCancelModalclose={() => setShowCancelModal(false)} 
                        handleExit={() => handleExit()}
                        showCancelBtn={(!isLoadingAddTask && !isSuccessAddTask && step == steps.length) ? false : true}
                    />
                )}
            </SidebarBody>

            { !showSuccessScreen && (
                <StepsFormFooter 
                    currentStep={step}
                    stepsAmount={steps.length}
                    disableBackBtn={step == steps.length || isLoadingAddTask}
                    disableNextBtn={hasError || isLoadingAddTask}
                    loading={isLoadingAddTask}
                    handlePreviousStep={handlePreviousStep}
                    handleNextStep={handleNextStep}
                />
            )}
        </Sidebar>
    );
}