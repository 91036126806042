import { Polygon as OlPolygon } from 'ol/geom';
import { transform } from 'ol/proj';

export class PhotoMetadata {
  fileName: string;
  GPSLatitudeRef: string;
  GPSLatitude: string;
  GPSLongitude: string;
  GPSLongitudeRef: string;
  GPSAltitudeRef: string;
  GPSAltitude: string;

  constructor(
    fileName: string,
    GPSLatitudeRef: string,
    GPSLatitude: string,
    GPSLongitudeRef: string,
    GPSLongitude: string,
    GPSAltitudeRef: string,
    GPSAltitude: string,
  ) {
    this.fileName = fileName;
    this.GPSLatitudeRef = GPSLatitudeRef;
    this.GPSLatitude = GPSLatitude;
    this.GPSLongitudeRef = GPSLongitudeRef;
    this.GPSLongitude = GPSLongitude;
    this.GPSAltitudeRef = GPSAltitudeRef;
    this.GPSAltitude = GPSAltitude;
  }

  static generarPoligono(photosMetadata: PhotoMetadata[]) {
    if (!photosMetadata || photosMetadata.length === 0) return null;

    const coords = photosMetadata.map((photoMetadata: PhotoMetadata) => {
      const latitud = parseFloat(photoMetadata.GPSLatitude);
      const longitud = parseFloat(photoMetadata.GPSLongitude);
      const coords3857 = transform([longitud, latitud], 'EPSG:4326', 'EPSG:3857');
      return coords3857;
    });
    coords.push(coords[0]); // add first element to close the polygon
    const poligono = new OlPolygon([coords]);
    return poligono;
  }
}
