import { ReactComponent as TickIcon } from 'assets/icons/ico-tick.svg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './SuccessScreen.module.scss';

interface SuccessScreenProps {
    redirectTo: string
}

export default function SuccessScreen({redirectTo}: SuccessScreenProps) {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate(redirectTo);
        }, 3000);
    }, [])

    return (
        <div className={styles.bodyComponent}>
            <div className={styles.iconWrapper}>
                <TickIcon width={60}/>
            </div> 
            <h3>Tu solicitud se esta procesando en nuestra plataforma</h3>
            <span className={styles.smallLegend}>Recibirás una notificacion cuando la tarea esté lista</span>
        </div>
    );
}
