import { GeoJSONFeatureCollection } from 'models/GeoJSON';
import Lot from 'models/Lot';
import { Prescription } from 'models/Prescription';

import { VistaguayApi } from './VistaguayAPI';

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['Lot', 'Projects', 'Prescriptions'],
});

const lotApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getLots: builder.query<Lot[], number>({
      query: (projectId: number) => `projects/${projectId}/lots`,
      providesTags: ['Lot'],
    }),
    getLot: builder.query<Lot, { projectId: number; lotId: number }>({
      query: ({ projectId, lotId }) => `projects/${projectId}/lots/${lotId}`,
      providesTags: ['Lot'],
    }),
    addLot: builder.mutation({
      query: ({ lot, projectId }: { lot: Omit<Lot, 'id'>; projectId: number }) => ({
        url: `projects/${projectId}/lots`,
        method: 'POST',
        body: lot,
      }),
      invalidatesTags: ['Lot', 'Projects'],
    }),
    addPrescriptions: builder.mutation({
      query: ({
        lotId,
        projectId,
        prescription,
      }: {
        lotId: number;
        projectId: number;
        prescription: Partial<Prescription>;
      }) => ({
        url: `projects/${projectId}/lots/${lotId}/prescriptions`,
        method: 'POST',
        body: {
          prescription: prescription,
          lotId: lotId,
        },
      }),
      invalidatesTags: ['Prescriptions'],
    }),
    updatePrescriptions: builder.mutation({
      query: ({ lotId, projectId, prescription, prescriptionId }) => ({
        url: `projects/${projectId}/lots/${lotId}/prescriptions/${prescriptionId}`,
        method: 'PATCH',
        body: {
          prescription: prescription,
          lotId: lotId,
        },
      }),
      invalidatesTags: ['Prescriptions'],
    }),
    getPrescriptions: builder.query<Prescription[], { projectId: number; lotId: number }>({
      query: ({ projectId, lotId }) => `projects/${projectId}/lots/${lotId}/prescriptions`,
      providesTags: ['Prescriptions'],
    }),
    deletePrescription: builder.mutation({
      query: ({ 
        lotId, 
        projectId, 
        prescriptionId 
      }:{
        lotId: number, 
        projectId: number, 
        prescriptionId: number
      }) => ({
        url: `projects/${projectId}/lots/${lotId}/prescriptions/${prescriptionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Prescriptions'],
    }),
    deleteLot: builder.mutation({
      query: ({ lotId, projectId }:{lotId: number, projectId: number}) => ({
        url: `projects/${projectId}/lots/${lotId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Lot'],
    }),
    patchLot: builder.mutation({
      query: ({ 
          lotId, 
          projectId,
          body 
        }:{
          lotId: number, 
          projectId: number,
          body: {name?: string}
        }) => ({
        url: `projects/${projectId}/lots/${lotId}`,
        method: 'PATCH',
        body: body
      }),
      invalidatesTags: ['Lot'],
    })
  }),
});

export const {
  useGetLotsQuery,
  useGetLotQuery,
  useAddLotMutation,
  useDeleteLotMutation,
  usePatchLotMutation,
  useGetPrescriptionsQuery,
  useAddPrescriptionsMutation,
  useUpdatePrescriptionsMutation,
  useDeletePrescriptionMutation
} = lotApi;
