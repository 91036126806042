import { Crop } from 'models/Crop';
import { CropDistance } from 'models/CropDistance';
import { Seeder } from 'models/Seeder';
import { SeederBrand } from 'models/SeederBrand';
import { SeederMeterBrand } from 'models/SeederMeterBrand';
import { Seeding } from 'models/Seeding';
import { TaskStatus } from 'models/TaskStatus';

import { VistaguayApi, VistaguayApiResponse } from './VistaguayAPI';

export interface ExtraDataResponse {
  seeders: Seeder[];
  seedings: Seeding[];
  crops: Crop[];
  taskStatus: TaskStatus[];
  cropTaskStatus: TaskStatus[];
  distances: CropDistance[];
  seederMeterBrands: SeederMeterBrand[];
  seederBrands: SeederBrand[];
}
interface GetIndustryResponse {
  id: number;
  value:string;
}
interface GetCuitsResponse {
  cuits: string;
  id: number;
  nombre: string;
}

const enhanced = VistaguayApi.enhanceEndpoints({
  addTagTypes: ['ExtraData'],
});

const extraDataApi = enhanced.injectEndpoints({
  endpoints: (builder) => ({
    getExtraData: builder.query<ExtraDataResponse, void>({
      query: () => 'extra-datas',
      providesTags: ['ExtraData'],
    }),
    getCuits: builder.query<GetCuitsResponse[], void>({
      query: () => 'extra-datas/cuits',
      providesTags: ['ExtraData'],
    }),
    getIndustry: builder.query<GetIndustryResponse[],void>({
      query: () => 'extra-datas/industry',
      providesTags: ['ExtraData'],
    }),
  }),
});

export const { useGetExtraDataQuery, useGetCuitsQuery, useGetIndustryQuery} = extraDataApi;
