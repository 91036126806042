// #region REACT
// #endregion
// #region Icons
import { Icon } from '@blueprintjs/core';
// #endregion
// #region COMPONENTS
import { Popover2 } from '@blueprintjs/popover2';
// #endregion
// #region API
import { useGetExtraDataQuery } from 'api/Vistaguay/ExtraData';
import { useGetMosaicMetadataQuery, useGetWmsQuery, WmsObject } from 'api/Vistaguay/MosaicAPI';
import { ReactComponent as Dots } from 'assets/icons/ico-dots.svg';
import { ReactComponent as InspectLayersIcon } from 'assets/icons/ico-inspect-layers.svg';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
// #endregion
// #region Styles
import { Reorder, useDragControls } from 'framer-motion';
import { update } from 'lodash';
// #endregion
// #region Models
import MosaicTask, { MOSAIC_STAGE } from 'models/MosaicTask';
import { TaskStatus } from 'models/TaskStatus';
import { MosiacView } from 'pages/Tasks/Mosaics/MosiacView';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
// #endregion
// #region Redux State
import {
  addMosaic,
  clearHistogramData,
  LayerInfo,
  removeMosaic,
  setMosaicTaskLayers,
  updateMosaic,
} from 'state/slices/mosaicSlice';
import { IAllTasks, toggleLayersVisibility, updateVisualization, VisualizationPlantCountTask } from 'state/slices/taskSlice';
import { setHoveredLotId } from 'state/slices/uiSlice';

import { TypeIcon } from './CountTaskListItem';
import { ReorderIcon } from './ReorderIcon';
import { MosaicContextual } from './TaskListContextual/MosaicContextual/MosaicContextual';
import { HistogramModal } from './TaskListContextual/MosaicContextual/Options/HistogramModal';
import style from './TaskListItem.module.scss';
// #endregion

interface MosaicListItemProps {
  mosaicTask: IAllTasks;
}

export const StatusIconMosaic = ({status}: {status:number}) => {
  
  const size = 25;
  const styleIcon = {
    display:'flex',
    alignItems:'center',
  }

  switch (status) {
    case MOSAIC_STAGE.PROCESSING || MOSAIC_STAGE.NEW || MOSAIC_STAGE.REPROCESS:
      return <Icon icon='history' size={size} style={styleIcon}/>;
    case MOSAIC_STAGE.PUBLISHED: 
      return null
    case MOSAIC_STAGE.BROKEN || MOSAIC_STAGE.DISCARD:
      return <Icon icon='cross-circle' size={size} style={styleIcon}/>;
    default:
      return <Icon icon='history' size={size} style={styleIcon} />;
  }

}

const getStatusName = (status: number) : string => {
  switch(status) {
    case MOSAIC_STAGE.NEW:
      return 'Nuevo'
    case MOSAIC_STAGE.PROCESSING:
      return 'Procesando'
    case MOSAIC_STAGE.REPROCESS:
      return 'Re procesando'   
    case MOSAIC_STAGE.BROKEN:
      return 'Roto'
    case MOSAIC_STAGE.DISCARD:
      return 'Descartado'
    case MOSAIC_STAGE.PUBLISHED:
      return 'Publicado'
    default: 
      return 'Estado desconocido'
  }
}

export function MosaicListItem({ mosaicTask }: MosaicListItemProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const taskData = mosaicTask.task as MosaicTask;
  const visualization = mosaicTask.visualization as VisualizationPlantCountTask;
  
  const [showModal, setShowModal] = useState(false);
  const [zoomLayers, setZoomLayers] = useState(false);

  const dragControls = useDragControls();

  const { projectId, lotId } = useParams<{projectId: string;lotId: string;}>();

  if (!lotId || !projectId) {
    return <></>;
  }

  const taskId = '' + taskData.mosaicoId;

  // const { data: mosaicMetadata } = useGetMosaicMetadataQuery({ taskId });
  const { data: wmsData } = useGetWmsQuery({ taskId: mosaicTask.taskId }, { skip: !mosaicTask.taskId });

  const mosaics = useAppSelector((state) => state.mosaic.mosaics);
  const mosaicView = mosaics.find((m) => m.id === taskData.mosaicoId);


  useEffect(() => {
    if (taskData.mosaicoId) {
      dispatch(
        addMosaic({
          name: taskData.nombre,
          id: taskData.mosaicoId,
          data: taskData,
          layers: [],
          histogramView: null,
          showPhotoPoints: false,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (wmsData) {
      const aux: WmsObject = wmsData;
      const keys = Object.keys(aux);
      const layers = keys.map((key) => {
        return {
          layerName: key,
          minValue: -1,
          maxValue: 1,
          visible: true,
          opacity: 1,
        };
      });

      dispatch(
        setMosaicTaskLayers({
          id: taskData.mosaicoId,
          layers: layers
        }),
      );

      if(mosaicView) {
        dispatch(
          updateMosaic({
            ...mosaicView,
            layers: layers
          }),
        );
      }
    }
  }, [wmsData]);


  const {
    data: extraData,
    isLoading: isLoadingExtraData,
    isSuccess: isSuccessExtraData,
    error: errorExtraData,
  } = useGetExtraDataQuery();

  const filteredCropStatus = mosaicStatus(extraData?.cropTaskStatus || []);

  const goToTaskDetail = () => {
    navigate(`/projects/${projectId}/lots/${lotId}/mosaic/${taskId}`);
  };

  function getFlightDate() {
    if (typeof taskData.fechaVuelo === 'string') {
      return new Date(taskData.fechaVuelo).toLocaleDateString();
    }
    return taskData.fechaVuelo;
  }

  const handdleCloseModal = () => {
    dispatch(clearHistogramData(taskData.mosaicoId));
    setShowModal(false);
  };

  const handleToggleAllLayers = (status: boolean) => {
    const newTask = {
      ...mosaicTask,
      visualization: {
        ...visualization,
        visible: status,
      },
    };
    
    dispatch(updateVisualization(newTask));
    dispatch(toggleLayersVisibility(mosaicTask.taskId));
    
    if(mosaicView) {
      dispatch(
        updateMosaic({
          ...mosaicView,
          layers: mosaicView.layers.map((layer: any) => {
            if (layer) {
              return {
                ...layer,
                visible: status,
              };
            }
            return layer;
          }),
        }),
      );
    }
  };

  return (
    <Reorder.Item
      // onMouseOver={() => dispatch(setHoveredLotId(taskData.mosaicoId))} // Cause bug mosaic appear and disappear
      // onMouseOut={() => dispatch(setHoveredLotId(null))} // Cause BUG
      className={style.taskItem}
      value={mosaicTask}
      key={mosaicTask as any}
      dragControls={dragControls}
    >
      <div className={style.taskinfo}>
        <div className={style.reorderIcon}>
          <ReorderIcon dragControls={dragControls}/>
        </div>

        <TypeIcon type={'mapeo'} />

        <div className={style.taskInfoText}>
          <p>{`${t('mosaic')} (#${taskData.mosaicoId})`}</p>

          <p className={style.taskInfoSubtitle}>
            {`${t('flight')}: ${getFlightDate()} - ${taskData.tipoCamara?.split('-').join('')}`}
          </p>
        </div>
      </div>

      <div className={style.taskItemStatus}>
        <InspectLayersIcon 
          stroke={ zoomLayers ? style.azul : style.gris} 
          style={{marginRight: '10px'}}
          onClick={() => setZoomLayers((prevState) => !prevState)}
        />

        { taskData.estadoId == 2 && (
          <SwitchButton 
            value={visualization.visible} 
            handleOnChange={(status) => handleToggleAllLayers(status)} 
            switchWidth={34}
            switchHeight={18}
            showLayersIcon
          />
        )}
        
        <Popover2
          interactionKind='hover'
          usePortal={false}
          content={<span className={style.statusPopover}>{getStatusName(+taskData.estadoId)}</span>}
          position='top'
        >
          <StatusIconMosaic status={taskData.estadoId ?  taskData.estadoId : 1} />
        </Popover2>
      </div>
     
      <Popover2
        position='right'
        interactionKind='click'
        usePortal={true}
        disabled={MOSAIC_STAGE.PUBLISHED != taskData.estadoId}
        className={style.popover}
        content={
          mosaicView ?
          <MosaicContextual
            mosaicTask={taskData}
            mosaicView={mosaicView}
            projectId={projectId}
            lotId={lotId}
            taskId={taskId}
            handdleModal={() => setShowModal(!showModal)}
          />
          :
          <>
          </>
        }
      >
        <div className={style.dotwrap} >
          <Dots className={style.icon} style={{ fill:  MOSAIC_STAGE.PUBLISHED != taskData.estadoId ? style.verdeOscuro : '' }} ></Dots>
        </div>
      </Popover2>
      {showModal && mosaicView && (
        <HistogramModal mosaicView={mosaicView} handdleClose={() => handdleCloseModal()} />
      )}
    </Reorder.Item>
  );
}

export const mosaicStatus = (taskStatus: TaskStatus[]) => {
  const aux = taskStatus
    .filter((status: TaskStatus) => status.name.startsWith('DB_MOSAICO_STATUS'))
    .map((status: TaskStatus) => {
      return { ...status, name: status.name.replace('DB_MOSAICO_STATUS_', '') };
    });
  return aux.reduce((acc: any, curr: TaskStatus) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});
};
