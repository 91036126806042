import { ReactComponent as ArrowNext } from 'assets/icons/arrow-next.svg';
import { ReactComponent as ArrowPrev } from 'assets/icons/arrow-prev.svg';
import cx from 'classnames';
import { motion } from 'framer-motion';
import SidebarFooter from 'layout/Sidebar/SidebarFooter';
import { useTranslation } from 'react-i18next';

import style from '../../../pages/Tasks/AddTask.module.scss';


export interface StepsFormFooterProps {
    currentStep: number;
    stepsAmount: number;
    handlePreviousStep: () => void;
    handleNextStep: () => void;
    loading?: boolean;
    disableBackBtn?: boolean;
    disableNextBtn?: boolean;
}

export default function StepsFormFooter ({
    currentStep,
    stepsAmount,
    loading,
    disableBackBtn,
    disableNextBtn,
    handlePreviousStep,
    handleNextStep
}: StepsFormFooterProps) {
    const { t } = useTranslation();

    const isFirstStep = currentStep === 1; 
    const isLastStep = currentStep === stepsAmount;

    return (
      <SidebarFooter oneChildContainer={currentStep === 1}>
        {/* BACK STEP */}
        { currentStep != 1 && (
          <motion.div whileHover={'hoverBack'} 
            className={cx(
              style.sidebarFooterBtn,
              disableBackBtn 
                ? style.sidebarFooterBtnDisabled 
                : style.sidebarFooterBtnEnabled
            )}
            onClick={handlePreviousStep}
          >
            <motion.svg
              style={{width: '20px', height: '15px'}}
              variants={{
                hoverBack: {
                  x: -3,
                  transition: {
                    duration: 0.2,
                    repeat: Infinity,
                    repeatType: 'reverse',
                  },
                },
              }}
            >
              <ArrowPrev />
            </motion.svg>
            
            <p>{t('anterior')}</p>
          </motion.div>
        )}

        {/* NEXT STEP */}
        <motion.div  
          whileHover={'hoverBack'}
          className={cx(
            style.sidebarFooterBtn,
            disableNextBtn
              ? style.sidebarFooterBtnDisabled 
              : style.sidebarFooterBtnEnabled
          )}
          onClick={handleNextStep}
        >

          <p>
            {isFirstStep
              ? t('start')
              : isLastStep
                ? t('Finalizar')
                : t('next') 
            }
          </p>

          <motion.svg
            style={{width: '20px', height: '15px'}}
            variants={{
              hoverBack: {
                x: -3,
                transition: {
                  duration: 0.2,
                  repeat: Infinity,
                  repeatType: 'reverse',
                },
              },
            }}
          >
            <ArrowNext/>
          </motion.svg>
        </motion.div>
      </SidebarFooter>
    )
}