import { Popover } from 'bootstrap';
import { Feature, MapBrowserEvent, Overlay } from 'ol';
import { Point as OlPoint, Point } from 'ol/geom';
import { Pointer } from 'ol/interaction';
import { transform } from 'ol/proj';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { dataProjection, featureProjection } from 'services/configurations';
import { ColorCoverage } from 'styles/pointStyle';

import MapContext from '../Context/MapContext';
import VectorLayerContext from '../Context/VectorLayerContext';
import { randomString } from './MPoint';

interface MCircleProps {
  lat: number;
  lng: number;
  hovered?: boolean;
  color?: string;
  text?: string;
  radius?: number;
  overlayContent?: { title: JSX.Element; content: JSX.Element };
  onLoadCenter?: boolean;
}

function MCircleText({
  lat,
  lng,
  hovered,
  color = '#fdeb1f',
  text = '',
  radius = 0,
  overlayContent = { title: <></>, content: <></> },
}: MCircleProps) {
  const map = React.useContext(MapContext);
  const vectorLayer = React.useContext(VectorLayerContext);
  const featureRef = React.useRef<Feature>(new Feature());
  const feature = featureRef.current;
  const popup = useRef<Overlay>();
 
  useEffect(() => {
    const style = feature.getStyle() as Style;
    if (hovered && style) {
      style.getImage()?.setScale(3);
      style.getText()?.setScale(2);
      feature.changed();
    } else if (!hovered && style) {
      style.getImage()?.setScale(1);
      feature.changed();
    }
  }, [feature, hovered]);

  useEffect(() => {
    if (overlayContent && map) {
      const popupDomElement = document.getElementById('popup');
      const idToRemove = randomString();
      const element = document.createElement('div');
      element.id = idToRemove;
      popupDomElement?.appendChild(element);
      const handleClickOutside = (e: MouseEvent) => {
        const popoverInstance = Popover.getInstance(element);
        if (popoverInstance && !element.contains(e.target as Node)) {
          popoverInstance.dispose();
        }
      };
      const onClickFn = (e: MapBrowserEvent<any>) => {
        const features = map.getFeaturesAtPixel(e.pixel, {
          hitTolerance: 8,
        });
        if (features && features.length > 0) {
          const currentFeat = features.filter((feat) => feat === feature);
          if (currentFeat.length > 0) {
            Popover.getInstance(element)?.dispose();
            if (popup.current) {
              map.removeOverlay(popup.current);
              popup.current.dispose();
              popup.current = undefined;
            }
            const newPopup = new Overlay({
              element,
            });
            popup.current = newPopup;
            map.addOverlay(newPopup);
            const Content = overlayContent.content;
            const contentDiv = document.createElement('div');
            const root = createRoot(contentDiv);
            root.render(Content!);
            const Title = overlayContent.title;
            const titleDiv = document.createElement('div');
            const rootTitle = createRoot(titleDiv);
            rootTitle.render(Title!);
            const newPopover = new Popover(element, {
              animation: true,
              container: element,
              content: contentDiv,
              html: true,
              placement: 'top',
              title: titleDiv,
              sanitize: false,
            });
            newPopover.show();
            newPopup.setOffset([0, -10]);
            const pos = (currentFeat[0].getGeometry() as Point).getCoordinates();
            newPopup.setPosition(pos);
            newPopup.setPositioning('top-center');
          }
        }
      };
      const click = map.on('click', onClickFn);
      document.addEventListener('mousedown', handleClickOutside); // Add the click listener
      return () => {
        if (popup.current) {
          map.removeOverlay(popup.current);
          popup.current.dispose();
          popup.current = undefined;
        }
        Popover.getInstance(element)?.dispose();
        document.removeEventListener('mousedown', handleClickOutside); // Remove the click listener
        document.getElementById(idToRemove)?.remove();

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        map.un('click', onClickFn);
      };
    }
  }, [hovered, feature, map, overlayContent]);

  useEffect(() => {
    function createStyleGrey(text: string, color: string, radius: number) {
      return new Style({
        zIndex: 1000000000,
        image: new CircleStyle({
          radius: radius * 2,
          fill: new Fill({ color: ColorCoverage.color }),
          stroke: new Stroke({ color: ColorCoverage.stroke, width: 1.3 }),
        }),
      });
    }

    const CIRCLE_STYLE_IDENTIFIER = 'greyCircle';

    feature.set('uniqueIdentifier', CIRCLE_STYLE_IDENTIFIER);
    
    const pointerInteraction = new Pointer({
      handleMoveEvent: function (event) {
        if (map != null) {
          const features = map.getFeaturesAtPixel(event.pixel, {
            hitTolerance: radius * 2,
          });
    
          // Check if any feature at the cursor position has the unique identifier
          const hasCircleStyle = features.some((feat) => {
            return feat.get('uniqueIdentifier') === CIRCLE_STYLE_IDENTIFIER;
          });
    
          map.getTargetElement().style.cursor = hasCircleStyle ? 'pointer' : '';
        }
      },
    });
    map?.addInteraction(pointerInteraction);

    function createStyle(text: string, color: string, radius: number) {
      return new Style({
        zIndex: 1000000001,
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({ color: color }),
          stroke: new Stroke({ color: color === 'transparent' ? color : 'black', width: 1.4 }),
        }),
        text: new Text({
          font: '14.5px Roboto',
          textAlign: 'left',
          justify: 'left',
          text: `${text}`,
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: '#000000',
            width: 3,
          }),
          offsetX: (10),
        }),
      });
    }

    // show point and text
    const PointStyle = createStyle(text, color, radius);
    feature.setGeometryName('coordinates');
    const point = new OlPoint(transform([lng, lat], dataProjection, featureProjection));
    feature.setGeometry(point);
    const source = vectorLayer?.getSource();
    feature.setStyle(PointStyle);
    if (source) {
      source.addFeature(feature);
    }

    // show gray circle
    // const source = vectorLayer?.getSource();
    const GrayCircleStyle = createStyleGrey(text, color, radius);
    const grayCircle = new OlPoint(transform([lng, lat], dataProjection, featureProjection));
    const grayFeature = new Feature();
    grayFeature.setGeometryName('coordinatesX');
    grayFeature.setGeometry(grayCircle);
    grayFeature.setStyle(GrayCircleStyle);
    if (source) {
      source.addFeature(grayFeature);
    }

    // const TextStyle = createStyleText(text, color, radius);
    // const textMarker = new OlPoint(transform([lng, lat], dataProjection, featureProjection));
    // const textFeature = new Feature();
    // textFeature.setGeometryName('coordinatesX');
    // textFeature.setGeometry(textMarker);
    // textFeature.setStyle(TextStyle);
    // if (source) {
    //   source.addFeature(textFeature);
    // }

    return () => {
      vectorLayer?.getSource()?.removeFeature(feature);
      vectorLayer?.getSource()?.removeFeature(grayFeature);
      // vectorLayer?.getSource()?.removeFeature(textFeature);
    };
  }, [color, feature, lat, lng, vectorLayer, text, radius, map]);

  return <></>;
}

export default MCircleText;
