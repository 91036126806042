// #region REACT
import { Popover2 } from '@blueprintjs/popover2';
// #endregion
// #region Icons
import { ReactComponent as Dots } from 'assets/icons/ico-dots.svg';
import { ReactComponent as InspectLayersIcon } from 'assets/icons/ico-inspect-layers.svg';
import { ReactComponent as MappingIcon } from 'assets/icons/ico-mapeo.svg';
import { ReactComponent as ScoutingIcon } from 'assets/icons/ico-scouting.svg';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import { Reorder, useDragControls } from 'framer-motion';
// #endregion
// #region Models
import { MappingMission, ScoutingMission } from 'models/Mission';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
// #endregion
// #region State
import { IAllTasks, toggleLayersVisibility, updateVisualization,VisualizationMission } from 'state/slices/taskSlice';

import { ReorderIcon } from './ReorderIcon';
// #endregion
// #region COMPONENTS
import MissionContextual from './TaskListContextual/MissionContextual/MissionContextual';
// #endregion
// #region STYLES
import style from './TaskListItem.module.scss';
// #endregion

interface MissionItemProps {
  task: IAllTasks,
}

export function MissionListItem({ task }: MissionItemProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dragControls = useDragControls();

  const [zoomLayers, setZoomLayers] = useState(false);

  const { projectId, lotId } = useParams<{
    projectId: string;
    lotId: string;
  }>();

  if (!projectId || !lotId) {
    return null;
  }

  const visualization = task.visualization as VisualizationMission;
  const taskData = task.task as MappingMission | ScoutingMission;
  const taskType = taskData.type == 'SCOUTING' ? 'Scouting' : 'Mapping';
  
  const handleToggleAllTasks = (newTask: any) => {
    dispatch(updateVisualization(newTask));
    dispatch(toggleLayersVisibility(task.taskId));
  };

  const handleToggle = (status: boolean) => {
    const newTask = {
      ...task,
      visualization: {
        ...visualization,
        visible: status,
        showFlightPlan: status,
      },
    };
    handleToggleAllTasks(newTask);
  }

  function getFlightDate() {
    if (typeof taskData.creationDateTime === 'string') {
      return new Date(taskData.creationDateTime).toLocaleDateString();
    }
  }

  return (
    <Reorder.Item 
      className={style.taskItem}
      value={task} 
      key={task as any}
      dragControls={dragControls}
    >
      <div className={style.taskinfo}>
        <div className={style.reorderIcon}>
          <ReorderIcon dragControls={dragControls} />
        </div>

        { taskData.type == 'SCOUTING'  && <ScoutingIcon className={style.missionIcon}/>}
        { taskData.type == 'MAPPING' && <MappingIcon className={style.missionIcon}/>}
        
        <div className={style.taskInfoText} >
          <p>
            {`${t('mission')} (#${task.taskId})`}
          </p>
          <p className={style.taskInfoSubtitle}>
            {`${getFlightDate()} - ${t('height')} ${taskData.altitude}m`}
          </p>
        </div>
      </div>

      <div className={style.taskItemStatus}>
        <InspectLayersIcon 
          stroke={zoomLayers ? style.azul : style.gris} 
          style={{marginRight: '10px'}}
          onClick={() => setZoomLayers((prevState) => !prevState)}
        />

        <SwitchButton 
          value={visualization.visible} 
          handleOnChange={(status) => handleToggle(status)} 
          switchWidth={34}
          switchHeight={18}
          showLayersIcon
        />
      </div>

      <Popover2
        interactionKind='click'
        usePortal={true}
        className={style.popover}
        content={<MissionContextual projectId={projectId} task={task} lotId={lotId} />}
      >
        <div className={style.dotwrap} >
          <Dots className={style.icon} />
        </div>
      </Popover2>
    </Reorder.Item >
  );
}
