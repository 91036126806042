import { cameras } from 'services/mappingMissionGenerator';

const ALTITUDE_MIN = 0;
const ALTITUDE_MAX = 200;

const SPEED_MIN = 1;
const SPEED_MAX = 15;

const OVERLAP_MIN = 20;
const OVERLAP_MAX = 90;
//  {
//   name: any;
//   altitude: number;
//   speed: number;
//   flight_direction: number;
//   type: string;
//   frontal_overlap: number;
//   lateral_overlap: number;
//   polygon_vertices: string | any[];
//   camera_name?: string | null;
// }

export function validateMission(mission: any) {
  const errors = [];
  if (!mission.name) {
    errors.push('Debe agregar nombre a la misión');
  }

  if (mission.altitude > ALTITUDE_MAX) {
    errors.push(`La altitud debe ser menor a ${ALTITUDE_MAX}`);
  }

  if (mission.altitude < ALTITUDE_MIN) {
    errors.push(`La altitud debe ser mayor a ${ALTITUDE_MIN}`);
  }

  if (mission.speed > SPEED_MAX) {
    errors.push(`La velocidad debe ser menor a ${SPEED_MAX}`);
  }

  if (mission.speed < SPEED_MIN) {
    errors.push(`La velocidad debe ser mayor a ${SPEED_MIN}`);
  }

  if (mission.flight_direction > 180 && mission.flight_direction < -180) {
    errors.push('La dirección de vuelo debe estar entre -180 y 180 grados');
  }

  if (mission.type == 'MAPPING') {
    if (mission.frontal_overlap < OVERLAP_MIN) {
      errors.push(`La superposición frontal debe ser mayor a ${OVERLAP_MIN}`);
    }

    if (mission.frontal_overlap > OVERLAP_MAX) {
      errors.push(`La superposición frontal debe ser menor a ${OVERLAP_MAX}`);
    }

    if (mission.lateral_overlap < OVERLAP_MIN) {
      errors.push(`La superposición lateral debe ser mayor a ${OVERLAP_MIN}`);
    }

    if (mission.lateral_overlap > OVERLAP_MAX) {
      errors.push(`La superposición lateral debe ser menor a ${OVERLAP_MAX}`);
    }
  }

  // if( (mission.type == "MAPPING") && (mission.polygon_vertices<4)){
  //     errors.push(`La misión debe tener al menos 4 puntos`)
  // }

  // if( (mission.type == "MAPPING") && (mission.polygon_vertices<4)){
  //     errors.push(`La misión debe tener al menos 4 puntos`)
  // }

  if (mission.polygon_vertices.length < 4) {
    errors.push('La misión debe tener al menos 4 puntos');
  }
  if (
    mission.type === 'MAPPING' &&
    (mission.camera_name === null ||
      cameras[mission.camera_name as keyof typeof cameras] === undefined)
  ) {
    errors.push('Debe seleccionar una cámara');
  }
  return errors;
}
