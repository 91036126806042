import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import { PlantCountTask } from 'models/PlantCountTask';
import { Prescription } from 'models/Prescription';

interface PQFormStateProps {
    showPrescription: boolean;
    showPrescriptionTable: boolean;
    collapsePrescriptionTable: boolean; 
    prescriptionEditable: boolean;
    prescription: Prescription | null;
    pqForm: Partial<PlantCountTask>; 
}

const initialState = {
    showPrescription: false,
    showPrescriptionTable: false,
    collapsePrescriptionTable: false,
    prescriptionEditable: true,
    prescription: null,
    pqForm: {} 
} as PQFormStateProps 


const reducer = {
    setName: (state: PQFormStateProps, action: PayloadAction<string>) => {
        state.pqForm.nombre = action.payload
    },
    setCuit: (state: PQFormStateProps, action: PayloadAction<string>) => {
        state.pqForm.CUIT = action.payload
    },
    setTaxIdType: (state: PQFormStateProps, action: PayloadAction<string>) => {
        state.pqForm.taxIdType = action.payload
    },
    setSowDate: (state: PQFormStateProps, action: PayloadAction<string | undefined>) => {
        state.pqForm.fechaSiembra = action.payload
    },
    setFligthDate: (state: PQFormStateProps, action: PayloadAction<string | undefined>) => {
        state.pqForm.fechaVuelo = action.payload
    },
    setTargetDensity: (state: PQFormStateProps, action: PayloadAction<number>) => {
        state.pqForm.targetDensity = action.payload
    },
    setDensidadesJson: (state: PQFormStateProps, action: PayloadAction<any>) => {
        state.pqForm.densidadesJson = action.payload
    },
    setCropId: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.cropId = action.payload
    },
    setGenotypeId: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.genotypeId = action.payload
    },
    setDistanceId: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.distancing = action.payload
    },
    setSurco: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.dSurco = action.payload
    },
    setPhenologicalStageId: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.phenologicalStateId = action.payload
    },
    setSowTypeId: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.sowTypeId = action.payload
    },
    setSeederMeterId: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.seederMeterId = action.payload
    },
    setSeederType: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.seederId = action.payload
    },
    setSeederBrandId: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.seederBrandId = action.payload
    },
    setSeederModelId: (state: PQFormStateProps, action: PayloadAction<number | undefined>) => {
        state.pqForm.seederModelId = action.payload
    },
    setImageFiles: (state: PQFormStateProps, action: PayloadAction<File[]>) => {
        state.pqForm.files = action.payload
    },
    setShowPrescription: (state: PQFormStateProps, action: PayloadAction<boolean>) => {
        state.showPrescription = action.payload;
    },
    setShowPrescriptionTable: (state: PQFormStateProps, action: PayloadAction<boolean>) => {
        state.showPrescriptionTable = action.payload;
    },
    setCollapsePrescriptionTable: (state: PQFormStateProps, action: PayloadAction<boolean>) => {
        state.collapsePrescriptionTable = action.payload;
    },
    setPrescriptionEditable: (state: PQFormStateProps, action: PayloadAction<boolean>) => {
        state.prescriptionEditable = action.payload;
    },
    setPrescription: (state: PQFormStateProps, action: PayloadAction<Prescription | null>) => {
        state.prescription = action.payload;
    },
    clearForm: (state: PQFormStateProps) => {
        return { ...initialState };
    }
}

export const pqFormSlice = createSlice({
    name: 'pqFormState',
    initialState,
    reducers: reducer,
});

export const {
    setName,
    setCuit,
    setTaxIdType,
    setTargetDensity,
    setCropId,
    setGenotypeId,
    setDistanceId,
    setSowTypeId,
    setSeederMeterId,
    setSeederType,
    setSeederBrandId,
    setSeederModelId,
    setPhenologicalStageId,
    setDensidadesJson,
    setSowDate,
    setSurco,
    setFligthDate,
    setImageFiles,
    setShowPrescription,
    setShowPrescriptionTable,
    setCollapsePrescriptionTable,
    setPrescriptionEditable,
    setPrescription,
    clearForm,
} = pqFormSlice.actions;
  
export default pqFormSlice.reducer;